import React, { useMemo } from 'react'

const ImagePlaceholder = ({type, iconSize='5x'}) => {

	const getIcon = type => {
		switch(type) {
			case 'pdf': return 'fa-file-pdf';
			case 'image': return 'fa-file-image';
			case 'audio': return 'fa-file-audio';
			case 'video': return 'fa-file-video';
			default:
				return 'fa-file';
		}
	}

	const classNames = useMemo(() => {

		const iconClass = getIcon(type);

		return [
			'fas',
			`fa-${iconSize}`,
			'text-primary',
			iconClass
		].join(' ');
	}, [type, iconSize]);

	return (
		<div>
			<i className={classNames} style={{
				transform: 'translateY(6%)'
			}}></i>
		</div>
	)
}

export default ImagePlaceholder
