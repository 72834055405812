import React, { Component } from 'react'
import './Maintenance.css'

export class Maintenanance extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="error-template">
                            <h1>
                                <img style={{width: "100px", marginRight: "30px"}} src={require("../../../assets/images/repair.png")}/>
                                :) Oops!</h1>
                            <h2>
                                Forgot password is under construction</h2>
                            <h1>
                                We’ll be back soon!</h1>
                            <div>
                                <p>
                                    Sorry for the inconvenience but we’re performing some maintenance at the moment.
                                    we’ll be back online shortly!</p>
                                <p>
                                    — The Team</p>
                            </div>
                            <div className="error-actions">
                                <a href="/" style={{marginTop: "10px"}} className="btn">
                                Back to home
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default Maintenanance
