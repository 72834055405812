import React, { useState, useEffect } from 'react';
import AutosizeInput from 'react-input-autosize';

import './index.css';

function VitalSignsComponent({
  vitalSigns = {}, 
  updateVitalSigns = () => {},
  isDisabled = false,
}) {

  const [minimized, setMinimized] = useState(false);

  function handleOnChange(e) {
    const { name, value } = e.target;
    updateVitalSigns(name, value);
  }

  useEffect(() => {
    
    window.addEventListener('resize', () => {
      if (window.innerWidth < 398) {
        setMinimized(true);
      } else  {
        setMinimized(false);
      }
    });

    return () => {};
  }, [])

  return (
    <div className="card custom-card">
      <h5 className="card-header ">VITAL SIGNS</h5>
      <div className="card-body" style={{ padding: '10px' }}>
        <div className="custom-form-group-container">
          <div className="custom-form-group">
            <span htmlFor="bp">BP</span>
            <input
              disabled={isDisabled} 
              id="bp" 
              name="bp" 
              type="text" 
              className="form-control" 
              placeholder="BP"
              onChange={e => handleOnChange(e)}
              value={vitalSigns.bp} />
          </div>
          <div className="custom-form-group">
            <span htmlFor="hr">HR</span>
            <input
              disabled={isDisabled} 
              id="hr" 
              name="hr" 
              type="text" 
              className="form-control" 
              placeholder="HR" 
              onChange={e => handleOnChange(e)}
              value={vitalSigns.hr} />
          </div>
          <div className="custom-form-group">
            <span htmlFor="br">RR</span>
            <input
              disabled={isDisabled} 
              id="br" 
              name="br" 
              type="text" 
              className="form-control" 
              placeholder="RR" 
              onChange={e => handleOnChange(e)}
              value={vitalSigns.br} />
          </div>
          <div className="custom-form-group">
            <span htmlFor="temp">Temp</span>
            <input
              disabled={isDisabled} 
              id="temp" 
              name="temp" 
              type="text" 
              className="form-control" 
              placeholder="Temp" 
              onChange={e => handleOnChange(e)}
              value={vitalSigns.temp} />
          </div>
          <div className="custom-form-group">
            <span htmlFor="sat">02 Sat</span>
            <input
              disabled={isDisabled} 
              id="sat" 
              name="sat" 
              type="text" 
              className="form-control" 
              placeholder="02 Sat" 
              onChange={e => handleOnChange(e)}
              value={vitalSigns.sat} />
          </div>
          <div className="custom-form-group">
            <span htmlFor="painScale">Pain Scale</span>
            <select 
              id="painScale" 
              name="painScale" 
              className="form-control" 
              placeholder="Pain Scale" 
              onChange={e => handleOnChange(e)}
              value={vitalSigns.painScale}
              disabled={isDisabled}>
              <option value="0">0/10</option>
              <option value="1">1/10</option>
              <option value="2">2/10</option>
              <option value="3">3/10</option>
              <option value="4">4/10</option>
              <option value="5">5/10</option>
              <option value="6">6/10</option>
              <option value="7">7/10</option>
              <option value="8">8/10</option>
              <option value="9">9/10</option>
              <option value="10">10/10</option>
            </select>
          </div>
          <div className="custom-form-group">
            <span htmlFor="height">Height(in)</span>
            <input
              disabled={isDisabled} 
              id="height" 
              name="height" 
              type="text" 
              className="form-control" 
              placeholder="Height(in)" 
              onChange={e => handleOnChange(e)}
              value={vitalSigns.height} />
          </div>
          <div className="custom-form-group">
            <span htmlFor="weight">Weight(lb)</span>
            <input
              disabled={isDisabled} 
              id="weight" 
              name="weight" 
              type="text" 
              className="form-control" 
              placeholder="Weight(lb)" 
              onChange={e => handleOnChange(e)}
              value={vitalSigns.weight} />
          </div>
          <div className="custom-form-group">
            <span htmlFor="bmi">BMI</span>
            <input
              disabled={isDisabled} 
              id="bmi" 
              name="bmi" 
              type="text" 
              className="form-control" 
              type="text"  
              onChange={e => handleOnChange(e)}
              value={vitalSigns.bmi} />
          </div>
          <div className="custom-form-group" style={{ maxWidth: '310px' }}>
            <span htmlFor="comments">Interpretation and Comments</span>
            <AutosizeInput
              id="comments"
              name="comments"
              type="text" 
              inputStyle={{ 
                minWidth: minimized ? '128px' : '287px',
                maxWidth: minimized ? '128px' : '450px',
                height: '24px',
                fontSize: '12px',
                border: 'solid 1px rgb(209, 211, 226)',
                paddingLeft: '10px',
                paddingRight: '10px',
              }}
              onChange={e => handleOnChange(e)}
              value={vitalSigns.comments}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default VitalSignsComponent;
