import React, { Component } from 'react'

export class SystemMessage extends Component {
    render() {
        const { message } = this.props;
        return (
            <li className="Bubble-List-Container">
                <div className="_system-message">
                    {/**<i class="material-icons" style={{fontSize: '18px', verticalAlign: "middle", marginRight: "3px"}}>info</i>**/}
                    {message.text}
            </div>
           </li>
        )
    }
}

export default SystemMessage
