import React, { Component } from 'react'

export class Block extends Component {
    render() {
        return (
            <div className="Video-Container">
                <div className="Allow-Message">
                    <div class="arrow-up"></div>
                    <p style={{fontSize: "15px", margin: "0", fontWeight: "500"}}>Allow access to microphone and camera</p>
                    <p style={{lineHeight: "14px", fontSize: "12px", marginTop: "5px"}}>You'll need to allow access to microphone and camera to continue calling</p>
                </div>
                <div className="Instruction-Container">
                <p style={{fontSize: "23px"}}>Something went wrong Please allow microphone <i style={{color: "rgb(211, 47, 47)"}} className="fas fa-microphone"></i> and camera <i style={{color: "rgb(211, 47, 47)"}} className="fas fa-camera"></i></p>
                </div>

            </div>
        )
    }
}

export default Block
