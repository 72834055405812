import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchGroups,
  getGroups,
  searchGroups,
  searchStatus,
} from "../../../actions/GroupAction";
import { createThread, fetchThreads } from "../../../actions/ThreadAction";
import GroupList from "./GroupList";
import LoadingSpinner from "../../../components/Conversation/Bubble/LoadingSpinner";

export class GroupContainer extends Component {
  constructor() {
    super();
    this.state = {
      isLoadingMoreGroups: false,
      preventReload: false,
      searchTimeout: null,
      isLoadingSearch: false,
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.searchMoreGroups = this.searchMoreGroups.bind(this);
    this.loadMoreGroups = this.loadMoreGroups.bind(this);
  }

  componentDidMount() {
    this.props.fetchThreads();
    if (!this.props.groupInitialLoad) {
      this.props.fetchGroups("regular", false);
    }
    this.content.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    this.content.removeEventListener("scroll", this.handleScroll);
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (this.props.typeValue !== "") {
      if (prevProps.typeValue !== this.props.typeValue) {
        // Everytime the user types something in search input...
        const { searchTimeout } = this.state;

        // Clear the currently running timer
        // Reset it basically
        if (searchTimeout) {
          clearTimeout(searchTimeout);
        }

        // Create new one
        const newSearchTimeout = setTimeout(() => {
          this.setState({ isLoadingSearch: true });

          this.props.searchGroups({ text: this.props.typeValue });
        }, 500);

        // Update the state with the new timeout
        this.setState({ searchTimeout: newSearchTimeout });
      }
    } else {
      if (this.props.isSearchingGroup) {
        this.props.searchStatus(false);
      }
    }

    return null;
  }

  componentDidUpdate() {}

  handleScroll(event) {
    let initialHeight = event.target.scrollHeight;
    let height = event.target.offsetHeight;
    let scrollTop = event.target.scrollTop;

    if (scrollTop + height >= initialHeight) {
      this.handleScrollBottom();
    }
  }

  handleScrollBottom() {
    const isSearching = this.props.typeValue != "";

    const isLoadingMore = isSearching
      ? this.props.isLoadingMoreSearch
      : this.state.isLoadingMoreGroups;

    const action = isSearching ? this.searchMoreGroups : this.loadMoreGroups;

    if (!isLoadingMore) {
      action();
    }
  }

  loadMoreGroups() {
    if (this.state.preventReload) return;

    this.setState({
      isLoadingMoreGroups: true,
    });

    this.props
      .fetchGroups("regular", true, this.props.page)
      .then((itemLength) => {
        if (itemLength === 0) {
          this.setState({
            preventReload: true,
          });
        }
        this.setState({
          isLoadingMoreGroups: false,
        });
      })
      .catch(() => {
        this.setState({
          isLoadingMoreGroups: false,
        });
      });
  }

  searchMoreGroups() {
    const { searchPagination } = this.props;

    const { page, hasMore } = searchPagination;

    if (!hasMore) return;

    this.props.searchGroups({
      text: this.props.typeValue,
      page: page + 1,
    });
  }

  render() {
    const { isLoadingSearch, isLoadingMoreSearch } = this.props;

    return (
      <div className="Tab-Container mb-0" ref={(ul) => (this.content = ul)}>
        {!this.props.groupInitialLoad && <LoadingSpinner />}
        <GroupList
          {...this.props}
          isLoadingSearch={isLoadingSearch}
          isLoadingMoreSearch={isLoadingMoreSearch}
          isLoadingMoreGroups={this.state.isLoadingMoreGroups}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  groupInitialLoad: state.group.groupInitialLoad,
  groups: state.group.groups,
  isSearchingGroup: state.group.isSearchingGroup,
  page: state.group.groupPage,

  /* 
        Group search
    */
  isLoadingSearch: state.group.isLoadingSearch,
  isLoadingMoreSearch: state.group.isLoadingMoreSearch,
  searchPagination: state.group.searchPagination,
  filterGroupResult: state.group.filterGroupResult,
});

export default connect(mapStateToProps, {
  fetchGroups,
  getGroups,
  createThread,
  fetchThreads,
  searchGroups,
  searchStatus,
})(GroupContainer);
