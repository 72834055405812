import React from "react";
import "./index.css";

function OrdersComponent({ orders = {} }) {
  const {
    prescription = "",
    diagnosticStudies = "",
    referrals = "",
    medicalSupplies = ""
  } = orders;

  return (
    <div className="card custom-card">
      <h5 className="card-header ">
        <strong>ORDERS</strong>
      </h5>
      <div className="card-body">
        <div className="custom-form-group-container">
          {prescription && (
            <div className="pe-text">
              <strong>Prescription</strong>
              <div>{prescription || ""}</div>
            </div>
          )}
          {diagnosticStudies && (
            <div className="pe-text">
              <strong>Diagnostic Studies</strong>
              <div>{diagnosticStudies || "added."}</div>
            </div>
          )}
          {referrals && (
            <div className="pe-text">
              <strong>Referrals/Consults</strong>
              <div>{referrals || ""}</div>
            </div>
          )}
          {medicalSupplies && (
            <div className="pe-text">
              <strong>Medical Supplies</strong>
              <div>{medicalSupplies || ""}</div>
            </div>
          )}
          {!prescription &&
            !diagnosticStudies &&
            !referrals &&
            !medicalSupplies && (
              <div className="custom-form-group-container pe-text">
                <span></span>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default OrdersComponent;
