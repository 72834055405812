import React, { useEffect, useState } from 'react';
import ContactSelect from './ContactSelect';
import Modal from '../../../Common/Modal';
import Chips from './Chips';

const $ = window.$;

function InviteContactModal({ isOpen, onClose, onSubmit }) {

    // The selected contacts, and setter method
    const [selected, setSelected] = useState([]);
    const [currentTab, setCurrentTab] = useState('invite_user');

    // Use componentDidmount lifecycle
    useEffect(() => {

        if (!isOpen) return;

        // Upon opening modal, clear selected
        setSelected([]);
    }, [isOpen]);

    const handleSelect = contact => {
        setSelected(selected => [...selected, contact]);
    };

    const handleUnselect = contact => {
        setSelected(selected =>
            selected.filter(c => c.objectId != contact.objectId)
        );
    };

    function handleSubmit(e) {

        e.preventDefault();

        onSubmit(selected);
    }

    return (
        <Modal shown={isOpen} onHide={onClose}>

            <div className="modal-header">
                <h5 className="modal-title hc-primary-text">Hubchart contacts</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div className="modal-body py-0" style={{ height: '100%' }}>
                <ContactSelect exclude={selected} onSelect={(c) => handleSelect(c)} />
            </div>

            <div className="modal-footer py-0" align="right">
                <div style={{ display: "block" }}>
                    {selected.map((contact, i) =>
                        <Chips key={i} contact={contact} onUnselect={() => handleUnselect(contact)} />
                    )}
                </div>
                <button data-tip data-for="start-conference-button" disabled={!selected.length} onClick={handleSubmit} type="button" className="btn btn-hubchart btn-hubchart-primary my-3">
                    <i className="fas fa-user-plus mr-1"></i>
                    Invite
                </button>
            </div>
        </Modal>
    );
}

export default InviteContactModal;
