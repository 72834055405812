import React from 'react'
import './Signup.css'

const Spinner = (props) => {
    const Style = {
        right: "-3px",
        top: "2px",
        position: "absolute"
    }

    if (props.style) {
        Style.right = props.style.right;
        Style.top = props.style.top;

    }

    return (
        <div style={Style}>
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Spinner;