import { parseAction, parseActionNoAuth } from "../actions/REST";
import config from "../config";

function createConferenceCall(participantIds = [], recorded, circleId = "") {
  const method = "post";

  const url = config.BASE_URL + "/parse/functions/createConferenceCall";

  const payload = {
    participantIds,
    recorded,
    circleId,
  };

  const headers = {
    "Content-Type": "application/json",
  };

  return parseAction(method, url, payload, headers).then((res) => {
    return res.result;
  });
}

function joinConferenceCall(roomId) {
  const method = "post";

  const url = config.BASE_URL + "/parse/functions/joinConferenceCall";

  const payload = {
    roomId,
  };

  return parseAction(method, url, payload).then((res) => {
    return res.result;
  });
}

function endConferenceCall(roomId) {
  const method = "post";

  const url = config.BASE_URL + "/parse/functions/completeConferenceCall";

  const payload = {
    roomId,
  };

  return parseAction(method, url, payload)
    .then((response) => {
      return response.result;
    })
    .catch((error) => {
      // TODO handle error when fetching search contact
      console.log(error);

      return Promise.reject(error);
    });
}

function getActiveConferenceCall() {
  const method = "post";

  const url = config.BASE_URL + "/parse/functions/getActiveConferenceCall";

  return parseAction(method, url)
    .then((response) => {
      return response.result;
    })
    .catch((error) => {
      // TODO handle error when fetching search contact
      console.log(error);

      return Promise.reject(error);
    });
}

function getConferenceCalls() {
  const method = "post";

  const url = config.BASE_URL + "/parse/functions/getConferenceCalls";

  return parseAction(method, url)
    .then((response) => {
      return response.result;
    })
    .catch((error) => {
      // TODO handle error when fetching search contact
      console.log(error);

      return Promise.reject(error);
    });
}

function getConferenceCallsById(circleId) {
  const method = "post";
  const url = config.BASE_URL + `/parse/functions/getConferenceCalls`;
  const headers = {
    "Content-Type": "application/json",
  };

  return parseAction(method, url, { circleId }, headers)
    .then((response) => {
      return response.result;
    })
    .catch((error) => {
      console.log(error);
      return Promise.reject(error);
    });
}

function scheduleConferenceCall(payload) {
  const method = "post";

  const url = config.BASE_URL + "/parse/functions/scheduleConferenceCall";

  // const payload = {
  //     participantIds,
  //     dateScheduled
  // };

  const headers = {
    "Content-Type": "application/json",
  };

  return parseAction(method, url, payload, headers)
    .then((response) => {
      return response.result;
    })
    .catch((error) => {
      // TODO handle error when fetching search contact
      console.log(error);

      return Promise.reject(error);
    });
}

function deleteConferenceCall(payload) {
  const method = "post";
  const url = config.BASE_URL + "/parse/functions/deleteConferenceCall";
  const headers = {
    "Content-Type": "application/json",
  };

  return parseAction(method, url, payload, headers)
    .then((response) => {
      return response.result;
    })
    .catch((error) => {
      // TODO handle error when fetching search contact
      console.log(error);

      return Promise.reject(error);
    });
}

function updateConferenceCall(payload) {
  const method = "post";
  const url = config.BASE_URL + "/parse/functions/updateConferenceCall";
  const headers = {
    "Content-Type": "application/json",
  };

  return parseAction(method, url, payload, headers)
    .then((response) => {
      return response.result;
    })
    .catch((error) => {
      // TODO handle error when fetching search contact
      console.log(error);

      return Promise.reject(error);
    });
}

function leaveConferenceCall(roomId) {
  const method = "post";

  const url = config.BASE_URL + "/parse/functions/leaveConferenceCall";

  const payload = {
    roomId,
  };

  return parseAction(method, url, payload)
    .then((response) => {
      return response.result;
    })
    .catch((error) => {
      // TODO handle error when fetching search contact
      console.log(error);

      return Promise.reject(error);
    });
}

function getSchedules(hasPayload) {
  let payload = {};
  if (hasPayload) {
    payload.page = hasPayload.page;
    payload.pageSize = hasPayload.pageSize;
    payload.filter = hasPayload.filter;
  }

  const method = "post";
  const url = config.BASE_URL + "/parse/functions/getSchedulesV2";

  return parseAction(method, url, payload)
    .then((response) => {
      return response.result;
    })
    .catch((error) => {
      // TODO handle error when fetching search contact
      console.log(error);

      return Promise.reject(error);
    });
}

function getUsersById(objectIds) {
  const method = "post";
  const url = config.BASE_URL + "/parse/functions/getUsersById";
  const headers = {
    "Content-Type": "application/json",
  };

  return parseAction(method, url, objectIds, headers)
    .then((response) => {
      return response.result;
    })
    .catch((error) => {
      console.log(error);
      return Promise.reject(error);
    });
}

function joinConferenceCallAsGuest(roomId, displayName, identity) {
  const method = "post";

  const url = config.BASE_URL + "/parse/functions/joinConferenceCallAsGuest";

  const payload = {
    roomId,
    displayName,
    identity,
  };

  return parseActionNoAuth(method, url, payload).then((response) => {
    return response.result;
  });
}

function addParticipants(roomId, participantIds = []) {
  const method = "post";

  const url = config.BASE_URL + "/parse/functions/addParticipants";

  const payload = {
    roomId,
    participantIds,
  };

  const headers = {
    "Content-Type": "application/json",
  };

  return parseAction(method, url, payload, headers)
    .then((response) => {
      return response.result;
    })
    .catch((error) => {
      // TODO handle error when fetching search contact
      console.log(error);

      return Promise.reject(error);
    });
}

function controlConferenceCall(roomId, { mic, cam, identities }) {
  const method = "post";

  const url = config.BASE_URL + "/parse/functions/controlConferenceCall";

  const payload = {
    objectId: roomId,
    mic,
    cam,
    identities,
  };

  const headers = {
    "Content-Type": "application/json",
  };

  return parseAction(method, url, payload, headers).then((res) => res.result);
}

export default {
  createConferenceCall,
  joinConferenceCall,
  endConferenceCall,
  getActiveConferenceCall,
  getConferenceCalls,
  getConferenceCallsById,
  scheduleConferenceCall,
  deleteConferenceCall,
  updateConferenceCall,
  leaveConferenceCall,
  getSchedules,
  getUsersById,
  joinConferenceCallAsGuest,
  addParticipants,
  controlConferenceCall,
};
