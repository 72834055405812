import React from 'react';
import { useSelector } from 'react-redux';
import defaultImageUrl from '../../../../../assets/images/default.png';

const SearchItem = ({ onSelect, item }) => {

    const { displayName, picture } = item;

    const pictureUrl = picture ? picture.url : defaultImageUrl;
    const addingOrgMembers = useSelector(state => state.session.addingOrgMembers);

    return (
        <div className="border-item layout-item">
            <img src={pictureUrl}
                alt=""
                className="profile-image rounded-circle mr-2"
                style={{ width: "30px", height: "30px" }} />
            <p className="name text-truncate m-0">{displayName}</p>

            <button disabled={addingOrgMembers} onClick={(e) => onSelect(item.objectId)} className="btn btn-primary btn-sm btn-block text-nowrap">
                {addingOrgMembers ?
                    <LoadingSpinner /> :
                    <span>Add member</span>
                }
            </button>
        </div>
    )
}

const LoadingSpinner = () => {
    return (
        <div className="mx-4">
            <div className="spinner-border spinner-border-sm text-white" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}

export default SearchItem;
