import React from "react";

import "./index.css";

function VitalSignsComponent({ vitalSigns = {} }) {
  return (
    <div className="card custom-card">
      <h5 className="card-header">
        <strong>VITAL SIGNS</strong>
      </h5>
      <div className="card-body">
        <div className="patient-info-flex">
          <div className="patient-info-item">
            <div>
              <strong>BP: </strong>
              <span>{vitalSigns.bp || "--"}</span>
            </div>
            <div>
              <strong>HR: </strong>
              <span>{vitalSigns.hr || "--"}</span>
            </div>
            <div>
              <strong>RR: </strong>
              <span>{vitalSigns.br || "--"}</span>
            </div>
          </div>
          <div className="patient-info-item">
            <div>
              <strong>Temperature: </strong>
              <span>{vitalSigns.temp || "--"}</span>
            </div>
            <div>
              <strong>02 Sat: </strong>
              <span>{vitalSigns.sat || "--"}</span>
            </div>
            <div>
              <strong>Pain Scale: </strong>
              <span>{`${vitalSigns.painScale}/10` || "--"}</span>
            </div>
          </div>
          <div className="patient-info-item">
            <div>
              <strong>Height(in): </strong>
              <span>{vitalSigns.height || "--"}</span>
            </div>
            <div>
              <strong>Weight(lb): </strong>
              <span>{vitalSigns.weight || "--"}</span>
            </div>
            <div>
              <strong>BMI: </strong>
              <span>{vitalSigns.bmi || "--"}</span>
            </div>
            <div>
              <strong>Interpretation and Comments: </strong>
              <span>{vitalSigns.comments || "--"}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VitalSignsComponent;
