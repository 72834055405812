import React, { Component } from 'react'
import { connect } from 'react-redux'
import { parseAction } from '../../../actions/REST'
import Video from 'twilio-video'
import Parse from 'parse'
import config from '../../../config'

export class VideoContainer extends Component {
    constructor() {
        super();
        this.state = {
            destination : "",
            room : "",
            hasVideo : "",
            hasCall : "",
            callerName : "",
            uuid : "",
            callerProfilePic : ""
        }
        this.log = this.log.bind(this);
        this.activeRoom = null;
        this.joinRoom = this.joinRoom.bind(this)
        this.roomJoined = this.roomJoined.bind(this);
        this.log = this.log.bind(this);
        this.receiveMessage = this.receiveMessage.bind(this)
    }

    componentDidMount() {
        // let params = new URLSearchParams(this.props.location.search);
        // let destination = params.get("cid"),
        //     room = params.get("rid"),
        //     hasVideo = params.get("isVideo"),
        //     hasCall = params.get("hasCall"),
        //     callerName = params.get("caller"),
        //     uuid = params.get("uuid"),
        //     callerProfilePic = params.get("picture");

        //     this.setState({
        //         destination,
        //         room,
        //         hasVideo,
        //         hasCall,
        //         callerName,
        //         uuid,
        //         callerProfilePic,
        //     })


        window.addEventListener("message", this.receiveMessage, false);
        window.addEventListener('beforeunload', this.beforeUnload);

        if (this.props.callDestination === Parse.User.current().id) {
            this.joinRoom();
        }



    }

    componentWillUnmount() {
        this.activeRoom.disconnect();
        window.removeEventListener('beforeunload', this.beforeUnload);
    }

    // if ((window.event.clientX < 0) || (window.event.clientY<0)) // close button
    //         {
    //             //do something on closing event
    //         }
    //         else if (window.event.altKey == true) // ALT + F4
    //         {
    //             //do something on closing event
    //         }
    //         else // for all other unload events
    //         {
    //             //do something on closing event
    //         }

    beforeUnload(e) {
        this.activeRoom.disconnect();
        const confirmationMessage = '';
        e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
        return confirmationMessage;              // Gecko, WebKit, Chrome <34
    }

    // Called sometime after postMessage is called
    receiveMessage(event) {
        // Do we trust the sender of this message?
        if (event.origin !== "http://localhost:3000")
            return;

        // event.source is window.opener
        // event.data is "hello there!"

        // Assuming you've verified the origin of the received message (which
        // you must do in any case), a convenient idiom for replying to a
        // message is to call postMessage on event.source and provide
        // event.origin as the targetOrigin.
        this.parentEventSoure = event.source;
    }



    joinRoom() {
        parseAction("post", config.BASE_URL + "/parse/functions/requestCallToken", {
            destination : this.props.callDestination,
            room : this.props.callRoom
        }).then((res) => {
            Video.createLocalTracks({
                audio: true,
                video: { width: 300 }
            }).then(localTracks => {
                var connectOptions = {
                    name: res.result.room,
                    tracks: localTracks,
                    // logLevel: "debug"
                };


                return Video.connect(res.result.token, connectOptions);

            }).then(this.roomJoined, (error)=> {
                this.log("Could not connect to Twilio: " + error.message);
            });


        }).catch((error) => {
            console.log(error)
        })
    }

    roomJoined(room) {
        this.activeRoom = room;
        if (this.parentEventSoure) {
            this.parentEventSoure.postMessage(this.activeRoom);
        }

        window.room = room.name;
        let self = this;
        this.log("Joined room '" + room.name + "'");
        const localParticipant = room.localParticipant;
        console.log(`Connected to the Room as LocalParticipant "${localParticipant.identity}"`);

        // Attach LocalParticipant's Tracks, if not already attached.
        // var previewContainer = this.refs.localMedia;
        // if (!previewContainer.querySelector("video")) {
        //     this.attachParticipantTracks(room.localParticipant, previewContainer);
        // }

        Video.createLocalVideoTrack().then(track => {
            const localMediaContainer = this.refs.localMedia;
            if (!localMediaContainer.querySelector("video")) {
                localMediaContainer.appendChild(track.attach());
            }
        });

        // Log any Participants already connected to the Room
        room.participants.forEach(participant => {
            self.log(`Participant "${participant.identity}" is connected to the Room`);

            participant.tracks.forEach(publication => {
                if (publication.isSubscribed) {
                  const track = publication.track;
                  self.refs.removeMedia.append(track.attach());
                }
            });

            participant.on('trackSubscribed', track => {
                self.refs.removeMedia.append(track.attach());
            });
        });

        // Log new Participants as they connect to the Room
        room.once('participantConnected', participant => {
            self.log(`Participant "${participant.identity}" has connected to the Room`);
            participant.tracks.forEach(publication => {
                if (publication.isSubscribed) {
                  const track = publication.track;
                  self.refs.removeMedia.append(track.attach());
                }
            });

            participant.on('trackSubscribed', track => {
                self.refs.removeMedia.append(track.attach());
            });
        });

        // Log Participants as they disconnect from the Room
        room.once('participantDisconnected', participant => {
            console.log(`Participant "${participant.identity}" has disconnected from the Room`);

            // Detach the local media elements
            room.localParticipant.tracks.forEach(publication => {
                const attachedElements = publication.track.detach();
                attachedElements.forEach(element => element.remove());
            });
        });

    }

    attachTracks(tracks, container) {
        tracks.forEach((track)=> {
            container.appendChild(track.attach());
        });
    }

    attachParticipantTracks(participant, container) {
        var tracks = Array.from(participant.tracks.values());
        this.attachTracks(tracks, container);
    }


    detachTracks(tracks) {
        tracks.forEach((track)=> {
            track.detach().forEach((detachedElement)=> {
                detachedElement.remove();
            });
        });
    }

    detachParticipantTracks(participant) {
        var tracks = Array.from(participant.tracks.values());
        this.detachTracks(tracks);
    }


    log(message) {
        var logDiv = this.refs.log;
        logDiv.innerHTML += "<p>&gt;&nbsp;" + message + "</p>";
        logDiv.scrollTop = logDiv.scrollHeight;
    }

    render() {
        return (
            <div>
                <div ref="log" id="log"></div>
                <button>Cancel Call</button>
                <div ref="removeMedia" id="remote-media-div"></div>
                <div id="controls">
                    <div id="preview" style={{width: "200px", height: "200px"}}>
                        <p className="instructions">Hello</p>
                        <div ref="localMedia" id="local-media"></div>
                    </div>
                    <div ref="roomControls">

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    hasCall : state.call.hasCall,
    onCall : state.call.onCall,
    isCalling : state.call.isCalling,
    callerName : state.call.callerName,
    callerId : state.call.callerId,
    hasVideo : state.call.hasVideo,
    callRoom : state.call.callRoom,
    callUUID : state.call.callUUID,
    callDestination : state.call.callDestination
})

export default connect(mapStateToProps, {})(VideoContainer)
