import React from 'react'
import defaultImage from '../../../assets/images/default.png'

const Member = ({ user = {}, isSelected, onSelect, onRemove }) => {

  const name = user.firstName + " " + user.lastName;

  const imgUrl = user.picture ? user.picture.url : defaultImage;

  const className = isSelected ? "border-item layout-item list-group-item-action bg-danger text-white" :
    "border-item layout-item list-group-item-action";

  const handleClick = () => {
    return isSelected ? onRemove(user.objectId) : onSelect(user)
  }

  return (
    <div className={className} style={{ minHeight: '62px' }} onClick={handleClick}>
      <img src={imgUrl}
        alt=""
        className="profile-image rounded-circle mr-2"
        style={{ width: "30px", height: "30px" }} />
      <p className="name m-0">{name}</p>
    </div>
  )
}

export default Member
