import React, { Component, useEffect } from "react";
import LoadingSpinner from '../../../components/Conversation/Bubble/LoadingSpinner'
import Item from "../Item";
import _ from 'lodash'
import Parse from 'parse';
import Loader from "../../Common/Loader";
import { shallowEqual, useSelector } from 'react-redux';

function ContactList(props) {

    const {isLoading} = useSelector(state => ({
        isLoading: state.contact.isLoading,
    }));
    
    let { typeValue, contacts, isSearchingContact, filterResult, otherUsersResult, isLoadingSearch, itemType = 'contact', requests = [] } = props;

    let containerStyle = {
        border: "0px solid white",
        minHeight: "50px",
        height: "50px",
        borderRadius: "0px",
        padding: "0"
    };

    let tempContacts;
    if (isSearchingContact) {
        tempContacts = [...filterResult]
    } else {
        tempContacts = [...contacts];
    }

    let Items = tempContacts.map((data, i) => {
        let contactData = {};
        if (isSearchingContact) {
            contactData.contact = data;
            contactData.objectId = data.objectId;
        } else {
            contactData = data;
        }

        return <Item key={data.objectId} data={contactData} type={itemType} createThread={props.createThread} history={props.history} />
    });

    let RequestItems = requests.map(roster => {
        let contactData = {};
        contactData.contact = roster.user;
        contactData.objectId = roster.user.objectId;
        return <Item key={roster.objectId} data={contactData} type={'contact_request'} createThread={props.createThread} history={props.history} />
    });

    if (isLoadingSearch) {
        Items = [];
    }

    let _OtherUsers = [];

    if (isSearchingContact) {
        _OtherUsers = _.differenceBy(otherUsersResult, tempContacts, 'objectId');
    }

    // Filter out currently logged in user from Others
    const currentUserId = Parse.User.current().id

    let Others = _OtherUsers.filter(u => u.objectId != currentUserId);

    Others = Others.map((data, i) => {
        if (data.objectId === Parse.User.current().id) return null;
        let contactData = {};
        if (isSearchingContact) {
            contactData.contact = data;
            contactData.objectId = data.objectId;

        } else {
            contactData = data;
        }

        return <Item key={data.objectId} data={contactData} type={itemType} createThread={props.createThread} history={props.history} />
    })

    return (
        <div className="list-group modified-collection">
            {isLoadingSearch ?
                <a key="group-load-more" style={containerStyle} className="list-group-item list-group-item-action flex-column align-items-start">
                    <LoadingSpinner top={"0"} />
                </a>
                : ''
            }
            {!isLoadingSearch ?
                <div>
                    {!isSearchingContact && requests.length ?
                        <div>        
                            <div className="hc-primary-text" style={{ marginLeft: "20px", fontWeight: "500" }}>
                                CONTACT REQUESTS
                            </div>
                            {RequestItems}
                            <hr className="my-3"/>
                        </div> : ''
                    }
                    {isSearchingContact ? <div className="hc-primary-text" style={{ marginLeft: "20px", fontWeight: "500" }}>
                        MY CONTACTS
                        </div>:
                        <div className="hc-primary-text" style={{ marginLeft: "20px", fontWeight: "500" }}>
                        MY CONTACTS
                        </div>}
                    {Items}
                    {isSearchingContact && !Items.length ?
                        <div className="text-empty">No results</div>
                        : ''
                    }
                    {props.isLoadingMoreContacts && <a key="group-load-more" style={containerStyle} className="list-group-item list-group-item-action flex-column align-items-start">
                        <LoadingSpinner top={"0"} />
                    </a>}
                    <br />
                    {isSearchingContact && <div className="hc-primary-text" style={{ marginLeft: "20px", fontWeight: "500" }}>
                        NOT IN MY CONTACT LIST
                        </div>}
                    {Others}
                    {isSearchingContact && !Others.length ?
                        <div className="text-empty">No results</div>
                        : ''
                    }
                </div>
                : ''
            }

            <Loader isLoading={isLoading} />
        </div>
    );
}

export default ContactList;
