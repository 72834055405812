import React, { Component } from 'react'

export class index extends Component {
    constructor() {
        super();
        this.state = {
            mmsUrl: '',
            defaultScale: 1,
            defaultDeg: 360,
            cantBeDrag: true,
            images: [],
            currentIndex: 0,
            openPopover: false,
            imageTransform: 'scale(1)',
            containerTransform: 'rotate(360deg)',
        }

        this.onClose = this.onClose.bind(this);
    }

    componentDidMount() {
        if (this.props.isOpen) {
            this.refs.filePreview.style.display = "block";
        } else {
            this.refs.filePreview.style.display = "none";
        }

        this.setState({
            mmsUrl: this.props.mmsUrl
        });
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        // Are we adding new items to the list?
        // Capture the scroll position so we can adjust scroll later.
        if (prevProps.isOpen != this.props.isOpen) {
            if (this.props.isOpen) {
                this.refs.filePreview.style.display = "block";
            } else {
                this.refs.filePreview.style.display = "none";
            }

            this.setState({
                mmsUrl: this.props.mmsUrl,
            });
        }
        return null;
    }

    onClose() {
        this.refs.filePreview.style.display = "none";
        this.props.onClose();
    }

    downloadFile() {
        
        let url = this.state.mmsUrl

        if (!url) return;

        // Source: http://pixelscommander.com/en/javascript/javascript-file-download-ignore-content-type/

        //iOS devices do not support downloading. We have to inform user about this.
        if (/(iP)/g.test(navigator.userAgent)) {
            alert('Your device does not support files downloading. Please try again in desktop browser.');
            window.open(url, '_blank');
            return false;
        }

        //If in Chrome or Safari - download via virtual link click
        if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1 || navigator.userAgent.toLowerCase().indexOf('safari') > -1) {
            //Creating new link node.
            var link = document.createElement('a');
            link.href = url;
            link.setAttribute('target', '_blank');

            if (link.download !== undefined) {
                //Set HTML5 download attribute. This will prevent file from opening if supported.
                var fileName = url.substring(url.lastIndexOf('/') + 1, url.length);
                link.download = fileName;
            }

            //Dispatching click event.
            if (document.createEvent) {
                var e = document.createEvent('MouseEvents');
                e.initEvent('click', true, true);
                link.dispatchEvent(e);
                return true;
            }
        }

        // Force file download (whether supported by server).
        if (url.indexOf('?') === -1) {
            url += '?download';
        }

        window.open(url, '_blank');

        return true;
    }

    zoomIn(e) {

        const {defaultScale} = this.state
        
        if (defaultScale === 4) return;

        const newScale = defaultScale + 1;

        const imageTransform = `scale(${newScale})`

        this.setState({
            defaultScale: newScale,
            imageTransform,
        });
    }

    zoomOut(e) {

        const {defaultScale} = this.state
        
        if (defaultScale === 1) return;

        const newScale = defaultScale - 1;

        const imageTransform = `scale(${newScale})`

        this.setState({
            defaultScale: newScale,
            imageTransform,
        });
    }

    rotate(direction) {
        // 360 default up
        // 90 right
        // 180 down
        // -90  left
        let deg = [360, 90, 180, -90];
        let rotateTo = ""
        let index = ""
        if (direction === "left") {
            index = deg.indexOf(this.state.defaultDeg);
            if (index === 0) {
                rotateTo = deg[deg.length - 1];
            } else {
                rotateTo = deg[index - 1];
            }
        } else if (direction === "right") {
            index = deg.indexOf(this.state.defaultDeg);
            if (index === deg.length - 1) {
                rotateTo = deg[0];
            } else {
                rotateTo = deg[index + 1];
            }
        }
        
        const containerTransform = `rotate(${rotateTo}deg)`;
        
        this.setState({
            defaultDeg: rotateTo,
            containerTransform
        });
    }

    render() {

        const {
            mmsUrl,
            imageTransform,
            containerTransform,
        } = this.state;

        const imageStyles = {
            maxWidth: "100%",
            maxHeight: "calc(100vh - 150px)",
            transform: imageTransform,
            transformOrigin: "center top"
        };

        const containerStyles = {
            transform: containerTransform
        };
        
        return (
            <div ref="filePreview" className="File-Preview-Modal">
                
                <div className="Action-Container">
                    <a onClick={this.downloadFile.bind(this)} href="#!" className="File-Action-Button no-underline">
                        <i className="fas fa-download mr-2"></i>
                        <span style={{ fontSize: "18px" }}>Download</span>
                    </a>

                    <a onClick={this.zoomIn.bind(this)} href="#!" className="File-Action-Button no-underline">
                        <i className="fas fa-search-plus mr-2"></i>
                        <span style={{ fontSize: "18px" }}>Zoom in</span>
                    </a>

                    <a onClick={this.zoomOut.bind(this)} href="#!" className="File-Action-Button no-underline">
                        <i className="fas fa-search-minus mr-2"></i>
                        <span style={{ fontSize: "18px" }}>
                            Zoom out
                        </span>
                    </a>
                    <a onClick={this.rotate.bind(this, "left")} href="#!" className="File-Action-Button">
                        <i className="fas fa-undo"></i>
                    </a>
                    <a onClick={this.rotate.bind(this, "left")} href="#!" className="File-Action-Button">
                        <i className="fas fa-undo fa-flip-horizontal"></i>
                    </a>
                    <button className="File-Action-Button" onClick={this.onClose} style={{ float: "right", position: "relative", top: "0px", right: "5px", width: "35px" }}><img style={{ width: "100%" }} src={require("../../assets/images/close_file_preview.png")} /></button>
                </div>

                <div className="File-Preview-Modal-Content2">
                    <div className="row justify-content-md-center mr-0 ml-0" style={{ height: '100%' }}>
                        <div className="col-md-8 p-0">
                            <div className="File-Preview-Container" style={{backgroundColor: 'unset'}}>
                                <div className="Main-Image" style={containerStyles}>
                                    <img ref="primaryImage" draggable="false" src={mmsUrl} style={imageStyles} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default index
