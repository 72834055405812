import React, { useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Modal from "../../Common/Modal";
import OverlayLoader from "../../Common/OverlayLoader";
import {
  tagCircle,
  untagCircle,
  tagRecent,
  untagRecent,
  setLabelNotes,
} from "../../../actions/ThreadAction";
import { getDashboard } from "../../../actions/DashboardAction";

const TagsModal = ({ isOpen, onClose, recent = {} }) => {
  const [tagNotes, setTagNotes] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const { circleLabels, isLoadingDashboard } = useSelector(
    mySelector,
    shallowEqual
  );

  const handleTagRecent = (isActive, labelId) => {
    const action = isActive
      ? tagRecent(recent.objectId, labelId)
      : untagRecent(recent.objectId, labelId);

    dispatch(action);
  };

  const handleTagCircle = (isActive, labelId) => {
    const action = isActive
      ? tagCircle(recent.threadId, labelId)
      : untagCircle(recent.threadId, labelId);

    dispatch(action);
  };

  const handleTag = useMemo(() => {
    return recent.threadType === "private" ? handleTagRecent : handleTagCircle;
  }, [recent]);

  const handleSave = async () => {
    setIsLoading(true);

    await dispatch(setLabelNotes(recent.threadId, tagNotes));

    setIsLoading(false);
    onClose();
  };

  useEffect(() => {
    const { labelNotes = "" } = recent;

    setTagNotes(labelNotes);
  }, [recent]);

  useEffect(() => {
    dispatch(getDashboard());
  }, []);

  return (
    <Modal shown={isOpen} onHide={onClose}>
      <div className="modal-header">
        <h4 className="modal-title text-primary text-truncate">
          <i className="fad fa-hashtag mr-1"></i>
          Tag: {recent.groupName}
        </h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div className="modal-body" style={{ height: "100%" }}>
        <div className="row">
          {circleLabels.map((label) => (
            <div className="col-md-6 mb-4">
              <Label
                label={label}
                activeLabels={recent.labels}
                onTag={handleTag}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="modal-body">
        <label
          className="hc-primary-text"
          for="tag-notes"
          style={{ fontSize: "14px" }}
        >
          Tag notes:
        </label>
        <textarea
          id="tag-notes"
          className="form-control"
          placeholder="Enter tag notes..."
          style={{ resize: "none" }}
          value={tagNotes}
          onChange={(e) => setTagNotes(e.target.value)}
        />
      </div>

      <div className="modal-footer py-0" align="right">
        <button
          onClick={(e) => onClose()}
          type="button"
          className="btn btn-hubchart btn-hubchart-close my-3"
        >
          Close
        </button>
        <button
          disabled={isLoading}
          onClick={(e) => handleSave()}
          type="button"
          className="btn btn-hubchart btn-hubchart-primary my-3"
        >
          {isLoading ? (
            <div class="spinner-border spinner-border-sm" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          ) : (
            <span>Save</span>
          )}
        </button>
      </div>
      <OverlayLoader isLoading={isLoadingDashboard} />
    </Modal>
  );
};

const Label = ({ label, activeLabels = [], onTag }) => {
  const [isActive, setActive] = useState(false);

  const borderClass = isActive ? "border-primary" : "border-grey";
  const textClass = isActive ? "text-primary" : "text-grey";

  const handleChecked = (isActive) => {
    onTag(isActive, label);
  };

  useEffect(() => {
    const isActive = !!activeLabels.find((l) => l.objectId === label.objectId);

    setActive(isActive);
  }, [label, activeLabels]);

  return (
    <label
      className={`border ${borderClass} rounded p-2 d-flex align-items-center justify-content-between`}
    >
      <div className={`${textClass} w-100 text-truncate text-left`}>
        <i className="fas fa-hashtag mr-1"></i>
        {label.name}
      </div>
      <input
        checked={isActive}
        onChange={(e) => handleChecked(e.target.checked)}
        type="checkbox"
      />
    </label>
  );
};

const mySelector = (state) => ({
  circleLabels: state.dashboard.circleLabels,
  isLoadingDashboard: state.dashboard.isLoading,
});

export default TagsModal;
