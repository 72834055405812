import React, { useEffect } from "react";
import moment from "moment";
import {
  fetchSelectedThread,
  forwardMessage,
  fetchGroupMessages
} from "../../../../actions/ThreadAction";
import {
  fetchOrganization,
  fetchphysiciansAndEditors
} from "../../../../actions/SessionAction";
import {
  createNewPrescriptionHistory,
  createNewOrderHistory
} from "../../../../actions/PatientAction";
import { MedicationComponent } from "./MedicationComponent";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Notify from "../../../Common/Notify";

const MedicationView = ({ threadId = "", ...props }) => {
  const dispatch = useDispatch();
  const { selectedThread, organization, physicians, editors } = useSelector(
    mySelector,
    shallowEqual
  );

  useEffect(() => {
    if (threadId) {
      fetchSelectedThread(threadId)(dispatch);
    }
    fetchOrganization()(dispatch);
  }, [threadId]);

  function handleCreateNewPrescriptionHistory(data, circleId) {
    createNewPrescriptionHistory(
      data,
      circleId
    )(dispatch)
      .then(res => {
        Notify.success("Prescription successfully generated.");
        window.open(
          `${window.location.origin}/prescription-history/${threadId}/${res.objectId}`,
          "_blank"
        );
      })
      .catch(error => {
        console.log(error);
      });
  }

  function handleForwardMessages(data, circleId) {
    createNewPrescriptionHistory(
      data,
      circleId
    )(dispatch)
      .then(res => {
        Notify.success("Prescription successfully generated.");
        window.open(
          `${window.location.origin}/prescription-history/${threadId}/${res.objectId}`,
          "_blank"
        );
      })
      .catch(error => {
        console.log(error);
      });
  }

  function handleForwardMessage(message, thread) {
    forwardMessage(
      message,
      thread
    )(dispatch)
      .then(res => {
        fetchGroupMessages(
          thread.objectId,
          moment().toDate(),
          thread.objectId,
          false,
          true
        )(dispatch);
      })
      .catch(() => {});
  }

  function handleFetchOrganization(objectId) {
    fetchphysiciansAndEditors(objectId)(dispatch);
  }

  return (
    <div className="componentContainer">
      {selectedThread.objectId && organization.objectId && (
        <MedicationComponent
          {...props}
          thread={selectedThread}
          threadId={threadId}
          createNewPrescriptionHistory={handleCreateNewPrescriptionHistory}
          fetchphysiciansAndEditors={handleFetchOrganization}
          forwardMessage={handleForwardMessage}
          organization={organization}
          physicians={physicians}
          editors={editors}
        />
      )}
    </div>
  );
};

const mySelector = state => ({
  selectedThread: state.thread.selectedThread,
  physicians: state.session.physicians,
  organization: state.session.organization,
  editors: state.session.editors
});

export default MedicationView;
