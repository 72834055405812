import { parseAction } from "../actions/REST";
import config from "../config";
import Parse from "parse";

const getProviders = async () => {
  const method = "post";

  const url = config.BASE_URL + "/parse/functions/getInsuranceProviders";

  const res = await parseAction(method, url);

  return res.result.items;
};

const addProvider = provider => {
  const InsuranceProvider = Parse.Object.extend("InsuranceProvider");

  const parseObject = new InsuranceProvider(provider);

  return parseObject.save().then(result => result.toJSON());
};

export default {
  getProviders,
  addProvider
};
