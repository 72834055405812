import React, { Component } from 'react'
import LoadingSpinner from '../../../components/Conversation/Bubble/LoadingSpinner'
import Item from '../Item'

export class GroupList extends Component {

    render() {
        let { typeValue, groups, isSearchingGroup, filterGroupResult, isLoadingMoreSearch, isLoadingMoreGroups } = this.props;
        let tempGroups
        if (isSearchingGroup) {
            tempGroups = [...filterGroupResult]
        } else {
            tempGroups = [...groups];
        }

        let containerStyle = {
            border: "0px solid white",
            minHeight: "50px",
            height: "50px",
            borderRadius: "0px",
            padding: "0"
        };

        const Items = tempGroups.map((data) => {
            return <Item key={data.objectId} data={data} type="group" createThread={this.props.createThread} history={this.props.history}/>
        });

        return (
            <div className="list-group modified-collection">
                { this.props.isLoadingSearch ?
                    <a key="group-load-more" style={containerStyle} className="list-group-item list-group-item-action flex-column align-items-start">
                        <LoadingSpinner top={"0"}/>
                    </a>
                    : ''
                }

                {
                    !this.props.isLoadingSearch ?
                    <span>
                        { Items }
                        {(isLoadingMoreGroups || isLoadingMoreSearch) && <a key="group-load-more" style={containerStyle} className="list-group-item list-group-item-action flex-column align-items-start">
                            <LoadingSpinner top={"0"}/>
                        </a>}
                        { !Items.length && <div className="text-empty">No results</div>}
                    </span>
                    : ''
                }
            </div>
        )
    }
}

export default GroupList
