import React, { useCallback, useState } from 'react';
import Parse from 'parse';

const useProvider = () => {
  
  const [name, setName] = useState();

  const getParseObject = useCallback(() => {

    const InsuranceProvider = Parse.Object.extend('InsuranceProvider');

    return new InsuranceProvider({name});
    
  }, [name]);

  return {
    name,
    setName,
    getParseObject,
  }
};

export default useProvider;

