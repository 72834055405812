import React, { useCallback, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Notify from './Notify';

const useConference = () => {

  const dispatch = useDispatch();

  const { host, localUser } = useSelector(state => ({
    host: state.activeConference.host,
    localUser: state.activeConference.localUser
  }), shallowEqual);

  const setHost = (host) => {
    dispatch({
      type: 'SET_HOST',
      host
    });
  }

  const setLocalUser = (localUser) => {
    dispatch({
      type: 'SET_LOCAL_USER',
      localUser
    });
  }

  const isHost = useMemo(() => {

    return (host && localUser) ?
      host.objectId === localUser.id :
      false;

  }, [host, localUser]);

  return {
    isHost,
    host,
    setHost,
    localUser,
    setLocalUser,
  }
}

export default useConference;
