import React from 'react';

const Item = ({onSelect, contact}) => {

  let Name;

  /**
  * default values for contacts
  */
  var contactURL;

  Name = contact.firstName + " " + contact.lastName;

  if (typeof contact.picture === "undefined") {
      contactURL = require("../../../../../assets/images/default.png");
  } else {
      contactURL = contact.picture.url;
  }

  return (
      <div className="border-item layout-item">
          <img src={contactURL}
              alt=""
              className="profile-image rounded-circle mr-2"
              style={{width: "30px", height: "30px"}}/>
          <p className="name m-0">{Name}</p>
          <button onClick={onSelect} className="btn btn-primary btn-sm ml-auto hc-bg-primary">Select</button>
      </div>
  )
}

export default Item;
