import React, { useEffect, useState } from 'react';
import {
  clinicInitial,
  clinicFollow_up,
  clinicTelemed,
  homeInitial,
  homeFollow_up,
  homeTelemed,
  assistedLiving,
  groupHome,
  domicillaryTelemed,
  nursingHomeNew,
  nursingHomeFfup,
  nursingHomeTelemed,
} from './config';

import './index.css';

function CptCodeComponent({
  encounterType = '',
  cptCode = '',
  posCode = '',
  modifierCode = '',
  updateCpt = () => {},
  isDisabled = false,
}) {
  const [startState_SlctBoxOne, setSubOptions] = useState(clinicInitial);
  const [state_POScode, setPosOptions] = useState(clinicInitial[0].options_POScode);
  const placeOfService = [
    {
      optGrpTitle_SlctBoxOne: "CLINIC ENCOUNTER",
      options: [
        {
          label: "Clinic initial visit",
          startState_SlctBoxOne: clinicInitial,
          modifierCode: '',
        },
        {
          label: "Clinic FF-UP visit",
          startState_SlctBoxOne: clinicFollow_up,
          modifierCode: '',
        },
        {
          label: "Telehealth (Pt @ Clinic)",
          startState_SlctBoxOne: clinicTelemed,
          posCode: 'POS-02',
          modifierCode: 'modifier-95',
        }
      ],
    },
    {
      optGrpTitle_SlctBoxOne: "HOME VISIT",
      options: [
        {
          label: "Home initial visit",
          startState_SlctBoxOne: homeInitial,
        },
        {
          label: "Home FF-UP visit ",
          startState_SlctBoxOne: homeFollow_up,
        },
        {
          label: "Telehealth (Pt @ Home)",
          startState_SlctBoxOne: homeTelemed,
          modifierCode: 'modifier-95'
        }
      ],
    },

    {
      optGrpTitle_SlctBoxOne: "DOMICILLARY ENCOUNTER",
      options: [
        {
          label: "ASSISTED LIVING",
          startState_SlctBoxOne: assistedLiving,
          modifierCode: '',
        },
        {
          label: "GROUP HOME",
          startState_SlctBoxOne: groupHome,
          modifierCode: '',
        },
        {
          label: "Telehealth ALF / Grp Home",
          startState_SlctBoxOne: domicillaryTelemed,
          modifierCode: 'modifier-95'
        }
      ],
    },
    {
      optGrpTitle_SlctBoxOne: "NURSING HOME (SNF)",
      options: [
        {
          label: "INITIAL VISIT, Nursing Home ",
          startState_SlctBoxOne: nursingHomeNew,
          modifierCode: '',
        },
        {
          label: "FF-UP VISIT, Nursing Home ",
          startState_SlctBoxOne: nursingHomeFfup,
          modifierCode: '',
        },
        {
          label: "Telehealth (Pt @ Nursing home)",
          startState_SlctBoxOne: nursingHomeTelemed,
          modifierCode: 'modifier-95'
        }
      ],
    }
  ];

  function handleEncounterTypeChange (e) {
    const { name, value } = e.target;
    let subOptions = [];
    let posOptions = [];
    let modifier = '';

    for (let i = 0; i < placeOfService.length; i++) {
      for (let s = 0; s < placeOfService[i].options.length; s++) {
        if (placeOfService[i].options[s].label === value) {
          subOptions = placeOfService[i].options[s].startState_SlctBoxOne;
          posOptions = placeOfService[i].options[s].startState_SlctBoxOne[0].options_POScode;
          modifier = placeOfService[i].options[s].modifierCode;
        }
      }
    }

    updateCpt(name, value, subOptions, posOptions, modifier);
  };

  function handleCptChange (e) {
    const { name, value } = e.target;
    updateCpt(name, value);
  }
  
  useEffect(() => {
    let subOptions = [];
    let posOptions = [];
    let modifier = '';
    for (let i = 0; i < placeOfService.length; i++) {
      for (let s = 0; s < placeOfService[i].options.length; s++) {
        if (placeOfService[i].options[s].label === (encounterType)) {
          subOptions = placeOfService[i].options[s].startState_SlctBoxOne;
          posOptions = placeOfService[i].options[s].startState_SlctBoxOne[0].options_POScode;
        }
      }
    }
    setSubOptions(subOptions);
    setPosOptions(posOptions);

    return () => {}
  }, [encounterType]);

  return (
    <div className="custom-form-group-container">
      <div className="custom-form-group" style={{ maxWidth: '200px', width: '100%' }}>
        <span htmlFor="encounterType">Encounter Type</span>
        <select
          id="encounterType" 
          name="encounterType" 
          type="text" 
          className="form-control" 
          placeholder="Encounter Type" 
          onChange={e => handleEncounterTypeChange(e)}
          disabled={isDisabled}
          value={encounterType}>
          <option disabled value="">Select encounter type</option>
          {placeOfService.map((group) => (
            <optgroup label={group.optGrpTitle_SlctBoxOne}>
              {group.options.map(option => (
                <option value={option.label}>{option.label}</option>
              ))}
            </optgroup>
          ))}
        </select>
      </div>
      <div className="custom-form-group" style={{ maxWidth: '200px', width: '100%' }}>
        <span htmlFor="cptCode">CPT Code</span>
        <select
          id="cptCode" 
          name="cptCode" 
          type="text" 
          className="form-control" 
          placeholder="CPT Code" 
          value={cptCode}
          disabled={isDisabled}
          onChange={e => handleCptChange(e)}>
          <option disabled value="">Select CPT code</option>
          {startState_SlctBoxOne.map(group => (
            <optgroup label={group.cptOptGrp_label}>
              {group.options.map(option => (
                <option value={option}>{option}</option>
              ))}
            </optgroup>
          ))}
        </select>
      </div>
      <div className="custom-form-group" style={{ maxWidth: '200px', width: '100%' }}>
        <span htmlFor="posCode">POS Code</span>
        <select
          id="posCode" 
          name="posCode" 
          type="text" 
          className="form-control" 
          placeholder="POS Code" 
          value={posCode}
          disabled={isDisabled}
          onChange={e => handleCptChange(e)}>
          <option disabled value="">Select POS code</option>
          {state_POScode.map(option => (
            <option value={option}>{option}</option>
          ))}
        </select>
      </div>
      <div className="custom-form-group" style={{ maxWidth: '200px', width: '100%' }}>
        <span htmlFor="modifierCode">Modifier</span>
        <input 
          id="modifierCode" 
          name="modifierCode" 
          type="text" 
          className="form-control" 
          placeholder="Modifier" 
          onChange={e => handleCptChange(e)}
          value={modifierCode || ''}
          disabled={isDisabled} />
      </div>
    </div>
  );
}

export default CptCodeComponent;