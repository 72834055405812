import React, { useEffect, useState } from 'react';

const $ = window.$;

function ConfirmModal({
  isOpen = false,
  event = {},
  onClose = () => {},
  title = '',
  message = '',
}) {

  const modalId = '#confirm_modal';
  
  useEffect(() => {
    componentDidMount();
  }, [isOpen, event]);

  function componentDidMount() {
    if (isOpen) {
      $(modalId).modal('show');
    } else {
      $(modalId).modal('hide');
    }
  }

  function handleClose () {
    $(modalId).modal('hide');
  }

  return (
    <div id="confirm_modal" className="modal fade" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title hc-primary-text" style={{ fontSize: "1.25rem" }}>
                {title}
              </h5>
              <button 
                type="button" 
                className="close" 
                aria-label="Close"
                onClick={() => handleClose()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <i 
                  className="fas fa-fw fa-question-circle fa-lg hc-primary-text" 
                  style={{ marginRight: '5px' }}></i>
                <span>{message}</span>
              </div>
            </div>
            <div className="modal-footer" align="right">
              <div className="form-group" style={{ textAlign: "right" }}>
                <button 
                  type="button" 
                  style={{ marginRight: "10px" }} 
                  className="btn btn-hubchart btn-hubchart-primary"
                  onClick={() => {
                    handleClose();
                    onClose(event);
                  }}>Yes</button>
                <button 
                  type="button" 
                  className="btn btn-hubchart btn-hubchart-close" 
                  data-dismiss="modal" 
                  onClick={() => {
                    handleClose();
                    onClose();
                  }}>No</button>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
}

export default ConfirmModal;
