// Import: Dependencies
import React, { Component } from 'react'

// Import: Components
import TabSearch from '../TabSearch'
import Item from '../Item'
import LoadingSpinner from '../../../components/Conversation/Bubble/LoadingSpinner'

// Import: Style
import './PatientListStyle.css'

const $ = window.$;

export class LabelNavContainer extends Component {
    constructor() {
        super();
        this.state = {
            openAccount : false,
            typeValue : "",
            willFetchPatients : false,
            label : {}
        }
    }

    componentWillUnmount() {
        this.closeNav();
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        if (this.props.isOpen) {

            let fetchGroup = false;
            if (!this.state.label) {
                if (!this.props.label.isFetching) {
                    fetchGroup = true;
                    if (this.props.label.groups) {
                        if (this.props.label.groups.length !== 0) {
                            fetchGroup = false;
                        }
                    }
                }
            } else if(this.state.label.objectId !== this.props.label.objectId) {
                if (!this.props.label.isFetching) {
                    fetchGroup = true;
                    if (this.props.label.groups) {
                        if (this.props.label.groups.length !== 0) {
                            fetchGroup = false;
                        }
                    }
                }
            }

            if (fetchGroup) this.fetchGroups();

            this.openNav();
        } else {
            this.closeNav();
        }
        return null;
    }

    componentDidUpdate() {

    }

    fetchGroups() {
        if (!this.state.willFetchPatients) {
            this.setState({
                willFetchPatients : true,
                label : this.props.label
            })
            this.props.fetchPatients("patient", false, 0, this.props.label.className, this.props.label.objectId, this.props.label.columnName).then((groups) => {
                this.setState({
                    willFetchPatients : false,
                    label : {
                        ...this.state.label,
                        groups : groups
                    }
                })
            });
        }
    }


    openNav() {
        this.refs.mySidenav.style.width = "350px";
        this.refs.blur.style.width = "100%";
    }

    closeNav() {
        this.refs.mySidenav.style.width = "0";
        this.refs.blur.style.width = "0";

    }

    handleCloseNav() {
        this.props.closeNav();
    }

    typing(value) {
        this.setState({
            typeValue : value
        })
    }

    render() {
        const label = this.props.label;
        let className = "";
        if (label.className) className = label.className.toLowerCase();

        let Items = []
        if (this.state.label.groups) {
            Items = this.state.label.groups.map((data) => {
                return <Item key={data.objectId} data={data} type="chart" createThread={this.props.createThread} history={this.props.history}/>
            });
        }

        return (
            <div>
                <div ref="blur" className="blur" onClick={this.handleCloseNav.bind(this)}></div>
                <div ref="mySidenav" className="Label-Nav-Container">
                    <a href="#!" className="closebtn" onClick={this.handleCloseNav.bind(this)}>
                        <i className="fas fa-times"></i>
                    </a>
                    <div className="row" style={{width: "100%", margin: "0px"}}>
                        <div className="col-12">
                            <h4 className="hc-primary-text" style={{display: "block", margin: "0"}}>{label.name}</h4>
                            <span style={{ color: "#00000087"}}>{label.className}</span>
                        </div>

                    </div>

                    <div className="row" style={{width: "100%", margin: "0px"}}>
                        <div className="col-12">
                         <TabSearch activeTab={className} tabContainerStyle={{margin: "10px 0 20px 0"}} typeValue={this.state.typeValue} typing={this.typing.bind(this)}/>
                        </div>
                    </div>

                    <div className="row" style={{width: "100%", margin: "0px"}}>
                        <div className="col-12">
                            { Items }
                            {this.state.willFetchPatients && <LoadingSpinner top={"0"}/> }
                        </div>
                    </div>



                </div>
            </div>
        )
    }
}

export default LabelNavContainer
