import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchContacts, getContacts } from "../../../actions/ContactAction";
import { parseAction } from "../../../actions/REST";
import config from "../../../config";
import Notify from "../../Common/Notify";
import TabLoader from "../../Common/TabLoader";
import OverlayPlaceholder from "../../Common/OverlayPlaceholder";
import Parse from "parse";

const $ = window.$;

export class AddMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tempContacts: [],
      initialLoad: false,
      selectedContact: [],
      filterMembers: [],
      isSearching: false,
      isLoading: false,
      searchTimeout: null,
    };
  }

  componentDidMount() {
    // console.log('filteredConnta:', this.props.members);
    
    if (this.props.members.length === 0) {
      
      this.setState({
        initialLoad: false,
      });

    } else {
      this.setState({
        tempContacts: [
          ...this.props.admins,
          ...this.props.members,
          ...this.props.guests
        ],
        filterMembers: [
          ...this.props.admins,
          ...this.props.members,
          ...this.props.guests
        ],
        initialLoad: true,
      });
    }
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    
    if (this.props.members.length !== 0) {
      if (!this.state.initialLoad) {

        this.setState({
          tempContacts: [
            ...this.props.admins,
            ...this.props.members,
            ...this.props.guests
          ],
          filterMembers: [
            ...this.props.admins,
            ...this.props.members,
            ...this.props.guests
          ],
          initialLoad: true,
        });
      }
    }
  }

  componentDidUpdate() {}

  inviteContacts() {
    this.props.inviteMember(this.state.selectedContact);

    this.setState({
      selectedContact: [],
    });
  }

  selectMember(contact) {
    let duplicate = false;
    let newSelectedContact = this.state.selectedContact;

    newSelectedContact.map((data, index) => {
      if (data.objectId === contact.objectId) {
        newSelectedContact.splice(index, 1);
        duplicate = true;
      }
    });

    if (!duplicate) {
      newSelectedContact = [...this.state.selectedContact, contact];
    }

    this.setState({
      selectedContact: newSelectedContact,
    });
  }

  filterMembers() {
    const { searchTimeout } = this.state;
    let text = this.input.value;

    if (text !== "") {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }

      const newSearchTimeout = setTimeout(() => {
        this.searchMember(text);
      }, 500);

      this.setState({ searchTimeout: newSearchTimeout });
    } else {
      this.setState({
        filterMembers: [...this.state.tempContacts],
        isSearching: false,
      });
    }
  }

  searchMember(text) {
    this.setState({
      isSearching: true,
      filterMembers: [],
      isLoading: true,
    });

    let self = this;

    parseAction("post", config.BASE_URL + "/parse/functions/searchOrgMembers", {
      search: text,
    })
      .then((res) => {
        const { orgMembers } = res.result;

        const userId = Parse.User.current().id;

        if (self.state.isSearching) {
          self.setState({
            filterMembers: this.excludeOwner(orgMembers, userId),
          });
        }

        self.setState({ isLoading: false });
      })
      .catch((err) => {
        console.log(err);

        this.setState({
          isLoading: false,
        });

        Notify.error("Failed to search contacts");
      });
  }

  excludeOwner(list, owner) {
    return list.filter((user) => user.objectId != owner);
  }

  render() {
    const List = this.state.filterMembers.map((data, i) => {
      let contact = data;
      if (!contact) return null;

      return (
        <Item
          key={i}
          contact={contact}
          selectMember={this.selectMember.bind(this, contact)}
        />
      );
    });

    const { isLoading } = this.state;

    return (
      <>
        <div className="modal-body py-0">
          <div className="py-3" ref={(h) => (this.headerContainer = h)}>
            <div ref={(t) => (this.topContainer = t)} style={{ width: "100%" }}>
              <div className="Tab-Search-Container m-0">
                <i className="fas fa-search"></i>
                <input
                  onChange={this.filterMembers.bind(this)}
                  id="search"
                  placeholder="Search Member"
                  ref={(i) => (this.input = i)}
                  type="search"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal-body py-0"
          style={{ height: "70%", overflowY: "auto" }}
        >
          <div className="mycard-1 py-0">
            <div className="list-group">
              {List}
              <OverlayPlaceholder
                show={List.length === 0 && !isLoading}
                text={"No Member found"}
              />
            </div>
          </div>
          <TabLoader isLoading={isLoading} />
        </div>
        <div className="modal-footer">
          <div style={{ display: "block" }}></div>
          <button
            disabled={!this.state.selectedContact.length}
            type="button"
            className="btn btn-hubchart my-3"
            onClick={this.inviteContacts.bind(this)}
          >
            Save member(s)
          </button>
        </div>
      </>
    );
  }
}

const Item = (props) => {
  let Name;

  var contact = props.contact,
    contactURL;
  var type = contact.type;
  var desig = contact.designation;

  Name = contact.displayName;

  if (typeof contact.picture === "undefined") {
    contactURL = require("../../../assets/images/default.png");
  } else {
    contactURL = contact.picture.url;
  }

  return (
    <div className="border-item layout-item">
      <img
        src={contactURL}
        alt=""
        className="profile-image rounded-circle mr-2"
        style={{ width: "30px", height: "30px" }}
      />
      <div>
        <p className="name m-0" style={{color: '#000000'}}>
          {Name} 
          {desig ? (' ('+ desig + ')') : ('')}
        </p>
        <span className="text-secondary" style={{fontSize: "0.8em"}}>{type}</span>
      </div>
      <div>
        <input
          type="checkbox"
          className="ml-auto"
          style={{ transform: "scale(2)" }}
          onClick={() => props.selectMember()}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  contacts: state.contact.contacts,
});

export default connect(mapStateToProps, { fetchContacts, getContacts })(
  AddMember
);
