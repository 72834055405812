import React, { useEffect, useMemo } from 'react';
import Notify from '../../../components/Common/Notify';
import Parse from 'parse';

const ConferenceToastContainer = ({ conference = {} }) => {

  const joinConference = (roomId) => {

    const conferenceUrl = [
      window.location.origin,
      "/conferencecall",
      "?roomId=" + roomId,
      "&hasVideo=false",
    ].join('');

    window.open(conferenceUrl, "Hubchart Conference Call", "status=1,menubar=0");
  }

  useEffect(() => {

    if (!conference.roomId) return;

    const { participants } = conference;

    const imageUrl = require("../../../assets/images/default.png");;

    /* const pics = participants.map(p => {
      const url = p.picture ? p.picture.url : imageUrl;

      return (
        <div key={p.objectId} className="img-conference-wrapper">
          <img src={url} width="35" height="35" />
        </div>
      );
    }); */

    const roomId = conference.roomId;

    const content = (
      <span className="text-white">
        <div className="hc-nobreak">
          <i className="fas fa-video-plus mr-1"></i>
          <strong className="mr-1">You have an active call</strong>
        </div>
        <Names participants={participants}/>
        <div>
          <button onClick={e => joinConference(roomId)} style={{ color: '#1a5b28' }} className="btn btn-light btn-join-call btn-xs">
            <i className="fas fa-phone mr-1"></i>
            Join
          </button>
        </div>
      </span>
    );

    const toastId = Notify.success(content, {
      autoClose: false,
      closeOnClick: false,
      closeButton: true,
    });

    return () => {
      Notify.dismiss(toastId);
    }

  }, [conference.roomId]);

  return (
    <div></div>
  );
}

const Names = ({ participants = []}) => {

  const user = Parse.User.current();
  
  const names = useMemo(() => {
    return participants
      .filter(p => p.objectId != user.id)
      .map(p => p.firstName).join(', ');
  }, [participants])
  
  if (!names) return '';

  return (
    <div className="mb-1">
      <a className="">with {names}</a>
    </div>
  )
}

export default ConferenceToastContainer;

