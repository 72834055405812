import React from 'react';
import { Document, Page } from 'react-pdf';

const Pdf = ({ url }) => {
  return (
    <div className='react-pdf-root'>
      <Document file={url} loading={PdfLoader}>
        <Page pageNumber={1} className="react-pdf__Page-canvas-container-2" />
      </Document>
    </div>
  );
}

const PdfLoader = () => {
  return (
    <div>
      <i className="fas fa-circle-notch fa-spin"></i>
      Loading PDF
    </div>
  )
}

export default Pdf;
