import React from 'react'

const Toast = (props) => {
    let url = "";
    if (props.picture) url = props.picture.url;
    return (
        <>
            <img src={url} style={{height: "50px", marginRight: "5px"}}/>
            {props.message}
        </>
    )
}

export default Toast
