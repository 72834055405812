import React, { Fragment, useEffect, useState } from "react";
import Parse from "parse";
import { parseAction } from "../../../../actions/REST";
import moment from "moment";
import Notify from "../../../Common/Notify";
import Loader from "../../../Common/Loader";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  forwardMessage,
  fetchGroupMessages,
} from "../../../../actions/ThreadAction";
import {
  createNewOrder,
  updateOrder,
  createNewOrderHistory,
} from "../../../../actions/PatientAction";
import { fetchphysiciansAndEditors } from "../../../../actions/SessionAction";
import {
  handleMessageFormat,
  handleOrderFormat,
  removeExtraLines,
} from "../../../../helper/util";
import PostOnHubModal from "../../../Conversation/Setting/PrescriptionModal/PostOnHub";
import PrescriptionModal from "../../../Conversation/Setting/PrescriptionModal";
import ForwardModal from "../../../Conversation/Forward/ForwardModal2";
import Spinner from "../../../Common/Spinner";
import Plan from "../ProgressNotesModal/Plan";
import {
  getProgressNotesPlans,
  checkUncheckPlansArray,
} from "../../../../actions/ProgressNotesAction";
import config from "../../../../config";

import "../ProgressNotesModal/index.css";

const $ = window.$;

function OrdersModal({
  title = "",
  thread = {},
  isOpen = false,
  patientId = "",
  onClose = () => {},
  postOnConversation = () => {},
  isPatient,
  patientName = "",
  patientDob = "",
  guests = [],
  options = [],
  toList = false,
  appendOrders = () => {},
  selectedOrder = {},
  isFromPatientOrders = false,
}) {
  const defaultOrder = isFromPatientOrders
    ? selectedOrder
    : {
        prescription: "",
        diagnosticStudies: "",
        referrals: "",
        medicalSupplies: "",
      };
  const dispatch = useDispatch();
  const {
    isUpdating,
    plansArray,
    isOrdersLoading,
    organization,
    physicians,
  } = useSelector(mySelector, shallowEqual);
  const modalId = "#orders_modal";
  const [order, setOrder] = useState(defaultOrder);
  const [rxModalShown, setRxModalShown] = useState(false);
  const [postOnHubModalShown, setPostOnHubModalShown] = useState(false);
  const [forwardModalShown, setForwardModalShown] = useState(false);
  const [canAssignPrescriber, setcanAssignPrescriber] = useState(false);
  const [patientInfo, setPatientInfo] = useState({});
  const [planIds, setPlans] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [hasNoPrescriber, setHasNoPrescriber] = useState(false);

  function handleFetchPhysiciansAndEditors() {
    const { objectId } = organization;
    fetchphysiciansAndEditors(objectId)(dispatch);
  }

  function superAdminChecker() {
    const { owner = {} } = organization;
    const user = Parse.User.current();
    const role = user.get("orgRole");

    if (owner.objectId === user.id || role === "physician") {
      setcanAssignPrescriber(true);
    }
  }

  function handleClose() {
    $(modalId).modal("hide");
  }

  function handleUpdatePlans(name, value) {
    setOrder({
      ...order,
      [name]: value,
    });
  }

  function handleImportedPrescription(meds = []) {
    setOrder({
      ...order,
      prescription: handleMessageFormat(meds),
    });
    setRxModalShown(false);
  }

  function handleUpdatePlansCheckbox(data, checked) {
    const { diagnosticStudies } = order;
    checkUncheckPlansArray(data.objectId, checked, plansArray)(dispatch);
    setOrder({
      ...order,
      diagnosticStudies: checked
        ? addStr(`${diagnosticStudies}% ${data.name}`)
        : removeStr(diagnosticStudies, data.name),
    });
    const ids = filterPlanIds(planIds, data.objectId, checked);
    setPlans(ids);

    function filterPlanIds(ids, id, checked) {
      const pIds = ids;

      if (checked) {
        pIds.push(id);
      } else if (!checked) {
        return pIds.filter((i) => i !== id);
      }
      return pIds;
    }

    function addStr(str) {
      return str
        .split("%")
        .filter(Boolean)
        .join(";");
    }

    function removeStr(ds, str) {
      const strData = ds.replace(str, "");
      return strData
        .split(";")
        .filter((entry) => {
          return entry.trim() !== "";
        })
        .join(";");
    }
  }

  function handlePostOnHubModal(bool) {
    setPostOnHubModalShown(bool);
  }

  function handlePostOnConversation() {
    const text = removeExtraLines(handleOrderFormat(order));
    postOnConversation(text);
    handleClose();
    onClose();
  }

  function handleForwardMessageModal(bool = false) {
    setForwardModalShown(bool);
  }

  function handlePostMessageOnHub(message, thread) {
    forwardMessage(
      message,
      thread
    )(dispatch)
      .then(() => {
        fetchGroupMessages(
          thread.objectId,
          moment().toDate(),
          thread.objectId,
          false,
          true
        )(dispatch);
      })
      .catch((err) => {
        console.log("ERROR: ", err);
      });
  }

  function handleOrdersText(data) {
    const newData = `PATIENT NAME: ${patientName ||
      "--"}\nPATIENT DOB: ${patientDob || "--"}\n\n${removeExtraLines(
      handleOrderFormat(data)
    )}`;
    return newData;
  }

  function previewOrders() {
    localStorage.setItem("orders", JSON.stringify(order));
    const prescriptionUrl = `${window.location.origin}/orders/${patientId}`;
    window.open(prescriptionUrl, "Hubchart Orders");
  }

  function createOrders() {
    if (!patientInfo.prescriber) {
      setHasNoPrescriber(true);
      Notify.error("Please select a prescriber for the order.");
      return;
    } else {
      createNewOrder(order, patientId, () => {
        Notify.success("Order successfully created.");
        localStorage.removeItem("orders");
        localStorage.removeItem("someModalIsOpen");
        handleClose();
        onClose();
      })(dispatch);
    }
  }

  function createOrdersShortcut() {
    if (!patientInfo.prescriber) {
      setHasNoPrescriber(true);
      Notify.error("Please select a prescriber for the order.");
      return;
    }
    createNewOrder(order, patientId, (objectId, data) => {
      createNewOrderHistory(data, objectId, patientId, (response) => {
        Notify.success("Order successfully generated.");
        window.open(
          `${window.location.origin}/orders-history/${patientId}/${response.objectId}`,
          "_blank"
        );
        localStorage.removeItem("orders");
        localStorage.removeItem("someModalIsOpen");
        handleClose();
        onClose();
      })(dispatch);

      // Notify.success('Order successfully created.');
    })(dispatch);
  }

  function updateOrders() {
    updateOrder(order, patientId, () => {
      Notify.success("Order successfully updated.");
      localStorage.removeItem("orders");
      localStorage.removeItem("someModalIsOpen");
      handleClose();
      onClose();
    })(dispatch);
  }

  function handleRefreshData() {}

  function fetchPatientInfo() {
    parseAction("get", config.BASE_URL + "/parse/classes/Circle", {
      where: {
        objectId: patientId,
      },
    })
      .then((result) => {
        if (result.results.length === 0) {
          return;
        }

        var data = {
          ...result.results[0],
          prescriber: result.results[0].prescriber
            ? result.results[0].prescriber.objectId
            : "",
        };
        setPatientInfo(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function selectPrescriber(event) {
    const { value } = event.target;

    const User = Parse.Object.extend("_User");
    const Circle = Parse.Object.extend("Circle");

    const prescriber = new User({ objectId: value });

    let newCircle = new Circle({
      objectId: patientInfo.objectId,
      prescriber,
    });

    savePrescriber(newCircle);
  }

  const savePrescriber = async (circle) => {
    setIsloading(true);
    try {
      const prescriber = await circle.save();
      Notify.success("Prescriber updated for patient.");
      fetchPatientInfo();
      setHasNoPrescriber(false);
      setIsloading(false);
    } catch (err) {
      console.error(err);
      setIsloading(false);
    }
  };

  useEffect(() => {
    componentDidMount();
    getProgressNotesPlans(planIds)(dispatch);
    handleFetchPhysiciansAndEditors();
    superAdminChecker();
    fetchPatientInfo();
  }, [isOpen]);

  function componentDidMount() {
    if (isOpen) {
      localStorage.setItem("someModalIsOpen", "true");
      $(modalId).modal({
        backdrop: "static",
        keyboard: false,
      });
      $(modalId).modal("show");
    } else {
      $(modalId).modal("hide");
    }
  }

  return (
    <div id="orders_modal" className="modal fade" tabIndex="-1" role="dialog">
      <div
        className="modal-dialog"
        role="document"
        style={{ maxWidth: "700px" }}
      >
        <div
          className="modal-content for-orders modal-full-height"
          style={{ overflowY: "auto" }}
        >
          <div className="modal-body" style={{ padding: "0px" }}>
            <div className="pn-container bypass-dropdown">
              <Plan
                planIds={planIds}
                plansArray={plansArray}
                labs={order}
                openPrescription={() => setRxModalShown(true)}
                updatePlans={handleUpdatePlans}
                updatePlansCheckbox={handleUpdatePlansCheckbox}
                setPrescriptionToText={handleImportedPrescription}
                isPatient={isPatient}
                patientName={patientName}
                patientDob={patientDob}
                thread={thread}
                guests={guests}
                options={options}
                toList={toList}
                appendOrders={appendOrders}
              />
              {canAssignPrescriber && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    flexDirection: "row",
                  }}
                >
                  <div style={{ padding: "0px 15px", width: "200px" }}>
                    <span style={{ fontSize: "12px" }}>Prescribed by: </span>
                    <select
                      id="prescription-prescriber"
                      className="form-control"
                      value={patientInfo.prescriber}
                      onChange={(e) => selectPrescriber(e)}
                    >
                      <option value="">None</option>
                      {physicians.map((physician, index) => (
                        <option key={index} value={physician.objectId}>
                          {physician.displayName}
                        </option>
                      ))}
                    </select>
                  </div>
                  {hasNoPrescriber && (
                    <span style={{ paddingBottom: "5px", color: "#e74a3b" }}>
                      Prescriber is required.
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <div className="form-group orders-modal-flex">
              {!toList && (
                <Fragment>
                  <div>
                    {isPatient && (
                      // <button
                      //   type="button"
                      //   className="btn btn-hubchart btn-hubchart-Primary flexnowrap"
                      //   onClick={() => handlePostOnHubModal(true)}
                      // >
                      //   <i className="fas fa-pills"></i>
                      //   <span className="d-none d-sm-block d-sm-none d-md-block">
                      //     Post on Hub
                      //   </span>
                      // </button>
                      <div />
                    )}
                    {!isPatient && (
                      <button
                        type="button"
                        className="btn btn-hubchart btn-hubchart-Primary flexnowrap"
                        onClick={() => handlePostOnConversation(true)}
                      >
                        <i className="fas fa-pills"></i>
                        <span className="d-none d-sm-block d-sm-none d-md-block">
                          Post on Conversation
                        </span>
                      </button>
                    )}
                  </div>
                  <button
                    type="button"
                    className="btn btn-hubchart btn-hubchart-Primary flexnowrap"
                    onClick={() => handleForwardMessageModal(true)}
                  >
                    <i className="fas fa-share-square"></i>
                    <span className="d-none d-sm-block d-sm-none d-md-block">
                      Forward
                    </span>
                  </button>
                  {isPatient && (
                    <button
                      type="button"
                      className="btn btn-hubchart btn-hubchart-primary flexnowrap"
                      style={{ marginLeft: "auto" }}
                      disabled={
                        !order.prescription &&
                        !order.diagnosticStudies &&
                        !order.referrals &&
                        !order.medicalSupplies
                      }
                      onClick={() => createOrdersShortcut()}
                    >
                      <i className="fas fa-plus"></i>
                      <span className="d-none d-sm-block d-sm-none d-md-block">
                        Add to Order
                      </span>
                    </button>
                  )}
                </Fragment>
              )}
              {toList && (
                <button
                  type="button"
                  className="btn btn-hubchart btn-hubchart-primary flexnowrap"
                  style={{ marginLeft: "auto" }}
                  disabled={
                    !order.prescription &&
                    !order.diagnosticStudies &&
                    !order.referrals &&
                    !order.medicalSupplies
                  }
                  onClick={() => {
                    if (selectedOrder.objectId) {
                      updateOrders();
                    } else {
                      createOrders();
                    }
                  }}
                >
                  <i className="fas fa-plus"></i>
                  <span className="d-none d-sm-block d-sm-none d-md-block">
                    {selectedOrder.objectId ? "Save Orders" : "Add to Orders"}
                  </span>
                </button>
              )}
              <button
                type="button"
                className="btn btn-hubchart btn-hubchart-close flexnowrap"
                onClick={() => {
                  localStorage.removeItem("orders");
                  localStorage.removeItem("someModalIsOpen");
                  handleClose();
                  onClose();
                }}
              >
                <i className="fas fa-times"></i>
                <span className="d-none d-sm-block d-sm-none d-md-block">
                  Close
                </span>
              </button>
            </div>
          </div>
          {isUpdating ||
            (isOrdersLoading && (
              <div className="tab-loading">
                <Spinner size={5} color={"#fbba09"} loading={true} />
              </div>
            ))}
        </div>
      </div>
      {rxModalShown && (
        <PrescriptionModal
          threadId={patientId}
          isOpen={rxModalShown}
          onClose={() => setRxModalShown(false)}
          refreshData={() => handleRefreshData()}
          importPrescription={(data) => handleImportedPrescription(data)}
          fromOrders={true}
        />
      )}
      {postOnHubModalShown && (
        <PostOnHubModal
          title="Forward Orders"
          isOpen={postOnHubModalShown}
          modalClose={() => handlePostOnHubModal(false)}
          thread={thread}
          message={order}
          forwardMessage={(message, thread) =>
            handlePostMessageOnHub(message, thread)
          }
          patientName={thread.groupName || "No Name"}
          isOrder={true}
        />
      )}
      {forwardModalShown && (
        <ForwardModal
          isOpen={forwardModalShown}
          onClose={handleForwardMessageModal}
          messages={[{ text: handleOrdersText(order) }]}
        />
      )}
      <Loader isLoading={isLoading} />
    </div>
  );
}

const mySelector = (state) => ({
  isUpdating: state.notes.isUpdating,
  plansArray: state.notes.plans,
  isOrdersLoading: state.patient.isOrdersLoading,
  organization: state.session.organization,
  physicians: state.session.physicians,
});

export default OrdersModal;
