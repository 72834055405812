import React, { useState, useEffect, useRef } from 'react';
import NetworkSignal from './NetworkSignal';
import ScreenVideo from './ScreenVideo';
import ConnectionStatus from './ConnectionStatus';
import Twilio, { createLocalVideoTrack } from 'twilio-video';
import Notify from './Notify';
import MicIndicator from './MicIndicator';
import CamIndicator from './CamIndicator';

const videoTrackOptions = {
  video: { name: 'camera', width: 853 }
};

const LocalParticipant = ({
  localTracks,
  user,
  videoShared,
  audioShared,
  screenShared,
  setScreenShared,
  setVideoShared,
  localParticipant,
  room,
}) => {

  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [screenTrack, setScreenTrack] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState(0);
  const [networkQualityLevel, setNetworkQualityLevel] = useState(-1);

  const videoRef = useRef();

  useEffect(() => {

    if (!localTracks) return;

    setVideoTracks(() => {
      return localTracks.filter(track => track.kind === 'video');
    });

    setAudioTracks(() => {
      return localTracks.filter(track => track.kind === 'audio');
    });

    // Before unmounting component,
    return () => {

      // Reset audio and video tracks
      setVideoTracks([]);
      setAudioTracks([]);
    };
  }, [localTracks]);

  useEffect(() => {

    const videoTrack = videoTracks[0];

    // Attach video track to <video> in DOM
    if (videoTrack) {

      videoTrack.attach(videoRef.current);

      return () => {
        // And detach it upon unmount
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    videoTracks.forEach(track => {
      if (videoShared) track.enable();
      else track.disable();
    });
  }, [videoShared]);

  useEffect(() => {
    audioTracks.forEach(track => {
      if (audioShared) track.enable();
      else track.disable();
    });
  }, [audioShared]);

  useEffect(() => {

    const createScreenTrack = async () => {

      const stream = await navigator.mediaDevices.getDisplayMedia();

      const screenTrack = new Twilio.LocalVideoTrack(stream.getTracks()[0], { name: 'screen' });

      return screenTrack;
    }

    const handleEnd = screenTrack => {
      screenTrack.mediaStreamTrack.onended = () => {
        localParticipant.unpublishTrack(screenTrack);
        setScreenTrack(null);
        setScreenShared(false);
      };
    }

    const publishTrack = screenTrack => {
      localParticipant.publishTrack(screenTrack);
    }

    const initScreenTrack = async () => {

      try {
        const screenTrack = await createScreenTrack();

        setScreenTrack(screenTrack);

        handleEnd(screenTrack);

        publishTrack(screenTrack);

        setVideoShared(false);
      }
      catch (err) {
        if (err instanceof DOMException) {
          switch (err.name) {
            case 'NotAllowedError': return setScreenShared(false);
          }
        }

        Notify.error('Screen sharing not supported by browser');

        setScreenShared(false);
      }
    }

    const removeScreenTrack = () => {
      if (screenTrack) {
        setScreenTrack(null);
        localParticipant.unpublishTrack(screenTrack);
      }
    }

    if (screenShared) initScreenTrack();
    else removeScreenTrack();

  }, [screenShared]);

  useEffect(() => {

    /* 
      HERE: When the user enabled his video but
      has not yet published a video track
    */
    if (videoShared && !videoTracks.length) {

      // Create a video track
      createLocalVideoTrack(videoTrackOptions)
        .then(videoTrack => {

          // Add video track to state,
          // so that it can be played locally
          setVideoTracks(videoTracks => [
            ...videoTracks,
            videoTrack,
          ]);

          // Publish it so it can be played remotely
          localParticipant.publishTrack(videoTrack);
        });
    }

  }, [videoShared, videoTracks]);

  useEffect(() => {

    // If there is a screenTrack, unpublish all 
    // videoTrack where name='camera'
    if (screenTrack) {
      videoTracks.forEach(track => {
        localParticipant.unpublishTrack(track);
      });
    }

    // If there is no screenTrack
    // then re publish videoTrack where name='camera'
    else {
      videoTracks.forEach(track => {
        // Make sure the track is disabled before republishing
        track.disable();
        localParticipant.publishTrack(track);
      });
    }
  }, [screenTrack]);

  useEffect(() => {

    if (room) setConnectionStatus(1);

    return () => {
      setConnectionStatus(-1);
    }
  }, [room]);

  useEffect(() => {

    // If inside the room, set signal level to maximum
    if (room) {
      setNetworkQualityLevel(5);
    }

    return () => {

      // Then set to zero upon leaving
      setNetworkQualityLevel(0);
    }

  }, [room]);

  const zIndex = videoShared ? 1 : 2;

  user = user || {};

  const { imgUrl, thumbUrl, displayName } = user;

  return (<>
    {screenTrack ?
      <div className="camera-container" style={{ zIndex: 3 }}>
        <ScreenVideo screenTrack={screenTrack} />
      </div> : ''
    }
    <div className="camera-container">
      <video ref={videoRef} autoPlay={true} />
    </div>
    <div className="user-thumbnail">
      <div className="px-2 participant-name">
        <NetworkSignal level={networkQualityLevel} />
        {displayName}
        <span className="float-right">
          <CamIndicator available={videoTracks.length} enabled={videoShared} />
          <MicIndicator available={audioTracks.length} enabled={audioShared} />
        </span>
      </div>
    </div>
    <div className="camera-placeholder" style={{ zIndex }}>
      <div className="profile-image-wrapper-x">
        <div className="field-content">
          <img src={imgUrl} alt="" className="profile-image rounded-circle img" />
        </div>
      </div>
      <img src={thumbUrl} alt="" className="bg" />
    </div>
  </>);
};

export default LocalParticipant;
