import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchContacts, getContacts } from '../../../actions/ContactAction'
import { parseAction } from '../../../actions/REST'
import config from '../../../config'
import Notify from '../../Common/Notify'
import TabLoader from '../../Common/TabLoader'

const $ = window.$;

export class AddMember extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tempContacts: [],
            initialLoad: false,
            selectedContact: [],
            filterContacts: [],
            isSearching: false,
            isLoading: false,
            searchTimeout: null,
        }
    }

    componentDidMount() {
        if (this.props.contacts.length === 0) {

            this.setState({isLoading: true});

            this.props.fetchContacts()
            .then(() => {
                this.setState({isLoading: false});
            })
            .catch(err => {
                this.setState({isLoading: false});

                Notify.error('Failed to fetch contacts');
            })
        } else {
            let newTemp = [
                ...this.props.contacts.filter((data) => {
                    var _find = this.props.members.find(member => {
                        if (!member || !data.contact) {
                            return false;
                        }
                        return member.objectId === data.contact.objectId
                    });

                    if (_find) {
                        return false
                    }
                    return true
                })
            ]
            this.setState({
                tempContacts: newTemp,
                filterContacts: newTemp,
                initialLoad: true
            })
        }
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        if (this.props.contacts.length !== 0) {
            if (!this.state.initialLoad) {
                let newTemp = [
                    ...this.props.contacts.filter((data) => {
                        var _find = this.props.members.find(member => {
                            if (!member || !data.contact) {
                                return false;
                            }
                            return member.objectId === data.contact.objectId
                        });

                        if (_find) {
                            return false
                        }
                        return true
                    })
                ]

                this.setState({
                    tempContacts: newTemp,
                    filterContacts: newTemp,
                    initialLoad: true
                })
            }

        }
        return null;
    }

    componentDidUpdate() {

    }

    inviteContacts() {
        this.props.inviteMember(this.state.selectedContact);

        this.setState({
            selectedContact: []
        })

    }

    selectedContact(contact) {

        let newSelectedContact = [...this.state.selectedContact, contact];

        let filterContactsTemp = [...this.state.filterContacts.filter((data) => {
            if (this.state.isSearching) {
                return data.objectId !== contact.objectId;
            } else {
                if (!contact || !data.contact) {
                    return false;
                }
                return data.contact.objectId !== contact.objectId;
            }
        })]

        this.setState({
            // tempContacts : newTempContact,
            filterContacts: filterContactsTemp,
            selectedContact: newSelectedContact,
        })
    }

    removeSelected(contact) {
        let newSelectedContact = [...this.state.selectedContact.filter((data) => {
            return data.objectId !== contact.objectId;
        })];

        let contactData = {};
        let filterContactsTemp;
        if (this.state.isSearching) {
            filterContactsTemp = [contact, ...this.state.filterContacts]
        } else {
            contactData.contact = contact;
            filterContactsTemp = [contactData, ...this.state.filterContacts]
        }

        this.setState({

            filterContacts: filterContactsTemp,
            selectedContact: newSelectedContact,
        })
    }

    filterContacts() {
        
        const {searchTimeout} = this.state;
        let text = this.input.value;

        if (text !== "") {

            if (searchTimeout) {
                clearTimeout(searchTimeout);  
            }

            const newSearchTimeout = setTimeout(() => {
                this.searchContacts(text);
            }, 500);

            this.setState({searchTimeout: newSearchTimeout});
        } else {
            this.setState({
                filterContacts: [...this.state.tempContacts],
                isSearching: false
            })
        }
    }

    searchContacts(text) {

        this.setState({
            isSearching: true,
            filterContacts: [],
            isLoading: true,
        })
        
        let self = this;

        parseAction("post", config.BASE_URL + '/parse/functions/searchContactsV2',
            {
                filter: text
            }
        )
        .then(res => {

            const {rosters} = res.result;

            const contacts = rosters.map(r => r.contact);

            if (self.state.isSearching) {
                self.setState({
                    filterContacts: contacts,
                });
            }

            self.setState({isLoading: false});
        })
        .catch(err => {

            console.log(err);

            this.setState({
                isLoading: false,
            });

            Notify.error('Failed to search contacts');
        });
    }

    render() {
        const List = this.state.filterContacts.map((data, i) => {

            let contact;
            if (this.state.isSearching) {
                contact = data;
            } else {
                contact = data.contact;
            }

            if (!contact) return null;

            return <Item key={i} contact={contact} selectedContact={this.selectedContact.bind(this, contact)} />
        })

        const Selected = this.state.selectedContact.map((contact, i) => {
            return <Chips key={i} contact={contact} removeSelected={this.removeSelected.bind(this, contact)} />
        });

        const {isLoading} = this.state;

        return (
            <>
                <div className="modal-body py-0">
                    <div className="py-3" ref={h => this.headerContainer = h}>
                        <div ref={t => this.topContainer = t} style={{ width: "100%" }}>
                            <div className="Tab-Search-Container m-0">
                                <i className="fas fa-search"></i>
                                <input onChange={this.filterContacts.bind(this)} id="search" placeholder="Search Contact" ref={i => this.input = i} type="search" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-body py-0" style={{ height: '100%', overflowY: 'auto' }}>
                    <div className="mycard-1 py-0">
                        <div className="list-group">
                            {List}
                        </div>
                    </div>
                    <TabLoader isLoading={isLoading}/>
                </div>
                <div className="modal-footer py-0">
                    <div style={{ display: "block" }}>
                        {Selected}
                    </div>
                    <button
                        disabled={!this.state.selectedContact.length}
                        type="button"
                        className="btn btn-hubchart my-3"
                        onClick={this.inviteContacts.bind(this)}>
                        Invite contact(s)
                    </button>
                </div>
            </>
        )
    }
}

const Chips = (props) => {
    var contact = props.contact
    let contactURL;

    if (typeof contact.picture === "undefined") {
        contactURL = require("../../../assets/images/default.png");
    } else {
        contactURL = contact.picture.url;
    }

    return (
        <div className="chips">
            <img src={contactURL} className="chip-img" alt={contact.displayName} />
            <span>{contact.firstName}</span>
            <i onClick={() => props.removeSelected()} className="close-chip far fa-times-circle"></i>
        </div>
    )
}

const Item = (props) => {

    let Name;

    /**
    * default values for contacts
    */
    var contact = props.contact,
        contactURL;

    Name = contact.firstName + " " + contact.lastName;

    if (typeof contact.picture === "undefined") {
        contactURL = require("../../../assets/images/default.png");
    } else {
        contactURL = contact.picture.url;
    }

    return (
        <div className="border-item layout-item">
            <img src={contactURL}
                alt=""
                className="profile-image rounded-circle mr-2"
                style={{width: "30px", height: "30px"}}/>
            <p className="name m-0">{Name}</p>
            <button onClick={() => props.selectedContact()} className="btn btn-primary btn-sm ml-auto">Select</button>
        </div>
    )
}

const mapStateToProps = state => ({
    contacts: state.contact.contacts
})

export default connect(mapStateToProps, { fetchContacts, getContacts })(AddMember);
