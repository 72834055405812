import {
  TEAM_FETCH_STATUS,
  NEW_TEAM,
  TEAM_NEW_NAME,
  NEW_TEAM_MEMBERS,
  REMOVE_TEAM_MEMBER,
} from "./types";
import Parse from "parse";
import { parseAction } from "./REST";
import config from "../config";

export const fetchMyTeams = (userObjectId) => (dispatch) => {
  dispatch({
    type: TEAM_FETCH_STATUS,
    status: "FETCHING",
  });

  const currentUser = Parse.User.current();
  parseAction("get", config.BASE_URL + "/parse/classes/Team", {
    where: {
      user: {
        __type: "Pointer",
        className: "_User",
        objectId: currentUser.id,
      },
    },
    include: "members",
  })
    .then((teams) => {
      dispatch({
        type: TEAM_FETCH_STATUS,
        status: "SUCCESS",
        teams: teams.results,
      });
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: TEAM_FETCH_STATUS,
        status: "ERROR",
      });
    });
};

export const createNewTeam = (teamName) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let Team = Parse.Object.extend("Team");
    let newTeam = new Team();

    let User = Parse.Object.extend("User");
    let currentUser = new User();
    currentUser.id = Parse.User.current().id;

    newTeam.set("user", currentUser);
    newTeam.set("name", teamName);

    newTeam.save().then(
      (savedTeam) => {
        let _results = JSON.stringify(savedTeam);
        _results = JSON.parse(_results);
        dispatch({
          type: NEW_TEAM,
          status: "SUCCESS",
          team: _results,
        });
        resolve(_results);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export const removeTeamMember = (memberObjectId, teamObjectId) => (
  dispatch
) => {
  return new Promise((resolve, reject) => {
    let Team = Parse.Object.extend("Team");
    let newTeam = new Team();
    newTeam.id = teamObjectId;

    let User = Parse.Object.extend("User");
    let currentUser = new User();
    currentUser.id = memberObjectId;

    newTeam.remove("members", currentUser);

    newTeam.save().then(
      (savedTeam) => {
        dispatch({
          type: REMOVE_TEAM_MEMBER,
          memberObjectId: memberObjectId,
          teamObjectId: teamObjectId,
        });
        resolve(memberObjectId);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export const updateTeamName = (teamObjectId, newName) => (dispatch) => {
  return new Promise((resolve, reject) => {
    parseAction(
      "put",
      config.BASE_URL + "/parse/classes/Team/" + teamObjectId,
      {
        name: newName,
      }
    )
      .then((team) => {
        dispatch({
          type: TEAM_NEW_NAME,
          teamObjectId: teamObjectId,
          name: newName,
        });
        resolve(team);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const teamNewMembers = (
  newMembers,
  membersOriginalObject,
  teamObjectId
) => (dispatch) => {
  return new Promise((resolve, reject) => {
    // Create the object.
    var Team = Parse.Object.extend("Team");
    var team = new Team();
    team.id = teamObjectId;

    for (let x = 0; x < newMembers.length; x++) {
      team.addUnique("members", newMembers[x]);
    }

    team.save().then(
      (res) => {
        dispatch({
          type: NEW_TEAM_MEMBERS,
          teamObjectId: teamObjectId,
          newMembers: membersOriginalObject,
        });
        resolve(true);
      },
      (error) => {
        // FIXME notif for unsuccessfull invite(delete)
        reject(error);
      }
    );
  });
};
