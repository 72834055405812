import React, { Component } from "react";
import Parse from "parse";
import "../../TabList/TabList.css";
import { withRouter } from "react-router-dom";
import {
  createConferenceCall,
  launchTelemed
} from "../../../actions/ConferenceAction";
import Notify from "../../Common/Notify";
import ConferenceHelper from "../../../helper/conference";
import { connect } from "react-redux";
import Loader from "../../Common/Loader";

const $ = window.$;

export class Participant extends Component {
  constructor() {
    super();

    this.state = {
      isLoading: false,
      loadingMessage: ""
    };

    this.handleCall = this.handleCall.bind(this);
    this.createConference = this.createConference.bind(this);
    // this.openCallWindow = this.openCallWindow.bind(this);
    this.setLoading = this.setLoading.bind(this);
  }

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  handleClick(e) {
    const { member } = this.props;

    e.preventDefault();

    this.props.history.push(`/u/${member.objectId}`);

    this.props.onClick(e);
  }

  handleCall(hasVideo) {
    const { member } = this.props;

    const partnerObjectId = member.objectId;

    this.createConference([partnerObjectId], hasVideo);
  }

  createConference(participantIds, hasVideo) {
    // Show loading indicator with message
    this.setLoading(true, "Starting conference...");

    return this.props.launchTelemed(participantIds);
  }

  setLoading(isLoading, loadingMessage) {
    this.setState({
      isLoading,
      loadingMessage
    });
  }

  render() {
    const { member, selected } = this.props;

    const user = member;
    let containerStyle = {
      border: "0px solid white",
      paddingLeft: "60px",
      minHeight: "45px"
    };

    let Image,
      Name,
      nameStyle = {
        marginTop: "4px"
      };

    var contactURL;

    Name = user.firstName + " " + user.lastName;

    if (typeof user.picture === "undefined") {
      contactURL = require("../../../assets/images/default.png");
    } else {
      contactURL = user.picture.url;
    }

    if (member.isAdministrator) {
      nameStyle.marginTop = "0px";
    }

    return (
      <div
        className="border-item participant-item"
        onClick={e => this.props.onClick(member)}
      >
        <div class="round">
          <input
            type="checkbox"
            checked={selected}
            id="checkbox"
            disabled={true}
          />
          <label for="checkbox"></label>
        </div>
        <img
          src={contactURL}
          alt=""
          className="profile-image rounded-circle mr-2"
          style={{ width: "30px", height: "30px" }}
        />
        <p className="name m-0">{Name}</p>
      </div>
    );
  }
}

const _withRouter = withRouter(Participant);

const mapStateToProps = state => ({});

export default connect(mapStateToProps, {
  createConferenceCall,
  launchTelemed
})(_withRouter);
