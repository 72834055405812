import React from 'react'

const MicIndicator = ({ available, enabled }) => {

  const colorClass = available ? 'text-white' : 'text-danger';
  const iconClass = (available && enabled) ? 'fa-microphone' : 'fa-microphone-slash';

  const classNames = [
    'fas',
    'fa-fw',
    iconClass,
    colorClass,
  ].join(' ');

  return (
    <span>
      <i className={classNames}></i>
    </span>
  );
}

export default MicIndicator;