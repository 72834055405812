import React from 'react';
import { toast, ToastContainer, cssTransition } from 'react-toastify';

const defaultOptions = {
    autoClose: 3000,
    closeButton: false,
    hideProgressBar: true,
    position: 'top-left',
};

function loading(message) {

    const content = (
        <span>
            <i className="far fa-circle-notch fa-spin mr-1"></i>
            {message}
        </span>
    );

    toast.warning(content, {
        ...defaultOptions,
        autoClose: false,
        className: 'toast-base custom'
    });
}

function show(message) {

    const content = (
        <span>
            <i className="far fa-info-circle mr-1"></i>
            {message}
        </span>
    );

    toast(content, {
        ...defaultOptions,
        className: 'toast-base custom'
    });
}

function error(message) {
    const content = (
        <span>
            <i className="fas fa-exclamation-circle mr-1"></i>
            {message}
        </span>
    );

    toast.error(content, {
        ...defaultOptions,
        className: 'toast-base'
    });
}

function success(message) {
    const content = (
        <span>
            <i className="fas fa-check-circle mr-1"></i>
            {message}
        </span>
    );

    toast.success(content, {
        ...defaultOptions,
        className: 'toast-base'
    });
}

function dismiss(id) {
    toast.dismiss(id);
}

function prompt(message, options={}) {

    const {
        autoClose = 10000,
        confirmLabel = 'Ok',
        cancelLabel = 'Cancel',
        onConfirm,
        onCancel,
    } = options;

    const handleCancel = () => {
        closeToast();
        if (onCancel) onCancel();
    }

    const handleConfirm = () => {
        closeToast();
        if (onConfirm) onConfirm();
    }

    const content = (
        <div className="d-flex flex-column">
            <div className="d-flex align-items-center my-1">
                <span className="text-primary">
                    <i className="fas fa-question-circle mr-1"></i>
                    {message}
                </span>
            </div>
            <div className="d-flex justify-content-end">   
                <button onClick={handleCancel} style={{borderRadius: '25px'}} className="btn btn-light btn-sm mr-1">{cancelLabel}</button>
                <button onClick={handleConfirm} style={{borderRadius: '25px'}} className="btn btn-primary btn-sm">{confirmLabel}</button>
            </div>
        </div>
    );

    const toastId = toast(content, {
        ...defaultOptions,
        className: 'toast-base prompt',
        autoClose,
    });

    function closeToast () {
        toast.dismiss(toastId);
    }
}

export default {
    toast,
    show,
    error,
    success,
    loading,
    dismiss,
    prompt,
    ToastContainer,
}
