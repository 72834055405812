import React, { useState } from "react";

const $ = window.$;

function RequirePasswordESign({
  onClose = () => {},
  message = "No message",
  isValidating = false
}) {
  const [otp, setOTP] = useState("");

  const handleEsignPDF = () => {
    onClose(otp);
  };

  return (
    <div className="save-changes-container">
      <div className="save-changes-box">
        <i className="fas fa-exclamation-circle hc-primary-text fa-2x"></i>
        <div className="save-changes-flex">
          <h5>One Time Password Required</h5>
          <span style={{ fontSize: "12px" }}>{message}</span>
          <div style={{ margin: "10px 0px" }}>
            <input
              className="form-control"
              type="password"
              autoFocus
              value={otp}
              onChange={e => setOTP(e.target.value)}
            />
          </div>
          <div style={{ textAlign: "right" }}>
            <button
              type="button"
              style={{ marginRight: "10px" }}
              className="btn btn-hubchart btn-hubchart-primary"
              disabled={isValidating}
              onClick={() => {
                handleEsignPDF();
              }}
            >
              Submit
            </button>
            <button
              type="button"
              className="btn btn-hubchart btn-hubchart-close"
              onClick={() => onClose(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequirePasswordESign;
