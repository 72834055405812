import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchStripePlan } from "../../../../actions/SubscriptionAction";
import Parse from "parse";
import CertifiedSubscription from "./CertifiedSubscription";
import AffiliatedSubscription from "./AffiliatedSubscription";
import CorporateSubscription from "./CorporateSubscription";
import PaymentContainer from "./PaymentContainer";

const $ = window.$;
export class SubscriptionContainer extends Component {
  constructor() {
    super();
    this.state = {
      showSubscription: "",
      fetchingSubscriptions: true,
      errorFetchingSubscriptions: false,
      plans: [],
      openPayment: false,
    };
    this.getPlan = this.getPlan.bind(this);
  }

  componentDidMount() {
    this.getPlan();
    // this.fetchStripePlan('regular');
    this.props.fetchStripePlan("regular");
  }

  // async fetchStripePlan(planId) {
  //     await new Promise((resolve, reject) => {
  //         stripe.plans.retrieve(
  //             planId,
  //             function(err, plan) {
  //                 console.log(plan)
  //             }
  //         );
  //     })
  // }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (this.state.showSubscription !== "") {
      if (this.props.openAccount === false) {
        this.setState({
          openPayment: false,
          showSubscription: "",
        });
      }
    }

    return null;
  }

  componentDidUpdate() {}

  showDetail(subscription) {
    this.setState({
      showSubscription: subscription,
    });
  }

  getPlan() {
    this.setState({
      fetchingSubscriptions: true,
    });
    let IAPItem = Parse.Object.extend("IAPItem");
    let query = new Parse.Query(IAPItem);

    query.containedIn("subscriptionGroup", [
      "certification",
      "corporate",
      "provider",
    ]);

    let self = this;
    query
      .find()
      .then(function(results) {
        let _results = JSON.stringify(results);
        _results = JSON.parse(_results);
        self.setState({
          fetchingSubscriptions: false,
          plans: _results,
        });
      })
      .catch(function(error) {
        self.setState({
          fetchingSubscriptions: false,
          errorFetchingSubscriptions: true,
        });
      });
  }

  subscribeToPlan(planId) {
    this.setState({
      openPayment: true,
      planId: planId,
    });
  }

  closePayment() {
    this.setState({
      openPayment: false,
    });
  }

  _renderLayout() {
    if (this.state.openPayment) {
      return (
        <PaymentContainer
          plans={this.state.plans}
          planId={this.state.planId}
          closePayment={this.closePayment.bind(this)}
        />
      );
    }

    if (this.state.showSubscription === "") {
      if (this.state.fetchingSubscriptions) {
        return (
          <>
            <h5 className="hc-primary-text">Subscriptions</h5>
            <span>
              <i className="fas fa-circle-notch fa-spin"></i> Please wait
            </span>
          </>
        );
      }
      if (this.state.errorFetchingSubscriptions) {
        return (
          <>
            <h5 className="hc-primary-text">Subscriptions</h5>
            <p style={{ color: "rgb(216, 75, 85)" }}>
              {" "}
              Can't fetch subscription right now, plase check your internet
              connection or try again later.
            </p>
            <button
              onClick={this.getPlan}
              className="btn btn-hubchart"
              style={{
                width: "131px",
                fontSize: "16px",
                margin: "0 auto",
                display: "block",
              }}
            >
              <i class="fas fa-redo-alt" style={{ marginRight: "5px" }}></i>
              Reload
            </button>
          </>
        );
      }
      return (
        <>
          <h5 className="hc-primary-text">Subscriptions</h5>
          <span>What kind of subscription are you looking for?</span>

          <div
            className="row"
            style={{ padding: "10px 20px", marginTop: "15px" }}
          >
            <button
              onClick={this.showDetail.bind(this, "certified")}
              type="button"
              className="btn btn-hubchart"
              style={{ width: "100%", fontSize: "18px", height: "50px" }}
            >
              Certified User Subscription
            </button>
          </div>

          <div className="row" style={{ padding: "10px 20px" }}>
            <button
              onClick={this.showDetail.bind(this, "affiliated")}
              type="button"
              className="btn btn-hubchart"
              style={{ width: "100%", fontSize: "18px", height: "50px" }}
            >
              Affiliated Provider Subscription
            </button>
          </div>

          <div className="row" style={{ padding: "10px 20px" }}>
            <button
              onClick={this.showDetail.bind(this, "corporate")}
              type="button"
              className="btn btn-hubchart"
              style={{ width: "100%", fontSize: "18px", height: "50px" }}
            >
              Corporate Account Subscription
            </button>
          </div>
        </>
      );
    } else if (this.state.showSubscription === "certified") {
      let cMonthly = this.state.plans.find(
        (plan) =>
          plan.name === "Certified User" &&
          plan.subscriptionPeriod === "monthly"
      );
      let cAnnual = this.state.plans.find(
        (plan) =>
          plan.name === "Certified User" && plan.subscriptionPeriod === "annual"
      );

      return (
        <CertifiedSubscription
          planMonthly={cMonthly}
          planAnnual={cAnnual}
          back={this.showDetail.bind(this)}
        />
      );
    } else if (this.state.showSubscription === "affiliated") {
      let apMonthly = this.state.plans.find(
        (plan) =>
          plan.name === "Preferred Provider" &&
          plan.subscriptionPeriod === "monthly"
      );
      let apAnnual = this.state.plans.find(
        (plan) =>
          plan.name === "Preferred Provider" &&
          plan.subscriptionPeriod === "annual"
      );
      return (
        <AffiliatedSubscription
          planMonthly={apMonthly}
          planAnnual={apAnnual}
          back={this.showDetail.bind(this)}
        />
      );
    } else if (this.state.showSubscription === "corporate") {
      return (
        <CorporateSubscription
          subscribeToPlan={this.subscribeToPlan.bind(this, "corporate")}
          back={this.showDetail.bind(this)}
        />
      );
    }
  }

  render() {
    return (
      <div className="Account-Setting-Container" style={{ minWidth: "450px" }}>
        {this._renderLayout()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, { fetchStripePlan })(
  SubscriptionContainer
);
