import React, { useEffect } from 'react';

const $ = window.$;

function SaveChanges({
  onClose = () => {},
  message = 'No message',
}) {

  return (
    <div className="save-changes-container">
      <div className="save-changes-box">
        <i className="fas fa-question-circle hc-primary-text fa-2x"></i>
        <div className="save-changes-flex">
          <h4>Confirm Navigation</h4>
          <span>{message}</span>
          <div style={{ textAlign: "right" }}>
            <button 
              type="button" 
              style={{ marginRight: "10px" }} 
              className="btn btn-hubchart btn-hubchart-primary"
              onClick={() => onClose(true)}>Yes</button>
            <button 
              type="button" 
              className="btn btn-hubchart btn-hubchart-close" 
              onClick={() => onClose(false)}>No</button>
            </div>
        </div>
      </div>
    </div>
  );
}

export default SaveChanges;
