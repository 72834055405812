import Parse from "parse";

const savePatient = (
  circle,
  primaryInsurance,
  secondaryInsurance,
  otherInsurance,
  serviceLocation
) => {
  // TODO: Fix: Saving patient will also update insurance and serviceLocation
  // causing updatedAt even when no fields were updated
  circle.set("primaryInsurance", primaryInsurance);
  circle.set("secondaryInsurance", secondaryInsurance);
  circle.set("otherInsurance", otherInsurance);
  circle.set("serviceLocation", serviceLocation);

  return circle.save();
};

export default {
  savePatient
};
