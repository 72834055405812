import moment from "moment";
import { parseAction } from "./REST";
import ConferenceApi from "../api/Conference";
import config from "../config";

export const getDashboard = () => (dispatch) => {
  setLoading(true, dispatch);

  return parseAction(
    "post",
    config.BASE_URL + "/parse/functions/getDashboard"
  ).then((response) => {
    const { requests, todoCount } = response.result;

    dispatch({
      type: "UPDATE_DASHBOARD",
      data: {
        requests,
        todoCount,
      },
    });

    setLoading(false, dispatch);
  });
};

export const getRiskManagement = () => (dispatch) => {
  setLoadingRM(true, dispatch);

  return parseAction(
    "post",
    config.BASE_URL + "/parse/functions/getRiskManagement"
  ).then((response) => {
    const { recents } = response.result;

    dispatch({
      type: "SET_RISK_MANAGEMENT",
      recents,
    });

    setLoadingRM(false, dispatch);
  });
};

export const getSchedulesToday = (page = 0, pageSize = 10, currentSchedule = [] ,date = '') => (dispatch) => {
  setLoadingST(true, dispatch);

  return ConferenceApi.getSchedules({
    page: page,
    pageSize: pageSize,
    filter: date || moment().format('YYYY-MM-DD')
  }).then((result) => {
    const { conferences } = result;
    const dateToday = moment().format("l");
    const filteredConferences = conferences; //.filter(conf => (dateToday === moment(conf.dateScheduled.iso).format('l')))

    dispatch({
      type: "SET_SCHEDULES_TODAY",
      conferences: [...currentSchedule, ...filteredConferences] || [],
      allCurrentPage: page
    });

    dispatch({
      type: "SET_LOADING_SCHEDULES",
      value: false,
    });

    dispatch({
      type: "SET_SCHEDULE_HAS_MORE",
      value: result.hasMore
    });

    setLoadingST(false, dispatch);
  });
};

export const getCircleLabels = () => (dispatch) => {
  setLoading(true, dispatch);

  return parseAction(
    "post",
    config.BASE_URL + "/parse/functions/getCircleLabels"
  ).then((response) => {
    const { circleLabels, circleLabelsCount } = response.result;

    dispatch({
      type: "UPDATE_DASHBOARD",
      data: {
        circleLabels,
      },
    });

    dispatch({
      type: "UPDATE_CIRCLE_LABELS",
      circleLabels,
      circleLabelsCount,
    });

    setLoading(false, dispatch);
  });
};

export const getUrgentCount = () => (dispatch) => {
  setLoading(true, dispatch);

  return parseAction(
    "post",
    config.BASE_URL + "/parse/functions/getUrgentCount"
  ).then((response) => {
    const { urgentCount } = response.result;

    dispatch({
      type: "UPDATE_DASHBOARD",
      data: {
        urgentCount
      },
    });

    setLoading(false, dispatch);
  });
};

export const getAttentionCount = () => (dispatch) => {
  setLoading(true, dispatch);

  return parseAction(
    "post",
    config.BASE_URL + "/parse/functions/getAttentionCount"
  ).then((response) => {
    const { attentionCount } = response.result;

    dispatch({
      type: "UPDATE_DASHBOARD",
      data: {
        attentionCount
      },
    });

    setLoading(false, dispatch);
  });
};

export const getReturnCallsCount = () => (dispatch) => {
  setLoading(true, dispatch);

  return parseAction(
    "post",
    config.BASE_URL + "/parse/functions/getReturnCallsCount"
  ).then((response) => {
    const { returnCallCount } = response.result;

    dispatch({
      type: "UPDATE_DASHBOARD",
      data: {
        returnCallCount
      },
    });

    setLoading(false, dispatch);
  });
};

export const getHighPriorityCount = () => (dispatch) => {
  setLoading(true, dispatch);

  return parseAction(
    "post",
    config.BASE_URL + "/parse/functions/getHighPriorityCount"
  ).then((response) => {
    const { highPriorityCount } = response.result;

    dispatch({
      type: "UPDATE_DASHBOARD",
      data: { highPriorityCount },
    });

    setLoading(false, dispatch);
  });
};

export const getMediumPriorityCount = () => (dispatch) => {
  setLoading(true, dispatch);

  return parseAction(
    "post",
    config.BASE_URL + "/parse/functions/getMediumPriorityCount"
  ).then((response) => {
    const { mediumPriorityCount } = response.result;

    dispatch({
      type: "UPDATE_DASHBOARD",
      data: { mediumPriorityCount },
    });

    setLoading(false, dispatch);
  });
};

export const getRoutinePriorityCount = () => (dispatch) => {
  setLoading(true, dispatch);

  return parseAction(
    "post",
    config.BASE_URL + "/parse/functions/getRoutinePriorityCount"
  ).then((response) => {
    const { routinePriorityCount } = response.result;

    dispatch({
      type: "UPDATE_DASHBOARD",
      data: { routinePriorityCount },
    });

    setLoading(false, dispatch);
  });
};

const setLoading = (value, dispatch) => {
  dispatch({
    type: "SET_LOADING",
    value,
  });
};

const setLoadingRM = (value, dispatch) => {
  dispatch({
    type: "SET_LOADING_RISK_MANAGEMENT",
    value,
  });
};

const setLoadingST = (value, dispatch) => {
  dispatch({
    type: "SET_LOADING_SCHEDULES_TODAY",
    value,
  });
};
