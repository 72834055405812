import React, { useEffect, useMemo, Fragment } from "react";
import moment from "moment";
import TextareaAutosize from "react-textarea-autosize";
import { useState } from "react";
import Parse from "parse";
import Modal from "../../../Common/Modal";
import OverlayLoader from "../../../Common/OverlayLoader";
import OverlayPlaceholder from "../../../Common/OverlayPlaceholder";
import { parseAction } from "../../../../actions/REST";
import { calculateAge } from "../../../../helper/util";
import config from "../../../../config";
import { Input, Select, Label, Spinner } from "../../../Common/Forms";
import { useDispatch } from "react-redux";
import {
  createActionItems,
  completeActionItem,
  updateActionItem,
  sendNewMessage,
  randomID,
} from "../../../../actions/ThreadAction";
import ProfilePic from "../../../Common/ProfilePic";
import _ from "lodash";
import PatientInfo from "../PatientInfo";
import Logo from "../../../../assets/images/logo-blue.png";

const ActionsComponent = ({ thread, threadId, activeTab }) => {
  const [circle, setCircle] = useState({});
  const [members, setMembers] = useState([]);
  const [actionItems, setActionItems] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [addItemShown, setAddItemShown] = useState(false);

  const fetchPatientInfo = async (threadId) => {
    const url = config.BASE_URL + "/parse/classes/Circle";

    const params = {
      where: {
        objectId: threadId,
      },
      include: ["actionItems", "actionItems.assignedTo", "members"],
    };

    const res = await parseAction("get", url, params);

    const circle = res.results[0] || {};
    const sorted = sortActionItems(circle.actionItems || []);

    setCircle(circle);
    setActionItems(sorted);
  };

  const fetchMembers = async (threadId) => {
    const url = config.BASE_URL + "/parse/functions/getCircleMembers";

    const params = {
      circleObjectId: threadId,
    };

    const res = await parseAction("post", url, params);

    const { orgAdmins, orgMembers, regMembers } = res.result;

    const allMembers = [...orgAdmins, ...orgMembers, ...regMembers];

    const uniqueMembers = _.uniqBy(allMembers, "objectId");

    setMembers(uniqueMembers);
  };

  const handleNewItem = (actionItem) => {
    setActionItems((actionItems) => {
      return [actionItem, ...actionItems];
    });

    setAddItemShown(false);
  };

  const sortActionItems = (actionItems) => {
    const sorted = _.orderBy(
      actionItems,
      [(obj) => new Date(obj.createdAt)],
      ["desc"]
    );

    return sorted;
  };

  const handleCompleteItem = (itemId) => {
    setActionItems((actionItems) => {
      return actionItems.map((item) => {
        if (item.objectId === itemId) {
          return {
            ...item,
            status: "DONE",
          };
        }

        return item;
      });
    });
  };

  const handleOnAssigneeChange = (item) => {
    const selectedMember = members.filter(
      (itm) => itm.objectId !== item.assignedTo.objectId
    );
    const newActionItems = actionItems.map((itm) => {
      if (itm.objectId === item.objectId) {
        return {
          ...itm,
          assignedTo: selectedMember[0],
        };
      }

      return { ...itm };
    });

    setActionItems(newActionItems);
  };

  const handleOnActionChange = (selItem, val, idx, propName) => {
    let newActionItems = [...actionItems];
    const item = {
      ...selItem,
      [propName]: val,
    };

    newActionItems[idx] = item;
    setActionItems(newActionItems);
  };

  useEffect(() => {
    setLoading(true);

    Promise.all([fetchPatientInfo(threadId), fetchMembers(threadId)])
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [threadId]);

  return (
    <div className="progress-notes-container pn-con componentInnerContainer">
      <div className="container mb-15 pn-title-flex">
        <span className="navbar-brand">
          <img
            className="d-sm-inline"
            width="40"
            height="40"
            src={Logo}
            alt="H"
          ></img>
          <span
            className="hc-primary-text d-none d-md-inline"
            style={{ fontWeight: "500" }}
          >
            HUBCHART
          </span>
        </span>
        <div className="pn-patient-details-flex">
          <div>
            <span>{circle.name || "--"}</span>
            <span>ACTION ITEMS</span>
          </div>
          {(typeof (circle.image || {}).url === "undefined" ||
            (circle.image || {}).url === "") && (
            <img src={require("../../../../assets/images/default.png")} />
          )}
          {(circle.image || {}).url && <img src={(circle.image || {}).url} />}
        </div>
        {/* <span className="pn-title">ACTION ITEMS</span> */}
      </div>
      <div className="pn-template-container">
        <div className="container pb-10">
          <PatientInfo patientInfo={circle} />
        </div>
      </div>
      <div class="container">
        <div>
          <ActionForm
            onNewItem={handleNewItem}
            circleId={threadId}
            setAddItemShown={setAddItemShown}
            addItemShown={addItemShown}
            onCancel={() => setAddItemShown(false)}
            activeTab={activeTab}
            members={members}
            thread={thread}
          />
          <div
            style={{
              width: "100%",
              overflowX: "auto",
              paddingTop: "0",
              paddingBottom: "0",
            }}
          >
            <ActionsTable
              onCompleteItem={handleCompleteItem}
              onAssigneeChange={handleOnAssigneeChange}
              onActionChange={handleOnActionChange}
              actionItems={actionItems}
              members={members}
              activeTab={activeTab}
              thread={thread}
              isLoading={isLoading}
              handleSetAddItemShown={setAddItemShown}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const ModalHeader = () => {
  return (
    <div className="modal-header">
      <h5 className="text-primary mb-0" style={{ fontSize: "1.25rem" }}>
        Action Items
      </h5>
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

const ModalBody = ({ children, style = {} }) => {
  const styles = {
    ...style,
  };

  return (
    <div className="modal-body" style={styles}>
      {children}
    </div>
  );
};

const PatientDetails = ({ patientInfo }) => {
  return (
    <div className="row" style={{ paddingTop: "15px" }}>
      <div className="col-md-6">
        <div className="patient-details">
          <span>
            <i className="fas fa-fw fa-hospital-user"></i> Patient Name:{" "}
          </span>
          <span>{patientInfo.name || "--"}</span>
        </div>
        <div className="patient-details">
          <span>
            <i className="fas fa-fw fa-address-card"></i> Address:{" "}
          </span>
          <span>{patientInfo.address || "--"}</span>
        </div>
        <div className="patient-details">
          <span>
            {" "}
            <i className="fas fa-fw fa-calendar-day"></i> Birth date:{" "}
          </span>
          <span>{`${calculateAge(patientInfo.dob)}`}</span>
        </div>
      </div>
      <div className="col-md-6">
        <div className="patient-details">
          <span>
            <i className="fas fa-fw fa-phone"></i> Contact No.:{" "}
          </span>
          <span>{patientInfo.phoneNumber || "--"}</span>
        </div>
        <div className="patient-details">
          <span>
            <i className="fas fa-fw fa-allergies"></i> Allergies:{" "}
          </span>
          <span>{patientInfo.allergies || "--"}</span>
        </div>
      </div>
    </div>
  );
};

const ActionsTable = ({
  actionItems = [],
  members = [],
  addItemShown,
  onCompleteItem,
  onAssigneeChange,
  onActionChange,
  activeTab,
  thread,
  isLoading,
  handleSetAddItemShown,
}) => {
  const thStyles = {
    fontWeight: "bold",
  };

  const tableStyles = {
    color: "#000",
    fontSize: "12px",
  };

  return (
    <div style={{ position: "relative", minHeight: "calc(100vh - 400px)" }}>
      <table className="table table-borderless shadow-sm" style={tableStyles}>
        <thead className="border-bottom">
          <tr style={thStyles}>
            <td>ASSIGNED TO</td>
            <td>ACTIONS</td>
            <td className="text-center align-middle">DATE ENTERED</td>
            <td className="text-center align-middle">STATUS</td>
            <td></td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {actionItems.map((item, i) => (
            <ActionItem
              key={item.objectId}
              item={item}
              itemIndex={i}
              hideButtons={addItemShown}
              onComplete={onCompleteItem}
              onAssigneeChange={onAssigneeChange}
              onActionChange={onActionChange}
              activeTab={activeTab}
              members={members}
              thread={thread}
            />
          ))}
        </tbody>
      </table>
      <OverlayLoader isLoading={isLoading} />
      <OverlayPlaceholder show={!isLoading && !actionItems.length}>
        <span className="text-empty mr-1">No action items yet.</span>
        <button
          className="btn btn-link p-0"
          onClick={() => handleSetAddItemShown(true)}
        >
          Add new?
        </button>
      </OverlayPlaceholder>
    </div>
  );
};

const ActionForm = ({
  circleId,
  addItemShown,
  setAddItemShown,
  onCancel,
  members = [],
  onNewItem,
  thread,
}) => {
  const [text, setText] = useState("");
  const [checked, setIsChecked] = useState(false);
  const [assignee, setAssignee] = useState("");
  const [isLoading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const clearForm = () => {
    setText("");
    setAssignee("");
    setIsChecked(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const assignedTo = members.find((member) => {
      return member.objectId === assignee;
    });

    const data = {
      text,
      assignedTo,
      thread,
      threadName: thread.groupName,
      threadId: circleId,
    };

    setLoading(true);
    const actionItem = await dispatch(createActionItems(circleId, data));

    // if (checked) {
    handleAlertSubmit([assignedTo], actionItem);
    // }

    setLoading(false);

    onNewItem(actionItem);

    clearForm();
  };

  const handleCancel = () => {
    clearForm();
    onCancel();
  };

  function handleAlertSubmit(attArray = [], actionItem) {
    const attNames = attArray.map((att) => att.firstName);
    const text = `New task(s) are assigned to you on action items. \n\nNote: ${actionItem.text}`;

    createNewMessage(thread, text, attArray, attNames, actionItem);
  }

  function createNewMessage(data, text, attentionArray, attNames, actionItem) {
    let newThread = {
      senderName: "",
      senderObjectId: "",
      threadDetail: "",
      threadType: "group",
      unreadMessageCount: 0,
      threadId: data.objectId,
      objectId: data.objectId,
      groupName: data.name,
      groupType: data.groupType,
    };

    createNewMessageThread(
      newThread,
      text,
      attentionArray,
      attNames,
      actionItem
    );
  }

  function createNewMessageThread(
    newThread,
    text,
    attentionArray,
    attNames = [],
    actionItem = {}
  ) {
    const date = new Date();
    let newMessage = {
      text: text.trim(),
      originalMessageDate: date,
      threadId: newThread.threadId,
      user: {
        __type: "Pointer",
        className: "_User",
        objectId: Parse.User.current().id,
      },
      objectId: randomID(),
      threadType: "group",
      newMessageStatus: "sending",
      createdAt: date,
      circle: {
        __type: "Pointer",
        className: "Circle",
        objectId: newThread.threadId,
      },
      adhocGroupId: newThread.threadId,
      attention: attentionArray,
      attentionName: attNames,
      actionItem: actionItem.objectId,
    };

    sendNewMessage(newMessage, newThread)(dispatch);
  }

  if (!addItemShown) {
    return (
      <div
        className="d-flex justify-content-end"
        style={{ margin: "15px 0px" }}
      >
        <button
          className="btn btn-sm btn-primary ml-auto"
          onClick={() => setAddItemShown(true)}
          disabled={addItemShown}
        >
          <i className="fas fa-plus mr-1"></i>
          Add action item
        </button>
      </div>
    );
  }

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <div className="row" style={{ marginBottom: "15px" }}>
        <div className="col-md-4 pr-md-0 mb-3 mb-md-0">
          <Label>Assign to:</Label>
          <Select
            className="form-control"
            value={assignee}
            onChange={(e) => setAssignee(e.target.value)}
          >
            <option value="">Choose assignee</option>
            {members.map((member) => (
              <option key={member.objectId} value={member.objectId}>
                {member.displayName}
              </option>
            ))}
          </Select>
        </div>
        <div className="col-md-6 pr-md-0 mb-3 mb-md-0">
          <Label>Action:</Label>
          {/* <Input
            className="form-control"
            placeholder="Enter what needs to be done"
            onChange={e => setText(e.target.value)}
            value={text}
            required
          /> */}
          <TextareaAutosize
            className="form-control shadow-sm border-0"
            placeholder="Enter what needs to be done"
            onChange={(e) => setText(e.target.value)}
            style={{
              borderRadius: "0px",
              resize: "none",
            }}
            value={text}
            required
          />
        </div>
        <div
          className="col-md-2 d-flex align-items-start"
          style={{ marginTop: "26px" }}
        >
          <button
            type="button"
            className="btn btn-sm btn-light mr-1"
            onClick={() => handleCancel()}
            style={{ width: "35px", marginLeft: "auto" }}
          >
            <i className="fas fa-times text-danger"></i>
          </button>
          <button
            type="submit"
            className="btn btn-sm btn-light"
            disabled={isLoading}
            style={{ width: "35px", marginRight: "12px" }}
          >
            {isLoading ? (
              <div
                className="spinner-border spinner-border-sm text-primary"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <i className="fas fa-check text-primary"></i>
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

const ActionItem = ({
  item = {},
  itemIndex,
  hideButtons,
  onComplete,
  onAssigneeChange,
  onActionChange,
  activeTab,
  members,
  thread,
}) => {
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isItemChecked, setIsItemChecked] = useState(false);

  const { search = "" } = window.location;
  const urlParams = new URLSearchParams(search);
  const actionId = urlParams.get("itemId");

  const isComplete = useMemo(() => {
    return item.status === "DONE";
  }, [item.status]);

  const handleComplete = async () => {
    setLoading(true);

    await dispatch(completeActionItem(item.objectId));

    setLoading(false);
    onComplete(item.objectId);
  };

  const handleUpdate = async (assignedTo) => {
    setLoading(true);
    const actionItem = await dispatch(updateActionItem(item));
    setLoading(false);
    setIsEditMode(false);

    if (isItemChecked) {
      handleAlertSubmit([assignedTo], actionItem);
    }
  };

  const handleDelete = async () => {};

  function handleAlertSubmit(attArray = [], actionItem = {}) {
    const attNames = attArray.map((att) => att.firstName);
    const text = `Your task is updated on action items. \n\nNote: ${actionItem.text}`;

    createNewMessage(thread, text, attArray, attNames, actionItem);
  }

  function createNewMessage(data, text, attentionArray, attNames, actionItem) {
    let newThread = {
      senderName: "",
      senderObjectId: "",
      threadDetail: "",
      threadType: "group",
      unreadMessageCount: 0,
      threadId: data.threadId,
      objectId: data.threadId,
      groupName: data.groupName,
      groupType: data.groupType,
    };

    createNewMessageThread(
      newThread,
      text,
      attentionArray,
      attNames,
      actionItem
    );
  }

  function createNewMessageThread(
    newThread,
    text,
    attentionArray,
    attNames = [],
    actionItem = {}
  ) {
    const date = new Date();
    let newMessage = {
      text: text.trim(),
      originalMessageDate: date,
      threadId: newThread.threadId,
      user: {
        __type: "Pointer",
        className: "_User",
        objectId: Parse.User.current().id,
      },
      objectId: randomID(),
      threadType: "group",
      newMessageStatus: "sending",
      createdAt: date,
      circle: {
        __type: "Pointer",
        className: "Circle",
        objectId: newThread.threadId,
      },
      adhocGroupId: newThread.threadId,
      attention: attentionArray,
      attentionName: attNames,
      actionItem: actionItem.objectId,
    };

    sendNewMessage(newMessage, newThread)(dispatch);
    setIsItemChecked(false);
  }

  return (
    <tr className={item.objectId === actionId ? "active-action-item" : ""}>
      <td className="text-left align-middle" style={{ display: "flex" }}>
        {item.assignedTo && !isEditMode && (
          <AssignedTo user={item.assignedTo} />
        )}
        {item.assignedTo && isEditMode && (
          <Select
            className="form-control"
            value={item.assignedTo.objectId}
            onChange={(e) => onAssigneeChange(item)}
          >
            <option value="">Choose assignee</option>
            {members.map((member) => (
              <option key={member.objectId} value={member.objectId}>
                {member.displayName}
              </option>
            ))}
          </Select>
        )}
        {!item.assignedTo && (
          <div className="text-empty" style={{ textAlign: "left" }}>
            None
          </div>
        )}
      </td>
      <td className="align-middle">
        {!isEditMode && <Label>{item.text}</Label>}
        {isEditMode && (
          <TextareaAutosize
            className="form-control shadow-sm border-0"
            placeholder="Enter what needs to be done"
            onChange={(e) =>
              onActionChange(item, e.target.value, itemIndex, "text")
            }
            value={item.text}
            required
            style={{
              borderRadius: "0px",
              resize: "none",
            }}
          />
        )}
      </td>
      <td className="text-center align-middle">
        {moment(item.createdAt).format("l")}
      </td>
      <td className="text-center align-middle">
        <ItemStatus status={item.status} />
      </td>
      <td>
        {isEditMode && (
          <Fragment>
            <span style={{ whiteSpace: "nowrap" }}>Send Reminder?</span>
            <Input
              type="checkbox"
              placeholder="Enter what needs to be done"
              onChange={() => setIsItemChecked(!isItemChecked)}
              checked={isItemChecked}
            />
          </Fragment>
        )}
      </td>
      <td
        className="align-middle action-buttons-container"
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {!isComplete && (
          <button
            onClick={() => setIsEditMode(true)}
            className="btn btn-sm btn-outline-primary"
            disabled={isLoading}
            style={{ marginRight: "5px" }}
          >
            {isLoading ? (
              <Spinner className="spinner-border-sm text-primary" />
            ) : (
              <i className="fas fa-fw fa-edit"></i>
            )}
          </button>
        )}
        {isEditMode && (
          <button
            onClick={() => handleUpdate(item.assignedTo)}
            className="btn btn-sm btn-outline-success"
            disabled={isLoading}
            style={{ marginRight: "5px" }}
          >
            {isLoading ? (
              <Spinner className="spinner-border-sm text-success" />
            ) : (
              <i className="fas fa-fw fa-save"></i>
            )}
          </button>
        )}
        {!hideButtons && !isComplete && !isEditMode ? (
          <button
            onClick={() => handleComplete()}
            className="btn btn-sm btn-outline-success"
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner className="spinner-border-sm text-success" />
            ) : (
              <i className="fas fa-fw fa-check"></i>
            )}
          </button>
        ) : (
          ""
        )}
      </td>
    </tr>
  );
};

const AssignedTo = ({ user = {} }) => {
  const { picture = {} } = user;

  return (
    <div className="d-flex align-items-center text-truncate">
      <ProfilePic url={picture.url} size="25" />
      <span className="text-primary ml-1">{user.firstName}</span>
    </div>
  );
};

const ItemStatus = ({ status = "" }) => {
  const classNames = useMemo(() => {
    switch (status) {
      case "PENDING":
        return "badge badge-warning badge-pill";
      case "DONE":
        return "badge badge-success badge-pill";
      default:
        return "badge badge-light badge-pill";
    }
  }, [status]);

  return <span className={classNames}>{status}</span>;
};

export default ActionsComponent;
