import { parseAction } from "./REST";
import config from "../config";

export const fetchPriorities = (loadMore = false, page = 0, priorityLevel = 2) => (dispatch) => {
  dispatch({
    type: "SET_LOADING",
    value: true,
  });

  const method = "post";
  const url = config.BASE_URL + "/parse/functions/getPrioritiesV2";

  const options = {
    page: page,
    pageSize: 10,
    priorityLevel: priorityLevel
  };

  return parseAction(method, url, options)
    .then((response) => {
      const { priorities, hasMore } = response.result;

      dispatch({
        replace: !loadMore,
        type: "SAVE_PRIORITIES",
        status: "SUCCESS",
        priorities,
        page: page,
        hasMore,
      });

      dispatch({
        type: "SET_LOADING",
        value: false,
      });

      return priorities.length;
    })
    .catch((error) => {
      // TODO handle error when fetching thread
      console.error(error);
      dispatch({
        type: "SAVE_PRIORITIES",
        status: "FAILED",
      });

      dispatch({
        type: "SET_LOADING",
        value: false,
      });
    });
};

export const setActivePriority = (thread) => (dispatch) => {
  dispatch({
    type: "NEW_ACTIVE_PRIORITY",
    thread: thread,
  });

  const method = "post";
  const url = config.BASE_URL + "/parse/functions/deletePriority";

  const data = {
    objectId: thread.objectId,
  };

  return parseAction(method, url, data);
};
