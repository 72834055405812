import React, { useEffect } from 'react';

import './index.css';

function ProcedureNotesComponent({
  procedureNotes = '',
  updateProcedureNotes = () => {},
  isDisabled = false,
}) {

  function handleUpdateProcedureNotes (e) {
    e.preventDefault();
    updateProcedureNotes(e);
  }
  
  useEffect(() => {
    return () => {}
  }, []);

  return (
    <div className="custom-form-group" style={{ maxWidth: '250px' }}>
      <span htmlFor="procedureNotes">PROCEDURE NOTES</span>
      <select 
        id="procedureNotes" 
        name="procedureNotes" 
        type="text" 
        className="form-control" 
        placeholder="Procedure Notes"
        onChange={e => handleUpdateProcedureNotes(e)}
        value={procedureNotes || ''}
        disabled={isDisabled}>
        <option value="">None</option>
        <option value="Joint/Tendon Aspiration/Injection">Joint/Tendon Aspiration/Injection</option>
        <option value="Incision & Drainage">Incision & Drainage</option>
        <option value="Suture Removal">Suture Removal</option>
      </select>
    </div>
  );
}

export default ProcedureNotesComponent;
