import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { PDFViewer, Document, View, Text, Page } from "@react-pdf/renderer";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { parseAction } from "../../../../actions/REST";
import Loader from "../../../Common/Loader";
import {
  getOrderHistory,
  setOrdersHistory,
  requestOTP,
  eSignPDF
} from "../../../../actions/PatientAction";
import ConferenceApi from "../../../../api/Conference";
import { calculateAge } from "../../../../helper/util";
import config from "../../../../config";
import Styles from "../PrescriptionPDF/styles";
import { ToastContainer } from "react-toastify";
import Notify from "../../../Common/Notify";
import RequirePasswordESign from "../PrescriptionPDF/RequirePasswordESign";

function OrdersPDF({
  patientId = "",
  orderHistoryId = "",
  fromPatientOrders = false
}) {
  const dispatch = useDispatch();
  const [patientInfo, setPatientInfo] = useState({});
  const [doctorInfo, setDoctorInfo] = useState({});
  const [isESignedOpen, setIsESignedOpen] = useState(false);
  const { patientOrder: orders, isOrderLoading } = useSelector(
    state => ({
      patientOrder: state.patient.patientOrder,
      isOrderLoading: state.patient.isOrderLoading
    }),
    shallowEqual
  );

  console.log(doctorInfo);

  function fetchPatientInfo(threadId) {
    parseAction("get", config.BASE_URL + "/parse/classes/Circle", {
      where: {
        objectId: threadId
      }
    })
      .then(result => {
        var data = {
          ...result.results[0],
          prescriber: result.results[0].prescriber
            ? result.results[0].prescriber.objectId
            : ""
        };
        setPatientInfo(data);

        if (data.prescriber) {
          getPrescriberDetails(data.prescriber);
        }

        if (fromPatientOrders) {
          getOrderHistory(orderHistoryId)(dispatch);
        } else {
          const orders = JSON.parse(localStorage.getItem("orders") || {});
          setOrdersHistory(orders)(dispatch);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  function getPrescriberDetails(objectId) {
    ConferenceApi.getUsersById({ objectIds: [objectId] }).then(response => {
      setDoctorInfo(response.users[0]);
    });
  }

  function handleESignPDF(otp) {
    eSignPDF(
      {
        otp,
        patientOrderId: orderHistoryId
      },
      "order",
      res => {
        setIsESignedOpen(false);
        getOrderHistory(orderHistoryId)(dispatch);
      }
    )(dispatch);
  }

  function renderData(data = "") {
    const arr = data.split(";");
    return arr.map((item, i) => (
      <View style={[{ padding: "0px 10px", width: "100%" }]} key={`order-${i}`}>
        <Text style={Styles.textXs}>{item}</Text>
      </View>
    ));
  }

  function copyOrderLink() {
    const OrderUrl = `${window.location.origin}/orders-history/${patientId}/${orderHistoryId}`;
    const elem = document.createElement("textarea");

    elem.value = OrderUrl;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);

    Notify.success("Order link copied!");
  }

  function renderString(str) {
    return String(str).replace("\t", "");
  }

  useEffect(() => {
    fetchPatientInfo(patientId);
    return () => {};
  }, [patientId]);

  console.log("orders: ", orders);

  return (
    <Fragment>
      <PDFViewer>
        <Document>
          <Page style={Styles.page}>
            <Text style={Styles.date} fixed>{`${moment(
              orders.createdAt || new Date()
            ).format("LLLL")}`}</Text>
            <View style={Styles.headerContainer} fixed>
              <View style={Styles.header} fixed>
                <Text style={[Styles.textMdStrong, { marginBottom: "5px" }]}>
                  {"Vital Health Medical Group"}
                </Text>
                <Text style={[Styles.textXs, { color: "#2194f3" }]}>
                  {"6245 2nd flr De Longpre Ave, Hollywood, CA 90028"}
                </Text>
                <Text
                  style={[
                    Styles.textXs,
                    { color: "#2194f3", marginBottom: "5px" }
                  ]}
                >
                  Los Angeles, CA 90026
                </Text>
                <View style={Styles.rowView}>
                  <View style={Styles.rowView}>
                    <Text style={Styles.textXsStrong}>Phone: </Text>
                    <Text style={[Styles.textXs, { color: "#2194f3" }]}>
                      {"(323)315-0911"}
                    </Text>
                  </View>
                  <Text> </Text>
                  <View style={Styles.rowView}>
                    <Text style={Styles.textXsStrong}>Fax: </Text>
                    <Text style={[Styles.textXs, { color: "#2194f3" }]}>
                      {"(323)"}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={Styles.divider} fixed></View>
              <View
                style={[Styles.aroundBox, { padding: 5, minHeight: 55 }]}
                fixed
              >
                <View style={Styles.divider}></View>
                <View style={[{ width: 200 }]}>
                  <View style={Styles.details}>
                    <Text style={Styles.patientBold}>Patient: </Text>
                    <View>
                      <Text style={Styles.patientReg}>
                        {renderString(patientInfo.name) || "--"}
                      </Text>
                    </View>
                  </View>
                  <View style={Styles.details}>
                    <Text style={Styles.patientBold}>Address: </Text>
                    <View>
                      <Text style={Styles.patientReg}>
                        {renderString(patientInfo.address) || "--"}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={[{ width: 200 }]}>
                  <View style={Styles.details}>
                    <Text style={Styles.patientBold}>DOB:</Text>
                    <View>
                      <Text style={Styles.patientReg}>{`${calculateAge(
                        patientInfo.dob
                      )}`}</Text>
                    </View>
                  </View>
                  <View style={Styles.details}>
                    <Text style={Styles.patientBold}>Sex: </Text>
                    <View>
                      <Text style={Styles.patientReg}>
                        {patientInfo.gender || "--"}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={[{ width: 200 }]}>
                  <View style={Styles.details}>
                    <Text style={Styles.patientBold}>Phone: </Text>
                    <View>
                      <Text style={Styles.patientReg}>
                        {patientInfo.phoneNumber || "--"}
                      </Text>
                    </View>
                  </View>
                  <View style={Styles.details}>
                    <Text style={Styles.patientBold}>MRN: </Text>
                    <View>
                      <Text style={Styles.patientReg}>
                        {patientInfo.mrn || "--"}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={Styles.divider} fixed></View>
            </View>
            <View style={[{ marginBottom: 20 }]} fixed></View>
            {orders.prescription !== "" && orders.prescription && (
              <View>
                <Text style={Styles.textSmStrong}>PRESCRIPTION:</Text>
                <View style={[Styles.leftRow, { padding: "10px 0px" }]}>
                  <View style={[{ padding: "0px 10px", width: "100%" }]}>
                    <Text style={Styles.textXs}>{orders.prescription}</Text>
                  </View>
                </View>
                <View style={Styles.ordersDivider}></View>
              </View>
            )}
            {orders.diagnosticStudies !== "" && orders.diagnosticStudies && (
              <View>
                <Text style={Styles.textSmStrong}>DIAGNOSTIC STUDIES:</Text>
                <View style={[Styles.leftRow, { padding: "10px 0px" }]}>
                  {renderData(orders.diagnosticStudies)}
                </View>
                <View style={Styles.ordersDivider}></View>
              </View>
            )}
            {orders.referrals !== "" && orders.referrals && (
              <View>
                <Text style={Styles.textSmStrong}>REFERRALS:</Text>
                <View style={[Styles.leftRow, { padding: "10px 0px" }]}>
                  {renderData(orders.referrals)}
                </View>
                <View style={Styles.ordersDivider}></View>
              </View>
            )}
            {orders.medicalSupplies !== "" && orders.medicalSupplies && (
              <View>
                <Text style={Styles.textSmStrong}>MEDICAL SUPPLIES:</Text>
                <View style={[Styles.leftRow, { padding: "10px 0px" }]}>
                  {renderData(orders.medicalSupplies)}
                </View>
                <View style={Styles.ordersDivider}></View>
              </View>
            )}
            <View style={Styles.SignatureBox} fixed>
              <View>
                <View style={[Styles.doctorsView, { marginBottom: 40 }]}>
                  {/* <Text style={[Styles.doctorDetails]}>
                    Electronically signed by:{" "}
                  </Text>
                  <View style={Styles.underline}>
                    <Text style={Styles.textXsStrong}>{`${doctorInfo.displayName}, ${doctorInfo.title}`}</Text>
                  </View> */}
                  <Text style={[Styles.doctorDetails]}>Signature: </Text>
                  <View style={Styles.underline}>
                    {orders.isESigned && (
                      <View style={{ marginLeft: "5px", marginBottom: "5px" }}>
                        <Text style={Styles.textXsStrong}>
                          Electronically Signed
                        </Text>
                        <Text
                          style={Styles.textXs}
                        >{`${doctorInfo.displayName}, ${doctorInfo.title}`}</Text>
                      </View>
                    )}
                  </View>
                </View>
                <View style={Styles.doctorsView}>
                  <Text style={[Styles.doctorDetails]}>Prescribed by: </Text>
                  <Text
                    style={Styles.doctorsText}
                  >{`${doctorInfo.displayName}, ${doctorInfo.title}`}</Text>
                </View>
                <View style={Styles.doctorsView}>
                  <Text style={[Styles.doctorDetails]}>NPI#: </Text>
                  <Text style={Styles.doctorsText}>--</Text>
                </View>
                <View style={Styles.doctorsView}>
                  <Text style={[Styles.doctorDetails]}>LIC#: </Text>
                  <Text style={Styles.doctorsText}>--</Text>
                </View>
                <View style={Styles.doctorsView}>
                  <Text style={[Styles.doctorDetails]}>DEA#: </Text>
                  <Text style={Styles.doctorsText}>--</Text>
                </View>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
      {fromPatientOrders && (
        <div
          style={{
            padding: "10px",
            background: "#37373a",
            boxShadow: "0px 0px 4px 0px #000000",
            textAlign: "center"
          }}
        >
          <button
            type="button"
            className="btn btn-hubchart"
            style={{
              fontSize: "12px",
              padding: "3px 10px",
              height: "33px",
              marginRight: "5px"
            }}
            onClick={() => copyOrderLink()}
          >
            Copy Link
          </button>
          {!orders.isESigned && (
            <button
              type="button"
              className="btn btn-hubchart-success"
              style={{
                fontSize: "12px",
                padding: "3px 10px",
                height: "33px",
                marginRight: "5px",
                color: "#FFFFFF"
              }}
              onClick={() => {
                requestOTP(doctorInfo.username)(dispatch);
                setIsESignedOpen(true);
              }}
            >
              E-Sign
            </button>
          )}
        </div>
      )}
      <ToastContainer />
      <Loader isLoading={isOrderLoading} />
      {isESignedOpen && (
        <RequirePasswordESign
          onClose={otp => {
            if (otp) {
              handleESignPDF(otp);
            } else {
              setIsESignedOpen(false);
            }
          }}
          title="Password Required"
          message="You need to enter a OTP to electronically sign this document."
        />
      )}
    </Fragment>
  );
}

export default OrdersPDF;
