import React from 'react';
import util from '../../helper/util';
import Logo from '../../assets/images/logo-blue.png';

const RedirectConference = (props) => {

  const redirectToWeb = () => {

    const params = new URLSearchParams(props.location.search);
    const roomId = params.get('roomId');

    const conferenceUrl = [
      window.location.origin,
      "/conferencecall",
      "?roomId=" + roomId,
    ].join('');

    var newWin = window.open(conferenceUrl, "Hubchart Telemed", "status=1,menubar=0");

    if(!newWin || newWin.closed || typeof newWin.closed == 'undefined') { 
      window.location.href = conferenceUrl;
    }
  };

  const redirectToApp = () => {

    const params = new URLSearchParams(props.location.search);
    const roomId = params.get('roomId');
    const hostId = params.get('hostId');

    const url = [
      'hubchart://conferencecall',
      '?roomId=', roomId,
      '&hostId=', hostId
    ].join('');

    window.location = url;
  }

  return util.isMobile.iOS() || util.isMobile.Android() ?
    <RedirectMobile {...props} redirectToWeb={redirectToWeb} redirectToApp={redirectToApp} /> :
    <RedirectWeb {...props} redirectToWeb={redirectToWeb} />
}

const RedirectMobile = ({ redirectToApp, redirectToWeb }) => {

  redirectToApp();

  const wrapperStyles = {
    height: '100%',
    width: '100%',
    background: '#2194f3',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const formStyles = {
    height: '50%',
    width: '100%',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const handleJoin = (e) => {
    
    e.preventDefault();

    redirectToWeb();
  }

  return (
    <div style={wrapperStyles}>
      <div style={formStyles}>
        <form className="text-center" style={{ width: '400px', padding: '10px' }}>
          <div align="center" className="mb-3">
            <img
              width="80"
              height="70"
              className="Logo"
              src={Logo}
              alt="Hubchart"
            />
            <h3 className="hc-primary-text">HUBCHART</h3>
          </div>
          <button onClick={handleJoin} className="btn btn-primary hc-bg-primary">
            <i className="fa fa-video-plus mr-2"></i>
              Join conference
          </button>
        </form>
      </div>
    </div>
  );
}

const RedirectWeb = ({ redirectToWeb }) => {

  const wrapperStyles = {
    height: '100%',
    width: '100%',
    background: '#2194f3',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const formStyles = {
    height: '50%',
    width: '100%',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const handleJoin = (e) => {
    
    e.preventDefault();

    redirectToWeb();
  }

  return (
    <div style={wrapperStyles}>
      <div style={formStyles}>
        <form className="text-center" style={{ width: '400px', padding: '10px' }}>
          <div align="center" className="mb-3">
            <img
              width="80"
              height="70"
              className="Logo"
              src={Logo}
              alt="Hubchart"
            />
            <h3 className="hc-primary-text">HUBCHART</h3>
          </div>
          <button onClick={handleJoin} className="btn btn-primary hc-bg-primary">
            <i className="fa fa-video-plus mr-2"></i>
              Join conference
            </button>
        </form>
      </div>
    </div>
  );
}

export default RedirectConference;
