import React, { Component } from 'react'
import CameraPhoto, { FACING_MODES, IMAGE_TYPES } from 'jslib-html5-camera-photo';
import './Camera.css'
const $ = window.$;
export class CameraModal extends Component {

    constructor() {
        super();
        this.cameraPhoto = null;
        this.videoRef = React.createRef();
        this.state = {
            dataUri: '',
            noCamera : false
        }
    }


    componentDidMount() {
        if (this.props.isOpen) {
            $("#cameraModal").modal('show');
        } else {
            $("#cameraModal").modal('hide');
        }
        $("#cameraModal").on('hidden.bs.modal', function (e) {
            this.stopCamera();
            this.props.modalClose();

        }.bind(this))

        this.cameraPhoto = new CameraPhoto(this.videoRef.current);
        this.startCamera();
    }

    startCamera () {
        let self = this;
        this.cameraPhoto.startCamera(FACING_MODES.USER, {width: 640, height: 360})
        .then(() => {
        console.log('camera is started !');
        })
        .catch((error) => {
            self.setState({
                noCamera : true
            })
        });
    }

    takePhoto () {
        this.flash();
        const config = {
          sizeFactor: 1
        };

        let dataUri = this.cameraPhoto.getDataUri(config);
        this.setState({ dataUri });
    }

    stopCamera () {
        this.cameraPhoto.stopCamera()
          .then(() => {
            console.log('Camera stoped!');
          })
          .catch((error) => {
            console.log('No camera to stop!:');
          });
    }

    retakePhoto() {
        this.setState({ dataUri : "" });
        this.cameraPhoto = new CameraPhoto(this.videoRef.current);
        this.startCamera();
    }

    flash(e){
        $('.flash')
         .show()  //show the hidden div
         .animate({opacity: 0.5}, 300)
         .fadeOut(300)
         .css({'opacity': 1});
    }

    sendPicture() {
        this.props.sendCapturedPhoto(this.state.dataUri);
        this.stopCamera();
        $("#cameraModal").modal('hide');
    }


    render() {




        return (
            <div id="cameraModal" className="modal" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body" style={{paddingBottom: "0px"}}>
                            <h5 className="hc-primary-text">Camera</h5>
                            <div className="Camera-Util-Container">
                                {this.state.noCamera &&
                                    <div style={{textAlign: "center", color: "red", fontSize: "14px"}}>
                                        <p>Please check your camera and allow access to camera at the top right of the screen</p>
                                    </div>
                                }
                                <video
                                    className="Camera-Video-Stream"
                                    ref={this.videoRef}
                                    autoPlay={true}
                                />
                                <img
                                        className="Photo-Captured"
                                        alt="imgCamera"
                                        src={this.state.dataUri}
                                        style={this.state.dataUri === "" ? {display: "none", width: "100%"} : {width: "100%"}}
                                        />

                                <div className="flash"></div>
                            </div>

                            <div style={{height: "50px", textAlign: "right", marginTop: "10px"}}>
                                {this.state.dataUri !== "" && <button onClick={this.retakePhoto.bind(this)} className="Confirm-Button hc-primary-text" style={{float: "left"}}>Retake Photo</button>}
                                {this.state.dataUri === "" ?
                                    <button 
                                        onClick={this.takePhoto.bind(this)} 
                                        className="Confirm-Button hc-primary-text"
                                        style={{ borderRadius: '30px' }}>Take Photo</button>
                                    :
                                    <button 
                                        onClick={this.sendPicture.bind(this)} 
                                        className="Confirm-Button hc-primary-text"
                                        style={{ borderRadius: '30px' }}>Send Photo</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CameraModal
