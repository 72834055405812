import React, { Component } from 'react'

const $ = window.$;
export class MedicalProfileWarning extends Component {

    componentDidMount() {
        if (this.props.isOpen) {
            $("#profile-warning").modal('show');
        } else {
            $("#profile-warning").modal('hide');
        }
        $("#profile-warning").on('hidden.bs.modal', function (e) {
            this.props.modalClose();
        }.bind(this))
    }

    render() {
        return (
            <div id="profile-warning" className="modal" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-body" style={{paddingBottom: "0px"}}>
                        <h5 style={{color: "#D84B55", fontSize: "1.25rem"}}>Medical Record already link to an account</h5>
                        <p style={{fontSize: "0.9em"}}>You can still continue with you're registration.</p>
                    </div>
                    <div className="modal-footer" style={{border: "0", padding: "0 20px 20px 0"}}>
                        <button type="button" className="btn btn-hubchart btn-hubchart-close" data-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MedicalProfileWarning
