import moment from 'moment';
import ConferenceApi from '../api/Conference';
import { getCalendarMeetings, googleSignIn, googleSignOut, initializeGapi } from '../api/GoogleCalendarApi';
import { parseAction } from './REST';
import config from '../config';
import { telemedUrl } from '../helper/util';
import ConferenceHelper from '../helper/conference';
import Notify from '../components/Common/Notify';
import {
    SET_CONFERENCES_BY_ID,
    SET_LOADING_CONFERENCES_BY_ID
} from './types';

export const getActiveConferenceCall = () => dispatch => {

    dispatch({
        type: 'SET_LOADING_ACTIVE_CALL',
        value: true
    });

    return ConferenceApi.getActiveConferenceCall()
        .then(result => {
            const { conference } = result;

            dispatch({
                type: 'SET_LOADING_ACTIVE_CALL',
                value: false,
            });

            dispatch({
                type: 'SET_CONFERENCE',
                conference
            });
        })
        .catch(response => {
            dispatch({
                type: 'SET_LOADING_ACTIVE_CALL',
                value: false,
            });
        });
}

export const createConferenceCall = (participantIds = []) => dispatch => {

    return ConferenceApi.createConferenceCall(participantIds)
        .then(result => {
            const { conference } = result;

            dispatch({
                type: 'SET_CONFERENCE',
                conference
            });

            return result;
        });
}

export const endConferenceCall = (roomId) => dispatch => {

    return ConferenceApi.endConferenceCall(roomId)
        .then(result => {
            dispatch({
                type: 'REMOVE_CONFERENCE',
            });
        })
        .catch(result => {
            dispatch({
                type: 'REMOVE_CONFERENCE',
            });
        });
}

export const getConferenceCalls = () => dispatch => {

    dispatch({
        type: 'SET_LOADING_CONFERENCES',
        value: true
    });

    return ConferenceApi.getConferenceCalls()
        .then(result => {
            const { conferences } = result;

            dispatch({
                type: 'SET_CONFERENCES',
                conferences
            });

            dispatch({
                type: 'SET_LOADING_CONFERENCES',
                value: false
            });
        });
}

export const getConferenceCallsById = id => dispatch => {

    dispatch({
        type: SET_LOADING_CONFERENCES_BY_ID,
        value: true
    });
    
    return ConferenceApi.getConferenceCallsById(id)
        .then(result => {
            const { conferences = [] } = result;
            dispatch({
                type: SET_CONFERENCES_BY_ID,
                conferences,
                initial_conference: conferences.length > 0 ? [conferences[0]] : [],
            });

            dispatch({
                type: SET_LOADING_CONFERENCES_BY_ID,
                value: false
            });
        });
}

export const updateConferenceCall = (id, data) => dispatch => {

    const url = config.BASE_URL + '/parse/classes/ConferenceCall/' + id;

    return parseAction("put", url, data)
        .then((response) => {
            dispatch({
                type: 'UPDATE_CONFERENCE_SUCCESS',
                id,
                data
            });
        });
}

export const scheduleConferenceCall = (data) => dispatch => {

    return ConferenceApi.scheduleConferenceCall(data)
        .then(result => {

            // Add newly created conference to store
            // const {conference} = result;

            // dispatch({
            //     type: 'ADD_SCHEDULE',
            //     conference
            // });

            return result;
        });
}

export const deleteConferenceCall = (objectId) => dispatch => {

    return ConferenceApi.deleteConferenceCall(objectId)
        .then(result => {

            // Add newly created conference to store
            // const {conference} = result;

            // dispatch({
            //     type: 'ADD_SCHEDULE',
            //     conference
            // });

            return result;
        });
}

export const updateScheduledConferenceCall = (data) => {

    return ConferenceApi.updateConferenceCall(data)
        .then(result => {
            return result;
        });
}

export const setConferenceTab = (tab) => (dispatch) => {
    dispatch({
        type: 'SET_CONFERENCE_TAB',
        value: tab
    });
}

export const getSchedules = () => dispatch => {

    dispatch({
        type: 'SET_LOADING_SCHEDULES',
        value: true
    });

    return ConferenceApi.getSchedules()
        .then(result => {
            const { conferences } = result;

            dispatch({
                type: 'SET_SCHEDULES',
                conferences
            });

            dispatch({
                type: 'SET_LOADING_SCHEDULES',
                value: false
            });
        });
}

export const loadGoogleApi = (gData, callback) => dispatch => {
    return initializeGapi(gData, res => {
        const isSignedIn = res ? true : false;
        dispatch({
            type: 'SET_GOOGLE_SIGNED_IN',
            value: isSignedIn,
        });

        dispatch({
            type: 'SET_GOOGLE_INITIALIZED',
            value: true
        });

        callback();
    });
}

export const signInToGoogle = () => dispatch => {

    return googleSignIn()
        .then(() => {
            dispatch({
                type: 'SET_GOOGLE_SIGNED_IN',
                value: true,
            })
        }, error => {
            dispatch({
                type: 'SET_GOOGLE_SIGNED_IN',
                value: false,
            })

            dispatch({
                type: 'SET_GOOGLE_SCHEDULE_COUNT',
                value: 0,
            })
        });
}

export const signOutOfGoogle = () => dispatch => {

    return googleSignOut()
        .then(() => {
            dispatch({
                type: 'SET_GOOGLE_SIGNED_IN',
                value: false,
            })

            dispatch({
                type: 'SET_GOOGLE_SCHEDULE_COUNT',
                value: 0,
            })
        }, error => {
            dispatch({
                type: 'SET_GOOGLE_SIGNED_IN',
                value: true,
            })
        });
}

export const getMeetings = () => dispatch => {

    dispatch({
        type: 'SET_LOADING_SCHEDULES',
        value: true
    });

    return getCalendarMeetings()
        .then(result => {
            const telemed_events = result.filter(sched => {
                const new_sched = {
                    ...sched,
                    description: sched.description || '',
                };
                return new_sched.description.includes(telemedUrl);
            });
            const datesToday = telemed_events.filter(sched => moment(sched).isSame(sched.start.dateTime, 'day'));

            dispatch({
                type: 'SET_GOOGLE_SCHEDULES',
                conferences: result
            });

            dispatch({
                type: 'SET_SELECTED_SCHEDULES',
                selected_conferences: telemed_events,
            });

            dispatch({
                type: 'SET_TELEMED_SCHEDULES',
                telemed_conferences: telemed_events
            });

            dispatch({
                type: 'SET_LOADING_SCHEDULES',
                value: false
            });

            dispatch({
                type: 'SET_GOOGLE_SIGNED_IN',
                value: true
            });

            dispatch({
                type: 'SET_GOOGLE_SCHEDULE_COUNT',
                value: datesToday.length,
            });
        }, error => {
            dispatch({
                type: 'SET_LOADING_SCHEDULES',
                value: false
            });

            dispatch({
                type: 'SET_GOOGLE_SIGNED_IN',
                value: false
            })
        });
}

export const updateSchedule = (id, data) => dispatch => {

    const url = config.BASE_URL + '/parse/classes/ConferenceCall/' + id;

    return parseAction("put", url, data)
        .then((response) => {
            dispatch({
                type: 'UPDATE_SCHEDULE_SUCCESS',
                id,
                data
            });
        });
}

export const setFilteredSchedules = (schedules, str) => dispatch => {
    const telemed_events = schedules.filter(sched => {
        const new_sched = {
            ...sched,
            description: sched.description || '',
        };
        const descHasUrl = (new_sched.description || '').includes(telemedUrl);
        const commentsHasUrl = (new_sched.comments || '').includes(telemedUrl);
        return descHasUrl || commentsHasUrl;
    });
    const selected_events = str.includes('TELEMED') ? telemed_events : schedules;

    dispatch({
        type: 'SET_SELECTED_SCHEDULES',
        selected_conferences: selected_events
    });
}

export const onConferenceEnd = () => dispatch => {
    dispatch({
        type: 'REMOVE_CONFERENCE',
    });
}

/* 
    Launch a telemed immediately,
    then either share copied link or invite hubchart contacts
*/
export const launchTelemed = (participantIds = [], options = {}) => dispatch => {

    const { circleId } = options;

    openConferenceWindow({ hasVideo: false, participantIds, circleId });
}

export const linkToCalendar = () => dispatch => {
    dispatch({
        type: 'LINK_TO_IMPORTANT',
    });
}

function openConferenceWindow({ hasVideo = false, participantIds = [], circleId }) {

    const urlParts = [
        window.location.origin,
        "/conferencecall",
        "?action=create",
        `&hasVideo=${hasVideo}`,
        `&participantIds=${participantIds.join(',')}`,
    ];

    if (circleId) urlParts.push(`&circleId=${circleId}`);

    const conferenceUrl = urlParts.join('');

    window.open(conferenceUrl, "Hubchart Conference Call", "status=1,menubar=0");

    localStorage.removeItem('conferenceId');
}
