import React, { Component } from 'react';
import PropTypes from 'prop-types'
import Spinner from './Spinner';

class TabLoader extends Component {

    render() {
        const {isLoading} = this.props

        const containerStyles = {
            display: isLoading ? "flex" : "none",
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
        };

        return (
            <div style={containerStyles}>
                <Spinner
                    size={5}
                    color={"#fbba09"}
                    loading={true} />
            </div>
        );
    }
}

TabLoader.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    message: PropTypes.string,
};

export default TabLoader;
