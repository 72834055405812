import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import ContactSelector from './ContactSelector';
import { updateConferenceCall } from '../../../actions/ConferenceAction';
import Loading from '../../Pages/Signup/Loading';
import Notify from '../../Common/Notify';

const $ = window.$;

function UpdateConnferenceModal(props) {

    const modalId = '#update_conference_modal';

    // State hook
    const [loading, setLoading] = useState(false);
    const [label, setLabel] = useState('');
    const [comments, setComments] = useState('');
    const [chiefComplaint, setChiefComplaint] = useState('');
    const [dob, setDob] = useState('');

    // Dispatch hook
    const dispatch = useDispatch();

    // Use componentDidmount lifecycle
    useEffect(componentDidMount, [props.isOpen, props.conference]);

    function componentDidMount() {
        if (props.isOpen) {
            $(modalId).modal({
                backdrop: 'static',
                keyboard: false
            });
            $(modalId).modal('show');

            const { label = '', comments = '', dob = '', chiefComplaint = '' } = props.conference;

            setLoading(false);
            setLabel(label);
            setComments(comments);
            setChiefComplaint(chiefComplaint);
            setDob(dob);
        } else {
            $(modalId).modal('hide');
        }
        $(modalId).on('hidden.bs.modal', function (e) {

            if (props.onClose) {
                props.onClose();
            }
        }.bind(this))
    }

    function handleSubmit(e) {

        e.preventDefault();

        setLoading(true);

        const data = { label, comments, dob, chiefComplaint };
        const { conference } = props;

        updateConferenceCall(conference.objectId, data)(dispatch)
            .then(() => {

                Notify.success('Conference call updated!');

                props.onClose(props.isActiveCall);
            })
            .catch(() => {

                Notify.error('Could not update conference call.');
            })
    }

    function handleDobChange(value = '') {

        let dob = value;

        if (dob.endsWith('/')) {
            dob = dob.slice(0, -1);
        }
        else if (
            dob.length === 2 ||
            dob.length === 5) {
            dob += '/'
        }

        setDob(dob);
    }

    function render() {
        return (
            <div id="update_conference_modal" className="modal" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">

                        <form onSubmit={handleSubmit}>
                            <div className="modal-header">
                                <h5 className="modal-title hc-primary-text" style={{ fontSize: "1.25rem" }}>
                                    Enter/Edit Notes
                                </h5>

                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>


                            <div className="modal-body" style={{ paddingBottom: "0px" }}>
                                <div className="hc-card mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text hc-input-addon">
                                            <i className="fas fa-address-card"></i>
                                        </span>
                                    </div>
                                    <input value={label} onChange={(e) => setLabel(e.target.value)} type="text" placeholder="Patient name/Topic" required />
                                </div>

                                <div className="hc-card mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text hc-input-addon">
                                            <i className="fas fa-calendar"></i>
                                        </span>
                                    </div>
                                    <input value={dob} onChange={(e) => handleDobChange(e.target.value)} type="text" placeholder="Date of Birth: mm/dd/yyyy" />
                                </div>

                                <div className="hc-card mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text hc-input-addon">
                                            <i className="fas fa-comments"></i>
                                        </span>
                                    </div>
                                    <input value={chiefComplaint} onChange={(e) => setChiefComplaint(e.target.value)} rows="8" placeholder="Enter/edit chief complaint" />
                                </div>

                                <div className="hc-card mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text hc-input-addon">
                                            <i className="fas fa-comments"></i>
                                        </span>
                                    </div>
                                    <textarea value={comments} onChange={(e) => setComments(e.target.value)} rows="8" placeholder="Enter/edit notes" />
                                </div>
                            </div>

                            <div className="modal-footer" align="right">
                                {!loading ?
                                    <div className="form-group" style={{ textAlign: "right" }}>
                                        <button type="submit" style={{ marginRight: "15px" }} className="btn btn-hubchart btn-hubchart-primary">Update</button>
                                        <button type="button" className="btn btn-hubchart btn-hubchart-close" data-dismiss="modal">Close</button>
                                    </div>
                                    :
                                    <div className="form-group" style={{ textAlign: "right", position: "relative", height: "40px" }}>
                                        <Loading />
                                    </div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    return render();
}

export default UpdateConnferenceModal;
