import { StyleSheet, Font } from "@react-pdf/renderer";
const fontFamily = "Open Sans";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf"
    },
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600
    }
  ]
});

const Styles = StyleSheet.create({
  page: {
    // paddingTop: 35,
    paddingVertical: 200,
    paddingHorizontal: 25
  },
  date: {
    position: "absolute",
    top: 15,
    right: 25,
    color: "#878787",
    fontSize: 10
  },
  headerContainer: {
    position: "absolute",
    top: 35,
    left: 25,
    width: "100%"
  },
  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    minHeight: 65,
    maxHeight: 75
  },
  rowView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  preWrapView: {
    whiteSpace: "pre-wrap"
  },
  leftRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    flexWrap: "wrap"
    // alignItems: 'center',
  },
  rteFlex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    flexWrap: "nowrap"
  },
  countCls: {
    minWidth: "15px"
  },
  textXs: { fontSize: 10, fontFamily },
  textXsStrong: { fontSize: 10, fontFamily, fontWeight: "bold" },
  textSm: { fontSize: 12, fontFamily },
  textSmStrong: { fontSize: 12, fontFamily, fontWeight: "bold" },
  textMd: { fontSize: 16, fontFamily },
  textMdStrong: { fontSize: 16, fontFamily, fontWeight: "bold" },
  textLg: { fontSize: 18, fontFamily },
  textLgStrong: { fontSize: 18, fontFamily, fontWeight: "bold" },
  aroundBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    border: 1,
    borderColor: "#000000"
  },
  details: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "left"
  },
  patientBold: {
    fontSize: 10,
    fontFamily,
    fontWeight: "bold",
    width: 50,
    marginRight: 5,
    textAlign: "right"
  },
  patientReg: {
    fontSize: 10,
    fontFamily,
    width: 100,
    marginRight: 5
  },
  divider: {
    borderTop: 1,
    borderColor: "#000000",
    marginVertical: 10
  },
  ordersDivider: {
    borderTop: 1,
    borderColor: "#000000",
    marginTop: 5,
    marginBottom: 10
  },
  drugsFlex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: 3
  },
  drugContainer: {
    marginTop: 5
  },
  drugBold: {
    fontSize: 10,
    fontFamily,
    fontWeight: "bold",
    width: 75,
    marginRight: 5,
    textAlign: "right"
  },
  box: {
    height: 10,
    width: 10,
    border: 1,
    borderColor: "#000000",
    marginRight: 5
  },
  SignatureBox: {
    minHeight: 180,
    maxHeight: 180,
    position: "absolute",
    bottom: 0,
    right: 25,
    width: "100%"
  },
  rxContainer: {
    // minHeight: 105,
    // maxHeight: 105,
  },
  doctorsView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  doctorDetails: {
    textAlign: "right",
    width: 125,
    fontSize: 10,
    fontFamily,
    fontWeight: "bold",
    marginRight: 5
  },
  underline: {
    borderBottom: 1,
    borderColor: "#000000",
    width: 150
  },
  doctorsText: {
    width: 150,
    fontSize: 10,
    fontFamily
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column"
  }
});

export default Styles;
