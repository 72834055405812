import React, { Component } from "react";
import { parseAction } from "../../../actions/REST";
import "../../Pages/Login/Login.css";
import Loading from "../../Pages/Signup/Loading";
import config from "../../../config";
import MemberListModal from "./MemberListModal";
import Notify from "../../Common/Notify";

const $ = window.$;
export class CreateGroup extends Component {
  constructor() {
    super();
    this.state = {
      isCreatingGroup: false,
      memberListShown: false,
      createGroupState: "none",
      groupName: '',
      groupType: 'corporate'
    };
    this.createGroup = this.createGroup.bind(this);
    this.openMemberList = this.openMemberList.bind(this);
  }

  componentDidMount() {

    if (this.props.isOpen) {
      $("#createGroupModal").modal({
        backdrop: "static",
        keyboard: false
      });
      $("#createGroupModal").modal("show");
    } else {
      $("#createGroupModal").modal("hide");
    }
    $("#createGroupModal").on(
      "hidden.bs.modal",
      function(e) {
        this.props.modalClose();
      }.bind(this)
    );
  }

  confirm() {
    this.props.confirmAction();
    $("#createGroupModal").modal("hide");
  }

  UUID() {
    let d = new Date().getTime();
    let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(
      c
    ) {
      let r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16).toUpperCase();
    });
    return uuid;
  }

  openMemberList(e) {
    let name = this.refs.groupName.value;

    this.setState({
      groupName: e.target.value || name,
      isCreatingGroup: true,
      createGroupState: "none"
    })

    console.log('type:', this.state.groupType);

    if (this.state.groupType === 'corporate') {
      this.createGroup([]);
    } else {
      this.setState({ memberListShown: true })
    }
    
    e.preventDefault();
  }

  createGroup(data) {
    console.log('DATA:', data);

    let membersId = [];
    data.map((data) => {
      membersId.push(data.objectId);
    });

    let groupName = this.refs.groupName.value,
        uuid = this.UUID(),
        type = this.state.groupType,
        members = membersId;

    // console.log('membersId:', membersId);

    let self = this;
    parseAction(
      "post",
      config.BASE_URL + "/parse/functions/createRegularGroup",
      {
        name: groupName,
        uuid: uuid,
        type: type,
        members: membersId
      }, {
        "Content-Type": "application/json",
      }
    )
      .then(res => {
        Notify.info("Created new Group " + groupName);

        let group = res.result.circle;
        self.props.newGroup(group);
        this.props.history.push("/u/" + group.objectId);
        this.hideMemberList();
      })
      .catch(error => {
        self.setState({
          isCreatingGroup: false,
          createGroupState: "ERROR"
        });
      });
  }

  handleChange(group) {
    this.setState({
      groupType: group.target.value 
    })
  }

  hideMemberList() {
    $("#memberListModal").modal("hide");
    this.setState({ memberListShown: false });
    $("#createGroupModal").modal("hide");
  }

  render() {
    return (
      <div id="createGroupModal" className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body" style={{ paddingBottom: "0px" }}>
              <h5 className="hc-primary-text" style={{ fontSize: "1.25rem" }}>
                Create Group
              </h5>
              <div
                className="Form-Container"
                style={{ margin: "20px 0", maxWidth: "100%" }}
              >
                <form onSubmit={this.openMemberList}>
                  <div className="form-group">
                    <div className="card">
                      <input
                        ref="groupName"
                        type="text"
                        id="group"
                        placeholder="Group Name"
                        style={{textTransform:'uppercase'}}
                        required
                      />
                      <i className="fas fa-users hc-primary-text"></i>
                    </div>
                    <h6
                      className="modal-title hc-primary-text"
                      style={{ marginTop: '10px' }}
                    >
                      Group Type
                    </h6>
                    <div className="radio" onChange={(e) => this.handleChange(e)}>
                      <input 
                        defaultChecked
                        className="ml-4 mt-3"
                        id="groupCorporate"
                        type="radio" 
                        value="corporate" 
                        name="groupType"
                        /> <label htmlFor="groupCorporate"> Corporate</label> 
                      <br/>
                      <input 
                        className="ml-4"
                        id="groupGeneral"
                        type="radio" 
                        value="general" 
                        name="groupType" 
                        /> <label htmlFor="groupGeneral"> General Use</label>
                    </div>
                  </div>
                  {!this.state.isCreatingGroup ? (
                    <div className="form-group" style={{ textAlign: "right" }}>
                      <button
                        type="submit"
                        style={{ marginRight: "15px" }}
                        className="btn btn-hubchart btn-hubchart-primary"
                      >
                        Create
                      </button>
                      <button
                        type="button"
                        className="btn btn-hubchart btn-hubchart-close"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  ) : (
                    <div
                      className="form-group"
                      style={{
                        textAlign: "right",
                        position: "relative",
                        height: "40px"
                      }}
                    >
                      <Loading />
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
        {this.state.memberListShown && (
          <MemberListModal
            // onNewMember={this.props.onNewMember}
            createGroup={this.createGroup.bind(this)}
            // threadId={"FXsDA8Wpqf"}
            isOpen={this.state.memberListShown}
            onClose={this.hideMemberList.bind(this)}
          />
          )}
      </div>
    );
  }
}

export default CreateGroup;
