import React, { Component } from 'react'

export class Redial extends Component {
    render() {
        let ImageURL;
        if (this.props.callData.image_url === "" || typeof this.props.callData.image_url === undefined) {
            ImageURL = require('../../../assets/images/default.png');
        } else {
            ImageURL = this.props.callData.image_url;
        }

        return (
            <div className="Video-Container">
                <div className="Other-Profile" style={{marginTop: "100px", textAlign: "center"}}>
                    <img src={ImageURL} style={{height: "100px", width: "100px"}} alt="Call Image" className="Call-Image-Profile"></img>
                    <h5 style={{display: "block"}}>{this.props.callData.caller_name}</h5>
                    {this.props.status === "No Answer" && <p>{this.props.status}</p>}
                    {this.props.status === "Call Ended" && <p>{this.props.status}</p>}
                    <div className="row" style={{width: "200px", margin: "0 auto", paddingTop: "20px"}}>
                        <div className="col-6">
                            <a href="#!"  style={{cursor: "pointer"}}>
                                <img onClick={this.props.startCall}  src={require("./images/call_green.png")} style={{height: "45px"}}/>
                            </a>
                            <span>Redial</span>
                        </div>
                        <div className="col-6">
                            <a href="#!" onClick={() => window.close()} style={{cursor: "pointer"}}>
                                <img src={require("./images/close.png")} style={{height: "45px"}}/>
                            </a>
                            <span>Close</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Redial
