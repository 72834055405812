import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Time from '../Time'
import Parse from 'parse'

export class PatientItem extends Component {

    handleItemClick(data, type) {
        if (this.props.fromSearch) {
            this.props.closeSearch();
        }

        if (type === "message") {
            this.props.setActiveThread(data);
            return;
        }

        this.props.createThread(data, type);
    }

    render() {

        let chart = this.props.patient,
            currentPlan,
            chartImage;

            let currentUser = Parse.User.current();
        let containerStyle = {
            border: "0px solid white",
            paddingLeft: "72px",
            minHeight: "65px",
            height: "65px",
            borderRadius: "0px"
        };
        let patientColorScheme = {
            fontSize: "11px",
            fontWeight: "bold",
            float: "right",
            marginTop: "5px"
        }


        let Image,
            Name,
            Sender,
            Date,
            Text,
            isSubgroup = false,
            isCCMGroup = false,
            CCMCurrentPlan,
            Seen = true,
            TextLine,
            UnreadBadge,
            badgeColor = {},
            nameStyle = {};

        let patientThread = false,
            patientAdminTags = "";

        let InactiveBadge,
            CCMDOB,
            CCMTags;


        isCCMGroup = true; // default true since chart is ccm
        if (chart.ccmProgram) {
            if (chart.ccmProgram.currentPlan) {
                currentPlan = chart.ccmProgram.currentPlan;
                CCMCurrentPlan = currentPlan.toUpperCase();
            }
        }

        patientColorScheme = {
            fontSize: "11px",
            fontWeight: "bold",
            float: "right"
        }
        if (currentPlan === "none" || currentPlan === "" || !currentPlan) {
            patientColorScheme.color = "#ff99c7";
            chartImage = require("../../../assets/images/none-default.png");
            CCMCurrentPlan = "NONE"
        }
        if (currentPlan === "nf2f") {
            patientColorScheme.color = "rgb(238, 151, 73)";
            chartImage = require("../../../assets/images/nf2f-default.png");
        }
        if (currentPlan === "ccm") {
            patientColorScheme.color = "#9550b3";
            chartImage = require("../../../assets/images/ccm-default.png");
        }
        if (currentPlan === "tcm") {
            patientColorScheme.color = "#00b9ea";
            chartImage = require("../../../assets/images/tcm-default.png");
        }
        if (currentPlan === "cpo") {
            patientColorScheme.color = "#23aaa0";
            chartImage = require("../../../assets/images/cpo-default.png");
        }

        if (typeof chart.image !== "undefined") {
            chartImage = chart.image.url;
        }

        if (chart.patientStatus === "inactive") {
            patientColorScheme.marginTop = "5px";
            InactiveBadge = <span style={{display: "block", fontSize: "10px", borderRadius: "0.10rem"}} className="badge badge-secondary">INACTIVE</span>;
        }
        var tags;
        if (chart.tags) {
            tags = chart.tags.join(' ');
            containerStyle.minHeight = "80px"
        }
        CCMDOB = <p className="text-content" style={{display: "block", fontSize: "14px", color: " rgb(95, 93, 93)", margin: "0px"}}> { typeof chart.dob !== "undefined" ? chart.dob : "DOB: PENDING"} </p>;
        CCMTags = <p className="text-content">{tags}</p>;

        Image = <img src={chartImage} alt="" className="profile-image rounded-circle" />
        Name = chart.name;


        const isActiveThread = (path, match, location) => !!(match || path === location.pathname);

        let idPath = chart.objectId;

        return (
            <div style={{position: "relative"}}>
                <NavLink to={"/u/"+idPath} onClick={this.handleItemClick.bind(this, chart, "chart")} activeClassName="activeThread" isActive={isActiveThread.bind(this, "/u/"+idPath)} className="list-group-item list-group-item-action flex-column align-items-start" style={containerStyle}>
                    {Image}
                    { UnreadBadge }
                    <p className="name" style={nameStyle}>{Name}</p>
                    {patientThread && <p style={{fontSize: "12px", color: "#3e78b2",textTransform: "uppercase"}} className="name"><span style={{background: "#3e78b2", position: "relative", top: "-1px"}} className="badge badge-primary">ADMIN</span> {chart.groupTags}</p>}
                    <div className="secondary-content" style={{top: '15px', right: "30px"}}>
                        { Date && <Time date={Date}/> }
                        { InactiveBadge }
                    </div>
                    { TextLine }
                    { CCMDOB }
                    { CCMTags }
                </NavLink>
                {chart.excludeFromOrganization === false || !chart.excludeFromOrganization ?
                    <span onClick={() => this.props.removeGroupsFromOrganization(chart.objectId)} className="Patient-Star Star-Yellow"><i className="fas fa-star"></i></span>
                    :
                    <span onClick={() => this.props.addGroupsToOrganization(chart.objectId)} className="Patient-Star"><i className="fas fa-star"></i></span>
                }
            </div>
        )
    }
}

export default PatientItem
