import React from "react";
import TextAreaList from "../../TextareaComponent/TextAreaList";

export function AddDiagnosisModal({
  addTextAreaField = () => {},
  removeTextAreaField = () => {},
  handleCloseAddDiagnosisModal = () => {},
  handleChangeTextAreaDetails = () => {},
  saveTempDiagnosis = () => {},
  selectedTempDiagnosis = [],
  method
}) {
  return (
    <div
      id="patientAddDiagnosisModal"
      className="modal"
      tabIndex="-1"
      role="dialog"
    >
      <div
        className="modal-dialog modal-print"
        role="document"
        style={{ zIndex: "1050" }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="hc-primary-text" style={{ fontSize: "1.25rem" }}>
              Diagnosis Details
            </h5>
          </div>
          <div className="modal-body">
            <div className="rx-input-container input-handler">
              <TextAreaList
                data={selectedTempDiagnosis}
                handleChangeTextAreaDetails={handleChangeTextAreaDetails}
                addTextAreaField={addTextAreaField}
                removeTextAreaField={removeTextAreaField}
                method={method}
                labels={{
                  prop: "longDescription",
                  placeholder: "long description"
                }}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-hubchart btn-hubchart-primary"
              onClick={() => saveTempDiagnosis()}
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-hubchart btn-hubchart-close"
              onClick={() => handleCloseAddDiagnosisModal()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddDiagnosisModal;
