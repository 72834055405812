import React, { useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import usePatients from './usePatients';
import Item from './Item';
import SearchItem from './SearchItem';
import TabLoader from '../../../../Common/TabLoader';
import SearchInput from './SearchInput';
import { searchRecentPatients, setIsSearching } from '../../../../../actions/PatientAction';

const PatientSelect = ({ 
  onSelect = () => {}, 
  onClose = () => {},
  onOpenPatientModal,
}) => {

  const dispatch = useDispatch();
  

  const {
    isSearching,
    isLoading,
    searchItems,
    items,
  } = usePatients();

  const isEmpty = isSearching && !isLoading && !searchItems.length;

  const handleSearch = (keyword) => {

    dispatch(
      setIsSearching(true)
    );

    dispatch(
      searchRecentPatients(keyword)
    );
  }

  const handleClear = () => {
    dispatch(
      setIsSearching(false)
    );
  }

  return (
    <Fragment>
      <div className="d-flex flex-column h-100">
        <div className="py-3">
          <div className="d-flex">
            <SearchInput onSearch={handleSearch} onClear={handleClear} />
            <div className="ml-2">
              <button 
                onClick={() => {
                  onOpenPatientModal(true);
                  setTimeout(() => {
                    onClose();
                  }, 100)
                }} 
                className="btn btn-light bg-white c-patient" 
                data-toggle="tooltip" 
                data-placement="top" 
                data-html="true" 
                title="Create New Patient">
                <i className="fas fa-fw fa-user-plus hc-primary-text"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="h-100" style={{ overflowY: 'auto', position: 'relative' }}>
          <div className="mycard-1 py-0 w-100" style={{ position: 'absolute' }}>
            { isSearching ?
              <SearchItems isLoading={isLoading} items={searchItems} onSelect={circleId => onSelect(circleId)} /> :
              <RecentItems isLoading={isLoading} items={items} onSelect={circleId => onSelect(circleId)} />
            }
          </div>
          <NoResults show={isEmpty} />
          <TabLoader isLoading={isLoading} />
        </div>
      </div>
    </Fragment>
  );
};

const RecentItems = ({items, isLoading, onSelect}) => {

  if (isLoading) return '';

  return (
    <div className="list-group">
      {items.map(recent =>
        <Item
          key={recent.objectId}
          recent={recent}
          onSelect={(e) => onSelect(recent.threadId)} />
      )}
    </div>
  )
}

const SearchItems = ({items, isLoading, onSelect}) => {

  if (isLoading) return '';

  return (
    <div className="list-group">
      {items.map(circle =>
        <SearchItem
          key={circle.objectId}
          circle={circle}
          onSelect={(e) => onSelect(circle.objectId)} />
      )}
    </div>
  )
}

const NoResults = ({ show }) => {

  if (!show) return '';

  return (
    <div style={{ height: '100%' }} className="d-flex flex-column justify-content-center">
      <span className="text-empty">No results</span>
    </div>
  )
};

export default PatientSelect;
