import React, { Component, useEffect, useMemo, useRef, useState } from 'react'
import OverlayLoader from './OverlayLoader';
import ImagePlaceholder from './ImagePlaceholder';

const ImageLoad = ({ openLightbox, imageURL, iconSize, imageStyles={} }) => {

	const [width, setWidth] = useState('auto');
	const [height, setHeight] = useState('auto');
	const [hasLoaded, setHasLoaded] = useState(false);
	const [errLoading, setErrLoading] = useState(false);

	const styles = useMemo(() => {
		return {
			background: 'gray',
			display: 'block',
			cursor: 'pointer',
			// marginBottom: "15px",
			width,
			height,
			...imageStyles,
		};
	}, [width, height, imageStyles]);

	const handleImageLoad = () => {
		setHasLoaded(true);
		setWidth('auto');
		setHeight('auto');
	}

	const handleImageError = () => {
		setHasLoaded(true);
		setErrLoading(true);
	}

	useEffect(() => {
		setWidth('auto');
		setHeight('auto');
		setHasLoaded(false);
		setErrLoading(false);
	}, [imageURL]);

	return (
		<div style={{ position: 'relative' }}>
			<img
				onLoad={e => handleImageLoad()}
				onError={e => handleImageError()}
				className="img-fluid image-lazy-fade-in"
				onClick={openLightbox}
				style={styles}
				src={imageURL}
				alt="Photo"
			/>
			<OverlayLoader background="transparent" color="white" isLoading={!hasLoaded}/>
			<Placeholder show={errLoading} iconSize={iconSize}/>
		</div>
	)
}

const Placeholder = ({ show, iconSize="5x" }) => {

	const styles = {
		position: 'absolute',
		top: '0',
		left: '0',
		backgroundColor: 'white',
		width: '200px',
		height: '200px',
		display: show ? 'flex' : 'none',
		justifyContent: 'center',
		alignItems: 'center',
	}

	return (
		<div style={styles}>
			<ImagePlaceholder type="image" iconSize={iconSize}/>
		</div>
	)
}

export default ImageLoad
