import React, { Component } from 'react'
import Draggable from 'react-draggable'
import { Document, Page, pdfjs } from 'react-pdf';
import moment from 'moment'
import './FilePreview.css'


const PDFThumbnail = require('../../assets/images/pdf.png');
const defualtPicture = require('../../assets/images/default.png')

export class index extends Component {
    constructor() {
        super();
        this.state = {
            defaultScale : 1,
            defaultDeg : 360,
            cantBeDrag : true,
            images : [],
            currentIndex : 0,
            openPopover : false
        }

        this.closeModal = this.closeModal.bind(this);
        this.zoomIn = this.zoomIn.bind(this);
        this.zoomOut = this.zoomOut.bind(this);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this)

        this.openPopover = this.openPopover.bind(this)
        this.closePopover = this.closePopover.bind(this);
    }

    componentDidMount() {
        if (this.props.isOpen) {
            this.refs.filePreview.style.display = "block";
        } else {
            this.refs.filePreview.style.display = "none";
        }
        this.setState({
            images : this.props.images,
            currentIndex : this.props.currentIndex
        })

    }

    closeModal() {
        this.refs.filePreview.style.display = "none";
        this.props.modalClose();
    }

    zoomIn(e) {
        if (this.state.defaultScale === 4) {
            return;
        }

        this.refs.primaryImage.style.transform = "scale("+ (this.state.defaultScale + 1) +")";
        this.setState({
            defaultScale : this.state.defaultScale + 1,
            cantBeDrag : false
        })
    }

    zoomOut() {
        if (this.state.defaultScale === 1) {
            return;
        }

        this.refs.primaryImage.style.transform = "scale("+ (this.state.defaultScale - 1) +")";
        this.setState({
            defaultScale : this.state.defaultScale - 1
        })
    }

    next() {
        if (this.refs.imageContainer) {
            this.refs.imageContainer.style.transform = "translate(0px, 0px)";
        }
        if (this.refs.primaryImage) {
            this.refs.primaryImage.style.transform = "scale(1)";
        }
        this.setState({
            cantBeDrag : true,
            defaultScale : 1,
            defaultDeg : 360,
            currentIndex : this.state.currentIndex + 1
        })

    }

    previous() {
        if (this.refs.imageContainer) {
            this.refs.imageContainer.style.transform = "translate(0px, 0px)";
        }
        if (this.refs.primaryImage) {
            this.refs.primaryImage.style.transform = "scale(1)";
        }
        this.setState({
            currentIndex : this.state.currentIndex - 1,
            cantBeDrag : true,
            defaultScale : 1,
            defaultDeg : 360
        })
    }

    thumbnailAction(data) {
        let index = this.state.images.indexOf(data);
        if (index < 0) return;
        if (this.refs.imageContainer) {
            this.refs.imageContainer.style.transform = "translate(0px, 0px)";
        }
        if (this.refs.primaryImage) {
            this.refs.primaryImage.style.transform = "scale(1)";
        }

        this.setState({
            currentIndex : index + 1,
            cantBeDrag : true,
            defaultScale : 1,
            defaultDeg : 360
        })
    }


    paginateThumbnail(items, currentIndex = 1) {
        if (items.length === 0) return [];
        let emptyIndex = 0,
        emptyLeftItems = [],
        emptyRightItems = [],
        leftItems = [],
        rightItems = [];
        // activeIndex is only 0, 1, 2 ,3

        if (currentIndex < 4) {
            emptyIndex = 4 - currentIndex;
            emptyLeftItems = [...Array(emptyIndex)].map(() => {
                return null;
            });
        }

        rightItems = items.slice(currentIndex - 1, currentIndex + 3);
        // console.log(emptyIndex)
        if (emptyIndex !== 0) {
            leftItems = items.slice(currentIndex - (4 - emptyIndex), currentIndex - 1)
        } else {
            leftItems = items.slice(currentIndex - 4, currentIndex - 1)
        }

        if (rightItems.length < 4) {
            let numberOfEmptyRight = 4 - rightItems.length;
            emptyRightItems = [...Array(numberOfEmptyRight)].map(() => {
                return null;
            });
        }

        // console.log(emptyLeftItems, leftItems, rightItems, emptyRightItems)

        let tempItems = [].concat.apply([], [emptyLeftItems, leftItems, rightItems, emptyRightItems])
        // return object with all pager properties required by the view
        return tempItems;
    }

    downloadFile() {
        let url = this.state.images[this.state.currentIndex - 1].url;
        if (!url) return;

        // Source: http://pixelscommander.com/en/javascript/javascript-file-download-ignore-content-type/

        //iOS devices do not support downloading. We have to inform user about this.
        if (/(iP)/g.test(navigator.userAgent)) {
            alert('Your device does not support files downloading. Please try again in desktop browser.');
            window.open(url, '_blank');
            return false;
        }

        //If in Chrome or Safari - download via virtual link click
        if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1 || navigator.userAgent.toLowerCase().indexOf('safari') > -1) {
            //Creating new link node.
            var link = document.createElement('a');
            link.href = url;
            link.setAttribute('target','_blank');

            if (link.download !== undefined) {
                //Set HTML5 download attribute. This will prevent file from opening if supported.
                var fileName = url.substring(url.lastIndexOf('/') + 1, url.length);
                link.download = fileName;
            }

            //Dispatching click event.
            if (document.createEvent) {
                var e = document.createEvent('MouseEvents');
                e.initEvent('click', true, true);
                link.dispatchEvent(e);
                return true;
            }
        }

        // Force file download (whether supported by server).
        if (url.indexOf('?') === -1) {
            url += '?download';
        }

        window.open(url, '_blank');
        return true;


    }


    openPopover() {
        this.setState({
            openPopover : true
        })
    }

    closePopover() {
        this.setState({
            openPopover : false
        })
    }

    rotate(direction) {
        // 360 default up
        // 90 right
        // 180 down
        // -90  left
        let deg = [360, 90, 180, -90];
        let rotateTo = ""
        let index = ""
        if (direction === "left") {
            index = deg.indexOf(this.state.defaultDeg);
            if (index === 0) {
                rotateTo = deg[deg.length - 1];
            } else {
                rotateTo = deg[index - 1];
            }
        } else if (direction === "right") {
            index = deg.indexOf(this.state.defaultDeg);
            if (index === deg.length - 1) {
                rotateTo = deg[0];
            } else {
                rotateTo = deg[index + 1];
            }
        }
        this.refs.primaryImage.style.transform = "rotate("+rotateTo+"deg)";
        this.setState({
            defaultDeg : rotateTo
        })
    }

    render() {
        let paginate = this.paginateThumbnail(this.state.images, this.state.currentIndex)
        let Thumbnail = paginate.map((data, i) => {
            if (data === null) {
                return <div key={i} className="thumbnal" style={{cursor: "auto"}}></div>
            }

            let url = data.url;
            if (data.isPDF) {
                url = PDFThumbnail;
            }
            let imageStyle = {
                backgroundImage: "url("+ url +")",
            }
            if (i === 3) {
                return <div key={i} className="thumbnal" style={imageStyle}></div>
            }
            return <div onClick={this.thumbnailAction.bind(this, data)} key={i} className="thumbnal" style={imageStyle}>
                <div className="Image-Overlay"></div>
            </div>
        })

        let mainImage;
        let isPDF = false;
        let isIMG = true;
        let file;
        let senderProfile = defualtPicture;
        let senderName = "";
        let uploaderProfile = defualtPicture;
        let uploaderName = "";
        let date = "";
        let originalDateSent = "";
        if (this.state.images.length !== 0) {
            file = this.state.images[this.state.currentIndex - 1];
            mainImage = file.url;
            isPDF = file.isPDF || false;
            isIMG = file.isIMG || false;
            if (file.originalSender.picture) {
                senderProfile = file.originalSender.picture.url
            }
            senderName = file.originalSender.displayName;
            if (file.user.picture) {
                uploaderProfile = file.user.picture.url
            }

            uploaderName = file.user.displayName;
            date = file.date;
            if (file.originalDateSent) {
                if (file.originalDateSent.iso) originalDateSent = moment(file.originalDateSent.iso).format("LLLL");
            }
        }

        let disabledZoomIn = false;
        let disabledZoomOut = false;

        if (this.state.defaultScale === 1) {
            disabledZoomOut = true;
        }

        if (this.state.defaultScale === 4) {
            disabledZoomIn = true;
        }
        // onMouseLeave={this.closePopover}
        // onHide={this.closePopover.bind(this)} >
        return (
            <div ref="filePreview" className="File-Preview-Modal">
                <div className="Action-Container">
                    <button className="File-Action-Button Show-Info"><img style={{width: "22px"}} src={require("../../assets/images/info.png")}/></button>
                    <div className="Info-Message">
                        <div className="Info-Arrow-Up"></div>
                        <div className="media">
                            <img src={senderProfile} style={{width: "50px", height: "50px", borderRadius: "50%"}} className="mr-3" alt="..." />
                            <div className="media-body">
                                <h5 className="mt-0" style={{fontSize: "15px", margin: "0px", fontWeight: "500"}}>{senderName}</h5>
                                <span style={{lineHeight: "14px", fontSize: "12px", marginTop: "5px"}}>Original sender of this file</span>
                                <p style={{lineHeight: "14px", fontSize: "12px", marginTop: "5px"}}>{originalDateSent}</p>
                            </div>
                        </div>
                        <div className="media" style={{marginTop : "10px"}}>
                            <img src={uploaderProfile} style={{width: "50px", height: "50px", borderRadius: "50%"}} className="mr-3" alt="..." />
                            <div className="media-body">
                                <h5 className="mt-0" style={{fontSize: "15px", margin: "0px", fontWeight: "500"}}>{uploaderName}</h5>
                                <span style={{lineHeight: "14px", fontSize: "12px", marginTop: "5px"}}>Uploaded this file on {this.props.documentName}</span>
                                <p style={{lineHeight: "14px", fontSize: "12px", marginTop: "5px"}}>{moment(date).format("LLLL")}</p>
                            </div>
                        </div>
                    </div>
                    <button className="File-Action-Button" onClick={this.downloadFile.bind(this)}>
                        <img style={{width: "22px"}} src={require("../../assets/images/file-download.png")}/>
                        <span style={{fontSize: "15px"}}>Download</span>
                    </button>
                    {isPDF && (
                        <a href={mainImage} target="_blank" className="File-Action-Button">
                            <img style={{width: "22px"}} src={require("../../assets/images/view.png")}/> 
                            <span style={{fontSize: "15px"}}>View PDF</span>
                        </a>
                    )}
                    {isIMG && (
                        <button 
                            className="File-Action-Button" 
                            onClick={this.zoomIn} 
                            disabled={disabledZoomIn}>
                            <img style={{width: "22px"}} src={require("../../assets/images/zoom-in.png")}/>
                            <span style={{fontSize: "15px"}}>Zoom In</span>
                        </button>
                    )}
                    {isIMG && (
                        <button 
                            className="File-Action-Button" 
                            onClick={this.zoomOut} 
                            disabled={disabledZoomOut}>
                            <img style={{width: "22px"}} src={require("../../assets/images/zoom-out.png")}/>
                            <span style={{fontSize: "15px"}}>Zoom Out</span>
                        </button>
                    )}
                    {isIMG && (
                        <button 
                            className="File-Action-Button" 
                            onClick={this.rotate.bind(this, "left")}>
                            <img style={{width: "21px"}} src={require("../../assets/images/rotate-left.png")}/>
                            <span style={{fontSize: "15px"}}></span>
                        </button>
                    )}
                    {isIMG && (
                        <button 
                            className="File-Action-Button" 
                            onClick={this.rotate.bind(this, "right")}>
                            <img style={{width: "21px"}} src={require("../../assets/images/rotate-right.png")}/>
                            <span style={{fontSize: "15px"}}></span>
                        </button>
                    )}
                    <button 
                        className="File-Action-Button" 
                        onClick={this.closeModal}>
                        <img style={{width: "100%"}} src={require("../../assets/images/close_file_preview.png")}/>
                    </button>
                </div>
                <div className="File-Preview-Modal-Content">
                    <div className="File-Info">

                    </div>
                    <Draggable disabled={true || this.state.cantBeDrag}>
                        <div ref="imageContainer" className="Main-Image">
                            {isPDF && <Document file={mainImage} loading={<div><i className="fas fa-circle-notch fa-spin"></i> Loading PDF</div>}><Page pageNumber={1} className="react-pdf__Page-canvas-container" /></Document>}
                            {isIMG && <img ref="primaryImage" draggable="false" src={mainImage} style={{maxHeight: "calc(100vh - 150px)"}}/>}
                        </div>
                    </Draggable>
                    {this.state.currentIndex !== 1 && <button className="Previous-Button" onClick={this.previous}><img style={{width: "100%", verticalAlign: 'unset'}} src={require("../../assets/images/back.png")}/></button>}
                    {this.state.currentIndex !== this.state.images.length && <button className="Next-Button" onClick={this.next}><img style={{width: "100%", verticalAlign: 'unset'}} src={require("../../assets/images/next.png")}/></button>}
                </div>
                <div className="Thumbnail-Container">
                    {Thumbnail}
                </div>
            </div>
        )
    }
}

export default index
