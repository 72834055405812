import React, { Component } from 'react'
import moment from 'moment'

export class Time extends Component {
    beautifyTime(date) {
        let today = moment(new Date());
        let yesterday =  moment().subtract(1, 'day');
        let dateSend = moment(new Date(date));
        if (typeof date === "undefined") {
            return;
        }
        // TODAY
        if(moment(dateSend).isSame(today, 'day')) {
            return moment(new Date(date)).format('h[:]mma');
        } else if(moment(dateSend).isSame(yesterday, 'day')) { // YESTERDAY
            return moment(new Date(date)).format('ddd');
        } else if(moment(dateSend).isSame(today, 'week')) { // SAME WEEK
            return moment(new Date(date)).format('ddd');
        } else if(moment(dateSend).isSame(today, 'year')) { // SAME YEAR
            return moment(new Date(date)).format('MMM D');
        } else {
            return moment(new Date(date)).format('MMM D, YY');
        }

    }

    render() {
        let dateFormated = this.beautifyTime(this.props.date);
        let timeStyle = {
            fontSize: '11px',
            display: "block",
            fontWeight: "normal",
            color: "#000000ab",
            textAlign: "right"
        }
        return (
            <span style={timeStyle}>{dateFormated}</span>
        )
    }
}

export default Time
