const GAPI = window.gapi;
const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"];
const SCOPES = "https://www.googleapis.com/auth/calendar.events";

function googleSignIn () {
  return GAPI.auth2.getAuthInstance().signIn()
}

function googleSignOut () {
  return GAPI.auth2.getAuthInstance().signOut();
}

function getCalendarMeetings() {
  return GAPI.client.calendar.events.list({
    'calendarId': 'primary',
    // 'timeMin': (new Date()).toISOString(),
    'showDeleted': false,
    'singleEvents': true,
    // 'maxResults': 10,
    'orderBy': 'startTime',
    // 'q': telemedUrl,
  }).then(function(response) {
      const { items = [] } = response.result;
      return items;
  }); 
}

function initializeGapi({ apiKey, clientId }, callback) {
  GAPI.load('client:auth2', () => {
    GAPI.client.init({
        apiKey,
        clientId,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES
    }).then(function () {
        GAPI.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);
        updateSigninStatus(GAPI.auth2.getAuthInstance().isSignedIn.get());

        function updateSigninStatus (isSignedIn) {
          callback(isSignedIn);
        }
    }, function(error) {
        callback(false);
    });
  });
}

function createEvent(eventData, callback) {
  const event = handleEventData(eventData);
  
  const request = GAPI.client.calendar.events.insert({
    'calendarId': 'primary',
    'resource': event
  });
  
  request.execute(function(event) {
    callback(event);
  });
}

function updateEvent(eventData, eventId, callback) {
  const event = handleEventData(eventData);
  
  const request = GAPI.client.calendar.events.update({
    'calendarId': 'primary',
    'eventId': eventId,
    'resource': event,
  });
  
  request.execute(function(event) {
    callback(event);
  });
}

function deleteEvent(eventId, callback) {
  const request = GAPI.client.calendar.events.delete({
    'calendarId': 'primary',
    'eventId': eventId,
  });
  
  request.execute(function(response) {
    callback(response);
  });
}

function handleEventData (eventData) {
  return {
    'summary': eventData.summary,
    'location': '',
    'description': eventData.description,
    'start': {
      'dateTime': eventData.start.toISOString(),
      'timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    'end': {
      'dateTime': eventData.end.toISOString(),
      'timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    'recurrence': [
      'RRULE:FREQ=DAILY;COUNT=1'
    ],
    'attendees': eventData.attendees,
    'reminders': {
      'useDefault': false,
      'overrides': [
        {'method': 'email', 'minutes': 60},
        {'method': 'popup', 'minutes': 10}
      ]
    },
  }
}

export {
  initializeGapi,
  googleSignIn,
  googleSignOut,
  createEvent,
  updateEvent,
  getCalendarMeetings,
  deleteEvent,
  GAPI,
  DISCOVERY_DOCS,
  SCOPES,
};
