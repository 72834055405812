import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { parseAction } from "../../../../actions/REST";
import moment from "moment";
import {
  deleteOrder,
  createNewGroupedList,
  getGroupedLists,
  updateGroupedList,
  deleteGroupedList,
} from "../../../../actions/PatientAction";
import Loader from "../../../Common/Loader";
import Notify from "../../../Common/Notify";
import config from "../../../../config";
import { computeAge } from "../../../../helper/util";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "../../../Pages/Login/Login.css";
import "../SettingModal.css";

import AddListModal from "./AddListModal";

const defaultSelectedList = {
  subject: "",
  body: "",
  createdAt: new Date(),
};
const $ = window.$;

function ListModal({ isOpen = false, threadId = "", onClose = () => {} }) {
  const { groupedLists, isGroupedListsLoading } = useSelector(
    mySelector,
    shallowEqual
  );
  const defaultOrder = {
    prescription: "",
    diagnosticStudies: "",
    referrals: "",
    medicalSupplies: "",
  };
  const dispatch = useDispatch();
  const [method, setMethod] = useState("create");
  const [patientInfo, setPatientInfo] = useState(false);
  const [listModalShown, setListModalShown] = useState(false);
  const [addListModalShown, setAddListModalShown] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedList, setSelectedList] = useState([
    {
      subject: "Test Subject",
      body:
        "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.",
    },
  ]);
  const [selectedTempList, setSelectedTempList] = useState([
    { ...defaultSelectedList },
  ]);

  function fetchPatientInfo(threadId) {
    parseAction("get", config.BASE_URL + "/parse/classes/Circle", {
      where: {
        objectId: threadId,
      },
    })
      .then((result) => {
        if (result.results.length === 0) {
          return;
        }
        var data = result.results[0];
        setPatientInfo(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleCreateList() {}

  function handleListOrder() {
    handleListModalShown(true);
  }

  function handleUpdateList(item, index) {
    setMethod("update");
    setSelectedIndex(index);
    setSelectedTempList([{ ...item }]);
    handleSetAddListModalShown();
  }

  function handleAddList() {
    setMethod("create");
    setSelectedTempList([{ ...defaultSelectedList }]);
    handleSetAddListModalShown();
  }

  function removeItem(item, patientId) {
    deleteOrder(item.objectId, patientId, () => {
      Notify.success("List successfully deleted.");
    })(dispatch);
  }

  function handleListModalShown(bool) {
    setListModalShown(bool);
  }

  function handleChangeTextAreaDetails(e, i) {
    const { value } = e.target;
    let newArr = [...selectedTempList];
    newArr[i].body = value;

    setSelectedTempList([...newArr]);
  }

  function handleChangeSubjectDetails(e, i) {
    const { value } = e.target;
    let newArr = [...selectedTempList];
    newArr[i].subject = value;

    setSelectedTempList([...newArr]);
  }

  function closeAddListModal() {
    setAddListModalShown(false);
    setSelectedTempList([]);
    setSelectedIndex(null);
  }

  function addTextAreaField() {
    setSelectedTempList([
      {
        ...defaultSelectedList,
        body: "",
      },
      ...selectedTempList,
    ]);
  }

  function removeTextAreaField(i) {
    let newArr = selectedTempList;
    newArr.splice(i, 1);
    setSelectedTempList([...newArr]);
  }

  function handleCreateGroupedLIsts() {
    const filteredSelectedTempList = selectedTempList.filter(
      (item) => item.body || item.subject
    );
    const promises = filteredSelectedTempList.map((item) => {
      return createNewGroupedList(item, threadId)(dispatch);
    });

    Promise.all(promises).then((res) => {
      getGroupedLists(threadId)(dispatch);
    });
  }

  function handleSaveTempList(list) {
    if (method === "create") {
      handleCreateGroupedLIsts();
    } else {
      let newSelectedList = [...selectedList];
      newSelectedList[0].subject = selectedTempList[0].subject;
      newSelectedList[0].body = selectedTempList[0].body;

      updateGroupedList(newSelectedList[0], list[0].objectId, threadId, () => {
        Notify.success("Grouped list successfully updated");
      })(dispatch);
    }
    closeAddListModal();
  }

  function handleSetAddListModalShown() {
    setAddListModalShown(true);
    setTimeout(() => {
      $("#patientAddListModal").modal({
        backdrop: false,
        keyboard: false,
      });
      $("#patientAddListModal").modal("show");
    }, 100);
  }

  function removeListItem(item) {
    deleteGroupedList(item.objectId, threadId, () => {
      Notify.success("Grouped list successfully deleted");
    })(dispatch);
  }

  useEffect(() => {
    componentDidMount();
  }, [isOpen]);

  function componentDidMount() {
    if (isOpen) {
      localStorage.setItem("someModalIsOpen", "true");
      $("#ListModal").modal({
        backdrop: "static",
        keyboard: false,
      });
      $("#ListModal").modal("show");
      $(".modal-dialog").draggable({
        handle: ".modal-header",
      });
      fetchPatientInfo(threadId);
      getGroupedLists(threadId)(dispatch);
    } else {
      $("#ListModal").modal("hide");
    }

    $("#ListModal").on("hidden.bs.modal", function() {
      localStorage.removeItem("someModalIsOpen");
      onClose();
    });

    $("#patientAddListModal").on("hidden.bs.modal", function(e) {}.bind(this));
  }

  return (
    <Fragment>
      <div id="ListModal" className="modal" tabIndex="-1" role="dialog">
        <div
          className="modal-dialog modal-print"
          role="document"
          style={{ maxWidth: "1000px" }}
        >
          <div className="modal-content modal-full-height">
            <div className="modal-header">
              <h5 className="hc-primary-text" style={{ fontSize: "1.25rem" }}>
                Grouped List
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body body-style" style={{ overflow: "auto" }}>
              <div className="details-container">
                <div>
                  <div className="patient-details">
                    <span>
                      <i className="fas fa-fw fa-hospital-user"></i> Patient
                      Name:{" "}
                    </span>
                    <span>{patientInfo.name}</span>
                  </div>
                  <div className="patient-details">
                    <span>
                      <i className="fas fa-fw fa-address-card"></i> Address:{" "}
                    </span>
                    <span>{patientInfo.address}</span>
                  </div>
                  <div className="patient-details">
                    <span>
                      {" "}
                      <i className="fas fa-fw fa-calendar-day"></i> Birth date:{" "}
                    </span>
                    <span>
                      {patientInfo.dob
                        ? `${patientInfo.dob} (${computeAge(
                            patientInfo.dob
                          )} yrs old)`
                        : "--"}
                    </span>
                  </div>
                </div>
                <div>
                  <div className="patient-details">
                    <span>
                      <i className="fas fa-fw fa-phone"></i> Contact No.:{" "}
                    </span>
                    <span>{patientInfo.phoneNumber}</span>
                  </div>
                  <div className="patient-details">
                    <span>
                      <i className="fas fa-fw fa-allergies"></i> Allergies:{" "}
                    </span>
                    <span>{patientInfo.allergies}</span>
                  </div>
                </div>
              </div>
              <hr style={{ margin: "0px 0px 15px 0px" }} />
              <div className="filter-view">
                <div className="search-flex search-identifier">
                  <div />
                  <button
                    type="button"
                    className="btn btn-hubchart btn-sm btn-hubchart-primary"
                    onClick={() => handleAddList()}
                    style={{ margin: "0px", height: "33px", width: "170px" }}
                  >
                    <i className="fas fa-plus"></i> Add
                  </button>
                </div>
              </div>
              <div className="grouped-list" style={{ overflowY: "auto" }}>
                {groupedLists.length > 0 && (
                  <div className="setting-modal-headers">
                    <span>List</span>
                    <span>Date Entered</span>
                    <div />
                    <span>Action</span>
                  </div>
                )}
                <div className="setting-modal-item-container">
                  {groupedLists.map((item, i) => (
                    <div
                      className="setting-modal-item"
                      key={`po-${moment(new Date()).format("lll")}-${i}`}
                    >
                      <div>
                        <strong>{item.subject}</strong>
                        <div
                          style={{
                            margin: "0px 10px",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {item.body}
                        </div>
                        <br />
                      </div>
                      <span>
                        <span>{moment(item.createdAt).format("lll")}</span>
                      </span>
                      <div />
                      <span>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          title="Update List"
                          onClick={() => handleUpdateList(item, i)}
                        >
                          <i className="fas fa-edit"></i>
                        </button>
                        &nbsp;
                        <button
                          type="button"
                          className="btn btn-danger btn-sm"
                          title="Remove List"
                          style={{ width: "33.75px" }}
                          onClick={() => removeListItem(item)}
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              {groupedLists.length === 0 && (
                <div className="default-view">
                  <span>No List Found.</span>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-hubchart btn-hubchart-close"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
        {addListModalShown && (
          <AddListModal
            handleChangeTextAreaDetails={handleChangeTextAreaDetails}
            handleChangeSubjectDetails={handleChangeSubjectDetails}
            handleCloseAddListModal={closeAddListModal}
            addTextAreaField={addTextAreaField}
            removeTextAreaField={removeTextAreaField}
            saveTempList={handleSaveTempList}
            selectedTempList={selectedTempList}
            method={method}
          />
        )}
        <Loader isLoading={isGroupedListsLoading} />
      </div>
    </Fragment>
  );
}

const mySelector = (state) => ({
  groupedLists: state.patient.groupedLists,
  isGroupedListsLoading: state.patient.isGroupedListsLoading,
});

export default ListModal;
