import { combineReducers } from 'redux';
import SessionReducer from './SessionReducer'
import ThreadReducer from './ThreadReducer';
import GroupReducer from './GroupReducer';
import ContactReducer from './ContactReducer';
import CallReducer from './CallReducer';
import DocumentReducer from './DocumentReducer';
import TeamReducer from './TeamReducer';
import PriorityReducer from './PriorityReducer';
import GlobalReducer from './GlobalReducer';
import ConferenceReducer from './ConferenceReducer';
import DashboardReducer from './DashboardReducer';
import PatientReducer from './PatientReducer';
import ProgressNotesReducer from './ProgressNotesReducer';
import ActiveConferenceReducer from './ActiveConferenceReducer';

export default combineReducers({
    global : GlobalReducer,
    session : SessionReducer,
    thread : ThreadReducer,
    group : GroupReducer,
    contact : ContactReducer,
    call : CallReducer,
    document : DocumentReducer,
    team : TeamReducer,
    priority: PriorityReducer,
    conference: ConferenceReducer,
    dashboard: DashboardReducer,
    patient: PatientReducer,
    notes: ProgressNotesReducer,
    activeConference: ActiveConferenceReducer,
});
