import React from "react";
import TextareaAutosize from "react-textarea-autosize";

var $ = window.$;

export function TextAreaComponent({
  openDocuments = () => {},
  handleChangeTextAreaDetails = () => {},
  handleChangeSubjectDetails = () => {},
  addTextAreaField = () => {},
  removeTextAreaField = () => {},
  data = [],
  method,
  labels = {
    prop: "free_text",
    placeholder: "prescription"
  },
  hasSubject = false
}) {
  function handleArr(data) {
    return data.map((item, i) => (
      <div className="ta-item" key={`${i}-itm`}>
        {method === "create" && (
          <button
            className="doc-abs"
            type="button"
            onClick={() => removeTextAreaField(i)}
          >
            <i className="fa fa-minus" />
          </button>
        )}
        {hasSubject && (
          <input
            name="subject"
            className="form-control"
            value={item.subject}
            placeholder={`Subject #${i + 1}`}
            style={{ marginBottom: "10px", color: "#000000" }}
            onChange={e => handleChangeSubjectDetails(e, i)}
          />
        )}
        <TextareaAutosize
          name="body"
          key={`${i}-pres`}
          className="custom-textarea"
          value={item[labels.prop]}
          style={{ width: "100%", padding: "5px 10px" }}
          minRows={3}
          onChange={e => handleChangeTextAreaDetails(e, i)}
        />
      </div>
    ));
  }

  return (
    <div className="textarea-comp-container">
      <div className="doc-btn-flex">
        {hasSubject && <div />}
        {!hasSubject && (
          <button
            type="button"
            className="doc-btn"
            onClick={() => openDocuments()}
          >
            <i
              className="fa fa-inbox"
              data-toggle="tooltip"
              data-placement="top"
              data-html="true"
              title="Documents"
            />
          </button>
        )}
        {method === "create" && (
          <button
            className="doc-btn"
            type="button"
            onClick={() => addTextAreaField()}
          >
            <i className="fas fa-plus"></i>
          </button>
        )}
      </div>
      <div className="ta-container">{handleArr(data)}</div>
    </div>
  );
}

export default TextAreaComponent;
