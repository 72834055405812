const planArray = [
  {
    title: 'Hematology/Coagulation',
    checklist: [
      { name: 'CBC w/ automated diff', isChecked: false },
      { name: 'CBC w/ manual diff', isChecked: false },
      { name: 'Hemoglobin', isChecked: false },
      { name: 'Hematocrit', isChecked: false },
      { name: 'PT w/ INR', isChecked: false },
      { name: 'PTT', isChecked: false },
      { name: 'D-dimer', isChecked: false },
    ],
  },
  {
    title: 'Common Panels',
    checklist: [
      { name: 'BMP', isChecked: false },
      { name: 'CMP', isChecked: false },
      { name: 'Anemia panel', isChecked: false },
      { name: 'Cardiac panel', isChecked: false },
      { name: 'Hepatitis, Acute', isChecked: false },
      { name: 'Hepatitis, Chronic B/C screen', isChecked: false },
      { name: 'Iron panel', isChecked: false },
      { name: 'Lipid panel', isChecked: false },
      { name: 'Liver panel', isChecked: false },
      { name: 'Renal panel', isChecked: false },
      { name: 'Thyroid panel', isChecked: false },
      { name: 'Arthritis panel', isChecked: false },
    ],
  },
  {
    title: 'Urine Tests',
    checklist: [
      { name: 'UA', isChecked: false },
      { name: 'UA, w/ Reflex Culture', isChecked: false },
      { name: 'Urine toxicology', isChecked: false },
      { name: 'Urine Legionella Ag', isChecked: false },
      { name: 'Spot Urine Protein:Creatinine ratio', isChecked: false },
      { name: '24 hr Urine Protein:Crea ratio', isChecked: false },
      { name: 'Urine catecholamines: VMA, HVA', isChecked: false },
    ],
  },
  {
    title: 'Custom Panels',
    checklist: [
      { name: 'Annual Geriatric Male panel', isChecked: false },
      { name: 'Annual Geriatric Female panel', isChecked: false },
      { name: 'Comprehensive Geriatric Male', isChecked: false },
      { name: 'Comprehensive Geriatric Female', isChecked: false },
      { name: 'Diabetic quarterly routine', isChecked: false },
    ],
  },
  {
    title: 'Microbiology',
    checklist: [
      { name: 'Culture, Aerobic Bacteria', isChecked: false },
      { name: 'Culture, Grp A Strep', isChecked: false },
      { name: 'Culture, Grp B Strep', isChecked: false },
      { name: 'Culture, Throat', isChecked: false },
      { name: 'Culture, sputum', isChecked: false },
      { name: 'Culture, wound', isChecked: false },
    ],
  },
  {
    title: 'Drug Levels',
    checklist: [
      { name: 'Carbamazepine', isChecked: false },
      { name: 'Clozapine', isChecked: false },
      { name: 'Digoxin', isChecked: false },
      { name: 'Lamotrigine', isChecked: false },
      { name: 'Levetiracetam', isChecked: false },
      { name: 'Lithium', isChecked: false },
      { name: 'Phenobarbital', isChecked: false },
      { name: 'Phenytoin', isChecked: false },
      { name: 'Valproic acid', isChecked: false },
    ],
  },
  {
    title: 'STD Screen / Tests',
    checklist: [
      { name: 'Chlamydia/Gonorrhea, NAA, Urine', isChecked: false },
      { name: 'Chlamydia/GC, NAA, Urethral swab', isChecked: false },
      { name: 'Hepatitis Panel', isChecked: false },
      { name: 'HIV 1 and 2 Ag/Ab', isChecked: false },
      { name: 'Herpes Simplex HSV 1 and 2 Ab', isChecked: false },
      { name: 'RPR', isChecked: false },
      { name: 'UA', isChecked: false },
      { name: 'Urine Trichomanas', isChecked: false },
      { name: 'Trichomonas, NAAT urine', isChecked: false },
      { name: 'Trichomonas, NAAT swab', isChecked: false },
      { name: 'VDRL', isChecked: false },
    ],
  },
  {
    title: 'Hormones',
    checklist: [
      { name: 'Male Hormones', type: 'span' },
      { name: 'Testosterone, Total & Free', isChecked: false },
      { name: 'Testosterone, Free', isChecked: false },
      { name: 'Estradiol / Progesterone', isChecked: false },
      { name: 'DHEA', isChecked: false },
      { name: 'Sex Hormone Binding Globulin', isChecked: false },
      { name: 'Prolactin', isChecked: false },
      { name: 'PSA Total', isChecked: false },
      { name: 'Female Hormones', type: 'span' },
      { name: 'FSH/LH', isChecked: false },
      { name: 'Prolactin', isChecked: false },
      { name: 'Estradiol/Progesterone', isChecked: false },
      { name: 'Testosterone Total', isChecked: false },
      { name: 'DHEA', isChecked: false },
      { name: 'Sex Hormone Binding Globulin', isChecked: false },
    ],
  },
  {
    title: 'Immunization Titers',
    checklist: [
      { name: 'Chickenpox (Varicella)', isChecked: false },
      { name: 'Diphtheria Ab', isChecked: false },
      { name: 'Hepatitis B Ab', isChecked: false },
      { name: 'Hepatitis C Ab', isChecked: false },
      { name: 'Measles Ab (IgG)', isChecked: false },
      { name: 'MMR Ab', isChecked: false },
      { name: 'Mumps Virus Ab (IgG)', isChecked: false },
      { name: 'Varicella (VZV) IgG', isChecked: false },
    ],
  },
  {
    title: 'Cancer Markers',
    checklist: [
      { name: 'AFP', isChecked: false },
      { name: 'BCR-ABL', isChecked: false },
      { name: 'CA 19-9', isChecked: false },
      { name: 'CA-125', isChecked: false },
      { name: 'Carcinoembryonic antigen (CEA)', isChecked: false },
      { name: 'CD19', isChecked: false },
      { name: 'Beta-2-microglobulin (B2M)', isChecked: false },
      { name: 'Thyroglobulin', isChecked: false },
      { name: 'Terminal transferase (TdT)', isChecked: false },
      { name: 'Prostate-specific antigen (PSA)', isChecked: false },
      { name: 'Myeloperoxidase (MPO)', isChecked: false },
    ],
  },
  {
    title: 'Rheumatology / Auto-Immune',
    checklist: [
      { name: 'ANA Screen(Reflex Titer/Pattern)', isChecked: false },
      { name: 'Anti-ds DNA', isChecked: false },
      { name: 'Anti-Sm', isChecked: false },
      { name: 'Anti-Ro / Anti-La', isChecked: false },
      { name: 'CCP Ab, IgG (Cyclic Citrullinated Peptide Ab)', isChecked: false },
      { name: 'Anti-parietal cell Ab', isChecked: false },
      { name: 'Antithyroid peroxidase Ab (ATPO)', isChecked: false },
      { name: 'Complement C3 C4', isChecked: false },
      { name: 'CRP', isChecked: false },
      { name: 'Endomysial Antibody (IgA) Screen w/ Reflex Titer', isChecked: false },
      { name: 'ESR', isChecked: false },
      { name: 'Rheumatoid Factor', isChecked: false },
      { name: 'Tissue Transglutaminase IgA', isChecked: false },
      { name: 'Tissue Transglutaminase IgG', isChecked: false },
    ],
  },
  {
    title: 'Other common tests',
    checklist: [
      { name: 'Albumin', isChecked: false },
      { name: 'Alkaline Phosphatase', isChecked: false },
      { name: 'Amylase/Lipase', isChecked: false },
      { name: 'ALT/AST', isChecked: false },
      { name: 'ANA screen', isChecked: false },
      { name: 'Bilirubin, Total & Direct', isChecked: false },
      { name: 'BNP', isChecked: false },
      { name: 'CRP', isChecked: false },
      { name: 'hs-CRP', isChecked: false },
      { name: 'Calcium', isChecked: false },
      { name: 'Calcium,ionized', isChecked: false },
      { name: 'CK, Total', isChecked: false },
      { name: 'Fecal occult blood x 3', isChecked: false },
      { name: 'Ferritin', isChecked: false },
      { name: 'Folate', isChecked: false },
      { name: 'GGT', isChecked: false },
      { name: 'HgA1c', isChecked: false },
      { name: 'Lipase', isChecked: false },
      { name: 'Magnesium', isChecked: false },
      { name: 'Phosphorus', isChecked: false },
      { name: 'Uric acid', isChecked: false },
      { name: 'Potassium', isChecked: false },
    ],
  },
];

const clinicInitial = [
  {
    cptOptGrp_label: "CLINIC NEW ENCOUNTER",
    options: [
      "99202 (15-29 mins)",
      "99203 (30-44 mins)",
      "99204 (45-59 mins)",
      "99205 (60-74 mins)"
    ],
    options_POScode: ['POS-11']
  },
  {
    cptOptGrp_label: "CLINIC NEW PROLONGED VISIT",
    options: [
      "99205, G2212 x 1 (89-103 mins)",
      "99205, G2212 x 2 (104-118 mins)",
      "99205, G2212 x 3 (119-133 mins)"
    ],
    options_POScode: ['POS-11']
  }
];
const clinicFollow_up = [
  {
    cptOptGrp_label: "CLINIC FF-UP VISIT",
    options: [
      "99212 (10-19 mins)",
      "99213 (20-29 mins)",
      "99214 (30-39 mins)",
      "99215 (40-54 mins)"
    ],
    options_POScode: ['POS-11']
  },
  {
    cptOptGrp_label: "CLINIC FF-UP PROLONGED VISIT",
    options: [
      "99215, G2212 x 1 (69-83 mins)",
      "99215, G2212 x 2 (84-98 mins)",
      "99215, G2212 x 3 (99-113 mins)"
    ],
    options_POScode: ['POS-11']
  }
];
const clinicTelemed = [
  {
    cptOptGrp_label: "CLINIC INITIAL TELEHEALTH",
    options: [
      "99202 (15-29 mins)",
      "99203 (30-44 mins)",
      "99204 (45-59 mins)",
      "99205 (60-74 mins)"
    ],
    options_POScode: ['POS-02']
  },
  {
    cptOptGrp_label: "CLINIC ESTABLISHED TELEHEALTH",
    options: [
      "99212 (10-19 mins)",
      "99213 (20-29 mins)",
      "99214 (30-39 mins)",
      "99215 (40-54 mins)"
    ],
    options_POScode: ['POS-02']
  },
  {
    cptOptGrp_label: "PROLONGED CLINIC TELEHEALTH",
    options: [],
  },
  {
    cptOptGrp_label: "PROLONGED TELEHEALTH - NEW PT",
    options: [
      "99205, G2212 x 1 (89-103 mins) ",
      "99205, G2212 x 2 (104-118 mins)",
      "99205, G2212 x 3 (119-133 mins)"
    ],
    options_POScode: ['POS-02']
  },
  {
    cptOptGrp_label: "PROLONGED TELEHEALTH FF-UP",
    options: [
      "99215, G2212 x 1 (69-83 mins) ",
      "99215, G2212 x 2 (84-98 mins) ",
      "99215, G2212 x 3 (99-113 mins)"
    ],
    options_POScode: ['POS-02']
  }
];
const homeInitial = [
  {
    cptOptGrp_label: "HOME INITIAL ENCOUNTER",
    options: [
      // low severity.     
      "99341 (20 mins)",
      //   moderate severity
      "99342 (30 mins)",
      //   high severity 
      "99343 (45 mins)",
      //  High severity 
      "99344 (60 mins)",
      // Unstable, need immediate attention          
      "99345 (75 mins)"
    ],
    options_POScode: ['POS-12']

  },
  {
    cptOptGrp_label: "HOME INITIAL PROLONGED",
    options: [
      "99341 + 99354  (50 mins)",
      "99342 + 99354  (60 mins)",
      "99343 + 99354  (75 mins)",
      "99344 + 99354  (90 mins)",
      "99345 + 99354  (105 mins)",
      "99341 + 99354 + 99355  (95 mins)",
      "99342 + 99354 + 99355  (105 mins)",
      "99343 + 99354 + 99355  (120 mins)",
      "99344 + 99354 + 99355  (135 mins)",
      "99345 + 99354 + 99355  (150 mins)"
    ],
    options_POScode: ['POS-12']
  }
];
const homeFollow_up = [
  {
    cptOptGrp_label: "HOME ESTABISHED ENCOUNTER",
    options: [
      "99347 (15 mins) POS-12",
      "99348 (25 mins) POS-12",
      "99349 (40 mins) POS-12",
      "99350 (60 mins) POS-12"
    ],
    options_POScode: ['POS-12']
  },
  {
    cptOptGrp_label: "HOME ESTABISHED  PROLONGED",
    options: [
      "99347 + 99354 (45 mins)",
      "99348 + 99354 (55 mins)",
      "99349 + 99354 (70 mins)",
      "99350 + 99354 (90 mins)",
      "99347 + 99354, 99355 (90 mins)",
      "99348 + 99354, 99355 (100 mins)",
      "99349 + 99354, 99355 (115 mins)",
      "99350 + 99354, 99355 (135 mins)"
    ],
    options_POScode: ['POS-12']
  }
];
const homeTelemed = [
  {
    cptOptGrp_label: "HOME INITIAL TELEHEALTH",
    options: [
      "99341 (20 mins)",
      "99342 (30 mins)",
      "99343 (45 mins)",
      "99344 (60 mins)",
      "99345 (75 mins)"
    ],
    options_POScode: ['POS-02']
  },
  {
    cptOptGrp_label: "HOME ESTABLISHED TELEHEALTH",
    options: [
      "99347 (15 mins)",
      "99348 (25 mins)",
      "99349 (40 mins)",
      "99350 (60 mins)"
    ],
    options_POScode: ['POS-02']
  }
];
const assistedLiving = [
  {
    cptOptGrp_label: "ASSISTED LIVING NEW",
    options: [
      "99324 (20mins)",
      "99325 (30 mins)",
      "99326 (45 mins)",
      "99327 (60 mins)",
      "99328 (75 mins)"
    ],
    options_POScode: ['POS-13']
  },
  {
    cptOptGrp_label: "ASSISTED LIVING ESTABLISHED",
    options: [
      "99334 (15 mins)",
      "99335 (25 mins)",
      "99336 (40 mins)",
      "99337 (60 mins)"
    ],
    options_POScode: ['POS-13']
  },
  {
    cptOptGrp_label: "ALF NEW PROLONGED",
    options: [
      "99324 + 99354 (50 mins)",
      "99325 + 99354 (60 mins)",
      "99326 + 99354 (75 mins)",
      "99327 + 99354 (90 mins)",
      "99328 + 99354 (105 mins)",
      "99324 + 99354, 99355 (95 mins)",
      "99325 + 99354, 99355 (105 mins)",
      "99326 + 99354, 99355 (120 mins)",
      "99327 + 99354, 99355 (135 mins)",
      "99328 + 99354, 99355 (150 mins)"
    ],
    options_POScode: ['POS-13']
  },
  {
    cptOptGrp_label: "ALF FF-UP PROLONGED",
    options: [
      "99334 + 99354 (45 mins)",
      "99335 + 99354 (55 mins)",
      "99336 + 99354 (70 mins)",
      "99337 + 99354 (90 mins)",
      "99334 + 99354, 99355 (90 mins",
      "99335 + 99354, 99355 (100 mins)",
      "99336 + 99354, 99355 (115 mins)",
      "99337 + 99354, 99355 (135 mins)"
    ],
    options_POScode: ['POS-13']
  }
];
const groupHome = [
  {
    cptOptGrp_label: "GRP HOME NEW",
    options: [
      "99324 (20 mins)",
      "99325 (30 mins)",
      "99326 (45 mins)",
      "99327 (60 mins)",
      "99328 (75 mins)"
    ],
    options_POScode: ['POS-14']
  },
  {
    cptOptGrp_label: "GRP HOME FF-UP",
    options: [
      "99334 (15 mins)",
      "99335 (25 mins)",
      "99336 (40 mins)",
      "99337 (60 mins)"
    ],
    options_POScode: ['POS-14']
  },
  {
    cptOptGrp_label: "GRP HOME NEW PROLONGED",
    options: [
      "99324 + 99354 (50 mins)",
      "99325 + 99354 (60 mins)",
      "99326 + 99354 (75 mins)",
      "99327 + 99354 (90 mins)",
      "99328 + 99354 (105 mins)",
      "99324 + 99354, 99355 (95 mins)",
      "99325 + 99354, 99355 (105 mins)",
      "99326 + 99354, 99355 (120 mins)",
      "99327 + 99354, 99355 (135 mins)",
      "99328 + 99354, 99355 (150 mins)"
    ],
    options_POScode: ['POS-14']
  },
  {
    cptOptGrp_label: "GRP HOME FF-UP PROLONGED",
    options: [
      "99334 + 99354 (45 mins)",
      "99335 + 99354 (55 mins)",
      "99336 + 99354 (70 mins)",
      "99337 + 99354 (90 mins)",
      "99334 + 99354, 99355 (90 mins)",
      "99335 + 99354, 99355 (100 mins)",
      "99336 + 99354, 99355 (115 mins)",
      "99337 + 99354, 99355 (135 mins)"
    ],
    options_POScode: ['POS-14']
  }
];
const domicillaryTelemed = [
  {
    cptOptGrp_label: "TELEHEALTH NEW",
    options: [
      "99324 (20 mins)",
      "99325 (30 mins)",
      "99326 (45 mins)",
      "99327 (60 mins)",
      "99328 (75 mins)"
    ],
    options_POScode: ['POS-02']
  },
  {
    cptOptGrp_label: "TELEHEALTH ESTABLISHED",
    options: [
      "99334 (15 mins)",
      "99335 (25 mins)",
      "99336 (40 mins)",
      "99337 (60 mins)"
    ],
    options_POScode: ['POS-02']
  }
];

const nursingHomeNew = [
  {
    cptOptGrp_label: "NURSING HOME NEW",
    options: ["99304 (25 mins)", "99305 (35 mins)", "99306 (45 mins)"],
    options_POScode: [
      "skilled care POS-31",
      "custodial care POS-32"
    ],
  },
  {
    cptOptGrp_label: "NURSING HOME NEW PROLONGED",
    options: [
      "99304 + 99354 (55 mins)",
      "99305 + 99354 (65 mins)",
      "99306 + 99354 (75 mins)",
      "99304 + 99354, 99355 (100 mins)",
      "99305 + 99354, 99355 (110 mins)",
      "99306 + 99354, 99355 (120 mins)"
    ],
    options_POScode: [
      "skilled care POS-31",
      "custodial care POS-32"
    ],
  }
];

const nursingHomeFfup = [
  {
    cptOptGrp_label: "NURSING HOME FF-UP",
    options: [
      "99307 (10 min)",
      "99308 (15 min)",
      "99309 (25 min)",
      "99310 (35 min)"
    ],
    options_POScode: [
      "skilled care POS-31",
      "custodial care POS-32"
    ],
  },
  {
    cptOptGrp_label: "FF-UP PROLONGED ENCOUNTER",
    options: [
      "99307 + 99354 (40 mins)",
      "99308 + 99354 (45 mins)",
      "99309 + 99354 (55 mins)",
      "99310 + 99354 (65 mins)",
      "99307 + 99354, 99355 (85 mins)",
      "99308 + 99354, 99355 (90 mins)",
      "99309 + 99354, 99355 (100 mins)",
      "99310 + 99354, 99355 (105 mins)"
    ],
    options_POScode: [
      "skilled care POS-31",
      "custodial care POS-32"
    ],
  },
  {
    cptOptGrp_label: "NURSING HOME DISCHARGE",
    options: [
      "99315 (Discharge 30 mins)",
      "99316 (Discharge >30 mins)"
    ],
    options_POScode: [
      "skilled care POS-31",
      "custodial care POS-32"
    ],
  }
];
const nursingHomeTelemed = [
  {
    cptOptGrp_label: "NEW, TELEHEALTH ENCOUNTER",
    options: ["99304 (25 mins)", "99305 (35 mins)", "99306 (45 mins)"],
    options_POScode: ['POS-02']
  },
  {
    cptOptGrp_label: "FF-UP, TELEHEALTH ENCOUNTER",
    options: [
      "99307 (10 min)",
      "99308 (15 min)",
      "99309 (25 min)",
      "99310 (35 min)"
    ],
    options_POScode: ['POS-02']
  },
  {
    cptOptGrp_label: "FF-UP, TELEHEALTH PROLONGED",
    options: [
      "99307 + 99354 (40 mins)",
      "99308 + 99354 (45 mins)",
      "99309 + 99354 (55 mins)",
      "99310 + 99354 (65 mins)",
      "99307 + 99354, 99355 (85 mins)",
      "99308 + 99354, 99355 (90 mins)",
      "99309 + 99354, 99355 (100 mins)",
      "99310 + 99354, 99355 (105 mins)"
    ],
    options_POScode: ['POS-02']
  }
];

export {
  clinicInitial,
  clinicFollow_up,
  clinicTelemed,
  homeInitial,
  homeFollow_up,
  homeTelemed,
  assistedLiving,
  groupHome,
  domicillaryTelemed,
  nursingHomeNew,
  nursingHomeFfup,
  nursingHomeTelemed,
  planArray,
};