import Parse from "parse";
import sort from "fast-sort";
import { parseAction } from "./REST";
import config from "../config";
import util from "../helper/util";

export const deleteAccount = () => (dispatch) => {
    const method = "post";
    const url = config.BASE_URL + "/parse/functions/deleteAccount";
  
    dispatch({
      type: "SET_LOADING",
      value: true,
    });
  
    return parseAction(method, url)
      .then((response) => {
        dispatch({
          type: "SET_LOADING",
          value: false,
        });
        window.location.reload();
      })
      .catch((error) => {
        // TODO handle error when fetching search contact
        console.log(error);
  
        dispatch({
          type: "SET_LOADING",
          value: false,
        });
  
        return Promise.reject(error);
      });
  };

export const showProfileSidebar = () => dispatch => {
    dispatch({
        type: 'SHOW_PROFILE_SIDEBAR',
    });
}

export const hideProfileSidebar = () => dispatch => {
  dispatch({
      type: 'HIDE_PROFILE_SIDEBAR',
  });
}

export const showAccount = (accountType) => dispatch => {
    dispatch({
        type: 'SHOW_ACCOUNT',
        accountType
    });
}

export const hideAccount = () => dispatch => {
    dispatch({
        type: 'HIDE_ACCOUNT',
    });
}
