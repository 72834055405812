import { parseAction, parseActionNoAuth } from "../actions/REST";
import Axios from "axios";
import config from "../config";

function getFiles(threadId, options = {}) {
  const method = "post";

  const url = config.BASE_URL + "/parse/functions/downloadFiles";

  const { page = 1, size = 10 } = options;

  const params = {
    threadId,
    page,
    size,
  };

  return parseAction(method, url, params)
    .then((response) => {
      return response.result;
    })
    .catch((error) => {
      // TODO handle error when fetching search contact
      console.log(error);

      return Promise.reject(error);
    });
}

async function getFilesv2(threadId, options = {}) {
  const method = "post";

  const url = config.BASE_URL + "/parse/functions/downloadFiles:v2";

  const { page = 0, size = 10 } = options;

  const params = {
    threadId,
    page,
    pageSize: size,
  };

  const res = await parseAction(method, url, params);

  return res.result;
}

export const sendGroupMessage = async ({
  attachmentIds = [],
  text = "",
  threadId = "",
  attention,
  urgent,
  replyTo,
  actionItem,
  priorityLevel = 0,
}) => {
  const method = "post";

  const url = config.BASE_URL + "/parse/functions/sendGroupMessage:v3";

  const attentionIds = attention.map((user) => user.objectId);

  const params = {
    originalMessageDate: new Date().toISOString(),
    attention: attentionIds,
    threadId,
    isUrgent: urgent,
    sendSmsOnAttention: attentionIds.length > 0 || urgent ? true : false,
    text,
    actionItem,
    priorityLevel,
  };

  if (replyTo) params.replyTo = replyTo.messageId;
  if (attachmentIds.length) params.attachmentIds = attachmentIds;

  const res = await parseAction(method, url, params, {
    "Content-Type": "application/json",
  });

  return res.result.message;
};

export const sendPrivateMessage = async ({
  text = "",
  partnerObjectId = "",
  attachmentIds = [],
  replyTo,
}) => {
  const method = "post";

  const url = config.BASE_URL + "/parse/functions/sendPrivateMessage:v2";

  const params = {
    originalMessageDate: new Date().toISOString(),
    contactId: partnerObjectId,
    text,
  };

  if (replyTo) params.replyTo = replyTo.messageId;
  if (attachmentIds.length) params.attachmentIds = attachmentIds;

  const res = await parseAction(method, url, params, {
    "Content-Type": "application/json",
  });

  return res.result.message;
};

export const sendMessage = ({
  text = "",
  recent,
  attention = [],
  replyTo,
  urgent,
  attachmentIds = [],
  actionItem,
  priorityLevel = 0,
}) => {
  switch (recent.threadType) {
    case "group":
      return sendGroupMessage({
        replyTo,
        attention,
        urgent,
        threadId: recent.threadId,
        text,
        attachmentIds,
        actionItem,
        priorityLevel,
      });
    case "private":
      return sendPrivateMessage({
        replyTo,
        partnerObjectId: recent.partnerObjectId,
        text,
        attachmentIds,
      });
    /* case 'adhoc':
            return sendAdhocMessage({
                adhocGroupId: recent.threadId,
                text,
                user,
            }); */
    default:
      return Promise.reject(
        "sendMessage(): Unsupported threadType: " + recent.threadType
      );
  }
};

export const createAttachment = async (file) => {
  const fd = new FormData();

  fd.append("file", file);

  const res = await Axios.post(
    "https://api-files-staging.hubchart.net/api/attachment",
    fd,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return res.data.attachment;
};

export default {
  getFiles,
  getFilesv2,
  sendMessage,
  createAttachment,
};
