import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { parseAction } from "../../../../actions/REST";
import moment from "moment";
import {
  getPatientOrders,
  deleteOrder,
  createNewOrderHistory,
  checkUncheckPatientOrder,
} from "../../../../actions/PatientAction";
import Loader from "../../../Common/Loader";
import Notify from "../../../Common/Notify";
import config from "../../../../config";
import { computeAge } from "../../../../helper/util";
import OrdersModal from "../../../Pages/ProgressNotes/OrdersModal";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "../../../Pages/Login/Login.css";
import "../SettingModal.css";

const $ = window.$;

function PatientOrdersModal({
  isOpen = false,
  threadId = "",
  onClose = () => {},
}) {
  const defaultOrder = {
    prescription: "",
    diagnosticStudies: "",
    referrals: "",
    medicalSupplies: "",
  };
  const dispatch = useDispatch();
  const [patientInfo, setPatientInfo] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(defaultOrder);
  const [ordersModalShown, setOrdersModalShown] = useState(false);

  const { patientOrders, isOrdersLoading } = useSelector(
    (state) => ({
      patientOrders: state.patient.patientOrders,
      isOrdersLoading: state.patient.isOrdersLoading,
    }),
    shallowEqual
  );

  function fetchPatientInfo(threadId) {
    parseAction("get", config.BASE_URL + "/parse/classes/Circle", {
      where: {
        objectId: threadId,
      },
    })
      .then((result) => {
        if (result.results.length === 0) {
          return;
        }

        var data = result.results[0];
        const order_list = (data.orders || []).map((item) => ({
          ...item,
          onsetDate: new Date(item.onsetDate.iso),
        }));
        setPatientInfo(data);
        getOrders(threadId);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getOrders(id) {
    getPatientOrders(id)(dispatch);
  }

  function handleCreateOrderHistory() {
    const selOrder = patientOrders.filter((item) => item.isChecked);
    if (selOrder.length === 1) {
      createNewOrderHistory(
        selOrder[0],
        threadId,
        patientInfo.objectId,
        (res) => {
          Notify.success("Order successfully generated.");
          window.open(
            `${window.location.origin}/orders-history/${threadId}/${res.objectId}`,
            "_blank"
          );
        }
      )(dispatch);
    } else {
      Notify.info("Please select one order to generate.");
    }
  }

  function handleCreateOrder() {
    setSelectedOrder(defaultOrder);
    handleOrderModalShown(true);
  }

  function handleUpdateOrder(item) {
    setSelectedOrder(item);
    setOrdersModalShown(true);
  }

  function removeItem(item, patientId) {
    deleteOrder(item.objectId, patientId, () => {
      Notify.success("Order successfully deleted.");
    })(dispatch);
  }

  function onInputCheckedChange(e, idx) {
    const { checked } = e.target;
    checkUncheckPatientOrder(patientOrders, checked, idx)(dispatch);
  }

  function handleOrderModalShown(bool) {
    setOrdersModalShown(bool);
  }

  useEffect(() => {
    componentDidMount();
  }, [isOpen]);

  function componentDidMount() {
    if (isOpen) {
      localStorage.setItem("someModalIsOpen", "true");
      $("#patientOrdersModal").modal({
        backdrop: "static",
        keyboard: false,
      });
      $("#patientOrdersModal").modal("show");
      $(".modal-dialog").draggable({
        handle: ".modal-header",
      });
      fetchPatientInfo(threadId);
    } else {
      $("#patientOrdersModal").modal("hide");
    }

    $("#patientOrdersModal").on("hidden.bs.modal", function() {
      localStorage.removeItem("someModalIsOpen");
      onClose();
    });

    $("#patientAddDiagnosisModal").on(
      "hidden.bs.modal",
      function(e) {}.bind(this)
    );
  }

  return (
    <Fragment>
      <div
        id="patientOrdersModal"
        className="modal"
        tabIndex="-1"
        role="dialog"
      >
        <div
          className="modal-dialog modal-print"
          role="document"
          style={{ maxWidth: "1000px" }}
        >
          <div className="modal-content modal-full-height">
            <div className="modal-header">
              <h5 className="hc-primary-text" style={{ fontSize: "1.25rem" }}>
                Patient Orders
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body body-style" style={{ overflow: "auto" }}>
              <div className="details-container">
                <div>
                  <div className="patient-details">
                    <span>
                      <i className="fas fa-fw fa-hospital-user"></i> Patient
                      Name:{" "}
                    </span>
                    <span>{patientInfo.name}</span>
                  </div>
                  <div className="patient-details">
                    <span>
                      <i className="fas fa-fw fa-address-card"></i> Address:{" "}
                    </span>
                    <span>{patientInfo.address}</span>
                  </div>
                  <div className="patient-details">
                    <span>
                      {" "}
                      <i className="fas fa-fw fa-calendar-day"></i> Birth date:{" "}
                    </span>
                    <span>
                      {patientInfo.dob
                        ? `${patientInfo.dob} (${computeAge(
                            patientInfo.dob
                          )} yrs old)`
                        : "--"}
                    </span>
                  </div>
                </div>
                <div>
                  <div className="patient-details">
                    <span>
                      <i className="fas fa-fw fa-phone"></i> Contact No.:{" "}
                    </span>
                    <span>{patientInfo.phoneNumber}</span>
                  </div>
                  <div className="patient-details">
                    <span>
                      <i className="fas fa-fw fa-allergies"></i> Allergies:{" "}
                    </span>
                    <span>{patientInfo.allergies}</span>
                  </div>
                </div>
              </div>
              <hr style={{ margin: "0px 0px 15px 0px" }} />
              <div className="filter-view">
                <div className="search-flex search-identifier">
                  <div />
                  <button
                    type="button"
                    className="btn btn-hubchart btn-sm btn-hubchart-primary"
                    onClick={() => handleCreateOrder()}
                    style={{ margin: "0px", height: "33px", width: "170px" }}
                  >
                    <i className="fas fa-plus"></i> Add
                  </button>
                </div>
              </div>
              <div className="orders" style={{ overflowY: "auto" }}>
                {patientOrders.length > 0 && (
                  <div className="setting-modal-headers">
                    <div />
                    <span>Orders</span>
                    <span>Date Entered</span>
                    <span>Action</span>
                  </div>
                )}
                <div className="setting-modal-item-container">
                  {patientOrders.map((item, i) => (
                    <div
                      className="setting-modal-item"
                      key={`po-${moment(new Date()).format("lll")}-${i}`}
                    >
                      {/* <div>
                        <input 
                          type="checkbox" 
                          id="isChecked"
                          name="isChecked"
                          checked={item.isChecked}
                          onChange={e => onInputCheckedChange(e, i)} />
                      </div> */}
                      <div
                        onClick={() =>
                          onInputCheckedChange(
                            { target: { checked: !item.isChecked } },
                            i
                          )
                        }
                      >
                        <div class="round-small">
                          <input
                            type="checkbox"
                            id="isChecked"
                            name="isChecked"
                            checked={item.isChecked}
                          />
                          <label for="checkbox"></label>
                        </div>
                      </div>
                      <div
                        onClick={() =>
                          onInputCheckedChange(
                            { target: { checked: !item.isChecked } },
                            i
                          )
                        }
                      >
                        {item.prescription && (
                          <Fragment>
                            <strong>Prescription</strong>
                            <div
                              style={{
                                margin: "0px 10px",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {item.prescription}
                            </div>
                            <br />
                          </Fragment>
                        )}
                        {item.diagnosticStudies && (
                          <Fragment>
                            <strong>Diagnostic Studies</strong>
                            <div style={{ margin: "0px 10px" }}>
                              <span>{item.diagnosticStudies}</span>
                            </div>
                            <br />
                          </Fragment>
                        )}
                        {item.referrals && (
                          <Fragment>
                            <strong>Referrals</strong>
                            <div style={{ margin: "0px 10px" }}>
                              <span>{item.referrals}</span>
                            </div>
                            <br />
                          </Fragment>
                        )}
                        {item.medicalSupplies && (
                          <Fragment>
                            <strong>Medical supplies</strong>
                            <div style={{ margin: "0px 10px" }}>
                              <span>{item.medicalSupplies}</span>
                            </div>
                            <br />
                          </Fragment>
                        )}
                      </div>
                      <span
                        onClick={() =>
                          onInputCheckedChange(
                            { target: { checked: !item.isChecked } },
                            i
                          )
                        }
                      >
                        <span>{moment(item.createdAt).format("lll")}</span>
                      </span>
                      <span>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          title="Remove Order"
                          onClick={() => handleUpdateOrder(item)}
                        >
                          <i className="fas fa-edit"></i>
                        </button>
                        &nbsp;
                        <button
                          type="button"
                          className="btn btn-danger btn-sm"
                          title="Remove Order"
                          style={{ width: "33.75px" }}
                          onClick={() => removeItem(item, threadId)}
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              {patientOrders.length === 0 && (
                <div className="default-view">
                  <span>No Orders Found.</span>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-hubchart btn-hubchart-primary"
                onClick={() => handleCreateOrderHistory()}
              >
                Create New Order
              </button>
              <button
                type="button"
                className="btn btn-hubchart btn-hubchart-close"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <Loader isLoading={isOrdersLoading} />
      </div>
      {ordersModalShown && (
        <OrdersModal
          isOpen={ordersModalShown}
          title="Orders"
          thread={{}}
          patientId={patientInfo.objectId}
          onClose={() => handleOrderModalShown(false)}
          postOnConversation={{}}
          isPatient={false}
          patientName={""}
          patientDob={""}
          guests={[]}
          options={[]}
          toList={true}
          appendOrders={() => {}}
          selectedOrder={selectedOrder}
          isFromPatientOrders={true}
        />
      )}
    </Fragment>
  );
}

export default PatientOrdersModal;
