import React, { useEffect, useMemo, useRef, useCallback } from 'react';
import ConferenceHelper from '../../../helper/conference';
import Logo from '../../../assets/images/logo-blue.png';
import Notify from './Notify';

const ErrorLobby = ({ message, allowRetry, errorLog }) => {

  const errorLogRef = useRef();

  const styles = { width: '100%', height: '100%', background: '#000', color: '#fff' };
  const classNames = "d-flex flex-column justify-content-center align-items-center";

  const closeWindow = () => {
    window.close();
  };

  const reloadWindow = () => {
    window.location.reload();
  }

  const handleCopyErrorLog = useCallback(() => {

    /* Get the text field */
    var copyText = errorLogRef.current;

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");

    Notify.success('Error log copied!');
  }, [errorLog]);

  const templateColumns = useMemo(() => {
    return allowRetry ? '1fr 1fr' : '1fr';
  }, [allowRetry]);

  useEffect(() => {
    // Prevent auto close if we allow retrying connection
    if (allowRetry) return;

    setTimeout(() => {
      window.close();
    }, 7000);
  }, []);

  return (
    <div className={classNames} style={styles}>
      <img className="mb-3" src={Logo} style={{width: "100px"}}/>
      <h3 className="mb-3">{message}</h3>

      <div style={{display: 'grid', gridTemplateColumns: templateColumns, gridColumnGap: '20px'}}>
        <div className="d-flex flex-column align-items-center">
          <button onClick={closeWindow} className="btn btn-danger btn-circle btn-lg">
            <i class="fas fa-times"></i>
          </button>
          Close
        </div>
        { allowRetry ?
          <div className="d-flex flex-column align-items-center">
            <button onClick={reloadWindow} className="btn btn-success btn-circle btn-lg">
              <i class="fas fa-redo-alt"></i>
            </button>
            Reconnect
          </div>
          : ''
        }
      </div>
      
      <div style={{position: 'absolute', bottom: '0', left: '0'}}>
        <button onClick={e => handleCopyErrorLog(e)} className="btn btn-link">Copy error log</button>
      </div>
      <input style={{zIndex: '-1', opacity: '0'}} ref={errorLogRef} value={errorLog} type="text"/>

    </div>
  );
}

export default ErrorLobby;
