import React, { useCallback, useEffect, useState, Fragment } from "react";
import moment from "moment";
import Notify from "../../../Common/Notify";
import useProvider from "./useProvider";
import InsuranceApi from "../../../../api/Insurance";
import Loader from "../../../Common/Loader";

const InsuranceForm = ({
  ipa,
  onAddProvider,
  setProvider,
  setLoading,
  setPaymentMethod,
  setComments,
  payment_method = "",
  comments = "",
  providers,
  editMode,
  label
}) => {
  const [addProviderMode, setAddProviderMode] = useState(false);
  const newProvider = useProvider();

  const handleAddProvider = useCallback(async () => {
    // Check if provider already exists
    const existingProvider = providers.find(p => p.name === newProvider.name);

    if (!newProvider.name) return Notify.info("Please type a provider name");
    if (existingProvider) return Notify.info("Provider already exists");

    const newProviderObj = newProvider.getParseObject().toJSON();

    setLoading(true);

    try {
      const savedProvider = await InsuranceApi.addProvider(newProviderObj);

      // Add the new provider to list
      onAddProvider(savedProvider);

      // Set the new provider as selected
      setProvider(savedProvider);

      // and turn off edit mode
      setAddProviderMode(false);

      Notify.success("Insurance added");

      setLoading(false);
    } catch (err) {
      Notify.error("Could not add insurance");

      setLoading(false);
    }
  }, [newProvider.name]);

  return (
    <div>
      <label>{label}</label>

      {editMode ? (
        <Fragment>
          <div className="radio-btn-container">
            <span className="input-group-text hc-input-addon">
              <i className="fas fa-fw fa-file-medical" />
              &nbsp; Payment Method
            </span>
            <div className="radio-btn" id="other">
              <label
                className={payment_method === "Cash" ? "hc-primary-text" : ""}
              >
                <input
                  className="mr-1"
                  type="radio"
                  name="Cash"
                  value="Cash"
                  checked={payment_method === "Cash"}
                  onChange={e => setPaymentMethod(e.target.value)}
                />{" "}
                Cash
              </label>
              <label
                className={
                  payment_method === "Concierge" ? "hc-primary-text" : ""
                }
              >
                <input
                  className="mr-1"
                  type="radio"
                  name="Concierge"
                  value="Concierge"
                  checked={payment_method === "Concierge"}
                  onChange={e => setPaymentMethod(e.target.value)}
                />{" "}
                Concierge
              </label>
              <label
                className={
                  payment_method === "Subscription" ? "hc-primary-text" : ""
                }
              >
                <input
                  className="mr-1"
                  type="radio"
                  name="Subscription"
                  value="Subscription"
                  checked={payment_method === "Subscription"}
                  onChange={e => setPaymentMethod(e.target.value)}
                />{" "}
                Subscription
              </label>
              <label
                className={
                  payment_method === "Courtesy" ? "hc-primary-text" : ""
                }
              >
                <input
                  className="mr-1"
                  type="radio"
                  name="Courtesy"
                  value="Courtesy"
                  checked={payment_method === "Courtesy"}
                  onChange={e => setPaymentMethod(e.target.value)}
                />{" "}
                Courtesy
              </label>
              <label className={!payment_method ? "hc-primary-text" : ""}>
                <input
                  className="mr-1"
                  type="radio"
                  name="none"
                  value=""
                  checked={!payment_method}
                  onChange={e => setPaymentMethod(e.target.value)}
                />{" "}
                None
              </label>
            </div>
          </div>
          <PatientField
            label="Comments"
            placeholder="Comments"
            onChange={e => setComments(e.target.value)}
            value={comments}
            id="comments"
            iconClass="fas fa-fw fa-comments"
          />
        </Fragment>
      ) : (
        <Fragment>
          <PatientValue
            label="Payment Method"
            value={payment_method}
            iconClass="fas fa-fw fa-credit-card"
          />
          <PatientValue
            label="Comments"
            value={comments}
            iconClass="fas fa-fw fa-comments"
          />
        </Fragment>
      )}
    </div>
  );
};

const PatientField = props => {
  const {
    /* For input */
    label,
    placeholder,
    value,
    id = "",

    /* Icon */
    iconClass,
    children
  } = props;

  return (
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <span class="input-group-text hc-input-addon">
          <i className={iconClass}></i>
          &nbsp;
          {label}
        </span>
      </div>
      {/* You can provide custom input */}
      {children ? (
        { ...children }
      ) : (
        <input
          onChange={props.onChange}
          value={value}
          type="text"
          class="form-control"
          placeholder={placeholder}
          id={id}
        />
      )}
    </div>
  );
};

const PatientValue = props => {
  const {
    /* For input */
    label,
    value,

    /* Icon */
    iconClass,
    style = {}
  } = props;

  return (
    <div class="input-group" style={{ boxShadow: "unset" }}>
      <div class="input-group-prepend">
        <span class="input-group-text hc-input-addon">
          <i className={iconClass}></i>
          &nbsp;
          {label}
        </span>
      </div>
      <div className="center-vertical" style={style}>
        {value ? value : <span className="text-blank">---</span>}
      </div>
    </div>
  );
};

export default InsuranceForm;
