import React, { useEffect, useState } from 'react';

const SearchInput = (props) => {

  const { onSearch, onClear, onPressEnter } = props;

  const [keyword, setKeyword] = useState('');

  const handleSubmit = e => {
    
    e.preventDefault();

    if (onPressEnter) onPressEnter();
  }

  useEffect(() => {

    if (!keyword) return;

    // Everytime keyword changes,
    // Wait for half a second, then trigger onSearch
    const timeout = setTimeout(() => {
      onSearch(keyword);
    }, 500);

    // But if user types within the timeout,
    // The timeout will be stopped
    return () => {
      clearTimeout(timeout);
    }

  }, [keyword]);

  useEffect(() => {
    if (!keyword) onClear();
  }, [keyword]);

  return (
    <form {...props} onSubmit={handleSubmit}>
      <div className="Tab-Search-Container m-0">
        <i className="fas fa-search"></i>
        <input value={keyword} onChange={(e) => setKeyword(e.target.value)} placeholder="Type a contact name" type="search" />
      </div>
    </form>
  );
}

export default SearchInput;
