import React, { Component } from 'react'

const $ = window.$;

export class RecentItem extends Component {
    
    render() {

        let { thread, type, data={}, successSend = false, isForwarding = false, groupType } = this.props;

        let Name,
            Image,
            isCCMGroup = false,
            isSubgroup = false,
            CCMCurrentPlan,
            patientColorScheme = {
                fontSize: "11px",
                fontWeight: "bold",
                float: "left"
            };
        let containerStyle = {
            border: "0px solid white",
            paddingLeft: "55px",
            minHeight: "45px",
            // height: "45px",
            borderRadius: "0px"
        };

        let imageStyle = {
            width: "30px",
            height: "30px",
            top: "50%",
            transform: "translateY(-50%)"
        }

        let id;

        switch(type) {
            case "message":
                id = "tooltip_"+thread.objectId;
                switch(thread.threadType) {
                    case "private":
                        Name = thread.partnerName;
                        if (typeof thread.partnerImageURL === "undefined" || thread.partnerImageURL === "") {
                            Image = <img src={require("../../../assets/images/default.png")} alt="" className="profile-image rounded-circle" style={imageStyle}/>;
                        } else {
                            Image = <img src={thread.partnerImageURL} alt="" className="profile-image rounded-circle" style={imageStyle}/>;
                        }
                    break;

                    case "group":
                        Name = thread.groupName;
                        var urlImage = require("../../../assets/images/group-default.png");
                        if (thread.currentPlan === "none") {
                            patientColorScheme.color = "#ff99c7";
                            urlImage = require("../../../assets/images/none-default.png");
                        }
                        if (thread.currentPlan === "nf2f") {
                            patientColorScheme.color = "rgb(238, 151, 73)";
                            urlImage = require("../../../assets/images/nf2f-default.png");
                        }
                        if (thread.currentPlan === "ccm") {
                            patientColorScheme.color = "#9550b3";
                            urlImage = require("../../../assets/images/ccm-default.png");
                        }
                        if (thread.currentPlan === "tcm") {
                            patientColorScheme.color = "#00b9ea";
                            urlImage = require("../../../assets/images/tcm-default.png");
                        }
                        if (thread.currentPlan === "cpo") {
                            patientColorScheme.color = "#23aaa0";
                            urlImage = require("../../../assets/images/cpo-default.png");
                        }

                        if (!thread.groupImageURL) {
                            Image = <img src={urlImage} alt="" className="profile-image rounded-circle" style={imageStyle}/>;
                        } else {
                            Image = <img src={thread.groupImageURL} alt="" className="profile-image rounded-circle" style={imageStyle}/>;
                        }

                        if (thread.currentPlan) {
                            isCCMGroup = true;
                            CCMCurrentPlan = thread.currentPlan.toUpperCase();
                            imageStyle.top = "15px";
                            containerStyle.minHeight = "50px";
                            containerStyle.height = "50px"
                        }
                        if (thread.subgroupSession) {
                            isSubgroup = true;
                        }
                    break;

                    case "adhoc":
                        if (!thread.adhocGroupMembers) return (null);
                        let adhocName = [];

                        for (let x = 0; x < thread.adhocGroupMembers.length; x++) {
                            const element = thread.adhocGroupMembers[x];
                            adhocName.push(element.name);
                        }
                        Name = adhocName.join(", ");
                        Image = <img src={thread.senderImageURL} alt="" className="profile-image rounded-circle" style={imageStyle}/>;
                    break;

                    default:
                    break;
                }
            break;
            case "group":
            case "chart":
                id = "tooltip_"+data.objectId;
                Name = data.name;

                var urlImage = require("../../../assets/images/group-default.png");
                if (data.currentPlan === "none") {
                    patientColorScheme.color = "#ff99c7";
                    urlImage = require("../../../assets/images/none-default.png");
                }
                if (data.currentPlan === "nf2f") {
                    patientColorScheme.color = "rgb(238, 151, 73)";
                    urlImage = require("../../../assets/images/nf2f-default.png");
                }
                if (data.currentPlan === "ccm") {
                    patientColorScheme.color = "#9550b3";
                    urlImage = require("../../../assets/images/ccm-default.png");
                }
                if (data.currentPlan === "tcm") {
                    patientColorScheme.color = "#00b9ea";
                    urlImage = require("../../../assets/images/tcm-default.png");
                }
                if (data.currentPlan === "cpo") {
                    patientColorScheme.color = "#23aaa0";
                    urlImage = require("../../../assets/images/cpo-default.png");
                }

                if (!data.image) {
                    Image = <img src={urlImage} alt="" className="profile-image rounded-circle" style={imageStyle}/>;
                } else {
                    Image = <img src={data.image.url} alt="" className="profile-image rounded-circle" style={imageStyle}/>;
                }

                if (data.currentPlan) {
                    isCCMGroup = true;
                    CCMCurrentPlan = data.currentPlan.toUpperCase();
                    imageStyle.top = "15px";
                    containerStyle.minHeight = "50px";
                    containerStyle.height = "50px"
                }
                if (data.subgroupSession) {
                    isSubgroup = true;
                }
            break;
            case "contact":
                var contact = data.contact,
                    contactURL;
                id = "tooltip_"+data.contact.objectId;
                Name = contact.firstName +" "+contact.lastName;

                if (typeof contact.picture === "undefined") {
                    contactURL = require("../../../assets/images/default.png");
                } else {
                    contactURL = contact.picture.url;
                }
                Image = <img src={contactURL} alt="" className="profile-image rounded-circle" style={imageStyle}/>;
            break;
            case "provider":
                const provider = data;

                if (typeof provider.companyPhoto !== "undefined") {
                    urlImage = provider.companyPhoto.url;
                } else {
                    urlImage = require("../../../assets/images/group-default.png");
                }
                Image = <img src={urlImage} alt="" className="profile-image rounded-circle" style={imageStyle}/>;
                Name = provider.companyName;
            break;
            default:
            break;
        }

        if (this.props.isForwarding) {
            $("#"+id).tooltip('dispose');
        }

        // const LoadingStyle = {
        //     height: "100%",
        //     display: "flex",
        //     position: "relative",
        //     top: "50%"
        // }

        let RightButton = <i id={id} onClick={() => this.props.createNewMessage()} className="fas fa-paper-plane plane-btn" data-toggle="tooltip" data-placement="top" data-html="true" title="Send"></i>;
        if (successSend) {
            RightButton = <span className="hc-primary-text plane-btn" style={{fontSize: "13px", fontWeight: "bold"}}>SENT</span>;
        }
        if (isForwarding) {
            RightButton = <i id={id} style={{top: "30%"}} className="fas fa-circle-notch spin loading-btn"></i>;
        }

        if (groupType === 'patient') return (
            <a href="#!" className="list-group-item list-group-item-action flex-column align-items-start" style={containerStyle}>
		    	{Image}
                <div className="row">
                    <div className="col-md-6">
                        <p className="name m-0" style={{paddingRight: 'unset'}}>{Name}</p>
                        <p className="hc-primary-text truncate m-0">
                            <i className="fas fa-user mr-1"></i>
                            { data.owner ?
                                <span className="text-blank">{data.owner.displayName}</span>
                                : ''
                            }
                        </p>
                        <span className="badge badge-patient">Patient hub</span>
                    </div>
                    <div className="col-md-6">
                        <div className="hc-primary-text m-0">
                            <i className="fas fa-calendar mr-1"></i>
                            <span className="text-blank">{data.dob}</span>
                        </div>
                        <div className="hc-primary-text truncate m-0">
                            <span>
                                <i className="fas fa-credit-card mr-1"></i>                            
                            </span>
                            <span className="text-blank">
                                {
                                    data.primaryInsurance ?
                                    data.primaryInsurance.name :
                                    'Insurance'
                                }
                            </span>
                        </div>
                    </div>
                </div>
                {RightButton}
            </a>
        )

        return (
            <a href="#!" className="list-group-item list-group-item-action flex-column align-items-start" style={containerStyle}>
		    	{Image}
                <p className="name">{Name}</p>
                { isCCMGroup && <span style={patientColorScheme}>{CCMCurrentPlan}</span> }
                { groupType === 'patient' ?
                    <span className="badge badge-patient">Patient hub</span>
                    : ''
                }
                { groupType === 'regular' ?
                    <span className="badge badge-primary">Group</span>
                    : ''
                }
                {RightButton}
            </a>
        )
    }
}

export default RecentItem
