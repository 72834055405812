import React, { Component } from "react";
import { connect } from "react-redux";
import Parse from "parse";
import imageCompression from "browser-image-compression";

const $ = window.$;
export class PrescriptionContainer extends Component {
  constructor() {
    super();
    this.state = {
      user: "",
      profilePictureURL: "",
      willUpdatePrescriptionSettings: false,
      savingPrescriptionSettings: false,
      willUpload: false,
      isUploading: false
    };

    this.updatePrescriptionSettings = this.updatePrescriptionSettings.bind(
      this
    );
    this.fileHandleChange = this.fileHandleChange.bind(this);
  }

  componentWillMount() {
    let image = this.state.profilePictureURL;
    let picture = Parse.User.current().get("picture");
    // if (typeof picture != "undefined") {
    // 	image = picture._url;
    // } else {
    image = require("../../../assets/images/default.png");
    // }

    this.setState({
      user: Parse.User.current(),
      profilePictureURL: image
    });
  }

  willUpdatePrescriptionSettings() {
    this.setState({
      willUpdatePrescriptionSettings: true
    });
  }

  cancelPrescriptionLogo(e) {
    e.preventDefault();
    this.setState({
      willUpdatePrescriptionSettings: false
    });
  }

  updatePrescriptionSettings(e) {
    e.preventDefault();

    this.setState({
      savingPrescriptionSettings: true
    });

    let firstName = this.refs.firstName.value,
      firstNameCap = firstName.charAt(0).toUpperCase() + firstName.slice(1),
      lastName = this.refs.lastName.value,
      lastNameCap = lastName.charAt(0).toUpperCase() + lastName.slice(1),
      title = this.refs.title.value.toUpperCase();

    if (firstName === "" || lastName === "") {
      this.setState({
        savingPrescriptionSettings: false
        // willUpdatePrescriptionSettings: false,
      });
      return;
    }

    let displayName = firstNameCap + " " + lastNameCap;

    let currentUser = Parse.User.current();
    currentUser.set("firstName", firstNameCap);
    currentUser.set("lastName", lastNameCap);
    currentUser.set("firstNameLower", firstName.toLowerCase());
    currentUser.set("lastNameLower", lastName.toLowerCase());
    currentUser.set("displayName", displayName);
    currentUser.set("displayNameLower", displayName.toLowerCase());
    currentUser.set("title", title);

    currentUser.save().then(
      user => {
        this.setState({
          savingPrescriptionSettings: false,
          willUpdatePrescriptionSettings: false,
          user: Parse.User.current()
        });
      },
      error => {
        if (error.code === 203) {
          this.setState({
            savingPrescriptionSettings: false
          });
        }
      }
    );
  }

  openFileImage() {
    $(this.refs.profileImage).trigger("click");
  }

  async fileHandleChange(e) {
    const imageFile = e.target.files[0];
    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      const URLFile = await imageCompression.getDataUrlFromFile(compressedFile);
      this.setState({
        file: URLFile,
        willUpload: true
      });

      this.readURL(compressedFile);
    } catch (error) {
      console.log(error);
    }
  }

  readURL(file) {
    if (file) {
      var reader = new FileReader();

      reader.onload = function(e) {
        this.setState({
          profilePictureURL: e.target.result
        });
      }.bind(this);

      reader.readAsDataURL(file);
    }
  }

  uploadPicture() {
    if (!this.state.willUpload) return;

    this.setState({
      isUploading: true
    });

    let file = this.state.file;
    // console.log(file);
    // // return;
    // let fileType = file.type;
    // let fileName = file.name.replace(/[^\w\s]/gi, '');
    // fileName = fileName.replace(/[\s,.]/g,"_");
    // let parseFile = new Parse.File(fileName, file, fileType);
    let self = this;
    var parseFile = new Parse.File("prescriptionLogo.jpg", { base64: file });
    parseFile.save().then(
      function() {
        let currentUser = Parse.User.current();
        currentUser.set("picture", parseFile);
        currentUser.save().then(
          user => {
            self.setState({
              isUploading: false
            });
          },
          error => {}
        );
      },
      function(error) {
        console.log(error);
      }
    );
  }

  render() {
    const { objectId: organizationId } = this.props.organization;
    return (
      <div className="Account-Setting-Container" style={{ minWidth: "450px" }}>
        {organizationId && (
          <div>
            <div
              style={{
                width: "126px",
                position: "relative",
                margin: "0 auto",
                display: "block"
              }}
            >
              <img
                style={{
                  border: "3px solid white",
                  width: "126px",
                  height: "126px",
                  borderRadius: "30px"
                }}
                src={this.state.profilePictureURL}
              />
              {this.state.isUploading ? (
                <p style={{ textAlign: "center", color: "rgb(74, 173, 82)" }}>
                  Uploading...
                </p>
              ) : (
                <div className="Upload-Button-Container">
                  <i
                    onClick={this.openFileImage.bind(this)}
                    className="fas fa-image upload-button"
                  ></i>
                  <input
                    ref="profileImage"
                    className="file-upload"
                    type="file"
                    accept="image/jpg,image/png,image/jpeg"
                    onChange={this.fileHandleChange}
                  />
                </div>
              )}
              {this.state.willUpload && !this.state.isUploading && (
                <button
                  onClick={this.uploadPicture.bind(this)}
                  className="Confirm-Button"
                  style={{ color: "#2194f3", marginTop: "10px" }}
                >
                  Upload Picture
                </button>
              )}
            </div>
            <div className="Form-Container">
              {/* onSubmit={this.updatePrescriptionSettings} */}
              <form style={{ position: "relative" }}>
                <h5 style={{ fontSize: "1rem" }}>Prescription Settings</h5>
                {!this.state.willUpdatePrescriptionSettings && (
                  <span
                    onClick={this.willUpdatePrescriptionSettings.bind(this)}
                    className="Update-Detail-Button"
                  >
                    <i className="fas fa-edit"></i> Edit
                  </span>
                )}
                <div className="form-group">
                  <span
                    style={{
                      fontSize: "13px",
                      color: "#00000096",
                      fontWeight: "500"
                    }}
                  >
                    Header
                  </span>
                  {this.state.willUpdatePrescriptionSettings ? (
                    <div className="card">
                      <input
                        style={{ textTransform: "capitalize" }}
                        ref="firstName"
                        type="text"
                        id="firstname"
                        placeholder="Header"
                        disabled={this.state.savingPrescriptionSettings}
                        required
                      />
                      <i className="fas fa-heading hc-primary-text"></i>
                    </div>
                  ) : (
                    <p style={{ marginLeft: "30px" }}>{"Header"}</p>
                  )}
                </div>
                <div className="form-group">
                  <span
                    style={{
                      fontSize: "13px",
                      color: "#00000096",
                      fontWeight: "500"
                    }}
                  >
                    Subheader
                  </span>
                  {this.state.willUpdatePrescriptionSettings ? (
                    <div className="card">
                      <input
                        style={{ textTransform: "capitalize" }}
                        ref="lastName"
                        type="text"
                        placeholder="Subheader"
                        disabled={this.state.savingPrescriptionSettings}
                        required
                      />
                      <i className="fas fa-list hc-primary-text"></i>
                    </div>
                  ) : (
                    <p style={{ marginLeft: "30px" }}>{"Subheader"}</p>
                  )}
                </div>
                <div className="form-group">
                  <span
                    style={{
                      fontSize: "13px",
                      color: "#00000096",
                      fontWeight: "500"
                    }}
                  >
                    Address
                  </span>
                  {this.state.willUpdatePrescriptionSettings ? (
                    <div className="card">
                      <input
                        style={{ textTransform: "capitalize" }}
                        ref="lastName"
                        type="text"
                        placeholder="Address"
                        disabled={this.state.savingPrescriptionSettings}
                        required
                      />
                      <i className="fas fa-address-book hc-primary-text"></i>
                    </div>
                  ) : (
                    <p style={{ marginLeft: "30px" }}>{"Address"}</p>
                  )}
                </div>
                <div className="form-group">
                  <span
                    style={{
                      fontSize: "13px",
                      color: "#00000096",
                      fontWeight: "500"
                    }}
                  >
                    Phone Number
                  </span>
                  {this.state.willUpdatePrescriptionSettings ? (
                    <div className="card">
                      <input
                        style={{ textTransform: "capitalize" }}
                        ref="lastName"
                        type="text"
                        placeholder="Phone Number"
                        disabled={this.state.savingPrescriptionSettings}
                        required
                      />
                      <i className="fas fa-phone hc-primary-text"></i>
                    </div>
                  ) : (
                    <p style={{ marginLeft: "30px" }}>{"Phone Number"}</p>
                  )}
                </div>
                <div className="form-group">
                  <span
                    style={{
                      fontSize: "13px",
                      color: "#00000096",
                      fontWeight: "500"
                    }}
                  >
                    Fax Number
                  </span>
                  {this.state.willUpdatePrescriptionSettings ? (
                    <div className="card">
                      <input
                        style={{ textTransform: "capitalize" }}
                        ref="lastName"
                        type="text"
                        placeholder="Fax Number"
                        disabled={this.state.savingPrescriptionSettings}
                        required
                      />
                      <i className="fas fa-fax hc-primary-text"></i>
                    </div>
                  ) : (
                    <p style={{ marginLeft: "30px" }}>{"Fax Number"}</p>
                  )}
                </div>
                {this.state.willUpdatePrescriptionSettings &&
                  !this.state.savingPrescriptionSettings && (
                    <div
                      className="form-group"
                      style={{ marginBottom: "30px", textAlign: "right" }}
                    >
                      <button
                        ref="submit"
                        type="submit"
                        className="Confirm-Button"
                      >
                        Save
                      </button>
                      <span
                        className="Vertical-Line-Seperator"
                        style={{ marginLeft: "7px" }}
                      ></span>
                      <button
                        onClick={this.cancelPrescriptionLogo.bind(this)}
                        className="Cancel-Button"
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                {this.state.savingPrescriptionSettings && (
                  <div
                    className="form-group"
                    style={{ textAlign: "right", position: "relative" }}
                  >
                    <i className="fas fa-circle-notch fa-spin"></i>
                    <span style={{ paddingLeft: "5px" }}>
                      Updating Prescription Settings
                    </span>
                  </div>
                )}
              </form>
            </div>
          </div>
        )}
        {!organizationId && (
          <div>
            <h4 style={{ textAlign: "center" }} className="hc-primary-text">
              Prescription Settings
            </h4>
            <p style={{ textAlign: "center", marginTop: "15px" }}>
              You need to subscribe to Corporate Subscription to use this
              feature.
            </p>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  organization: state.session.organization
});

export default connect(mapStateToProps, {})(PrescriptionContainer);
