import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Twilio, { connect, LocalDataTrack } from 'twilio-video';
import LocalParticipant from './LocalParticipant';
import Participant from './Participant';
import Controls from './Controls';
import TileLayout from './TileLayout';
import SpeakerLayout from './SpeakerLayout';
import { InPortal, createHtmlPortalNode } from 'react-reverse-portal';
import Notify from './Notify';
import useRoom from './useRoom';

// Convert hasVideo to constant
const hasVideo = false;

const Room = ({
  roomId,
  token,
  invited,
  localUser,
  onLeaveButtonClick,
  onInviteGuest,
  setUserModalShown,
  onDisconnected,
  isGuest,
  showErrorLobby,
  setParticipantsModalShown,
}) => {

  // State
  const [participantNodes, setParticipantNodes] = useState([]);
  const [localTracks, setLocaltracks] = useState(null);
  const [videoShared, setVideoShared] = useState(false);
  const [audioShared, setAudioShared] = useState(true);
  const [screenShared, setScreenShared] = useState(false);
  const [localParticipant, setLocalParticipant] = useState(null);
  const [layout, setLayout] = useState('speaker');
  const [remoteScreenSharer, setRemoteScreenSharer] = useState(null);
  const [dominantSpeaker, setDominantSpeaker] = useState(null);
  const [pinnedParticipant, setPinnedParticipant] = useState(null);
  const { room, setRoom } = useRoom();
  const [connectionQueue, setConnectionQueue] = useState([]);

  const invitedRef = useRef(invited);

  const getUser = participant => {
    return invitedRef.current.find(
      u => u.objectId === participant.identity
    );
  }

  const participantConnected = newParticipant => {

    /* 
      Sometimes, a participant that is still connected
      can connect again before disconnecting (When joining from another device).

      In that case, there will be a duplicate participant node.

      So what we'd like to do is:

      - Whenever we receive a duplicate connection,
      - We wait for a disconnection first, before proceeding with the connection

      - For this, we maintain a queue for connect/disconnect like:
      - ['connect', 'disconnect', 'connect', 'disconnect']

      const connectionQueue = [];

      if (connectionQueue.includes(newParticipant)) {
        do not create new participant node
      }
    */
    
    // Check whether the joining participant is still connected from another device
    // That is - if we have not yet received a participantDisconnected event prior
    
    setParticipantNodes(participantNodes => {
      
      /* 
        Check whether the joining participant is still connected
      */
      const existingNode = participantNodes.find(pn => pn.participant.identity === newParticipant.identity);

      if (existingNode) {

        // If still connected, Add the connected participant to queue
        // because we're expecting to receive a participantDisconnected event later
        setConnectionQueue(queue => {

          const existingQueue = queue.find(p => p.identity === newParticipant.identity);

          return !existingQueue ? [...queue, existingNode.participant] : [...queue];
        });

        console.log('participantConnected: Participant added to queue: ' + newParticipant.identity);
        
        // Now, update the participantNode's participant
        return participantNodes.map(pn => {
          if (pn.participant.identity === newParticipant.identity) {
            // Note: We're not updating the node
            return {
              ...pn,
              participant: newParticipant
            };
          }
          return pn;
        });
      }

      return [
        ...participantNodes,
        {
          node: createHtmlPortalNode(),
          participant: newParticipant
        }
      ]
    });
  };

  const participantDisconnected = participant => {

    console.log('participantDisconnected: ' + participant.identity);

    // Unset pinnedParticipant if the leaving participant is pinned
    setPinnedParticipant(pinnedParticipant =>
      pinnedParticipant === participant ? null : pinnedParticipant
    );

    // Unset dominantSpeaker if the leaving participant is the dominantSpeaker
    setDominantSpeaker(dominantSpeaker =>
      dominantSpeaker === participant ? null : dominantSpeaker
    );

    setConnectionQueue(queue => {

      const existingParticipant = queue.find(p => p.identity === participant.identity);

      if (existingParticipant) {
        
        console.log('participantDisconnected: Participant removed from queue: ' + participant.identity);

        return queue.filter(p => p.identity != participant.identity);
      }

      // Remove the participant node for the leaving participant
      setParticipantNodes(participantNodes => {
        return participantNodes.filter(
          participantNode => participantNode.participant.identity != participant.identity
        );
      });

      return queue;
    });
  };

  const participantScreenShared = participant => {
    setRemoteScreenSharer(participant);
  };

  const participantScreenUnshared = participant => {

    const displayName = getDisplayName(participant);

    Notify.show(displayName + ' turned off screen sharing');

    setRemoteScreenSharer(null);
  };

  const dominantSpeakerChanged = participant => {

    if (!participant) return;

    setDominantSpeaker(participant);
  };

  const getDisplayName = participant => {

    const user = getUser(participant) || {};
    const displayName = user.displayName || 'Guest';

    return displayName;
  };

  const handleRoomEvents = useCallback(room => {

    // Add all existing room participants to state
    room.participants.forEach(participantConnected);

    // And watch for when a participant will connect/disconnect
    room.on('participantConnected', newParticipant => {

      const message = getDisplayName(newParticipant) + ' has joined';

      Notify.show(message);

      console.log('participantConnected: ' + newParticipant.identity);

      participantConnected(newParticipant);
    });

    room.on('participantDisconnected', participant => {

      const message = getDisplayName(participant) + ' has left';

      Notify.show(message);

      setTimeout(() => {
        participantDisconnected(participant);
      }, 3000);
    });

    room.on('dominantSpeakerChanged', dominantSpeakerChanged);

    room.on('disconnected', onDisconnected);

  }, [roomId, token]);

  const handleShowParticipants = () => {
    setParticipantsModalShown(true);
  }

  const handleMicRequest = useCallback(() => {
    Notify.prompt(`Host is asking you to open your mic`, {
      confirmLabel: 'Open my mic',
      cancelLabel: 'Ignore',
      onConfirm: () => setAudioShared(true),
    });
  }, [localUser])

  const handleCamRequest = () => {
    Notify.prompt('Host is asking you to open your cam', {
      confirmLabel: 'Open my cam',
      cancelLabel: 'Ignore',
      onConfirm: () => setVideoShared(true),
    });
  }

  useEffect(() => {
    // Set the default value of state.videoShared
    // from props.hasVideo
    setVideoShared(hasVideo);
  }, [roomId, token]);

  useEffect(() => {

    if (!token) return;

    const init = async () => {

      // 1.) Obtain twilio room object by connecting to twilio
      // 2.) Save room to state
      // 3.) Handle room events
      // 4.) Cleanup upon unmounting component

      try {
        const newLocalTracks = await createLocalTracks();

        setLocaltracks(newLocalTracks);

        const toastId = Notify.loading('Joining room...');

        const room = await joinRoom(roomId, token, newLocalTracks);

        Notify.dismiss(toastId);

        Notify.success('You entered the room');
        
        setRoom(room);
        setLocalParticipant(room.localParticipant);

        handleRoomEvents(room);
      }
      catch (err) {

        if (err instanceof DOMException
          && err.name === 'NotAllowedError') {

          return showErrorLobby('Camera or microphone access is not enabled', true, err);
        }

        showErrorLobby('There was a problem connecting', true, err);
      }

    };

    init();

  }, [roomId, token]);

  useEffect(() => {

    /* 
      componentWillUnmount for room
    */
    if (!room) return;

    return () => {

      // If not connected to room, leave it be
      if (!isConnectedToRoom(room)) return;
      
      disconnectFromRoom(room, localTracks);

      // Otherwise, clear the room
      setRoom(null);
    };
  }, [room]);

  useEffect(() => {

    if (remoteScreenSharer) setLayout('speaker');

  }, [remoteScreenSharer]);

  useEffect(() => {
    // Update our invitedRef when props.invited changes
    invitedRef.current = invited;
  }, [invited]);

  useEffect(() => {
    if (pinnedParticipant) {

      const displayName = getDisplayName(pinnedParticipant);

      Notify.show('You pinned ' + displayName);

      return () => {
        Notify.show('You unpinned ' + displayName);
      }
    }
  }, [pinnedParticipant]);

  const handleToggleVideo = useCallback(() => {
    setVideoShared(videoShared => !videoShared);
  }, []);

  const handleToggleAudio = useCallback(() => {
    setAudioShared(audioShared => !audioShared);
  }, []);

  const handleToggleScreen = useCallback(() => {
    setScreenShared(screenShared => !screenShared);
  }, []);

  const handleMessage = useCallback((message) => {

    const { identities = [] } = message;

    // Ignore message if not for you
    if (!identities.includes(localUser.id)) return;

    switch(message.type) {
      case 'mic_request': return handleMicRequest();
      case 'mic_disable': return setAudioShared(false);
      case 'cam_request': return handleCamRequest();
      case 'cam_disable': return setVideoShared(false);
    }
  }, [localUser])

  // Create a portal node: this holds your rendered content
  const localParticipantNode = useMemo(() => createHtmlPortalNode(), []);

  return (<>
    <InPortal node={localParticipantNode}>
      <LocalParticipant
        localTracks={localTracks}
        user={localUser}
        videoShared={videoShared}
        setVideoShared={setVideoShared}
        audioShared={audioShared}
        screenShared={screenShared}
        setScreenShared={setScreenShared}
        localParticipant={localParticipant}
        room={room}
      />
    </InPortal>

    {participantNodes.map(participantNode => {

      const { participant, node } = participantNode;

      const user = invited.find(u => {
        return u.objectId === participant.identity;
      });

      return (
        <InPortal node={node} key={participant.identity}>
          <Participant
            onMessage={handleMessage}
            setPinnedParticipant={setPinnedParticipant}
            pinnedParticipant={pinnedParticipant}
            remoteScreenSharer={remoteScreenSharer}
            onScreenShared={participantScreenShared}
            onScreenUnshared={participantScreenUnshared}
            participant={participant}
            user={user}
          />
        </InPortal>
      );
    })}

    {layout === 'tile' ?

      <TileLayout
        localParticipantNode={localParticipantNode}
        remoteParticipantNodes={participantNodes}
        dominantSpeaker={dominantSpeaker}
      /> :

      <SpeakerLayout
        pinnedParticipant={pinnedParticipant}
        localParticipantNode={localParticipantNode}
        remoteParticipantNodes={participantNodes}
        remoteScreenSharer={remoteScreenSharer}
        dominantSpeaker={dominantSpeaker}
      />
    }

    <Controls
      isGuest={isGuest}
      disabled={!room}
      videoShared={videoShared}
      onToggleVideo={handleToggleVideo}
      audioShared={audioShared}
      onToggleAudio={handleToggleAudio}
      screenShared={screenShared}
      onToggleScreen={handleToggleScreen}
      onEnd={onLeaveButtonClick}
      layout={layout}
      setLayout={setLayout}
      videoDisabled={screenShared}
      screenDisabled={!!remoteScreenSharer}
      onInviteGuest={onInviteGuest}
      onInviteUser={setUserModalShown}
      onShowParticipants={handleShowParticipants} />

    <Notify.ToastContainer
      className="toast-container-custom"
      closeOnClick={false}
      limit={3}
    />
  </>);
}

async function joinRoom(roomId, token, localTracks) {

  console.log(`Joining room ${roomId}...`);

  const room = await connect(token, {
    name: roomId,
    tracks: [
      ...localTracks,
      new LocalDataTrack(),
    ],
    dominantSpeaker: true,
    networkQuality: {
      local: 2, // LocalParticipant's Network Quality verbosity [1 - 3]
      remote: 2 // RemoteParticipants' Network Quality verbosity [0 - 3]
    }
  });

  return room;
}

function createLocalTracks() {
  return Twilio.createLocalTracks({
    audio: true,
  });
}

function isConnectedToRoom(room) {
  return room && room.localParticipant.state === 'connected';
}

function disconnectFromRoom(room, localTracks) {

  if (localTracks) {
    localTracks.forEach(track => track.stop());
  }

  // Stop all published tracks (audio, video, etc...)
  room.localParticipant.tracks.forEach(function (trackPublication) {
    room.localParticipant.unpublishTrack(trackPublication.track);
  });

  // Disconnect from room
  room.disconnect();
}

export default Room;
