import React, { useEffect, useState } from "react";
import useSelect from "./useSelect";

const useInsurance = (insurance = {}, providers = []) => {
  const [objectId, setObjectId] = useState(null);
  const [policyNumber, setPolicyNumber] = useState("");
  const [updatedAt, setUpdatedAt] = useState(null);
  const [ipa, setIpa] = useState("");
  const [planName, setPlanName] = useState("");
  const [payment_method, setPaymentMethod] = useState("");
  const [comments, setComments] = useState("");

  const provider = useSelect(providers, insurance.provider, "objectId");

  useEffect(() => {
    if (insurance.objectId) {
      const formattedDate = new Date(insurance.updatedAt).toLocaleString();

      setObjectId(insurance.objectId);
      setPolicyNumber(insurance.policyNumber);
      setUpdatedAt(formattedDate);
      setIpa(insurance.ipa);
      setPlanName(insurance.planName);
      setPaymentMethod(insurance.payment_method);
      setComments(insurance.comments);
    }
  }, [insurance]);

  return {
    ipa,
    provider: provider.value,
    setProvider: provider.setValue,
    providerIndex: provider.index,
    setProviderIndex: provider.setIndex,
    updatedAt,
    policyNumber,
    objectId,
    setIpa,
    setPolicyNumber,
    planName,
    setPlanName,
    setPaymentMethod,
    setComments,
    payment_method,
    comments
  };
};

export default useInsurance;
