import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setActivePriority } from "../../../actions/PriorityAction";
import { beautifyTime } from "../../../helper/util";
import PriorityMenu from "./PriorityMenu";
import RecentImage from "../../Common/RecentImage";

const PriorityItem = (props) => {
  const { data } = props;
  const { groupName = "", messages } = data;

  const dispatch = useDispatch();

  function handleItemClick(data) {
    if (props.fromSearch) {
      props.closeSearch();
    }

    setActivePriority(data)(dispatch);
  }

  function isActiveThread(path, match, location) {
    return !!(match || path === location.pathname);
  }

  const idPath = "/u/" + data.objectId;

  let containerStyle = {
    minHeight: "65px",
  };

  const { senderName = "", senderImageUrl, messageDate, text, isUrgent, isAttention } = data;

  // Get ISO date if exists
  let date;

  if (messageDate) {
    const isoDate = messageDate.iso;
    date = beautifyTime(isoDate);
  }

  return (
    <NavLink
      to={idPath}
      onClick={() => handleItemClick(data)}
      activeClassName="activeThread"
      isActive={(match, location) => isActiveThread(idPath, match, location)}
      className="list-group-item list-group-item-action flex-column align-items-start p-2"
      style={containerStyle}
    >
      <div className="d-flex align-items-center mr-0">
        <div className="name">{groupName.toUpperCase() || senderName}</div>
        <PriorityMenu priorities={messages} />
      </div>

      <hr className="m-2" />

      {messages.map((m, index) => (
        <Message message={m} key={index} />
      ))}
    </NavLink>
  );
};

const Message = ({ message }) => {
  const { messageDate } = message;

  const date = useMemo(() => {
    if (messageDate) {
      const isoDate = messageDate.iso;
      return beautifyTime(isoDate);
    }

    return "";
  }, [messageDate]);

  const styles = {
    // backgroundColor: message.isUrgent ? '#f5c6cb' : '#fcebb6',
    borderRadius: "15px",
    overflow: "hidden",
  };

  return (
    <div className="d-flex position-relative mr-0 mb-2 p-2" style={styles}>
      <div className="mr-2" style={{ alignSelf: "start" }}>
        <RecentImage url={message.senderImageUrl} width="35px" height="35px" />
      </div>

      <div style={{ minWidth: 0 }} className="w-100">
        <div className="d-flex justify-content-between align-items-center">
          <span className="name">{message.senderName}</span>
          {message.isUrgent ? (
            <div className="badge urgent-badge-1">URGENT</div>
          ) : message.isAttention ? (
            <div className="badge attention-badge-1">ATTENTION</div>
          ) : (
            ""
          )}
        </div>
        <div className="d-flex">
          <div className="priority-text">{message.text}</div>
        </div>
        <div className="p-0 text-right" style={{ fontSize: "12px" }}>
          {date}
        </div>
      </div>
    </div>
  );
};

export default PriorityItem;
