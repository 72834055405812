import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { setActiveTab, getCirclesWithTodos, getAllToDos, completeActionItem } from "../../../actions/ThreadAction";
import PatientModal from "./PatientModal";
import PrescriptionModal from "../../Conversation/Setting/PrescriptionModal";
import CreatePatient from "../../TabList/PatientTab/CreatePatient";
import { newGroup } from "../../../actions/GroupAction";
import { getAllProgressNotes } from "../../../actions/ProgressNotesAction";
import {
  getDashboard,
  getCircleLabels,
  getUrgentCount,
  getAttentionCount,
  getReturnCallsCount,
  // getHighPriorityCount,
  // getMediumPriorityCount,
  // getRoutinePriorityCount,
  getRiskManagement,
  getSchedulesToday,
} from "../../../actions/DashboardAction";
import Loader from "../../Common/Loader";
import { SET_PROGRESS_NOTES_RECENT, SET_TO_DOS_RECENT } from "../../../actions/types";

import RiskManagement from "./RiskManagement";
import ActionButtons from "./ActionButtons";
import MessagesCard from "./MessagesCard";
import TelemedToday from "./TelemedToday";
// import TelemedCard from "./TelemedCard";
import ProgressNotesCard from "./ProgressNotesCard";
import ToDosCard from "./ToDosCard";
import Sidebar from "./Sidebar";
import ConferenceMain from "../../../components/Pages/ConferenceMain";
import ConfirmTask from "./ConfirmTask/ConfirmTask";
import { linkToLabel } from "../../../actions/ThreadAction";

import "./index.scss";
import { defaultTo } from "lodash";
// import TasksCard from "./TasksCard";

const Dashboard = () => {
  const dispatch = useDispatch();

  const {
    requests,
    attentionCount,
    urgentCount,
    returnCallCount,
    // highPriorityCount,
    // mediumPriorityCount,
    // routinePriorityCount,
    isLoading,
    isLoadingPN,
    isToDosLoading,
    isLoadingRM,
    riskManagement,
    isLoadingST,
    schedulesToday,
    scheduleTodayCurrentPage,
    circleLabels,
    todoCount,
    allProgressNotes,
    allProgressNotesPending,
    allProgressNotesDone,
    allProgressNotesRecent,

    pageProgressNotesAll,
    pageProgressNotesPending,
    pageProgressNotesDone,
    pageProgressNotesRecent,

    hasMoreProgressNotesAll,
    hasMoreProgressNotesPending,
    hasMoreProgressNotesDone,
    hasMoreProgressNotesRecent,

    allToDos,
    allToDoHigh,
    allToDoMed,
    allToDoRoutine,
    allToDosPending,
    allToDosPendingHigh,
    allToDosPendingMed,
    allToDosPendingRoutine,
    allToDosDone,
    allToDoDoneHigh,
    allToDoDoneMed,
    allToDoDoneRoutine,
    allToDosRecent,
    allToDosCurrentPage,
    allToDoHighCP,
    allToDoMedCP,
    allToDoRoutineCP,
    isToDosLoading: isAllToDosLoading,
    currentProgressNotesPage,
    hasMorePN,
    allToDosHasMore,
    allToDoHighHM,
    allToDoMedHM,
    allToDoRoutineHM,

    pendingToDosCurrentPage,
    pendingToDoHighCP,
    pendingToDoMedCP,
    pendingToDoRoutineCP,
    pendingToDosHasMore,
    pendingToDoHighHM,
    pendingToDoMedHM,
    pendingToDoRoutineHM,
    doneToDoHighCP,
    doneToDoMedCP,
    doneToDoRoutineCP,
    doneToDoHighHM,
    doneToDoMedHM,
    doneToDoRoutineHM,
    doneToDosCurrentPage,
    doneToDosHasMore,
    recentToDosCurrentPage,
    recentToDosHasMore,
    scheduleTodayHasMore,
  } = useSelector(mySelector, shallowEqual);

  const [patientModalShown, setPatientModalShown] = useState(false);
  const [rxModalShown, setRxModalShown] = useState(false);
  const [createPatientModalShown, setCreatePatientModalShown] = useState(false);
  const [circleId, setCircleId] = useState("");
  const [isEmr, setIsEMR] = useState(false);
  const [isDashboard, setIsDashboard] = useState(false);
  const [circles, setCircles] = useState([]);
  const [isToDosLoadingState, setIsToDosLoading] = useState(false);
  const [preventReload, setPreventReload] = useState(false);
  const [preventToDosReload, setPreventToDosReload] = useState(false);
  const [isConfirmTaskOpened, setIsConfirmTaskOpened] = useState(false);
  const [selectedActionItemId, setSelectedActionItemId] = useState("");
  const [isCompletingTask, setIsCompletingTask] = useState(false);
  const [currentToDoTab, setCurrentToDoTab] = useState("ALL");
  const [currentProgressNoteTab, setCurrentProgressNoteTab] = useState("all");
  const [currentTelemedTodayDate, setCurrentTelemedTodayDate] = useState("");
  const [currentPriority, setCurrentPriority] = useState(2);

  const handleNewPrescriptionOrNotes = () => {
    setPatientModalShown(true);
  };

  const handleSelectPatient = (circleId) => {
    setCircleId(circleId);
    setPatientModalShown(false);

    if (isEmr) {
      openProgressNotes(circleId);
    } else {
      setRxModalShown(true);
    }
  };

  const openProgressNotes = (patientId) => {
    const filesUrl = `${window.location.origin}/progressnotes/${patientId}`;
    window.open(filesUrl, "Hubchart Progress Notes");
    setIsEMR(false);
  };

  const handleNewGroup = (patient) => {
    setIsDashboard(true);
    dispatch(newGroup(patient));
  };

  const handleOpenPatientModal = () => {
    setCreatePatientModalShown(true);
  };

  const handleCreateMessage = () => {
    dispatch(setActiveTab("create_chat"));
  };

  const handleOpenProgressNotes = () => {
    handleNewPrescriptionOrNotes();
    setIsEMR(true);
  };

  const handleProgressNotesFilter = (status) => {
    setCurrentProgressNoteTab(status);

    if (status == "PENDING" && allProgressNotesPending) {
      dispatch({
        type: SET_PROGRESS_NOTES_RECENT,
        data: [...allProgressNotesPending] || [],
        page: pageProgressNotesPending,
        hasMore: hasMoreProgressNotesPending,
      });
    } else if (status == "DONE" && allProgressNotesDone) {
      dispatch({
        type: SET_PROGRESS_NOTES_RECENT,
        data: [...allProgressNotesDone] || [],
        page: pageProgressNotesDone,
        hasMore: hasMoreProgressNotesDone,
      });
    } else if (status == "all") {
      dispatch({
        type: SET_PROGRESS_NOTES_RECENT,
        data: [...allProgressNotes] || [],
        page: pageProgressNotesAll,
        hasMore: hasMoreProgressNotesAll,
      });
    } else {
      dispatch(getAllProgressNotes(0, [], 10, status));
    } 
  };

  const handleToDosFilter = (status, priority) => {
    // console.log('status:', status);
    // console.log('priority:', priority);
    setCurrentToDoTab(status); 
    setCurrentPriority(priority);

    if (status == "PENDING") {
      if (priority === 2 && allToDosPendingHigh) {
        dispatch({
          type: SET_TO_DOS_RECENT,
          data: [...allToDosPendingHigh] || [],
          page: pendingToDoHighCP,
          hasMore: pendingToDoHighHM,
        });
      }
      else if (priority === 1 && allToDosPendingMed) {
        dispatch({
          type: SET_TO_DOS_RECENT,
          data: [...allToDosPendingMed] || [],
          page: pendingToDoMedCP,
          hasMore: pendingToDoMedHM,
        });
      }
      else if (priority === 0 && allToDosPendingRoutine) {
        dispatch({
          type: SET_TO_DOS_RECENT,
          data: [...allToDosPendingRoutine] || [],
          page: pendingToDoRoutineCP,
          hasMore: pendingToDoRoutineHM,
        });
      }
      else {
        dispatch(getAllToDos(0, [], 10, 1, status, priority));
      }
    } else if (status == "DONE") {
      console.log('2:', allToDoDoneHigh);
      console.log('1:', allToDoDoneMed);
      console.log('0:', allToDoDoneRoutine);
      console.log('ALL:', allToDoDoneRoutine);
      if (priority === 2 && allToDoDoneHigh) {
        dispatch({
          type: SET_TO_DOS_RECENT,
          data: [...allToDoDoneHigh] || [],
          page: doneToDoHighCP,
          hasMore: doneToDoHighHM,
        });
      }
      else if (priority === 1 && allToDoDoneMed) {
        dispatch({
          type: SET_TO_DOS_RECENT,
          data: [...allToDoDoneMed] || [],
          page: doneToDoMedCP,
          hasMore: doneToDoRoutineHM,
        });
      }
      else if (priority === 0 && allToDoDoneRoutine) {
        dispatch({
          type: SET_TO_DOS_RECENT,
          data: [...allToDoDoneRoutine] || [],
          page: doneToDoRoutineCP,
          hasMore: doneToDoRoutineHM,
        });
      }
      else {
        dispatch(getAllToDos(0, [], 10, 1, status, priority));
      }
    } else if (status == "ALL") {
      if (priority === 2 && allToDoHigh) {
        dispatch({
          type: SET_TO_DOS_RECENT,
          data: [...allToDoHigh] || [],
          page: allToDoHighCP,
          hasMore: allToDoHighHM,
        });
      }
      else if (priority === 1 && allToDoMed) {
        dispatch({
          type: SET_TO_DOS_RECENT,
          data: [...allToDoMed] || [],
          page: allToDoMedCP,
          hasMore: allToDoMedHM,
        });
      }
      else if (priority === 0 && allToDoRoutine) {
        dispatch({
          type: SET_TO_DOS_RECENT,
          data: [...allToDoRoutine] || [],
          page: allToDoRoutineCP,
          hasMore: allToDoRoutineHM,
        });
      }
      else {
        console.log('DEFAULT'); 
        dispatch(getAllToDos(0, [], 10, 1, status, priority));
      }
    } else {
      dispatch(getAllToDos(0, [], 10, 1, status, priority));
    }
  };

  const handleTelemedTodayFilter = (date) => {
    console.log("date", date);
    setCurrentTelemedTodayDate(date);
    dispatch(getSchedulesToday(0, 10, [], date));
  };

  const handleScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    if (preventReload) return;
    var bottom = Math.round(scrollHeight) - Math.round(scrollTop) === clientHeight;
    if (
      Math.round(scrollHeight) - Math.round(scrollTop) + 1 === clientHeight ||
      Math.round(scrollHeight) - Math.round(scrollTop) - 1 === clientHeight
    )
      bottom = true;
    if (bottom && hasMoreProgressNotesRecent) {
      dispatch(getAllProgressNotes(pageProgressNotesRecent + 1, allProgressNotesRecent, 10, currentProgressNoteTab));
    }
  };

  const handleToDosScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    if (preventToDosReload) return;
    var bottom = Math.round(scrollHeight) - Math.round(scrollTop) === clientHeight;
    if (
      Math.round(scrollHeight) - Math.round(scrollTop) + 1 === clientHeight ||
      Math.round(scrollHeight) - Math.round(scrollTop) - 1 === clientHeight
    )
      bottom = true;
    if (bottom && recentToDosHasMore) {
      dispatch(
        getAllToDos(
          recentToDosCurrentPage + 1, 
          allToDosRecent, 
          10, 
          1, 
          currentToDoTab, 
          currentPriority
        ));
    }
  };

  const handleTelemedTodayScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    if (preventToDosReload) return;
    const bottom = Math.round(scrollHeight) - Math.round(scrollTop) === clientHeight;
    if (bottom && scheduleTodayHasMore) {
      dispatch(getSchedulesToday(scheduleTodayCurrentPage + 1, 10, schedulesToday));
    }
  };

  const openConfirmTask = (actionItemId) => {
    setSelectedActionItemId(actionItemId);
    setIsConfirmTaskOpened(true);
  };

  const handleClickLabel = (label) => {
    dispatch(linkToLabel(label.objectId));
  };

  const markTaskAsDone = async (actionItemId) => {
    setIsCompletingTask(true);
    await dispatch(completeActionItem(actionItemId));
    dispatch(getAllToDos(0, [], 10, 1, currentToDoTab, currentPriority));
    setSelectedActionItemId("");
    setIsCompletingTask(false);
  };

  useEffect(() => {
    // setIsToDosLoading(true);
    dispatch(getDashboard());
    dispatch(getCircleLabels());
    dispatch(getUrgentCount());
    dispatch(getAttentionCount());
    dispatch(getReturnCallsCount());
    // dispatch(getHighPriorityCount());
    // dispatch(getMediumPriorityCount());
    // dispatch(getRoutinePriorityCount());
    // dispatch(getRiskManagement());
    dispatch(getAllProgressNotes());
    dispatch(getAllToDos());
    // dispatch(getRiskManagement());
    dispatch(getSchedulesToday());
    dispatch(getCirclesWithTodos())
      .then((circles) => {
        setCircles(circles);
        setIsToDosLoading(false);
      })
      .catch((err) => {
        setIsToDosLoading(false);
      });
  }, []);

  return (
    <div className="d-flex w-100">
      <Sidebar todoCount={todoCount} circleLabels={circleLabels} />
      <MainContainer>
        <div className="row h-100">
          <div className="col-12 col-md-4 col-flex">
            <MessagesCard
              attentionCount={attentionCount}
              urgentCount={urgentCount}
              returnCallCount={returnCallCount}
              isLoading={isLoading}
              handleCreateMessage={handleCreateMessage}
            />
            {/* <TasksCard
              highPriorityCount={highPriorityCount}
              mediumPriorityCount={mediumPriorityCount}
              routinePriorityCount={routinePriorityCount}
              isLoading={isLoading}
            /> */}
            <div className="dashboard-box overflow-auto">
              <h6 className="sidebar-heading d-flex justify-content-between align-items-center hc-primary-text">
                <strong>
                  <span>Tagged groups</span>
                </strong>
                <a className="text-secondary" href="#!">
                  <i className="fa fa-plus-circle"></i>
                </a>
              </h6>
              <CircleLabels labels={circleLabels} onClickLabel={handleClickLabel} />
            </div>
          </div>
          {/* <div className="col-12 col-md-3">
            <div
              style={{
                minHeight: "calc(100vh - 180px)",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <ProgressNotesCard
                handleOpenProgressNotes={handleOpenProgressNotes}
                recents={allProgressNotesRecent}
                isLoading={isLoadingPN}
                handleScroll={handleScroll}
                handleProgressNotesFilter={handleProgressNotesFilter}
                hasMorePN={hasMoreProgressNotesRecent}
              />
            </div>
          </div> */}

          <div className="col-12 col-md-4">
            <div
              style={{
                minHeight: "calc(100vh - 230px)",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <ToDosCard
                recents={allToDosRecent}
                isLoading={isToDosLoading}
                openConfirmTask={openConfirmTask}
                handleScroll={handleToDosScroll}
                handleToDosFilter={handleToDosFilter}
                hasMoreToDos={recentToDosHasMore}
                handleOpenPrescription={handleNewPrescriptionOrNotes}
                // handleSubToDosFilter={handleSubToDosFilter}
              />
            </div>
          </div>
          <div className="col-12 col-md-4">
            <TelemedToday
              conferences={schedulesToday}
              isLoading={isLoadingST}
              handleTelemedTodayScroll={handleTelemedTodayScroll}
              handleTelemedTodayFilter={handleTelemedTodayFilter}
              scheduleTodayHasMore={scheduleTodayHasMore}
            />
          </div>
        </div>
      </MainContainer>
      <ConferenceMain handleOpenPrescription={handleNewPrescriptionOrNotes} />
      <PatientModal
        isEmr={isEmr}
        onSelectPatient={handleSelectPatient}
        isOpen={patientModalShown}
        onClose={() => setPatientModalShown(false)}
        onOpenPatientModal={handleOpenPatientModal}
      />
      {rxModalShown ? (
        <PrescriptionModal threadId={circleId} isOpen={rxModalShown} onClose={() => setRxModalShown(false)} />
      ) : (
        ""
      )}
      {createPatientModalShown && (
        <CreatePatient
          newGroup={handleNewGroup}
          isOpen={createPatientModalShown}
          modalClose={() => {
            if (isDashboard) {
              dispatch(setActiveTab("chart"));
            }
            setCreatePatientModalShown(false);
          }}
        />
      )}
      {isConfirmTaskOpened && selectedActionItemId && (
        <ConfirmTask
          onClose={(id) => {
            if (id) {
              markTaskAsDone(id);
            } else {
              setIsConfirmTaskOpened(false);
              setSelectedActionItemId("");
            }
          }}
          actionItemId={selectedActionItemId}
        />
      )}
      <Loader isLoading={isCompletingTask} />
    </div>
  );
};

const CircleLabels = ({ labels, onClickLabel }) => {
  const containerStyles = {
    position: "relative",
    height: "100%",
  };

  const listStyles = {
    width: "100%",
    fontSize: "14px",
  };

  return (
    <div style={containerStyles}>
      <div className="list-group list-group-flush" style={listStyles}>
        {labels.map((label) => (
          <a
            onClick={(e) => onClickLabel(label)}
            className="list-group-item sidebar-item d-flex align-items-center label-cont"
            href="#!"
            key={label.objectId}
          >
            <i className="fas mr-2 fa-circle fa-sm hc-primary-text"></i>
            <span className="text-truncate text-">{label.name}</span>
            <span className="badge ml-auto">{label.count}</span>
          </a>
        ))}
      </div>
    </div>
  );
};

const MainContainer = (props) => {
  const styles = {
    overflow: "auto",
    backgroundColor: "#f8f9fc",
  };

  return (
    <div className="d-flex flex-column w-100" style={styles}>
      <div id="content overflow-auto">
        <div className="container-fluid my-3" style={{ height: "calc(100vh - 100px)" }}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

const filterLabels = (circleLabels) => {
  return circleLabels.filter((label) => label.code != "return_call");
};

const mySelector = (state) => ({
  requests: state.dashboard.requests,
  attentionCount: state.dashboard.attentionCount,
  urgentCount: state.dashboard.urgentCount,
  returnCallCount: state.dashboard.returnCallCount,
  // highPriorityCount: state.dashboard.highPriorityCount,
  // mediumPriorityCount: state.dashboard.mediumPriorityCount,
  // routinePriorityCount: state.dashboard.routinePriorityCount,
  isLoading: state.dashboard.isLoading,
  isLoadingRM: state.dashboard.isLoadingRM,
  riskManagement: state.dashboard.riskManagement,
  isLoadingST: state.dashboard.isLoadingST,
  schedulesToday: state.dashboard.schedulesToday,
  scheduleTodayCurrentPage: state.dashboard.allCurrentPage,
  scheduleTodayHasMore: state.dashboard.scheduleTodayHasMore,
  circleLabels: filterLabels(state.dashboard.circleLabels),
  todoCount: state.dashboard.todoCount,
  allProgressNotes: state.notes.allProgressNotes,
  allProgressNotesPending: state.notes.allProgressNotesPending,
  allProgressNotesDone: state.notes.allProgressNotesDone,
  allProgressNotesRecent: state.notes.allProgressNotesRecent,
  pageProgressNotesAll: state.notes.pageProgressNotesAll,
  pageProgressNotesPending: state.notes.pageProgressNotesPending,
  pageProgressNotesDone: state.notes.pageProgressNotesDone,
  pageProgressNotesRecent: state.notes.pageProgressNotesRecent,
  hasMoreProgressNotesRecent: state.notes.hasMoreProgressNotesRecent,
  hasMoreProgressNotesAll: state.notes.hasMoreProgressNotesAll,
  hasMoreProgressNotesPending: state.notes.hasMoreProgressNotesPending,
  hasMoreProgressNotesDone: state.notes.hasMoreProgressNotesDone,

  allToDos: state.thread.allToDos,
  allToDoHigh: state.thread.allToDoHigh,
  allToDoMed: state.thread.allToDoMed,
  allToDoRoutine: state.thread.allToDoRoutine,

  allToDoHighCP: state.thread.allToDoHighCP,
  allToDoMedPC: state.thread.allToDoMedCP,
  allToDoRoutineCP: state.thread.allToDoRoutineCP,

  allToDoHighHM: state.thread.allToDoHighHM,
  allToDoMedHM: state.thread.allToDoMedHM,
  allToDoRoutineHM: state.thread.allToDoRoutineHM,

  allToDosPending: state.thread.allToDosPending,
  allToDosPendingHigh: state.thread.allToDosPendingHigh,
  allToDosPendingMed: state.thread.allToDosPendingMed,
  allToDosPendingRoutine: state.thread.allToDosPendingRoutine,

  allToDosDone: state.thread.allToDosDone,
  allToDoDoneHigh: state.thread.allToDoDoneHigh,
  allToDoDoneMed: state.thread.allToDoDoneMed,
  allToDoDoneRoutine: state.thread.allToDoDoneRoutine,
  allToDosRecent: state.thread.allToDosRecent,
  allToDosCurrentPage: state.thread.allToDosCurrentPage,
  pendingToDoHighCP: state.thread.pendingToDoHighCP,
  pendingToDoMedCP: state.thread.pendingToDoMedCP,
  pendingToDoRoutineCP: state.thread.pendingToDoRoutineCP,
  pendingToDoHighHM: state.thread.pendingToDoHighHM,
  pendingToDoMedHM: state.thread.pendingToDoMedHM,
  pendingToDoRoutineHM: state.thread.pendingToDoRoutineHM,
  doneToDoHighCP: state.thread.doneToDoHighCP,
  doneToDoMedCP: state.thread.doneToDoMedCP,
  doneToDoRoutineCP: state.thread.doneToDoRoutineCP,
  doneToDoHighHM: state.thread.doneToDoHighHM,
  doneToDoMedHM: state.thread.doneToDoMedHM,
  doneToDoRoutineHM: state.thread.doneToDoRoutineHM,

  currentProgressNotesPage: state.notes.allCurrentPage,
  isLoadingPN: state.notes.isLoading,
  isToDosLoading: state.thread.isToDosLoading,
  allToDosHasMore: state.thread.allToDosHasMore,
  pendingToDosCurrentPage: state.thread.pendingToDosCurrentPage,
  pendingToDosHasMore: state.thread.pendingToDosHasMore,
  doneToDosCurrentPage: state.thread.doneToDosCurrentPage,
  doneToDosHasMore: state.thread.doneToDosHasMore,
  recentToDosCurrentPage: state.thread.recentToDosCurrentPage,
  recentToDosHasMore: state.thread.recentToDosHasMore,
});

export default Dashboard;
