import React, { Component } from "react";
import { connect } from "react-redux";
import { parseAction } from "../../../actions/REST";
import Group from "./Group";
import User from "./User";
import Patient from "./Patient";
import "./Setting.css";
const $ = window.$;

export class SettingContainer extends Component {
  constructor() {
    super();
    this.state = {
      navIsOpen: false
    };

    this.openNav = this.openNav.bind(this);
    this.closeNav = this.closeNav.bind(this);
  }
  componentWillUnmount() {
    this.closeNav();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.isOpen) {
      this.openNav();
    } else {
      this.closeNav();
    }
  }

  openNav() {
    this.refs.mySidenav.style.width = "400px";
    this.refs.blur.style.width = "100%";
    this.setState({
      navIsOpen: true
    });
  }

  closeNav() {
    this.refs.mySidenav.style.width = "0";
    this.refs.blur.style.width = "0";
    this.setState({
      navIsOpen: false
    });
  }

  handleCloseNav() {
    this.props.closeNav();
  }

  render() {
    let { thread, threads = [], activeTab } = this.props;
    let ActiveSetting;

    switch (thread.threadType) {
      case "group":
        if (thread.groupType === "regular") {
          ActiveSetting = (
            <Group
              thread={thread}
              threads={threads}
              activeTab={activeTab}
              saveGroupObject={this.props.saveGroupObject}
              saveGroupMembers={this.props.saveGroupMembers}
              handleCloseNav={this.handleCloseNav.bind(this)}
            />
          );
        } else {
          ActiveSetting = (
            <Patient
              thread={thread}
              saveGroupObject={this.props.saveGroupObject}
              saveGroupMembers={this.props.saveGroupMembers}
              handleCloseNav={this.handleCloseNav.bind(this)}
              navIsOpen={this.state.navIsOpen}
            />
          );
        }

        break;

      case "private":
        ActiveSetting = (
          <User
            userId={thread.partnerObjectId}
            threadId={thread.threadId}
            isOpen={this.props.isOpen}
          />
        );
        break;

      default:
    }
    return (
      <div>
        <div
          ref="blur"
          className="blur"
          onClick={this.handleCloseNav.bind(this)}
        ></div>
        <div ref="mySidenav" className="sidenav">
          <a
            href="#!"
            className="closebtn"
            onClick={this.handleCloseNav.bind(this)}
          >
            <i className="fas fa-arrow-right"></i>
          </a>
          {ActiveSetting}
        </div>
      </div>
    );
  }
}

// export default SettingContainer;

const mapStateToProps = state => ({
  threads: state.thread.threads,
  activeTab: state.thread.activeTab
});

export default connect(mapStateToProps, {})(SettingContainer);
