import {
    TEAM_FETCH_STATUS,
    TEAM_NEW_NAME,
    NEW_TEAM,
    NEW_TEAM_MEMBERS,
    REMOVE_TEAM_MEMBER
} from '../actions/types';

const initialState = {
    teams : [],
    teamFetchStatus : "",
}

export default function(state = initialState, action) {
	switch(action.type) {
        case TEAM_FETCH_STATUS:
            if (action.status === "FETCHING") {
                return {
                    ...state,
                    teamFetchStatus : action.status
                }
            } else { // else action.status === SUCCESS

                return {
                    ...state,
                    teamFetchStatus : action.status,
                    teams : action.teams
                }
            }
        break;

        case NEW_TEAM:
            return {
                ...state,
                teams : [action.team, ...state.teams]
            }
        break;

        case TEAM_NEW_NAME:
            return {
                ...state,
                teams : state.teams.map(team => {

                    if (team.objectId === action.teamObjectId) {
                        return {
                            ...team,
                            name : action.name
                        }
                    }
                    return team;
                })
            }
        break;

        case NEW_TEAM_MEMBERS:
            var team = state.teams.find(c => c.objectId === action.teamObjectId);
            if (team) {
                if (team.members) {
                    team = {
                        ...team,
                        members : [...action.newMembers, ...team.members]
                    }
                } else {
                    team = {
                        ...team,
                        members : [...action.newMembers]
                    }
                }
            }
            return {
                ...state,
                teams : state.teams.map(t => {
                    if (t.objectId === team.objectId ){
                        return team;
                    }
                    return t;
                })
            };

        break;

        case REMOVE_TEAM_MEMBER:
            var team = state.teams.find(c => c.objectId === action.teamObjectId);
            if (team) {
                if (team.members) {
                    team = {
                        ...team,
                        members : [...team.members.filter((member) => member.objectId !== action.memberObjectId)]
                    }
                }
            }
            return {
                ...state,
                teams : state.teams.map(t => {
                    if (t.objectId === team.objectId ){
                        return team;
                    }
                    return t;
                })
            };
        break;

		default:
		return state;
	}
}