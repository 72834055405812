import React from 'react';
import defaultProfilePic from '../../../../assets/images/default.png';

const Chips = ({contact, onUnselect}) => {
  
  let contactURL;

  if (typeof contact.picture === "undefined") {
      contactURL = defaultProfilePic;
  } else {
      contactURL = contact.picture.url;
  }

  return (
      <div className="chips">
          <img src={contactURL} className="chip-img" alt={contact.displayName} />
          <span>{contact.firstName}</span>
          <i onClick={(e) => onUnselect()} className="close-chip far fa-times-circle"></i>
      </div>
  )
}

export default Chips;
