import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addContact, removeContact, blockContactNoThread as blockContact, unblockContactNoThread as unblockContact } from '../../../actions/ContactAction';
import Notify from '../../Common/Notify';
import Loader from '../../Common/Loader';
import { launchTelemed } from '../../../actions/ConferenceAction';

function ContactMenu(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');

    const dispatch = useDispatch();

    const installationId = useSelector(state => {
        return state.session.installationId
    });

    const { contact } = props;

    function setLoading(isLoading, loadingMessage) {
        setIsLoading(isLoading);
        setLoadingMessage(loadingMessage);
    }

    function handleUnblockContact(e) {
        // Prevent thread to be selected upon clicking menu
        e.stopPropagation();
        e.preventDefault();

        unblockContact(contact)(dispatch)
            .then(() => {
                Notify.success("Contact unblocked successfully!");
            })
            .catch(() => {
                Notify.error("Cannot unblock contact.");
            });
    }

    function handleBlockContact(e) {
        // Prevent thread to be selected upon clicking menu
        e.stopPropagation();
        e.preventDefault();

        blockContact(contact)(dispatch)
            .then(() => {
                Notify.success("Contact blocked successfully!");
            })
            .catch(() => {
                Notify.error("Cannot block contact.");
            });
    }

    function handleAddContact(e) {
        // Prevent thread to be selected upon clicking menu
        e.stopPropagation();
        e.preventDefault();

        addContact(contact)(dispatch)
            .then(() => {
                Notify.success("Contact request sent!");
            })
            .catch(() => {
                Notify.error("Contact request failed.");
            });
    }

    function handleRemoveContact(e) {
        // Prevent thread to be selected upon clicking menu
        e.stopPropagation();
        e.preventDefault();

        removeContact(contact)(dispatch)
            .then(() => {
                Notify.success("Contact removed successfully!");
            })
            .catch(() => {
                Notify.error("Cannot remove contact.");
            });
    }

    function handleClick(e) {
        // Prevent thread to be selected upon clicking menu
        e.stopPropagation();
    }

    function handleCall(hasVideo) {
        createConference([contact], hasVideo);
    }

    function createConference(participants, hasVideo) {

        const participantIds = participants.map(p => p.objectId);

        dispatch(launchTelemed(participantIds));
    }

    return (
        <div className="contact-menu-overlay">
            <div className="dropdown mr-2">
                <button onClick={(e) => handleClick(e)} type="button" className="btn contact-menu-button dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="fas fa-ellipsis-v"></i>
                </button>
                <div style={{ boxShadow: '0px 4px 8px 0px #888888' }} className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                    <h6 className="dropdown-header">Action</h6>
                    {
                        contact.isNotContact &&
                        <a className="dropdown-item" href="#!" onClick={handleAddContact}>
                            <i className="fas fa-user-plus" style={{ marginRight: '5px' }}></i>
                            Send contact request
                        </a>
                    }
                    {
                        !contact.isNotContact &&
                        (<span>
                            <a className="dropdown-item" href="#!" onClick={() => handleCall(false)}>
                                <i className="fas fa-phone" style={{ marginRight: '5px' }}></i>
                                Voice call
                            </a>
                            <a className="dropdown-item" href="#!" onClick={() => handleCall(true)}>
                                <i className="fas fa-video" style={{ marginRight: '5px' }}></i>
                                Video call
                            </a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="#!" onClick={handleRemoveContact}>
                                <i className="fas fa-user-minus" style={{ marginRight: '5px' }}></i>
                                Remove from contacts
                            </a>
                            {
                                contact.isBlocked ?
                                    <a className="dropdown-item" href="#!" onClick={handleUnblockContact}>
                                        <i className="fas fa-ban" style={{ marginRight: '5px' }}></i>
                                    Unblock
                                </a>
                                    :
                                    <a className="dropdown-item" href="#!" onClick={handleBlockContact}>
                                        <i className="fas fa-ban" style={{ marginRight: '5px' }}></i>
                                    Block
                                </a>
                            }
                        </span>)
                    }
                </div>
            </div>

            <Loader isLoading={isLoading} message={loadingMessage} />
        </div>
    );
}

export default ContactMenu;
