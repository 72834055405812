import React, { useEffect, useMemo, useState } from 'react';
import util from '../../helper/util'

const $ = window.$;

const Modal = ({
  size = '',
  children,
  shown = false,
  onHide,
  height = 'modal-full-height'
}) => {

  const modalId = useMemo(() => {
    return util.uuid();
  }, []);

  const modalClassName = useMemo(() => {
    return [
      'modal-dialog',
      size,
    ].join(' ');
  }, [size])

  const modalClassHeight = useMemo(() => {
    return [
      'modal-content',
      height,
    ].join(' ');
  }, [height])

  useEffect(() => {

    // Set DOM selector
    const selector = '#' + modalId;
    
    // Initialize modal properties
    $(selector).modal({
      backdrop: 'static',
      keyboard: false
    });
    $('.modal-dialog').draggable({
      handle: ".modal-header"
    });

    // Handle event when modal was closed internally
    $(selector).on('hidden.bs.modal', () => onHide());
  }, [modalId])

  // Handle show and hide
  useEffect(() => {

    // Set DOM selector
    const selector = '#' + modalId;

    if (shown) {
      $(selector).modal('show');
    }
    else {
      $(selector).modal('hide');
    }
  }, [shown, modalId]);

  useEffect(() => {
    
    
    const selector = '#' + modalId;

    // On unmount, hide the modal to prevent
    // the backdrop shadow from persisting
    return () => {
      $(selector).modal('hide');
    }
  }, [modalId]);

  return (
    <div id={modalId} className="modal" tabIndex="-1" role="dialog">
      <div className={modalClassName} role="document">
        <div className={modalClassHeight}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Modal;
