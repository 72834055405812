import React, { Component } from 'react'
import Ringtone from '../../assets/audio/ringtone.wav'
import { Howl } from 'howler'

const $ = window.$;
export class CallerConfirmationModal extends Component {

    constructor() {
        super();

        this.state = {
            setTime : 60
        }
    }

    componentDidMount() {
        if (this.props.isOpen) {
            $("#callerConfirmationModal").modal({
                backdrop: 'static',
                keyboard: false
            });

            $("#callerConfirmationModal").modal('show');
        } else {
            $("#callerConfirmationModal").modal('hide');
        }
        $("#callerConfirmationModal").on('hidden.bs.modal', function (e) {
            this.props.modalClose();
        }.bind(this))


        this.sound = new Howl({
            src: [Ringtone],
            autoplay: true,
            loop: true,
            autoUnlock : true,
            usingWebAudio : true,
            onplayerror: function() {
                this.sound.once('unlock', function() {
                  this.sound.play();
                });
            }
        });

        $("#autoPlay").on('click', () => {
            this.sound.play();
        })
        this.startTimeout();
    }

    getSnapshotBeforeUpdate() {
        if (this.props.didCancel) {
            clearInterval(this.timeOut);
            this.sound.stop();
            $("#callerConfirmationModal").modal('hide')
        }

        return null;
    }

    componentDidUpdate() {

    }

    countdown() {
        let isOnCall = localStorage.getItem("onCall");
        let _onCall =  JSON.parse(isOnCall);
        if (_onCall === true) {
            clearInterval(this.timeOut);
            this.sound.stop();
            $("#callerConfirmationModal").modal('hide')
            return;
        }
        console.log('Countdown ' + setTime);
        let setTime = this.state.setTime;
        setTime = setTime - 1;
        this.setState({
            setTime : setTime
        })


        if (setTime === 0) {

            clearInterval(this.timeOut);
            this.sound.stop();
            $("#callerConfirmationModal").modal('hide')
        }
    }

    startTimeout() {
		this.countdown();

		this.timeOut = setInterval(function() {
			this.countdown();
		}.bind(this), 1000);
	}

    componentWillUnmount() {
        this.sound.stop();
    }

    confirm() {
        this.sound.stop();
        this.props.confirmAction();
        clearInterval(this.timeOut);
        $("#callerConfirmationModal").modal('hide')
    }

    declineCall() {
        this.sound.stop();
        this.props.declineCall();
        clearInterval(this.timeOut);
        $("#callerConfirmationModal").modal('hide')
    }

    render() {
        let { incomingCall } = this.props;
        let ImageURL;
        if (incomingCall.caller_image_url === "" || typeof incomingCall.caller_image_url === undefined) {
            ImageURL = require('../../assets/images/default.png');
        } else {
            ImageURL = incomingCall.caller_image_url;
        }

        return (
            <div id="callerConfirmationModal" className="modal" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
                <div id="autoPaly"></div>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-body" style={{paddingBottom: "0px"}}>
                        <h5 className="hc-primary-text">Incoming Call</h5>
                        <div style={{position: "relative", display: "block", height: "100px", paddingLeft: "100px"}}>
                            <img src={ImageURL} alt="Profile" style={{position: "absolute", left: "15px", top: "15px", height: "70px", width: "70px", borderRadius: "15px"}}/>
                            <h5 style={{paddingTop: "25px", marginBottom: "0"}}>{incomingCall.caller_name}</h5>
                            <p style={{fontSize: "0.9em"}}>Answer to start the call.</p>
                        </div>
                    </div>
                    <div className="modal-footer" style={{border: "0", padding: "0 20px 20px 0"}}>
                        <button onClick={this.confirm.bind(this)} type="button" className="btn btn-hubchart btn-hubchart-primary">Answer</button>
                        <button onClick={this.declineCall.bind(this)} type="button" className="btn btn-hubchart btn-hubchart-close">Decline</button>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CallerConfirmationModal
