import React, { useEffect } from "react";
import "./index.css";

function AssessmentComponent({ assessment = "" }) {
  return (
    <div className="card custom-card">
      <h5 className="card-header ">
        <strong>Assessment</strong>
      </h5>
      <div className="card-body">
        <div className="custom-form-group-container pe-text">
          {assessment || ""}
        </div>
      </div>
    </div>
  );
}

export default AssessmentComponent;
