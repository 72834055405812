import React, { useEffect } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import './index.css';

function AddendumNoteComponent({
  addendumNote = '',
  updateAddendumNote = () => {},
  clearField = () => {},
}) {

  function handleUpdateAddendum (e) {
    e.preventDefault();
    updateAddendumNote(e);
  }

  function handleClear (name) {
    clearField(name);
  }
  
  useEffect(() => {
    return () => {}
  }, []);

  return (
    <div className="card custom-card">
      <h5 className="card-header ">ADDENDUM NOTE</h5>
      <div className="card-body" style={{ padding: '10px' }}>
        <div className="custom-form-group-container txt-clr" style={{ margin: '5px' }}>
          <TextareaAutosize 
            id="addendumNote" 
            name="addendumNote" 
            type="text" 
            className="form-control custom-textarea" 
            placeholder="Addendum Note" 
            onChange={e => handleUpdateAddendum(e)}
            value={addendumNote}
            required={true}
            minRows={2} />
          <i 
            className="fal fa-times clear-textarea text-danger"
            onClick={() => handleClear('addendumNote')}></i>
        </div>
      </div>
    </div>
  );
}

export default AddendumNoteComponent;
