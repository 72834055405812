import { parseAction } from '../actions/REST';
import config from '../config'

const getPubNubKeys = () => {

  const method = 'post';
  const url = config.BASE_URL + '/parse/functions/getPubNubKeys';

  return parseAction(method, url)
  .then(res => res.result);
}

export default {
  getPubNubKeys
}
