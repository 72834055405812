import React, { useCallback, useEffect, useMemo, useState } from 'react';

/*
  This hook manages the state of a <select> element
  when the value is an Object
 */
const useSelect = (choices, defaultChoice, key) => {
  
  // First, we store the index of the selected option
  const [index, setIndex] = useState(null);

  // And to retrieve the current value,
  const value = useMemo(() => {

    // We just use the index
    return choices[index];
  }, [choices, index]);

  // In setting the value,
  // we only store the index of that item
  const setValue = useCallback((value={}) => {
    const newIndex = choices.findIndex(
      item => item[key] === value[key]
    );
    setIndex(newIndex);
  }, [choices]);

  // If a default choice was provided,
  // find its index
  useEffect(() => {

    if (defaultChoice) {
      const newIndex = choices.findIndex(item => {
        return item[key] === defaultChoice[key]
      });
      
      setIndex(newIndex);
    }

  }, [choices, defaultChoice]);

  return {
    value,
    setValue,
    index,
    setIndex,
  }
}

export default useSelect;
