import React, { useEffect, useState } from 'react';
import { SearchInput as Input, Button } from '../../../../Common/Forms';

const SearchInput = (props) => {

  const { onSearch, onClear } = props;

  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    if (!keyword) onClear();
  }, [keyword]);

  const handleSubmit = (e) => {
    
    e.preventDefault();

    onSearch(keyword);
  }

  return (
    <form onSubmit={e => handleSubmit(e)}>
      <div className="d-flex">
        <Input
          className="form-control mr-2"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          placeholder="Enter contact email or phone number"
          type="search"
          required/>
        <Button disabled={keyword.length < 10} className="btn btn-primary" type="submit">Search</Button>
      </div>
      <sub className="text-primary">Enter at least 10 characters</sub>
    </form>
  );
}

export default SearchInput;
