import React, { Component } from 'react'

const $ = window.$;
export class GroupWarningModal extends Component {

    componentDidMount() {
        if (this.props.isOpen) {
            $("#groupWarningModal").modal('show');

        } else {
            $("#groupWarningModal").modal('hide');
        }
        $("#groupWarningModal").on('hidden.bs.modal', function (e) {
            this.props.modalClose();
        }.bind(this))
    }

    confirm() {
        this.props.confirmAction();
        $("#groupWarningModal").modal('hide');
    }

    render() {
        return (
            <div id="groupWarningModal" className="modal" tabIndex="-1" role="dialog" data-backdrop="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-body" style={{paddingBottom: "0px"}}>
                        <h5 style={{color: "#D84B55", fontSize: "1.25rem"}}>{this.props.isCreator ? "Are you sure you want to delete this group?" : "Are you sure you want to leave?"}</h5>
                        {/* <p style={{fontSize: "0.9em"}}>Message here</p> */}
                    </div>
                    <div className="modal-footer" style={{border: "0", padding: "0 20px 20px 0"}}>
                        <button onClick={this.confirm.bind(this)} type="button" className="btn btn-hubchart btn-hubchart-danger">{this.props.isCreator ? "Delete" : "Leave"}</button>
                        <button type="button" className="btn btn-hubchart btn-hubchart-close" data-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default GroupWarningModal
