import React, { Component } from 'react'
import { parseAction } from '../../../actions/REST'
import { declineCall, answerCall, requestCallToken, initiateCall, cancelCall } from '../../../actions/CallAction'
import { connect } from 'react-redux';
import Video from 'twilio-video'
import Parse from 'parse'
import Instruction from './Instruction'
import Block from './Block'
import LoadingSpinner from './LoadingSpinner'
import NewCall from './NewCall'
import Redial from './Redial'
import './Video.css'
import config from '../../../config'

const $ = window.$;
export class VideoCallContainer extends Component {
    constructor() {
        super();
        this.state = {
            callData : {},
            showInstruction : false,
            showBlock : false,
            showNewCall : false,
            isLoading : true,
            didAllowCameraAndMic : false,
            onCall : false,
            mute : false,
            hasVideo : true,
            defaultHasVideo : true,
            status : "Connecting...",
            setTime : 30,
            secodes : 0,
            minutes : 0,
            timeStarting : false
        }
        this.activeRoom = null;
        this.joinRoom = this.joinRoom.bind(this)
        this.roomJoined = this.roomJoined.bind(this);
        this.userWillLeave = this.userWillLeave.bind(this);


        this.detachParticipantTracks = this.detachParticipantTracks.bind(this);
        this.getTracks = this.getTracks.bind(this);
        this.participantConnected = this.participantConnected.bind(this);
        this.attachTracks = this.attachTracks.bind(this);
        this.attachTrack = this.attachTrack.bind(this);
        this.disconnectCallNoCancel = this.disconnectCallNoCancel.bind(this);
    }

    componentDidMount() {
        let params = new URLSearchParams(this.props.location.search);
        let room = params.get("rid");
        let installationId = params.get("iid");
        let _callData = localStorage.getItem(room);
        let callData = JSON.parse(_callData);
        this.setState({
            callData,
            hasVideo : callData.has_video,
            defaultHasVideo : callData.has_video,
            installationId : installationId
        })

        if (callData.status === "INCOMING_CALL") {
            if (callData.destination === Parse.User.current().id) {
                this.joinRoom(callData.destination, room, callData.has_video);
            }
        }

        if (callData.status === "CALL_ENDED") {
            // Show Page to start new call
            this.setState({
                showNewCall : true,
                isLoading : false
            })
        }

        if (callData.status === "REDIAL") {
            // Show redial page
            // callData.status = "REDIAL";
            localStorage.setItem(room, JSON.stringify(callData));
            this.setState({
                showRedial : true,
                isLoading : false,
                callData : callData,
            })
        }

        if (callData.status === "NEW_CALL") {
            // Start a new call
            this.setState({
                setTime : 30,
                seconds : 0,
                minutes : 0,
                status : "Connecting..."
            })
            this.startCall(callData, callData.has_video);
        }
        // this.startCall();

    }

    componentWillUnmount() {
        this.disconnectCall();
        clearInterval(this.timeOut);
        clearInterval(this.timeOut2);
        // window.removeEventListener('beforeunload', this.userWillLeave);
        localStorage.removeItem(this.state.callData.room);
    }

    userWillLeave(e) {
        e.preventDefault();
        // this.disconnectCall();
        this.props.declineCall(this.state.callData.uuid, this.state.installationId);
    }

    countdown() {
        let setTime = this.state.setTime;
        setTime = setTime - 1;
        this.setState({
            setTime : setTime
        })
        if (setTime === 0) {
            clearInterval(this.timeOut);
            this.disconnectCall();
            this.setState({
                status : "No Answer"
            })
        } else {
            let _callData = localStorage.getItem(this.state.callData.room);
            let callData = JSON.parse(_callData);
            if (callData !== null) {
                if (callData.status === "DECLINE_CALL") {
                    clearInterval(this.timeOut);
                    this.disconnectCall();
                    this.setState({
                        status : "No Answer"
                    })
                }

                if (callData.isRinging) {
                    this.setState({
                        status : "Ringing..."
                    })
                }
            }

        }
    }

    startTimeout() {
		this.countdown();

		this.timeOut = setInterval(function() {
			this.countdown();
		}.bind(this), 1000);
    }

    timer() {
        let seconds = this.state.seconds;
        seconds = seconds + 1;

        if (seconds < 60) {
            this.setState({
                seconds : seconds
            })
        } else {
            let totalMinutes = this.state.minutes + 1;
            this.setState({
                minutes : totalMinutes,
                seconds : 0
            })
        }
    }

    startCallTime() {
		this.timer();

		this.timeOut2 = setInterval(function() {
			this.timer();
		}.bind(this), 1000);
    }

    joinRoom(destination, room, has_video) {
        let configTrack = {
            audio: true,
            // video: true
        }

        if (has_video) {
            configTrack.video = true;
        }


        Video.createLocalTracks(configTrack).then(localTracks => {

            this.previewTracks = localTracks;

            this.setState({
                onCall : true,
                isLoading : false,
                showInstruction : false,
                showBlock : false,
            })

            parseAction("post", config.BASE_URL + "/parse/functions/requestCallToken", {
                destination : destination,
                room : room
            }).then((res) => {
                var connectOptions = {
                    name: res.result.room,
                    tracks: localTracks,
                    // logLevel: "debug"
                };
                Video.connect(res.result.token, connectOptions).then(this.roomJoined);
            }).catch((error) => {
                return Promise.reject({message: "Request token error"});
            })
        }, (error) => {
            if (error.message === "Permission denied" || error.message === "Permission dismissed") {
                this.setState({
                    showInstruction : false,
                    showBlock : true,
                    isLoading : false
                })
            }

            if (error.message === "Request token error") {

            }
            return Promise.reject({message: error.message});
        });
    }

    startCall(callData, hasVideo) {
        let room = callData.room;
        this.startTimeout();
        // callData.has_video = true;

        this.setState({
            showRedial : false,
            showNewCall : false,
            showBlock : false,
            showInstruction : false,
            isLoading : true,
            callData : callData,
            // hasVideo : true,
            mute : false,
            status : "Connecting..."
        })

        let _arr = room.split("_");
        const userId = Parse.User.current().id;
        let destination;
        let x;
        for (x of _arr) {
            if (x !== userId) {
                destination = x;
                break;
            }
        }

        let configTrack = {
            audio: true,
            // video: true
        }

        if (hasVideo) {
            configTrack.video = true;
        }


        Video.createLocalTracks(configTrack).then(localTracks => {

            this.previewTracks = localTracks;

            this.setState({
                onCall : true,
                isLoading : false,
                showInstruction : false,
                showBlock : false,
            })
            this.props.requestCallToken(destination, room).then((resToken) => {
                let tempCallData = this.state.callData;
                tempCallData.uuid = resToken.uuid;
                this.setState({
                    callData : tempCallData
                })
                this.props.initiateCall(destination, resToken.uuid, hasVideo).then(res => {
                    var connectOptions = {
                        name: room,
                        tracks: localTracks,
                        // logLevel: "debug"
                    };

                    Video.connect(resToken.token, connectOptions).then(this.roomJoined);
                }).catch(error => {
                    return Promise.reject({message: "Failed initiate Call"});
                })
            }).catch(error => {
                return Promise.reject({message: "Failed initiate Call"});
            });

            // parseAction("post", config.BASE_URL + "/parse/functions/requestCallToken", {
            //     destination : destination,
            //     room : room
            // }).then((res) => {

            //     var connectOptions = {
            //         name: res.result.room,
            //         tracks: localTracks,
            //         // logLevel: "debug"
            //     };
            //     Video.connect(res.result.token, connectOptions).then(this.roomJoined);
            // }).catch((error) => {
            //     return Promise.reject({message: "Request token error"});
            // })
        }, (error) => {
            if (error.message === "Permission denied" || error.message === "Permission dismissed") {
                this.setState({
                    showInstruction : false,
                    showBlock : true,
                    isLoading : false
                })
            }

            if (error.message === "Failed initiate Call") {

            }
        });


        // this.props.requestCallToken(destination, room).then(res => {
        //     console.log(res);
        // }).catch(error => {

        // });
    }

    roomJoined(room) {
        if (this.state.callData.status === "INCOMING_CALL") {
            this.props.answerCall(this.state.callData.uuid, this.state.installationId);
            this.setState({
                seconds : 0,
                minutes : 0,
                timeStarting : true
            })
            this.startCallTime();
        }

        this.activeRoom = room;

        window.room = room.name;
        const localParticipant = room.localParticipant;

        // Attach LocalParticipant's Tracks, if not already attached.
        let previewContainer = this.refs.localMedia;
        if (previewContainer) {
            if (!previewContainer.querySelector('video')) {
                this.attachTracks(this.getTracks(room.localParticipant), previewContainer);
            }
        }


        // // Attach the Tracks of the Room's Participants.
        let remoteMediaContainer = this.refs.remoteMedia
        room.participants.forEach((participant) => {
            this.participantConnected(participant, remoteMediaContainer);
        });

        // When a Participant joins the Room, log the event.
        room.on('participantConnected', (participant) => {
            // log("Joining: '" + participant.identity + "'");
            this.setState({
                seconds : 0,
                minutes : 0,
                timeStarting : true
            })
            clearTimeout(this.timeOut);
            this.startCallTime();
            this.participantConnected(participant, remoteMediaContainer);
        });

        // When a Participant leaves the Room, detach its Tracks.
        room.on('participantDisconnected', (participant) => {
            console.log("participant disconnected")
            // log("RemoteParticipant '" + participant.identity + "' left the room");
            this.setState({
                status : "Call Ended"
            })
            this.detachParticipantTracks(participant);

            // This is it
            // When the callee leaves room,
            // this function calls api cancelCall
            // Prevent this api call
            this.disconnectCallNoCancel();
        });

        // Once the LocalParticipant leaves the room, detach the Tracks
        // of all Participants, including that of the LocalParticipant.
        room.on('disconnected', () => {
            console.log("user disconnected")
            // log('Left');
            if (this.previewTracks) {
                this.previewTracks.forEach(function(track) {
                    track.stop();
                });
                this.previewTracks = null;
            }
            this.detachParticipantTracks(localParticipant);
            room.participants.forEach(this.detachParticipantTracks);
            this.setState({
                status : "Call Ended",
                seconds : 0,
                minutes : 0,
                setTime : 0
            })
            this.activeRoom = null;
        });

    }

    // Attach the Track to the DOM.
    attachTrack(track, container) {
        container.appendChild(track.attach());
    }

    // Attach array of Tracks to the DOM.
    attachTracks(tracks, container) {
		tracks.forEach(track => {
            console.log(track)
			container.appendChild(track.attach());
		});
    }

    // Get the Participant's Tracks.
    getTracks(participant) {
        return Array.from(participant.tracks.values()).filter((publication) => {
            return publication.track;
        }).map((publication) => {
            return publication.track;
        });
    }

    // Detach given track from the DOM
    detachTrack(track) {
        track.detach().forEach((element) => {
            element.remove();
        });
    }

    // A new RemoteTrack was published to the Room.
    trackPublished(publication, container) {

        if (publication.isSubscribed) {
            this.attachTrack(publication.track, container);
        }
        publication.on('subscribed', (track) => {
            this.attachTrack(track, container);
        });
        publication.on('unsubscribed', this.detachTrack);
    }

    // A RemoteTrack was unpublished from the Room.
    trackUnpublished(publication) {
        console.log("remote track unpublished")
        console.log("remote track unpublished");
    }

    // A new RemoteParticipant joined the Room
    participantConnected(participant, container) {
        participant.tracks.forEach((publication) => {
            this.trackPublished(publication, container);
        });
        participant.on('trackPublished', (publication) => {
            this.trackPublished(publication, container);
        });
        participant.on('trackUnpublished', this.trackUnpublished);
    }

    // Detach the Participant's Tracks from the DOM.
    detachParticipantTracks(participant) {
        var tracks = this.getTracks(participant);
        tracks.forEach(this.detachTrack);
    }

    disconnectCall() {
        clearTimeout(this.timeOut2);
        let callData = this.state.callData;
        if (this.activeRoom) {
            this.activeRoom.disconnect();
        }

        const status = callData.status;
        // status call_ended means that i'm calling or redialing
        
        if (status === "CALL_ENDED" || status === "NEW_CALL") {
            this.props.cancelCall(callData.uuid, this.state.installationId);
        }
        // Update localStorage CALL_ENDED

        callData.status = "REDIAL";
        callData.isRinnging = false;
        localStorage.setItem(this.state.callData.room, JSON.stringify(callData));
        localStorage.setItem("onCall", false);
        this.setState({
            callData : callData,
            showRedial : true,
            showBlock : false,
            showInstruction : false,
            showNewCall : false,
            onCall :false,
            timeStarting : false,
            seconds : 0,
            minutes : 0,
            setTime :0,
        });
    }

    disconnectCallNoCancel() {
        clearTimeout(this.timeOut2);
        let callData = this.state.callData;
        if (this.activeRoom) {
            this.activeRoom.disconnect();
        }

        callData.status = "REDIAL";
        callData.isRinnging = false;
        localStorage.setItem(this.state.callData.room, JSON.stringify(callData));
        localStorage.setItem("onCall", false);
        this.setState({
            callData : callData,
            showRedial : true,
            showBlock : false,
            showInstruction : false,
            showNewCall : false,
            onCall :false,
            timeStarting : false,
            seconds : 0,
            minutes : 0,
            setTime :0,
        });
    }

    muteAudio() {
        const localParticipant = this.activeRoom.localParticipant;
        localParticipant.audioTracks.forEach(function(track) {
            track.track.disable();
        })
        this.setState({
            mute : true
        })
    }

    unmuteAudio() {
        const localParticipant = this.activeRoom.localParticipant;
        localParticipant.audioTracks.forEach(function(track) {
            track.track.enable();
        })
        this.setState({
            mute : false
        })
    }

    pauseVideo() {
        $('[data-toggle="tooltip"]').tooltip();
        if (!this.activeRoom) {
            return;
        }
        const localParticipant = this.activeRoom.localParticipant;
        localParticipant.videoTracks.forEach(videoTrack => {
            videoTrack.track.disable();
        });


        this.setState({
            hasVideo : false
        })
    }

    unpauseVideo() {
        const localParticipant = this.activeRoom.localParticipant;
        if (localParticipant.videoTrack) {
            localParticipant.videoTracks.forEach(videoTrack => {
                videoTrack.track.enable();
            });
            this.setState({
                hasVideo : true
            })
        } else {
            let self = this;
            Video.createLocalVideoTrack().then(function(videoTrack) {
                let previewContainer = self.refs.localMedia;
                if (previewContainer) {
                    if (!previewContainer.querySelector('video')) {
                        localParticipant.publishTrack(videoTrack)
                        localParticipant.videoTrack = videoTrack;
                        self.attachTrack(videoTrack, previewContainer);

                        self.setState({
                            hasVideo : true
                        })
                    }
                }
            });
        }
    }

    screenShare() {

    }

    _renderError() {
        // console.logg(this.state.onCall)
        if (!this.state.onCall) {
            if (this.state.isLoading) {
                return (
                    <LoadingSpinner />
                )
            }
        }

        if (this.state.showInstruction) {
            return (
                <Instruction />
            )
        }
        if (this.state.showBlock) {
            return (
                <Block />
            )
        }
        if (this.state.showRedial) {
            return (
                <Redial startCall={this.startCall.bind(this, this.state.callData, this.state.defaultHasVideo)} callData={this.state.callData} status={this.state.status}/>
            )
        }
        if (this.state.showNewCall) {
            return (
                <NewCall startCall={this.startCall.bind(this, this.state.callData, this.state.defaultHasVideo)} callData={this.state.callData}/>
            )
        }
        return null;
    }

    _renderCall() {
        let ImageURL;
        if (this.state.callData.image_url === "" || typeof this.state.callData.image_url === undefined) {
            ImageURL = require('../../../assets/images/default.png');
        } else {
            ImageURL = this.state.callData.image_url;
        }
        if (this.state.onCall) {
            return (
                <div className="Call-Container">
                    <div className="Other-Profile">
                        <img src={ImageURL} alt="Call Image" className="Call-Image-Profile"></img>
                        <h5 style={{display: "inline"}}>{this.state.callData.caller_name}</h5>
                    </div>
                    <div className="Status-Container">
                        {this.state.timeStarting ? <span>{this.state.minutes < 10 && "0"}{this.state.minutes} : {this.state.seconds < 10 && "0"}{this.state.seconds}</span> : <span>{this.state.status}</span>}

                    </div>
                    <div className="Control-Container">
                        <div className="row" style={{width: "400px", margin: "0 auto"}}>
                            {this.state.hasVideo &&
                                <div className="col-4" onClick={this.pauseVideo.bind(this)}>
                                    <a href="#!" style={{cursor: "pointer"}}>
                                        <img src={require("./images/video.png")} style={{height: "45px"}}/>
                                    </a>
                                    <span>Close Camera</span>
                                </div>
                            }
                            {!this.state.hasVideo &&
                                <div className="col-4" onClick={this.unpauseVideo.bind(this)}>
                                    <a href="#!" style={{cursor: "pointer"}}>
                                        <img src={require("./images/video_off.png")} style={{height: "45px"}}/>
                                    </a>
                                    <span>Open Camera</span>
                                </div>
                            }

                            {this.state.mute &&
                                <div className="col-4" onClick={this.unmuteAudio.bind(this)}>
                                    <a href="#!" style={{cursor: "pointer"}}>
                                        <img src={require("./images/mic_mute.png")} style={{height: "45px"}}/>
                                    </a>
                                    <span>Unmute your microphone</span>
                                </div>
                            }
                            {!this.state.mute &&
                                <div className="col-4" onClick={this.muteAudio.bind(this)}>
                                    <a href="#!" style={{cursor: "pointer"}}>
                                        <img src={require("./images/mic.png")} style={{height: "45px"}}/>
                                    </a>
                                    <span>Mute your microphone</span>
                                </div>
                            }

                            <div className="col-4" onClick={this.disconnectCall.bind(this)}>
                                <a href="#!" style={{cursor: "pointer"}}>
                                    <img src={require("./images/end_call.png")} style={{height: "45px"}}/>
                                </a>
                                <span>End Call</span>
                            </div>
                        </div>
                    </div>
                    <div ref="remoteMedia" id="remote-media-div" className="Remote-Camera-Container"></div>
                    <div ref="localMedia" id="local-media" className="Camera-Container"></div>
                </div>
            );
        }

        return null;

    }

    render() {
        return (
            <div className="Video-Container">
                {this._renderError()}
                {this._renderCall()}
            </div>
        )
    }
}

const mapStateToProps = state => ({

})

export default connect(mapStateToProps, {
    declineCall,
    answerCall,
    requestCallToken,
    initiateCall,
    cancelCall
})(VideoCallContainer);
