import React, { Component, useEffect, useState } from 'react';
import Spinner from 'react-spinners/PulseLoader';
import Logo from '../../../assets/images/logo-blue.png';
import CallRingSound from './CallRingSound';
import ConferenceHelper from '../../../helper/conference';

const ConferenceInvite = ({ show, message = '', onEnd, onJoin }) => {

    const [isEngaged, setIsEngaged] = useState(false);

    useEffect(() => {
        if (show) {
            // Check if there is an active call
            setIsEngaged(ConferenceHelper.isEngaged());
        }
    }, [show]);
    
    return (<>
        <div className="loader"
            style={
                {
                    display: show ? "inline-grid" : "none"
                }
            }>
            <div align="center">
                <div className="loader-container">
                    <div>
                        <div>
                            <img
                                className='loader-logo'
                                src={Logo}
                                style={{
                                    width: "40px",
                                }}
                                alt="Solar Suite"
                            />
                            <span className="loader-brand">HUBCHART</span>
                        </div>
                        <div className="loader-message">
                            <Spinner
                                size={15}
                                color={"#2094f3"}
                                loading={show}
                            />
                            <span>{message}</span>
                        </div>
                        <div className="pt-3">
                            <button className="btn btn-danger btn-circle btn-lg mr-3" onClick={() => onEnd()}>
                                <i className="fas fa-phone" />
                            </button>
                            <button className="btn btn-success btn-circle btn-lg" onClick={() => onJoin()}>
                                <i className="fas fa-phone" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {show && !isEngaged ? <CallRingSound /> : ''}
    </>);
}

export default ConferenceInvite;
