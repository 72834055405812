import React, { useEffect, useState } from "react";

import "./index.css";

function ProcedureNotesComponent({ procedureNote = "" }) {
  return (
    <div className="custom-form-group" style={{ maxWidth: "250px" }}>
      <span htmlFor="procedureNotes">
        <strong>Procedure Notes</strong>
      </span>
      <div>
        <span>{procedureNote || ""}</span>
      </div>
    </div>
  );
}

export default ProcedureNotesComponent;
