import React, { Component } from "react";
import { connect } from "react-redux";
import CorpAcc from "../../../assets/images/subscription.png";
import "./CorporateSetting.css";

export class CorporateSubs extends Component {
  constructor() {
    super();
    this.state = {};
  }

  _renderLayout() {
    const subsService = [
      "Schedule telemed calls",
      "Record diagnoses",
      "Update problem list",
      "Record medical orders",
      "Create progress note",
    ];

    return (
      <div
        className="container"
        style={{ background: "#f8f9fa" }}
        id="corporateSub"
      >
        <div align="center" className="subs-header">
          <img width="250" height="150" src={CorpAcc} alt="H"></img>
          <h3>Corporate Account</h3>
          <p style={{ fontStyle: "italic" }}>
            Revolutionizing Patient-Centric Healthcare
          </p>
          <p>
            Hubchart Corporate plan brings together the power of telemedicine
            and team collaboration, revolutionizing the way you deliver
            healthcare. Take advantage of our subscription to unlock the full
            potential of collaborative care, streamline workflows, and enhance
            patient outcomes.
          </p>
        </div>
        <br />
        <div className="row">
          <div className="col-md" align="center" style={{ marginBottom: 20 }}>
            <div className="subs-container">
              <h3>Corp. Starter</h3>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h2>$ 9.99</h2>
                <p style={{ paddingTop: "15px" }}>/ month</p>
              </div>
              <button type="button" className="btn btn-subs">
                <span className="d-none d-sm-block d-sm-none d-md-block">
                  Get started
                </span>
              </button>
              <br />
              <hr />
              <ul className="ul-subs" align="left">
                <li>
                  <i className="fa fa-check"></i> Add up to 20 Corp. members and
                  guest
                </li>
                <li>
                  <i className="fa fa-check"></i> Create up to 1,000 patient
                  hubs
                </li>
                {subsService.map((item) => {
                  return (
                    <li>
                      <i className="fa fa-check"></i> {item}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="col-md" align="center" style={{ marginBottom: 20 }}>
            <div className="subs-container">
              <h3>Corp. Standard</h3>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h2>$ 49.99</h2>
                <p style={{ paddingTop: "15px" }}>/ month</p>
              </div>
              <button type="button" className="btn btn-subs">
                <span className="d-none d-sm-block d-sm-none d-md-block">
                  Get started
                </span>
              </button>
              <br />
              <hr />
              <ul className="ul-subs" align="left">
                <li>
                  <i className="fa fa-check"></i> Add up to 50 Corp. members and
                  guest
                </li>
                <li>
                  <i className="fa fa-check"></i> Create up to 3,000 patient
                  hubs
                </li>
                {subsService.map((item) => {
                  return (
                    <li>
                      <i className="fa fa-check"></i> {item}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="col-md" align="center" style={{ marginBottom: 20 }}>
            <div className="subs-container">
              <h3>Corp. Plus</h3>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h2>$ 99.99</h2>
                <p style={{ paddingTop: "15px" }}>/ month</p>
              </div>
              <button type="button" className="btn btn-subs">
                <span className="d-none d-sm-block d-sm-none d-md-block">
                  Get started
                </span>
              </button>
              <br />
              <hr />
              <ul className="ul-subs" align="left">
                <li>
                  <i className="fa fa-check"></i> Add up to 100 Corp. members
                  and guest
                </li>
                <li>
                  <i className="fa fa-check"></i> Create up to 5,000 patient
                  hubs
                </li>
                {subsService.map((item) => {
                  return (
                    <li>
                      <i className="fa fa-check"></i> {item}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div
        className="App-Row"
        style={{
          height: "100vh",
          background: "#f8f9fa",
          overflowY: "auto",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {this._renderLayout()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(CorporateSubs);
