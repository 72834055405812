import moment from 'moment';

const actions = [
  'Take',
  'Apply',
  'As directed',
  'Chew',
  'Dissolve',
  'Gargle & spit',
  'Inhale',
  'Nebulize',
  'Inject',
  'Insert',
  'Spray',
  'Swish & spit',
  'Swish & swallow',
  'Use',
  'Wash',
];

const dosage_forms = [
  'tablet',
  'capsule',
  'drop',
  'dose',
  'inhalation',
  'puffs',
  'spray',
  'units',
  'patch',
  'lozenge',
  'suppository',
  'application',
  'troche',
  'mg',
  'mg/kg',
  'ml',
  'ml/kg',
  'cartridge',
  'film',
  'packet',
  'pump',
  'ring',
  'scoop',
  'vial',
];

const quantifiables = [
  'tablet', 
  'capsule', 
  'patch', 
  'lozenge', 
  'suppository', 
  'film', 
  'packet', 
  'vial',
];

const routes = [
  { val: 'orally', text: 'orally' },
  { val: 'sublingual', text: 'sublingual' },
  { val: 'per nostril', text: 'nasal' },
  { val: 'topically', text: 'topically' },
  { val: 'transdermally', text: 'transdermally' },
  { val: 'subcutanously', text: 'subcutanously' },
  { val: 'intradermally', text: 'intradermally' },
  { val: 'intramuscularly', text: 'intramuscularly' },
  { val: 'intravenously', text: 'intravenously' },
  { val: 'intravaginally', text: 'intravaginally' },
  { val: 'rectally', text: 'rectally' },
  { val: 'buccally', text: 'buccally' },
  { val: 'by mouth', text: 'mouth, by' },
  { val: 'on the tongue', text: 'tongue,on the' },
  { val: 'on lips', text: 'lips, on' },
  { val: 'on both eyes', text: 'eye, both' },
  { val: 'on left eye', text: 'eye,left' },
  { val: 'on right eye', text: 'eye, right' },
  { val: 'on both eyelids', text: 'eyelids, both' },
  { val: 'on left eyelid', text: 'eyelid,left' },
  { val: 'on right eyelid', text: 'eyelid, right' },
  { val: 'into both ears', text: 'ears, both' },
  { val: 'into right ear', text: 'ear,into right' },
  { val: 'into left ear', text: 'ear, into left' },
  { val: 'per nostrils', text: 'nostrils, per' },
  { val: 'right nostril', text: 'nostril,right' },
  { val: 'left nostril', text: 'nostril,left' },
  { val: 'on affected area of skin', text: 'skin, affected area' },
  { val: 'externally', text: 'externally' },
  { val: 'intralesionally', text: 'intralesionally' },
  { val: 'by IV infusion', text: 'IV infusion (IVPB)' },
  { val: 'by slow IV push', text: 'IV push, slow' },
  { val: 'via nasogastric tube', text: 'nasogastric tube' },
  { val: 'via gastrostomy tube', text: 'gastrostomy tube' },
  { val: 'intraarticularly', text: 'intraarticularly' },
];

const locations = [
  { val: 'on affected area', text: 'on affected area' },
  { val: 'on scalp', text: 'scalp' },
  { val: 'on face', text: 'face' },
  { val: 'on forehead', text: 'forehead' },
  { val: 'around right periorbital', text: 'periorbital, right' },
  { val: 'around left periorbital', text: 'periorbital, left' },
  { val: 'on ear area', text: 'ear, external' },
  { val: 'on neck', text: 'neck' },
  { val: 'on anterior neck', text: 'neck, anterior' },
  { val: 'on posterior neck', text: 'neck, posterior' },
  { val: 'on anterior chest', text: 'anterior chest' },
  { val: 'on posterior chest', text: 'posterior chest' },
  { val: 'on right breast', text: 'breast, right' },
  { val: 'on left breast', text: 'breast, left' },
  { val: 'on right axilla', text: 'axilla, right' },
  { val: 'on left axilla', text: 'axilla, left' },
  { val: 'on right shoulder', text: 'shoulder, right' },
  { val: 'on left shoulder', text: 'shoulder, left ' },
  { val: 'on right upper arm', text: 'upper arm ,right' },
  { val: 'on left upper arm', text: 'upper arm,left' },
  { val: 'on right elbow', text: 'elbow, right' },
  { val: 'on left elbow', text: 'elbow, left' },
  { val: 'on right antecubital', text: 'antecubital, right' },
  { val: 'on left antecubital', text: 'antecubital, left' },
  { val: 'on right forearm', text: 'forarm,right' },
  { val: 'on left forearm', text: 'forearm,left' },
  { val: 'on right wrist', text: 'wrist, right' },
  { val: 'on left wrist', text: 'wrist, left' },
  { val: 'on right hand', text: 'hand, right' },
  { val: 'on left hand', text: 'hand, left' },
  { val: 'on finger', text: 'finger' },
  { val: 'on fingernail', text: 'fingernail' },
  { val: 'on upper back', text: 'back, upper' },
  { val: 'on lower back', text: 'back, lower' },
  { val: 'on buttock area', text: 'buttock' },
  { val: 'on right inguinal area', text: 'inguinal,right' },
  { val: 'on left inguinal area', text: 'inguinal, left ' },
  { val: 'on perineal area', text: 'perineal' },
  { val: 'on scrotal area', text: 'scrotal' },
  { val: 'on penile area', text: 'penile' },
  { val: 'on vaginal area', text: 'vaginal' },
  { val: 'around peri-anal area', text: 'peri-anal' },
  { val: 'around anal area', text: 'anal' },
  { val: 'on right hip', text: 'hip,right' },
  { val: 'on left hip', text: 'hip, left ' },
  { val: 'on right thigh', text: 'thigh ,right' },
  { val: 'on left thigh', text: 'thigh,left' },
  { val: 'on right knee', text: 'knee, right' },
  { val: 'on left knee', text: 'knee, left' },
  { val: 'on right popliteal', text: 'polpiteal, right' },
  { val: 'on left popliteal', text: 'popliteal, left' },
  { val: 'on right lower leg', text: 'lower leg,right' },
  { val: 'on left lower leg', text: 'lower leg,left' },
  { val: 'on right ankle', text: 'ankle, right' },
  { val: 'on left ankle', text: 'ankle, left' },
  { val: 'on right foot', text: 'foot, right' },
  { val: 'on left foot', text: 'foot, left' },
  { val: 'on right heel', text: 'heel, right' },
  { val: 'on left heel', text: 'heel, left' },
  { val: 'on plantar area of right foot', text: 'plantar,right' },
  { val: 'on plantar area of left foot', text: 'plantar,left' },
  { val: 'on toes', text: 'toes' },
  { val: 'on toenails', text: 'toe nails' },
  { val: 'in between toes', text: 'between toes' },
];

const frequencies = [
  { val: 1, text: 'daily' }, 
  { val: 2, text: '2 x per day' },
  { val: 3, text: '3 x per day' },
  { val: 4, text: '4 x per day' },
  { val: 2, text: 'q 12 hrs' },
  { val: 3, text: 'q 8 hrs' },
  { val: 4, text: 'q 6 hrs' },
  { val: 6, text: 'q 4 hrs' },
  { val: 12, text: 'q 2 hrs' },
  { val: 1, text: 'q AM' },
  { val: 1, text: 'q HS' },
  { val: 1, text: '1 time dose' },
  { val: 1, text: 'once a week' },
  { val: 2, text: '2 x / week' },
  { val: 3, text: '3 x / week' },
];

const duration_strings = [
  { val: 1, text: 'Day' },
  { val: 1, text: 'Days' },
  { val: 7, text: 'Week' },
  { val: 7, text: 'Weeks' },
  { val: 30, text: 'Month' },
  { val: 30, text: 'Months' },
];

const strengths = [
  'MG',
  'MCG',
  'G',
  'IU',
];

const defaultSelectedDrug = {
  displayName: '',
  direction: [],
  amount: 1,
  dosage_form: [],
  route: [],
  route_obj: [],
  location: [],
  location_obj: [],
  frequency: [],
  frequency_obj: [],
  duration: '',
  durationStr: [],
  duration_obj: [],
  quantity: '',
  refill: '',
  prn: false,
  prn_reason: '',
  additionalDirections: '',
  dateCreated: moment(new Date()).format('MM/DD/YYYY'),
};

export {
  actions,
  dosage_forms,
  quantifiables,
  routes,
  locations,
  frequencies,
  duration_strings,
  strengths,
  defaultSelectedDrug,
};