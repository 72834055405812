import React, { Component, useEffect, useMemo, useRef, useState } from "react";
import OverlayLoader from "../../Common/OverlayLoader";
import ImagePlaceholder from "../../Common/ImagePlaceholder";

const ImageLoad = ({ openLightbox, imageURL, iconSize }) => {
  const [width, setWidth] = useState("75px");
  const [height, setHeight] = useState("75px");
  const [hasLoaded, setHasLoaded] = useState(false);
  const [errLoading, setErrLoading] = useState(false);

  const styles = useMemo(() => {
    return {
      background: "gray",
      display: "block",
      cursor: "pointer",
      // marginBottom: "15px",
      width,
      height
    };
  }, [width, height]);

  const handleImageLoad = () => {
    setHasLoaded(true);
    setWidth("75px");
    setHeight("75px");
  };

  const handleImageError = () => {
    setHasLoaded(true);
    setErrLoading(true);
  };

  return (
    <div style={{ position: "relative" }}>
      <img
        onLoad={e => handleImageLoad()}
        onError={e => handleImageError()}
        className="image-lazy-fade-in"
        onClick={openLightbox}
        style={styles}
        src={imageURL}
        alt="Photo"
      />
      <OverlayLoader
        background="lightgray"
        color="white"
        isLoading={!hasLoaded}
      />
      <Placeholder show={errLoading} iconSize={iconSize} />
    </div>
  );
};

const Placeholder = ({ show, iconSize = "2x" }) => {
  const styles = {
    position: "absolute",
    top: "0",
    left: "0",
    backgroundColor: "white",
    width: "75px",
    height: "100%",
    display: show ? "flex" : "none",
    justifyContent: "center",
    alignItems: "center"
  };

  return (
    <div style={styles}>
      <ImagePlaceholder type="image" iconSize={iconSize} />
    </div>
  );
};

export default ImageLoad;
