import React from 'react';
import Member from './Member';

export default function MemberList(props) {

    const {
        members,
        admins,
        owner,
        isLoading,
        isAdmin,
        isCreator,
        guests,
        isCorporate,
    } = props;

    const Members = members.map((data) => {
        return <Member
            onClick={props.onClick}
            key={data.objectId}
            member={data}
            isCreator={isCreator && !isCorporate}
            isAdmin={isAdmin && !isCorporate}
            removeMember={props.onRemoveMember}
            assignAdmin={props.onAssignAdmin}
            removeAdmin={props.onRemoveAdmin} />
    });

    const Admins = admins.map((data) => {
        return <Member
            onClick={props.onClick}
            key={data.objectId}
            member={data}
            isCreator={isCreator && !isCorporate}
            isAdmin={isAdmin && !isCorporate}
            removeMember={props.onRemoveMember}
            assignAdmin={props.onAssignAdmin}
            removeAdmin={props.onRemoveAdmin} />
    });

    const Guests = guests.map((data) => {
        return <Member
            isGuest={true}
            onClick={props.onClick}
            key={data.objectId}
            member={data}
            isCreator={false}
            isAdmin={isAdmin}
            removeMember={props.onRemoveGuest}
            assignAdmin={props.onAssignAdmin}
            removeAdmin={props.onRemoveAdmin} />
    });

    const Owner = <Member
        onClick={props.onClick}
        key={owner.objectId}
        member={owner}
        isCreator={isCreator}
        isAdmin={false}
        removeMember={props.onRemoveMember}
        assignAdmin={props.onAssignAdmin}
        removeAdmin={props.onRemoveAdmin} />;

    return (
        <div className="accordion" id="accordionExample">

            {/* Corporate account admins */}
            <div className="mycard-1" style={{ border: "0" }}>
                <div className="card-header" id="headingOne" style={{ background: "white", border: "0", padding: "0" }}>
                    <p className="hc-primary-text mb-0" style={{ position: "relative", cursor: "pointer" }} data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                        <i className="fas fa-chevron-down mr-1" id="collapsed-chevron"></i>
                        <span className="mr-1">
                            Super Admin
                        </span>
                    </p>
                </div>

                {owner.objectId ?
                    <div id="collapseThree" className="collapse show mt-2" aria-labelledby="headingOne">
                        <div className="card-body" style={{ padding: "0" }}>
                            <div className="list-group">
                                {Owner}
                            </div>
                        </div>
                    </div>
                    : ''
                }
            </div>

            <hr className="m-0"></hr>

            {/* Admin Regular Group Members + Corporate members */}
            <div className="mycard-1" style={{ border: "0" }}>
                <div className="card-header" id="headingOne" style={{ background: "white", border: "0", padding: "0" }}>
                    <p className="hc-primary-text mb-0" style={{ position: "relative", cursor: "pointer" }} data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                        <i className="fas fa-chevron-down mr-1" id="collapsed-chevron"></i>
                        <span className="mr-1">
                            Admins
                        </span>
                        {!isLoading ?
                            <span className="badge badge-pill badge-primary">
                                {Admins.length}
                            </span>
                            : ''
                        }
                    </p>
                </div>

                {Admins.length ?
                    <div id="collapseTwo" className="collapse show mt-2" aria-labelledby="headingOne">
                        <div className="card-body" style={{ padding: "0" }}>
                            <div className="list-group">
                                {Admins}
                            </div>
                        </div>
                    </div>
                    : ''
                }
            </div>

            <hr className="m-0"></hr>

            {/* Non admin Regular Group Members + Corporate members */}
            <div className="mycard-1" style={{ border: "0" }}>
                <div className="card-header" id="headingOne" style={{ background: "white", border: "0", padding: "0" }}>
                    <p className="hc-primary-text mb-0" style={{ position: "relative", cursor: "pointer" }} data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <i className="fas fa-chevron-down mr-1" id="collapsed-chevron"></i>
                        <span className="mr-1">
                            Members
                        </span>
                        {!isLoading ?
                            <span className="badge badge-pill badge-primary">
                                {Members.length}
                            </span>
                            : ''
                        }
                    </p>
                </div>

                {Members.length ?
                    <div id="collapseOne" className="collapse show mt-2" aria-labelledby="headingOne">
                        <div className="card-body" style={{ padding: "0" }}>
                            <div className="list-group">
                                {Members}
                            </div>
                        </div>
                    </div>
                    : ''
                }
            </div>

            {/* Guests only */}
            {isCorporate ?
                <div className="mycard-1" style={{ border: "0" }}>
                    <div className="card-header" id="headingOne" style={{ background: "white", border: "0", padding: "0" }}>
                        <p className="hc-primary-text mb-0" style={{ position: "relative", cursor: "pointer" }} data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseOne">
                            <i className="fas fa-chevron-down mr-1" id="collapsed-chevron"></i>
                            <span className="mr-1">
                                Guests
                            </span>
                            {!isLoading ?
                                <span className="badge badge-pill badge-primary">
                                    {Guests.length}
                                </span>
                                : ''
                            }
                        </p>
                    </div>

                    {Guests.length ?
                        <div id="collapseFour" className="collapse show mt-2" aria-labelledby="headingOne">
                            <div className="card-body" style={{ padding: "0" }}>
                                <div className="list-group">
                                    {Guests}
                                </div>
                            </div>
                        </div>
                        : ''
                    }
                </div>
                : ''
            }
        </div>
    );
}