import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import Notify from './Notify';

const Controls = ({
  videoShared,
  onToggleVideo,
  audioShared,
  onToggleAudio,
  screenShared,
  onToggleScreen,
  onEnd,
  disabled,
  layout,
  setLayout,
  videoDisabled,
  onInviteGuest,
  screenDisabled,
  onInviteUser,
  onShowParticipants,
  isGuest,
}) => {

  return (
    <div className="controls-container row m-0">

      <div className="col-lg-4 col-md-6 justify-content-center d-none d-md-flex">
        <LayoutToggle layout={layout} setLayout={setLayout} />
        <InviteGuestButton onInviteGuest={onInviteGuest} disabled={isGuest || disabled} />
        <InviteUserButton onInviteUser={onInviteUser} disabled={isGuest || disabled} />
        <ParticipantsButton onShowParticipants={onShowParticipants} disabled={isGuest || disabled} />
      </div>

      <div className="col-12 col-lg-4 col-md-6 d-flex justify-content-center align-items-center">
        <span className="d-none d-md-block">
          <ScreenShareToggle
            screenShared={screenShared}
            onToggle={onToggleScreen}
            disabled={disabled || screenDisabled}
          />
        </span>
        <div className="d-flex d-md-none mr-3">
          <MobileDropdown
            layout={layout} setLayout={setLayout}
            onInviteGuest={onInviteGuest}
            onInviteUser={onInviteUser}
            inviteDisabled={isGuest || disabled}
            disabled={disabled}
           />
        </div>
        <CameraToggle
          videoShared={videoShared}
          onToggle={onToggleVideo}
          disabled={disabled || videoDisabled} />
        <MicToggle
          audioShared={audioShared}
          onToggle={onToggleAudio}
          disabled={disabled} />
        <EndButton onEnd={onEnd} />
      </div>

      <ReactTooltip effect="solid" type="info" />
    </div>
  );
};

const CameraToggle = ({ videoShared, onToggle, disabled }) => {

  const btnClass = videoShared ? 'btn-primary hc-bg-primary' : 'btn-light';

  return (
    <button
      data-tip={videoShared ? 'Turn off camera' : 'Turn on camera'}
      disabled={disabled}
      className={`btn ${btnClass} btn-circle mr-3`}
      onClick={(e) => onToggle()}>
      <i className={videoShared ? "fas fa-video" : "fas fa-video-slash text-muted"} />
    </button>
  );
}

const MicToggle = ({ audioShared, onToggle, disabled }) => {

  const btnClass = audioShared ? 'btn-primary hc-bg-primary' : 'btn-light';

  return (
    <button
      data-tip={audioShared ? 'Turn off mic' : 'Turn on mic'}
      disabled={disabled}
      className={`btn ${btnClass} btn-circle mr-3`}
      onClick={(e) => onToggle()}>
      <i className={audioShared ? "fas fa-microphone" : "fas fa-microphone-slash text-muted"} />
    </button>
  );
}

const ScreenShareToggle = ({ screenShared, onToggle, disabled }) => {

  const btnClass = screenShared ? 'btn-primary hc-bg-primary' : 'btn-light';

  return (
    <button
      data-tip={screenShared ? 'Stop screen sharing' : 'Share your screen'}
      disabled={disabled}
      className={`btn ${btnClass} btn-circle mr-3`}
      onClick={(e) => onToggle()}>
      <i className={screenShared ? "fas fa-phone-laptop" : "fas fa-phone-laptop text-muted"} />
    </button>
  );
}

const LayoutToggle = ({ layout, setLayout }) => {

  const [iconClass, setIconClass] = useState('');

  useEffect(() => {
    switch (layout) {
      case 'tile': return setIconClass('fas fa-th');
      case 'speaker': return setIconClass('fas fa-users-class');
      default:
    }
  }, [layout])

  return (
    <div className="dropdown">
      <button
        data-tip="Change layout"
        className="btn btn-link d-flex flex-column justify-content-center align-items-center hc-primary-text"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false">
        <i className={iconClass} />
        <small style={{fontSize: '12px'}}>Layout</small>
      </button>
      <div className="dropdown-menu dropdown-menu-right">
        <a onClick={(e) => setLayout('tile')} className="dropdown-item" href="#!">
          <i className="fas fa-th mr-1"></i>
          Tile layout
        </a>
        <a onClick={(e) => setLayout('speaker')} className="dropdown-item" href="#!">
          <i className="fas fa-users-class mr-1"></i>
          Speaker layout
        </a>
      </div>
    </div>
  );
}

const EndButton = ({ onEnd }) => {
  return (
    <button data-tip="End call" onClick={(e) => onEnd()} className="btn btn-danger btn-circle">
      <i className="fas fa-phone-slash" />
    </button>
  );
}

const InviteGuestButton = ({ onInviteGuest, disabled }) => {
  return (
    <button disabled={disabled} onClick={onInviteGuest} className="btn btn-link d-flex flex-column justify-content-center align-items-center hc-primary-text">
      <i className="fas fa-link" />
      <small style={{fontSize: '12px'}}>Copy invite link</small>
    </button>
  );
}

const InviteUserButton = ({ onInviteUser, disabled }) => {
  return (
    <button disabled={disabled} data-tip="Invite Hubchart user" onClick={onInviteUser} className="btn btn-link d-flex flex-column justify-content-center align-items-center hc-primary-text">
      <i className="fas fa-user-plus" />
      <small style={{fontSize: '12px'}}>Invite contacts</small>
    </button>
  );
}

const ParticipantsButton = ({ onShowParticipants, disabled }) => {
  return (
    <button disabled={disabled} data-tip="Invite Hubchart user" onClick={onShowParticipants} className="btn btn-link d-flex flex-column justify-content-center align-items-center hc-primary-text">
      <i className="fas fa-users" />
      <small style={{fontSize: '12px'}}>Participants</small>
    </button>
  );
}

const MobileDropdown = ({
  layout,
  setLayout,
  onInviteGuest,
  onInviteUser,
  inviteDisabled,
  disabled,
}) => {
  return (
    <div className="dropdown">
      <button
        disabled={disabled}
        className="btn btn-circle btn-outline-primary"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false">
        <i className="fas fa-fw fa-bars" />
      </button>
      <div className="dropdown-menu dropdown-menu-left">
        { layout === 'speaker' ?
          <a onClick={(e) => setLayout('tile')} className="dropdown-item" href="#!">
            <i className="fas fa-th mr-1"></i>
            Tile Layout
          </a> : ''
        }
        { layout === 'tile' ?
          <a onClick={(e) => setLayout('speaker')} className="dropdown-item" href="#!">
            <i className="fas fa-users-class mr-1"></i>
            Speaker Layout
          </a> : ''
        }
        <a disabled={inviteDisabled} onClick={onInviteGuest} className="dropdown-item" href="#!">
          <i className="fas fa-link mr-1"></i>
          Copy invite link
        </a>
        <a disabled={inviteDisabled} onClick={onInviteUser} className="dropdown-item" href="#!">
          <i className="fas fa-user-plus mr-1"></i>
          Invite contacts
        </a>
      </div>
    </div>
  );
}

export default Controls;
