import React, { useEffect, useMemo, useRef, useState } from 'react';
import Modal from '../../Common/Modal'
import OverlayLoader from '../../Common/OverlayLoader'
import Member from './Member';

const UrgentModal = ({ isOpen, onClose, options=[], onSubmit, guests=[] }) => {

  const inputRef = useRef();

  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [searched, setSearched] = useState([]);
  const [searchedGuests, setSearchedGuests] = useState([]);

  const selectedIds = useMemo(() => {
    return selected.map(user => user.objectId);
  }, [selected]);

  const handleSelect = user => {
    setSelected(selected => [
      ...selected,
      user
    ]);
  };

  const handleRemove = objectId => {
    setSelected(selected =>
      selected.filter(user => user.objectId != objectId)
    );
  };

  const handleSubmit = () => {
    onSubmit(selected);
  }

  useEffect(() => {
    setSelected([]);
  }, [options]);

  useEffect(() => {
    const searched = options.filter(
      user => (user.firstNameLower || '').startsWith(searchKey.toLowerCase()) ||
        (user.lastNameLower || '').startsWith(searchKey.toLowerCase())
    );
    setSearched(searched);
  }, [searchKey, options]);

  useEffect(() => {
    const searched = guests.filter(
      user => (user.firstNameLower || '').startsWith(searchKey.toLowerCase()) ||
        (user.lastNameLower || '').startsWith(searchKey.toLowerCase())
    );
    setSearchedGuests(searched);
  }, [searchKey, guests]);

  useEffect(() => {
    if (!isOpen) return;
    inputRef.current.focus()
  }, [isOpen])

  const list = useMemo(() => {
    return searchKey ? searched : options;
  }, [options, searched, searchKey]);

  const listGuest = useMemo(() => {
    return searchKey ? searchedGuests : guests;
  }, [guests, searchedGuests, searchKey]);

  return (
    <Modal shown={isOpen} onHide={onClose}>

      <ModalHeader />

      <ModalBody>
        <div className="Tab-Search-Container m-0">
          <i className="fas fa-search"></i>
          <form autocomplete="off" onSubmit={() => { }}>
            <input
              ref={inputRef}
              onChange={e => setSearchKey(e.target.value)}
              placeholder="Search Members"
              value={searchKey}
              type="search"
              id="search"
            />
          </form>
        </div>
      </ModalBody>
      <ModalBody style={{ height: '100%', overflowY: 'auto', padding: '0' }}>
        <div className="card-body px-3" style={{ padding: "0" }}>
          <label className="text-primary">Members</label>
          {list.map(user =>
            <Member
              isSelected={selectedIds.includes(user.objectId)}
              onSelect={user => handleSelect(user)}
              onRemove={objectId => handleRemove(objectId)}
              user={user}
            />
          )}
          {guests.length ?
            <>
              <hr/>
              <label className="text-primary">Guests</label>
              {listGuest.map(user =>
                <Member
                  isSelected={selectedIds.includes(user.objectId)}
                  onSelect={user => handleSelect(user)}
                  onRemove={objectId => handleRemove(objectId)}
                  user={user}
                />
              )}
            </>
            : ''
          }
        </div>
        <OverlayLoader isLoading={loading} />
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-primary" disabled={!selected.length} onClick={handleSubmit}>
          <i className="fas fa-check mr-1"></i>
          Done
        </button>
      </ModalFooter>
    </Modal>
  )
}

const ModalHeader = () => {
  return (
    <div className="modal-header">
      <h5 className="text-danger mb-0" style={{ fontSize: "1.25rem" }}>
        <i className="fas fa-exclamation-triangle mr-1"></i>
        Send urgent to
      </h5>
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  )
}

const ModalBody = ({ children, style = {} }) => {

  const styles = {
    ...style
  }

  return (
    <div className="modal-body" style={styles}>
      {children}
    </div>
  )
}

const ModalFooter = ({ children }) => {
  return (
    <div className="modal-footer">
      {children}
    </div>
  )
}

export default UrgentModal;
