import React, { Component } from 'react'
import moment from "moment"

export class DocumentLogItem extends Component {
    constructor() {
		super();
		this.state = {
			log : "",
			group : ""
		}
		this.preventDefault = this.preventDefault.bind(this);
	}

	componentWillMount() {
		this.setState({
			log : this.props.log,
			group : this.props.group
		})
	}

	preventDefault(e) {
		e.preventDefault();
	}

	render() {
		let liStyle = {
			border: "0px solid white",
		 	paddingLeft: "72px",
		 	heigth: "100%",
		 	borderBottom: "1px solid #0000003b",
		 	marginBottom: "1px"
		}
		const log = this.state.log;
		let fullName,
			userImageURL,
			forwardedMessages = [],
			serviceDate;

		if (typeof log.user != "undefined") {
			fullName = log.user.firstName + " " + log.user.lastName;
			if (typeof log.user.picture != "undefined") {
				userImageURL = log.user.picture.url;
			} else {
				userImageURL = "../images/default.png";
			}
		}

		if (typeof log.forwardedMessages != "undefined") {
			forwardedMessages = log.forwardedMessages.map((data, i) => {
				return <p key={i} className="ccm-forwarded-messages" style={{marginBottom: "0px"}}>{data}</p>
			});
		}


		if (typeof log.ccmData.serviceMonth != "undefined" && typeof log.ccmData.serviceYear != "undefined") {
			if (log.ccmData.serviceMonth.toString() != "0" && log.ccmData.serviceYear.toString() != "0") {
				serviceDate = moment().month(log.ccmData.serviceMonth - 1).format("MMMM") + ", " +moment().year(log.ccmData.serviceYear).format("YYYY");
			}
		}


		return (
			<a href="#" onClick={this.preventDefault} className="list-group-item list-group-item-action flex-column align-items-start" style={liStyle}>
				<img src={userImageURL} alt="" className="profile-image rounded-circle" />
                <p className="ccm-text" style={{color: "#000000ba"}}>{fullName}</p>
                <p className="ccm-text" style={{color: "#000000a3", fontSize: "13px"}}>{moment(log.createdAt).format("MMMM D, YYYY")}</p>
                {
                	typeof log.text != "undefined" &&
                	<span>
                		<br />
                		<p className="ccm-text">{log.text}</p>
                	</span>
                }

                {	forwardedMessages.length >= 1 &&
                	<span>
                		<br />
                		<p className="name">Forward Message</p>
                		{forwardedMessages}
                	</span>
            	}

            	<br />
            	<table style={{fontSize: "13px", color: "#000000a1"}}>
            		<tbody>
					<tr>
					    <td>Date fo encounter</td>
					    <td></td>
					</tr>
					<tr>
					    <td>Staff CCM Time supervised by clincician:</td>
					    <td style={{width: "50px"}}></td>
					    <td>{log.ccmData.staffMinutes+ " mins"}</td>
					</tr>
					<tr>
					    <td>Cliniccian CCM Time</td>
					    <td style={{width: "50px"}}></td>
					    <td>{log.ccmData.clinicianMinutes+ " mins"}</td>
					</tr>
					<tr>
					    <td>Supervising Clincician</td>
					    <td style={{width: "50px"}}></td>
					    <td>{log.ccmData.staffMinutes+ " mins"}</td>
					</tr>
					<tr>
					    <td>CCM Service Month</td>
					    <td style={{width: "50px"}}></td>
					    <td>{serviceDate}</td>
					</tr>
					<tr>
					    <td style={{fontWeight: "bold", color: "#000000e3"}}>Total CCM time this episode</td>
					    <td style={{width: "50px"}}></td>
					    <td>{log.ccmData.billableMinutes+ " mins"}</td>
					</tr>
					</tbody>
				</table>

            </a>
		);
	}
}

export default DocumentLogItem
