import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { parseAction } from "../../../../actions/REST";
import moment from "moment";
import {
  getPatientOrders,
  deleteOrder,
  createNewOrderHistory,
  checkUncheckPatientOrder
} from "../../../../actions/PatientAction";
import Loader from "../../../Common/Loader";
import Notify from "../../../Common/Notify";
import config from "../../../../config";
import { computeAge } from "../../../../helper/util";
import OrdersModal from "../../../Pages/ProgressNotes/OrdersModal";
import { ToastContainer } from "react-toastify";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "../../../Pages/Login/Login.css";
import "../../../Conversation/Setting/SettingModal.css";
import PatientInfo from "../PatientInfo";
import Logo from "../../../../assets/images/logo-blue.png";

const $ = window.$;

function PatientOrdersModal({
  isOpen = false,
  threadId = "",
  onClose = () => {},
  groupMembers = [],
  tempMembers = [],
  guestMembers = []
}) {
  const defaultOrder = {
    prescription: "",
    diagnosticStudies: "",
    referrals: "",
    medicalSupplies: ""
  };
  const dispatch = useDispatch();
  const [patientInfo, setPatientInfo] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(defaultOrder);
  const [ordersModalShown, setOrdersModalShown] = useState(false);

  const { patientOrders, isOrdersLoading } = useSelector(
    state => ({
      patientOrders: state.patient.patientOrders,
      isOrdersLoading: state.patient.isOrdersLoading
    }),
    shallowEqual
  );

  function fetchPatientInfo(id) {
    parseAction("get", config.BASE_URL + "/parse/classes/Circle", {
      where: {
        objectId: id
      }
    })
      .then(result => {
        if (result.results.length === 0) {
          return;
        }

        var data = result.results[0];
        const order_list = (data.orders || []).map(item => ({
          ...item,
          onsetDate: new Date(item.onsetDate.iso)
        }));
        setPatientInfo(data);
        getOrders(threadId);
      })
      .catch(error => {
        console.log(error);
      });
  }

  function getOrders(id) {
    getPatientOrders(id)(dispatch);
  }

  function handleCreateOrderHistory() {
    const selOrder = patientOrders.filter(item => item.isChecked);
    if (selOrder.length === 1) {
      createNewOrderHistory(
        selOrder[0],
        threadId,
        patientInfo.objectId,
        res => {
          Notify.success("Order successfully generated.");
          window.open(
            `${window.location.origin}/orders-history/${threadId}/${res.objectId}`,
            "_blank"
          );
        }
      )(dispatch);
    } else {
      Notify.info("Please select one order to generate.");
    }
  }

  function handleCreateOrder() {
    setSelectedOrder(defaultOrder);
    handleOrderModalShown(true);
  }

  function handleUpdateOrder(item) {
    setSelectedOrder(item);
    setOrdersModalShown(true);
  }

  function removeItem(item, patientId) {
    deleteOrder(item.objectId, patientId, () => {
      Notify.success("Order successfully deleted.");
    })(dispatch);
  }

  function onInputCheckedChange(e, idx) {
    const { checked } = e.target;
    checkUncheckPatientOrder(patientOrders, checked, idx)(dispatch);
  }

  function handleOrderModalShown(bool) {
    setOrdersModalShown(bool);
  }

  useEffect(() => {
    if (threadId) {
      componentDidMount();
    }
  }, [threadId]);

  function componentDidMount() {
    fetchPatientInfo(threadId);
  }

  return (
    <div className="progress-notes-container pn-con componentInnerContainer">
      <div className="container mb-15 pn-title-flex">
        <span className="navbar-brand">
          <img
            className="d-sm-inline"
            width="40"
            height="40"
            src={Logo}
            alt="H"
          ></img>
          <span
            className="hc-primary-text d-none d-md-inline"
            style={{ fontWeight: "500" }}
          >
            HUBCHART
          </span>
        </span>
        <div className="pn-patient-details-flex">
          <div>
            <span>{patientInfo.name || "--"}</span>
            <span>ORDERS</span>
          </div>
          {(typeof (patientInfo.image || {}).url === "undefined" ||
            (patientInfo.image || {}).url === "") && (
            <img src={require("../../../../assets/images/default.png")} />
          )}
          {(patientInfo.image || {}).url && (
            <img src={(patientInfo.image || {}).url} />
          )}
        </div>
        {/* <span className="pn-title">PATIENT ORDERS</span> */}
      </div>
      <div className="pn-template-container">
        <div className="container pb-10">
          <PatientInfo patientInfo={patientInfo} />
        </div>
      </div>
      <div class="container">
        <div className="filter-view" style={{ margin: "15px 0px" }}>
          <div className="search-flex search-identifier">
            <div />
            <button
              type="button"
              className="btn btn-hubchart btn-sm btn-hubchart-primary jiro"
              onClick={() => handleCreateOrder()}
              style={{ margin: "0px", height: "33px", width: "170px" }}
            >
              <i className="fas fa-plus"></i> Add
            </button>
          </div>
        </div>
        <div className="orders" style={{ overflowX: "auto" }}>
          {patientOrders.length > 0 && (
            <div className="setting-modal-headers">
              <div />
              <span>Orders</span>
              <span>Date Entered</span>
              <span>Action</span>
            </div>
          )}
          <div className="setting-modal-item-container">
            {patientOrders.map((item, i) => (
              <div
                className="setting-modal-item"
                key={`po-${moment(new Date()).format("lll")}-${i}`}
              >
                {/* <div>
                          <input 
                            type="checkbox" 
                            id="isChecked"
                            name="isChecked"
                            checked={item.isChecked}
                            onChange={e => onInputCheckedChange(e, i)} />
                        </div> */}
                <div
                  onClick={() =>
                    onInputCheckedChange(
                      { target: { checked: !item.isChecked } },
                      i
                    )
                  }
                >
                  <div class="round-small">
                    <input
                      type="checkbox"
                      id="checkbox"
                      id="isChecked"
                      name="isChecked"
                      checked={item.isChecked}
                    />
                    <label for="checkbox"></label>
                  </div>
                </div>
                <div
                  onClick={() =>
                    onInputCheckedChange(
                      { target: { checked: !item.isChecked } },
                      i
                    )
                  }
                >
                  {item.prescription && (
                    <Fragment>
                      <strong>Prescription</strong>
                      <div
                        style={{
                          margin: "0px 10px",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        {item.prescription}
                      </div>
                      <br />
                    </Fragment>
                  )}
                  {item.diagnosticStudies && (
                    <Fragment>
                      <strong>Diagnostic Studies</strong>
                      <div style={{ margin: "0px 10px" }}>
                        <span>{item.diagnosticStudies}</span>
                      </div>
                      <br />
                    </Fragment>
                  )}
                  {item.referrals && (
                    <Fragment>
                      <strong>Referrals</strong>
                      <div style={{ margin: "0px 10px" }}>
                        <span>{item.referrals}</span>
                      </div>
                      <br />
                    </Fragment>
                  )}
                  {item.medicalSupplies && (
                    <Fragment>
                      <strong>Medical supplies</strong>
                      <div style={{ margin: "0px 10px" }}>
                        <span>{item.medicalSupplies}</span>
                      </div>
                      <br />
                    </Fragment>
                  )}
                </div>
                <span
                  onClick={() =>
                    onInputCheckedChange(
                      { target: { checked: !item.isChecked } },
                      i
                    )
                  }
                >
                  <span>{moment(item.createdAt).format("lll")}</span>
                </span>
                <span>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    title="Remove Order"
                    onClick={() => handleUpdateOrder(item)}
                  >
                    <i className="fas fa-edit"></i>
                  </button>
                  &nbsp;
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    title="Remove Order"
                    style={{ width: "33.75px" }}
                    onClick={() => removeItem(item, threadId)}
                  >
                    <i className="fas fa-trash"></i>
                  </button>
                </span>
              </div>
            ))}
          </div>
        </div>
        {patientOrders.length === 0 && (
          <div className="default-view">
            <span>No Orders Found.</span>
          </div>
        )}
        <div className="medicationButtons">
          <div className="container">
            <div className="medicationButtonsFlex">
              <div />
              <button
                type="button"
                className="btn btn-hubchart btn-hubchart-primary"
                onClick={() => handleCreateOrderHistory()}
              >
                Create New Order
              </button>
            </div>
          </div>
        </div>
        <Loader isLoading={isOrdersLoading} />
        {ordersModalShown && (
          <OrdersModal
            isOpen={ordersModalShown}
            title="Orders"
            thread={{ ...patientInfo, threadId: patientInfo.objectId }}
            patientId={patientInfo.objectId}
            onClose={() => handleOrderModalShown(false)}
            postOnConversation={{}}
            isPatient={true}
            patientName={""}
            patientDob={""}
            guests={guestMembers}
            options={groupMembers}
            toList={true}
            appendOrders={() => {}}
            selectedOrder={selectedOrder}
            isFromPatientOrders={true}
          />
        )}
        <ToastContainer />
      </div>
    </div>
  );
}

export default PatientOrdersModal;
