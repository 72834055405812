import React, { Component } from 'react'
import { parseAction } from '../../../actions/REST'
import '../../Pages/Login/Login.css'
import Loading from '../../Pages/Signup/Loading'
import { toast } from 'react-toastify';
import config from '../../../config'

const $ = window.$;

export class InviteContact extends Component {

    constructor() {
        super();
        this.state = {
            isLoading : false,
            createGroupState : "none"
        }
        this.createGroup = this.createGroup.bind(this);
    }

    componentDidMount() {
        if (this.props.isOpen) {
            $("#inviteContactModal").modal({
                backdrop : 'static',
                keyboard : false
            });
            $("#inviteContactModal").modal('show');
        } else {
            $("#inviteContactModal").modal('hide');
        }
        $("#inviteContactModal").on('hidden.bs.modal', function (e) {
            this.props.modalClose();
        }.bind(this))
    }

    confirm() {
        this.props.confirmAction();
        $("#inviteContactModal").modal('hide');
    }

    UUID() {
        let d = new Date().getTime();
        let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            let r = (d + Math.random()*16)%16 | 0;
            d = Math.floor(d/16);
            return (c ==='x' ? r : (r&0x3|0x8)).toString(16).toUpperCase();
        });
        return uuid;
    }

    createGroup(e) {
        e.preventDefault();
        let groupName = this.refs.groupName.value,
            uuid = this.UUID();

        this.setState({
            isLoading : true,
            createGroupState : "none"
        })

        let self = this;
        parseAction("post", config.BASE_URL + '/parse/functions/sendInvitationEmail',
        {
            email: groupName
        })
        .then((res) => {
            this.notifySuccess('Invitation sent!');
            $("#inviteContactModal").modal('hide');
        })
        .catch((error) => {
            self.setState({
                isLoading : false,
                createGroupState : "ERROR"
            });
            this.notifyError('Failed to send invitation.');
        })
        /* setTimeout(() => {
            $("#inviteContactModal").modal('hide');
        }, 2000); */
    }

    notifySuccess(message) {
        const options = {
            autoClose: 3000,
            closeButton: false,
            hideProgressBar: true,
        };

        toast.success(message, options);
    }

    notifyError(message) {
        const options = {
            autoClose: 3000,
            closeButton: false,
            hideProgressBar: true,
        };

        toast.error(message, options);
    }

    render() {
        return (
            <div id="inviteContactModal" className="modal" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-body" style={{paddingBottom: "0px"}}>
                        <h5 className="hc-primary-text" style={{fontSize: "1.25rem"}}>Invite Contact</h5>
                        {/* <p style={{fontSize: "0.9em"}}>Message here</p> */}
                        <div className="Form-Container" style={{margin: "20px 0", maxWidth: "100%"}}>
                            <form onSubmit={this.createGroup}>
                                <div className="form-group">
                                    <div className="card">
                                        <input ref="groupName" type="email" id="group" placeholder="Email Address" required/>
                                        <i className="fas fa-at hc-primary-text"></i>
                                    </div>
                                </div>
                                { !this.state.isLoading ?
                                    <div className="form-group" style={{textAlign: "right"}}>
                                        <button type="submit" style={{marginRight: "15px"}} className="btn btn-hubchart btn-hubchart-primary">Invite</button>
                                        <button type="button" className="btn btn-hubchart btn-hubchart-close" data-dismiss="modal">Close</button>
                                    </div>
                                    :
                                    <div className="form-group" style={{textAlign: "right", position: "relative", height: "40px"}}>
                                        <Loading />
                                    </div>
                                }
                            </form>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default InviteContact
