import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import SearchItem from './SearchItem';
import SearchInput from './SearchInput';
import TabLoader from '../../../../Common/TabLoader';
import { searchContactByEmail } from '../../../../../actions/OrgAction';

const ContactSelect = ({ onSelect }) => {

  const dispatch = useDispatch();

  const [isSearching, setIsSearching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchItems, setSearchItems] = useState([]);

  const isEmpty = isSearching && !isLoading && !searchItems.length;

  const formatKeyword = (keyword='') => {
    
    if (!keyword.length) return '';

    return keyword.replace(/(^0)|\s|\+/g,'');
  }

  const handleSearch = (keyword) => {

    setIsLoading(true);
    setIsSearching(true)

    const formatted = formatKeyword(keyword);
    
    dispatch(searchContactByEmail(formatted))
    .then(users => {
      setSearchItems(users);
      setIsLoading(false);
    })
    .catch(err => {
      setIsLoading(false);
    });
  }

  const handleClear = () => {
    setIsSearching(false);
  }

  return (
    <div className="d-flex flex-column h-100">
      <div className="py-3">
        <SearchInput onSearch={handleSearch} onClear={handleClear} />
      </div>
      <div className="h-100" style={{ overflowY: 'auto', position: 'relative' }}>
        <div className="mycard-1 py-0 h-100 w-100" style={{ position: 'absolute' }}>
          {isSearching ?
            <SearchItems isLoading={isLoading} items={searchItems} onSelect={user => onSelect(user)} /> :
            <Instructions isLoading={isLoading} />
          }
        </div>
        <NoResults show={isEmpty} />
        <TabLoader isLoading={isLoading} />
      </div>
    </div>
  );
};

const Instructions = ({ isLoading }) => {

  if (isLoading) return '';

  return (
    <div style={{ height: '100%' }} className="d-flex flex-column justify-content-center">
      <span className="text-empty">Start by typing a contact's email or phone number</span>
    </div>
  )
}

const SearchItems = ({ items, isLoading, onSelect }) => {

  if (isLoading) return '';

  return (
    <div className="list-group">
      {items.map(item =>
        <SearchItem
          key={item.objectId}
          item={item}
          onSelect={(e) => onSelect(item)} />
      )}
    </div>
  )
}

const NoResults = ({ show }) => {

  if (!show) return '';

  return (
    <div style={{ height: '100%' }} className="d-flex flex-column justify-content-center">
      <span className="text-empty">User not found</span>
    </div>
  )
};

export default ContactSelect;
