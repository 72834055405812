import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { fetchContacts, searchContacts } from '../../../../../actions/ContactAction';

const useContacts = () => {

  // Dispatch
  const dispatch = useDispatch();

  // Get values from redux store
  const {
    rosters,
    initialLoad,
    isLoadingSearch,
    filterResult,
    page,
  } = useSelector(state => ({
    rosters: state.contact.contacts,
    initialLoad: state.contact.initialLoad,
    isLoadingSearch: state.contact.isLoadingSearch,
    filterResult: state.contact.filterResult,
    page: state.contact.contactPage,
  }), shallowEqual);

  useEffect(() => {
    if (!initialLoad) {
      fetchContacts(false, page)(dispatch);
    }
  }, [initialLoad]);

  const contacts = useMemo(() => {
    return rosters.map(
      roster => roster.contact
    );
  }, [rosters]);

  return {
    items: contacts,
    isLoading: !initialLoad || isLoadingSearch,
    searchItems: filterResult,
  };
}

export default useContacts;
