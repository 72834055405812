import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import Parse from "parse";
import store from "./store";
import App from "./components/App";
import config from "./config";

import "./scss/index.scss";
import "./assets/css/index.scss";
import "./assets/css/Animate.scss";
import ServiceWorkerWrapper from "./components/ServiceWorker";

/**
 * Initalize Parse
 */
const dIppa = "h5tasAJfd1zTKQDUR8tauJ62jozjcEbSjEhMfvqv";
const yeKterces = "m641S90xTcIoi3ejKjB5N0SqwnBPViCegmbYbGQw";

Parse.initialize(dIppa, yeKterces);
Parse.serverURL = `${config.BASE_URL}/parse`;
// Parse.serverURL = "http://api-staging.hubchart.net/parse";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    <ServiceWorkerWrapper />
  </Provider>,
  document.getElementById("root")
);
