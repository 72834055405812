import {
  SET_PROGRESS_NOTES,
  SET_DELETED_PROGRESS_NOTES,
  SET_PROGRESS_NOTES_ALL,
  SET_PROGRESS_NOTES_PENDING,
  SET_PROGRESS_NOTES_DONE,
  SET_PROGRESS_NOTES_RECENT,
  PROGRESS_NOTES_LOADING,
  // SET_PROGRESS_NOTE,
  PROGRESS_NOTE_LOADING,
  CREATE_PROGRESS_NOTE_SUCCESS,
  CREATE_PROGRESS_NOTE_FAILED,
  IS_CREATING_PROGRESS_NOTE,
  UPDATE_PROGRESS_NOTE_SUCCESS,
  UPDATE_PROGRESS_NOTE_FAILED,
  DELETE_PROGRESS_NOTE_SUCCESS,
  RESTORE_PROGRESS_NOTE_SUCCESS,
  IS_UPDATING_PROGRESS_NOTE,
  IS_DELETING_PROGRESS_NOTE,
  SET_PROGRESS_NOTES_PLANS,
  PROGRESS_NOTES_PLANS_LOADING,
  SET_DIAGNOSTIC_STUDIES,
  SET_REFERRALS,
  SET_SUPPLIES,
  CHECK_UNCHECK_PLANS,
  SET_PROGRESS_NOTES_PAGE,
  HAS_MORE_PROGRESS_NOTE
} from "./types";
import { parseAction } from "./REST";
import config from "../config";

export const getProgressNotes = (
  circleId,
  page = 0,
  currentNotes = [],
  pageSize = 10
) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const params = {
      circleId,
      pageSize,
      page,
    };

    dispatch({
      type: PROGRESS_NOTES_LOADING,
      isLoading: true,
    });

    parseAction(
      "post",
      config.BASE_URL + "/parse/functions/getProgressNotesV2",
      params
    )
      .then(
        (response) => {
          const { progressNotes = [] } = response.result;
          dispatch({
            type: SET_PROGRESS_NOTES,
            data: [...currentNotes, ...progressNotes] || [],
            currentPage: response.result.page,
          });

          dispatch({
            type: PROGRESS_NOTES_LOADING,
            isLoading: false,
          });
          resolve(progressNotes);
        },
        (error) => {
          reject(error);
        }
      )
      .catch((error) => {
        dispatch({
          type: PROGRESS_NOTES_LOADING,
          isLoading: false,
        });

        reject(error);
        return new Error(error);
      });
  });
};

export const getDeletedProgressNotes = (
  circleId,
  page = 0,
  currentNotes = [],
  pageSize = 10
) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const params = {
      circleId,
      pageSize,
      page,
    };

    dispatch({
      type: PROGRESS_NOTES_LOADING,
      isLoading: true,
    });

    parseAction(
      "post",
      config.BASE_URL + "/parse/functions/getDeletedProgressNotes",
      params
    )
      .then(
        (response) => {
          const { progressNotes = [] } = response.result;
          dispatch({
            type: SET_DELETED_PROGRESS_NOTES,
            data: [...currentNotes, ...progressNotes] || [],
            deletedCurrentPage: response.result.page,
          });

          dispatch({
            type: PROGRESS_NOTES_LOADING,
            isLoading: false,
          });
          resolve(progressNotes);
        },
        (error) => {
          reject(error);
        }
      )
      .catch((error) => {
        dispatch({
          type: PROGRESS_NOTES_LOADING,
          isLoading: false,
        });

        reject(error);
        return new Error(error);
      });
  });
};

export const getAllProgressNotes = (
  page = 0,
  currentNotes = [],
  pageSize = 10,
  status = ''
) => (dispatch) => {
  if (status === 'all') status = '';
  return new Promise((resolve, reject) => {
    const params = {
      pageSize,
      page,
      status
    };

    dispatch({
      type: PROGRESS_NOTES_LOADING,
      isLoading: true,
    });

    parseAction(
      "post",
      config.BASE_URL + "/parse/functions/getAllProgressNotes",
      params
    )
      .then(
        (response) => {
          const { progressNotes = [] } = response.result;

          switch (status) {
            case "": 
              dispatch({
                type: SET_PROGRESS_NOTES_ALL,
                data: [...currentNotes, ...progressNotes] || [],
                page: response.result.page,
                hasMore: response.result.hasMore
              });break;
            case "PENDING": 
              dispatch({
                type: SET_PROGRESS_NOTES_PENDING,
                data: [...currentNotes, ...progressNotes] || [],
                page: response.result.page,
                hasMore: response.result.hasMore
              });break;
            case "DONE": 
              dispatch({
                type: SET_PROGRESS_NOTES_DONE,
                data: [...currentNotes, ...progressNotes] || [],
                page: response.result.page,
                hasMore: response.result.hasMore
              });break;
          }

          dispatch({
            type: SET_PROGRESS_NOTES_RECENT,
            data: [...currentNotes, ...progressNotes] || [],
            page: response.result.page,
            hasMore: response.result.hasMore
          });

          dispatch({
            type: PROGRESS_NOTES_LOADING,
            isLoading: false,
          });

          dispatch({
            type: HAS_MORE_PROGRESS_NOTE,
            hasMorePN: response.result.hasMore
          });

          resolve(progressNotes);
        },
        (error) => {
          reject(error);
        }
      )
      .catch((error) => {
        dispatch({
          type: PROGRESS_NOTES_LOADING,
          isLoading: false,
        });

        reject(error);
        return new Error(error);
      });
  });
};

export const getProgressNote = (objectId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: PROGRESS_NOTE_LOADING,
      isLoading: true,
    });

    parseAction(
      "get",
      `${config.BASE_URL}/parse/classes/ProgressNote/${objectId}`
    ).then(
      (response) => {
        const progressNote = {
          ...response,
          planIds: (response.plans || []).map((item) => item.objectId),
        };
        resolve(progressNote);
        // dispatch({
        //     type: SET_PROGRESS_NOTE,
        //     progressNote,
        // });

        dispatch({
          type: PROGRESS_NOTE_LOADING,
          isLoading: false,
        });
      },
      (error) => {
        reject(error);
        dispatch({
          type: PROGRESS_NOTE_LOADING,
          isLoading: false,
        });
      }
    );
  });
};

export const createProgressNote = (payload, orgId = "") => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: IS_CREATING_PROGRESS_NOTE,
      isUpdating: true,
    });

    return parseAction(
      "post",
      config.BASE_URL + `/parse/functions/createProgressNote?orgId=${orgId}`,
      payload,
      { "Content-Type": "application/json" }
    ).then(
      (response) => {
        dispatch({ type: CREATE_PROGRESS_NOTE_SUCCESS });
        dispatch({
          type: IS_CREATING_PROGRESS_NOTE,
          isUpdating: false,
        });
        resolve(response);
      },
      (error) => {
        dispatch({ type: CREATE_PROGRESS_NOTE_FAILED });
        dispatch({
          type: IS_CREATING_PROGRESS_NOTE,
          isUpdating: false,
        });
        reject(error);
      }
    );
  });
};

export const updateProgressNote = (payload, objectId) => (dispatch) => {
  const { createdAt, updatedAt, ...rest } = payload;
  return new Promise((resolve, reject) => {
    dispatch({
      type: IS_UPDATING_PROGRESS_NOTE,
      isUpdating: true,
    });

    return parseAction(
      "put",
      `${config.BASE_URL}/parse/classes/ProgressNote/${objectId}`,
      rest,
      { "Content-Type": "application/json" }
    ).then(
      (response) => {
        dispatch({ type: UPDATE_PROGRESS_NOTE_SUCCESS });
        dispatch({
          type: IS_UPDATING_PROGRESS_NOTE,
          isUpdating: false,
        });
        resolve(response);
      },
      (error) => {
        dispatch({ type: UPDATE_PROGRESS_NOTE_FAILED });
        dispatch({
          type: IS_UPDATING_PROGRESS_NOTE,
          isUpdating: false,
        });
        reject(error);
      }
    );
  });
};

export const deleteProgressNote = (notes, objectId) => (dispatch) => {
  dispatch({
    type: IS_DELETING_PROGRESS_NOTE,
    isUpdating: true,
  });

  parseAction("post", `${config.BASE_URL}/parse/functions/deleteProgressNote`, {
    objectId,
  }).then(
    () => {
      const filteredNotes = notes.filter((item) => item.objectId !== objectId);

      dispatch({
        type: DELETE_PROGRESS_NOTE_SUCCESS,
        data: filteredNotes,
      });
      dispatch({
        type: IS_DELETING_PROGRESS_NOTE,
        isUpdating: false,
      });
    },
    (error) => {
      dispatch({
        type: IS_DELETING_PROGRESS_NOTE,
        isUpdating: false,
      });
    }
  );
};

export const restoreProgressNote = (notes, objectId) => (dispatch) => {
  dispatch({
    type: IS_DELETING_PROGRESS_NOTE,
    isUpdating: true,
  });

  parseAction(
    "post",
    `${config.BASE_URL}/parse/functions/restoreProgressNote`,
    {
      objectId,
    }
  ).then(
    () => {
      const filteredNotes = notes.filter((item) => item.objectId !== objectId);
      dispatch({
        type: RESTORE_PROGRESS_NOTE_SUCCESS,
        data: filteredNotes,
      });
      dispatch({
        type: IS_DELETING_PROGRESS_NOTE,
        isUpdating: false,
      });
    },
    (error) => {
      dispatch({
        type: IS_DELETING_PROGRESS_NOTE,
        isUpdating: false,
      });
    }
  );
};

export const getProgressNotesPlans = (planIds) => (dispatch) => {
  dispatch({
    type: PROGRESS_NOTES_PLANS_LOADING,
    isLoading: true,
  });

  return parseAction(
    "post",
    config.BASE_URL + "/parse/functions/getProgressNotePlans",
    { pageSize: 150 }
  ).then((response) => {
    const { progressNotePlans = [] } = response.result;
    const groupedPlans = progressNotePlans.reduce(function(rv, x) {
      (rv[x.title] = rv[x.title] || []).push(x);
      return rv;
    }, []);
    const restructured = Object.keys(groupedPlans).map((key) => ({
      title: key,
      checklist: groupedPlans[key].map((item) => ({
        ...item,
        isChecked: isChecked(item.objectId),
      })),
    }));

    function isChecked(objectId) {
      return planIds.includes(objectId);
    }

    dispatch({
      type: SET_PROGRESS_NOTES_PLANS,
      data: restructured,
    });

    dispatch({
      type: PROGRESS_NOTES_PLANS_LOADING,
      isLoading: false,
    });
  });
};

export const getDiagnosticStudies = () => (dispatch) => {
  parseAction("get", `${config.BASE_URL}/parse/classes/LOOKUP_Diagnostic`, {
    limit: 600,
  })
    .then(
      (response) => {
        const { results = [] } = response;
        dispatch({
          type: SET_DIAGNOSTIC_STUDIES,
          data: results || [],
        });
      },
      (error) => {
        console.log(error);
      }
    )
    .catch((error) => {
      return new Error(error);
    });
};

export const getReferrals = () => (dispatch) => {
  parseAction("get", `${config.BASE_URL}/parse/classes/LOOKUP_Referral`, {
    limit: 1000,
  })
    .then(
      (response) => {
        const { results = [] } = response;
        dispatch({
          type: SET_REFERRALS,
          data: results || [],
        });
      },
      (error) => {
        console.log(error);
      }
    )
    .catch((error) => {
      return new Error(error);
    });
};

export const getSupplies = () => (dispatch) => {
  parseAction("get", `${config.BASE_URL}/parse/classes/LOOKUP_Supplies`, {
    limit: 1000,
  })
    .then(
      (response) => {
        const { results = [] } = response;
        dispatch({
          type: SET_SUPPLIES,
          data: results || [],
        });
      },
      (error) => {
        console.log(error);
      }
    )
    .catch((error) => {
      return new Error(error);
    });
};

export const checkUncheckPlansArray = (
  objectId = "",
  checked = false,
  plansArray = []
) => (dispatch) => {
  const mappedArray = plansArray.map((item) => ({
    ...item,
    checklist: (item.checklist || []).map((listItem) => ({
      ...listItem,
      isChecked:
        listItem.objectId === objectId ? checked : listItem.isChecked || false,
    })),
  }));

  dispatch({
    type: CHECK_UNCHECK_PLANS,
    plans: mappedArray,
  });
};

export const setProgressNotesPage = (currentPage, callBack) => (dispatch) => {
  dispatch({
    type: SET_PROGRESS_NOTES_PAGE,
    currentPage,
  });

  callBack();
};
