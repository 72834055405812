import React, { useEffect } from "react";

import "./index.css";

function ObjectiveComponent({ objective = "" }) {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="card custom-card">
      <h5 className="card-header ">
        <strong>OBJECTIVE</strong>
      </h5>
      <div className="card-body">
        <div className="custom-form-group-container pe-text">
          {objective || ""}
        </div>
      </div>
    </div>
  );
}

export default ObjectiveComponent;
