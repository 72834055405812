import React, { Component } from 'react';
import Video, { connect, createLocalTracks, createLocalVideoTrack } from 'twilio-video';
import ConferenceApi from '../../../api/Conference';
// import Loader from '../../Common/Loader';
import ConferenceHelper from '../../../helper/conference';
import CallApi from '../../../api/Call';
import './PrivateCall.css';
import TimeLoader from './TimeLoader';
import { NoAnswer } from './NoAnswer';
import Spinner from '../../Common/Spinner';
import CallRingBack from './CallRingBack';

export default class PrivateCallWindow extends Component {

    constructor(props) {

        super(props);

        this.status = {
            CONNECTING: 'Connecting...',
            RINGING: 'Ringing...',
            CONNECTED: 'Connected',
            NO_ANSWER: 'No answer',
            DECLINED: 'Declined',
            ANSWERED: 'Joining call...',
            CANCELLED: 'Cancelled',
            ENDED: 'Ended',
        };

        this.state = {
            identity: null,  /* Will hold the fake name assigned to the client. The name is generated by faker on the server */
            roomName: '',    /* Will store the room name */
            roomNameErr: false,  /* Track error for room name TextField. This will    enable us to show an error message when this variable is true */
            previewTracks: null,
            localMediaAvailable: false, /* Represents the availability of a LocalAudioTrack(microphone) and a LocalVideoTrack(camera) */
            hasJoinedRoom: false,
            activeRoom: null, // Track the current active room

            // Fetched from backend
            roomId: '',
            token: '',
            conference: {},

            // Loading
            isLoading: false,
            loadingMessage: '',

            remoteParticipants: [],

            // Audio and Video controls
            mute: false,
            hasVideo: true,

            // Connection status of host
            // It starts with connecting
            // Then online
            connectionStatus: 'connecting',
            
            localVideoTrack: null,

            // Initial call status
            callStatus: this.status.CONNECTING,

            // Timer for timeout
            statusTimer: null,
            // After 60 seconds, declare call as not answered
            statusTimeout: 60,
            // In seconds
            statusProgress: 0,

            // Set to true to start timer
            timeoutStarted: false,

            // The Call object from parse
            call: {},

            // The user who called
            caller: {},

            // The user who receives call
            callee: {},

            errorStatus: null,

            // Properties for remote participant
            remoteHasVideo: true,

            // Name and image of callee
            partnerImageUrl: '',
            partnerName: '',

            isVideoCall: false,
        };

        this.joinRoom = this.joinRoom.bind(this);
        this.roomJoined = this.roomJoined.bind(this);
        this.leaveRoom = this.leaveRoom.bind(this);
        this.detachTracks = this.detachTracks.bind(this);
        this.detachParticipantTracks = this.detachParticipantTracks.bind(this);

        // Extra bindings
        this.participantConnected = this.participantConnected.bind(this);
        this.attachTracks = this.attachTracks.bind(this);
        this.attachTrack = this.attachTrack.bind(this);
        this.rejectCall = this.rejectCall.bind(this);

        this.handleGetTokenSuccess = this.handleGetTokenSuccess.bind(this);
        this.handleGetTokenError = this.handleGetTokenError.bind(this);

        // Audio and Video controls
        this.muteAudio = this.muteAudio.bind(this);
        this.unmuteAudio = this.unmuteAudio.bind(this);

        // Timer related methods
        this.handleTimerEnd = this.handleTimerEnd.bind(this);
        this.handleTimerTick = this.handleTimerTick.bind(this);

        // Others
        this.disconnectCall = this.disconnectCall.bind(this);
    }

    componentDidMount() {

        const params = new URLSearchParams(this.props.location.search);
        const action = params.get('action');

        // If window was opened from participant selection
        // by the host of conference call
        if (action === 'create') {

            const call_uuid = params.get('call_uuid');
            const callee_id = params.get('callee_id');
            const thread_id = params.get('thread_id');
            const has_video = params.get('has_video');
            const hasVideo = has_video === 'true';

            this.setState({isVideoCall: hasVideo});

            this.setCalleeData(thread_id);

            this.initiateCall({
                call_uuid,
                callee_id,
                has_video
            }, hasVideo);

            this.startTimer({
                onEnd: this.handleTimerEnd,
                onTick: this.handleTimerTick
            });
        }

        // If window was opened from a notification link
        // sent to participants, when conference call was created
        else {
            let roomId = params.get('roomId');

            const callerData = this.setCallerData(roomId);

            const hasVideo = callerData.has_video === 'true';

            this.setState({isVideoCall: hasVideo});

            this.answerCall(roomId, hasVideo);
        }
    }

    setCalleeData(thread_id) {
        let _callData = localStorage.getItem(thread_id);
        let callData = JSON.parse(_callData);

        if (callData) {
            this.setState({
                partnerImageUrl: callData.callee_image_url,
                partnerName: callData.callee_name,
            })
        }
    }

    setCallerData(roomId) {
        let _callData = localStorage.getItem(roomId);
        let callData = JSON.parse(_callData);

        if (callData) {
            this.setState({
                partnerImageUrl: callData.caller_image_url,
                partnerName: callData.caller_name,
            })
        }

        return callData;
    }

    startTimer(options = {}) {

        const {onEnd, onTick} = options;

        const statusTimer = window.setInterval(() => {

            const {statusProgress, statusTimeout} = this.state;

            if (statusProgress < statusTimeout) {
                
                if (onTick && typeof onTick === 'function') {
                    onTick();
                }

                this.setState({
                    statusProgress: statusProgress + 1
                });
            }
            else {
                if (onEnd && typeof onEnd === 'function') {
                    onEnd();
                }
            }
        }, 1000);

        this.setState({
            statusTimer,
            timeoutStarted: true
        });
    }

    handleTimerEnd() {
        
        window.clearInterval(this.state.statusTimer);

        // After the timer ends, cancel the call
        CallApi.cancelCall(this.state.roomId);

        // Leave the room
        this.leaveRoom();

        // Remove local video
        if (this.state.localVideoTrack) {
            this.detachTrack(this.state.localVideoTrack);
        }

        // Show redial view
        this.setState({callStatus: this.status.NO_ANSWER});
    }

    handleDeclineCall() {
        
        window.clearInterval(this.state.statusTimer);

        // Leave the room
        this.leaveRoom();

        // Remove local video
        if (this.state.localVideoTrack) {
            this.detachTrack(this.state.localVideoTrack);
        }

        // Show redial view
        this.setState({callStatus: this.status.DECLINED});
    }

    handleAnswerCall() {

        window.clearInterval(this.state.statusTimer);

        this.setState({callStatus: this.status.ANSWERED});
    }

    handleTimerTick() {

        const {roomId} = this.state;
        
        let _callData = localStorage.getItem(roomId);
        let callData = JSON.parse(_callData);

        if (callData != null) {
            
            if (callData.status === "CALL_DECLINED") {    
                return this.handleDeclineCall();
            }

            if (callData.status === "CALL_ANSWERED") {    
                return this.handleAnswerCall();
            }

            if (callData.isRinging) {
                this.setState({
                    callStatus: this.status.RINGING
                });
            }
        }
    }

    initiateCall(data = {}, has_video) {

        const { initiateCall } = CallApi;

        const {
            handleGetTokenSuccess,
            joinRoom,
            roomJoined,
        } = this;

        initiateCall(data)
        .then(res => handleGetTokenSuccess(res))
        .then(data => joinRoom(data, has_video))
        .then(room => roomJoined(room));
    }

    setLoading(isLoading, loadingMessage) {
        this.setState({
            isLoading,
            loadingMessage: !isLoading ? '' : loadingMessage
        });
    }

    updateParticipant(objectId, updates) {
        const { remoteParticipants } = this.state;

        const updated = remoteParticipants.map(rp => {
            if (rp.objectId === objectId) {
                return {
                    ...rp,
                    ...updates
                };
            }
            else return rp;
        });

        this.setState({ remoteParticipants: updated });
    }

    createConference(result) {

        this.initLocalVideo();

        Promise.resolve()
            // Handle result from main window
            .then(() => this.handleGetTokenSuccess(result))
            // Join room
            .then(() => this.joinRoom())
            .then(room => this.roomJoined(room));
    }

    answerCall(roomId, has_video) {

        const { answerCall } = CallApi;
        
        const {
            handleGetTokenSuccess,
            joinRoom,
            roomJoined,
        } = this;

        answerCall(roomId)
            .then(res => handleGetTokenSuccess(res))
            .then((data) => joinRoom(data, has_video))
            .then(room => roomJoined(room));
    }

    handleGetTokenSuccess(result) {

        // Hide loading indicator
        this.setLoading(false);

        // Show some log <3
        console.log('Get token success');

        // Prepare our data
        const { token, roomId, call } = result;
        const { callee, caller } = call;
        const data = {
            roomId,
            token,
            call,
            callee,
            caller,
        };

        // Update state
        this.setState(data);

        return data;
    }

    handleGetTokenError(response) {
        this.setLoading(false);
        console.log(response);

        const { responseJSON } = response;

        if (responseJSON) {
            const { error, code } = responseJSON;

            alert(error);

            return window.close();
        }

        else {
            alert('Something went wrong');
        }

        return Promise.reject();
    }

    detachTracks(tracks) {
        tracks.forEach(track => {
            track.detach().forEach(detachedElement => {
                detachedElement.remove();
            });
        });
    }

    detachParticipantTracks(participant) {
        var tracks = Array.from(participant.tracks.values());
        this.detachTracks(tracks);
    }

    joinRoom(data, has_video) {
        
        const {roomId, token} = data

        console.log(`Joining room ${roomId}...`);

        /* 
            Connect to a room by providing the token and connection
            options that include the room name and tracks.
            We also show an alert if an error occurs while connecting to the room.    
        */

        return createLocalTracks({
            audio: true,
            video: { width: 640 }
        })
        .then(localTracks => {

            if (!has_video) {
                const localVideoTrack = localTracks.find(track => {
                    return track.kind === 'video';
                });

                if (localVideoTrack) {
                    
                    localVideoTrack.disable();

                    this.setState({
                        hasVideo: false,
                        remoteHasVideo: false,
                    });
                }
            }
            
            return connect(token, {
                name: roomId,
                tracks: localTracks
            });
        })
        .then(room => {

            return room;
        })
        .catch(error => {
            // this.setLoading(false);
            alert('Could not connect to Twilio: ' + error.message);
        });
    }

    // Attach the Track to the DOM.
    attachTrack(track, container) {
        container.appendChild(track.attach());
    }

    // Attach the Tracks to the DOM.
    attachTracks(tracks, container) {
        tracks.forEach(track => {
            console.log(track)
            container.appendChild(track.attach());
        });
    }

    // Attach the Participant's Tracks to the DOM.
    attachParticipantTracks(participant, container) {
        var tracks = Array.from(participant.tracks.values());
        this.attachTracks(tracks, container);
    }

    // Get the Participant's Tracks.
    getTracks(participant) {
        return Array.from(participant.tracks.values())
            .filter((publication) => {
                return publication.track;
            }).map((publication) => {
                return publication.track;
            });
    }

    // Detach given track from the DOM
    detachTrack(track) {
        track.detach().forEach((element) => {
            element.remove();
        });
    }

    // A new RemoteParticipant joined the Room
    participantConnected(participant, container) {

        // Update participant from state
        this.updateParticipant(participant.identity, {
            connectionStatus: 'online'
        });

        participant.tracks.forEach((publication) => {
            this.trackPublished(publication, container);
        });
        participant.on('trackPublished', (publication) => {
            this.trackPublished(publication, container);
        });
        participant.on('trackUnpublished', this.trackUnpublished);
    }

    // A new RemoteTrack was published to the Room.
    trackPublished(publication, container) {

        if (publication.isSubscribed) {
            this.attachTrack(publication.track, container);
        }
        publication.on('subscribed', (track) => {
            this.attachTrack(track, container);
        });
        publication.on('unsubscribed', this.detachTrack);
    }

    // A RemoteTrack was unpublished from the Room.
    trackUnpublished(publication) {
        console.log("remote track unpublished")
        console.log("remote track unpublished");
    }

    getRemoteMediaContainer(participant) {
        const { identity } = participant;

        const refKey = `remoteMedia`;

        const container = this.refs[refKey];

        return container;
    }

    roomJoined(room) {

        // Upon joining room,
        // set isEngaged to local storage
        // This is checked in pubnub notif {type=conference_invite}
        // To prevent ring when engaged
        // Note: Should be set false upon disconnect. See below
        ConferenceHelper.setEngaged(true);

        // Called when a participant joins a room
        console.log("Joined as '" + this.state.identity + "'");
        this.setState({
            activeRoom: room,
            localMediaAvailable: true,
            hasJoinedRoom: true  // Removes ‘Join Room’ button and shows ‘Leave Room’
        });

        // Attach LocalParticipant's tracks to the DOM, if not already attached.
        var previewContainer = this.refs.localMedia;
        if (!previewContainer.querySelector('video')) {
            this.attachTracks(this.getTracks(room.localParticipant), previewContainer);
        }

        // Attach the Tracks of the Room's Participants.
        // rmedia1
        // let remoteMediaContainer = this.refs.remoteMedia
        room.participants.forEach((participant) => {

            console.log(`Attaching tracks for ${participant.identity}`);

            const remoteMediaContainer = this.getRemoteMediaContainer(participant);

            this.participantConnected(participant, remoteMediaContainer);
        });

        // ... more event listeners
        // Attach the Tracks of the room's participants.
        //rmedia2
        room.participants.forEach(participant => {
            console.log("Already in Room: '" + participant.identity + "'");
            var previewContainer = this.getRemoteMediaContainer(participant)
            this.attachTracks(this.getTracks(participant), previewContainer);
        });

        // Event handlers for each participants 
        room.participants.forEach(participant => {
            
            participant.on('trackDisabled', track => {
                // hide or remove the media element related to this track
                console.log(`trackDisabled for participant ${participant.identity}`);

                if (track.kind === 'video') {
                    this.setState({ remoteHasVideo: false });
                }
            });

            participant.on('trackEnabled', track => {
                // show the track again
                console.log(`trackEnabled for participant ${participant.identity}`);

                if (track.kind === 'video') {
                    this.setState({ remoteHasVideo: true });
                }
            });
        });

        // Participant joining room
        room.on('participantConnected', participant => {
            
            console.log("New Participant Joining: '" + participant.identity + "'");

            const remoteMediaContainer = this.getRemoteMediaContainer(participant);

            this.participantConnected(participant, remoteMediaContainer);

            this.setState({callStatus: this.status.CONNECTED});

            window.clearInterval(this.state.statusTimer);

            // Add event handlers for this participant
            participant.on('trackDisabled', track => {
                // hide or remove the media element related to this track
                console.log(`trackDisabled for participant ${participant.identity}`);

                if (track.kind === 'video') {
                    this.setState({ remoteHasVideo: false });
                }
            });

            participant.on('trackEnabled', track => {
                // show the track again
                console.log(`trackEnabled for participant ${participant.identity}`);

                if (track.kind === 'video') {
                    this.setState({ remoteHasVideo: true });
                }
            });
        });

        // Attach participant’s tracks to DOM when they add a track
        // rmedia3
        room.on('trackAdded', (track, participant) => {
            console.log(participant.identity + ' added track: ' + track.kind);
            // var previewContainer = this.refs.remoteMedia;
            var previewContainer = this.getRemoteMediaContainer(participant);
            this.attachTracks([track], previewContainer);
        });

        // Detach participant’s track from DOM when they remove a track.
        room.on('trackRemoved', (track, participant) => {
            this.log(participant.identity + ' removed track: ' + track.kind);
            this.detachTracks([track]);
        });

        // Detach all participant’s track when they leave a room.
        room.on('participantDisconnected', participant => {

            // Update participant from state
            this.updateParticipant(participant.identity, {
                connectionStatus: 'offline'
            });

            console.log("Participant '" + participant.identity + "' left the room");
            this.detachTracks(this.getTracks(participant));

            // If connected
            this.leaveRoom();

            CallApi.endCall(this.state.roomId);

            this.setState({
                callStatus: this.status.ENDED
            });

            // Stop the timer
            const {statusTimer} = this.state;

            window.clearInterval(statusTimer);
        });

        // Once the local participant leaves the room, detach the Tracks
        // of all other participants, including that of the LocalParticipant.
        room.on('disconnected', () => {

            if (this.state.previewTracks) {
                this.state.previewTracks.forEach(track => {
                    track.stop();
                });
            }
            this.detachTracks(this.getTracks(room.localParticipant));
            room.participants.forEach(participant => {

                this.detachTracks(this.getTracks(participant));
            });
            this.state.activeRoom = null;
            this.setState({ hasJoinedRoom: false, localMediaAvailable: false });
        });

        room.on('participantReconnecting', remoteParticipant => {

            const { state, identity } = remoteParticipant;

            // assert.equals(remoteParticipant.state, 'reconnecting');
            console.log(`${remoteParticipant.identity} is reconnecting the signaling connection to the Room!`);

            /* Update the RemoteParticipant UI here */
            this.updateParticipant(identity, {
                connectionStatus: 'reconnecting',
            });
        });

        room.on('participantReconnected', remoteParticipant => {

            const { identity } = remoteParticipant;

            // assert.equals(remoteParticipant.state, 'connected');
            console.log(`${remoteParticipant.identity} has reconnected the signaling connection to the Room!`);

            /* Update the RemoteParticipant UI here */
            this.updateParticipant(identity, {
                connectionStatus: 'online',
            });
        });
    }

    leaveRoom() {

        const {activeRoom} = this.state;

        if (activeRoom) {
            activeRoom.disconnect();
        }

        this.setState({ hasJoinedRoom: false, localMediaAvailable: false });
    }

    disconnectCall() {

        const {timeoutStarted, callStatus, roomId} = this.state

        // Disconnect from twilio room
        this.leaveRoom();

        if (timeoutStarted &&
            (callStatus === this.status.CONNECTING ||
            callStatus === this.status.RINGING ||
            callStatus === this.status.ANSWERED )) {

            CallApi.cancelCall(roomId);

            this.setState({
                callStatus: this.status.CANCELLED
            });

            const {statusTimer} = this.state;

            window.clearInterval(statusTimer);
        }
        else {
            // If connected
            CallApi.endCall(roomId);

            this.setState({
                callStatus: this.status.ENDED
            });
        }
    }

    rejectCall() {
        const { roomId } = this.state;

        if (roomId) {
            this.endConference(roomId);
        }
        else {
            window.close();
        }
    }

    componentWillUnmount() {

        const { roomId } = this.state;

        localStorage.setItem("onCall", false);
        localStorage.removeItem(roomId);
    }

    endConference(roomId) {

        this.setLoading(true, 'Ending conference call');

        return ConferenceApi.endConferenceCall(roomId)
            .then(result => {
                // Make an API call to end call.
                console.log('End conference call success');

                if (window.opener.clearConference) {
                    window.opener.clearConference()
                }

                window.close();
            })
            .catch(response => {
                console.log(response);
                console.error('End conference call failed');
                window.close();
                return Promise.reject();
            });
    }

    leaveConference(roomId) {

        this.setLoading(true, 'Leaving conference call');

        return ConferenceApi.leaveConferenceCall(roomId)
            .then(result => {
                // Make an API call to end call.
                console.log('End conference call success');

                if (window.opener.clearConference) {
                    window.opener.clearConference()
                }

                window.close();
            })
            .catch(response => {
                console.log(response);
                console.error('Leave conference call failed');
                window.close();
                return Promise.reject();
            });
    }

    muteAudio() {

        const { activeRoom } = this.state;

        const localParticipant = activeRoom.localParticipant;

        localParticipant.audioTracks.forEach(function (track) {
            track.track.disable();
        });

        this.setState({ mute: true });
    }

    unmuteAudio() {

        const { activeRoom } = this.state;

        const localParticipant = activeRoom.localParticipant;

        localParticipant.audioTracks.forEach(function (track) {
            track.track.enable();
        })
        this.setState({ mute: false });
    }

    pauseVideo() {

        const { activeRoom, localVideoTrack } = this.state

        if (!activeRoom) {
            return;
        }

        const localParticipant = activeRoom.localParticipant;

        // Disable all video tracks of local participant
        localParticipant.videoTracks.forEach(videoTrack => {
            videoTrack.track.disable();
        });

        if (localVideoTrack) {
            localVideoTrack.disable();
        }

        // Update state
        this.setState({
            hasVideo: false
        })
    }

    unpauseVideo() {

        const { activeRoom, localVideoTrack } = this.state;
        const { localParticipant } = activeRoom;

        if (localParticipant.videoTracks) {

            // Enable all video tracks of local participant
            localParticipant.videoTracks.forEach(videoTrack => {
                videoTrack.track.enable();
            });

            if (localVideoTrack) {
                localVideoTrack.enable();
            }

            // Update state
            this.setState({
                hasVideo: true
            });
        }
    }

    getStatusText(status) {
        switch (status) {
            case 'connecting': return 'Connecting';
            case 'invited': return 'Waiting to Join';
            case 'is_busy': return 'In another call';
            case 'online': return 'In Conference';
            case 'offline': return 'Disconnected';
            case 'reconnecting': return 'Reconnecting';
            default: return 'No status';
        }
    }

    renderPauseVideo() {

        const { hasVideo } = this.state;

        let handleClick = hasVideo ?
            this.pauseVideo.bind(this) :
            this.unpauseVideo.bind(this);

        let imgSrc = hasVideo ?
            require("../VideoCall/images/video.png") :
            require("../VideoCall/images/video_off.png");

        return (
            <span className="mr-3" onClick={handleClick}>
                <a href="#!" style={{ cursor: "pointer" }}>
                    <img src={imgSrc} style={{ height: "45px" }} />
                </a>
            </span>
        );
    }

    renderMute() {

        const { mute } = this.state;

        if (mute) {
            return (
                <span className="mr-3" onClick={this.unmuteAudio.bind(this)}>
                    <a href="#!" style={{ cursor: "pointer" }}>
                        <img src={require("../VideoCall/images/mic_mute.png")} style={{ height: "45px" }} />
                    </a>
                </span>
            );
        }
        else {
            return (
                <span className="mr-3" onClick={this.muteAudio.bind(this)}>
                    <a href="#!" style={{ cursor: "pointer" }}>
                        <img src={require("../VideoCall/images/mic.png")} style={{ height: "45px" }} />
                    </a>
                </span>
            );
        }
    }

    render() {

        const {
            isLoading,
            loadingMessage,
            remoteParticipants,
            conference,
            callStatus,
            hasVideo,
            statusProgress,
            statusTimeout,
            timeoutStarted,
            roomId,
            remoteHasVideo,
            partnerName,
            partnerImageUrl,
            isVideoCall,
        } = this.state;

        const userImage = require("../../../assets/images/default.png");

        const statusPercent = (statusProgress / statusTimeout) * 100;
        
        if (callStatus === this.status.NO_ANSWER ||
            callStatus === this.status.DECLINED ||
            callStatus === this.status.CANCELLED ||
            callStatus === this.status.ENDED) {
            return <NoAnswer partnerName={partnerName} partnerImageUrl={partnerImageUrl} callStatus={callStatus}/>;
        }

        return (<>
            <div className="private-call-container">

                <div className="receiver-video-item">
                    <div className="camera-container">
                        <div align="center" ref="remoteMedia"></div>
                    </div>

                    <div className="camera-placeholder" style={{ display: 'flex', flexDirection: 'column', zIndex: remoteHasVideo ? 1 : 2 }}>
                        <img src={partnerImageUrl || userImage} alt="" className="profile-image large rounded-circle" />
                        <span className="receiver-name">{partnerName}</span>
                    </div>
                </div>

                <div className="caller-video-item">
                    <div className="camera-container">
                        <div align="center" ref="localMedia"></div>
                    </div>

                    <div className="camera-placeholder" style={{ zIndex: hasVideo ? 1 : 2 }}>
                        <img src={userImage} alt="" className="profile-image rounded-circle" />
                    </div>
                </div>

                {   
                    timeoutStarted &&
                    callStatus != this.status.CONNECTED ?
                    <div className="call-status">
                        {/* <div style={{width: '200px'}}>
                            <TimeLoader seconds={statusTimeout} started={timeoutStarted}/>
                        </div> */}
                        <Spinner />
                        {callStatus}
                    </div>
                    : ''
                }

                <div className="controls-container-private" align="center">
                    {this.renderPauseVideo()}
                    {this.renderMute()}
                    <button className="btn btn-danger btn-circle btn-lg" onClick={this.disconnectCall}>
                        <i className="fas fa-phone-slash" />
                    </button>
                </div>

                { callStatus === this.status.RINGING ?
                    <CallRingBack />
                    : ''
                }
            </div>
        </>);
    }
}
