import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchContacts, getContacts } from '../../../actions/ContactAction'
import { parseAction } from '../../../actions/REST'
import config from '../../../config'

const $ = window.$;

export class AddMemberModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tempContacts : [],
            initialLoad : false,
            selectedContact : [],
            filterContacts : [],
            isSearching : false,
        }
    }

    componentDidMount() {
        if (this.props.isOpen) {
            $("#addMemberModal").modal('show');
        } else {
            $("#addMemberModal").modal('hide');
        }
        $("#addMemberModal").on('hidden.bs.modal', function (e) {
            this.props.modalClose();
        }.bind(this))

        if (this.props.contacts.length === 0) {
            this.props.fetchContacts();
        } else {
            let newTemp = [
                ...this.props.contacts.filter((data) => {
                    var _find = this.props.members.find(member => {
                        if (!member || !data.contact) {
                            return false;
                        }
                        return member.objectId === data.contact.objectId
                    });

                    if (_find) {
                        return false
                    }
                    return true
                })
            ]
            this.setState({
                tempContacts : newTemp,
                filterContacts : newTemp,
                initialLoad : true
            })
        }
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        if (this.props.contacts.length !== 0) {
            if (!this.state.initialLoad) {
                let newTemp = [
                    ...this.props.contacts.filter((data) => {
                        var _find = this.props.members.find(member => {
                            if (!member || !data.contact) {
                                return false;
                            }
                            return member.objectId === data.contact.objectId
                        });

                        if (_find) {
                            return false
                        }
                        return true
                    })
                ]

                this.setState({
                    tempContacts : newTemp,
                    filterContacts : newTemp,
                    initialLoad : true
                })
            }

        }
        return null;
    }

    componentDidUpdate() {

    }

    inviteContacts() {
        this.props.inviteMember(this.state.selectedContact);

        this.setState({
            selectedContact : []
        })

    }

    selectedContact(contact) {
        // let newTempContact = [...this.state.tempContacts.filter((data) => {
        //     if (!data.contact) return;
        //     return data.contact.objectId !== contact.objectId;
        // })]

        let newSelectedContact = [...this.state.selectedContact, contact];

        let filterContactsTemp = [...this.state.filterContacts.filter((data) => {
            if (this.state.isSearching) {
                return data.objectId !== contact.objectId;
            } else {
                if (!contact || !data.contact) {
                    return false;
                }
                return data.contact.objectId !== contact.objectId;
            }
        })]

        this.setState({
            // tempContacts : newTempContact,
            filterContacts : filterContactsTemp,
            selectedContact : newSelectedContact,
        })
    }

    removeSelected(contact) {
        let newSelectedContact = [...this.state.selectedContact.filter((data) => {
            return data.objectId !== contact.objectId;
        })];

        let contactData = {};
        let filterContactsTemp;
        if (this.state.isSearching) {
            filterContactsTemp = [contact, ...this.state.filterContacts]
        } else {
            contactData.contact = contact;
            filterContactsTemp = [contactData, ...this.state.filterContacts]
        }

        this.setState({

            filterContacts : filterContactsTemp,
            selectedContact : newSelectedContact,
        })
    }


    filterContacts() {
        let text = this.input.value;
        if (text !== "") {
            this.setState({
                isSearching : true,
                filterContacts : []
            })
            let self = this;
            parseAction("post",config.BASE_URL + '/parse/functions/findContacts',
                {
                    searchText : text
                }
            )
            .then(contacts => {
                if (self.state.isSearching) {
                    self.setState({
                        filterContacts : contacts.result.contacts
                    })
                }

            })
            .catch(error => {
                // TODO handle error when fetching search contact
            });
        } else {
            this.setState({
                filterContacts : [...this.state.tempContacts],
                isSearching : false
            })
        }

        // if (text === "") {
        //     this.setState({
        //         filterContacts : this.state.tempContacts
        //     })
        // }


        // let filtered = [...this.state.tempContacts.filter((data) => {
        //     return data.contact.displayNameLower.includes(text)
        // })]

        // this.setState({
        //     filterContact : filtered
        // })
    }


    render() {
        const List = this.state.filterContacts.map((data, i) => {

            let contact;
            if (this.state.isSearching) {
                contact = data;
            } else {
                contact = data.contact;
            }

            if (!contact) return null;

            return <Item key={i} contact={contact} selectedContact={this.selectedContact.bind(this, contact)}/>
        })

        const Selected = this.state.selectedContact.map((contact, i) => {
            return <Chips key={i} contact={contact} removeSelected={this.removeSelected.bind(this, contact)}/>
        })
        return (
            <div id="addMemberModal" className="modal fade" tabIndex="-1" role="dialog" data-backdrop="false">
                <div className="modal-table">
                    <div className="modal-table-cell">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div ref={h=>this.headerContainer=h} className="modal-header" style={{minHeight: "90px"}}>
                                    {/* <div style={{width: "100%", position: "relative"}}>
                                        <h5 class="modal-title">Add Member</h5>
                                        <button style={{padding: "10px", position: "absolute", top: "3px", right: "0"}} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>

                                    <div style={{position: "absolute", top: "45px", left: "0", right: "0"}} className="Tab-Search-Container">
                                        <i className="fas fa-search"></i>
                                        <input id="search" placeholder="Search Message" ref={i=>this.input=i} type="search" />
                                    </div>
                                    <div>
                                        { Selected }
                                    </div> */}
                                    <div ref={t=>this.topContainer=t}  style={{width: "100%"}}>
                                        <h5 className="hc-primary-text" style={{fontSize: "17px"}}>Add Contact</h5>
                                        <div style={{display: "block"}}>
                                            { Selected }
                                        </div>

                                        <div style={{margin: "10px 0 0 0"}} className="Tab-Search-Container">
                                            <i className="fas fa-search"></i>
                                            <input onChange={this.filterContacts.bind(this)} id="search" placeholder="Search Contact" ref={i=>this.input=i} type="search" />
                                        </div>
                                    </div>
                                    <button style={{padding: "10px", position: "absolute", top: "15px", right: "20px"}} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                    </button>

                                </div>
                                <div className="modal-body" ref={u=>this.ulContainer=u} style={{maxHeight: "400px",minHeight: "300px", overflow: "hidden", overflowY: "scroll", padding: "10px 0"}}>
                                        <div className="list-group">
                                            {List}
                                        </div>
                                </div>
                                <div className="modal-footer">
                                    {this.state.selectedContact.length <= 0 ? null : <button type="button" className="btn btn-hubchart" onClick={this.inviteContacts.bind(this)}>Invite Contact{this.state.selectedContact.length <= 1 ? "" : "s"}</button> }
					    	        <button type="button" className="btn btn-hubchart btn-hubchart-close" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
        )
    }
}


const Chips = (props) => {
    var contact = props.contact
    let contactURL;

    if (typeof contact.picture === "undefined") {
        contactURL = require("../../../assets/images/default.png");
    } else {
        contactURL = contact.picture.url;
    }

    return (
        <div className="chips">
            <img src={contactURL} className="chip-img" alt={contact.displayName}/>
            <span>{contact.firstName}</span>
            <i onClick={() => props.removeSelected()} className="close-chip far fa-times-circle"></i>
        </div>
    )
}

const Item = (props) => {
    let containerStyle = {
        border: "0px solid white",
        paddingLeft: "72px",
        minHeight: "65px",
        height: "65px",
        borderRadius: "0px",
        cursor: "pointer"
    };

    let Image,
            Name,
            Sender,
            TextLine,
            nameStyle = {};

         /**
         * default values for contacts
         */
        let mobileTag,
            BadgeTags;

    var contact = props.contact,
        contactURL;

    Name = contact.firstName +" "+contact.lastName;
    if(!contact.webOnly) {
        mobileTag = <span style={{display: "inline-block", fontSize: "10px", borderRadius: "0.10rem", backgroundColor: "#029AD6", marginRight: "5px", padding: "2px 5px"}} className="badge badge-primary">MOBILE</span>
    }  else {
        if (contact.statusMessage === "" || typeof contact.statusMessage === "undefined") {
            nameStyle.marginTop = "10px";
        }
    }
    if (contact.statusMessage) containerStyle.minHeight = "80px";

    if (typeof contact.picture === "undefined") {
        contactURL = require("../../../assets/images/default.png");
    } else {
        contactURL = contact.picture.url;
    }
    Image = <img src={contactURL} alt="" className="profile-image rounded-circle" />;
    TextLine = <p className="text-content" style={{display: "block", color: " rgb(95, 93, 93)", margin: "0px"}}><span>{Sender}</span>{contact.statusMessage}</p>
    BadgeTags = <p className="text-content">
                {contact.isPrimaryAccount && <span style={{display: "inline-block", fontSize: "10px", borderRadius: "0.10rem", backgroundColor: "#20738D", marginRight: "5px", padding: "2px 5px"}} className="badge badge-primary">MAIN</span>}
                {mobileTag}
            </p>

    return (
        <button onClick={() => props.selectedContact()} className="list-group-item list-group-item-action flex-column align-items-start" style={containerStyle}>
            {Image}
            <p className="name" style={nameStyle}>{Name}</p>
            { TextLine }
            { BadgeTags }
        </button>
    )
}

const mapStateToProps = state => ({
    contacts : state.contact.contacts
})

export default connect(mapStateToProps, {fetchContacts, getContacts})(AddMemberModal);
