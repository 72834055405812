import React, { Component } from "react";
import ms from "pretty-ms";
import Parse from "parse";

const $ = window.$;
export class IdleModal extends Component {
  constructor() {
    super();

    this.state = {
      // setTime: 900000
      setTime: 1000
    };

    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentDidMount() {
    if (this.props.isOpen) {
      $("#idleModal").modal({
        backdrop: "static",
        keyboard: false
      });

      $("#idleModal").modal("show");
    } else {
      $("#idleModal").modal("hide");
    }
    // $("#idleModal").on('hidden.bs.modal', function (e) {
    //     clearInterval(this.timer);
    //     this.props.modalClose();
    // }.bind(this))

    this.startTimeout();
  }

  handleCloseModal() {
    clearInterval(this.timer);
    $("#idleModal").modal("hide");
    this.props.modalClose();
  }

  countdown() {
    let setTime = this.state.setTime;
    setTime = setTime - 1000;
    this.setState({
      setTime: setTime
    });

    if (setTime === 0) {
      clearInterval(this.timer);
      this.logOutUser();
      $("#idleModal").modal("hide");
    }
  }

  logOutUser() {
    Parse.User.logOut().then(() => {
      // localStorage.removeItem("activeTab");
      localStorage.setItem("is_logged_in", false);
      // localStorage.clear();
      window.location.href = "/";
    });
  }

  startTimeout() {
    this.countdown();

    this.timer = setInterval(
      function() {
        this.countdown();
      }.bind(this),
      1000
    );
  }

  confirm() {
    this.props.confirmAction();
    clearInterval(this.timer);
    $("#idleModal").modal("hide");
  }

  render() {
    return (
      <div
        id="idleModal"
        className="modal"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div
              className="modal-body"
              style={{
                maxHeight: "300px"
                // overflow: "hidden",
                // overflowY: "scroll"
              }}
            >
              <h5 style={{ color: "#D84B55" }}>
                You have been idle for a while
              </h5>
              <p>
                You will be AUTOMATICALLY LOGGED OUT in{" "}
                <span style={{ fontWeight: "500" }} className="hc-primary-text">
                  {ms(this.state.setTime)}
                </span>
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-hubchart"
                // data-dismiss="modal"
                onClick={() => this.handleCloseModal()}
              >
                RESET AUTO LOGOUT
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default IdleModal;
