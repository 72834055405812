import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { parseAction } from "../../../../actions/REST";
import moment from "moment";
import {
  deleteOrder,
  createNewGroupedList,
  getGroupedLists,
  updateGroupedList,
  deleteGroupedList
} from "../../../../actions/PatientAction";
import Loader from "../../../Common/Loader";
import Notify from "../../../Common/Notify";
import config from "../../../../config";
import { computeAge } from "../../../../helper/util";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "../../../Pages/Login/Login.css";
import "../../../Conversation/Setting/SettingModal.css";

import AddListModal from "../../../Conversation/Setting/ListModal/AddListModal";
import PatientInfo from "../PatientInfo";
import Logo from "../../../../assets/images/logo-blue.png";

const defaultSelectedList = {
  subject: "",
  body: "",
  createdAt: new Date()
};
const $ = window.$;

function GroupedListsView({ threadId = "" }) {
  const { groupedLists, isGroupedListsLoading } = useSelector(
    mySelector,
    shallowEqual
  );
  const defaultOrder = {
    prescription: "",
    diagnosticStudies: "",
    referrals: "",
    medicalSupplies: ""
  };
  const dispatch = useDispatch();
  const [method, setMethod] = useState("create");
  const [patientInfo, setPatientInfo] = useState(false);
  const [listModalShown, setListModalShown] = useState(false);
  const [addListModalShown, setAddListModalShown] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedList, setSelectedList] = useState([
    {
      subject: "Test Subject",
      body:
        "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit."
    }
  ]);
  const [selectedTempList, setSelectedTempList] = useState([
    { ...defaultSelectedList }
  ]);

  function fetchPatientInfo(id) {
    parseAction("get", config.BASE_URL + "/parse/classes/Circle", {
      where: {
        objectId: id
      }
    })
      .then(result => {
        if (result.results.length === 0) {
          return;
        }
        var data = result.results[0];
        setPatientInfo(data);
      })
      .catch(error => {
        console.log(error);
      });
  }

  function handleCreateList() {}

  function handleListOrder() {
    handleListModalShown(true);
  }

  function handleUpdateList(item, index) {
    setMethod("update");
    setSelectedIndex(index);
    setSelectedTempList([{ ...item }]);
    handleSetAddListModalShown();
  }

  function handleAddList() {
    setMethod("create");
    setSelectedTempList([{ ...defaultSelectedList }]);
    handleSetAddListModalShown();
  }

  function removeItem(item, patientId) {
    deleteOrder(item.objectId, patientId, () => {
      Notify.success("List successfully deleted.");
    })(dispatch);
  }

  function handleListModalShown(bool) {
    setListModalShown(bool);
  }

  function handleChangeTextAreaDetails(e, i) {
    const { value } = e.target;
    let newArr = [...selectedTempList];
    newArr[i].body = value;

    setSelectedTempList([...newArr]);
  }

  function handleChangeSubjectDetails(e, i) {
    const { value } = e.target;
    let newArr = [...selectedTempList];
    newArr[i].subject = value;

    setSelectedTempList([...newArr]);
  }

  function closeAddListModal() {
    setAddListModalShown(false);
    setSelectedTempList([]);
    setSelectedIndex(null);
  }

  function addTextAreaField() {
    setSelectedTempList([
      {
        ...defaultSelectedList,
        body: ""
      },
      ...selectedTempList
    ]);
  }

  function removeTextAreaField(i) {
    let newArr = selectedTempList;
    newArr.splice(i, 1);
    setSelectedTempList([...newArr]);
  }

  function handleCreateGroupedLIsts() {
    const filteredSelectedTempList = selectedTempList.filter(
      item => item.subject || item.body
    );
    const promises = filteredSelectedTempList.map(item => {
      return createNewGroupedList(item, threadId)(dispatch);
    });

    Promise.all(promises).then(res => {
      getGroupedLists(threadId)(dispatch);
    });
  }

  function handleSaveTempList(list) {
    if (method === "create") {
      handleCreateGroupedLIsts();
    } else {
      let newSelectedList = [...selectedList];
      newSelectedList[0].subject = selectedTempList[0].subject;
      newSelectedList[0].body = selectedTempList[0].body;

      updateGroupedList(newSelectedList[0], list[0].objectId, threadId, () => {
        Notify.success("Grouped list successfully updated");
      })(dispatch);
    }
    closeAddListModal();
  }

  function handleSetAddListModalShown() {
    setAddListModalShown(true);
    setTimeout(() => {
      $("#patientAddListModal").modal({
        backdrop: false,
        keyboard: false
      });
      $("#patientAddListModal").modal("show");
    }, 100);
  }

  function removeListItem(item) {
    deleteGroupedList(item.objectId, threadId, () => {
      Notify.success("Grouped list successfully deleted");
    })(dispatch);
  }

  useEffect(() => {
    if (threadId) {
      componentDidMount();
    }
  }, [threadId]);

  function componentDidMount() {
    fetchPatientInfo(threadId);
    getGroupedLists(threadId)(dispatch);
  }

  return (
    <div className="progress-notes-container pn-con">
      <div className="container mb-15 pn-title-flex">
        <span className="navbar-brand">
          <img
            className="d-sm-inline"
            width="40"
            height="40"
            src={Logo}
            alt="H"
          ></img>
          <span
            className="hc-primary-text d-none d-md-inline"
            style={{ fontWeight: "500" }}
          >
            HUBCHART
          </span>
        </span>
        <div className="pn-patient-details-flex">
          <div>
            <span>{patientInfo.name || "--"}</span>
            <span>LISTS AND TABLES</span>
          </div>
          {(typeof (patientInfo.image || {}).url === "undefined" ||
            (patientInfo.image || {}).url === "") && (
            <img src={require("../../../../assets/images/default.png")} />
          )}
          {(patientInfo.image || {}).url && (
            <img src={(patientInfo.image || {}).url} />
          )}
        </div>
        {/* <span className="pn-title">LISTS AND TABLES</span> */}
      </div>
      <div className="pn-template-container">
        <div className="container pb-10">
          <PatientInfo patientInfo={patientInfo} />
        </div>
      </div>
      <div class="container">
        <div className="filter-view" style={{ margin: "15px 0px" }}>
          <div className="search-flex search-identifier">
            <div />
            <button
              type="button"
              className="btn btn-hubchart btn-sm btn-hubchart-primary"
              onClick={() => handleAddList()}
              style={{ margin: "0px", height: "33px", width: "170px" }}
            >
              <i className="fas fa-plus"></i> Add
            </button>
          </div>
        </div>
        <div className="grouped-list" style={{ overflowX: "auto" }}>
          {groupedLists.length > 0 && (
            <div className="setting-modal-headers">
              <span>List</span>
              <span>Date Entered</span>
              <div />
              <span>Action</span>
            </div>
          )}
          <div className="setting-modal-item-container">
            {groupedLists.map((item, i) => (
              <div
                className="setting-modal-item"
                key={`po-${moment(new Date()).format("lll")}-${i}`}
              >
                <div>
                  <strong>{item.subject}</strong>
                  <div
                    style={{
                      margin: "0px 10px",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    {item.body}
                  </div>
                  <br />
                </div>
                <span>
                  <span>{moment(item.createdAt).format("lll")}</span>
                </span>
                <div />
                <span>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    title="Update List"
                    onClick={() => handleUpdateList(item, i)}
                  >
                    <i className="fas fa-edit"></i>
                  </button>
                  &nbsp;
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    title="Remove List"
                    style={{ width: "33.75px" }}
                    onClick={() => removeListItem(item)}
                  >
                    <i className="fas fa-trash"></i>
                  </button>
                </span>
              </div>
            ))}
          </div>
        </div>
        {groupedLists.length === 0 && (
          <div className="default-view">
            <span>No List Found.</span>
          </div>
        )}
        {addListModalShown && (
          <AddListModal
            handleChangeTextAreaDetails={handleChangeTextAreaDetails}
            handleChangeSubjectDetails={handleChangeSubjectDetails}
            handleCloseAddListModal={closeAddListModal}
            addTextAreaField={addTextAreaField}
            removeTextAreaField={removeTextAreaField}
            saveTempList={handleSaveTempList}
            selectedTempList={selectedTempList}
            method={method}
          />
        )}
        <Loader isLoading={isGroupedListsLoading} />
      </div>
    </div>
  );
}

const mySelector = state => ({
  groupedLists: state.patient.groupedLists,
  isGroupedListsLoading: state.patient.isGroupedListsLoading
});

export default GroupedListsView;
