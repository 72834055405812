import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { checkUncheckContact } from '../../../actions/ContactAction';

function SelectControl(props) {
    const dispatch = useDispatch();
    const { contact } = props;
    const { isChecked = false } = contact;
    const { 
        contacts, 
        filterResult,
        otherUsersResult,
        isSearchingContact,
        selectedContacts,
    } = useSelector(state => ({
        contacts: state.contact.contacts,
        filterResult: state.contact.filterResult,
        otherUsersResult : state.contact.otherUsersResult,
        selectedContacts : state.contact.selectedContacts,
        isSearchingContact : state.contact.isSearchingContact,
    }), shallowEqual);

    function handleCheck(e) {
        // e.stopPropagation();
        const { checked } = e.target;
        checkUncheckContact(contact, { contacts, filterResult, otherUsersResult, selectedContacts, isSearchingContact }, checked)(dispatch);
    }

    return (
        <div style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }}>
            <label class="cb-container">
                {/* <input  type="checkbox" checked={isChecked} /> */}
                <div class="round-small" onClick={() => handleCheck({ target: { checked: !isChecked } })}>
                    <input
                        type="checkbox"
                        id="checkbox"
                        id="selectAll"
                        name="selectAll"
                        checked={isChecked}
                    />
                    <label for="checkbox"></label>
                </div>
                <span class="checkmark"></span>
            </label>
        </div>
    );
}

export default SelectControl;