import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import NetworkSignal from './NetworkSignal';
import MicIndicator from './MicIndicator';
import CamIndicator from './CamIndicator';
import DataTrack from './DataTrack.js';
import defaultImage from '../../../assets/images/default.png';
import useRoom from './useRoom';
import useConference from './useConference';
import Notify from './Notify';

const Participant = ({
  onMessage,
  setPinnedParticipant,
  remoteScreenSharer,
  pinnedParticipant,
  onScreenUnshared,
  onScreenShared,
  screenShared,
  participant,
  user,
}) => {

  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [dataTrack, setDataTrack] = useState(null);
  const [audioShared, setAudioShared] = useState(false);
  const [videoShared, setVideoShared] = useState(false);
  const [networkQualityLevel, setNetworkQualityLevel] = useState(0);
  const [connectionStatus, setConnectionStatus] = useState(0);

  const [imgUrl, setImgUrl] = useState('');
  const [thumbUrl, setThumbUrl] = useState('');
  const [displayName, setDisplayName] = useState('');

  const videoRef = useRef();
  const audioRef = useRef();

  const { sendData } = useRoom();
  const { isHost } = useConference();

  const isPinned = useMemo(() => {

    if (!participant) return false;

    return participant === pinnedParticipant;

  }, [participant, pinnedParticipant]);

  const videoAvailable = useMemo(() => {
    return !!videoTracks.find(track => track.name != 'screen');
  }, [videoTracks]);

  const audioAvailable = useMemo(() => {
    return !!audioTracks.length;
  }, [audioTracks]);

  const handlePin = useCallback((isPinned) => {

    setPinnedParticipant(isPinned ? participant : null);

  }, [participant]);

  const handleToggleMic = useCallback((value) => {

    const type = value ? 'mic_request' : 'mic_disable';

    const data = {
      type,
      identities: [user.objectId],
      mic: value,
    };
    sendData(data);

    const message = value ? 'Requesting mic for' : 'Turning off mic for';

    Notify.show(`${message} ${user.displayName}`);

  }, [user]);

  const handleToggleCam = useCallback((value) => {

    const type = value ? 'cam_request' : 'cam_disable';

    const data = {
      type,
      identities: [user.objectId],
      cam: value,
    };
    sendData(data);

    const message = value ? 'Requesting cam for' : 'Turning off cam for';

    Notify.show(`${message} ${user.displayName}`);

  }, [user]);

  useEffect(() => {

    if (!participant) return;

    console.log('Participant mounted: ', participant.identity);

    const screenShared = participant => {
      onScreenShared(participant);
    };

    const screenUnshared = participant => {
      onScreenUnshared(participant);
    };

    const trackSubscribed = track => {

      console.log('trackSubscribed: ' + track.kind);

      if (track.kind === 'video') {

        setVideoTracks(videoTracks => [...videoTracks, track]);

        if (track.name === 'screen') screenShared(participant);
      }
      else if (track.kind === 'data') {
        setDataTrack(track);
      }
      else {
        setAudioTracks(audioTracks => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = track => {

      console.log('trackUnsubscribed: ' + track.kind);

      if (track.kind === 'video') {

        setVideoTracks(videoTracks => videoTracks.filter(v => v !== track));

        if (track.name === 'screen') screenUnshared(participant);

      }
      else if (track.kind === 'data') {
        setDataTrack(null);
      }
      else {
        setAudioTracks(audioTracks => audioTracks.filter(a => a !== track));
      }
    };

    const trackDisabled = track => {
      if (track.kind === 'video') {
        setVideoShared(false);
      } else {
        setAudioShared(false);
      }
    };

    const trackEnabled = track => {
      if (track.kind === 'video') {
        setVideoShared(true);
      } else {
        setAudioShared(true);
      }
    };

    const networkQualityLevelChanged = networkQualityLevel => {
      setNetworkQualityLevel(networkQualityLevel);
    };

    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    participant.on('trackDisabled', trackDisabled);
    participant.on('trackEnabled', trackEnabled);

    participant.on('networkQualityLevelChanged', networkQualityLevelChanged);

    // Before unmounting component,
    return () => {

      console.log('Participant unmounted: ', participant.identity);

      // Reset audio and video tracks
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    // Set the default value of videoShared
    // If at least one VideoTrack is enabled
    setVideoShared(videoShared => {

      const enabledVideoTrack = videoTracks.find(track => {
        return track.isEnabled;
      });

      return !!enabledVideoTrack;
    });
  }, [participant, videoTracks]);

  useEffect(() => {
    // Set the default value of videoShared
    // If at least one VideoTrack is enabled
    setAudioShared(audioShared => {

      const enabledAudioTrack = audioTracks.find(track => {
        return track.isEnabled;
      });

      return !!enabledAudioTrack;
    });
  }, [participant, audioTracks]);

  useEffect(() => {

    videoTracks.forEach(videoTrack => {
      videoTrack.attach(videoRef.current);
    })

    return () => {
      videoTracks.forEach(videoTrack => {
        videoTrack.detach(videoRef.current);
      });
    };
  }, [videoTracks]);

  useEffect(() => {

    audioTracks.forEach(audioTrack => {
      audioTrack.attach(audioRef.current);
    })

    return () => {
      audioTracks.forEach(audioTrack => {
        audioTrack.detach(audioRef.current);
      });
    };
  }, [audioTracks]);

  useEffect(() => {

    if (participant) setConnectionStatus(1);

    return () => {
      setConnectionStatus(-1);
    }
  }, [participant]);

  useEffect(() => {

    // If participant is present, set signal level to maximum
    if (participant) {
      setNetworkQualityLevel(5);
    }

    return () => {

      // Then set to zero upon leaving
      setNetworkQualityLevel(0);
    }

  }, [participant]);

  useEffect(() => {
    if (user) {
      const { imgUrl, thumbUrl, displayName } = user;
      setImgUrl(imgUrl);
      setThumbUrl(thumbUrl);
      setDisplayName(displayName);
    }
    else {
      setImgUrl(defaultImage);
      setThumbUrl(defaultImage);
      setDisplayName('Guest');
    }
  }, [user]);

  const zIndex = videoShared ? 1 : 2;

  return (<>
    {remoteScreenSharer === participant ?
      <ScreenShareAlert displayName={displayName} /> : ''
    }
    {!remoteScreenSharer ?
      <Controls>
        {!remoteScreenSharer ?
          <PinButton isPinned={isPinned} onPin={handlePin} />
          : ''
        }
        {isHost ?
          <ControlDropdown>
            <MicButton audioShared={audioShared} onToggleMic={handleToggleMic} />
            <div className="dropdown-divider"></div>
            <CamButton videoShared={videoShared} onToggleCam={handleToggleCam} />
          </ControlDropdown>
          : ''
        }
      </Controls>
      : ''
    }
    <div className="camera-container">
      <video ref={videoRef} autoPlay={true} />
      <audio ref={audioRef} autoPlay={true} muted={!audioShared} />
      <DataTrack track={dataTrack} onMessage={onMessage} />
    </div>
    <div className="user-thumbnail">
      <div className="px-2 participant-name">
        <NetworkSignal level={networkQualityLevel} />
        {displayName}

        <span className="float-right">
          <CamIndicator available={videoAvailable} enabled={videoShared} />
          <MicIndicator available={audioAvailable} enabled={audioShared} />
        </span>
      </div>
    </div>
    <div className="camera-placeholder" style={{ zIndex }}>
      <div className="profile-image-wrapper-x">
        <div className="field-content">
          <img src={imgUrl} alt="" className="profile-image rounded-circle img" />
        </div>
      </div>
      <img src={thumbUrl} alt="" className="bg" />
    </div>
  </>);
};

const Controls = ({ children }) => {

  const styles = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    zIndex: '4',
    display: 'flex',
  };

  return (
    <div className="" style={styles}>
      {children}
    </div>
  )
};

const PinButton = ({ isPinned, onPin }) => {
  return isPinned ?
    <button onClick={(e) => onPin(false)} className="btn btn-primary btn-sm btn-circle mr-2">
      <span class="fa-stack">
        <i className="fas fa-thumbtack fa-stack-1x" />
        <i className="fas fa-slash fa-stack-1x" />
      </span>
    </button> :
    <button onClick={(e) => onPin(true)} className="btn btn-light btn-sm btn-circle mr-2">
      <i className="fas fa-thumbtack" />
    </button>;
}

const ControlDropdown = ({ children }) => {

  const btnStyles = {
    fontSize: '12px',
  }

  return (
    <div className="dropdown">
      <button className="btn btn-light btn-sm btn-circle text-primary"
        style={btnStyles}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false">
        <i className="fas fa-cog"></i>
      </button>
      <div className="dropdown-menu dropdown-menu-right">
        {children}
      </div>
    </div>
  )
}

const MicButton = ({ audioShared, onToggleMic }) => {

  const iconClass = audioShared ? 'fas fa-fw fa-microphone-slash mr-1 text-danger' :
    'fas fa-fw fa-microphone mr-1 text-primary';

  const text = audioShared ? 'Turn off mic' : 'Request mic';

  return (
    <a onClick={e => onToggleMic(!audioShared)} className="dropdown-item" href="#!">
      <i className={iconClass}></i>
      {text}
    </a>
  );
}

const CamButton = ({ videoShared, onToggleCam }) => {

  const iconClass = videoShared ? 'fas fa-fw fa-video-slash mr-1 text-danger' :
    'fas fa-fw fa-video mr-1 text-primary';

  const text = videoShared ? 'Turn off cam' : 'Request cam';

  return (
    <a onClick={e => onToggleCam(!videoShared)} className="dropdown-item" href="#!">
      <i className={iconClass}></i>
      {text}
    </a>
  );
}

const ScreenShareAlert = ({ displayName }) => {

  const styles = {
    position: 'absolute',
    zIndex: '3',
    color: 'black',
    textAlign: 'center',
    width: '100%',
    background: '#02ff09',
  }

  return (
    <div style={styles}>
      {displayName} is sharing screen
    </div>
  );
}

function trackpubsToTracks(trackMap) {
  return Array.from(trackMap.values())
    .map(publication => publication.track)
    .filter(track => track !== null);
}

export default Participant;
