import React from "react";
import { useDispatch } from "react-redux";
import { launchTelemed } from "../../../actions/ConferenceAction";
import { showAccount } from "../../../actions/GlobalAction";
import { linkToTodos } from "../../../actions/ThreadAction";
import hipaaBadge from "../../../assets/images/HIPAA_Badge.png";

const Sidebar = ({ circleLabels, todoCount }) => {
  const dispatch = useDispatch();

  const handleStartTelemed = () => {
    dispatch(launchTelemed());
  };

  const handleOpenAccount = () => {
    dispatch(showAccount("corporate"));
  };

  

  const handleClickTodos = () => {
    dispatch(linkToTodos());
  };

  return (
    <div
      className="navbar-nav bg-white sidebar accordion d-none d-lg-flex flex-column"
      id="accordionSidebar"
    >
      <div className="sidebar-row">
        <button className="btn btn-success w-100 rounded-pill">
          <i className="fas fa-cart-plus mr-1"></i>
          <span className="font-weight-bold">Subscriptions</span>
        </button>
      </div>

      <hr className="my-0 mx-2" />

      <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
        <span>Quick actions</span>
      </h6>

      <div className="list-group list-group-flush">
        <a
          onClick={(e) => handleStartTelemed()}
          className="list-group-item sidebar-item"
          href="#!"
        >
          <i className="fas mr-1 fa-video"></i>
          Start meeting
        </a>
        <a
          onClick={(e) => handleOpenAccount()}
          className="list-group-item sidebar-item"
          href="#!"
        >
          <i className="fas mr-1 fa-external-link-alt"></i>
          Corporate account
        </a>
        {/* <a onClick={(e) => handleClickTodos(e)} className="list-group-item sidebar-item d-flex align-items-center" href="#!">
          <i className="fas mr-1 fa-tasks"></i>
          My to-dos
          <span className="badge badge-pill badge-danger ml-auto">{todoCount}</span>
        </a> */}
      </div>

      <div className="sidebar-row shadow-top" style={{ marginTop: 'auto' }}>
        <img className="img-fluid hipaa-badge-image" src={hipaaBadge}></img>
      </div>
    </div>
  );
};

export default Sidebar;
