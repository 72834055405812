import React from "react";
import "./index.css";

function AddendumNoteComponent({ addendumNote = "" }) {
  return (
    <div className="card custom-card">
      <h5 className="card-header ">
        <strong>ADDENDUM NOTE</strong>
      </h5>
      <div className="card-body">
        <div className="custom-form-group-container pe-text">
          {addendumNote || ""}
        </div>
      </div>
    </div>
  );
}

export default AddendumNoteComponent;
