import React, { Component } from 'react'

export class Instruction extends Component {
    render() {
        return (
            <div className="Video-Container">
                <div className="Instruction-Container">


                    <p style={{fontSize: "23px"}}>Please allow microphone <i className="fas fa-microphone hc-primary-text"></i> and camera <i className="fas fa-camera hc-primary-text"></i></p>
                </div>

            </div>
        )
    }
}

export default Instruction
