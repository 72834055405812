import React, { Component } from 'react';
import Loading from '../../Pages/Signup/Loading';
import Parse from 'parse';
import imageCompression from 'browser-image-compression';
import { connect } from "react-redux";
import {
  deleteAccount
} from "../../../actions/GlobalAction";
import ConfirmDeleteModal from './ConfirmDeleteModal';

const $ = window.$;
export class AccountContainer extends Component {
  constructor() {
    super();

    const professionList = [
      'Physician ',
      'Psychiatrist',
      'Podiatrist',
      'Psychologist ',
      'Physician Assistant ',
      'Nurse practitioner',
      'Nurse, RN',
      'Nurse, LVN',
      'Social Worker',
      'Pharmacist',
      'Medical assistant',
      'Pharmacy technician',
      'Physical / Occupational Therapist ',
      'Respiratory Therapist',
      'Dietitian, RD',
      'Dentist',
      'Dental Assistant',
      'Audiologist',
      'Ultrasound Tech / Sonographer',
      'Speech-language pathology',
      'Optometrist',
      'Chiropractor',
      'Orthotist',
      'Paramedic',
      'EMT',
      'Medical technologists',
      'Administration',
      'Healthcare Office worker',
      'Others',
    ];

    this.state = {
      user: '',
      profilePictureURL: '',
      willUpdateUserProfile: false,
      savingUserProfile: false,
      willUpdateCompany: false,
      willUpdatePassword: false,
      savingPassword: false,
      savingCompany: false,
      willUpload: false,
      isUploading: false,
      password: '',
      confirmPassword: '',
      passwordError: false,
      passwordErrorMessage: '',
      wrongPassword: false,
      changePasswordSuccess: false,
      failedChangePassword: false,
      emailError: false,
      didChangeEmail: false,
      bmonth: '',
      bday: '',
      byear: '',
      professionList,
      isModalOpened: false,
    };

    this.updateProfile = this.updateProfile.bind(this);
    this.updateCompany = this.updateCompany.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.fileHandleChange = this.fileHandleChange.bind(this);
    this.handleDeleteAccount = this.handleDeleteAccount.bind(this);
  }

  componentWillMount() {
    let image = this.state.profilePictureURL;
    let picture = Parse.User.current().get('picture');
    if (typeof picture != 'undefined') {
      image = picture._url;
    } else {
      image = require('../../../assets/images/default.png');
    }

    const dob = Parse.User.current().get('dateOfBirth') || '';

    const [bmonth, bday, byear] = dob.split('/');

    this.setState({
      user: Parse.User.current(),
      profilePictureURL: image,
      bmonth,
      bday,
      byear,
    });
  }

  willUpdateUserProfile() {
    this.setState({
      willUpdateUserProfile: true,
    });
  }

  willUpdateCompany() {
    this.setState({
      willUpdateCompany: true,
    });
  }

  willUpdatePassword() {
    this.setState({
      willUpdatePassword: true,
      failedChangePassword: false,
      changePasswordSuccess: false,
    });
  }

  cancelUpdateProfile(e) {
    e.preventDefault();
    this.setState({
      willUpdateUserProfile: false,
    });
  }

  cancelUpdateCompany() {
    this.setState({
      willUpdateCompany: false,
    });
  }

  cancelUpdatePassword() {
    this.setState({
      willUpdatePassword: false,
    });
  }

  updateProfile(e) {
    e.preventDefault();

    this.setState({
      savingUserProfile: true,
      emailError: false,
      didChangeEmail: false,
    });

    let firstName = this.refs.firstName.value,
      firstNameCap = firstName.charAt(0).toUpperCase() + firstName.slice(1),
      lastName = this.refs.lastName.value,
      lastNameCap = lastName.charAt(0).toUpperCase() + lastName.slice(1),
      email = this.refs.email.value,
      title = this.refs.title.value.toUpperCase(),
      middleName = this.refs.middleName.value,
      middleNameCap = middleName.charAt(0).toUpperCase() + middleName.slice(1);

    if (firstName === '' || lastName === '') {
      this.setState({
        savingUserProfile: false,
        // willUpdateUserProfile: false,
      });
      return;
    }

    let willChangeEmail = false;

    let displayName = firstNameCap + ' ' + lastNameCap;

    let currentUser = Parse.User.current();
    currentUser.set('firstName', firstNameCap);
    currentUser.set('lastName', lastNameCap);
    currentUser.set('middleName', middleNameCap);
    currentUser.set('firstNameLower', firstName.toLowerCase());
    currentUser.set('lastNameLower', lastName.toLowerCase());
    currentUser.set('middleNameLower', middleName.toLowerCase());
    currentUser.set('displayName', displayName);
    currentUser.set('displayNameLower', displayName.toLowerCase());
    currentUser.set('title', title);

    const { bmonth, bday, byear } = this.state;

    if (bmonth && bday && byear) {
      currentUser.set('dateOfBirth', [bmonth, bday, byear].join('/'));
    }

    if (Parse.User.current().get('email') !== email) {
      currentUser.set('email', email);
      willChangeEmail = true;
    }
    currentUser.save().then(
      (user) => {
        this.setState({
          savingUserProfile: false,
          willUpdateUserProfile: false,
          user: Parse.User.current(),
          didChangeEmail: willChangeEmail,
        });
      },
      (error) => {
        if (error.code === 203) {
          this.setState({
            emailError: true,
            savingUserProfile: false,
          });
        }
      }
    );
  }

  updateCompany(e) {
    e.preventDefault();

    this.setState({
      savingCompany: true,
    });

    let workPlace = this.refs.workPlace.value,
      workPlaceType = this.refs.workPlaceType.value,
      profession = this.refs.profession.value;

    let currentUser = Parse.User.current();
    currentUser.set('workPlace', workPlace);
    currentUser.set('workPlaceType', workPlaceType);
    currentUser.set('profession', profession);
    currentUser.save().then(
      (user) => {
        this.setState({
          savingCompany: false,
          willUpdateCompany: false,
          user: Parse.User.current(),
        });
      },
      (error) => {}
    );
  }

  updatePassword(e) {
    e.preventDefault();
    this.setState({
      wrongPassword: false,
      savingPassword: true,
    });

    let currentUser = Parse.User.current();
    let username = currentUser.get('username');
    let currentPassword = this.refs.current_password.value;

    if (
      this.state.confirmPassword !== this.state.password ||
      this.state.passwordError ||
      currentPassword === ''
    ) {
      this.setState({
        savingPassword: false,
      });
      return;
    }

    const user = Parse.User.logIn(username, currentPassword);
    user
      .then(() => {
        currentUser.set('password', this.state.password);
        currentUser.save().then(
          (user) => {
            this.setState({
              changePasswordSuccess: true,
              willUpdatePassword: false,
              savingPassword: false,
              password: '',
              confirmPassword: '',
            });
          },
          (error) => {
            this.setState({
              failedChangePassword: true,
              willUpdatePassword: false,
              savingPassword: false,
              password: '',
              confirmPassword: '',
            });
          }
        );
      })
      .catch((error) => {
        this.setState({
          wrongPassword: true,
          savingPassword: false,
        });
      });
  }

  openFileImage() {
    $(this.refs.profileImage).trigger('click');
  }

  async fileHandleChange(e) {
    const imageFile = e.target.files[0];
    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(
        'compressedFile instanceof Blob',
        compressedFile instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB
      const URLFile = await imageCompression.getDataUrlFromFile(compressedFile);
      this.setState({
        file: URLFile,
        willUpload: true,
      });

      this.readURL(compressedFile);
    } catch (error) {
      console.log(error);
    }
  }

  readURL(file) {
    if (file) {
      var reader = new FileReader();

      reader.onload = function(e) {
        this.setState({
          profilePictureURL: e.target.result,
        });
      }.bind(this);

      reader.readAsDataURL(file);
    }
  }

  uploadPicture() {
    if (!this.state.willUpload) return;

    this.setState({
      isUploading: true,
    });

    let file = this.state.file;
    // console.log(file);
    // // return;
    // let fileType = file.type;
    // let fileName = file.name.replace(/[^\w\s]/gi, '');
    // fileName = fileName.replace(/[\s,.]/g,"_");
    // let parseFile = new Parse.File(fileName, file, fileType);
    let self = this;
    var parseFile = new Parse.File('profilePicture.jpg', { base64: file });
    parseFile.save().then(
      function() {
        let currentUser = Parse.User.current();
        currentUser.set('picture', parseFile);
        currentUser.save().then(
          (user) => {
            self.setState({
              isUploading: false,
            });
          },
          (error) => {}
        );
      },
      function(error) {
        console.log(error);
      }
    );
  }

  handleConfirmPasswordChange(e) {
    this.setState({
      confirmPassword: e.target.value,
    });
  }

  handlePasswordChange(e) {
    let wsRegex = '/^sw+,sw+!s*/';
    let firstChar = e.target.value[0];
    let lastChar = e.target.value[e.target.value.length - 1];
    let hasPasswordError = false;
    let passwordErrorMessage = [];
    if (e.target.value.length !== 0) {
      if (e.target.value.length <= 5) {
        hasPasswordError = true;
        passwordErrorMessage = [
          ...passwordErrorMessage,
          '* Minimum of 6 character',
        ];
      }
    }

    if (firstChar === ' ' || lastChar === ' ') {
      hasPasswordError = true;
      passwordErrorMessage = [
        ...passwordErrorMessage,
        '* Space are not allowed at the beginning and end of your password.',
      ];
    }
    if (hasPasswordError) {
      this.setState({
        passwordError: hasPasswordError,
        passwordErrorMessage: passwordErrorMessage,
      });
    } else {
      this.setState({
        passwordError: false,
        passwordErrorMessage: [],
      });
    }
    this.setState({
      password: e.target.value,
    });
  }

  handleDobChange(e, key) {
    const value = String(e.target.value);

    const maxLength = {
      bday: 2,
      bmonth: 2,
      byear: 4,
    };

    if (value.length > maxLength[key]) return;

    this.setState({
      [key]: value,
    });
  }

  handleDeleteAccount() {
    this.props.deleteAccount();
    
    Parse.User.logOut().then(() => {
      localStorage.setItem("is_logged_in", false);
      window.location.href = "/";
    });
  }

  render() {
    return (
      <div className="Account-Setting-Container" style={{ minWidth: '450px' }}>
        <div
          style={{
            width: '126px',
            position: 'relative',
            margin: '0 auto',
            display: 'block',
          }}
        >
          <img
            style={{
              border: '3px solid white',
              width: '126px',
              height: '126px',
              borderRadius: '30px',
            }}
            src={this.state.profilePictureURL}
          />
          {this.state.isUploading ? (
            <p style={{ textAlign: 'center', color: 'rgb(74, 173, 82)' }}>
              Uploading...
            </p>
          ) : (
            <div className="Upload-Button-Container">
              <i
                onClick={this.openFileImage.bind(this)}
                className="fas fa-camera upload-button"
              ></i>
              <input
                ref="profileImage"
                className="file-upload"
                type="file"
                accept="image/jpg,image/png,image/jpeg"
                onChange={this.fileHandleChange}
              />
            </div>
          )}
          {this.state.willUpload && !this.state.isUploading && (
            <button
              onClick={this.uploadPicture.bind(this)}
              className="Confirm-Button"
              style={{ color: '#2194f3', marginTop: '10px' }}
            >
              Upload Picture
            </button>
          )}
        </div>
        <div className="Form-Container">
          <div className="d-flex justify-content-between">
            <label>Licenses and Certification</label>
            <span
              style={{
                fontSize: '.9rem',
                color: 'rgb(74, 173, 82)',
                cursor: 'pointer',
              }}
            >
              <i className="fas fa-edit"></i> Edit
            </span>
          </div>
          <hr></hr>
          <div className="d-flex justify-content-between">
            <label>Services</label>
            <span
              style={{
                fontSize: '.9rem',
                color: 'rgb(74, 173, 82)',
                cursor: 'pointer',
              }}
            >
              <i className="fas fa-edit"></i> Edit
            </span>
          </div>
          <hr></hr>
          <div className="d-flex justify-content-between">
            <label>Skills</label>
            <span
              style={{
                fontSize: '.9rem',
                color: 'rgb(74, 173, 82)',
                cursor: 'pointer',
              }}
            >
              <i className="fas fa-edit"></i> Edit
            </span>
          </div>
          <hr></hr>
          <div className="d-flex justify-content-between">
            <label>Experience</label>
            <span
              style={{
                fontSize: '.9rem',
                color: 'rgb(74, 173, 82)',
                cursor: 'pointer',
              }}
            >
              <i className="fas fa-edit"></i> Edit
            </span>
          </div>
          <hr></hr>
          <div className="d-flex justify-content-between">
            <label>Education</label>
            <span
              style={{
                fontSize: '.9rem',
                color: 'rgb(74, 173, 82)',
                cursor: 'pointer',
              }}
            >
              <i className="fas fa-edit"></i> Edit
            </span>
          </div>
          <hr></hr>
          <div className="d-flex justify-content-between">
            <label>Social Media</label>
            <span
              style={{
                fontSize: '.9rem',
                color: 'rgb(74, 173, 82)',
                cursor: 'pointer',
              }}
            >
              <i className="fas fa-edit"></i> Edit
            </span>
          </div>
          <hr></hr>
          <form
            onSubmit={this.updateProfile}
            style={{ position: 'relative', marginTop: '50px' }}
          >
            <h5 style={{ fontSize: '1rem' }}>User Profile</h5>
            {this.state.didChangeEmail && (
              <span style={{ color: 'rgb(74, 173, 82)', fontSize: '13px' }}>
                Let us know this email belongs to you. Verify the email sent to{' '}
                {Parse.User.current().get('email')}
              </span>
            )}
            {!this.state.willUpdateUserProfile && (
              <span
                onClick={this.willUpdateUserProfile.bind(this)}
                className="Update-Detail-Button"
              >
                <i className="fas fa-edit"></i> Edit
              </span>
            )}
            <div className="form-group">
              <span
                style={{
                  fontSize: '13px',
                  color: '#00000096',
                  fontWeight: '500',
                }}
              >
                Phone Number
              </span>
              {this.state.willUpdateUserProfile ? (
                <div className="card">
                  <input
                    style={{ textTransform: 'capitalize' }}
                    type="text"
                    placeholder="Phone number"
                    defaultValue={this.state.user.get('username')}
                    disabled={true}
                    required
                  />
                  <i className="fas fa-user hc-primary-text"></i>
                </div>
              ) : (
                <p style={{ marginLeft: '30px' }}>
                  +{this.state.user.get('username')}
                </p>
              )}
            </div>
            <div className="form-group">
              <span
                style={{
                  fontSize: '13px',
                  color: '#00000096',
                  fontWeight: '500',
                }}
              >
                Email address
              </span>
              {this.state.willUpdateUserProfile ? (
                <div className="card">
                  <input
                    ref="email"
                    type="email"
                    id="email"
                    placeholder="your@email.here"
                    defaultValue={this.state.user.get('email')}
                    disabled={true}
                  />
                  <i className="fas fa-envelope hc-primary-text"></i>
                </div>
              ) : (
                <p style={{ marginLeft: '30px' }}>
                  {this.state.user.get('email')}
                </p>
              )}
              {this.state.emailError && (
                <span
                  style={{
                    fontSize: '13px',
                    color: 'rgb(216, 75, 85)',
                    fontWeight: '500',
                  }}
                >
                  There is an existing account associated with{' '}
                  {this.refs.email.value}.
                </span>
              )}
            </div>
            <div className="form-group">
              <span
                style={{
                  fontSize: '13px',
                  color: '#00000096',
                  fontWeight: '500',
                }}
              >
                First Name
              </span>
              {this.state.willUpdateUserProfile ? (
                <div className="card">
                  <input
                    style={{ textTransform: 'capitalize' }}
                    ref="firstName"
                    type="text"
                    id="firstname"
                    placeholder="Firstname"
                    defaultValue={this.state.user.get('firstName')}
                    disabled={this.state.savingUserProfile}
                    required
                  />
                  <i className="fas fa-user hc-primary-text"></i>
                </div>
              ) : (
                <p style={{ marginLeft: '30px' }}>
                  {this.state.user.get('firstName')}
                </p>
              )}
            </div>
            <div className="form-group">
              <span
                style={{
                  fontSize: '13px',
                  color: '#00000096',
                  fontWeight: '500',
                }}
              >
                Middle Name
              </span>
              {this.state.willUpdateUserProfile ? (
                <div className="card">
                  <input
                    style={{ textTransform: 'capitalize' }}
                    ref="middleName"
                    type="text"
                    placeholder="Middle name"
                    defaultValue={this.state.user.get('middleName')}
                    disabled={this.state.savingUserProfile}
                    required
                  />
                  <i className="fas fa-user hc-primary-text"></i>
                </div>
              ) : (
                <p style={{ marginLeft: '30px' }}>
                  {this.state.user.get('middleName')}
                </p>
              )}
            </div>
            <div className="form-group">
              <span
                style={{
                  fontSize: '13px',
                  color: '#00000096',
                  fontWeight: '500',
                }}
              >
                Last Name
              </span>
              {this.state.willUpdateUserProfile ? (
                <div className="card">
                  <input
                    style={{ textTransform: 'capitalize' }}
                    ref="lastName"
                    type="text"
                    placeholder="Lastname"
                    defaultValue={this.state.user.get('lastName')}
                    disabled={this.state.savingUserProfile}
                    required
                  />
                  <i className="fas fa-user hc-primary-text"></i>
                </div>
              ) : (
                <p style={{ marginLeft: '30px' }}>
                  {this.state.user.get('lastName')}
                </p>
              )}
            </div>
            <div className="form-group">
              <span
                style={{
                  fontSize: '13px',
                  color: '#00000096',
                  fontWeight: '500',
                }}
              >
                Date of Birth
              </span>
              {this.state.willUpdateUserProfile ? (
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gridGap: '0.5rem',
                  }}
                >
                  <div className="card px-1">
                    <input
                      value={this.state.bmonth}
                      onChange={(e) => this.handleDobChange(e, 'bmonth')}
                      type="number"
                      placeholder="MM"
                      disabled={this.state.savingUserProfile}
                    />
                  </div>
                  <div className="card px-1">
                    <input
                      value={this.state.bday}
                      onChange={(e) => this.handleDobChange(e, 'bday')}
                      type="number"
                      placeholder="DD"
                      disabled={this.state.savingUserProfile}
                    />
                  </div>
                  <div className="card px-1">
                    <input
                      value={this.state.byear}
                      onChange={(e) => this.handleDobChange(e, 'byear')}
                      type="number"
                      placeholder="YYYY"
                      disabled={this.state.savingUserProfile}
                    />
                  </div>
                </div>
              ) : (
                <p style={{ marginLeft: '30px' }}>
                  {this.state.user.get('dateOfBirth')}
                </p>
              )}
              {this.state.emailError && (
                <span
                  style={{
                    fontSize: '13px',
                    color: 'rgb(216, 75, 85)',
                    fontWeight: '500',
                  }}
                >
                  There is an existing account associated with{' '}
                  {this.refs.email.value}.
                </span>
              )}
            </div>
            <div className="form-group">
              <span
                style={{
                  fontSize: '13px',
                  color: '#00000096',
                  fontWeight: '500',
                }}
              >
                Title (e.g. MD, MA, RN)
              </span>
              {this.state.willUpdateUserProfile ? (
                <div className="card">
                  <input
                    style={{ textTransform: 'capitalize' }}
                    ref="title"
                    type="text"
                    placeholder="Title"
                    defaultValue={this.state.user.get('title')}
                    disabled={this.state.savingUserProfile}
                  />
                  <i className="fas fa-user-tie hc-primary-text"></i>
                </div>
              ) : (
                <p style={{ marginLeft: '30px' }}>
                  {this.state.user.get('title')}
                </p>
              )}
            </div>
            {this.state.willUpdateUserProfile && !this.state.savingUserProfile && (
              <div
                className="form-group"
                style={{ marginBottom: '30px', textAlign: 'right' }}
              >
                <button ref="submit" type="submit" className="Confirm-Button">
                  Save
                </button>
                <span className="Vertical-Line-Seperator"></span>
                <button
                  onClick={this.cancelUpdateProfile.bind(this)}
                  className="Cancel-Button"
                >
                  Cancel
                </button>
              </div>
            )}
            {this.state.savingUserProfile && (
              <div
                className="form-group"
                style={{ textAlign: 'right', position: 'relative' }}
              >
                <i className="fas fa-circle-notch fa-spin"></i>
                <span style={{ paddingLeft: '5px' }}>
                  Updating User Profile
                </span>
              </div>
            )}
          </form>
          <hr />
          <form
            onSubmit={this.updateCompany}
            style={{ position: 'relative', marginTop: '50px' }}
          >
            <h5 style={{ fontSize: '1rem' }}>Company / Organization</h5>
            {!this.state.willUpdateCompany && (
              <span
                onClick={this.willUpdateCompany.bind(this)}
                className="Update-Detail-Button"
              >
                <i className="fas fa-edit"></i> Edit
              </span>
            )}
            <div className="form-group">
              <span
                style={{
                  fontSize: '13px',
                  color: '#00000096',
                  fontWeight: '500',
                }}
              >
                Workplace name
              </span>
              {this.state.willUpdateCompany ? (
                <div className="card">
                  <input
                    style={{ textTransform: 'capitalize' }}
                    ref="workPlace"
                    type="text"
                    placeholder="Workplace name"
                    defaultValue={this.state.user.get('workPlace')}
                    disabled={this.state.savingCompany}
                  />
                  <i className="fas fa-user-tie hc-primary-text"></i>
                </div>
              ) : (
                <p style={{ marginLeft: '30px' }}>
                  {this.state.user.get('workPlace')}
                </p>
              )}
            </div>
            <div className="form-group">
              <span
                style={{
                  fontSize: '13px',
                  color: '#00000096',
                  fontWeight: '500',
                }}
              >
                Workplace type
              </span>
              {this.state.willUpdateCompany ? (
                <div className="card">
                  <input
                    style={{ textTransform: 'capitalize' }}
                    ref="workPlaceType"
                    type="text"
                    placeholder="Workplace type"
                    defaultValue={this.state.user.get('workPlaceType')}
                    disabled={this.state.savingCompany}
                  />
                  <i className="fas fa-user hc-primary-text"></i>
                </div>
              ) : (
                <p style={{ marginLeft: '30px' }}>
                  {this.state.user.get('workPlaceType')}
                </p>
              )}
            </div>
            <div className="form-group">
              <span
                style={{
                  fontSize: '13px',
                  color: '#00000096',
                  fontWeight: '500',
                }}
              >
                Profession
              </span>
              {this.state.willUpdateCompany ? (
                <div className="card">
                  <select
                    style={{ textTransform: 'capitalize' }}
                    ref="profession"
                    type="text"
                    placeholder="Profession"
                    defaultValue={this.state.user.get('profession')}
                    disabled={this.state.savingCompany}
                  >
                    {this.state.professionList.map((p) => {
                      return <option>{p}</option>;
                    })}
                  </select>
                  <i className="fas fa-user hc-primary-text"></i>
                </div>
              ) : (
                <p style={{ marginLeft: '30px' }}>
                  {this.state.user.get('profession')}
                </p>
              )}
            </div>
            {/* <div className="form-group">
                                    <span style={{fontSize: "13px", color:"#00000096", fontWeight: "500"}}>Organization Type</span>
                                    {this.state.willUpdateCompany ?
                                        <div className="card">
                                            <input style={{textTransform: "capitalize"}} ref="lastName" type="text"  placeholder="Lastname" defaultValue={this.state.user.get("organization")} disabled={this.state.savingCompany}/>
                                            <i className="fas fa-user hc-primary-text"></i>
                                        </div>
                                        :
                                        <p style={{marginLeft: "30px"}}>{this.state.user.get("organization")}</p>
                                    }
                                </div> */}
            {this.state.willUpdateCompany && !this.state.savingCompany && (
              <div
                className="form-group"
                style={{ marginBottom: '30px', textAlign: 'right' }}
              >
                <button ref="submit" type="submit" className="Confirm-Button">
                  Save
                </button>
                <span className="Vertical-Line-Seperator"></span>
                <button
                  onClick={this.cancelUpdateCompany.bind(this)}
                  className="Cancel-Button"
                >
                  Cancel
                </button>
              </div>
            )}
            {this.state.savingCompany && (
              <div
                className="form-group"
                style={{ textAlign: 'right', position: 'relative' }}
              >
                <i className="fas fa-circle-notch fa-spin"></i>
                <span style={{ paddingLeft: '5px' }}>
                  Updating Company/Organization
                </span>
              </div>
            )}
          </form>

          <hr />

          <form
            onSubmit={this.updatePassword}
            style={{ position: 'relative', marginTop: '50px' }}
          >
            <h5 style={{ fontSize: '1rem' }}>Change Password</h5>
            {this.state.changePasswordSuccess && (
              <span style={{ color: 'rgb(74, 173, 82)', fontSize: '13px' }}>
                Password has been changed
              </span>
            )}
            {this.state.failedChangePassword && (
              <span style={{ color: 'rgb(216, 75, 85)', fontSize: '13px' }}>
                Can't change password right now, please try again later
              </span>
            )}
            {!this.state.willUpdatePassword && (
              <span
                onClick={this.willUpdatePassword.bind(this)}
                className="Update-Detail-Button"
              >
                <i className="fas fa-edit"></i> Edit
              </span>
            )}
            <div className="form-group">
              <span
                style={{
                  fontSize: '13px',
                  color: '#00000096',
                  fontWeight: '500',
                }}
              >
                Current Password
              </span>
              {this.state.willUpdatePassword ? (
                <div className="card">
                  <input
                    ref="current_password"
                    type="password"
                    placeholder="••••••••"
                    disabled={this.state.savingCompany}
                    required
                  />
                  <i className="fas fa-lock hc-primary-text"></i>
                </div>
              ) : (
                <p style={{ marginLeft: '30px' }}>••••••••</p>
              )}
              {this.state.wrongPassword && (
                <span
                  style={{
                    fontSize: '13px',
                    color: 'rgb(216, 75, 85)',
                    fontWeight: '500',
                  }}
                >
                  Incorrent password.
                </span>
              )}
            </div>
            {this.state.willUpdatePassword && (
              <>
                <div className="form-group">
                  <span
                    style={{
                      fontSize: '13px',
                      color: '#00000096',
                      fontWeight: '500',
                    }}
                  >
                    New Password
                  </span>
                  <div className="card">
                    <input
                      onChange={this.handlePasswordChange.bind(this)}
                      value={this.state.password}
                      ref="new_password"
                      type="password"
                      placeholder="••••••••"
                      disabled={this.state.savingCompany}
                      required
                    />
                    <i className="fas fa-lock hc-primary-text"></i>
                  </div>
                  <div style={{ paddingTop: '5px' }}>
                    {this.state.passwordError &&
                      this.state.passwordErrorMessage.map((e, i) => {
                        return (
                          <span
                            key={i}
                            style={{
                              fontSize: '13px',
                              color: 'rgb(216, 75, 85)',
                              display: 'block',
                              fontWeight: '500',
                            }}
                          >
                            {e}
                          </span>
                        );
                      })}
                  </div>
                </div>
                <div className="form-group">
                  <span
                    style={{
                      fontSize: '13px',
                      color: '#00000096',
                      fontWeight: '500',
                    }}
                  >
                    Confirm Password
                  </span>
                  <div className="card">
                    <input
                      onChange={this.handleConfirmPasswordChange.bind(this)}
                      value={this.state.confirmPassword}
                      ref="confirm_password"
                      type="password"
                      placeholder="••••••••"
                      disabled={this.state.savingCompany}
                      required
                    />
                    <i className="fas fa-lock hc-primary-text"></i>
                  </div>
                  {this.state.confirmPassword !== this.state.password && (
                    <span
                      style={{
                        fontSize: '13px',
                        color: 'rgb(216, 75, 85)',
                        fontWeight: '500',
                      }}
                    >
                      Password does not match password confirmation.
                    </span>
                  )}
                </div>
              </>
            )}
            {this.state.willUpdatePassword && !this.state.savingPassword && (
              <div
                className="form-group"
                style={{ marginBottom: '30px', textAlign: 'right' }}
              >
                <button ref="submit" type="submit" className="Confirm-Button">
                  Save
                </button>
                <span className="Vertical-Line-Seperator"></span>
                <button
                  onClick={this.cancelUpdatePassword.bind(this)}
                  className="Cancel-Button"
                >
                  Cancel
                </button>
              </div>
            )}
            {this.state.savingPassword && (
              <div
                className="form-group"
                style={{ textAlign: 'right', position: 'relative' }}
              >
                <i className="fas fa-circle-notch fa-spin"></i>
                <span style={{ paddingLeft: '5px' }}>Updating Password</span>
              </div>
            )}
          </form>
        </div>
        <div className="delete-acct-container">
          <button
            type="button"
            className="btn btn-danger btn-block"
            onClick={() => this.setState({ isModalOpened: true })}>
            Delete Account
          </button>
        </div>
        {this.state.isModalOpened && (
          <ConfirmDeleteModal
            isOpen={this.state.isModalOpened}
            onClose={(bool) => {
              this.setState({ isModalOpened: true })
              if (bool) {
                this.handleDeleteAccount();
              }
            }}
            title='Delete Account'
            message='Are you sure you want to delete your account?'
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  deleteAccount,
})(AccountContainer);
