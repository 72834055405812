import React, { Component } from 'react'

export class NewCall extends Component {
    render() {
        let ImageURL;
        if (this.props.callData.image_url === "" || typeof this.props.callData.image_url === undefined) {
            ImageURL = require('../../../assets/images/default.png');
        } else {
            ImageURL = this.props.callData.image_url;
        }

        return (
            <div className="Video-Container">
                <div className="Other-Profile" style={{marginTop: "100px", textAlign: "center"}}>
                    <img src={ImageURL} style={{height: "100px", width: "100px"}} alt="Call Image" className="Call-Image-Profile"></img>
                    <h5 style={{display: "block"}}>{this.props.callData.caller_name}</h5>
                    <button onClick={this.props.startCall} type="button" class="btn btn-success">Start a call</button>
                </div>
            </div>
        )
    }
}

export default NewCall
