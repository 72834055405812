import React, { Component } from 'react'
import Parse from 'parse';
import '../../TabList/TabList.css'
const $ = window.$;

export class GuestItem extends Component {

    componentDidMount() {
        $('[data-toggle="tooltip"]').tooltip();
    }

    componentDidUpdate() {
        $('[data-toggle="tooltip"]').tooltip();
    }

    render() {
        const { strRole, member, isAdmin, isCreator, setRole = () => { }, isGuest = false } = this.props;
        const user = member.user;
        let containerStyle = {
            border: "0px solid white",
            minHeight: "45px",
            display: 'flex',
            flexDirection: 'row !important',
        };

        let Image,
            Name,
            nameStyle = {
                marginTop: "4px"
            };

        let selectStyle = {
            maxWidth: '105px',
            height: '27px',
            fontSize: '12px',
            paddingTop: '4px',
            paddingBottom: '4px',
        };

        let ImageStyle = {
            width: "30px",
            height: "30px",
            position: 'relative',
            top: '0px',
            left: '0px',
            alignSelf: 'center',
        };

        var contactURL;

        Name = user.firstName + " " + user.lastName;

        if (typeof user.picture === "undefined") {
            contactURL = require("../../../assets/images/default.png");
        } else {
            contactURL = user.picture.url;
        }
        Image = <img src={contactURL} alt="" className="profile-image rounded-circle mr-3" style={ImageStyle} />;

        if (user.statusMessage) {
            nameStyle.marginTop = "-4px"
        }


        let RemoveAdmin = <li onClick={() => this.props.removeAdmin(member)} key={"remove-admin-" + member.objectId} className="dropdown-item">Remove Admin</li>;
        let AssignAdmin = <li onClick={() => this.props.assignAdmin(member)} key={"assign-" + member.objectId} className="dropdown-item">Assign Admin</li>;
        let RemoveMember = <li onClick={() => this.props.removeMember(user.objectId)} key={"remove-" + member.objectId} className="dropdown-item">Remove</li>;

        let Options = []
        let showDropdown = true;
        let showInfo = false;
        if (isCreator) showDropdown = true;
        if (user.objectId === Parse.User.current().id) showDropdown = false;

        if (isAdmin) {
            if (!member.isAdministrator) {
                if (!this.props.isTeam) {
                    Options.push(AssignAdmin);
                }
                Options.push(RemoveMember);
            } else {
                showDropdown = false;
                showInfo = true;
            }
        } else {
            showDropdown = false;
            showInfo = false;
        }

        if (isCreator) {
            Options = [];
            if (member.isAdministrator) {
                Options.push(RemoveAdmin);
            } else {
                Options.push(AssignAdmin);
                Options.push(RemoveMember);
            }

            showDropdown = true;
            showInfo = false;
        }


        let Dropdown = <div className="dropdown menu-btn">
            <i className="fas fa-cog dropdown-toggle" id={member.objectId + "_dropdown"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
            <div className="dropdown-menu" style={{ minWidth: "8rem" }} aria-labelledby={member.objectId + "_dropdown"}>
                {Options}
            </div>
        </div>

        if (isGuest) {
            Dropdown = <div className="dropdown menu-btn">
                <i className="fas fa-cog dropdown-toggle" id={member.objectId + "_dropdown"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                <div className="dropdown-menu" style={{ minWidth: "8rem" }} aria-labelledby={member.objectId + "_dropdown"}>
                    <li onClick={() => this.props.removeGuest(user.objectId)} key={"remove-" + member.objectId} className="dropdown-item">Remove guest</li>
                </div>
            </div>
        }

        return (
            <a href="#!;" className="list-group-item list-group-item-action" style={containerStyle}>
                {Image}
                <div style={{
                    maxWidth: isCreator ? '230px' : '335px',
                    width: '100%'
                }}>
                    <p className="name" style={nameStyle}>{Name}</p>
                    <p style={{ marginBottom: "0", fontWeight: "500", fontSize: "11px", lineHeight: "1", color: "#000000c7" }}>{user.statusMessage}</p>
                    {showDropdown && Dropdown}
                    {showInfo && <i className="fas fa-info-circle menu-btn custom-tooltip">
                        <span className="custom-tooltiptext">Only group creator can revoke admin</span>
                    </i>}
                </div>
                <div />
            </a>
        )
    }
}

export default GuestItem;
