import React, { Component } from 'react'
import Loader from '../../Common/Loader'
import Notify from '../../Common/Notify'
import { parseAction } from '../../../actions/REST'
import config from '../../../config'

const $ = window.$;
export class ReplySMSModal extends Component {

    constructor() {

        super();

        this.state = {
            text: '',
            isLoading: false,
        };
    }

    handleChange(e) {
        this.setState({
            text: e.target.value,
        });
    }

    handleSubmit(e) {
        e.preventDefault();

        const method = 'post';
        const url = config.BASE_URL + '/parse/functions/replyBySms';

        const { message } = this.props;
        const { text } = this.state;

        const data = {
            to: message.smsFrom,
            text
        };

        this.setState({ isLoading: true });

        return parseAction(method, url, data)
            .then(response => {

                this.setState({ isLoading: false });

                Notify.success('SMS sent successfully!');

                $("#ReplySMSModal").modal('hide');
            })
            .catch(error => {
                this.setState({ isLoading: false });

                Notify.error('Could not send SMS.')
            });
    }

    componentDidMount() {
        if (this.props.isOpen) {
            $("#ReplySMSModal").modal('show');
        } else {
            $("#ReplySMSModal").modal('hide');
        }

        $("#ReplySMSModal").on('hidden.bs.modal', function (e) {
            this.props.modalClose();
        }.bind(this));
    }

    render() {

        const { message = {} } = this.props;
        const { smsFrom } = message;
        const { text, isLoading } = this.state;

        return (
            <>
                <div id="ReplySMSModal" className="modal fade" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <form className="modal-content" onSubmit={this.handleSubmit.bind(this)}>

                            <div className="modal-header">
                                <h5 className="modal-title hc-primary-text">Reply via SMS</h5>
                                <button style={{ position: "absolute", top: "15px", right: "15px" }} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div className="modal-body d-flex flex-column">
                                <div className="alert alert-info d-flex">
                                    <div className="d-flex flex-column justify-content-center mr-3">
                                        <i className="fa fa-2x fa-info-circle"></i>
                                    </div>
                                    <div>
                                        This message will be sent using your virtual phone number.
                                </div>
                                </div>

                                <div class="hc-card mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text hc-input-addon">
                                            <i className="fas fa-id-card mr-1"></i>
                                        To:
                                    </span>
                                    </div>

                                    <input disabled value={smsFrom} type="text" class="form-control" />
                                </div>

                                <div class="hc-card">
                                    <textarea value={text} onChange={this.handleChange.bind(this)} className="p-3" rows="8" placeholder="Enter message..." required />
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button data-dismiss="modal" className="btn btn-hubchart btn-hubchart-close">
                                    Cancel
                            </button>
                                <button className="btn btn-hubchart btn-hubchart-primary">
                                    <i className="fas fa-paper-plane mr-1"></i>
                                Send
                            </button>
                            </div>
                        </form>
                    </div>
                    <Loader isLoading={isLoading} />
                </div>
            </>
        );
    }
}

export default ReplySMSModal;
