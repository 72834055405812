import { toast, ToastContainer } from 'react-toastify';
import React from 'react';

const options = {
    autoClose: 5000,
    closeButton: false,
    hideProgressBar: true,
    className: 'toast-base-main'
};

function success (message, customOptions={}) {
    toast.success(message, {
        ...options,
        ...customOptions
    });
}

function error (message) {
    toast.error(message, options);
}

function info (message, customOptions={}) {
    toast.info(message, {
        ...options,
        ...customOptions
    });
}

function light (message, customOptions={}) {
    toast(message, {
        ...options,
        ...customOptions
    });
}

function white (message, customOptions={}) {
    toast(message, {
        ...options,
        ...customOptions,
        className: 'toast-base-main white'
    });
}

function errorWithTitle (title, message) {

    const alertContent = (
        <div>
            <div style={{fontSize: '16px', fontWeight: 'bold'}}>{title}</div>
            <div style={{fontSize: '14px'}}>{message}</div>
        </div>
    );

    toast.error(alertContent, options);
}

export default {
    success,
    error,
    info,
    light,
    POSITION: toast.POSITION,
    white,
    errorWithTitle,
    dismiss: toast.dismiss,
    ToastContainer,
}
