import React, { useCallback, useState } from 'react';

const Item = ({ user, participant, onRequestMic, onRequestCam, isHost }) => {

    const {
        displayName,
        imgUrl,
        objectId,
    } = user;

    const [mic, setMic] = useState(false);
    const [cam, setCam] = useState(false);

    const btnStyles = {
        fontSize: '12px',
    }

    const handleRequestMic = useCallback((e, value) => {
        e.preventDefault();
        onRequestMic(user, value);
    }, [user]);

    const handleRequestCam = useCallback((e, value) => {
        e.preventDefault();
        onRequestCam(user, value);
    }, [user]);

    return (
        <div className="border-item layout-item">
            <img src={imgUrl}
                alt=""
                className="profile-image rounded-circle mr-2"
                style={{ width: "30px", height: "30px" }} />
            <p className="name m-0">{displayName}</p>
            { isHost ?
                <span className="ml-auto d-flex">
                    <button onClick={e => handleRequestMic(e, true)} className="btn btn-light btn-sm btn-block text-left text-truncate mr-2" style={btnStyles}>
                        <i className="fas fa-fw fa-microphone mr-1 text-success"></i>
                        Request Mic
                    </button>
                    <div className="dropdown">
                        <button className="btn btn-light btn-sm text-primary"
                            style={btnStyles}
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                            <i className="fas fa-cog"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-right">
                            <a onClick={e => handleRequestCam(e, true)} className="dropdown-item" href="#!" style={btnStyles}>
                                <i className="fas fa-video mr-1 text-success"></i>
                                Request Cam
                            </a>
                            <div className="dropdown-divider"></div>
                            <a onClick={e => handleRequestCam(e, false)} className="dropdown-item" href="#!" style={btnStyles}>
                                <i className="fas fa-video-slash mr-1 text-danger"></i>
                                Turn off Cam
                            </a>
                            <a onClick={e => handleRequestMic(e, false)} className="dropdown-item" href="#!" style={btnStyles}>
                                <i className="fas fa-microphone-slash mr-1 text-danger"></i>
                                Mute
                            </a>
                        </div>
                    </div>
                </span>
                : ''
            }
        </div>
    )
}

export default Item;
