import React, { useState } from "react";
import "./ForwardModal.css";
import Modal from "../../Common/Modal";
import moment from "moment";

const $ = window.$;

function ForwardFilterModal({ 
  isOpen, 
  onClose, 
  openForward, 
  openForwardMultiple, 
  setGroupFilter, 
  openNextModal 
}) {
  
  const [group, setGroup] = useState('');

  const handleChange = (group) => {
    setGroupFilter(group);
    setGroup(group);
  }
  
  const handleSetReminder = () => {
    if (openNextModal === 'forward') {
      openForward();
      // onClose(false);
    } else {
      openForwardMultiple(); // <<< check param
      // onClose(false)
    }
  }

  return (
    <Modal size="modal-sm" height="modal-small-height" shown={isOpen} onHide={onClose}>
      <ModalHeader onClose={onClose} />
      <ModalBody>
        <div className="card-body px-3" style={{ padding: "0" }}>
          <div className="radio forward-selection-table text-lg">
            <span>
              <input 
                id="contact"
                type="radio" 
                value="hour" 
                name="reminder"
                onChange={(e) => handleChange('contact')} 
                /> <label htmlFor="contact"> Contact</label> 
            </span>
            <></>
            <span>
              <input 
                id="group"
                type="radio" 
                value="day" 
                name="reminder"
                onChange={(e) => handleChange('group')} 
                /> <label htmlFor="group"> Group</label>
              </span>
            <span className="text-sm">
            </span>
            <span>
            <input 
              id="patient"
              type="radio" 
              value="week" 
              name="reminder" 
              onChange={(e) => handleChange('patient')} 
              /> <label htmlFor="patient"> Patient</label> 
            </span>
            <></>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          disabled={group === '' ? true : false}
          style={{ marginRight: "10px" }}
          className="btn btn-hubchart btn-hubchart-primary"
          onClick={handleSetReminder}
        >
          Proceed
        </button>
      </ModalFooter>
    </Modal>
  );
}

const ModalHeader = (onClose) => {
  return (
    <div className="modal-header">
      <h5 className="text-info mb-0" style={{ fontSize: "1.25rem" }}>
        Select Group
      </h5>
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
        onClick={() => onClose()}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

const ModalBody = ({ children, style = {} }) => {
  const styles = {
    ...style,
  };

  return (
    <div className="modal-body" style={styles}>
      {children}
    </div>
  );
};

const ModalFooter = ({ children }) => {
  return <div className="modal-footer">{children}</div>;
};

export default ForwardFilterModal;
