import { SAVE_DOCUMENT, NEW_DOCUMENT, GET_DOCUMENT, DOCUMENT_FETCH_STATUS } from '../actions/types'

const initialState = {
    documentList : [],
    activeDocument : {}
}


// document = {
//     activeThreadId: "id",
//     documents: {},
//      initialLoad : boolean,
//      fetchStatus : ""
// }

export default function(state = initialState, action) {
	switch(action.type) {
        case DOCUMENT_FETCH_STATUS:
            return {
                ...state,
                activeDocument : {
                    ...state.activeDocument,
                    documents : state.activeDocument.documents.map((docu) => {
                        if (docu.type === action.docuType) {
                            return {
                                ...docu,
                                initialLoad: true,
                                fetchStatus : action.status
                            }
                        }
                        return docu
                    })
                },
                documentList: state.documentList.map((item) => {
                    if (item.activeThreadId === action.id) {
                        return {
                            ...item,
                            documents : item.documents.map((docu) => {
                                if (docu.type === action.docuType) {
                                    return {
                                        ...docu,
                                        initialLoad: true,
                                        fetchStatus : action.status
                                    }
                                }
                                return docu
                            })
                        }
                    }
                    return item
                })
            }
        break;

        case GET_DOCUMENT:
                var currentDocu = state.documentList.find(c => c.activeThreadId === action.id);
                if (currentDocu) {
                    return {
                        ...state,
                        activeDocument : currentDocu
                    }
                } else {
                    let newDocu = {
                        activeThreadId : action.id,
                        documents : [
                            {
                                type : "encounter_notes",
                                document : [],
                                initialLoad : false,
                                fetchStatus : ""
                            },
                            {
                                type : "meds_rx",
                                document : [],
                                initialLoad : false,
                                fetchStatus : ""
                            },
                            {
                                type : "authorization",
                                document : [],
                                initialLoad : false,
                                fetchStatus : ""
                            },
                            {
                                type : "labs",
                                document : [],
                                initialLoad : false,
                                fetchStatus : ""
                            },
                            {
                                type : "radiology",
                                document : [],
                                initialLoad : false,
                                fetchStatus : ""
                            },
                            {
                                type : "pathology",
                                document : [],
                                initialLoad : false,
                                fetchStatus : ""
                            },
                            {
                                type : "consult_notes",
                                document : [],
                                initialLoad : false,
                                fetchStatus : ""
                            },
                            {
                                type : "hospital_records",
                                document : [],
                                initialLoad : false,
                                fetchStatus : ""
                            },
                            {
                                type : "photos",
                                document : [],
                                initialLoad : false,
                                fetchStatus : ""
                            },
                            {
                                type : "admin_docs",
                                document : [],
                                initialLoad : false,
                                fetchStatus : ""
                            },
                            {
                                type : "other_files",
                                document : [],
                                initialLoad : false,
                                fetchStatus : ""
                            },

                        ]
                    }

                    return {
                        ...state,
                        activeDocument : newDocu,
                        documentList : [...state.documentList, newDocu]
                    }
                }

        break;

        case SAVE_DOCUMENT:
            var currentDocu = state.documentList.find(c => c.activeThreadId === action.id);
            if (currentDocu) {
                currentDocu = {
                    ...currentDocu,
                    documents : currentDocu.documents.map((docu) => {
                        if (docu.type === action.docuType) {
                            return {
                                ...docu,
                                document : [...action.documents],
                                initialLoad: true,
                                fetchStatus : action.status
                            }
                        }
                        return docu
                    })
                }

                return {
                    ...state,
                    activeDocument : currentDocu,
                    documentList : state.documentList.map((docu) => {
                        if (docu.activeThreadId === action.id) {
                            return {
                                ...currentDocu
                            }
                        }
                        return docu
                    })
                }
            }
            return {
                ...state,
            }
        break;

        case NEW_DOCUMENT:
            var currentDocu = state.documentList.find(c => c.activeThreadId === action.id);
            if (currentDocu) {
                currentDocu = {
                    ...currentDocu,
                    documents : currentDocu.documents.map((docu) => {
                        if (docu.type === action.docuType) {
                            return {
                                ...docu,
                                document : [action.document, ...docu.document],
                                initialLoad: true,
                                fetchStatus : action.status
                            }
                        }
                        return docu
                    })
                }
                let activeDocument = {...state.activeDocument}
                if (activeDocument.activeThreadId === action.id) {
                    activeDocument = {...currentDocu}
                }

                return {
                    ...state,
                    activeDocument : activeDocument,
                    documentList : state.documentList.map((docu) => {
                        if (docu.activeThreadId === action.id) {
                            return {
                                ...currentDocu
                            }
                        }
                        return docu
                    })
                }
            } else {
                let newDocu = {
                    activeThreadId : action.id,
                    documents : [
                        {
                            type : "encounter_notes",
                            document : [],
                            initialLoad : false,
                            fetchStatus : ""
                        },
                        {
                            type : "meds_rx",
                            document : [],
                            initialLoad : false,
                            fetchStatus : ""
                        },
                        {
                            type : "authorization",
                            document : [],
                            initialLoad : false,
                            fetchStatus : ""
                        },
                        {
                            type : "labs",
                            document : [],
                            initialLoad : false,
                            fetchStatus : ""
                        },
                        {
                            type : "radiology",
                            document : [],
                            initialLoad : false,
                            fetchStatus : ""
                        },
                        {
                            type : "pathology",
                            document : [],
                            initialLoad : false,
                            fetchStatus : ""
                        },
                        {
                            type : "consult_notes",
                            document : [],
                            initialLoad : false,
                            fetchStatus : ""
                        },
                        {
                            type : "hospital_records",
                            document : [],
                            initialLoad : false,
                            fetchStatus : ""
                        },
                        {
                            type : "photos",
                            document : [],
                            initialLoad : false,
                            fetchStatus : ""
                        },
                        {
                            type : "admin_docs",
                            document : [],
                            initialLoad : false,
                            fetchStatus : ""
                        },
                        {
                            type : "other_files",
                            document : [],
                            initialLoad : false,
                            fetchStatus : ""
                        },

                    ]
                }

                newDocu = {
                    ...newDocu,
                    documents : newDocu.documents.map((docu) => {
                        if (docu.type === action.docuType) {
                            return {
                                ...docu,
                                document : [action.document, ...docu.document]
                            }
                        }
                        return docu
                    })
                }


                return {
                    ...state,
                    documentList : [...state.documentList, newDocu]
                }
            }
        break;



		default:
		return state;
	}
}

