import React, { useCallback, useState, useRef, useEffect, Fragment } from 'react';
import { useSelector, useDispatch, shallowEqual, connect } from 'react-redux';
import debounce from 'lodash.debounce';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import { Typeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import { createEvent, updateEvent } from '../../../api/GoogleCalendarApi';
import AvailableTimeChecker from '../../../components/AvailableTimeChecker/AvailableTimeChecker';
import DatePicker from 'react-datepicker';
import Notify from '../../Common/Notify';
import ConferenceHelper from '../../../helper/conference';
import ConferenceApi from '../../../api/Conference';
import TabLoader from '../../Common/TabLoader';
import { telemedUrl } from '../../../helper/util';
import { 
  setConferenceTab, 
  getMeetings, 
  getSchedules, 
  loadGoogleApi, 
  signInToGoogle, 
  signOutOfGoogle, // used for testing
  scheduleConferenceCall, 
  updateScheduledConferenceCall
} from '../../../actions/ConferenceAction';
import { searchContacts } from '../../../actions/ContactAction';

import config from '../../../../src/config';
import 'rc-time-picker/assets/index.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './index.css';

import imgPlaceHolder from '../../../assets/images/default.png';

const $ = window.$;

const ManageEventModal = ({ 
  filterResult = [],
  otherUsersResult = [],
  searchContacts = () => {},
  onClose = () => {},
  method = 'create',
  isOpen = false,
  dataEvent = {},
  title = '',
}) => {
  
  const eventForm = useRef(null);
  const modalId = '#manageEventModal';
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const {
    isLoadingSchedules,
    isGoogleSignedIn,
    schedules,
    google_calendar_schedules,
  } = mapStateToProps();

  const defaultEventData = {
    eventName: title,
    eventDescription: '',
    eventChiefComplaint: '',
  }

  const initData = handleInitData(dataEvent);
  const [event,  setEventData] = useState(initData.event);
  const [startDate, setStartDate] = useState(initData.startDate);
  const [start_time, setStartTime] = useState(initData.startTime);
  const [end_time, setEndTime] = useState(initData.endTime); 
  const [attendees, setAttendees] = useState([]);
  const [selected_attendees, setSelectedAttendees] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [isGoogleLoaded, setIsGoogleLoaded] = useState(false);

  const handleScheduleConference = (conferenceData) => {
    setLoading(true);
    const participantIds = conferenceData.participantIds.filter(Boolean);
    const confData = { ...conferenceData, participantIds }
    
    return scheduleConferenceCall(confData)(dispatch)
      .then(result => {
        const { objectId } = result.conference;
        if (isGoogleSignedIn) {
          const eventData = {
            summary: event.eventName,
            description: `${event.eventDescription}
Hubchart Link:
${telemedUrl}?roomId=${result.roomId}`,
            start: new Date(start_time),
            end: new Date(end_time),
            attendees: formatAttendees(attendees),
          };
          createMeeting(eventData, objectId);
        } else {
          const data = {
            objectId,
            label: event.eventName,
            comments: `${event.eventDescription}
Hubchart Link:
${telemedUrl}?roomId=${result.roomId}`,
          };
          Notify.success('Telemed successfully scheduled.');
          handleUpdateScheduledConferenceCall(data);
        }
        setLoading(false);
      })
      .catch(response => {
          const { responseJSON } = response;
          if (responseJSON) {
              const { error, code } = responseJSON;
              Notify.error(error);
          } else {
              Notify.error('Something went wrong');
          }
          setLoading(false);
          return Promise.reject();
      });
  }

  function handleInitData (data = {}) {
    if (method === 'update') {
      const start = (moment(data.dateScheduled.iso)).toDate();
      const end = (moment(data.dateFinished.iso)).toDate();
      const split_comments = data.comments.split('Hubchart Link:');
      const description = split_comments[0].replace(/\s/g, ' ');
      
      return {
        startDate: new Date(data.dateScheduled.iso),
        startTime: moment(start.getTime()),
        endTime: moment(end.getTime()),
        event: {
          eventName: data.label,
          eventDescription: split_comments.length > 1 ? description : '',
          eventChiefComplaint: data.chiefComplaint
        },
        attendees: data.participants.filter(p => p.username !== dataEvent.host.username),
      
      }
    } else {
      const default_start = setDefaultStartTime(new Date());
      const end_default = moment(default_start).add(1, 'hours');
      
      return {
        startDate: new Date(),
        startTime: default_start,
        endTime: end_default,
        event: defaultEventData,
        attendees: [],
      }
    }
  }

  function setDefaultStartTime (date) {
    const defaultDate = moment(date).add(1, 'hours');
    const splitTime = defaultDate.toDate();

    splitTime.setMinutes(0);
    return moment(splitTime);
  }

  function mapStateToProps() {
    return useSelector(state => ({
        isLoadingSchedules: state.conference.isLoadingSchedules,
        isGoogleSignedIn: state.conference.isGoogleSignedIn,
        google_calendar_schedules: state.conference.google_calendar_schedules,
        schedules: state.conference.schedules,
    }), shallowEqual);
  }

  function handleClientLoad() {
    loadGoogleApi({ 
      apiKey: config.REACT_APP_GOOGLE_API_KEY, 
      clientId: config.REACT_APP_GOOGLE_CLIENT_ID, 
    }, () => {
      setIsGoogleLoaded(true);
      listUpcomingEvents();
    })(dispatch);
  }

  function listUpcomingEvents() {
    if (isGoogleSignedIn) {
      getMeetings()(dispatch);
    }
  }

  function createMeeting (eventData, objectId) {
    createEvent(eventData, ev => {
      const data = {
        objectId,
        label: eventData.summary,
        comments: eventData.description,
        eventId: ev.id,
      }
      
      handleUpdateScheduledConferenceCall(data);
      // window.open(ev.htmlLink, '_blank');
    });
  }

  function updateMeeting () {
    const split_comments = dataEvent.comments.split('Hubchart Link:');
    const link = split_comments[split_comments.length - 1].replace(/\s/g, '');
    const evData = {
      summary: event.eventName,
      description: `${event.eventDescription}
Hubchart Link:
${link}
      `,
      start: new Date(start_time),
      end: new Date(end_time),
      attendees: formatAttendees(attendees),
    };

    updateEvent(evData, dataEvent.eventId, ev => {
      const data = {
        objectId: dataEvent.objectId,
        label: evData.summary,
        chiefComplaint: event.eventChiefComplaint,
        comments: evData.description,
        dateScheduled: evData.start.toISOString(),
        dateFinished: evData.end.toISOString(),
        participantIds: attendees.map(participant => participant.objectId)
      }
      
      handleUpdateScheduledConferenceCall(data);
    });
  }

  function handleUpdateScheduledConferenceCall (data) {
    updateScheduledConferenceCall(data)
      .then(result => {
        getSchedules()(dispatch);
        handleDefaultValues();
      })
      .catch(response => {
          const { responseJSON } = response;
          if (responseJSON) {
              const { error, code } = responseJSON;
              Notify.error(error);
          } else {
              Notify.error('Something went wrong');
          }
          setLoading(false);
          return Promise.reject();
      });
  }

  function saveEvent () {
    const participantIds = attendees.map(participant => participant.objectId);

    if (method === 'update' && isGoogleSignedIn) {
      updateMeeting();
    } else if (method === 'update' && !isGoogleSignedIn) {
      const split_comments = dataEvent.comments.split('Hubchart Link:');
      const link = split_comments[split_comments.length - 1].replace(/\s/g, '');
      const data = {
        objectId: dataEvent.objectId,
        label: event.eventName,
        chiefComplaint: event.eventChiefComplaint,
        comments: `${event.eventDescription}
Hubchart Link:
${link}
        `,
        dateScheduled: (new Date(start_time)).toISOString(),
        dateFinished: (new Date(end_time)).toISOString(),
        participantIds: attendees.map(participant => participant.objectId)
      }
      
      handleUpdateScheduledConferenceCall(data);
    } else {
      handleScheduleConference({
        participantIds,
        chiefComplaint: event.eventChiefComplaint,
        dateScheduled: (new Date(start_time)).toISOString(),
        dateFinished: (new Date(end_time)).toISOString(),
      });
    }
  }

  function formatAttendees (attendees) {
    return attendees.map(item =>({
      email: item.email ? item.email : item.displayName,
    }));
  }

  function handleStartDate (date) {
    setStartDate(date);
    const defaultTime = setDefaultStartTime(date);
    setStartTime(moment(defaultTime));
    setEndTime(moment(defaultTime).add(1, 'hours'));
    listUpcomingEvents();
  }

  function handleStartTime (time) {
    const endTime = moment(time).add(1, 'hours');
    setStartTime(time);
    setEndTime(endTime);
  }

  function handleSetSelectedAttendees () {
    const new_attendees = [
      ...selected_attendees,
      ...attendees,
    ];
    const filtered_attendees = Array.from(new Set(new_attendees.map(JSON.stringify))).map(JSON.parse);
    setSelectedAttendees(filtered_attendees);
    setAttendees([]);
  }

  function handleRemoveAttendees (index) {
    const new_selected_attendees = selected_attendees.slice(0);
    new_selected_attendees.splice(index, 1);

    setSelectedAttendees(new_selected_attendees);
  }

  function handleSetEventData (target) {
    const { name, value } = target;
    setEventData({
      ...event,
      [name]: value,
    });
  }
  
  function handleDefaultValues () {
      const defaultTime = setDefaultStartTime(new Date());

      setEventData(defaultEventData);
      setStartDate(new Date());
      setStartTime(moment(defaultTime));
      setEndTime(moment(defaultTime).add(1, 'hours'));
      setAttendees([]);
      setSelectedAttendees([]);
      setConferenceTab('SCHEDULES')(dispatch);
      listUpcomingEvents();
      handleClose();
  }
  
  function componentDidMount() {
    if (isOpen) {
      $(modalId).modal('show');
    } else {
      $(modalId).modal('hide');
    }
  }
  
  function handleClose (id) {
    $(modalId).modal('hide');
    onClose();
  }

  function handleGetUsersById (data) {
    const newParticipants = data.participants.filter(item => item.objectId !== data.host.objectId)
    const objectIds = newParticipants.map(participant => {return participant.objectId});
    ConferenceApi.getUsersById({ objectIds }).then(response => {
      // setSelectedAttendees(response.users);
      setAttendees(response.users);
    });
  }

  useEffect(() => {
    if (!isGoogleLoaded) {
      handleClientLoad();
    }
    setSuggestions([
      ...filterResult, 
      ...otherUsersResult
    ]);
  }, [filterResult, otherUsersResult]);
  
  useEffect(() => {
    componentDidMount();
    if (method === 'update') {
      handleGetUsersById(dataEvent);
    }
  }, [isOpen, dataEvent, method]);

  const debouncedHandleSearchContact =  debounce(searchContacts, 500);
  
  return (
    <div id="manageEventModal" className="modal fade" tabIndex="-1" role="dialog" data-backdrop="static">
      <div className="modal-dialog" role="document">
          <div className="modal-content modal-full-height">
            <div className="modal-header">
              <h5 className="modal-title hc-primary-text text-capitalize">{`${method} Schedule`}</h5>
              <button 
                style={{ 
                  position: "absolute", 
                  top: "15px", 
                  right: "15px",
                }} 
                type="button" 
                className="close" 
                aria-label="Close" 
                onClick={() => handleClose()}>
                  <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {!isLoadingSchedules && (
              <Fragment>
                <div className="modal-body" style={{ overflowY: 'auto', paddingBottom: '5px', flex: 'unset' }}>
                  <form ref={eventForm} className="event-form-flex">
                    <div>
                      <label>Name/Topic: </label>
                      <input 
                        type="text" 
                        id="eventName" 
                        name="eventName" 
                        className="form-control" 
                        placeholder="Enter Name/Topic" 
                        onChange={e => handleSetEventData(e.target)}
                        value={event.eventName} />
                    </div>
                    <div>
                      <label>Date & Time:</label> <br />
                      <div className="picker-flex">
                        <div>
                          <DatePicker 
                            dateFormat="EEEE, MMMM dd" 
                            selected={startDate} 
                            onChange={date => handleStartDate(date)} />
                        </div>
                        <div className="timepicker-flex">
                          <TimePicker 
                            showSecond={false} 
                            use12Hours={true} 
                            onChange={time => handleStartTime(time)} 
                            value={start_time} 
                            allowEmpty={false}
                            minuteStep={30} />
                          <span className="pad-side-sm">To</span>
                          <TimePicker 
                            showSecond={false} 
                            use12Hours={true} 
                            onChange={time => setEndTime(time)}
                            value={end_time}
                            allowEmpty={false}
                            minuteStep={30} />
                        </div>
                      </div>
                    </div>
                    <div align="right">
                      <AvailableTimeChecker
                        schedule_date={moment(startDate)}
                        setStartTime={(date) => handleStartTime(date)}
                        schedules={isGoogleSignedIn ? google_calendar_schedules : schedules}
                        method={method} />
                    </div>
                    <div>
                      <label>Chief Complaint: </label>
                      <input 
                        type="text" 
                        id="eventChiefComplaint" 
                        name="eventChiefComplaint" 
                        className="form-control" 
                        placeholder="Enter chief complaint" 
                        onChange={e => handleSetEventData(e.target)}
                        value={event.eventChiefComplaint} />
                    </div>
                    <div>
                      <label>Description: </label>
                      <textarea 
                        className="form-control" 
                        id="eventDescription" 
                        name="eventDescription" 
                        rows="2"
                        onChange={e => handleSetEventData(e.target)}
                        value={event.eventDescription} />
                    </div>
                </form>
              </div>
              <div className="modal-body" style={{ paddingTop: 0 }}>
                <div className="rbt-parent">
                  <label>Attendees: </label>
                  {/* <div className="guests-container">
                    {selected_attendees.length === 0 && (
                      <span className="badge badge-light">No attendees yet.</span>
                    )}
                    {selected_attendees.map((guest, i) => (
                      <Fragment key={guest.displayName}>
                        <div className="guest-item">
                          <button type="button" className="guest-item-button" onClick={() => handleRemoveAttendees(i)} >-</button>
                          <span>{guest.displayName}</span>
                        </div>
                      </Fragment>
                    ))}
                  </div> */}
                  <Typeahead
                    id="attendees-typeahead"
                    placeholder="Search contacts"
                    multiple
                    onChange={selected => {
                      setAttendees(selected);
                    }}
                    onInputChange={(e) => debouncedHandleSearchContact(e)}
                    options={suggestions}
                    maxResults={999999999}
                    labelKey={'displayName'}
                    filterBy={['displayName']}
                    selected={attendees}
                    renderMenu={(results, menuProps) => {
                      const myContacts = results.filter(item => !item.isNotContact);
                      const nonContacts = results.filter(item => item.isNotContact);
                      
                      return (
                        <Menu {...menuProps}>
                          {myContacts.length > 0 && (
                            <div 
                              className="hc-primary-text" 
                              style={{ marginLeft: '15px', marginBottom: '5px', fontSize: '14px' }}>MY CONTACTS</div>
                          )}
                          {myContacts.map(result => (
                            <MenuItem key={result.objectId} option={result}>
                              <img 
                                src={(result.thumbnail || {}).url || imgPlaceHolder} 
                                width="25" 
                                height="25"
                                style={{ borderRadius: '50%', marginRight: '5px' }} /> {result.displayName}
                            </MenuItem>
                          ))}
                          {nonContacts.length > 0 && (
                            <div 
                              className="hc-primary-text" 
                              style={{ marginLeft: '15px', margin: '5px 0px 5px 15px', fontSize: '14px' }}>
                              NOT IN MY CONTACT LIST<br />
                              <em style={{ fontSize: '12px', color: '#8e8e8e' }}>(Go to Contacts to add new user to contact list)</em>
                            </div>
                          )}
                          {nonContacts.map(result => (
                            <MenuItem key={result.objectId} option={result} style={{ pointerEvents: 'none', background: '#efefef' }}>
                              <img 
                                src={(result.thumbnail || {}).url || imgPlaceHolder} 
                                width="25" 
                                height="25"
                                style={{ borderRadius: '50%', marginRight: '5px' }} /> {result.displayName}
                            </MenuItem>
                          ))}
                          {myContacts.length === 0 && nonContacts.length === 0 && (
                            <a role="option" className="dropdown-item disabled" href="#">No matches found.</a>
                          )}
                        </Menu>
                      )
                    }}
                  />
                  {/* <div style={{ marginTop: '10px', textAlign: 'right' }}>
                    <button 
                      type="button" 
                      className={`btn ${attendees.length > 0 ? 'contact-menu-button-active' : 'contact-menu-button'}`} 
                      disabled={attendees.length > 0 ? false : true}
                      id="addAttendeeButton" 
                      style={{ marginRight: '2px' }}
                      onClick={() => handleSetSelectedAttendees()}>
                      Add Attendee(s)
                    </button>
                  </div> */}
                </div>
              </div>
              <div className="modal-footer" style={{border: "0", padding: "0 20px 20px 0"}}>
                <button 
                  type="button" 
                  className="btn btn-hubchart btn-hubchart-primary" 
                  onClick={() => saveEvent()}
                  disabled={loading || attendees.length === 0}>
                    {`${loading ? 'Saving...' : 'Save'}`}
                  </button>
                <button 
                  type="button" 
                  className="btn btn-hubchart btn-hubchart-close" 
                  onClick={() => handleClose()}>Close</button>
                {/* <button 
                  type="button" 
                  className="btn btn-light" 
                  onClick={() => signOutOfGoogle()(dispatch)}>Sign Out</button> */}
              </div>
            </Fragment>
          )}
          {isLoadingSchedules && <TabLoader isLoading={isLoadingSchedules} />}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  filterResult: state.contact.filterResult,
  otherUsersResult: state.contact.otherUsersResult,
});

export default connect(mapStateToProps, { searchContacts })(ManageEventModal);