const defaultList = [
  {
    code: "E11.9",
    isValidHIPAA: "1",
    longDescription: "Type 2 diabetes mellitus without complications",
    orderNumber: null,
    shortDescription: "Type 2 diabetes mellitus without complications",
  },
  {
    code: "E11.65",
    isValidHIPAA: "1",
    longDescription: "Type 2 diabetes mellitus with hyperglycemia",
    orderNumber: null,
    shortDescription: "Type 2 diabetes mellitus with hyperglycemia",
  },
  {
    code: "E11.40",
    isValidHIPAA: "1",
    longDescription: "Type 2 diabetes mellitus with diabetic neuropathy, unsp",
    orderNumber: null,
    shortDescription: "Type 2 diabetes mellitus with diabetic neuropathy, unsp",
  },
  {
    code: "E11.29",
    isValidHIPAA: "1",
    longDescription: "Type 2 diabetes mellitus with other diabetic kidney complication",
    orderNumber: null,
    shortDescription: "Type 2 diabetes mellitus with other diabetic kidney complication",
  },
  {
    code: "N39.0",
    isValidHIPAA: "1",
    longDescription: "Urinary tract infection, site not specified",
    orderNumber: null,
    shortDescription: "Urinary tract infection, site not specified",
  },
  {
    code: "K21.9",
    isValidHIPAA: "1",
    longDescription: "Gastroesophageal reflux disease without esophagitis",
    orderNumber: null,
    shortDescription: "Gastroesophageal reflux disease without esophagitis",
  },
  {
    code: "E03.9",
    isValidHIPAA: "1",
    longDescription: "Hypothyroidism, unspecified",
    orderNumber: null,
    shortDescription: "Hypothyroidism, unspecified",
  },
  {
    code: "F41.9",
    isValidHIPAA: "1",
    longDescription: "Anxiety disorder, unspecified",
    orderNumber: null,
    shortDescription: "Anxiety disorder, unspecified",
  },
  {
    code: "N40.0",
    isValidHIPAA: "1",
    longDescription: "Enlarged prostate without lower urinary tract symptoms148.91 Unspecified atrial fibrillation",
    orderNumber: null,
    shortDescription: "Enlarged prostate without lower urinary tract symptoms148.91 Unspecified atrial fibrillation",
  },
  {
    code: "R19.7",
    isValidHIPAA: "1",
    longDescription: "Diarrhea, unspecified",
    orderNumber: null,
    shortDescription: "Diarrhea, unspecified",
  },
  {
    code: "N30.00",
    isValidHIPAA: "1",
    longDescription: "Acute cystitis without hematuria",
    orderNumber: null,
    shortDescription: "Acute cystitis without hematuria",
  },
  {
    code: "R05",
    isValidHIPAA: "1",
    longDescription: "Cough",
    orderNumber: null,
    shortDescription: "Cough",
  },
  {
    code: "R60.0",
    isValidHIPAA: "1",
    longDescription: "Localized edema",
    orderNumber: null,
    shortDescription: "Localized edema",
  },
  {
    code: "R60.1",
    isValidHIPAA: "1",
    longDescription: "Generalized edema",
    orderNumber: null,
    shortDescription: "Generalized edema",
  },
  {
    code: "R42",
    isValidHIPAA: "1",
    longDescription: "Dizziness and giddiness",
    orderNumber: null,
    shortDescription: "Dizziness and giddiness",
  },
];

export {
  defaultList,
};