import React, { useState, useEffect, useCallback } from 'react';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import * as serviceWorker from '../../serviceWorker';

const ServiceWorkerWrapper = () => {
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);

  /*
    Handle when service worker detects an
    update: Any file changed, whether in the app
    or the service worker code itself
  */
  const onSWUpdate = (registration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  const onSWWaiting = (waitingWorker) => {
    setShowReload(true);
    setWaitingWorker(waitingWorker);
  }

  /*
    A function that reloads the page
  */
  const reloadPage = useCallback(() => {

    // Tell the service worker to skip waiting
    if (waitingWorker) {
      waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    }

    setShowReload(false);
    
  }, [waitingWorker]);

  /*
    Run this on mount:
  */
  useEffect(() => {

    // Register our service worker, with a handler to onUpdate
    serviceWorker.register({
      onUpdate: onSWUpdate,
      onWaiting: onSWWaiting,
    });

    // Unregister our service worker
    // serviceWorker.unregister();
  }, []);

  useEffect(() => {

    if (!waitingWorker) return;

    // We setup an event listener to automatically reload the page
    // after the Service Worker has been updated, this will trigger
    // on all the open tabs of our application, so that we don't leave
    // any tab in an incosistent state
    waitingWorker.addEventListener('statechange', event => {
      if (event.target.state === 'activated') {
        window.location.reload();
      }
    });

    return () => {
      waitingWorker.removeEventListener('statechange');
    }
  }, [waitingWorker]);

  return (
    <Snackbar
      open={showReload}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert severity="warning" elevation={3}>
        <span className="mr-2">
          Hubchart has new updates!
        </span>
        <button className="btn btn-sm btn-primary py-0 px-2" style={{fontSize: '14px'}} onClick={e => reloadPage(e)}>
          Update now
        </button>
      </Alert>
    </Snackbar>
  );
}

export default ServiceWorkerWrapper;