import {
  SET_PATIENT_ORDERS,
  PATIENT_ORDERS_LOADING,
  SET_PATIENT_ORDER,
  PATIENT_ORDER_LOADING,
  // CREATE_PATIENT_ORDER_SUCCESS,
  // CREATE_PATIENT_ORDER_FAILED,
  // IS_CREATING_PATIENT_ORDER,
  // UPDATE_PATIENT_ORDER_SUCCESS,
  // UPDATE_PATIENT_ORDER_FAILED,
  // DELETE_PATIENT_ORDER_SUCCESS,
  PATIENT_ORDERS_HISTORY_LOADING,
  SET_PATIENT_ORDERS_HISTORY,
  IS_CREATING_PRESCRIPTION_HISTORY,
  PRESCRIPTIONS_HISTORY_LOADING,
  PRESCRIPTION_HISTORY_LOADING,
  SET_PRESCRIPTIONS_HISTORY,
  SET_PRESCRIPTION_HISTORY,
  IS_CREATING_GROUPED_LIST,
  GROUPED_LISTS_LOADING,
  GROUPED_LIST_LOADING,
  SET_GROUPED_LISTS,
  SET_GROUPED_LIST,
  E_SIGN_LOADING,
  SET_PATIENT_INFO_REDUCED,
  SET_PATIENT_MEDICATIONS,
  IS_PATIENT_MEDICATIONS_LOADING,
  SET_PATIENT_PROBLEMS,
  IS_PATIENT_PROBLEMS_LOADING,
  SET_PATIENT_DIAGNOSES,
  IS_PATIENT_DIAGNOSES_LOADING,
  SET_RECENT_SEARCH
} from "../actions/types";
const initialState = {
  // List of patients
  recentPatients: [],

  // List of search patients
  searchResults: [],

  // If fetching items in progress
  isLoading: false,

  // If searching items in progress
  isLoadingSearch: false,

  // If show search results
  isSearching: false,

  // The current page viewed (Search)
  page: 0,

  // If more items can be loaded by scrolling (Search)
  hasMore: false,

  // Whether fetching for the first time
  initialLoad: true,
  patientInfo: {},
  patientInfoReduced: {},
  patientOrders: [],
  patientOrdersHistory: [],
  patientOrder: {},
  isOrdersLoading: false,
  isOrderLoading: false,
  isOrdersHistoryLoading: false,
  prescriptionsHistory: [],
  prescriptionHistory: {},
  isCreatingPrescriptionHistory: false,
  isPrescriptionsHistoryLoading: false,
  isPrescriptionHistoryLoading: false,
  groupedLists: [],
  groupedList: {},
  isCreatingGroupedList: false,
  isGroupedListsLoading: false,
  isGroupedListLoading: false,
  isESignLoading: false,
  patientMedicationsLoading: false,
  patientMedications: [],
  patientProblemsLoading: false,
  patientProblems: [],
  patientDiagnosesLoading: false,
  patientDiagnoses: [],
  recentSearch: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    // Set isLoading
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.value,
      };
      break;

    // Set isLoading
    case "SET_LOADING_SEARCH":
      return {
        ...state,
        isLoadingSearch: action.value,
      };
      break;

    // Set isLoading
    case "SET_IS_SEARCHING":
      return {
        ...state,
        isSearching: action.value,
      };
      break;

    // Set isLoading
    case "SET_RECENT_PATIENTS":
      return {
        ...state,
        recentPatients: action.recents,
        initialLoad: false,
      };
      break;

    case "SEARCH_RECENT_PATIENTS":
      const loadMore = action.page > 0;

      const searchResults = loadMore ? [...state.searchResults, ...action.results] : [...action.results];

      const { page, hasMore } = action;

      return {
        ...state,
        searchResults,
        page,
        hasMore,
      };
      break;

    case "SET_PATIENT_INFO":
      return {
        ...state,
        patientInfo: action.patientInfo,
      };
      break;

    case SET_PATIENT_INFO_REDUCED:
      return {
        ...state,
        patientInfoReduced: action.patientInfoReduced,
      };
      break;

    case SET_PATIENT_MEDICATIONS:
      return {
        ...state,
        patientMedications: action.patientMedications,
      };
      break;

    case IS_PATIENT_MEDICATIONS_LOADING:
      return {
        ...state,
        patientMedicationsLoading: action.patientMedicationsLoading,
      };
      break;

    case SET_PATIENT_PROBLEMS:
      return {
        ...state,
        patientProblems: action.patientProblems,
      };
      break;

    case IS_PATIENT_PROBLEMS_LOADING:
      return {
        ...state,
        patientProblemsLoading: action.patientProblemsLoading,
      };
      break;

    case SET_PATIENT_DIAGNOSES:
      return {
        ...state,
        patientDiagnoses: action.patientDiagnoses,
      };
      break;

    case IS_PATIENT_DIAGNOSES_LOADING:
      return {
        ...state,
        patientDiagnosesLoading: action.patientDiagnosesLoading,
      };
      break;

    case SET_PATIENT_ORDERS:
      return {
        ...state,
        patientOrders: action.patientOrders,
      };
      break;
    case SET_PATIENT_ORDER:
      return {
        ...state,
        patientOrder: action.patientOrder,
      };
      break;
    case PATIENT_ORDERS_LOADING:
      return {
        ...state,
        isOrdersLoading: action.isOrdersLoading,
      };
      break;
    case PATIENT_ORDER_LOADING:
      return {
        ...state,
        isOrderLoading: action.isOrderLoading,
      };
      break;
    case SET_PATIENT_ORDER:
      return {
        ...state,
        patientOrder: action.patientOrder,
      };
      break;
    case PATIENT_ORDER_LOADING:
      return {
        ...state,
        isOrdersLoading: action.isOrdersLoading,
      };
      break;
    case SET_PATIENT_ORDERS_HISTORY:
      return {
        ...state,
        patientOrdersHistory: action.patientOrdersHistory,
      };
      break;
    case PATIENT_ORDERS_HISTORY_LOADING:
      return {
        ...state,
        isOrdersHistoryLoading: action.isOrdersHistoryLoading,
      };
      break;
    case IS_CREATING_PRESCRIPTION_HISTORY:
      return {
        ...state,
        isCreatingPrescriptionHistory: action.isCreatingPrescriptionHistory,
      };
      break;

    case PRESCRIPTIONS_HISTORY_LOADING:
      return {
        ...state,
        isPrescriptionsHistoryLoading: action.isPrescriptionsHistoryLoading,
      };
      break;
    case PRESCRIPTION_HISTORY_LOADING:
      return {
        ...state,
        isPrescriptionHistoryLoading: action.isPrescriptionHistoryLoading,
      };
      break;
    case SET_PRESCRIPTIONS_HISTORY:
      return {
        ...state,
        prescriptionsHistory: action.prescriptionsHistory,
      };
      break;
    case SET_PRESCRIPTION_HISTORY:
      return {
        ...state,
        prescriptionHistory: action.prescriptionHistory,
      };
      break;

    case IS_CREATING_GROUPED_LIST:
      return {
        ...state,
        isCreatingGroupedList: action.isCreatingGroupedList,
      };
      break;

    case GROUPED_LISTS_LOADING:
      return {
        ...state,
        isGroupedListsLoading: action.isGroupedListsLoading,
      };
      break;
    case GROUPED_LIST_LOADING:
      return {
        ...state,
        isGroupedListLoading: action.isGroupedListLoading,
      };
      break;
    case SET_GROUPED_LISTS:
      return {
        ...state,
        groupedLists: action.groupedLists,
      };
      break;
    case SET_GROUPED_LIST:
      return {
        ...state,
        groupedList: action.groupedList,
      };
      break;

    case E_SIGN_LOADING:
      return {
        ...state,
        isESignLoading: action.isESignLoading,
      };
      break;

    case SET_RECENT_SEARCH:
      // console.log('actions', action);
      return {
        ...state,
        recentSearch: action.recentSearch,
      };
      break;
      
    default:
      return state;
  }
}
