import React, { useState, useEffect, useRef } from 'react';
import Twilio from 'twilio-video';

const ScreenVideo = ({ screenTrack }) => {

  const screenRef = useRef();

  useEffect(() => {

    screenTrack.attach(screenRef.current);
  
    return () => {
      // And detach it upon unmount
      screenTrack.detach();
    };
  }, [screenTrack]);
  

  return (
    <video ref={screenRef} autoPlay={true} />
  );
};

export default ScreenVideo;

