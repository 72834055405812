import React, { Component } from "react";
import ContactList from './ContactList';
import { connect } from 'react-redux';
import { fetchContacts, getContacts, searchContacts, searchStatus, otherUsers, fetchRequests } from '../../../actions/ContactAction';
import { createThread } from '../../../actions/ThreadAction';
import LoadingSpinner from '../../../components/Conversation/Bubble/LoadingSpinner'

class ContactContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingMoreContacts : false,
            preventReload : false,
            searchTimeout: null,
        }

        this.handleCreateThread = this.handleCreateThread.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        if (!this.props.contactInitialLoad) {
            this.props.fetchContacts(false, this.props.page);
            this.props.fetchRequests(false, this.props.page);
        }
        this.content.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        this.props.searchStatus(false);
        this.content.removeEventListener('scroll', this.handleScroll);
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        if (this.props.typeValue !== "") {
            if(prevProps.typeValue !== this.props.typeValue) {

                // Everytime the user types something in search input...
                const {searchTimeout} = this.state;

                // Clear the currently running timer
                // Reset it basically
                if (searchTimeout) {
                    clearTimeout(searchTimeout);  
                }
                
                // Create new one
                const newSearchTimeout = setTimeout(() => {
                    this.props.searchContacts(this.props.typeValue);
                }, 500);

                // Update the state with the new timeout
                this.setState({searchTimeout: newSearchTimeout});
            }
        } else {
            if (this.props.isSearchingContact) {
                this.props.searchStatus(false);
            }

        }

        return null;
    }

    componentDidUpdate() {

    }

    handleCreateThread(data, type) {
        this.props.createThread(data.contact, type);
    }

    handleScroll(event) {
        let initialHeight = event.target.scrollHeight;
        let height = event.target.offsetHeight;
        let scrollTop = event.target.scrollTop;
        if (scrollTop + height >= initialHeight) {
            if (!this.state.isLoadingMoreContacts) {
                if (!this.state.preventReload) {
                    this.setState({
                        isLoadingMoreContacts : true
                    })
                    this.loadMoreContacts();
                }
            }
        }
    }

    loadMoreContacts() {
        this.props.fetchContacts(true, this.props.page).then((itemLength) => {
            if (itemLength === 0) {
                this.setState({
                    preventReload : true
                })
            }
            this.setState({
                isLoadingMoreContacts : false
            })
        }).catch(() => {
            this.setState({
                isLoadingMoreContacts : false
            })
        });
    }

    render() {
        const {isLoadingSearch} = this.props;

        return (
        	<div className="Tab-Container" ref={ul=>this.content=ul}>
                { !this.props.contactInitialLoad && <LoadingSpinner />}
                <ContactList {...this.props} isLoadingSearch={isLoadingSearch} isLoadingMoreContacts={this.state.isLoadingMoreContacts}/>
        	</div>
        )
    }
}

const mapStateToProps = state => ({
    contactInitialLoad : state.contact.initialLoad,
    contacts : state.contact.contacts,
    requests : state.contact.requests,
    activeThread : state.thread.activeThread,
    page : state.contact.contactPage,
    isSearchingContact : state.contact.isSearchingContact,
    filterResult : state.contact.filterResult,
    otherUsersResult : state.contact.otherUsersResult,
    isLoadingSearch: state.contact.isLoadingSearch,
})

export default connect(mapStateToProps, { fetchContacts, getContacts, createThread, searchContacts, searchStatus, otherUsers, fetchRequests })(ContactContainer);
