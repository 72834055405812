import React, { Component } from 'react'
import Dropbox from './Dropbox'
import '../../Conversation/Setting/Setting.css'
import './Sidebar.css'
const grid = 8;
const $ = window.$;

export class SideBar extends Component {

  constructor() {
    super();

    this.state = {
      openGallery: false,
      activeGallery: "",
    };

    this.closeNav = this.closeNav.bind(this);
  }

  openNav(type) {
    this.setState({
      openGallery: true,
      activeGallery: type
    })
  }

  closeNav() {
    this.setState({
      openGallery: false,
    })
  }

  openSideBar() {
    this.props.onDocumentOpen();
  }

  closeSideBar() {
    this.props.onDocumentClose();
  }

  removeFilter() {
    this.props.onFilter('');
  }

  setFilter(filter) {
    this.props.onFilter(filter);
  }

  render() {

    const dropdownBaseStyle = {
      height: "100%",
      zIndex: 0,
      outline: "none"
    }

    const activeStyle = {
      display: "block",
      border: "2px dashed green"
    }

    const { documentIsOpen } = this.props;

    let containerWidth;

    if (documentIsOpen) {
      containerWidth = '300px';
    }
    else {
      containerWidth = '65px';
    }

    const {filter, links} = this.props;

    return (
      <div className="Document-Panel" ref="documentPanel">
        <div className="Document-Container" ref="documentContainer" style={{ width: containerWidth }}>
          <div className="Save-File-Container">
            {documentIsOpen ?
              <div style={{ padding: '10px 0px 0px 20px' }}>
                <i 
                  className="fa fa-times-circle animate-fade" 
                  onClick={this.closeSideBar.bind(this)}
                  style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                  }}></i>
                <span className="hc-primary-text animate-fade" style={{ fontSize: "1.2rem", fontWeight: 'bold' }}>
                  DOCUMENT HUB
                </span>
              </div>
              :
              <button onClick={this.openSideBar.bind(this)} className="btn btn-hubchart btn-hubchart-primary animate-fade" style={{ height: '100%', fontSize: '12px', fontWeight: 'bold', boxShadow: '-2px 2px 8px #888888' }}>
                DOC HUB
              </button>
            }
          </div>
          <div className="setting-container" style={{ textAlign: "left", top: "50px" }}>
            <div style={{ position: 'relative' }}>
              <div className="detail-padding">
                {documentIsOpen ?
                  <div className="file-details animate-fade">
                    <span className="nowrap">Drag - drop files on designated hub</span><br />
                    <span className="nowrap">(Trains AI for file sorting)</span>
                  </div>
                  :
                  <div data-toggle="tooltip" data-placement="top" data-html="true" title="" data-original-title="Drag - drop files on designated hub" className="file-details animate-fade" align="center">
                    <span className="fa fa-info"></span>
                  </div>
                }
              </div>

              <div onClick={() => this.removeFilter()} className={filter === '' ? 'detail-container active' : 'detail-container'}>
                <p className="action">
                  <img data-toggle="tooltip" data-placement="top" data-html="true" title="" data-original-title="All files" style={{ height: "25px", marginRight: "20px" }} src={require('../../../assets/images/papers.png')} />
                  {documentIsOpen && <span className="document-category"> All files </span>}
                </p>
              </div>

              {links.map(link =>
                <Dropbox target={link.dropzoneRef}>
                  <div onClick={() => this.setFilter(link.dropzoneRef)} className={link.dropzoneRef === filter ? 'detail-container active' : 'detail-container'}>
                    <p className="action">
                      <img
                        data-toggle="tooltip"
                        data-placement="top"
                        data-html="true"
                        title=""
                        data-original-title={link.label}
                        style={{ height: "25px", marginRight: "20px" }}
                        src={link.imageSrc} />

                      {documentIsOpen ?
                        <span className="document-category">{link.label}</span>
                        : ''
                      }
                    </p>
                  </div>
                </Dropbox>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SideBar;
