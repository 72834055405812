import React, { useMemo } from 'react';

const OrgEmails = ({emails=[]}) => {

  const joined = useMemo(() => {
    return emails.join(', ');
  }, [emails]);

  return (
    <div>
      <i className="fas fa-envelope hc-primary-text mr-1"></i>
      {joined}
    </div>
  )
}

export default OrgEmails;
