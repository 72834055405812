import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";
import util from "../../../helper/util";
import InsuranceForm from "./InsuranceForm";
import OtherInsuranceForm from "./OtherInsuranceForm";
import Parse from "parse";
import { ToastContainer } from "react-toastify";
import Notify from "../../Common/Notify";
import InsuranceApi from "../../../api/Insurance";
import useInsurance from "./InsuranceForm/useInsurance";
import useOtherInsurance from "./OtherInsuranceForm/useInsurance";
import useServiceLocation from "./useServiceLocation";

import "./PatientForm.css";

function PatientForm({
  setLoading,
  patient = {},
  onSave,
  onPrint,
  onEdit,
  editMode,
}) {
  const [formData, setFormData] = useState({ emergencyContact: {} });
  const [insuranceProviders, setInsuranceProviders] = useState([]);

  const primaryInsurance = useInsurance(
    formData.primaryInsurance,
    insuranceProviders
  );

  const secondaryInsurance = useInsurance(
    formData.secondaryInsurance,
    insuranceProviders
  );

  const otherInsurance = useOtherInsurance(
    formData.otherInsurance,
    insuranceProviders
  );

  const serviceLocation = useServiceLocation(formData.serviceLocation);

  const iStyles = {
    color: "#2194f3",
    fontWeight: "bold",
  };

  useEffect(() => {
    setFormData(() => {
      const newPatient = { ...patient };

      if (!newPatient.primaryInsurance) newPatient.primaryInsurance = {};
      if (!newPatient.secondaryInsurance) newPatient.secondaryInsurance = {};
      if (!newPatient.otherInsurance) newPatient.otherInsurance = {};
      if (!newPatient.emergencyContact) newPatient.emergencyContact = {};

      return newPatient;
    });
  }, [patient]);

  useEffect(() => {
    const init = async () => {
      const providers = await InsuranceApi.getProviders();

      setInsuranceProviders(providers);
    };

    init();
  }, []);

  useEffect(() => {
    if (formData.primaryInsurance && formData.primaryInsurance.objectId) {
      setInsuranceProviders((providers) => {
        const { provider } = formData.primaryInsurance;

        if (!provider) return providers;

        const existingProvider = providers.find(
          (p) => p.objectId === provider.objectId
        );

        return !existingProvider
          ? [...providers, formData.primaryInsurance.provider]
          : [...providers];
      });
    }
  }, [formData.primaryInsurance]);

  useEffect(() => {
    if (formData.secondaryInsurance && formData.secondaryInsurance.objectId) {
      setInsuranceProviders((providers) => {
        const { provider } = formData.secondaryInsurance;

        if (!provider) return providers;

        const existingProvider = providers.find(
          (p) => p.objectId === provider.objectId
        );

        return !existingProvider
          ? [...providers, formData.secondaryInsurance.provider]
          : [...providers];
      });
    }
  }, [formData.secondaryInsurance]);

  useEffect(() => {
    if (formData.otherInsurance && formData.otherInsurance.objectId) {
      setInsuranceProviders((providers) => {
        const { provider } = formData.otherInsurance;

        if (!provider) return providers;

        const existingProvider = providers.find(
          (p) => p.objectId === provider.objectId
        );

        return !existingProvider
          ? [...providers, formData.otherInsurance.provider]
          : [...providers];
      });
    }
  }, [formData.otherInsurance]);

  const handleSave = (e) => {
    e.preventDefault();

    const circle = formatCircle(formData);

    const primary = formatInsurance(primaryInsurance);

    const secondary = formatInsurance(secondaryInsurance);

    const other = formatInsurance(otherInsurance);

    const _serviceLocation = serviceLocation.getParseObject();

    onSave(circle, primary, secondary, other, _serviceLocation);
  };

  const formatInsurance = (insurance) => {
    const {
      objectId,
      ipa,
      policyNumber,
      provider,
      planName,
      co_payment,
      primary_doctor,
      payment_method,
      comments,
    } = insurance;

    const Insurance = Parse.Object.extend("Insurance");
    const InsuranceProvider = Parse.Object.extend("InsuranceProvider");

    const newInsurance = new Insurance({
      objectId,
      ipa,
      policyNumber,
      planName,
      co_payment,
      primary_doctor,
      payment_method,
      comments,
    });

    if (provider) {
      const newInsuranceProvider = new InsuranceProvider({
        objectId: provider.objectId,
        name: provider.name,
      });

      newInsurance.set("provider", newInsuranceProvider);
    }

    return newInsurance;
  };

  const formatCircle = (circle) => {
    const data = {
      objectId: circle.objectId,
      patientId: circle.patientId,
      firstName: circle.firstName,
      lastName: circle.lastName,
      dob: circle.dob,
      dateOfDischarge: circle.dateOfDischarge,
      dateOfAdmission: circle.dateOfAdmission,
      emergencyContact: circle.emergencyContact,
      gender: circle.gender,
      allergies: circle.allergies,
      address: circle.address,
      phoneNumber: circle.phoneNumber,
      landlineNumber: circle.landlineNumber,
      email: circle.email,
      cprStatus: circle.cprStatus,
      advanceDirective: circle.advanceDirective,
      contactMethod: circle.contactMethod,
      contactPersonLastName: circle.contactPersonLastName,
      contactPersonFirstName: circle.contactPersonFirstName,
      contactPersonRelation: circle.contactPersonRelation,
      contactPersonPhone: circle.contactPersonPhone,
      contactPersonLandline: circle.contactPersonLandline,
      pharmacyName: circle.pharmacyName,
      pharmacyAddress: circle.pharmacyAddress,
      pharmacyFax: circle.pharmacyFax,
      pharmacyPhone: circle.pharmacyPhone,
      pharmacyHubchartContactName: circle.pharmacyHubchartContactName,
    };

    const Circle = Parse.Object.extend("Circle");

    return new Circle(data);
  };

  const handleAddProvider = (provider) => {
    setInsuranceProviders((providers) => [...providers, provider]);
  };

  function handleChange(e, key) {
    setFormData({
      ...formData,
      [key]: e.target.value,
    });
  }

  function handleRadioButtonChange(e, key) {
    setFormData({
      ...formData,
      [key]: e.target.checked,
    });
  }

  function handleEContactChange(e, key) {
    setFormData({
      ...formData,
      emergencyContact: {
        ...formData.emergencyContact,
        [key]: e.target.value,
      },
    });
  }

  let imageURL = require("../../../assets/images/group-default.png");

  if (typeof formData.image !== "undefined") {
    imageURL = formData.image.url;
  }

  let age;

  if (formData.dob) age = util.computeAge(formData.dob);

  function copyFacesheetLink(patientId) {
    const facesheetUrl = `${window.location.origin}/facesheet/${patientId}`;
    const elem = document.createElement("textarea");

    elem.value = facesheetUrl;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);

    Notify.success("Facesheet link copied!");
  }

  return (
    <>
      <div id="section-to-print" className="print-panel patient-bypass">
        <div align="center">
          <br />
          <br />
          <h1 style={{ color: "#686868" }}>
            <i style={iStyles} className="fal fa-clipboard-user"></i> ADMISSION
            RECORD
          </h1>
          <h4>{formData.facility && formData.facility.name}</h4>
          <br />
        </div>
        {/* <div className="Form-Container" style={{ margin: "0", maxWidth: "100%" }}>
                    <div className="form-buttons">
                        <button onClick={() => window.close()} type="button" className="btn btn-hubchart btn-hubchart-close">
                            <i className="fa fa-chevron-left" style={{ marginRight: '3px' }}></i> Back
                        </button>
                        <button onClick={() => copyFacesheetLink(formData.objectId)} type="button" className="btn btn-hubchart btn-hubchart-success" style={{ marginRight: "15px" }}>
                            <i className="fa fa-copy" style={{ marginRight: '3px' }}></i> Copy Link
                        </button>
                        {editMode ?
                            <button onClick={handleSave} type="button" style={{ marginRight: "15px" }} className="btn btn-hubchart btn-hubchart-primary">
                                <i className="fa fa-save" style={{ marginRight: '3px' }}></i>
                            Save
                        </button>
                            :
                            <button onClick={onEdit} type="button" style={{ marginRight: "15px" }} className="btn btn-hubchart btn-hubchart-primary">
                                <i className="fa fa-edit" style={{ marginRight: '3px' }}></i>
                            Edit
                        </button>
                        }
                        <button disabled={editMode} onClick={onPrint} type="submit" className="btn btn-hubchart btn-hubchart-primary">
                            <i className="fa fa-print" style={{ marginRight: '3px' }}></i>
                        Print
                        </button>
                    </div>
                </div> */}
        <div className="row row-nomargin">
          <div className="col-md-12 no-padding">
            <div className="patient-category">
              <i style={iStyles} className="fal fa-fw fa-user"></i> RESIDENT
              INFORMATION
            </div>
          </div>
          <div
            className="col-md-4 padding-10"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img width="200" height="200" src={imageURL}></img>
          </div>

          {editMode ? (
            <div className="col-md-8 padding-10">
              <PatientField
                onChange={(e) => handleChange(e, "patientId")}
                label="Patient ID"
                value={formData.patientId}
                placeholder="Patient ID"
                iconClass="fas fa-fw fa-address-card"
              />
              <PatientField
                onChange={(e) => handleChange(e, "firstName")}
                label="First name"
                value={formData.firstName}
                placeholder="First name"
                iconClass="fas fa-fw fa-user"
              />
              <PatientField
                onChange={(e) => handleChange(e, "lastName")}
                label="Last name"
                value={formData.lastName}
                placeholder="Last name"
                iconClass="fas fa-fw fa-user"
              />
              <PatientField label="Gender" iconClass="fas fa-fw fa-venus-mars">
                <select
                  onChange={(e) => handleChange(e, "gender")}
                  value={formData.gender || ""}
                  className="form-control"
                >
                  <option value="" disabled>
                    Select a gender
                  </option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </PatientField>
              <PatientField
                onChange={(e) => handleChange(e, "dob")}
                label="Date of Birth"
                value={formData.dob}
                placeholder="mm/dd/yyyy"
                iconClass="fas fa-fw fa-calendar-alt"
              />
              {/* <PatientField onChange={(e) => handleChange(e,'age')} label="Age" value={age} placeholder="Age" iconClass="fas fa-fw fa-calendar-alt"/> */}
              <PatientField
                onChange={(e) => handleChange(e, "dateOfAdmission")}
                label="Date of Admission"
                value={formData.dateOfAdmission}
                placeholder="mm/dd/yyyy"
                iconClass="fas fa-fw fa-calendar-alt"
              />
              <PatientField
                onChange={(e) => handleChange(e, "dateOfDischarge")}
                label="Date of Discharge"
                value={formData.dateOfDischarge}
                placeholder="mm/dd/yyyy"
                iconClass="fas fa-fw fa-calendar-alt"
              />
              <div className="radio-btn-container">
                <span className="input-group-text hc-input-addon">
                  <i className="fas fa-fw fa-procedures"></i>&nbsp; CPR/Code
                  Status
                </span>
                <div className="radio-btn" id="cprStatus">
                  <label
                    className={
                      formData.cprStatus === "DNR" ? "hc-primary-text" : ""
                    }
                  >
                    <input
                      className="mr-1"
                      type="radio"
                      name="dnr"
                      value="DNR"
                      checked={formData.cprStatus === "DNR"}
                      onChange={(e) => handleChange(e, "cprStatus")}
                    />{" "}
                    DNR
                  </label>
                  <label
                    className={
                      formData.cprStatus === "Attempt CPR"
                        ? "hc-primary-text"
                        : ""
                    }
                  >
                    <input
                      className="mr-1"
                      type="radio"
                      name="attemptCpr"
                      value="Attempt CPR"
                      checked={formData.cprStatus === "Attempt CPR"}
                      onChange={(e) => handleChange(e, "cprStatus")}
                    />{" "}
                    Attempt CPR
                  </label>
                </div>
              </div>
              <div className="radio-btn-container">
                <span className="input-group-text hc-input-addon">
                  <i className="fas fa-fw fa-file-medical" />
                  &nbsp; Advance Directive
                </span>
                <div className="radio-btn" id="advanceDirective">
                  <label
                    className={
                      formData.advanceDirective === "Available on file"
                        ? "hc-primary-text"
                        : ""
                    }
                  >
                    <input
                      className="mr-1"
                      type="radio"
                      name="availableOnFile"
                      value="Available on file"
                      checked={
                        formData.advanceDirective === "Available on file"
                      }
                      onChange={(e) => handleChange(e, "advanceDirective")}
                    />{" "}
                    Available on file
                  </label>
                  <label
                    className={
                      formData.advanceDirective === "Not Available"
                        ? "hc-primary-text"
                        : ""
                    }
                  >
                    <input
                      className="mr-1"
                      type="radio"
                      name="noteAvailable"
                      value="Not Available"
                      checked={formData.advanceDirective === "Not Available"}
                      onChange={(e) => handleChange(e, "advanceDirective")}
                    />{" "}
                    Not Available
                  </label>
                  <label
                    className={
                      !formData.advanceDirective ? "hc-primary-text" : ""
                    }
                  >
                    <input
                      className="mr-1"
                      type="radio"
                      name="none"
                      value=""
                      checked={!formData.advanceDirective}
                      onChange={(e) => handleChange(e, "advanceDirective")}
                    />{" "}
                    None
                  </label>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-md-8 padding-10">
              <PatientValue
                label="Patient ID"
                value={formData.patientId}
                placeholder="Patient ID"
                iconClass="fas fa-fw fa-address-card"
              />
              <PatientValue
                label="Patient name"
                value={formData.firstName + " " + formData.lastName}
                placeholder="First name"
                iconClass="fas fa-fw fa-user"
              />
              <PatientValue
                label="Gender"
                value={formData.gender}
                iconClass="fas fa-fw fa-venus-mars"
              ></PatientValue>
              <PatientValue
                label="Date of Birth"
                value={formData.dob}
                placeholder="Date of birth"
                iconClass="fas fa-fw fa-calendar-alt"
              />
              <PatientValue
                label="Age"
                value={age}
                placeholder="Age"
                iconClass="fas fa-fw fa-calendar-alt"
              />
              <PatientValue
                label="Date of Admission"
                value={formData.dateOfAdmission}
                placeholder="Date of Admission"
                iconClass="fas fa-fw fa-calendar-alt"
              />
              <PatientValue
                label="Date of Discharge"
                value={formData.dateOfDischarge}
                placeholder="Date of Discharge"
                iconClass="fas fa-fw fa-calendar-alt"
              />
              <PatientValue
                label="CPR/Code Status"
                value={formData.cprStatus}
                placeholder="CPR/Code Status"
                iconClass="fas fa-fw fa-procedures"
              />
              <PatientValue
                label="Advance Directive"
                value={formData.advanceDirective}
                placeholder="Advance Directive"
                iconClass="fas fa-fw fa-file-medical"
              />
            </div>
          )}
        </div>

        <div className="row row-nomargin">
          <div className="col-md-12 no-padding">
            <div className="patient-category">
              <i style={iStyles} className="fal fa-clipboard-list"></i>{" "}
              Allergies
            </div>
          </div>

          {editMode ? (
            <div className="col-md-6 padding-10">
              <PatientField
                onChange={(e) => handleChange(e, "allergies")}
                label="Allergies"
                value={formData.allergies}
                placeholder="Allergies"
                id="allergies"
                iconClass="fas fa-fw fa-allergies"
              />
            </div>
          ) : (
            <div className="col-md-6 padding-10">
              <PatientValue
                label="Allergies"
                value={formData.allergies}
                placeholder="Allergies"
                iconClass="fas fa-fw fa-allergies"
              />
            </div>
          )}
        </div>

        <div className="row row-nomargin">
          <div className="col-md-12 no-padding">
            <div className="patient-category">
              <i style={iStyles} className="fal fa-fw fa-id-badge"></i> PATIENT
              CONTACT DETAILS
            </div>
          </div>

          {editMode && (
            <Fragment>
              <div className="col-md-6 padding-10">
                <PatientField
                  onChange={(e) => handleChange(e, "address")}
                  label="Address"
                  value={formData.address}
                  placeholder="Address"
                  iconClass="fas fa-fw fa-address-card"
                />
                <PatientField
                  onChange={(e) => handleChange(e, "phoneNumber")}
                  label="Mobile number"
                  value={formData.phoneNumber}
                  placeholder="Mobile number"
                  iconClass="fas fa-fw fa-mobile-alt"
                />
                <PatientField
                  onChange={(e) => handleChange(e, "landlineNumber")}
                  label="Landline number"
                  value={formData.landlineNumber}
                  placeholder="Landline number"
                  iconClass="fas fa-fw fa-phone"
                />
              </div>
              <div className="col-md-6 padding-10">
                <PatientField
                  onChange={(e) => handleChange(e, "email")}
                  label="Email"
                  value={formData.email}
                  placeholder="Email"
                  iconClass="fas fa-fw fa-mobile-alt"
                />

                <PatientField
                  label="Preferred communication"
                  iconClass="fas fa-fw fa-envelope"
                >
                  <select
                    onChange={(e) => handleChange(e, "contactMethod")}
                    value={formData.contactMethod}
                    className="form-control"
                  >
                    <option value="" disabled>
                      Select an item
                    </option>
                    <option value="mobile">Mobile</option>
                    <option value="landline">Landline</option>
                    <option value="email">Email</option>
                    <option value="text">Text</option>
                  </select>
                </PatientField>
              </div>
            </Fragment>
          )}

          {!editMode && (
            <Fragment>
              <div className="col-md-8 padding-10">
                <PatientValue
                  label="Address"
                  value={formData.address}
                  placeholder="Address"
                  iconClass="fas fa-fw fa-address-card"
                />
                <PatientValue
                  label="Mobile number"
                  value={formData.phoneNumber}
                  placeholder="Mobile number"
                  iconClass="fas fa-fw fa-mobile-alt"
                />
                <PatientValue
                  label="Landline number"
                  value={formData.landlineNumber}
                  placeholder="Landline number"
                  iconClass="fas fa-fw fa-phone"
                />
              </div>
              <div className="col-md-4 padding-10">
                <PatientValue
                  label="Email"
                  value={formData.email}
                  placeholder="Email"
                  iconClass="fas fa-fw fa-mobile-alt"
                />
                <PatientValue
                  label="Preferred communication"
                  value={formData.contactMethod}
                  placeholder="Preferred communication"
                  iconClass="fas fa-fw fa-envelope"
                />
              </div>
            </Fragment>
          )}
        </div>

        <div className="row row-nomargin">
          <div className="col-md-12 no-padding">
            <div className="patient-category">
              <i style={iStyles} className="fal fa-fw fa-id-badge"></i>{" "}
              EMERGENCY CONTACTS
            </div>
          </div>
          {editMode && (
            <Fragment>
              <div className="col-md-6 padding-10">
                <PatientField
                  onChange={(e) => handleEContactChange(e, "name")}
                  label="Name"
                  value={formData.emergencyContact.name}
                  placeholder="Name"
                  iconClass="fas fa-fw fa-user"
                />
                <PatientField
                  onChange={(e) => handleEContactChange(e, "address")}
                  label="Address"
                  value={formData.emergencyContact.address}
                  placeholder="Address"
                  iconClass="fas fa-fw fa-address-card"
                />
                <PatientField
                  onChange={(e) =>
                    handleEContactChange(e, "legal_representative")
                  }
                  label="Legal Representative"
                  value={formData.emergencyContact.legal_representative}
                  placeholder="Legal Representative"
                  iconClass="fas fa-fw fa-address-card"
                />
                <PatientField label="Type" iconClass="fas fa-fw fa-book-open">
                  <select
                    onChange={(e) => handleEContactChange(e, "type")}
                    value={formData.emergencyContact.type}
                    className="form-control"
                  >
                    <option value="" disabled>
                      Select an item
                    </option>
                    <option value="Power of Attorney">Power of Attorney</option>
                    <option value="Conversator">Conversator</option>
                    <option value="Guardian">Guardian</option>
                    <option value="Next of Kin">Next of Kin</option>
                  </select>
                </PatientField>
                <PatientField
                  onChange={(e) => handleEContactChange(e, "relationship")}
                  label="Relationship"
                  value={formData.emergencyContact.relationship}
                  placeholder="Relationship"
                  iconClass="fas fa-fw fa-people-arrows"
                />
              </div>
              <div className="col-md-6 padding-10">
                <PatientField
                  onChange={(e) => handleEContactChange(e, "mobileNumber")}
                  label="Mobile number"
                  value={formData.emergencyContact.mobileNumber}
                  placeholder="Mobile number"
                  iconClass="fas fa-fw fa-mobile-alt"
                />
                <PatientField
                  onChange={(e) => handleEContactChange(e, "landlineNumber")}
                  label="Landline number"
                  value={formData.emergencyContact.landlineNumber}
                  placeholder="Landline number"
                  iconClass="fas fa-fw fa-phone"
                />
                <PatientField
                  label="Preferred communication"
                  iconClass="fas fa-fw fa-envelope"
                >
                  <select
                    onChange={(e) => handleEContactChange(e, "contactMethod")}
                    value={formData.emergencyContact.contactMethod}
                    className="form-control"
                  >
                    <option value="" disabled>
                      Select an item
                    </option>
                    <option value="mobile">Mobile</option>
                    <option value="landline">Landline</option>
                  </select>
                </PatientField>
              </div>
            </Fragment>
          )}
          {!editMode && (
            <Fragment>
              <div className="col-md-8 padding-10">
                <PatientValue
                  label="Name"
                  value={formData.emergencyContact.name}
                  placeholder="Name"
                  iconClass="fas fa-fw fa-user"
                />
                <PatientValue
                  label="Address"
                  value={formData.emergencyContact.address}
                  placeholder="Address"
                  iconClass="fas fa-fw fa-address-card"
                />
                <PatientValue
                  label="Legal Representative"
                  value={formData.emergencyContact.legal_representative}
                  placeholder="Legal Representative"
                  iconClass="fas fa-fw fa-address-card"
                />
                <PatientValue
                  label="Type"
                  value={formData.emergencyContact.type}
                  placeholder="Type"
                  iconClass="fas fa-fw fa-book-open"
                />
                <PatientValue
                  label="Relationship"
                  value={formData.emergencyContact.relationship}
                  placeholder="Relationship"
                  iconClass="fas fa-fw fa-people-arrows"
                />
              </div>
              <div className="col-md-4 padding-10">
                <PatientValue
                  label="Mobile number"
                  value={formData.emergencyContact.mobileNumber}
                  placeholder="Mobile number"
                  iconClass="fas fa-fw fa-mobile-alt"
                />
                <PatientValue
                  label="Landline number"
                  value={formData.emergencyContact.landlineNumber}
                  placeholder="Landline number"
                  iconClass="fas fa-fw fa-phone"
                />
                <PatientValue
                  label="Preferred communication"
                  value={formData.emergencyContact.contactMethod}
                  placeholder="Preferred communication"
                  iconClass="fas fa-fw fa-envelope"
                />
              </div>
            </Fragment>
          )}
        </div>

        <div className="row row-nomargin">
          <div className="col-md-12 no-padding">
            <div
              className="patient-category"
              style={{ display: "flex", alignItems: "center" }}
            >
              <i
                style={iStyles}
                className="fal fa-fw fa-hospitals"
                style={{ marginRight: "10px" }}
              ></i>
              PAYER INFORMATION
              <span style={{ fontSize: "14px", marginLeft: "15px" }}>
                Updated: {moment(primaryInsurance.updatedAt).format("l")}
              </span>
            </div>
          </div>

          <div
            className={editMode ? "col-md-6 padding-10" : "col-md-4 padding-10"}
          >
            <InsuranceForm
              {...primaryInsurance}
              setLoading={setLoading}
              onAddProvider={handleAddProvider}
              editMode={editMode}
              providers={insuranceProviders}
              label="Primary insurance"
            />
          </div>

          <div
            className={editMode ? "col-md-6 padding-10" : "col-md-4 padding-10"}
          >
            <InsuranceForm
              {...secondaryInsurance}
              setLoading={setLoading}
              onAddProvider={handleAddProvider}
              editMode={editMode}
              providers={insuranceProviders}
              label="Secondary insurance"
            />
          </div>

          <div
            className={editMode ? "col-md-6 padding-10" : "col-md-4 padding-10"}
          >
            <OtherInsuranceForm
              {...otherInsurance}
              setLoading={setLoading}
              onAddProvider={handleAddProvider}
              editMode={editMode}
              providers={insuranceProviders}
              label="Other insurance"
            />
          </div>
        </div>

        <div className="row row-nomargin">
          <div className="col-md-12 no-padding">
            <div className="patient-category">
              <i style={iStyles} className="fal fa-hospital-user"></i> PLACE OF
              SERVICE
            </div>
          </div>

          {editMode ? (
            <div className="col-md-6 padding-10">
              <PatientField
                onChange={(e) => serviceLocation.onChangeName(e.target.value)}
                label="Place of service"
                value={serviceLocation.name}
                placeholder="Place of service"
                id="pos"
                iconClass="fas fa-fw fa-map-marked-alt"
              />
            </div>
          ) : (
            <div className="col-md-6 padding-10">
              <PatientValue
                label="Place of service"
                value={serviceLocation.name}
                placeholder="Place of service"
                iconClass="fas fa-fw fa-map-marked-alt"
              />
            </div>
          )}
        </div>

        <div className="row row-nomargin">
          <div className="col-md-12 no-padding">
            <div className="patient-category">
              <i style={iStyles} className="fal fa-clinic-medical"></i> PHARMACY
            </div>
          </div>

          {editMode ? (
            <>
              <div className="col-md-6 padding-10">
                <PatientField
                  onChange={(e) => handleChange(e, "pharmacyName")}
                  label="Pharmacy name"
                  value={formData.pharmacyName}
                  placeholder="Pharmacy name"
                  iconClass="fas fa-fw fa-hospital"
                />
                <PatientField
                  onChange={(e) => handleChange(e, "pharmacyAddress")}
                  label="Pharmacy address"
                  value={formData.pharmacyAddress}
                  placeholder="Pharmacy address"
                  iconClass="fas fa-fw fa-map-pin"
                />
                <PatientField
                  onChange={(e) =>
                    handleChange(e, "pharmacyHubchartContactName")
                  }
                  label="Hubchart contact name"
                  value={formData.pharmacyHubchartContactName}
                  placeholder="Hubchart contact name"
                  iconClass="fas fa-fw fa-user"
                />
              </div>

              <div className="col-md-6 padding-10">
                <PatientField
                  onChange={(e) => handleChange(e, "pharmacyFax")}
                  label="Fax number"
                  value={formData.pharmacyFax}
                  placeholder="Fax number"
                  iconClass="fas fa-fw fa-fax"
                />
                <PatientField
                  onChange={(e) => handleChange(e, "pharmacyPhone")}
                  label="Phone number"
                  value={formData.pharmacyPhone}
                  placeholder="Phone number"
                  iconClass="fas fa-fw fa-phone"
                />
              </div>
            </>
          ) : (
            <>
              <div className="col-md-4 padding-10">
                <PatientValue
                  label="Pharmacy name"
                  value={formData.pharmacyName}
                  placeholder="Pharmacy name"
                  iconClass="fas fa-fw fa-hospital"
                />
                <PatientValue
                  label="Pharmacy address"
                  value={formData.pharmacyAddress}
                  placeholder="Pharmacy address"
                  iconClass="fas fa-fw fa-map-pin"
                />
                <PatientValue
                  label="Hubchart contact name"
                  value={formData.pharmacyHubchartContactName}
                  placeholder="Hubchart contact name"
                  iconClass="fas fa-fw fa-user"
                />
              </div>

              <div className="col-md-8 padding-10">
                <PatientValue
                  label="Fax Number"
                  value={formData.pharmacyFax}
                  placeholder="Fax Number"
                  iconClass="fas fa-fw fa-fax"
                />
                <PatientValue
                  label="Phone number"
                  value={formData.pharmacyPhone}
                  placeholder="Phone number"
                  iconClass="fas fa-fw fa-phone"
                />
              </div>
            </>
          )}
        </div>

        <div className="row row-nomargin">
          <div className="col-md-12 no-padding">
            <div className="patient-category">
              <i style={iStyles} className="fal fa-user-md"></i> PHYSICIANS
            </div>
          </div>

          {!formData.physicians ? (
            <div className="col-md-6 padding-10">
              <p>N/A</p>
            </div>
          ) : (
            <>
              {formData.physicians.map((data, i) => {
                return (
                  <div className="col-md-4 padding-10">
                    <Physicians key={`ph-${i}`} physicians={data} />
                  </div>
                );
              })}
            </>
          )}
        </div>
        <div className="row row-nomargin">
          <div className="col-md-12 no-padding">
            <div className="patient-category">
              <i style={iStyles} className="fal fa-diagnoses"></i> DIAGNOSIS
            </div>
          </div>
          <div className="col-md-12 padding-10">
            {(formData.diagnosis || []).length > 0 && (
              <div className="diagnosis-container">
                <div className="diagnosis-flex-header">
                  <span>ICD10 Code</span>
                  <span>Description</span>
                  <span>Onset Date</span>
                </div>
                {formData.diagnosis.map((item, i) => (
                  <div
                    className="diagnosis-flex-item"
                    key={`dx-${item.code}${i}`}
                  >
                    <span>{item.code || "---"}</span>
                    <span>
                      {item.longDescription || item.shortDescription || "---"}
                    </span>
                    <span>{`${moment(new Date(item.onsetDate.iso)).format(
                      "MM/DD/YYYY"
                    )}`}</span>
                  </div>
                ))}
              </div>
            )}
            {(formData.diagnosis || []).length === 0 && <p>N/A</p>}
          </div>
        </div>
        {/* End of print view  */}
      </div>
      <div className="buttons-container">
        <div className="container">
          <div
            className="Form-Container"
            style={{ margin: "0", maxWidth: "100%" }}
          >
            <div className="form-buttons">
              <button
                onClick={() => window.close()}
                type="button"
                className="btn btn-hubchart btn-hubchart-close flexnowrap"
              >
                <i
                  className="fa fa-chevron-left"
                  style={{ marginRight: "3px" }}
                ></i>
                <span className="d-none d-sm-block d-sm-none d-md-block">
                  Back
                </span>
              </button>
              <button
                onClick={() => copyFacesheetLink(formData.objectId)}
                type="button"
                className="btn btn-hubchart btn-hubchart-success flexnowrap"
              >
                <i className="fa fa-copy" style={{ marginRight: "3px" }}></i>
                <span className="d-none d-sm-block d-sm-none d-md-block">
                  Copy Link
                </span>
              </button>
              {editMode ? (
                <button
                  onClick={handleSave}
                  type="button"
                  className="btn btn-hubchart btn-hubchart-primary flexnowrap"
                >
                  <i className="fa fa-save" style={{ marginRight: "3px" }}></i>
                  <span className="d-none d-sm-block d-sm-none d-md-block">
                    Save
                  </span>
                </button>
              ) : (
                <button
                  onClick={onEdit}
                  type="button"
                  className="btn btn-hubchart btn-hubchart-primary flexnowrap"
                >
                  <i className="fa fa-edit" style={{ marginRight: "3px" }}></i>
                  <span className="d-none d-sm-block d-sm-none d-md-block">
                    Edit
                  </span>
                </button>
              )}
              <button
                disabled={editMode}
                onClick={onPrint}
                type="submit"
                className="btn btn-hubchart btn-hubchart-primary flexnowrap"
              >
                <i className="fa fa-print" style={{ marginRight: "3px" }}></i>
                <span className="d-none d-sm-block d-sm-none d-md-block">
                  Print
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

function PatientField(props) {
  const {
    /* For input */
    label,
    placeholder,
    value,
    id = "",

    /* Icon */
    iconClass,
    children,
  } = props;

  return (
    <div className="input-group mb-2">
      <div className="input-group-prepend">
        <span className="input-group-text hc-input-addon">
          <i className={iconClass}></i>
          &nbsp;
          {label}
        </span>
      </div>
      {/* You can provide custom input */}
      {children ? (
        { ...children }
      ) : (
        <input
          onChange={props.onChange}
          value={value}
          type="text"
          className="form-control"
          placeholder={placeholder}
          id={id}
        />
      )}
    </div>
  );
}

function PatientValue(props) {
  const {
    /* For input */
    label,
    value,

    /* Icon */
    iconClass,
  } = props;

  return (
    <div className="input-group" style={{ boxShadow: "unset" }}>
      <div className="input-group-prepend">
        <span className="input-group-text hc-input-addon">
          <i className={iconClass}></i>
          &nbsp;
          {label}
        </span>
      </div>
      <div className="center-vertical">
        {value ? value : <span className="text-blank">---</span>}
      </div>
    </div>
  );
}

function Physicians(props) {
  const { physicians } = props;

  return (
    <div>
      <span style={{ fontWeight: "bold" }}>
        {physicians.fullName} - {physicians.specialty}
      </span>
      <br />
      <span>{physicians.address}</span>
      <br />
      <span>{physicians.contactNumber}</span>
    </div>
  );
}

export default PatientForm;
