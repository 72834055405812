import React, { Fragment } from "react";
import util from '../../../helper/util';
import moment from 'moment';
import { deleteEvent } from '../../../api/GoogleCalendarApi';

function ScheduleItem(props) {

    const { conference, onOpenConfirmModal, messageGroup } = props;

    const {
        objectId,
        createdAt,
        dateScheduled = {},
        dateFinished = {},
        timeStarted = {},
        timeEnded = {},
        participants = [],
        label = '',
        comments = '',
        dob = '',
        summary = '',
        start = {},
        end = {},
        description = '',
        orgRole = '',
        status,
        currentUser = {},
        host = {},
        joined = [],
    } = conference;

    const containerStyle = {
        minHeight: "65px",
    };
    
    const canEdit = (orgRole === 'physician' || currentUser.id === host.objectId) ? true : false;

    const split_description = comments.split('Hubchart Link:');
    
    const link = split_description[split_description.length - 1].replace(/\s/g, '');
    
    const imageUrl = require("../../../assets/images/default.png");

    const createdDate = util.formatConferenceDate(dateScheduled.iso);

    const duration = util.formatConferenceDuration(timeStarted.iso, timeEnded.iso);

    const filteredParticipants = participants.filter(p => p.objectId !== currentUser.id);

    const names = filteredParticipants.map(p => p.firstName).join(', ');

    const pics = filteredParticipants.map(p => {
        const url = p.picture ? p.picture.url : imageUrl;

        return (
            <div key={p.objectId    } className="img-conference-wrapper">
                <img src={url} width="25" height="25" />
            </div>
        );
    });

    const currentDate = moment();
    const startDate = moment(dateScheduled.iso);
    const finishedDate = moment(dateFinished.iso);
    const dayEndTime = moment(finishedDate).endOf('day');
    const endDate = moment((timeEnded || {}).iso || dayEndTime);
    const showJoin = startDate.isBefore(currentDate);
    const disableBadge = showJoin;
    const appendJoinButton = (currentDate.isBetween(startDate, endDate)) ? true : false;
    
    function renderPatientInfo() {
        return (
            <div className="mt-2">
                {label ?
                    <p className="name">
                        <i className="fas fa-tablet-alt hc-primary-text mr-2"></i>
                        {label}
                    </p>
                    : ''
                }
                {/* {dob ?
                    <p className="name">
                        <i className="fa fa-calendar hc-primary-text mr-2"></i>
                        {dob}
                    </p>
                    : ''
                }
                {comments ?
                    <p className="name">
                        <i className="fa fa-comments hc-primary-text mr-2"></i>
                        {comments}
                    </p>
                    : ''
                } */}
            </div>
        );
    }

    function joinMeeting (url) {
        window.open(url, '_blank');
    }

    return (
        <div 
            className="list-card-conference" 
            style={containerStyle} 
            style={{ 
                background: (joined.length === 0 && showJoin && !appendJoinButton) ? '#e5e5e5' : 'unset',
            }}>
            <div>
                {appendJoinButton && <button 
                    onClick={() => joinMeeting(link)/* props.onJoin(objectId) */} 
                    disabled={!showJoin && joined.length === 0} 
                    className="btn btn-sm btn-success btn-join-call">
                    <i className="fa fa-phone mr-1"></i>
                    Join call
                </button>}
                {!appendJoinButton && <div style={{ height: '10px' }} />}
                
                {label ?
                    renderPatientInfo():
                    <div className="mt-2">
                        <p className="name">
                            {label || 'No title'}
                            {/* Scheduled conference */}
                        </p>
                    </div>
                }
                <span style={{ position: 'absolute', right: '10px', top: '5px' }}>
                    <span className="badge badge-warning mr-1" style={{ background: disableBadge ? '#a9a9a9' : '' }}>{`${moment(dateScheduled.iso).format("MMM DD")}`}</span>
                    <span className="badge badge-warning mr-1" style={{ background: disableBadge ? '#a9a9a9' : '' }}>{`${moment(dateScheduled.iso).format("hh:mm A")}`}</span>
                </span>

                {/* <span className="badge badge-primary">{`${status}`}</span><br /> */}
                <p className="text-content">
                    {`${(names.length > 0) ? `with ${names}` : ''}`}
                </p>

                <p className="name conference-participants">
                    {pics}
                </p>
            </div>

            <div className="center-vertical">
                <div className="dropdown">
                    <button /* onClick={(e) => handleClick(e)} */ type="button" className="btn contact-menu-button dropdown-toggle" 
                    id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-v"></i>
                    </button>
                    <div style={{ boxShadow: '0px 4px 8px 0px #888888' }} className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                        <h6 className="dropdown-header">Action</h6>
                        {canEdit && (
                            <Fragment>
                                <a className="dropdown-item" href="#!" onClick={props.onOpenUpdateModal}>
                                    <i className="fa fa-edit" style={{ marginRight: '5px' }}></i>
                                    Enter/edit conference details
                                </a>
                                <a className="dropdown-item" href="#!" 
                                    onClick={() => onOpenConfirmModal({
                                        identifier: 'delete',
                                        title: 'Delete Schedule',
                                        body: 'Are you sure you want to delete this telemed schedule?'
                                    })}>
                                    <i className="fa fa-remove" style={{ marginRight: '5px' }}></i>
                                    Delete Schedule
                                </a>
                            </Fragment>
                        )}
                        <a className="dropdown-item" href="#!" onClick={() => messageGroup(conference)}>
                            <i className="fa fa-comment" style={{ marginRight: '5px' }}></i>
                            Create Message
                        </a>
                        {(!(timeEnded || {}).iso && canEdit) && (
                            <a className="dropdown-item" 
                                href="#!" 
                                onClick={() => onOpenConfirmModal({
                                    identifier: 'complete',
                                    title: 'Completed Schedule',
                                    body: 'Are you sure you want to set this telemed schedule to completed?'
                                })}>
                                <i className="fa fa-check" style={{ marginRight: '5px' }}></i>
                                Completed
                            </a>
                        )}
                        {/* <a className="dropdown-item" href="#!" onClick={props.onOpenForwardModal}>
                            <i className="fa fa-share-square" style={{ marginRight: '5px' }}></i>
                            Forward
                        </a> */}
                    </div>
                </div>
            </div>
            {/* {(timeEnded || {}).iso && <div style={{ marginTop: '7px' }}>
                <span className="badge badge-success">Completed <i className="fas fa-check"></i></span>
            </div>} */}
        </div>
    );
}

export default ScheduleItem;
