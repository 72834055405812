import Parse from 'parse';
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { acceptContact, addContact, blockContact } from "../../actions/ContactAction";
import util from "../../helper/util";

const ContactBanner = ({ contactId = "", status = "", blocked = {} }) => {
  
  const dispatch = useDispatch();

  const [hidden, setHidden] = useState(false);

  const handleRequest = () => {
    dispatch(addContact({ objectId: contactId }));
  };

  const handleAccept = () => {
    dispatch(acceptContact({ objectId: contactId }));
  };

  const handleBlock = () => {

    const yourId = Parse.User.current().id;
    const threadId = util.getThreadId(yourId, contactId);

    dispatch(blockContact(contactId, threadId));
  };

  const button = useMemo(() => {
    const buttonMap = {
      pending_request: (
        <button className="btn btn-primary btn-sm ml-auto mr-2" disabled>
          Request sent
        </button>
      ),
      pending_acceptance: (
        <button
          className="btn btn-primary btn-sm ml-auto mr-2"
          onClick={(e) => handleAccept(e)}
        >
          Accept
        </button>
      ),
      not_contacts: (
        <button
          className="btn btn-primary btn-sm ml-auto mr-2"
          onClick={(e) => handleRequest(e)}
        >
          Send contact request
        </button>
      ),
    };

    return buttonMap[status];
  }, [status]);

  useEffect(() => {
    setHidden(false);
  }, [contactId]);

  if (hidden || !status || status === "accepted" || blocked.userHasBlocked) return "";

  return (
    <div
      class="alert alert-primary d-flex align-items-center rounded-0"
      role="alert"
    >
      <div className="row w-100 align-items-center">
        <div className="col-lg-6">
          <span>This person is not in your contact list.</span>
        </div>
        <div className="col-lg-6 text-right align-items-center">
          {button}

          <button
            className="btn btn-outline-danger btn-sm mr-2"
            onClick={(e) => handleBlock(e)}
          >
            Block this user
          </button>
        </div>
      </div>

      <button
        type="button"
        class="close"
        aria-label="Close"
        onClick={(e) => setHidden(true)}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

export default ContactBanner;
