import React, { Component } from 'react'
import Parse from 'parse'
import ReactPhoneInput from "react-phone-input-2";
import { parse, getCountryCallingCode, isValidNumber } from 'libphonenumber-js'
import Loading from './Loading'
import Logo from '../../../assets/images/logo-blue.png';
import ExceedLimit from './ExceedLimit';
import MedicalProfileWarning from './MedicalProfileWarning'
import './Signup.css'
import '../Login/Login.css'

const $ = window.$;

export class Signup extends Component {
    constructor() {
        super();

        this.state = {
            validPhoneNumber : false,
            phoneIsAvailable : false,
            checkingPhoneIfAvailable : false,
            showStatus : false,
            phoneIsVerified : false,
            phoneNumber : "",
            errorMessage : "",
            isVerifyingSMS : false,
            isVerifyingCode : false,
            validCode : false,
            showValidStatus : false,
            readyToSignup : false,
            isSendingSMS : false,
            setTime : 60,
            resendLimit : 3,
            openExceedLimit : false,
            isResendingSMS : false,
            password : "",
            confirmPassword : "",
            passwordError : false,
            passwordErrorMessage : "",
            signingUser : false,
            canCreateMedicalProfile : false,
            patientId : "",
            refreshToken : "",
            opemMedicalProfileWarning : false,
            emailError : false,
            emailWithError : ""
        }

        this.handlePhoneInput = this.handlePhoneInput.bind(this);
        this.verifySMSCode = this.verifySMSCode.bind(this);
        this.openExceedLimit = this.openExceedLimit.bind(this);
        this.openMedicalProfileWarning = this.openMedicalProfileWarning.bind(this);
    }


    componentDidMount() {
        // let params = new URLSearchParams(this.props.location.search);
        // let codeObjectId = params.get("code");


        // if (codeObjectId !== null) {
        //     if (codeObjectId !== "") {

        //         // Fetch BB2Code data if has
        //         // After retrieve delete the BB2Code
        //         // Then Create the MedicalProfile


        //         // var MedicalProfile = Parse.Object.extend("MedicalProfile");
        //         // var query = new Parse.Query(MedicalProfile);
        //         // query.equalTo("patientId", patientId);
        //         // query.count().then((res) => {
        //         //     if (res >= 1) {
        //         //         this.setState({
        //         //             openMedicalProfileWarning : true
        //         //         })
        //         //     } else {
        //         //         this.setState({
        //         //             canCreateMedicalProfile : true,
        //         //             patientId,
        //         //             refreshToken
        //         //         })
        //         //     }
        //         // }, (error) => {
        //         //     console.log(error)
        //         // });
        //     }
        // }
    }


    componentWillUnmount() {
        clearInterval(this.timeOut);
    }

    openExceedLimit() {
        this.setState({
            openExceedLimit : true
        })
    }

    openMedicalProfileWarning() {
        this.setState({
            openMedicalProfileWarning : true
        })
    }

    modalClose() {
        this.setState({
            openExceedLimit : false,
            openMedicalProfileWarning : false
        })
    }

    checkPhoneNumber(e) {
        e.preventDefault();
        let { phoneNumber, validPhoneNumber } = this.state;
        let username = phoneNumber.replace(/\D/g,'');

        if (validPhoneNumber) {

            var User = Parse.Object.extend("User");
            var query = new Parse.Query(User);
            query.equalTo("username", username);
            query.count().then((res) => {
                if (res >= 1) {
                    this.setState({
                        checkingPhoneIfAvailable : false,
                        phoneIsAvailable : false,
                        showStatus : true
                    })
                } else {
                    this.sendSMSVerification(username);
                }
            }, (error) => {
                console.log(error)
            });
        }
        return;
    }

    getCountry(obj) {
        
        if (!obj || !obj.countryCode) return '';
        
        return obj.countryCode.toUpperCase();
    }

    handlePhoneInput(value, countryObj) {
        
        this.setState({
            showStatus : false,
            phoneIsAvailable : false,
            validPhoneNumber : false,
        });

        const country = this.getCountry(countryObj);
        
        let data = parse(value, country);

		let callingCode = null;
		if (typeof data.country != "undefined") {
			callingCode = getCountryCallingCode(data.country);
        }

		// if (Object.keys(data).length === 0 && data.constructor === Object) {
        //     // NOTE invalid number
		// 	return;
        // }

        let isValid = isValidNumber({ phone: data.phone, country: data.country })
        let phoneIsAvailable = true;
        if (isValid) {
            this.setState({
                checkingPhoneIfAvailable : true,
            })
            var User = Parse.Object.extend("User");
            var query = new Parse.Query(User);
            let username = value.replace(/\D/g,'');
            query.equalTo("username", username);
            query.count().then((res) => {
                if (res >= 1) {
                    this.setState({
                        checkingPhoneIfAvailable : false,
                        phoneIsAvailable : false,
                        showStatus : true
                    })
                } else {
                    this.setState({
                        checkingPhoneIfAvailable : false,
                        phoneIsAvailable : true,
                        showStatus : true

                    })
                }
            }, (error) => {
                console.log(error)
            });
        }

		this.setState({
            phoneNumber: value,
            validPhoneNumber : isValid
		})
    }

    startTimeout() {
		this.countdown();

		this.timeOut = setInterval(function() {
			this.countdown();
		}.bind(this), 1000);
	}

	countdown() {
		let setTime = this.state.setTime;
		setTime = setTime - 1;
		this.setState({
			setTime : setTime
		})
		if (setTime == 0) {
			clearInterval(this.timeOut);
		}
	}

    sendSMSVerification(phoneNumber) {
        this.setState({
            isSendingSMS : true
        });

        let self = this;
        Parse.Cloud.run("requestVerificationCode", {
            to: phoneNumber
        }).then(function(res) {
            self.setState({
                isVerifyingSMS : true,
                isSendingSMS : false
            })
            self.startTimeout();
        }).catch((error) => {
            if (error.message === "SMS_MAX_RETRIES_EXCEEDED") {
                self.openExceedLimit();
                self.setState({
                    isVerifyingSMS : false,
                    isSendingSMS : false
                })
            }
        });

    }

    resendSMSVerification() {
        let { phoneNumber, validPhoneNumber } = this.state;
        let username = phoneNumber.replace(/\D/g,'');

        let limit = this.state.resendLimit;
		if (limit == 0) {
			return;
		}
		let setTime;
		if (limit == 3) {
			setTime = 120;
		} else if (limit == 2) {
			setTime = 180;
		} else if (limit == 1) {
			setTime = null;
		}
		limit = limit - 1;

        this.setState({
            isResendingSMS : true,
            resendLimit : limit,
			setTime : setTime
        });

        let self = this;
        Parse.Cloud.run("requestVerificationCode", {
            to: username
        }).then(function(res) {
            self.setState({
                isVerifyingSMS : true,
                isResendingSMS : false
            })
            self.startTimeout();
        }).catch((error) => {
            if (error.message === "SMS_MAX_RETRIES_EXCEEDED") {
                self.openExceedLimit();
                self.setState({
                    isResendingSMS : false
                })
            }
        });
    }

    verifySMSCode(e) {
        e.preventDefault();
        this.setState({
            isVerifyingCode : true,
            showValidStatus : false,
        })

        let { phoneNumber } = this.state;
        let number = phoneNumber.replace(/\D/g,'');

        let self = this;
        Parse.Cloud.run("verifyCode", {
            number : number,
            code : self.refs.code.value
        }).then(function(res) {
            self.setState({
                validCode : true,
                isVerifyingCode : false,
                showValidStatus : true,
                readyToSignup : true
            })
        }).catch((error) => {
            this.setState({
                validCode : false,
                isVerifyingCode : false,
                showValidStatus : true
            });
        });
    }

    signUpUser(e) {
        e.preventDefault();

        if (this.state.confirmPassword !== this.state.password || this.state.passwordError || this.refs.firstName.value === "" || this.refs.lastName.value === "") {
            return;
        }

        this.setState({
            signingUser : true,
            emailError : false
        })

        let firstName = this.refs.firstName.value;
        let firstNameCap = firstName.charAt(0).toUpperCase() + firstName.slice(1);
        let lastName = this.refs.lastName.value;
        let lastNameCap = lastName.charAt(0).toUpperCase() + lastName.slice(1);
        let username = this.state.phoneNumber.replace(/\D/g,'');
        let password = this.state.password;
        let displayName = firstNameCap + " " + lastNameCap;
        let email = this.refs.email.value;


        var user = new Parse.User();
        user.set("webOnly", false);
        user.set("username", username);
  		user.set("password", password);
  		user.set("firstName", firstNameCap);
  		user.set("lastName", lastNameCap);
  		user.set("firstNameLower", firstName.toLowerCase());
  		user.set("lastNameLower", lastName.toLowerCase());
  		user.set("statusMessage", "I'm on Hubchart");
  		user.set("displayName", displayName);
        user.set("displayNameLower", displayName.toLowerCase());
        user.set("email", email);

        user.signUp().then((user) => {
            if (this.state.canCreateMedicalProfile) {
                const MedicalProfile = Parse.Object.extend("MedicalProfile");
                const newMedicalProfile = new MedicalProfile();

                newMedicalProfile.set("patientId", this.state.patientId);
                newMedicalProfile.set("refreshToken", this.state.refreshToken);
                newMedicalProfile.set("user", user);

                newMedicalProfile.save().then((medicalProfileObject) => {
                    user.set("medicalProfile", medicalProfileObject);
                    return user.save();
                }).catch((error) => {
                    console.log(error);
                });

                this.loginUser(username, password);
            } else {
                this.loginUser(username, password);
            }



        }).catch((error) => {
            if (error.code === 203) {
                this.setState({
                    emailError : true,
                    emailWithError : email,
                    signingUser : false,
                    password: "",
                    confirmPassword : ""
                })
            }
        })
    }

    loginUser(username, password) {
        const user = Parse.User.logIn(username, password);
        user.then((user) => {
            window.location.href = '/?status=success';
        })
        .catch((error) => {
        })
    }

    handlePasswordChange(e) {
        let wsRegex = '/^\s\w+,\s\w+!\s*/';
        let firstChar = e.target.value[0];
        let lastChar = e.target.value[e.target.value.length - 1];
        let hasPasswordError = false;
        let passwordErrorMessage = [];
        if (e.target.value.length !== 0) {
            if (e.target.value.length <= 5) {
                hasPasswordError = true;
                passwordErrorMessage = [...passwordErrorMessage, "* Minimum of 6 character"];
            }
        }

        if (firstChar === " " || lastChar === " ") {
            hasPasswordError = true;
            passwordErrorMessage = [...passwordErrorMessage, "* Space are not allowed at the beginning and end of your password."];
        }
        if (hasPasswordError) {
            this.setState({
                passwordError : hasPasswordError,
                passwordErrorMessage : passwordErrorMessage
            })
        } else {
            this.setState({
                passwordError : false,
                passwordErrorMessage : []
            })
        }
        this.setState({
            password : e.target.value
        })
    }

    handleConfirmPasswordChange(e) {
        this.setState({
            confirmPassword : e.target.value
        })
    }

    render() {

        let PhoneNumberComponent;
        let CodeComponent;
        let RegistrationComponent;
        let ConfirmPhoneNumberStyle = {width: "auto", fontSize: "17px", float: "right", cursor: "pointer"};


        let phoneInputStyle = {
            paddingLeft: "0px",
            marginTop: "3px"
        };

        let disabledConfirm = true;
        if (this.state.showStatus) {
            if (this.state.phoneIsAvailable && this.state.validPhoneNumber) {
                disabledConfirm = false;
                phoneInputStyle.border = "1px solid #4AAD52";
            }

            if (!this.state.phoneIsAvailable && this.state.validPhoneNumber) {
                phoneInputStyle.border = "1px solid #d84b55";
            }
        }

        let disabledInput = false;

        if (this.state.isVerifyingSMS) {
            phoneInputStyle.border = "none";
            disabledInput = true;
        }


        PhoneNumberComponent = <form onSubmit={this.checkPhoneNumber.bind(this)}>
                                    <div className="form-group">
                                    <span style={{fontSize: "13px", color:"#00000096", fontWeight: "500"}}>Please enter your full phone number.</span>
                                        <div className="card" style={phoneInputStyle}>
                                            <ReactPhoneInput disabled={disabledInput} country={'us'} defaultCountry={'us'} enableLongNumbers={true} onChange={this.handlePhoneInput} value={this.state.phoneNumber} inputStyle={{width: "100%", outline: "none", boxShadow: "none"}}/>
                                            {this.state.checkingPhoneIfAvailable && <Loading style={{right: "-23px", top: "-2px"}} />}
                                            {this.state.showStatus && this.state.phoneIsAvailable && this.state.validPhoneNumber && <div style={{position: "absolute", right: "-2px", height: "40px", width: "40px"}}><i style={{color: "#4AAD52", fontSize: "18px"}} className="fas fa-check"></i></div>}
                                            {this.state.showStatus && !this.state.phoneIsAvailable && this.state.validPhoneNumber && <div style={{position: "absolute", right: "-2px", height: "40px", width: "40px"}}><i style={{color: "#d84b55", fontSize: "18px", left: "12px"}} className="fas fa-times"></i></div>}
                                        </div>
                                        {!this.state.isVerifyingSMS &&
                                            <div>
                                                {this.state.showStatus && this.state.phoneIsAvailable && this.state.validPhoneNumber && <span style={{color: "#4AAD52", fontSize: "14px"}}>Phone number still available</span>}
                                                {this.state.showStatus && !this.state.phoneIsAvailable && this.state.validPhoneNumber && <span style={{color: "#d84b55", fontSize: "14px",}}>Phone number is already in use</span>}
                                            </div>
                                        }
                                    </div>
                                    {this.state.isSendingSMS || !this.state.isVerifyingSMS && <div className="form-group" style={{marginBottom: "30px"}}>
                                        <button disabled={disabledConfirm} ref="submit" type="submit" className={"confirm-phonenumber " + (disabledConfirm ? "disabled-confirm" : "")} style={ConfirmPhoneNumberStyle}>Confirm</button>
                                    </div> }
                                    {this.state.isSendingSMS && <div className="form-group" style={{position: "relative"}}>
                                        <Loading style={{right: "120px", top: "-10px"}}/>
                                        <span style={{float: "right"}}>Sending SMS Code</span>
                                    </div>}
                                </form>

        if (this.state.isVerifyingSMS) {
            let codeInputStyle = {}
            if (this.state.showValidStatus && !this.state.validCode) {
                codeInputStyle.border = "1px solid #d84b55";
            }

            CodeComponent = <form onSubmit={this.verifySMSCode.bind(this)} style={{marginTop: "20px"}}>
                                <div className="form-group">
                                    <span style={{fontSize: "13px", color:"#00000096", fontWeight: "500"}}>Please enter the verification code that we sent to your phone number</span>
                                    <div className="card" style={codeInputStyle}>
                                        <input ref="code" type="text" id="code" placeholder="****" maxLength="4"  required disabled={this.state.validCode}/>
                                        <i className="fas fa-lock hc-primary-text"></i>
                                        {this.state.isVerifyingCode && <Loading style={{right: "-24px", top: "-2px"}}/>}
                                        {this.state.showValidStatus && this.state.validCode && <div style={{position: "absolute", right: "-2px", height: "40px", width: "40px"}}><i style={{color: "#4AAD52", fontSize: "18px"}} className="fas fa-check"></i></div>}
                                        {this.state.showValidStatus && !this.state.validCode && <div style={{position: "absolute", right: "-2px", height: "40px", width: "40px"}}><i style={{color: "#d84b55", fontSize: "18px", marginLeft: "3.5px"}} className="fas fa-times"></i></div>}
                                    </div>
                                    {
                                        !this.state.readyToSignup && <div>
                                            {this.state.resendLimit != 0 ?
                                                <small className="form-text" style={{color: "rgb(61, 155, 233)"}}> Did not receive SMS? { this.state.setTime == 0 ? <span onClick={this.resendSMSVerification.bind(this)} className="badge badge-success" style={{fontSize: "12px", cursor: "pointer"}}>RESEND</span> : <strong>RESEND</strong>}  {this.state.setTime == 0 ? null : (this.state.setTime)}</small>
                                                :
                                                <small className="form-text" style={{color: "rgb(61, 155, 233)"}}> Please wait for your verification code. </small>
                                            }
                                        </div>

                                    }

                                </div>

                                {this.state.isResendingSMS || !this.state.validCode && <div className="form-group" style={{marginBottom: "30px"}}>
                                    {!this.state.isVerifyingCode && <button ref="submit" type="submit" className="confirm-phonenumber" style={ConfirmPhoneNumberStyle}>Verify</button> }
                                </div>}
                                {this.state.isResendingSMS && <div className="form-group" style={{position: "relative"}}>
                                        <Loading style={{right: "120px", top: "-10px"}}/>
                                        <span style={{float: "right"}}>Sending SMS Code</span>
                                    </div>}
                            </form>
        }
        if (this.state.readyToSignup) {
            RegistrationComponent = <form onSubmit={this.signUpUser.bind(this)} style={{marginTop: "20px"}}>
                                        <span style={{fontSize: "13px", color:"#00000096", fontWeight: "500"}}>Please enter your user details</span>
                                        <div className="form-group">
                                            <div className="card">
                                                <input style={{textTransform: "capitalize"}} ref="firstName" type="text" id="firstname" placeholder="Firstname" required disabled={this.state.signingUser}/>
                                                <i className="fas fa-user hc-primary-text"></i>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="card">
                                                <input style={{textTransform: "capitalize"}} ref="lastName" type="text" id="lastname" placeholder="Lastname" required disabled={this.state.signingUser}/>
                                                <i className="fas fa-user hc-primary-text"></i>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="card">
                                                <input ref="email" type="email" id="email" placeholder="Email Address" required disabled={this.state.signingUser}/>
                                                <i className="fas fa-user hc-primary-text"></i>
                                            </div>
                                            {this.state.emailError && <span style={{fontSize: "13px", color: "rgb(216, 75, 85)", fontWeight: "500"}}>There is an existing account associated with {this.state.emailWithError}.</span>}
                                        </div>
                                        <div className="form-group">
                                            <div className="card" style={{marginBottom: "5px"}}>
                                                <input onChange={this.handlePasswordChange.bind(this)} value={this.state.password} type="password" id="password" placeholder="Password" required disabled={this.state.signingUser}/>
                                                <i className="fas fa-lock hc-primary-text"></i>
                                            </div>
                                            {this.state.passwordError && this.state.passwordErrorMessage.map((e, i) => {
                                                return <span key={i} style={{fontSize: "13px", color: "rgb(216, 75, 85)", display: "block", fontWeight: "500"}}>{e}</span>
                                            })}
                                        </div>
                                        <div className="form-group">
                                            <div className="card">
                                                <input onChange={this.handleConfirmPasswordChange.bind(this)} value={this.state.confirmPassword} ref="confirm_password" type="password" id="confirm_password" placeholder="Confirm Password" required disabled={this.state.signingUser}/>
                                                <i className="fas fa-lock hc-primary-text"></i>
                                            </div>
                                            {this.state.password !== "" && this.state.confirmPassword !== this.state.password && <span style={{fontSize: "13px", color: "rgb(216, 75, 85)", fontWeight: "500"}}>Password does not match password confirmation.</span>}
                                        </div>
                                        {this.state.signingUser ?
                                            <div className="form-group" style={{position: "relative"}}>
                                                <Loading style={{right: "160px", top: "-5px"}}/>
                                                <span style={{float: "right"}}>Creating User Account</span>
                                            </div>
                                            :
                                            <div className="form-group" style={{marginBottom: "30px"}}>
                                                <button ref="submit" type="submit" className="confirm-phonenumber" style={ConfirmPhoneNumberStyle}>Sign up</button>
                                            </div>
                                        }

                                    </form>
        }


        return (
            <div className="Login-Container">
                <div className="Form-Container">
                    <img className="Logo" src={Logo} alt="Hubchart Logo" />
                    <h1 className="hc-primary-text">HUBCHART</h1>
                    <a href="/" style={{float: "right", fontSize: "1rem", fontWeight: "500"}}>Login</a>
                    <h5 style={{fontSize: "1.15rem"}}>Sign up</h5>
                    { PhoneNumberComponent }
                    { CodeComponent }
                    { RegistrationComponent }
                </div>
                {this.state.openExceedLimit && <ExceedLimit isOpen={this.state.openExceedLimit} modalClose={this.modalClose.bind(this)} />}
                {this.state.openMedicalProfileWarning && <MedicalProfileWarning  isOpen={this.state.openMedicalProfileWarning} modalClose={this.modalClose.bind(this)}/>}
            </div>
        )
    }
}

export default Signup
