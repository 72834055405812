import React, { Component } from "react";
import MicRecorder from "mic-recorder-to-mp3";
import "./Audio.css";
const $ = window.$;
export class AudioModal extends Component {
  constructor() {
    super();
    this.state = {
      isRecording: false,
      audioURL: "",
      audioFile: ""
    };
  }

  componentDidMount() {
    if (this.props.isOpen) {
      $("#audioModal").modal("show");
    } else {
      $("#audioModal").modal("hide");
    }
    $("#audioModal").on(
      "hidden.bs.modal",
      function(e) {
        this.props.modalClose();
      }.bind(this)
    );
  }

  componentWillUnmount() {
    if (this.state.isRecording) {
      this.recorded.stop();
    }
    clearInterval(this.countTime);
  }

  startRecord() {
    if (this.state.isRecording) return;
    let self = this;
    // New instance
    this.recorded = new MicRecorder({
      bitRate: 128
    });

    // Start recording. Browser will request permission to use your microphone.
    this.recorded
      .start()
      .then(() => {
        self.setState({
          isRecording: true,
          audioURL: "",
          audioFile: ""
        });
        self.startTimer();
      })
      .catch(e => {
        console.error(e);
      });
  }

  stopRecord() {
    if (!this.state.isRecording) return;

    let self = this;
    clearInterval(self.countTime);
    $("#timer").text("00:00");
    this.recorded
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        // do what ever you want with buffer and blob
        // Example: Create a mp3 file and play

        const file = new File(buffer, "recorded-audio.mp3", {
          type: blob.type === "audio/mp3" ? "audio/mpeg" : blob.type, //blob.type,
          lastModified: Date.now()
        });

        self.setState({
          audioFile: file,
          audioURL: URL.createObjectURL(file),
          isRecording: false
        });
      })
      .catch(e => {
        alert("We could not retrieve your message");
        console.log(e);
      });
  }

  startTimer() {
    let second = 0;
    let min = 0;
    this.second = 0;
    this.min = 0;
    this.countTime = setInterval(
      function() {
        second = second + 1;
        if (second == 60) {
          min = min + 1;
          second = 0;
        }
        var secondText = second;
        var minText = min;
        if (second < 10) {
          secondText = "0" + second;
        }
        if (min < 10) {
          minText = "0" + min;
        }
        this.second = second;
        this.min = min;
        $("#timer").text(minText + ":" + secondText);
      }.bind(this),
      1000
    );
  }

  sendAudio() {
    if (this.state.audioFile === "") return;
    var minDuration = this.min * 60 * 1000;
    var secondDuration = this.second * 1000;
    var totalDuration = minDuration + secondDuration;

    this.props.uploadAudioFile(this.state.audioFile, totalDuration);
    $("#audioModal").modal("hide");
  }

  render() {
    return (
      <div id="audioModal" className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body" style={{ paddingBottom: "0px" }}>
              <div className="list-group">
                <div className="row">
                  <div
                    className="col-12"
                    style={{ textAlign: "center", fontSize: "15px" }}
                  >
                    <span id="timer" style={{ color: "#00000085" }}>
                      00:00
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-12"
                    style={{
                      textAlign: "center",
                      fontSize: "50px",
                      height: "100px",
                      marginTop: "50px",
                      marginBottom: "20px"
                    }}
                  >
                    {this.state.isRecording && (
                      <div className="pulsating-circle"></div>
                    )}
                    <i className="fas fa-microphone hc-primary-text micro-pulse"></i>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-4"
                    style={{ textAlign: "center", fontSize: "25px" }}
                  >
                    <a
                      onClick={this.startRecord.bind(this)}
                      href="#!"
                      className={
                        "audio-record-btn " +
                        (this.state.isRecording && "audio-btn-disabled")
                      }
                    >
                      <i
                        className="fas fa-circle"
                        style={{ color: "#D84B55" }}
                      ></i>
                      <span>Record</span>
                    </a>
                  </div>
                  <div
                    className="col-4"
                    style={{ textAlign: "center", fontSize: "25px" }}
                  >
                    <a
                      onClick={this.stopRecord.bind(this)}
                      href="#!"
                      className={
                        "audio-record-btn " +
                        (this.state.isRecording
                          ? "hc-primary-color"
                          : "audio-btn-disabled")
                      }
                    >
                      <i className="far fa-stop-circle"></i>
                      <span>Stop</span>
                    </a>
                  </div>
                  <div
                    className="col-4"
                    style={{ textAlign: "center", fontSize: "25px" }}
                  >
                    <a
                      onClick={this.sendAudio.bind(this)}
                      href="#!"
                      className={
                        "audio-record-btn " +
                        (this.state.audioURL === ""
                          ? "audio-btn-disabled"
                          : "hc-primary-color")
                      }
                    >
                      <i className="far fa-paper-plane"></i>
                      <span>Send</span>
                    </a>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "20px" }}>
                  {this.state.audioURL != "" && (
                    <audio controls style={{ margin: "10px auto" }}>
                      <source src={this.state.audioURL} type="audio/mpeg" />
                      Your browser does not support the audio element.
                    </audio>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AudioModal;
