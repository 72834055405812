import React, { Fragment } from "react";
import moment from "moment";
import { computeAge } from "../../../helper/util";

import "./index.css";

const $ = window.$;

function PatientInfoComponent({
  patient = {},
  encounterType = "",
  dateOfService = new Date(),
}) {
  const patientInfo = {
    ...patient,
    dob: moment(patient.dob).format("MM-DD-YYYY"),
    dateSeen: moment(dateOfService).format("lll"),
    age: parseInt(computeAge(patient.dob)),
    gender: patient.gender || "",
  };

  return (
    <div className="card custom-card">
      <h5 className="card-header">
        <strong>PATIENT INFORMATION</strong>
      </h5>
      <div className="card-body">
        <div className="patient-info-flex">
          <div className="patient-info-item">
            <div>
              <strong>Name: </strong>
              <span>{patientInfo.name}</span>
            </div>
            <div>
              <strong>MR Number: </strong>
              <span>{patientInfo.mrNumber || "--"}</span>
            </div>
            <div>
              <strong>DOB: </strong>
              <span>{patientInfo.dob || "--"}</span>
            </div>
            <div>
              <strong>Age: </strong>
              <span>{patientInfo.age}</span>
            </div>
            <div>
              <strong>Gender: </strong>
              <span>{patientInfo.gender || "--"}</span>
            </div>
          </div>
          {/* <div className="patient-info-item">
            <div>
              <strong>Race/Ethnicity: </strong>
              <span>{patientInfo.race || '--'}</span>
            </div>
            <div>
              <strong>Language: </strong>
              <span>{patientInfo.preferredLanguage || '--'}</span>
            </div>
            <div>
              <strong>Phone Number: </strong>
              <span>{patientInfo.phoneNumber || '--'}</span>
            </div>
            <div>
              <strong>Primary MD: </strong>
              <span>{patientInfo.primaryMd || '--'}</span>
            </div>
            <div>
              <strong>Pharmacy: </strong>
              <span>{patientInfo.defaultPharmacy || '--'}</span>
            </div>
          </div> */}
          <div className="patient-info-item">
            {/* <div>
              <strong>Insurance: </strong>
              <span>{patientInfo.insurance || '--'}</span>
            </div> */}
            <div>
              <strong>Date of Service: </strong>
              <span>{patientInfo.dateSeen}</span>
            </div>
            <div>
              <strong>Provider this Visit: </strong>
              <span>{patientInfo.provider || "--"}</span>
            </div>
            <div>
              <strong>Encounter Type: </strong>
              <span>{encounterType || "--"}</span>
            </div>
            <div>
              <strong>Clinic Location: </strong>
              <span>{patientInfo.clinicLocation || "--"}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatientInfoComponent;
