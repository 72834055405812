import React, { Fragment, useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Parse from "parse";
import moment from "moment";
import TextareaAutosize from "react-textarea-autosize";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import debounce from "lodash.debounce";
import { parseAction } from "../../../../actions/REST";
import Loader from "../../../Common/Loader";
import TabLoader from "../../../Common/TabLoader";
// import { updatePatientInfo } from '../../../../actions/GroupAction';
import Notify from "../../../Common/Notify";
import config from "../../../../config";
import { defaultList } from "./config";
import { computeAge } from "../../../../helper/util";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "../../../Pages/Login/Login.css";
import "../SettingModal.css";
import AddDiagnosisModal from "./AddDiagnosisModal";

const $ = window.$;
const defaultSelectedDiagnosis = {
  code: "",
  longDescription: "",
  shortDescription: "",
  onsetDate: new Date(),
  is_acute: false,
  is_chronic: false,
  is_resolved: false,
  type: "manual",
};

function PatientDiagnosisModal({
  isOpen = false,
  threadId = "",
  onClose = () => {},
  refreshData = () => {},
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDiagnosis, setIsLoadingDiagnosis] = useState(false);
  const [endOfList, setEndOfList] = useState(false);
  const [manualDxEntryDisabled, setManualDxEntryDisabled] = useState(false);
  const [patientInfo, setPatientInfo] = useState(false);
  const [addDiagnosisModalShown, setAddDiagnosisModalShown] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState([]);
  const [selectedTempDiagnosis, setSelectedTempDiagnosis] = useState([
    { ...defaultSelectedDiagnosis },
    { ...defaultSelectedDiagnosis },
    { ...defaultSelectedDiagnosis },
    { ...defaultSelectedDiagnosis },
    { ...defaultSelectedDiagnosis },
  ]);
  const [method, setMethod] = useState("create");
  const [diagnosisList, setDiagnosisList] = useState([]);
  const [keyWord, setKeyWord] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  function fetchPatientInfo(threadId) {
    setIsLoading(true);
    parseAction("get", config.BASE_URL + "/parse/classes/Circle", {
      where: {
        objectId: threadId,
      },
    })
      .then((result) => {
        if (result.results.length === 0) {
          return;
        }

        var data = result.results[0];
        const diags = (data.diagnosis || []).map((item) => ({
          ...item,
          onsetDate: new Date(item.onsetDate.iso),
        }));
        setPatientInfo(data);
        setSelectedDiagnosis(diags);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }

  function fetchDiagnosisList(keyWord, cPage = 1) {
    setIsLoadingDiagnosis(true);
    parseAction(
      "get",
      `${config.PATIENT_URL}/diagnosis?search=${keyWord}&page=${cPage}&size=50`
    )
      .then((result) => {
        const { items, page, total } = result.data;
        const finalList =
          page === 1 && !keyWord
            ? [...defaultList, ...items]
            : page === 1
            ? items
            : [...diagnosisList, ...items];
        const isEndOfList = total === finalList.length ? true : false;

        setDiagnosisList(finalList);
        setKeyWord(keyWord);
        setCurrentPage(page);
        setTotalItems(total);
        setEndOfList(isEndOfList);
        setIsLoadingDiagnosis(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        setIsLoadingDiagnosis(false);
      });
  }

  function handleFetchDiagnosisPageChange() {
    fetchDiagnosisList(keyWord, currentPage + 1);
  }

  function formatCircle() {
    const data = {
      objectId: patientInfo.objectId,
      diagnosis: selectedDiagnosis,
    };

    const Circle = Parse.Object.extend("Circle");
    const newCircle = new Circle(data);

    handleSave(newCircle);
  }

  async function handleSave(circle) {
    setIsLoading(true);

    try {
      const group = await circle.save();
      setPatientInfo(group.toJSON());
      setIsLoading(false);

      Notify.success("Diagnosis successfully saved.");
      refreshData();
      $("#patientDiagnosisModal").modal("hide");
    } catch (err) {
      setIsLoading(false);
      Notify.error("Could not save Diagnosis.");
      console.error(err);
    }
  }

  function removeItem(item) {
    selectedDiagnosis.splice(selectedDiagnosis.indexOf(item), 1);
    setSelectedDiagnosis([...selectedDiagnosis]);
  }

  function updateOnsetDate(item, date) {
    const item_index = selectedDiagnosis.indexOf(item);
    selectedDiagnosis[item_index].onsetDate = date;

    setSelectedDiagnosis([...selectedDiagnosis]);
  }

  function manualDxEntry(selectedDiagnosis) {
    setManualDxEntryDisabled(true);
    setTimeout(() => {
      const newSelectedDiagnosis = [
        {
          code: "",
          longDescription: "",
          shortDescription: "",
          onsetDate: new Date(),
          is_acute: false,
          is_chronic: false,
          type: "manual",
        },
        ...selectedDiagnosis,
      ];

      setSelectedDiagnosis([...newSelectedDiagnosis]);
      setManualDxEntryDisabled(false);
    }, 100);
  }

  function onInputChange(e, idx) {
    const { value } = e.target;

    selectedDiagnosis[idx].longDescription = value;
    selectedDiagnosis[idx].shortDescription = value;

    setSelectedDiagnosis([...selectedDiagnosis]);
  }

  function onInputCheckedChange(e, idx) {
    const { checked, name } = e.target;
    selectedDiagnosis[idx][name] = checked;

    setSelectedDiagnosis([...selectedDiagnosis]);
  }

  function onInputCodeChange(e, idx) {
    const { value } = e.target;
    selectedDiagnosis[idx].code = value;

    setSelectedDiagnosis([...selectedDiagnosis]);
  }

  function selectDiagnosis(selected) {
    const new_dx = [
      {
        code: selected[0].code,
        longDescription:
          selected[0].longDescription ||
          selected[0].shortDescription ||
          "No description",
        shortDescription: selected[0].shortDescription || "No description",
        is_acute: false,
        is_chronic: false,
        onsetDate: new Date(),
      },
      ...selectedDiagnosis,
    ];
    const final_dx = Array.from(
      new Set(new_dx.map((a) => a.code))
    ).map((code) => new_dx.find((a) => a.code === code));

    setSelectedDiagnosis(final_dx);
    setSelectedOption([]);
  }

  // function calculateAge (dob) {
  //   const newDate = new Date(dob);
  //   return moment().diff(moment(newDate, 'YYYY'), 'years');
  // }

  function handleChangeTextAreaDetails(e, i) {
    const { value } = e.target;
    let newArr = [...selectedTempDiagnosis];
    newArr[i].longDescription = value;

    setSelectedTempDiagnosis([...newArr]);
  }

  function addTextAreaField() {
    setSelectedTempDiagnosis([
      {
        ...defaultSelectedDiagnosis,
        longDescription: "",
      },
      ...selectedTempDiagnosis,
    ]);
  }

  function removeTextAreaField(i) {
    let newArr = selectedTempDiagnosis;
    newArr.splice(i, 1);
    setSelectedTempDiagnosis([...newArr]);
  }

  function closeAddDiagnosisModal() {
    // $("#patientAddDiagnosisModal").modal('hide');
    setAddDiagnosisModalShown(false);
  }

  function handleSetAddDiagnosisModalShown() {
    setAddDiagnosisModalShown(true);
    setTimeout(() => {
      $("#patientAddDiagnosisModal").modal({
        backdrop: false,
        keyboard: false,
      });
      $("#patientAddDiagnosisModal").modal("show");
    }, 100);
  }

  function handleSaveTempDiagnosis() {
    const filteredSelectedTempDiagnosis = selectedTempDiagnosis.filter(
      (item) => item.longDescription
    );
    const newDiagnosis = [
      ...filteredSelectedTempDiagnosis,
      ...selectedDiagnosis,
    ];

    setSelectedDiagnosis(newDiagnosis);
    setSelectedTempDiagnosis([
      { ...defaultSelectedDiagnosis },
      { ...defaultSelectedDiagnosis },
      { ...defaultSelectedDiagnosis },
      { ...defaultSelectedDiagnosis },
      { ...defaultSelectedDiagnosis },
    ]);
    closeAddDiagnosisModal();
  }

  useEffect(() => {
    componentDidMount();
  }, [isOpen]);

  function componentDidMount() {
    if (isOpen) {
      localStorage.setItem("someModalIsOpen", "true");
      $("#patientDiagnosisModal").modal({
        backdrop: "static",
        keyboard: false,
      });
      $("#patientDiagnosisModal").modal("show");
      $(".modal-dialog").draggable({
        handle: ".modal-header",
      });
      fetchPatientInfo(threadId);
      fetchDiagnosisList("");
    } else {
      $("#patientDiagnosisModal").modal("hide");
    }

    $("#patientDiagnosisModal").on("hidden.bs.modal", function() {
      localStorage.removeItem("someModalIsOpen");
      onClose();
    });

    $("#patientAddDiagnosisModal").on(
      "hidden.bs.modal",
      function(e) {
        setAddDiagnosisModalShown(false);
      }.bind(this)
    );
  }

  const debouncedFetchDiagnosisList = debounce(fetchDiagnosisList, 500);

  return (
    <div
      id="patientDiagnosisModal"
      className="modal"
      tabIndex="-1"
      role="dialog"
    >
      <div
        className="modal-dialog modal-print"
        role="document"
        style={{ maxWidth: "1000px" }}
      >
        <div className="modal-content modal-full-height">
          <div className="modal-header">
            <h5 className="hc-primary-text" style={{ fontSize: "1.25rem" }}>
              Patient Diagnosis
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body body-style" style={{ overflow: "auto" }}>
            <div className="details-container">
              <div>
                <div className="patient-details">
                  <span>
                    <i className="fas fa-fw fa-hospital-user"></i> Patient Name:{" "}
                  </span>
                  <span>{patientInfo.name}</span>
                </div>
                <div className="patient-details">
                  <span>
                    <i className="fas fa-fw fa-address-card"></i> Address:{" "}
                  </span>
                  <span>{patientInfo.address}</span>
                </div>
                <div className="patient-details">
                  <span>
                    {" "}
                    <i className="fas fa-fw fa-calendar-day"></i> Birth date:{" "}
                  </span>
                  <span>
                    {patientInfo.dob
                      ? `${patientInfo.dob} (${computeAge(
                          patientInfo.dob
                        )} yrs old)`
                      : "--"}
                  </span>
                </div>
              </div>
              <div>
                <div className="patient-details">
                  <span>
                    <i className="fas fa-fw fa-phone"></i> Contact No.:{" "}
                  </span>
                  <span>{patientInfo.phoneNumber}</span>
                </div>
                <div className="patient-details">
                  <span>
                    <i className="fas fa-fw fa-allergies"></i> Allergies:{" "}
                  </span>
                  <span>{patientInfo.allergies}</span>
                </div>
              </div>
            </div>
            <hr style={{ margin: "0px 0px 15px 0px" }} />
            <div className="filter-view">
              <div className="search-flex search-identifier">
                <div className="rbt-container">
                  <Typeahead
                    id="setting-modal-typeahead"
                    placeholder="Search Dx here"
                    onChange={(selected) => {
                      selectDiagnosis(selected);
                    }}
                    onInputChange={(e) => debouncedFetchDiagnosisList(e)}
                    options={diagnosisList}
                    maxResults={999999999}
                    labelKey={"longDescription"}
                    filterBy={() => true}
                    selected={selectedOption}
                    renderMenu={(results, menuProps) => (
                      <Menu {...menuProps}>
                        {results.map((result, index) => (
                          <MenuItem
                            key={result.code + index}
                            option={result}
                            position={index}
                          >
                            {`(${result.code}) - ${result.longDescription ||
                              result.shortDescription}`}
                          </MenuItem>
                        ))}
                        <TabLoader isLoading={isLoadingDiagnosis} />
                        <div className="load-more">
                          {!endOfList && (
                            <button
                              type="button"
                              className="btn btn-block btn-light btn-sm"
                              onClick={() => handleFetchDiagnosisPageChange()}
                            >
                              Load More
                            </button>
                          )}
                          {endOfList && <span>- End of List -</span>}
                        </div>
                      </Menu>
                    )}
                  />
                  <button
                    type="button"
                    className="btn btn-hubchart btn-sm btn-hubchart-primary"
                    style={{ height: "34px", width: "50px" }}
                  >
                    <i className="fas fa-search"></i>
                  </button>
                </div>
                <button
                  type="button"
                  className="btn btn-hubchart btn-sm btn-hubchart-primary"
                  disabled={manualDxEntryDisabled}
                  onClick={() => handleSetAddDiagnosisModalShown()} // manualDxEntry(selectedDiagnosis)}
                  style={{ margin: "0px", height: "33px", width: "170px" }}
                >
                  <i className="fas fa-plus"></i> Add
                </button>
              </div>
            </div>
            <div className="diagnosis" style={{ overflowY: "auto" }}>
              {selectedDiagnosis.length > 0 && (
                <div className="setting-modal-headers">
                  <span>Code</span>
                  <span>Diagnosis</span>
                  <span>Date Entered</span>
                  <span>Resolved</span>
                  {/* <span>Chronic</span> */}
                  <span>Action</span>
                </div>
              )}
              <div className="setting-modal-item-container">
                {selectedDiagnosis.map((item, i) => (
                  <div
                    className="setting-modal-item"
                    key={`dx-${moment(new Date()).format("lll")}-${i}`}
                  >
                    {item.type !== "manual" && (
                      <Fragment>
                        <span>{item.code}</span>
                        <span>
                          {item.longDescription || item.shortDescription}
                        </span>
                      </Fragment>
                    )}
                    {item.type === "manual" && (
                      <Fragment>
                        <input
                          className="setting-modal-input"
                          type="text"
                          placeholder="Enter Code"
                          value={item.code}
                          onChange={(e) => onInputCodeChange(e, i)}
                        />
                        <TextareaAutosize
                          key={`${i}-dx`}
                          className="setting-modal-input-2"
                          value={item.longDescription}
                          minRows={1.3}
                          onChange={(e) => onInputChange(e, i)}
                        />
                      </Fragment>
                    )}
                    <span>
                      <DatePicker
                        className="date-picker-custom black"
                        // dateFormat="MM/dd/yyyy"
                        placeholderText="mm/dd/yyyy"
                        selected={item.onsetDate}
                        onChange={(date) => {
                          updateOnsetDate(item, date);
                        }}
                        isClearable
                      />
                    </span>
                    <span>
                      <input
                        type="checkbox"
                        id="is_resolved"
                        name="is_resolved"
                        checked={item.is_resolved}
                        style={{ marginTop: "10px" }}
                        onChange={(e) => onInputCheckedChange(e, i)}
                      />
                    </span>
                    {/* <span>
                      <input 
                        type="checkbox" 
                        id="is_chronic" 
                        name="is_chronic" 
                        checked={item.is_chronic}
                        style={{ marginTop: '10px' }}
                        onChange={e => onInputCheckedChange(e, i)} />
                    </span> */}
                    <span>
                      <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        title="Remove Dx"
                        onClick={() => removeItem(item)}
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </span>
                  </div>
                ))}
              </div>
            </div>
            {selectedDiagnosis.length === 0 && (
              <div className="default-view">
                <span>No Diagnosis Found.</span>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-hubchart btn-hubchart-primary"
              onClick={() => formatCircle()}
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-hubchart btn-hubchart-close"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
      {addDiagnosisModalShown && (
        <AddDiagnosisModal
          handleChangeTextAreaDetails={handleChangeTextAreaDetails}
          handleCloseAddDiagnosisModal={closeAddDiagnosisModal}
          addTextAreaField={addTextAreaField}
          removeTextAreaField={removeTextAreaField}
          saveTempDiagnosis={handleSaveTempDiagnosis}
          selectedTempDiagnosis={selectedTempDiagnosis}
          method={method}
        />
      )}
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default PatientDiagnosisModal;
