import React, { useEffect } from 'react';
import Ringtone from '../../../assets/audio/ringback_tone.mp3';
import { Howl } from 'howler';

function CallRingBack(props) {

    useEffect(componentDidMount, []);

    function componentDidMount() {
        
        const newSound = new Howl({
            src: [Ringtone],
            autoplay: true,
            loop: true,
            autoUnlock : true,
            usingWebAudio : true,
            onplayerror: () => {
                newSound.once('unlock', function() {
                    newSound.play();
                });
            }
        });
        
        newSound.play();
        
        return () => {
            newSound.stop();
        };
    }

    return <div style={{display: 'none'}}></div>;
}

export default CallRingBack;
