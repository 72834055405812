import React from 'react';
import Item from './Item';

const Participants = ({ invited=[], onRequestMic, onRequestCam, isHost }) => {
  return (
    <div className="d-flex flex-column h-100">
      <div className="h-100" style={{overflowY: 'auto', position: 'relative'}}>
        <div className="mycard-1 py-0 w-100" style={{position: 'absolute'}}>
          <div className="list-group">
            {invited.map(user =>
              <Item
                isHost={isHost}
                onRequestCam={onRequestCam}
                onRequestMic={onRequestMic}
                key={user.objectId}
                user={user}/>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Participants;
