import React, { Component } from 'react'
import ReactDOM from 'react-dom'

export class VideoPortal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            externalWindow : null,
            containerElement : null
        }

        this.closePortal = this.closePortal.bind(this)
    }

    componentDidMount() {
        // 'width=600,height=400,left=200,top=200'
        var strWindowFeatures = "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes";
        let externalWindow = window.open('', 'Video Call', 'menubar=1,status=1');
        let containerElement = document.createElement('div');

        externalWindow.document.body.appendChild(containerElement);
        externalWindow.document.title = 'Video Call';
        // copyStyles(document, this.externalWindow.document);
        // update the state in the parent component if the user closes the
        // new window
        externalWindow.addEventListener('beforeunload', this.closePortal);

        this.setState({
            externalWindow : externalWindow,
            containerElement : containerElement
        })

    }

    componentWillUnmount() {
        // This will fire when this.state.showWindowPortal in the parent component becomes false
        // So we tidy up by just closing the window
        this.state.externalWindow.close();
        this.state.externalWindow.removeEventListener('beforeunload', this.closePortal);
    }

    closePortal() {
        this.props.closeWindowPortal();
    }

    render() {
        const { containerElement } = this.state;
        if (!containerElement) {
            return null;
        }

        return ReactDOM.createPortal(this.props.children, containerElement);
    }
}

export default VideoPortal
