import React, { Component } from 'react'
import { connect } from 'react-redux'
import TabSearch from '../../TabList/TabSearch'
import sort from 'fast-sort';
import RecentItem from './RecentItem';
import Notify from '../../Common/Notify';
import Parse from 'parse'
import { randomID, forwardMessage, openCloseCheckBoxes } from '../../../actions/ThreadAction'
import { fetchGroups } from '../../../actions/GroupAction'
import { fetchContacts } from '../../../actions/ContactAction'
import { parseAction } from '../../../actions/REST'
import LoadingSpinner from '../../../components/Conversation/Bubble/LoadingSpinner'
import config from '../../../config'
import '../../TabList/TabList.css'

const $ = window.$;
export class ForwardModal extends Component {

    constructor() {

        super();

        this.state = {
            forwardingTo: [],
            tempThread: [],
            tempContacts: [],
            tempGroups: [],
            toBeFilter: "",
            successSend: [],
            searchTimeout: null,

            // Will contain search results
            contacts: [],
            groups: [],
            patients: [],

            isLoadingSearch: false,
            noResults: false,
        };
    }


    componentDidMount() {

        if (this.props.isOpen) {
            $("#Forward").modal('show');
        } else {
            $("#Forward").modal('hide');
        }
        $("#Forward").on('hidden.bs.modal', function (e) {
            this.props.modalClose();
        }.bind(this));

        if (!this.props.groupInitialLoad) {
            this.props.fetchGroups();
        } else {
            this.setState({
                tempGroups: this.props.groups
            })
        }

        if (!this.props.contactInitialLoad) {
            this.props.fetchContacts();
        } else {
            this.setState({
                tempContacts: this.props.contacts
            })
        }

        this.setState({
            tempThread: this.props.threads
        })
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        if (this.state.tempContacts.length === 0) {
            if (this.props.contacts.length !== 0) {
                this.setState({
                    tempContacts: this.props.contacts
                })

            }
        }

        if (this.state.tempGroups.length === 0) {
            if (this.props.groups.length !== 0) {
                this.setState({
                    tempGroups: this.props.groups
                })

            }
        }

        return null;
    }

    componentDidUpdate() {
        $('[data-toggle="tooltip"]').tooltip();
    }


    filterList(value) {

        const { searchTimeout } = this.state;

        if (value !== '') {

            if (searchTimeout) {
                clearTimeout(searchTimeout);
            }

            const newSearchTimeout = setTimeout(() => {
                this.searchContacts(value);
            }, 500);

            this.setState({ searchTimeout: newSearchTimeout });
        }

        this.setState({
            toBeFilter: value,
            noResults: false
        })
    }

    searchContacts(text) {

        this.setState({ isLoadingSearch: true });

        parseAction("post", config.BASE_URL + '/parse/functions/searchAll',
            {
                text: text
            }
        )
            .then(data => {

                const { result } = data;

                const { contacts = [], groups = [], patients = [] } = result;

                const noResults = contacts.length === 0 &&
                    groups.length === 0 &&
                    patients.length === 0;

                this.setState({
                    contacts,
                    groups,
                    patients,
                });

                this.setState({
                    isLoadingSearch: false,
                    noResults
                });
            })
            .catch(error => {
                // TODO handle error when fetching search contact
                this.setState({ isLoadingSearch: false });
            });
    }

    createNewMessage(data, type) {
        let thread = {
            senderName: "",
            senderObjectId: "",
            threadDetail: "",
            unreadMessageCount: 0,
            threadId: "",
            objectId: ""
        };


        switch (type) {
            case "group":
            case "chart":
                thread.threadType = "group";
                thread.threadId = data.objectId;
                thread.objectId = data.objectId;
                if (data.image) {
                    thread.groupImageURL = data.image.url;
                }
                thread.groupName = data.name;
                thread.groupType = data.groupType;
                break;

            case "contact":
                let tempThreadId;
                let idArray = [Parse.User.current().id, data.contact.objectId];
                idArray = sort(idArray).asc((p) => p.toLowerCase());
                tempThreadId = idArray.join("_");
                thread.threadId = tempThreadId;
                thread.objectId = data.objectId;
                thread.partnerHcuType = "regular"
                thread.partnerName = data.contact.displayName;
                thread.partnerObjectId = data.contact.objectId
                thread.threadType = "private";
                if (data.contact.picture) thread.partnerImageURL = data.contact.picture.url;
                if (data.contact.hcuSubscriptionType) thread.partnerHcuType = data.contact.hcuSubscriptionType;
                break;

            default:
                break;
        }
        this.handleMultipleForward(thread);
    }

    handleMultipleForward(thread) {
        const promises = this.props.messages.map(message => {
            return this.createNewMessageThread(thread, message);
        });
      
        Promise.all(promises)
            .then(res => {
                const successSendMessage = res[res.length -1];
                this.setState(prevProps => ({
                    successSend: [...prevProps.successSend, successSendMessage],
                    forwardingTo: [...prevProps.forwardingTo.filter((data) => {
                        return data.threadId !== successSendMessage.threadId
                    })]
                }));
                this.props.openCloseCheckBoxes(false);
            })
            .catch(error => {
                console.log(error);
            });
    }

    createNewMessageThread(thread, message) {
        var hasThread = this.state.forwardingTo.find(c => c.threadId === thread.threadId);
        let ms = "";
        if (message.text) {
            ms = "'" + message.text + "'";
        }

        let sender = message.user.displayName;
        if (message.user.objectId === Parse.User.current().id) {
            sender = Parse.User.current().get("displayName");
        }
        let fwdText = "Fwd: " + ms + " -- " + sender;
        
        if (hasThread) {
            // is forwarding
        } else {
            const date = new Date();
            let newMessage = {
                text: fwdText.trim(),
                originalMessageDate: date,
                threadId: thread.threadId,
                user: {
                    "__type": "Pointer",
                    "className": "_User",
                    "objectId": Parse.User.current().id
                },
                objectId: randomID(),
                threadType: thread.threadType,
                newMessageStatus: "sending",
                createdAt: date,

            }

            if (message.attachments) {
                newMessage.attachments = message.attachments;
            }

            if (typeof message.picture !== "undefined") {
                newMessage.picture = message.picture;
            }

            if (message.smsFrom) {
                newMessage["smsFrom"] = message.smsFrom;
                newMessage["mmsUrl"] = message.mmsUrl;
                newMessage["mmsContentType"] = message.mmsContentType;
            }

            switch (thread.threadType) {
                case "private":
                    newMessage.contact = {
                        "__type": "Pointer",
                        "className": "_User",
                        "objectId": thread.partnerObjectId
                    }
                    break;

                case "group":
                    newMessage.circle = {
                        "__type": "Pointer",
                        "className": "Circle",
                        "objectId": thread.threadId
                    }
                    break;

                case "group":
                    newMessage.adhocGroupId = thread.threadId;
                    break;

                default:
                    break;
            }

            this.setState(prevProps => ({
                forwardingTo: [...prevProps.forwardingTo, thread]
            }));

            return this.props.forwardMessage(newMessage, thread, message)
                .then((successSendMessage) => {
                    return successSendMessage;
                })
        }

    }

    getFileExtention(name) {
        return name.substr((~-name.lastIndexOf(".") >>> 0) + 2);
    }

    render() {
        let filteredThread = [...this.state.tempThread];
        let filteredContact = [...this.state.tempContacts];
        let filteredGroup = [...this.state.tempGroups];
        let filteredPatients = [];

        if (this.state.toBeFilter !== "") {
            filteredThread = this.state.tempThread.filter((thread) => {
                let Name = "";
                if (thread.threadType === "private") {
                    Name = thread.partnerName;
                }
                if (thread.threadType === "group") {
                    Name = thread.groupName;
                }
                return Name.toLowerCase().includes(this.state.toBeFilter.toLowerCase());
            })

            filteredContact = this.state.contacts.map(contact => {
                return { contact };
            });

            filteredGroup = this.state.groups;
            filteredPatients = this.state.patients;
        }

        let Recents = filteredThread.slice(0, 5).map((data, i) => {
            let hasThread = this.state.forwardingTo.find(c => c.threadId === data.threadId);
            let successSend = this.state.successSend.find(s => s.threadId === data.threadId);
            let isForwarding = false;
            let isSuccess = false;
            if (hasThread) isForwarding = true;
            if (successSend) isSuccess = true;
            return <RecentItem groupType={data.groupType} type="message" key={"forward-message-" + i} thread={data} isForwarding={isForwarding} successSend={isSuccess} createNewMessage={this.handleMultipleForward.bind(this, data)} />
        })

        let Contacts = filteredContact.slice(0, 5).map((data, i) => {
            let hasContact = this.state.forwardingTo.find(c => c.objectId === data.objectId);
            let tempThreadId;
            if (!data.contact) return;
            let idArray = [Parse.User.current().id, data.contact.objectId];
            idArray = sort(idArray).asc((p) => p.toLowerCase());
            tempThreadId = idArray.join("_");
            let successSend = this.state.successSend.find(s => s.threadId === tempThreadId);
            let isForwarding = false;
            let isSuccess = false;
            if (hasContact) isForwarding = true;
            if (successSend) isSuccess = true;
            return <RecentItem type="contact" key={"forward-contact-" + i} data={data} isForwarding={isForwarding} successSend={isSuccess} createNewMessage={this.createNewMessage.bind(this, data, "contact")} />
        })

        let Groups = filteredGroup.slice(0, 5).map((data, i) => {
            let hasGroup = this.state.forwardingTo.find(c => c.objectId === data.objectId);
            let successSend = this.state.successSend.find(s => s.threadId === data.objectId);
            let isForwarding = false;
            let isSuccess = false;
            if (hasGroup) isForwarding = true;
            if (successSend) isSuccess = true;
            return <RecentItem type="group" key={"forward-group-" + i} data={data} isForwarding={isForwarding} successSend={isSuccess} createNewMessage={this.createNewMessage.bind(this, data, "group")} />
        })

        let Patients = filteredPatients.slice(0, 5).map((data, i) => {
            let hasGroup = this.state.forwardingTo.find(c => c.objectId === data.objectId);
            let successSend = this.state.successSend.find(s => s.threadId === data.objectId);
            let isForwarding = false;
            let isSuccess = false;
            if (hasGroup) isForwarding = true;
            if (successSend) isSuccess = true;
            return <RecentItem groupType="patient" type="group" key={"forward-group-" + i} data={data} isForwarding={isForwarding} successSend={isSuccess} createNewMessage={this.createNewMessage.bind(this, data, "group")} />
        });

        let noResultStyle = {
            textAlign: "center",
            fontSize: "13px",
            fontWeight: "500",
            color: "#0000008a"
        }

        let containerStyle = {
            border: "0px solid white",
            minHeight: "50px",
            height: "50px",
            borderRadius: "0px",
            padding: "0"
        };

        const { isLoadingSearch, noResults } = this.state;

        return (
            <div id="Forward" className="modal fade" tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content modal-full-height">
                        <div className="modal-header">
                            <h5 className="modal-title hc-primary-text">Forward</h5>
                            <button style={{ position: "absolute", top: "15px", right: "15px" }} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body d-flex flex-column">
                            <TabSearch 
                                placeholder="Search for contacts and groups" 
                                typing={this.filterList.bind(this)} 
                                tabContainerStyle={{ margin: "0" }} 
                            />
                        </div>
                        <div className="modal-body mb-1" style={{ display: "block", height: "100%", overflowX: "hidden", overflowY: "auto" }}>
                            {isLoadingSearch ?
                                <a key="group-load-more" style={containerStyle} className="list-group-item list-group-item-action flex-column align-items-start">
                                    <LoadingSpinner top={"0"} />
                                </a>
                                : ''
                            }
                            {!isLoadingSearch ?
                                <div style={{ overflowX: "hidden", overflowY: "auto" }}>
                                    {
                                        this.state.toBeFilter === '' ?
                                            <span>
                                                <span className="hc-primary-text">Recent</span>
                                                <div className="list-group">
                                                    {Recents.length === 0 && <span style={noResultStyle}>No results</span>}
                                                    {Recents}
                                                </div>
                                                <div style={{ height: "20px" }}></div>
                                            </span>
                                            : ''
                                    }
                                    {
                                        Contacts.length ?
                                            <span>
                                                <span className="hc-primary-text">Contacts</span>
                                                <div className="list-group">
                                                    {Contacts.length === 0 && <span style={noResultStyle}>No results</span>}
                                                    {Contacts}
                                                </div>
                                                <div style={{ height: "20px" }}></div>
                                            </span>
                                            : ''
                                    }
                                    {
                                        Groups.length ?
                                            <span>
                                                <span className="hc-primary-text">Groups</span>
                                                <div className="list-group">
                                                    {Groups.length === 0 && <span style={noResultStyle}>No results</span>}
                                                    {Groups}
                                                </div>
                                                <div style={{ height: "20px" }}></div>
                                            </span>
                                            : ''
                                    }
                                    {
                                        Patients.length ?
                                            <span>
                                                <span className="hc-primary-text">Patients</span>
                                                <div className="list-group">
                                                    {Patients.length === 0 && <span style={noResultStyle}>No results</span>}
                                                    {Patients}
                                                </div>
                                                <div style={{ height: "20px" }}></div>
                                            </span>
                                            : ''
                                    }
                                    {
                                        this.state.toBeFilter != '' ?
                                            <span>
                                                <div className="list-group">
                                                    {
                                                        noResults &&
                                                        <span style={noResultStyle}>No results</span>
                                                    }
                                                </div>
                                                <div style={{ height: "20px" }}></div>
                                            </span>
                                            : ''
                                    }
                                </div>
                                : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    threads: state.thread.threads,
    contacts: state.contact.contacts,
    contactInitialLoad: state.contact.initialLoad,
    groups: state.group.groups,
    groupInitialLoad: state.group.initialLoad
})

export default connect(mapStateToProps, { 
    forwardMessage, 
    fetchGroups, 
    fetchContacts,
    openCloseCheckBoxes,
})(ForwardModal);
