import React from 'react';
import defaultImageUrl from '../../../../../assets/images/default.png';

const SearchItem = ({ onSelect, circle }) => {

    const { lastName, firstName, image } = circle;

    const imageUrl = image ? image.url : defaultImageUrl;

    return (
        <div className="border-item layout-item">
            <img src={imageUrl}
                alt=""
                className="profile-image rounded-circle mr-2"
                style={{ width: "30px", height: "30px" }} />
            <p className="name m-0 text-uppercase">{lastName + ', ' + firstName}</p>
            <button onClick={(e) => onSelect(circle.objectId)} className="btn btn-primary btn-sm ml-auto text-nowrap">
                Select
            </button>
        </div>
    )
}

export default SearchItem;
