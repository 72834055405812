const initialState = {
  // Data
  requests: null,
  attentionCount: null,
  urgentCount: null,
  returnCallCount: null,
  highPriorityCount: null,
  mediumPriorityCount: null,
  routinePriorityCount: null,

  // Whether the dashboard is loading
  isLoading: false,

  // Whether risk management is loading
  isLoadingRM: false,

  // Whether today schedules is loading
  isLoadingST: false,

  // Recents tagged as risk management
  riskManagement: [],

  // Conferences scheduled today
  schedulesToday: [],

  // Conferences scheduled today
  circleLabels: [],

  // Whether today schedules has more schedules
  scheduleTodayHasMore: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_DASHBOARD":
      return {
        ...state,
        ...action.data,
      };

    case "SET_RISK_MANAGEMENT":
      return {
        ...state,
        riskManagement: action.recents,
      };

    case "SET_SCHEDULES_TODAY":
      return {
        ...state,
        schedulesToday: action.conferences,
        allCurrentPage: action.allCurrentPage
      };

    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.value,
      };

    case "SET_LOADING_RISK_MANAGEMENT":
      return {
        ...state,
        isLoadingRM: action.value,
      };

    case "SET_LOADING_SCHEDULES_TODAY":
      return {
        ...state,
        isLoadingST: action.value,
      };

    case "UPDATE_CIRCLE_LABELS":
      return {
        ...state,
        circleLabels: updateLabelsWithCount(
          action.circleLabels,
          action.circleLabelsCount
        ),
      };

    case "SET_SCHEDULE_HAS_MORE":
      return {
        ...state,
        scheduleTodayHasMore: action.value
      };

    default:
      return state;
  }
}

const updateLabelsWithCount = (labels, labelsCount) => {
  return labels.map((label) => {
    const total =
      labelsCount.find((lb) => lb.objectId === label.objectId) || {};

    return {
      ...label,
      count: total.count || 0,
    };
  });
};
