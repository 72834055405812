import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Parse from "parse";
import moment from "moment";
import TextareaAutosize from "react-textarea-autosize";
import { parseAction } from "../../../../actions/REST";
import Loader from "../../../Common/Loader";
import Notify from "../../../Common/Notify";
import { ToastContainer } from "react-toastify";
import AddProblemsModal from "../../../Conversation/Setting/PatientProblemsModal/AddProblemsModal";
import config from "../../../../config";
import { computeAge } from "../../../../helper/util";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "../../../Pages/Login/Login.css";
import "../../../Conversation/Setting/SettingModal.css";
import PatientInfo from "../PatientInfo";
import Logo from "../../../../assets/images/logo-blue.png";

const $ = window.$;
const modalId = "#patientProblemsModal";
const defaultSelectedProblem = {
  problem: "",
  onsetDate: new Date(),
  is_active: false,
  is_resolved: false,
  type: "manual",
};

export default function PatientProblemsView({
  threadId = "",
  refreshData = () => {},
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [manualDxEntryDisabled, setIsManualDxEntryDisabled] = useState(false);
  const [addProblemsModalShown, setAddProblemsModalShown] = useState(false);
  const [patientInfo, setPatientInfo] = useState({});
  const [selectedProblems, setSelectedProblems] = useState([]);
  const [selectedTempProblems, setSelectedTempProblems] = useState([
    { ...defaultSelectedProblem },
    { ...defaultSelectedProblem },
    { ...defaultSelectedProblem },
    { ...defaultSelectedProblem },
    { ...defaultSelectedProblem },
  ]);
  const [method, setMethod] = useState("create");

  function fetchPatientInfo(threadId) {
    setIsLoading(true);
    parseAction("get", config.BASE_URL + "/parse/classes/Circle", {
      where: {
        objectId: threadId,
      },
    })
      .then((result) => {
        const data = result.results[0];
        const problems = (data.problems || []).map((item) => ({
          ...item,
          onsetDate: (item.onsetDate || {}).iso
            ? new Date(item.onsetDate.iso)
            : "",
        })); // will be replaced with right props from backend
        setPatientInfo(data);
        setSelectedProblems(problems); //
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }

  function formatCircle(newProblems = []) {
    const data = {
      objectId: patientInfo.objectId,
      problems: newProblems.length > 0 ? newProblems : selectedProblems,
    };

    const Circle = Parse.Object.extend("Circle");
    const newCircle = new Circle(data);

    handleSave(newCircle);
  }

  async function handleSave(circle) {
    setIsLoading(true);

    try {
      const group = await circle.save();

      setPatientInfo(group.toJSON());
      setIsLoading(false);

      Notify.success("Problems successfully saved.");
      refreshData();
      $("#patientProblemsModal").modal("hide");
    } catch (err) {
      setIsLoading(false);
      Notify.error("Could not save problems.");
    }
  }

  function setLoading(boolean) {
    setIsLoading(boolean);
  }

  function removeItem(item) {
    let problems = [...selectedProblems];
    problems.splice(problems.indexOf(item), 1);

    setSelectedProblems(problems);
    formatCircle(problems);
  }

  function updateOnsetDate(item, date) {
    const problems = [...selectedProblems];
    const item_index = problems.indexOf(item);
    problems[item_index].onsetDate = date;

    setSelectedProblems(problems);
    formatCircle(problems);
  }

  function manualProblemEntry() {
    setIsManualDxEntryDisabled(true);
    setTimeout(() => {
      const newSelectedProblems = [
        {
          problem: "",
          onsetDate: new Date(),
          is_active: false,
          is_resolved: false,
          type: "manual",
        },
        ...selectedProblems,
      ];
      setSelectedProblems(newSelectedProblems);
      setIsManualDxEntryDisabled(false);
    }, 100);
  }

  function onInputChange(e, idx) {
    const { value } = e.target;
    let problems = [...selectedProblems];

    problems[idx].problem = value;
    setSelectedProblems(problems);
  }

  function onInputCheckedChange(e, idx) {
    const { checked, name } = e.target;
    let problems = [...selectedProblems];

    problems[idx][name] = checked;
    setSelectedProblems(problems);
    formatCircle(problems);
  }

  function onInputProblemChange(e, idx) {
    const { value } = e.target;
    let problems = [...selectedProblems];
    problems[idx].problem = value;

    setSelectedProblems(problems);
  }

  function calculateAge(dob) {
    const newDate = new Date(dob);
    return moment().diff(moment(newDate, "YYYY"), "years");
  }

  function handleChangeTextAreaDetails(e, i) {
    const { value } = e.target;
    let newArr = [...selectedTempProblems];
    newArr[i].problem = value;

    setSelectedTempProblems([...newArr]);
  }

  function addTextAreaField() {
    setSelectedTempProblems([
      {
        ...defaultSelectedProblem,
        problem: "",
      },
      ...selectedTempProblems,
    ]);
  }

  function removeTextAreaField(i) {
    let newArr = selectedTempProblems;
    newArr.splice(i, 1);
    setSelectedTempProblems([...newArr]);
  }

  function closeAddProblemsModal() {
    // $("#patientAddProblemsModal").modal('hide');
    setAddProblemsModalShown(false);
  }

  function handleSetAddProblemsModalShown() {
    setAddProblemsModalShown(true);
    setTimeout(() => {
      $("#patientAddProblemsModal").modal({
        backdrop: false,
        keyboard: false,
      });
      $("#patientAddProblemsModal").modal("show");
    }, 100);
  }

  function handleSaveTempProblems() {
    const filteredSelectedTempProblems = selectedTempProblems.filter(
      (item) => item.problem
    );
    const newProblems = [...filteredSelectedTempProblems, ...selectedProblems];

    setSelectedProblems(newProblems);
    setSelectedTempProblems([
      { ...defaultSelectedProblem },
      { ...defaultSelectedProblem },
      { ...defaultSelectedProblem },
      { ...defaultSelectedProblem },
      { ...defaultSelectedProblem },
    ]);
    closeAddProblemsModal();
    formatCircle(newProblems);
  }

  useEffect(() => {
    if (threadId) {
      fetchPatientInfo(threadId);
    }
  }, [threadId]);

  return (
    <div className="progress-notes-container pn-con">
      <div className="container mb-15 pn-title-flex">
        <span className="navbar-brand">
          <img
            className="d-sm-inline"
            width="40"
            height="40"
            src={Logo}
            alt="H"
          ></img>
          <span
            className="hc-primary-text d-none d-md-inline"
            style={{ fontWeight: "500" }}
          >
            HUBCHART
          </span>
        </span>
        <div className="pn-patient-details-flex">
          <div>
            <span>{patientInfo.name || "--"}</span>
            <span>PROBLEMS</span>
          </div>
          {(typeof (patientInfo.image || {}).url === "undefined" ||
            (patientInfo.image || {}).url === "") && (
            <img src={require("../../../../assets/images/default.png")} />
          )}
          {(patientInfo.image || {}).url && (
            <img src={(patientInfo.image || {}).url} />
          )}
        </div>
        {/* <span className="pn-title">PATIENT PROBLEMS</span> */}
      </div>
      <div className="pn-template-container">
        <div className="container pb-10">
          <PatientInfo patientInfo={patientInfo} />
        </div>
      </div>
      <div class="container">
        <div className="filter-view" style={{ margin: "15px 0px" }}>
          <div className="search-flex search-identifier">
            <div className="rbt-container">
              <div />
            </div>
            <button
              type="button"
              className="btn btn-hubchart btn-sm btn-hubchart-primary"
              disabled={manualDxEntryDisabled}
              onClick={() =>
                handleSetAddProblemsModalShown()
              } /* manualProblemEntry() */
              style={{ margin: "0px", height: "33px", width: "170px" }}
            >
              <i className="fas fa-plus"></i> Add
            </button>
          </div>
        </div>
        <div className="problems" style={{ overflowX: "auto" }}>
          {selectedProblems.length > 0 && (
            <div className="setting-modal-headers">
              <div>Problem</div>
              <div>Date Entered</div>
              <div>Resolved</div>
              <div>Action</div>
            </div>
          )}
          <div className="setting-modal-item-container">
            {selectedProblems.map((item, i) => (
              <div
                className="setting-modal-item"
                key={`pms-${moment(new Date()).format("lll")}-${i}`}
              >
                <TextareaAutosize
                  className="setting-modal-input-2"
                  value={item.problem}
                  minRows={1.3}
                  onChange={(e) => onInputProblemChange(e, i)}
                  onBlur={() => formatCircle()}
                />
                <span>
                  <DatePicker
                    className="date-picker-custom black"
                    placeholderText="mm/dd/yyyy"
                    selected={item.onsetDate}
                    onChange={(date) => updateOnsetDate(item, date)}
                    isClearable
                  />
                </span>
                <span>
                  <input
                    type="checkbox"
                    id="is_resolved"
                    name="is_resolved"
                    checked={item.is_resolved}
                    style={{ marginTop: "10px" }}
                    onChange={(e) => onInputCheckedChange(e, i)}
                  />
                </span>
                <span>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    title="Remove Problem"
                    onClick={() => removeItem(item)}
                  >
                    <i className="fas fa-trash"></i>
                  </button>
                </span>
              </div>
            ))}
          </div>
        </div>
        {selectedProblems.length === 0 && (
          <div className="default-view">
            <span>No Problems Found.</span>
          </div>
        )}
        {addProblemsModalShown && (
          <AddProblemsModal
            handleChangeTextAreaDetails={handleChangeTextAreaDetails}
            handleCloseAddProblemsModal={closeAddProblemsModal}
            addTextAreaField={addTextAreaField}
            removeTextAreaField={removeTextAreaField}
            saveTempProblems={handleSaveTempProblems}
            selectedTempProblems={selectedTempProblems}
            method={method}
          />
        )}
        <Loader isLoading={isLoading} />
        <ToastContainer />
      </div>
    </div>
  );
}
