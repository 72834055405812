import React, { Component } from "react";
import { connect } from "react-redux";
import Parse from "parse";
import AccountContainer from "./AccountContainer";
import CorporateContainer from "./CorporateContainer";
import CorporateSetting from "./CorporateSetting";
import PrescriptionContainer from "./PrescriptionContainer";
import TeamContainer from "./TeamContainer.js";
import "./ProfileSetting.css";
import SubscriptionContainer from "./Subscription/SubscriptionContainer";
import Switch from "react-switch";
import {
  showProfileSidebar,
  hideProfileSidebar,
  showAccount,
  hideAccount,
} from "../../../actions/GlobalAction";

const pckgeJSON = require("../../../../package.json");

const $ = window.$;

export class ProfileSetting extends Component {
  constructor() {
    super();
    this.state = {
      user: "",
      profilePictureURL: "",
      willUpdateUserProfile: false,
      savingUserProfile: false,
      willUpdateCompany: false,
      savingCompany: false,
      willUpload: false,
      isUploading: false,
      isAvailable: true,
      isCriticalTask: false,
    };

    this._renderAccount = this._renderAccount.bind(this);
  }

  componentDidMount() {
    const user = Parse.User.current();

    this.setState({
      isAvailable: user.get("isAvailable"),
    });
  }

  componentWillUnmount() {
    this.closeNav();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.profileSidebarShown) {
      this.openNav(newProps.openAccount);
    } else {
      this.closeNav();
    }
  }

  openNav(openAccount) {
    if (openAccount) {
      this.refs.mySidenav.style.width = "850px";
      this.refs.blur.style.width = "100%";
    } else {
      this.refs.mySidenav.style.width = "350px";
      this.refs.blur.style.width = "100%";
    }
  }

  closeNav() {
    let image = this.state.profilePictureURL;
    let picture = Parse.User.current().get("picture");
    if (typeof picture != "undefined") {
      image = picture._url;
    } else {
      image = require("../../../assets/images/default.png");
    }
    this.setState({
      willUpdateUserProfile: false,
      willUpdateCompany: false,
      isUploading: false,
      willUpload: false,
      profilePictureURL: image,
    });
    this.props.hideAccount();
    this.refs.mySidenav.style.width = "0";
    this.refs.blur.style.width = "0";
  }

  handleCloseNav() {
    this.props.hideProfileSidebar();
  }

  logoutUser() {
    Parse.User.logOut().then(() => {
      // localStorage.removeItem("activeTab");
      localStorage.setItem("is_logged_in", false);
      // localStorage.clear();
      window.location.href = "/";
    });
  }

  toggleAccount(type) {
    if (type === this.props.accountType) {
      if (this.props.openAccount) {
        this.refs.mySidenav.style.width = "350px";
        this.props.hideAccount(type);
      } else if (!this.props.openAccount) {
        this.refs.mySidenav.style.width = "850px";
        this.props.showAccount(type);
      }
    } else {
      this.refs.mySidenav.style.width = "850px";
      this.props.showAccount(type);
    }
    this.refs.blur.style.width = "100%";
  }

  _renderAccount() {
    switch (this.props.accountType) {
      case "account":
        return <AccountContainer />;
        break;

      case "corporate":
        // return <CorporateContainer />;
        return <CorporateSetting />;
        break;

      case "prescription":
        return <PrescriptionContainer />;
        break;

      case "teams":
        return <TeamContainer />;
        break;

      case "subscription":
        return <SubscriptionContainer openAccount={this.props.openAccount} />;
        break;
    }
  }

  handleChange(checked) {
    this.setState({
      isAvailable: checked,
    });

    const user = Parse.User.current();

    user.set({ isAvailable: checked });

    user.save();
  }

  handleCriticalTask(checked) {
    this.setState({
      isCriticalTask: checked,
    })
  }

  openCorporateView() {
    const { activeThread } = this.props;
    const filesUrl = [window.location.origin, "/corporate-view"].join("");
    // const filesUrl = [window.location.origin, "/corporate-setting"].join("");
    window.open(filesUrl, "Hubchart Corporate");
  }

  render() {
    let userObject = JSON.stringify(Parse.User.current().toJSON());
    userObject = JSON.parse(userObject);

    let imageURL = require("../../../assets/images/default.png");
    if (userObject.picture) {
      if (userObject.picture.url) {
        imageURL = userObject.picture.url;
      }
    }
    let Name = "";
    let Email = "";

    if (userObject.displayName) Name = Parse.User.current().get("displayName");
    if (userObject.email) Email = Parse.User.current().get("email");

    let statusStyle = {
      padding: "0",
      fontSize: "12px",
      fontWeight: "500",
      color: "rgb(95, 93, 93)",
      whiteSpace: "pre-wrap",
    };

    const versionNumber = pckgeJSON.version;

    return (
      <div>
        <div
          ref="blur"
          className="blur"
          onClick={this.handleCloseNav.bind(this)}
        ></div>
        <div ref="mySidenav" className="Profile-Setting-Nav">
          <a
            href="#!"
            className="closebtn"
            onClick={this.handleCloseNav.bind(this)}
          >
            <i className="fas fa-times fa-fw"></i>
          </a>
          <div
            className="setting-container"
            style={{
              width: "351px",
              borderRight: "1px solid rgba(0,0,0,0.12)",
            }}
          >
            <div className="img-container">
              <img src={imageURL} alt="Group Profile Picture" />
              <p>{Name}</p>
              <p className="hc-primary-text p-0">{Email}</p>
              <p style={statusStyle}>{userObject.statusMessage}</p>
            </div>
            <div
              className="detail-container"
              style={{ background: "transparent" }}
            >
              <span className="action hover-primary">
                <i
                  className="fas fa-id-badge fa-fw"
                  style={{ margin: "0 10px" }}
                ></i>
                <label>
                  Available
                  <Switch
                    onChange={(e) => this.handleChange(e)}
                    checked={this.state.isAvailable}
                    onColor="#b6e0ff"
                    onHandleColor="#2693e6"
                    handleDiameter={20}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={15}
                    width={30}
                    className="react-switch ml-1 shift-down-4"
                    id="material-switch"
                  />
                </label>
              </span>
            </div>
            {/* <div
              className="detail-container action hover-primary"
              style={{ background: "transparent", display:'flex', color:'black' }}
            >
              <span className="">
                <i
                  className="fas fa-exclamation-triangle mr-1"
                  style={{ margin: "0 10px" }}
                ></i>
              </span>  
              <div style={{display:'inline'}}>
                Critical Task Pop-up
                <div style={{fontSize:'9px'}}>Pop-up every 5 mins</div>
              </div>                  
              <Switch
                onChange={(e) => this.handleCriticalTask(e)}
                checked={this.state.isCriticalTask}
                onColor="#b6e0ff"
                onHandleColor="#2693e6"
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={15}
                width={30}
                className="react-switch ml-1 shift-down-4"
                id="critical-switch"
              />
            </div> */}
            <div
              className="detail-container"
              style={{ background: "transparent" }}
            >
              <p
                className="action hover-primary"
                onClick={this.toggleAccount.bind(this, "account")}
              >
                <i
                  className="fas fa-user-alt fa-fw"
                  style={{ margin: "0 10px" }}
                ></i>
                My Profile
              </p>
            </div>
            <div
              className="detail-container"
              style={{ background: "transparent" }}
            >
              <p
                className="action hover-primary"
                onClick={this.toggleAccount.bind(this, "prescription")}
              >
                <i
                  className="fas fa-prescription fa-fw"
                  style={{ margin: "0 10px" }}
                ></i>
                Prescription Settings
              </p>
            </div>
            <div
              className="detail-container"
              style={{ background: "transparent" }}
            >
              <p
                className="action hover-primary"
                onClick={this.openCorporateView.bind(this)}
              >
                {" "}
                {/* this.toggleAccount.bind(this, "corporate") */}
                <i
                  className="fas fa-city fa-fw"
                  style={{ margin: "0 10px" }}
                ></i>
                Corporate Account
              </p>
            </div>
            <div
              className="detail-container"
              style={{ background: "transparent" }}
            >
              <p
                className="action hover-primary"
                onClick={this.toggleAccount.bind(this, "teams")}
              >
                <i
                  className="fas fa-users fa-fw"
                  style={{ margin: "0 10px" }}
                ></i>
                Manage My Teams
              </p>
            </div>
            {/* <div className="detail-container" style={{background: "transparent"}}>
                            <p className="action hover-primary" onClick={this.toggleAccount.bind(this, "subscription")}><i className="fas fa-edit fa-fw" style={{margin: "0 10px"}}></i>Subscription / Upgrades</p>
                        </div> */}
            <div
              className="detail-container"
              style={{ background: "transparent" }}
            >
              <p className="action hover-primary">
                <i
                  className="fas fa-balance-scale fa-fw"
                  style={{ margin: "0 10px" }}
                ></i>
                Legal
              </p>
            </div>
            <div
              className="detail-container"
              style={{ background: "transparent" }}
            >
              <p className="action hover-primary">
                <i
                  className="fas fa-info-circle fa-fw"
                  style={{ margin: "0 10px" }}
                ></i>
                About
              </p>
            </div>
            <div
              className="detail-container"
              style={{ background: "transparent" }}
            >
              <p
                className="action hover-primary"
                onClick={this.logoutUser.bind(this)}
              >
                <i
                  className="fas fa-sign-out-alt fa-fw"
                  style={{ margin: "0 10px" }}
                ></i>
                Logout
              </p>
            </div>
            <div className="version-container">
              <span className="version-text">{`Ver ${versionNumber}`}</span>
            </div>
          </div>

          <div>{this._renderAccount()}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profileSidebarShown: state.global.profileSidebarShown,
  openAccount: state.global.openAccount,
  accountType: state.global.accountType,
});

export default connect(mapStateToProps, {
  showProfileSidebar,
  hideProfileSidebar,
  showAccount,
  hideAccount,
})(ProfileSetting);
