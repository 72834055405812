import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { fetchRecentResult } from "../../actions/PatientAction";

const TabSearch = ({ tabContainerStyle = {}, placeholder = "", activeTab, typeValue, typing }) => {
  const dispatch = useDispatch();

  const { recentSearch } = useSelector(mySelector, shallowEqual);

  useEffect(() => {
    fetchRecentResult()(dispatch);
  }, []);

  const searchRecent = (key) => {
    typing(key);
  };

  const isTyping = (e) => {
    if (typing) {
      typing(e.target.value);
    }
  };

  const [isFocus, setFocus] = useState(false);

  const getPlaceholder = (activeTab) => {
    switch (activeTab) {
      case "message":
        return "Search Messages";
      case "group":
        return "Search Groups";
      case "chart":
        return "Search Patients";
      case "contact":
        return "Search Contacts";
      case "create_chat":
        return "Search Contacts";
      case "facility":
        return "Search Facility";
      case "insurance":
        return "Search Facility";
      case "todos":
        return "Search Patient";
      default:
        return "";
    }
  };

  const _placeholder = useMemo(() => {
    return getPlaceholder(activeTab) || placeholder;
  }, [activeTab]);

  // Hide search input field when in conference tab
  if (activeTab === "conference") return "";

  const styleRecent = {
    background: "white",
    position: "absolute",
    left: "10px",
    top: "40px",
    zIndex: 2,
    width: "95%",
    border: "1px solid #ececec",
  };

  const styleRecentTxt = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  return (
    <div className="Tab-Search-Container" style={tabContainerStyle}>
      <i className="fas fa-search"></i>
      <input
        onChange={(e) => isTyping(e)}
        id="search"
        placeholder={_placeholder}
        type="search"
        value={typeValue}
        autocomplete="off"
        list="search"
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
      {/* {activeTab == "chart" && isFocus ? (
        <div style={styleRecent}>
          {recentSearch.map((term) => {
            return (
              <button
                style={styleRecentTxt}
                role="option"
                className="dropdown-item "
                href="#"
                onMouseDown={() =>
                  searchRecent(
                    term.circle.name
                    // term.circle.lastName + ", " + term.circle.firstName
                  )
                }
              >
                {term.circle.lastName.toUpperCase()},{" "}
                {term.circle.firstName.toUpperCase()}
              </button>
            );
          })}
        </div>
      ) : (
        <></>
      )} */}
    </div>
  );
};
const mySelector = (state) => ({
  recentSearch: state.patient.recentSearch,
});

export default TabSearch;
