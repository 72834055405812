import React, { Component } from 'react'

const $ = window.$;
export class ExceedLimit extends Component {

    componentDidMount() {
        if (this.props.isOpen) {
            $("#exceed-limit").modal('show');
        } else {
            $("#exceed-limit").modal('hide');
        }
        $("#exceed-limit").on('hidden.bs.modal', function (e) {
            this.props.modalClose();
        }.bind(this))
    }

    render() {
        return (
            <div id="exceed-limit" className="modal" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-body" style={{paddingBottom: "0px"}}>
                        <h5 style={{color: "#D84B55", fontSize: "1.25rem"}}>Exceed limit of sending SMS Code.</h5>
                        <p style={{fontSize: "0.9em"}}>Already exceed sending of SMS Code, Please try again later.</p>
                    </div>
                    <div className="modal-footer" style={{border: "0", padding: "0 20px 20px 0"}}>
                        <button type="button" className="btn btn-hubchart btn-hubchart-close" data-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ExceedLimit
