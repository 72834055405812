import React, { Component } from "react";
import { connect } from "react-redux";
import "../../../../TabList/TabList.css";
import Parse from "parse";
import { randomID, forwardMessage } from "../../../../../actions/ThreadAction";
import LoadingSpinner from "../../../../../components/Conversation/Bubble/LoadingSpinner";
import {
  handleOrderFormat,
  handleMessageFormat,
  removeExtraLines
} from "../../../../../helper/util";

const $ = window.$;
const modalId = "postOnHub";
export class ForwardPrescriptionModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      forwardingTo: [],
      tempThread: [],
      tempContacts: [],
      tempGroups: [],
      toBeFilter: "",
      successSend: [],
      searchTimeout: null,
      isLoadingSearch: false,
      noResults: false
    };
    this.createNewMessage = this.createNewMessage.bind(this);
    this.createNewMessageThread = this.createNewMessageThread.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    if (this.props.isOpen) {
      $(`#${modalId}`).modal("show");
    } else {
      $(`#${modalId}`).modal("hide");
    }
    // $(`#${modalId}`).on('hidden.bs.modal', function (e) {
    //     this.props.modalClose();
    // }.bind(this));

    this.setState({
      tempThread: this.props.thread
    });
  }

  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  createNewMessage(data) {
    let thread = {
      senderName: "",
      senderObjectId: "",
      threadDetail: "",
      threadType: "group",
      unreadMessageCount: 0,
      threadId: data.threadId || data.objectId,
      objectId: data.threadId || data.objectId,
      groupName: data.groupName,
      groupType: data.groupType
    };
    this.createNewMessageThread(thread);
  }

  createNewMessageThread(thread) {
    const date = new Date();
    const text = this.textarea.value;
    let newMessage = {
      text: text.trim(),
      originalMessageDate: date,
      threadId: thread.threadId,
      user: {
        __type: "Pointer",
        className: "_User",
        objectId: Parse.User.current().id
      },
      objectId: randomID(),
      threadType: thread.threadType,
      newMessageStatus: "sending",
      createdAt: date,
      circle: {
        __type: "Pointer",
        className: "Circle",
        objectId: thread.threadId
      },
      adhocGroupId: thread.threadId
    };

    this.props.forwardMessage(newMessage, thread);
    this.closeModal();
  }

  closeModal() {
    const { modalClose = () => {} } = this.props;
    $(`#${modalId}`).modal("hide");
    modalClose(true);
  }

  render() {
    const {
      title = "Forward Prescription",
      message,
      thread,
      patientName,
      isOrder = false
    } = this.props;
    let ms = `${
      isOrder ? "Orders" : "Prescription"
    } for patient ${patientName}, \n\n`;

    if (isOrder) {
      ms = ms + handleOrderFormat(message);
    }

    if (!isOrder && message.length > 0) {
      ms = ms + handleMessageFormat(message);
    }

    ms = removeExtraLines(ms);

    return (
      <div id="postOnHub" className="modal fade" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content modal-full-height">
            <div className="modal-header">
              <h5 className="modal-title hc-primary-text">{title}</h5>
              <button
                style={{ position: "absolute", top: "15px", right: "15px" }}
                type="button"
                className="close"
                onClick={() => this.closeModal()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="modal-body d-flex flex-column"
              style={{ overflowY: "hidden" }}
            >
              <textarea
                className="textarea-style"
                style={{
                  border: "1px solid #00000017",
                  height: "100%",
                  maxHeight: "560px",
                  marginBottom: "0px !important"
                }}
                ref={ta => (this.textarea = ta)}
                placeholder="Type your message here..."
                rows={100}
                defaultValue={ms}
              />
            </div>
            <div className="modal-footer mb-1">
              <button
                className="btn btn-hubchart btn-hubchart-primary"
                type="button"
                onClick={() => this.createNewMessage(thread)}
              >
                <i className="fas fa-paper-plane"></i> Send
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { forwardMessage })(
  ForwardPrescriptionModal
);
