import { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { fetchThreads } from "../actions/ThreadAction";
import Parse from "parse";
import util from "../helper/util";

export default () => {
  const dispatch = useDispatch();

  const { initialLoad, isLoading, activeThread } = useSelector(
    (state) => ({
      initialLoad: state.thread.initialLoad,
      isLoading: state.thread.isLoading,
      activeThread: state.thread.activeThread,
    }),
    shallowEqual
  );

  const threadIdForContact = (contactId) => {
    const user = Parse.User.current();

    return util.getThreadId(user.id, contactId);
  };

  useEffect(() => {
    if (!initialLoad) {
      dispatch(fetchThreads());
    }
  }, [initialLoad]);

  return {
    isLoading: !initialLoad || isLoading,
    activeThread,
    threadIdForContact,
  };
};
