import React, { Component } from "react";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { parse, getCountryCallingCode, isValidNumber } from "libphonenumber-js";
import Logo from "../../../assets/images/logo-blue.png";
import Parse from "parse";
import "./Login.css";
import "./Login.mobile.css";
import { connect } from "react-redux";
import { setActiveTab } from "../../../actions/ThreadAction";
const $ = window.$;

export class Login extends Component {
  constructor() {
    super();

    this.state = {
      usingWeb: false,
      phoneNumber: "",
      errorMessage: "",
      failedSignin: false,
      needVerify: false,
      emailToVerify: "",
      resendingEmailVerification: false,
      resendStatus: "",
      newUser: false,
      newUserEmail: "",
      copslock: false,
    };

    this.handlePhoneInput = this.handlePhoneInput.bind(this);
    this.checkCapsLock = this.checkCapsLock.bind(this);
  }

  componentDidMount() {
    let params = new URLSearchParams(this.props.location.search);
    let status = params.get("status");
    if (status === "success") {
      if (Parse.User.current()) {
        this.setState({
          errorMessage: "Please verify your email address!",
          failedSignin: true,
          needVerify: true,
          emailToVerify: Parse.User.current().get("email"),
        });
        Parse.User.logOut().then(() => {
          // localStorage.removeItem("activeTab");
          localStorage.setItem("is_logged_in", false);
          // localStorage.clear();
        });
      } else {
        Parse.User.logOut().then(() => {
          // localStorage.removeItem("activeTab");
          localStorage.setItem("is_logged_in", false);
          // localStorage.clear();
        });
      }
    } else {
      if (Parse.User.current()) {
        Parse.User.logOut().then(() => {
          // localStorage.removeItem("activeTab");
          localStorage.setItem("is_logged_in", false);
          // localStorage.clear();
        });
      }
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    $(this.refs.submit).prop("disabled", true);
    $(this.refs.submit).html(
      '<i class="fas fa-spinner spin-loader" style="margin-right: 10px"></i> Signing in...'
    );

    this.setState({
      errorMessage: "",
      failedSignin: false,
      needVerify: false,
    });

    let username,
      password = this.refs.password.value;
    if (this.state.usingWeb) {
      username = this.refs.username.value;
    } else {
      username = this.state.phoneNumber.replace(/\D/g, "");
    }

    if (username == "" || password == "") {
      return;
    } else {
      const user = Parse.User.logIn(username, password);

      user
        .then((user) => {
          localStorage.setItem("is_idled", false);
          localStorage.setItem("is_logged_in", true);
          if (!user.get("email") || user.get("email") === "") {
            window.location.href = "/email";
          }
          /**
           * Refresh to render the home component for mobile users
           */
          // if (user.get("webOnly") != true) {
          // 	window.location.href = '/';
          // 	return;
          // }

          /**
           * Refresh if email is verified
           */

          // let currentUser = Parse.User.current();
          // currentUser.set("lastDateActive", {
          //   iso: new Date("02/02/2023").toISOString(),
          //   __type: "Date"
          // });
          // currentUser.save();
          this.props.setActiveTab("dashboard");
          if (user.get("emailVerified")) {
            // setTimeout(() => {
            window.location.href = "/";
            // }, 200);
          } else {
            /**
             * User need to verify email address
             */
            this.setState({
              errorMessage: "Please verify your email address!",
              failedSignin: true,
              needVerify: true,
              emailToVerify: user.get("email"),
            });

            this.refs.password.value = "";
            // Parse.User.logOut()
            $(this.refs.submit).prop("disabled", false);
            $(this.refs.submit).text("Sign in");
          }
        })
        .catch((error) => {
          this.setState({
            errorMessage: "Invalid username/password combination.",
            failedSignin: true,
          });
          this.refs.password.value = "";
          $(this.refs.submit).prop("disabled", false);
          $(this.refs.submit).text("Sign in");
        });
    }
  }

  createAccount() {}

  switchToMobile() {
    this.setState((prevState) => ({
      usingWeb: !prevState.usingWeb,
    }));
  }

  handlePhoneInput(value) {
    this.setState({
      phoneNumber: value,
    });
  }

  resentEmailVerification() {
    this.setState({
      needVerify: false,
      failedSignin: false,
      resendingEmailVerification: true,
    });

    let currentUser = Parse.User.current();

    currentUser.set("email", this.state.emailToVerify);
    currentUser.save().then(
      (user) => {
        this.setState({
          resendingEmailVerification: false,
          resendStatus: "SUCCESS",
          needVerify: true,
          failedSignin: true,
        });
      },
      (error) => {
        this.setState({
          resendingEmailVerification: false,
          resendStatus: "ERROR",
        });
      }
    );
  }

  checkCapsLock(e) {
    // If "caps lock" is pressed, display the warning text
    let copslockStatus = this.state.copslock;
    if (e.getModifierState("CapsLock")) {
      copslockStatus = true;
    } else {
      copslockStatus = false;
    }
    this.setState({
      copslock: copslockStatus,
    });
  }

  render() {
    let switchText = "Login with phone number";
    if (!this.state.usingWeb) {
      switchText = "Login using Web Username";
    }

    let verifyMessage = "Please verify your email ";
    if (this.state.resendStatus === "SUCCESS") {
      verifyMessage = "A new verifcation mail has been sent to  ";
    }

    return (
      <div className="Login-Container">
        <div className="Form-Container">
          <img className="Logo" src={Logo} alt="Hubchart Logo" />
          <h1 className="hc-primary-text">HUBCHART</h1>
          {this.state.failedSignin && !this.state.needVerify && (
            <div className="card Error-Container">
              <span>{this.state.errorMessage}</span>
            </div>
          )}
          {/* {this.state.failedSignin && this.state.needVerify && <span style={{color: "rgb(74, 173, 82)", fontSize: "13px"}}>Please verify you email {this.state.emailToVerify}</span> } */}
          {this.state.failedSignin && this.state.needVerify && (
            <div className="card Verify-Container" style={{ height: "100%" }}>
              <span style={{ margin: "0 5px", fontSize: "14px" }}>
                {verifyMessage}{" "}
                <span style={{ fontWeight: "bold" }}>
                  {this.state.emailToVerify}
                </span>
                .
              </span>
              {this.state.resendStatus !== "SUCCESS" && (
                <>
                  <span style={{ margin: "0 5px", fontSize: "13px" }}>
                    <a
                      onClick={this.resentEmailVerification.bind(this)}
                      style={{ fontSize: "12px" }}
                      href="#!"
                    >
                      Resend email verification
                    </a>
                  </span>
                  <span style={{ margin: "0 5px", fontSize: "13px" }}>
                    <a style={{ fontSize: "12px" }} href="/email">
                      Wrong email address
                    </a>
                  </span>
                </>
              )}
            </div>
          )}
          {this.state.resendingEmailVerification && (
            <div style={{ textAlign: "right", color: "rgb(74, 173, 82)" }}>
              <span>
                <i className="fas fa-circle-notch fa-spin"></i> Sending email
                verification...
              </span>
            </div>
          )}
          {this.state.resendStatus === "ERROR" && (
            <div className="card Error-Container" style={{ height: "100%" }}>
              <span style={{ margin: "0 5px", fontSize: "15px" }}>
                We are having problem sending a new verification to this email{" "}
                <span style={{ fontWeight: "bold" }}>
                  {this.state.emailToVerify}
                </span>{" "}
                right now. Please try again later.
              </span>
            </div>
          )}
          <form
            onSubmit={this.handleSubmit.bind(this)}
            style={{ marginTop: "20px" }}
          >
            <div className="form-group">
              {this.state.usingWeb ? (
                <div className="card">
                  <input
                    ref="username"
                    type="username"
                    id="username"
                    placeholder="Username"
                    required
                  />
                  <i className="fas fa-user hc-primary-text"></i>
                </div>
              ) : (
                <div className="card" style={{ paddingLeft: "0px" }}>
                  <ReactPhoneInput
                    country={"us"}
                    defaultCountry={"us"}
                    enableLongNumbers={true}
                    onChange={this.handlePhoneInput}
                    value={this.state.phoneNumber}
                    inputStyle={{
                      width: "100%",
                      outline: "none",
                      boxShadow: "none",
                    }}
                  />
                </div>
              )}

              <span
                style={{
                  fontSize: "13px",
                  color: "#00000096",
                  fontWeight: "500",
                }}
              >
                {switchText}
                <a
                  href="#!"
                  onClick={this.switchToMobile.bind(this)}
                  className="mobile-login hc-primary-text"
                >
                  {" "}
                  Click here.
                </a>
              </span>
            </div>
            <div className="form-group">
              <div className="card">
                <input
                  onKeyDown={this.checkCapsLock}
                  onKeyUp={this.checkCapsLock}
                  ref="password"
                  type="password"
                  id="password"
                  placeholder="Password"
                  required
                />
                <i className="fas fa-lock hc-primary-text"></i>
              </div>
              {this.state.copslock && (
                <span
                  style={{
                    fontSize: "12px",
                    color: "#0000009e",
                    display: "block",
                  }}
                >
                  * Your copslock is enabled
                </span>
              )}
              <a
                href="/forgotpassword"
                style={{
                  fontSize: "13px",
                  color: "#00000096",
                  fontWeight: "500",
                  textDecoration: "underline",
                }}
              >
                Forgot your password?
              </a>
            </div>
            <div className="form-group" style={{ marginBottom: "30px" }}>
              <button
                ref="submit"
                type="submit"
                className="btn btn-hubchart"
                style={{ width: "100%", fontSize: "18px" }}
              >
                Sign in
              </button>
            </div>
          </form>
          <div
            style={{
              textAlign: "center",
              borderTop: "1px solid #00000029",
              paddingTop: "15px",
            }}
          >
            <a
              href="/signup"
              className="hc-primary-text"
              style={{
                fontSize: "15px",
                fontWeight: "500",
                textDecoration: "underline",
              }}
            >
              Create New Account
            </a>
          </div>

          <div style={{ textAlign: "center", marginTop: "45px" }}>
            <a
              href="https://itunes.apple.com/us/app/hubchart/id991641284?ls=1&mt=8"
              target="_black"
            >
              <img
                style={{ width: "100px", height: "33px", marginRight: "5px" }}
                src={require("../../../assets/images/apple.png")}
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=apps.synchronytelemed.hubchart&hl=en"
              target="_blank"
            >
              <img
                style={{ width: "100px", height: "33px", marginLeft: "5px" }}
                src={require("../../../assets/images/google.png")}
              />
            </a>
          </div>
        </div>
        {JSON.parse(localStorage.getItem("is_idled")) && (
          <div className="idle-container">
            <div className="idled">
              <span>
                You have been automatically logged out because you have been
                idle for more than 1 hour.
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  setActiveTab,
})(Login);
