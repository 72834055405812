import React, { useEffect } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import './index.css';

function MaedicalDecisionMakingComponent({
  medicalDecisionMaking = '',
  updateMedicalDecisionMaking = () => {},
  clearField = () => {},
  isDisabled = false,
}) {

  function handleOnchange (e) {
    e.preventDefault();
    updateMedicalDecisionMaking(e);
  }

  function handleClear (name) {
    clearField(name);
  }
  
  useEffect(() => {
    return () => {}
  }, []);

  return (
    <div className="card custom-card">
      <h5 className="card-header ">MEDICAL DECISION MAKING / DISCUSSION</h5>
      <div className="card-body" style={{ padding: '10px' }}>
        <div className="custom-form-group-container txt-clr" style={{ margin: '5px' }}>
          <TextareaAutosize 
            id="medicalDecisionMaking" 
            name="medicalDecisionMaking" 
            type="text" 
            className="form-control custom-textarea" 
            placeholder="Medical Decision Making / Discussion" 
            onChange={e => handleOnchange(e)}
            value={medicalDecisionMaking}
            disabled={isDisabled}
            minRows={2} />
          {!isDisabled && (
            <i 
              className="fal fa-times clear-textarea text-danger"
              onClick={() => handleClear('medicalDecisionMaking')} 
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MaedicalDecisionMakingComponent;
