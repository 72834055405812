import React from "react";

import "./index.css";

function CptCodeComponent({
  cptCode = "",
  encounterType = "",
  posCode = "",
  modifierCode = ""
}) {
  return (
    <div className="custom-form-group-container">
      <div
        className="custom-form-group"
        style={{ maxWidth: "200px", width: "100%" }}
      >
        <strong>Encounter Type</strong>
        <div>
          <span>{encounterType || "--"}</span>
        </div>
      </div>
      <div
        className="custom-form-group"
        style={{ maxWidth: "200px", width: "100%" }}
      >
        <strong htmlFor="cptCode">CPT Code</strong>
        <div>
          <span>{cptCode || "--"}</span>
        </div>
      </div>
      <div
        className="custom-form-group"
        style={{ maxWidth: "200px", width: "100%" }}
      >
        <strong>POS Code</strong>
        <div>
          <span>{posCode || "--"}</span>
        </div>
      </div>
      <div
        className="custom-form-group"
        style={{ maxWidth: "200px", width: "100%" }}
      >
        <strong htmlFor="cptCode">Modifier</strong>
        <div>
          <span>{modifierCode || "--"}</span>
        </div>
      </div>
    </div>
  );
}

export default CptCodeComponent;
