import React, { Component } from 'react'
import { connect } from 'react-redux'
import Parse from 'parse'
import AddMemberModal from '../../Conversation/Setting/AddMemberModal'
import CreateTeam from './CreateTeam'
import LoadingSpinner from '../../../components/Conversation/Bubble/LoadingSpinner'
import { fetchMyTeams, createNewTeam, updateTeamName, teamNewMembers, removeTeamMember } from '../../../actions/TeamAction'
import UserItem from './UserItem'
import './ProfileSetting.css'
import { parseTwoDigitYear } from 'moment'

const teamDefault = require('../../../assets/images/enterprise.png');
const $ = window.$;
export class TeamContainer extends Component {
    constructor() {
        super();
        this.state = {
            openTeam : false,
            teamToOpen : {},
            willEditName : false,
            failedToUpdateName : false,
            nameIsUpdating : false,
            openAddMemberModal : false,
            openCreateTeam : false
        }

        this.updateTeamName = this.updateTeamName.bind(this);
        this.openAddMemberModal = this.openAddMemberModal.bind(this);
        this.openCreateTeam = this.openCreateTeam.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.removeMember = this.removeMember.bind(this);
    }

    componentDidMount() {
        if (this.props.teams.length === 0 && this.props.teamFetchStatus === "") {
            this.props.fetchMyTeams(Parse.User.current().id);
        }
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        $('[data-toggle="tooltip"]').tooltip();

        return null;
    }

    componentDidUpdate() {

    }

    openAddMemberModal() {
        this.setState({
            openAddMemberModal : true
        })
    }

    closeModal() {
        this.setState({
            openAddMemberModal : false,
            openCreateTeam : false
        })
    }

    openCreateTeam() {
        this.setState({
            openCreateTeam : true
        })
    }




    addMember(toBeInvited) {
        this.closeModal();
        let newMembers = toBeInvited.map(data => {
            return {
                "__type": "Pointer",
                "className":"_User",
                "objectId": data.objectId
            }
        })
        this.props.teamNewMembers(newMembers, toBeInvited, this.state.teamToOpen.objectId).then(team => {
            let tempTeam = this.state.teamToOpen;
            if (tempTeam.members) {
                tempTeam = {
                    ...tempTeam,
                    members : [...toBeInvited, ...tempTeam.members]
                }
            } else {
                tempTeam = {
                    ...tempTeam,
                    members : [...toBeInvited]
                }
            }
            this.setState({
                teamToOpen : tempTeam
            })
        }).catch(error => {
            console.log(error);
        })
    }

    openTeam(team) {
        this.setState({
            openTeam : true,
            teamToOpen : team
        })
    }

    back() {
        this.setState({
            openTeam : false,
            teamToOpen : {}
        })
    }

    willEditName() {
        this.setState({
            willEditName : true,
            failedToUpdateName : false
        })
    }

    removeMember(objectId) {
        this.props.removeTeamMember(objectId, this.state.teamToOpen.objectId).then(_objID => {
            let tempTeam = this.state.teamToOpen;
            if (tempTeam.members) {
                tempTeam = {
                    ...tempTeam,
                    members : [...tempTeam.members.filter((member) => member.objectId !== _objID)]
                }
            }
            this.setState({
                teamToOpen : tempTeam
            })
        }).catch(error => {
            console.log(error)
        })
    }

    updateTeamName(e) {
        e.preventDefault();

        let teamName = this.refs.teamName.value;


        if (teamName === "" || this.state.teamToOpen.name === teamName) {
            this.setState({
                nameIsUpdating : false,
                willEditName : false,
                failedToUpdateName : false
            })
            return;
        }

        this.setState({
            nameIsUpdating : true,
        })

        let self = this

        this.props.updateTeamName(this.state.teamToOpen.objectId, teamName).then(() => {
            self.setState({
                nameIsUpdating: false,
                willEditName : false,
                failedToUpdateName : false,
                teamToOpen : {
                    ...self.state.teamToOpen,
                    name : teamName
                }
            })
        }).catch((error) => {
            self.setState({
                failedToUpdateName : true,
                nameIsUpdating : false,
                willEditName : false,
            })
        })
    }

    _renderLayout() {
        if (this.state.openTeam) {

            let memberIsEmpty = false;
            if (this.state.teamToOpen.members) {
                if (this.state.teamToOpen.members.length === 0) memberIsEmpty = true;
            } else {
                memberIsEmpty = true;
            }
            let Members;
            let containerStyle = {
                border: "0px solid white",
                paddingLeft: "60px",
                minHeight: "45px",
                height: "45px"

            };

            if (!memberIsEmpty) {
                Members = this.state.teamToOpen.members.map((user, i) => {
                    const member = {
                        "user" : user,
                    }
                    return <UserItem key={"member_"+i} isTeam={true} member={member} isCreator={false} isAdmin={true} removeMember={this.removeMember}/>
                })
            }




            return (
                <>

                    <a href="#!" style={{fontSize: "16px", margin: "0", padding: "0", position: "absolute" ,top: "20px"}} onClick={this.back.bind(this)}>
                        <i className="fas fa-chevron-left"></i>
                    </a>
                    <div style={{paddingLeft: "85px", height: "80px", position: "relative", display: "block", marginTop: "25px"}}>
                        <img style={{position: "absolute", left: "0", border: "3px solid white", width: "75px", height: "75px", borderRadius: "30px"}} src={teamDefault}/>
                        <div className="Form-Container" style={{margin: "0"}}>
                            <form onSubmit={this.updateTeamName} style={{position: "relative"}}>
                                <div className="form-group" style={{paddingRight: "50px", paddingTop: "15px"}}>
                                    {!this.state.willEditName ?
                                            <p className="Corporate-Name hc-primary-text">{this.state.teamToOpen.name} <i onClick={this.willEditName.bind(this)} className="fas fa-pencil-alt" style={{color: "rgb(74, 173, 82)", position: "absolute", right: "0", cursor: "pointer"}}></i></p>
                                        :
                                        <div className="card">
                                            <input ref="teamName" type="text" id="teamName" placeholder="Enter a team name" defaultValue={this.state.teamToOpen.name} disabled={this.state.nameIsUpdating}/>
                                            <i className="fas fa-city hc-primary-text"></i>
                                        </div>
                                    }
                                </div>
                                {this.state.willEditName &&
                                    <div className="form-group Check-Container" style={{marginBottom: "30px", textAlign: "right"}}>
                                        {   this.state.nameIsUpdating ?
                                            <i className="fas fa-circle-notch fa-spin" style={{color: "rgb(74, 173, 82)", position: "relative", left: "-11px", top: "7px"}}></i>
                                            :
                                            <button ref="submit" type="submit" className="Confirm-Button" style={{width: "100%", height: "100%", marginLeft: "5px"}}><i className="fas fa-check"></i></button>

                                        }
                                    </div>
                                }
                            </form>
                        </div>
                    </div>
                    <div className="Corporate-Content">
                        <h5 style={{fontSize: "0.9rem", color: "rgba(0, 0, 0, 0.59)"}}>MEMBERS</h5>
                        {memberIsEmpty &&
                            <>
                                <p style={{color: "#0000009e", fontSize: "14px", textAlign: "center", marginTop: "25px"}}>Your member is empty, start by adding your contact to your teams.</p>
                                <button onClick={this.openAddMemberModal} className="btn btn-hubchart" style={{width: "131px", fontSize: "16px", margin: "0 auto", display: "block"}}>Add Member</button>
                            </>
                        }
                        <div className="list-group" style={{marginTop: "20px"}}>
                            { Members }
                        </div>
                    </div>
                </>
            )
        } else if (this.props.teamFetchStatus === "SUCCESS") {
            let containerStyle = {
                border: "0px solid white",
                paddingLeft: "60px",
                minHeight: "45px",
                height: "45px"

            };
            const Teams = this.props.teams.map((team) => {
                return <a key={team.objectId} onClick={this.openTeam.bind(this, team)} href="#!;" className="list-group-item list-group-item-action flex-column align-items-start " style={containerStyle}>
                            <img src={teamDefault} alt="" className="profile-image rounded-circle" style={{width: "30px", height: "30px"}}/>
                            <p style={{paddingTop: "4px", fontSize: "16px"}} className="name">{team.name}</p>
                        </a>
            })
            return (
                <>
                    <span onClick={this.openCreateTeam.bind(this)} style={{color: "rgb(74, 173, 82)", cursor: "pointer", fontSize: ".9rem", float: "right", position: "absolute", top: "22px", right: "25px"}}><i className="fas fa-plus"></i> New team</span>

                    <div className="list-group modified-collection" style={{marginTop: "20px"}}>
                        { Teams }
                    </div>
                </>
            )
        } else if (this.props.teamFetchStatus === "FETCHING") {
            return (
                <>
                    <LoadingSpinner top={"0"}/>
                </>
            )
        }

    }

    render() {
        let myTeamStyle = this.state.openTeam ? {marginLeft: "25px"} : {};
        return (
            <div className="Account-Setting-Container" style={{minWidth: "450px"}} >
                <h5 className="hc-primary-text" style={myTeamStyle}>My Teams</h5>
                {this._renderLayout()}
                {this.state.openAddMemberModal && <AddMemberModal from="organization" members={this.state.teamToOpen.members || []} inviteMember={this.addMember.bind(this)} isOpen={this.state.openAddMemberModal} modalClose={this.closeModal.bind(this)}/> }
                {this.state.openCreateTeam && <CreateTeam createNewTeam={this.props.createNewTeam} createdATeam={this.openTeam.bind(this)} isOpen={this.state.openCreateTeam} modalClose={this.closeModal.bind(this)}/> }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    teamFetchStatus : state.team.teamFetchStatus,
    teams : state.team.teams
})
export default connect(mapStateToProps, { fetchMyTeams, createNewTeam, updateTeamName, teamNewMembers, removeTeamMember })(TeamContainer);
