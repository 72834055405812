import React, { Component, useMemo } from "react";
import sort from "fast-sort";
import PriorityItem from "./PriorityItem";
import _ from "lodash";
import LoadingSpinner from "../../../components/Conversation/Bubble/LoadingSpinner";
import { useDispatch, useSelector } from "react-redux";

function PriorityList(props) {
  const priorities = useSelector(state => state.priority.priorities);

  const containerStyle = {
    border: "0px solid white",
    minHeight: "50px",
    height: "50px",
    borderRadius: "0px",
    padding: "0"
  };

  const createGroup = priority => {
    return {
      ...priority,
      messages: []
    };
  };

  const createMessage = priority => {
    return {
      objectId: priority.objectId,
      text: priority.text,
      senderName: priority.senderName,
      senderImageUrl: priority.senderImageUrl,
      messageDate: priority.messageDate,
      isUrgent: priority.isUrgent,
      isAttention: priority.isAttention
    };
  };

  const groupPriorities = priorities => {
    const groups = [];
    let currentGroup = createGroup();

    priorities.forEach(function(p, index) {
      const previous = priorities[index - 1] || {};
      const current = p;

      currentGroup = createGroup(current);
      if (p.isUrgent) {
        groups.unshift(currentGroup);
      } else {
        groups.push(currentGroup);
      }

      /* 
                Otherwise, just add the message to the group
            */
      const newMessage = createMessage(current);

      currentGroup.messages.push(newMessage);
    });
    return groups;
  };

  const priorityGroups = useMemo(() => groupPriorities(priorities), [
    priorities
  ]);

  return (
    <div className="list-group modified-collection">
      {/* List of items */}
      {priorityGroups.map((data, i) => ( 
        <PriorityItem key={i} data={data} />
      ))}

      {/* Loading indicator */}
      {props.isLoadingMore && (
        <a
          key="group-load-more"
          style={containerStyle}
          className="list-group-item list-group-item-action flex-column align-items-start"
        >
          <LoadingSpinner top={"0"} />
        </a>
      )}
    </div>
  );
}

export default PriorityList;
