import React, { Component } from "react";
import Parse from "parse";
import "../../TabList/TabList.css";
import { withRouter } from "react-router-dom";
import {
  createConferenceCall,
  launchTelemed,
} from "../../../actions/ConferenceAction";
import Notify from "../../Common/Notify";
import ConferenceHelper from "../../../helper/conference";
import { connect } from "react-redux";
import Loader from "../../Common/Loader";

const $ = window.$;

export class Member extends Component {
  constructor() {
    super();

    this.state = {
      isLoading: false,
      loadingMessage: "",
    };

    this.handleCall = this.handleCall.bind(this);
    this.createConference = this.createConference.bind(this);
    // this.openCallWindow = this.openCallWindow.bind(this);
    this.setLoading = this.setLoading.bind(this);
  }

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  handleClick(e) {
    const { member } = this.props;

    e.preventDefault();

    this.props.history.push(`/u/${member.objectId}`);

    this.props.onClick(e);
  }

  handleCall(hasVideo) {
    const { member } = this.props;

    const partnerObjectId = member.objectId;

    this.createConference([partnerObjectId], hasVideo);
  }

  createConference(participantIds, hasVideo) {
    // Show loading indicator with message
    // this.setLoading(true, 'Starting conference...');

    return this.props.launchTelemed(participantIds);
  }

  setLoading(isLoading, loadingMessage) {
    this.setState({
      isLoading,
      loadingMessage,
    });
  }

  render() {
    const {
      member,
      isAdmin,
      isCreator,
      isOrgMember = false,
      isGuest,
    } = this.props;

    const { isLoading, loadingMessage } = this.state;

    const user = member;
    let containerStyle = {
      border: "0px solid white",
      paddingLeft: "60px",
      minHeight: "45px",
    };

    let Image,
      Name,
      nameStyle = {
        marginTop: "4px",
      };

    var contactURL;

    Name = user.firstName + " " + user.lastName;

    if (typeof user.picture === "undefined") {
      contactURL = require("../../../assets/images/default.png");
    } else {
      contactURL = user.picture.url;
    }

    if (member.isAdministrator) {
      nameStyle.marginTop = "0px";
    }

    let RemoveAdmin = (
      <li
        onClick={() => this.props.removeAdmin(member)}
        key={"remove-admin-" + member.objectId}
        className="dropdown-item"
      >
        <i className="fas fa-chevron-double-down mr-1"></i>
        Revoke Admin
      </li>
    );
    let AssignAdmin = (
      <li
        onClick={() => this.props.assignAdmin(member)}
        key={"assign-" + member.objectId}
        className="dropdown-item"
      >
        <i className="fas fa-chevron-double-up mr-1"></i>
        Assign Admin
      </li>
    );
    let RemoveMember = (
      <li
        onClick={() => this.props.removeMember(member)}
        key={"remove-" + member.objectId}
        className="dropdown-item"
      >
        <i className="fas fa-trash mr-1"></i>
        Remove from group
      </li>
    );

    let Options = [];
    let showDropdown = true;
    let showInfo = false;

    if (!isOrgMember) {
      if (isCreator) showDropdown = true;

      if (isAdmin) {
        if (!member.isAdministrator) {
          !isGuest && Options.push(AssignAdmin);
          Options.push(RemoveMember);
        } else {
          showDropdown = false;
          showInfo = true;
        }
      } else {
        showDropdown = false;
        showInfo = false;
      }

      if (isCreator) {
        Options = [];
        if (member.isAdministrator) {
          !isGuest && Options.push(RemoveAdmin);
        } else {
          !isGuest && Options.push(AssignAdmin);
        }

        Options.push(RemoveMember);
        showDropdown = true;
        showInfo = false;
      }
      if (member.objectId === Parse.User.current().id) {
        showDropdown = false;
      }
    } else {
      showDropdown = false;
    }

    return (
      <div className="border-item layout-item" style={{ minHeight: "62px" }}>
        <img
          src={contactURL}
          alt=""
          className="profile-image rounded-circle mr-2"
          style={{ width: "30px", height: "30px" }}
        />
        <p className="name m-0">{Name}</p>

        <div
          className="ml-auto"
          style={{ display: "flex", flexDirection: "row" }}
        >
          {user.objectId !== Parse.User.current().id && (
            <>
              <span className="mr-2">
                <button
                  onClick={(e) => this.handleClick(e)}
                  className="btn btn-light btn-circle"
                >
                  <i className="fa fa-comment hc-primary-text"></i>
                </button>
              </span>
              <span className="mr-2">
                <div className="dropdown">
                  <button
                    className="btn btn-light btn-circle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fas fa-phone hc-primary-text dropdown-toggle"></i>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    style={{ minWidth: "8rem" }}
                  >
                    <a
                      className="dropdown-item"
                      href="#!"
                      onClick={() => this.handleCall(false)}
                    >
                      <i className="fas fa-phone mr-1"></i>
                      Voice call
                    </a>
                    <a
                      className="dropdown-item"
                      href="#!"
                      onClick={() => this.handleCall(true)}
                    >
                      <i className="fas fa-video mr-1"></i>
                      Video call
                    </a>
                  </div>
                </div>
              </span>
            </>
          )}
          {showDropdown ? (
            <div className="dropdown">
              <button
                className="btn btn-primary btn-circle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fas fa-cog dropdown-toggle"></i>
              </button>
              <div
                className="dropdown-menu dropdown-menu-right"
                style={{ minWidth: "8rem" }}
              >
                {Options}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <Loader isLoading={isLoading} message={loadingMessage} />
      </div>
    );
  }
}

const _withRouter = withRouter(Member);

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  createConferenceCall,
  launchTelemed,
})(_withRouter);
