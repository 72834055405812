import React from 'react';

export function NoAnswer(props) {

    const userImage = require("../../../assets/images/default.png");
    const redialImage = require('../VideoCall/images/call_green.png');
    const closeImage = require('../VideoCall/images/close.png');
    const {callStatus, partnerName, partnerImageUrl} = props;

    return (
        <div className="no-answer-container" >
            <img className="profile-image large rounded-circle" src={partnerImageUrl || userImage}/>
            <h5>{partnerName}</h5>
            <p>{callStatus}</p>
            <div className="row">
                {/* <div className="col-6" align="center">
                    <a href="#!" >
                        <img width="50" height="50" src={redialImage}  />
                    </a>
                    <br/>
                    <span>Redial</span>
                </div> */}
                <div className="col-12" align="center">
                    <a onClick={() => window.close()} href="#!" >
                        <img width="50" height="50" src={closeImage} />
                    </a>
                    <br/>
                    <span>Close</span>
                </div>
            </div>
        </div>
    );
}