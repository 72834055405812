import React, { useState, Fragment } from "react";
import OverlayLoader from "../../Common/OverlayLoader";
import Loader from "../../Common/Loader";
import OverlayPlaceholder from "../../Common/OverlayPlaceholder";
import moment from "moment";
import config from "../../../config";
import { parseAction } from "../../../actions/REST";
import { useDispatch } from "react-redux";
import ActionsModal from "../../Conversation/Setting/ActionsModal";

const ToDosCard = ({ 
    recents, 
    isLoading, 
    openConfirmTask, 
    handleScroll, 
    handleToDosFilter, 
    hasMoreToDos, 
    handleOpenPrescription
    // handleSubToDosFilter 
  }) => {
  const [activeThread, setActiveThread] = useState(null);
  const [actionsModalOpened, setActionsModalOpened] = useState(false);
  const [selectedActionItem, setSelectedActionItem] = useState(null);
  const [isPatientLoading, setIsPatientLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState("ALL");
  const [subFilter, setSubFilter] = useState(2);

  const dispatch = useDispatch();

  const openActionItem = (data) => {
    setIsPatientLoading(true);
    parseAction("get", config.BASE_URL + "/parse/classes/Circle", {
      where: {
        objectId: data.threadId,
      },
    })
      .then((result) => {
        setIsPatientLoading(false);
        if (result.results.length === 0) {
          this.setState({
            isFetching: false,
          });
          return;
        }
        var group = result.results[0];

        setActiveThread({
          threadId: data.threadId,
          groupName: group.name,
          groupType: group.groupType,
        });
        setSelectedActionItem(data);
        setActionsModalOpened(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const styles = {
    maxHeight: "calc(100vh - 100px)",
    borderRadius: "15px",
    overflow: "hidden",
  };

  const itemCls = {
    position: "relative",
    display: "flex",
    flexWrap: "nowrap !important",
    flexDirection: "row !important",
  };

  const handlePriorityLevel = (level) => {
    return level === 2
      ? "#04589e"
      : level === 1
      ? "#f6c23e"
      : level === 0
      ? "#2194f3"
      : "#e5e5e5";
  };

  const filterToDos = (status, priority) => {
    console.log('status:', status);
    console.log('priority:', priority);
    handleToDosFilter(status, priority);
    setCurrentTab(status);
    setSubFilter(priority);
    var container = document.getElementById('ToDos-list');
    container.scrollTop = 0;
  }

  return (
    <div className="dashboard-box" id="ToDosCard" style={{ padding: 0 }}>
      <div style={{ padding: "10px 10px 0px 10px", fontSize: 14 }}>
        <h6 className="sidebar-heading d-flex justify-content-between align-items-center ">
          <strong>
            <span>My Tasks</span>
          </strong>
          <button
            type="button"
            className="btn btn-sm btn-primary"
            style={{ borderRadius: 10 }}
            onClick={() => handleOpenPrescription()}
            title="Create Prescription"
          >
            <i class="fa-fw fad fa-prescription"></i>
          </button>
        </h6>
      </div>
      <ul className="nav nav-bac">
        <li className="nav-item">
          <a
            onClick={() => {if (currentTab != "ALL") {
              filterToDos("ALL", subFilter)
            }}}
            className={
              currentTab === "ALL" ? "hc-nav-link-active" : "hc-nav-link"
            }
            href="#!"
          >
            All
          </a>
        </li>
        <li className="nav-item">
          <a
            onClick={() => {if (currentTab != "PENDING") {
              filterToDos("PENDING", subFilter)
            }}}
            className={
              currentTab === "PENDING" ? "hc-nav-link-active" : "hc-nav-link"
            }
            href="#!"
          >
            Pending
          </a>
        </li>
        <li className="nav-item">
          <a
            onClick={() => {if (currentTab != "DONE") {
              filterToDos("DONE", subFilter)
            }}}
            className={
              currentTab === "DONE" ? "hc-nav-link-active" : "hc-nav-link"
            }
            href="#!"
          >
            Done
          </a>
        </li>
      </ul>
      <div
        className="btn-list-tasks"
        style={{
          marginLeft: "5px", 
          marginRight: "5px",
          display: "flex",
          flexDirection: "row",
          flexWrap: 'wrap',
          marginBottom: "10px",
          marginTop: "10px",
        }}
      >
        <button
          className={`btn btn-sm m-1 ${
            subFilter === 2
              ? "btn-warning" 
              : "btn-secondary"
          }`}
          style={{ marginRight: 5 }}
          onClick={() => filterToDos(currentTab, 2)}
        >
          High
        </button>
        <button
          className={`btn btn-sm m-1 ${
            subFilter === 1
              ? "btn-warning"
              : "btn-secondary"
          }`}
          style={{ marginRight: 5 }}
          onClick={() => filterToDos(currentTab, 1)}
        >
          Medium
        </button>
        <button
          className={`btn btn-sm m-1 ${
            subFilter === 0
              ? "btn-warning" 
              : "btn-secondary"
          }`}
          style={{ marginRight: 5 }}
          onClick={() => filterToDos(currentTab, 0)}
        >
          Routine
        </button>
        <button
          className={`btn btn-sm m-1 ${
            subFilter === ""
              ? "btn-warning" 
              : "btn-secondary"
          }`}
          style={{ marginRight: 5 }}
          onClick={() => filterToDos(currentTab, "")}
        >
          All
        </button>
      </div>
      <div
        className="h-100 w-100 position-relative overflow-auto"
        style={{ backgroundColor: "#FFFFFF", borderRadius: 8 }}
        onScroll={handleScroll}
        id="ToDos-list"
      >
        <div className="list-group modified-collection list-group-flush">
          {recents.map((r, index) => (
            <Fragment key={r.objectId + index}>
              <div
                style={itemCls}
                className="px-2 py-2 border-0 shadow-none mx-0 list-group-item-action align-items-start"
                onClick={() => {
                  openActionItem(r);
                }}
              >
                <div
                  style={{
                    borderLeft: `solid 3px ${handlePriorityLevel(
                      r.priorityLevel
                    )}`,
                    paddingLeft: "10px",
                    fontSize: 14,
                  }}
                >
                  <h6 style={{ fontWeight: "bold" }}>
                    {r.threadName || "No Name"}
                  </h6>
                  <div>
                    <span className="hc-primary-text">Assigned By: </span>
                    <span>{(r.createdBy || {}).displayName || "--"}</span>
                  </div>
                  {/* <div>
                    <span className="hc-primary-text">Status: </span>
                    <span>{r.status || "--"}</span>
                  </div> */}
                  <div>
                    <div
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      <span className="hc-primary-text">Task: </span>
                      {r.text}
                    </div>
                  </div>
                </div>
                <span
                  style={{
                    position: "absolute",
                    right: "-15px",
                    top: "8px",
                    fontSize: "13px",
                  }}
                >
                  {moment(r.createdAt).format("l")}
                </span>
                {r.status == "DONE" ? 
                  <button
                    className="btn btn-sm btn-outline-primary pre-check">
                    <i className="fad fa-check"></i>
                  </button> 
                : <button
                    className="btn btn-sm btn-outline-primary check-task"
                    id="confirm-task"
                    name="confirm-task"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      openConfirmTask(r.objectId);
                    }}
                    title="Mark as Done"
                  >
                    <i className="fad fa-check"></i>
                  </button>
                }
              </div>
              <div style={{ border: "1px solid #e5e5e5", margin: 10 }} />
            </Fragment>
          ))}
          <div style={itemCls} 
            className={hasMoreToDos ? "px-2 py-2 mx-0 list-group-item-action" : "d-none"}>
            <OverlayLoader isLoading={isLoading} />
          </div>
          
        </div>
        <OverlayPlaceholder
          show={!isLoading && !recents.length}
          text="No to-dos"
        />
        <OverlayLoader isLoading={isLoading} />
        {isLoading && <OverlayLoader isLoading={recents.length === 0} />}
        {actionsModalOpened && (
          <ActionsModal
            activeTab={""}
            thread={activeThread}
            threadId={activeThread.threadId}
            isOpen={actionsModalOpened}
            onClose={() => setActionsModalOpened(false)}
            refreshData={() => {}}
            selectedActionItem={selectedActionItem}
          />
        )}
        <Loader isLoading={isPatientLoading} />
      </div>
    </div>
  );
};

export default ToDosCard;
