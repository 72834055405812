import React, { Component } from "react";
import LoadingSpinner from '../../../components/Conversation/Bubble/LoadingSpinner'
import ContactItem from "../ConferenceTab/ContactItem";
import _ from 'lodash'
import Parse from 'parse';
import Loader from "../../Common/Loader";
import { shallowEqual, useSelector } from 'react-redux';

function ContactSelector(props) {

    const isLoading = useSelector(state => state.contact.isLoading);

    let { typeValue, contacts, isSearchingContact, searchResults, otherUsersResult, isLoadingSearch } = props;

    let containerStyle = {
        border: "0px solid white",
        minHeight: "50px",
        height: "50px",
        borderRadius: "0px",
        padding: "0"
    };

    let tempContacts;

    if (isSearchingContact) {
        tempContacts = [...searchResults]
    } else {
        tempContacts = [...contacts];
    }

    // Filter selected contacts
    // tempContacts = tempContacts.filter(c => !c.isSelected);
    tempContacts = _.uniqBy(tempContacts, 'objectId');

    let Items = tempContacts
    .filter((data) => {
        if (!isSearchingContact && !data.contact) {
            return false;
        } else {
            return true;
        }
    })
    .map((data, i) => {
        let contactData = {};
        if (isSearchingContact) {
            contactData.contact = data;
            contactData.objectId = data.objectId;
        } else {
            contactData = data;
        }

        return <ContactItem onSelect={(data) => props.onSelect(data)} key={data.objectId} data={contactData} type="contact" createThread={props.createThread} history={props.history} />
    });

    /* let Items = tempContacts.map((data, i) => {
        let contactData = {};
        if (isSearchingContact) {
            contactData.contact = data;
            contactData.objectId = data.objectId;
        } else {
            contactData = data;
        }

        return <ContactItem onSelect={(data) => props.onSelect(data)} key={data.objectId} data={contactData} type="contact" createThread={props.createThread} history={props.history} />
    }); */

    if (isLoadingSearch) {
        Items = [];
    }

    let _OtherUsers = [];

    if (isSearchingContact) {
        _OtherUsers = _.differenceBy(otherUsersResult, tempContacts, 'objectId');
    }

    // Filter out currently logged in user from Others
    const currentUserId = Parse.User.current().id

    return (
        <div className="row mb-3" style={{height: '450px', overflowY: 'auto'}}>
            <div className="col-md-12">
                <div className="list-group">
                    {isLoadingSearch ?
                        <a key="group-load-more" style={containerStyle} className="list-group-item list-group-item-action flex-column align-items-start">
                            <LoadingSpinner top={"0"} />
                        </a>
                        : ''
                    }
                    {!isLoadingSearch ?
                        <div>
                            {Items}
                            {isSearchingContact && !Items.length ?
                                <div className="text-empty">No results</div>
                                : ''
                            }
                            {props.isLoadingMoreContacts && <a key="group-load-more" style={containerStyle} className="list-group-item list-group-item-action flex-column align-items-start">
                                <LoadingSpinner top={"0"} />
                            </a>}
                        </div>
                        : ''
                    }
                </div>
            </div>
        </div>
    );
}

export default ContactSelector;
