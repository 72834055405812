import React, { Component } from 'react'
import { parseAction } from '../../../actions/REST'
import '../../Pages/Login/Login.css'
import Loading from '../../Pages/Signup/Loading'
import config from '../../../config'

const $ = window.$;
export class CreateTeam extends Component {

    constructor() {
        super();
        this.state = {
            isCreatingTeam : false,
            createTeamState : "none"
        }
        this.createTeam = this.createTeam.bind(this);
    }

    componentDidMount() {
        if (this.props.isOpen) {
            $("#createTeamModal").modal({
                keyboard : false
            });
            $("#createTeamModal").modal('show');
        } else {
            $("#createTeamModal").modal('hide');
        }
        $("#createTeamModal").on('hidden.bs.modal', function (e) {
            this.props.modalClose();
        }.bind(this))
    }

    UUID() {
        let d = new Date().getTime();
        let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            let r = (d + Math.random()*16)%16 | 0;
            d = Math.floor(d/16);
            return (c ==='x' ? r : (r&0x3|0x8)).toString(16).toUpperCase();
        });
        return uuid;
    }

    createTeam(e) {
        e.preventDefault();
        let teamName = this.refs.teamName.value;

        this.setState({
            isCreatingTeam : true,
            createTeamState : "none"
        })

        let self = this;

        this.props.createNewTeam(teamName).then(team => {
            this.props.createdATeam(team);
            $("#createTeamModal").modal('hide');

        }).catch(error => {
            self.setState({
                isCreatingTeam : false,
                createTeamState : "ERROR"
            })
        })
    }

    render() {
        return (
            <div id="createTeamModal" className="modal fade" tabIndex="-1" role="dialog" data-backdrop="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-body" style={{paddingBottom: "0px"}}>
                        <h5 className="hc-primary-text" style={{fontSize: "1.25rem"}}>Create Team</h5>
                        {/* <p style={{fontSize: "0.9em"}}>Message here</p> */}
                        <div className="Form-Container" style={{margin: "20px 0", maxWidth: "100%"}}>
                            <form onSubmit={this.createTeam}>
                                <div className="form-group">
                                    <div className="card">
                                        <input ref="teamName" type="text" id="team" placeholder="Team Name" required/>
                                        <i className="fas fa-users hc-primary-text"></i>
                                    </div>
                                </div>
                                { !this.state.isCreatingTeam ?
                                    <div className="form-group" style={{textAlign: "right"}}>
                                        <button type="submit" style={{marginRight: "15px"}} className="btn btn-hubchart btn-hubchart-primary">Create</button>
                                        <button type="button" className="btn btn-hubchart btn-hubchart-close" data-dismiss="modal">Close</button>
                                    </div>
                                    :
                                    <div className="form-group" style={{textAlign: "right", position: "relative", height: "40px"}}>
                                        <Loading />
                                    </div>
                                }
                            </form>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CreateTeam
