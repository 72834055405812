import React, { Component } from "react";
import { parseAction } from "../../../actions/REST";
import "../../Pages/Login/Login.css";
import Loader from "../../Common/Loader";
import Parse from "parse";
import {
  assignAdmin,
  removeAdmin,
  removeMember,
  inviteMember,
} from "../../../actions/GroupAction";
import { addGuestsToCircle } from "../../../actions/CircleAction";
import { connect } from "react-redux";
import Notify from "../../Common/Notify";
import _ from "lodash";
import MemberList from "../../Conversation/Setting/MemberList";
import config from "../../../config";
import AddMember from "./AddMember";

const $ = window.$;

export class MemberListModal extends Component {
  constructor() {
    super();
    this.state = {
      owner: {},
      admins: [],
      members: [],
      guests: [],

      isLoading: false,
      loadingMessage: "",
      isCreator: false,
      isAdmin: false,
      group: {},
      currentTab: "list",
    };

    this.inviteMember = this.inviteMember.bind(this);
  }

  componentDidMount() {
    if (this.props.isOpen) {
      $("#memberListModal").modal({
        backdrop: "static",
        keyboard: false,
      });

      $("#memberListModal").modal("show");

      this.fetchData();
    } else {
      $("#memberListModal").modal("hide");
    }
    $("#memberListModal").on(
      "hidden.bs.modal",
      function(e) {
        this.props.onClose();
      }.bind(this)
    );
  }

  setLoading(isLoading, loadingMessage) {
    this.setState({ isLoading, loadingMessage });
  }

  fetchData() {

    const promise = Promise.all([
      this.fetchAllMembers()
    ]);

    promise.then((result) => {
      let members = result[0].organization[0] || result[0].organization;

      this.setMembers(members, members);
      // this.setGroup(members);
    });
  }

  setMembers(result, group) {
    const { guests = [], members = [], administrators = [] } = result;
    
    const userId = Parse.User.current().id;

    const admins = this.excludeOwner(administrators, userId, 'Administrators');
    const member = this.excludeOwner(members, userId, 'Member');
    const guest = this.excludeOwner(guests, userId, 'Guest');

    if (group.includedInOwnersOrganization) {

      this.setState({
        admins: admins,
        members: member,
        guests: guest,
      });
    } else {

      this.setState({
        admins: admins,
        members: member,
        guests: guest,
      });
    }
  }

  fetchAllMembers() {

    const method = "post";
    const url = config.BASE_URL + "/parse/functions/getOrganizationV2";
    const data = { };

    this.setLoading(true);

    return parseAction(method, url, data)
      .then((response) => {
        this.setLoading(false);
        return response.result;
      })
      .catch((error) => {
        this.setLoading(false);

        Notify.error("Could not fetch members.");

        return Promise.reject(error);
      });
  }

  excludeOwner(list, owner, type) {
    list.map((data) => {
      data.type = type;
    })
    return list.filter((user) => user.objectId != owner);
  }

  inviteMember(toBeInvited) {
    this.props.createGroup(toBeInvited);
  }

  handleClick(e) {
    this.props.onClose();
  }

  render() {
    const {
      isLoading,
      loadingMessage,
      currentTab,
    } = this.state;

    return (
      <div id="memberListModal" className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content modal-full-height">
            <div className="modal-header">
              <h5
                className="modal-title hc-primary-text"
                style={{ fontSize: "1.25rem" }}
              >
                Members
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.props.hideMemberList()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body pb-0">
              <ul className="nav nav-pills nav-fill">
                <li className="nav-item">
                  <a
                    onClick={() => this.selectTab("list")}
                    className={
                      currentTab === "list"
                        ? "hc-nav-link active"
                        : "hc-nav-link"
                    }
                    href="#"
                  >
                    <i className="fa fa-list mr-2"></i>
                    Member List
                  </a>
                </li>
              </ul>
            </div>

            <div
              className="modal-body pt-0"
              style={{ height: "100%", overflowY: "auto" }}
            >
              <AddMember 
                members={this.state.members} 
                admins={this.state.admins}
                guests={this.state.guests}
                inviteMember={this.inviteMember} />
            </div>
            <div className="modal-footer rm-border" />
          </div>
        </div>

        <Loader isLoading={isLoading} message={loadingMessage} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // Access values from store
});

export default connect(mapStateToProps, {
  assignAdmin,
  removeAdmin,
  removeMember,
  inviteMember,
  addGuestsToCircle,
})(MemberListModal);
