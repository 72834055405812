import React, { useState } from "react";

const $ = window.$;

function AttentionNotify({
  continueWithoutAssignment = () => {},
  assignMember = () => {},
  closeNotification = () => {}
}) {
  return (
    <div className="assignment-notify-container">
      <div className="attention-notify-box">
        <i className="fas fa-exclamation-circle hc-primary-text fa-2x"></i>
        <div className="save-changes-flex" style={{ marginLeft: 5 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <h5>No Member Assigned</h5>
            <i className="fas fa-times" onClick={() => closeNotification()} />
          </div>
          <span style={{ fontSize: "12px" }}>
            You’re sending this message w/o notification.
          </span>
          <div style={{ textAlign: "right", marginTop: 10 }}>
            <button
              type="button"
              style={{ marginRight: "10px" }}
              className="btn btn-hubchart btn-hubchart-primary"
              onClick={continueWithoutAssignment}
            >
              Proceed
            </button>
            <button
              type="button"
              className="btn btn-outline-warning"
              onClick={() => assignMember()}
            >
              Assign <i className="fas fa-fw fa-at" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AttentionNotify;
