import React, { useEffect, useState } from 'react';
import PatientSelect from './PatientSelect';
import Modal from '../../../Common/Modal';

const $ = window.$;

function PatientModal({ 
  isOpen, 
  onClose, 
  onSelectPatient, 
  isEmr = false,
  isCreatePatientOpen,
  newGroup,
  onClosePatientModal,
  onOpenPatientModal
}) {

  const handleSelect = circleId => {
    onSelectPatient(circleId);
  };

  return (<>
    <Modal shown={isOpen} onHide={onClose}>
      <div className="modal-header">
        <h5 className="modal-title hc-primary-text d-flex align-items-center">
          {!isEmr && <i className="fad fa-prescription fa-2x mr-1"></i>}
          {isEmr && <i className="fad fa-file-signature fa-2x mr-1"></i>}
          <span className="font-weight-bold">
            Search a patient
          </span>
        </h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body py-0" style={{ height: '100%', marginBottom: '10px' }}>
        <PatientSelect 
          onSelect={(circleId) => handleSelect(circleId)} 
          onClose={onClose} 
          onOpenPatientModal={onOpenPatientModal}
        />
      </div>
      <div className="modal-footer rm-border" />
    </Modal>
  </>);
}

export default PatientModal;
