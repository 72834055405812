import React, { Component, Fragment } from "react";
import { parseAction } from "../../../actions/REST";
import Loading from "../../Pages/Signup/Loading";
import moment from "moment";
import config from "../../../config";
import { toast, ToastContainer } from "react-toastify";
import InputMask from "react-input-mask";

const $ = window.$;
export class CreatePatient extends Component {
  constructor() {
    super();
    this.state = {
      isCreatingGroup: false,
      createGroupState: "none",
      allowCorporateAccess: true,
      is_valid_date: true,
    };

    this.createPatient = this.createPatient.bind(this);
    this.handleErrorNotification = this.handleErrorNotification.bind(this);
    this.showPatientInfo = this.showPatientInfo.bind(this);
  }

  componentDidMount() {
    var dis = this;
    $("#date-container input")
      .datepicker({
        forceParse: false,
        endDate: "+0d",
        maxDate: "now",
      })
      .on("changeDate", function(ev) {
        dis.setState({ dob: moment(ev.date).format("MM/DD/YYYY") });
      });
    if (this.props.isOpen) {
      $("#createPatientModal").modal({
        backdrop: "static",
        keyboard: false,
      });
      $("#createPatientModal").modal("show");
    } else {
      $("#createPatientModal").modal("hide");
    }
    $("#createPatientModal").on(
      "hidden.bs.modal",
      function(e) {
        this.props.modalClose();
      }.bind(this)
    );
  }

  confirm() {
    this.props.confirmAction();
    $("#createPatientModal").modal("hide");
  }

  UUID() {
    let d = new Date().getTime();
    let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(
      c
    ) {
      let r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16).toUpperCase();
    });
    return uuid;
  }

  createPatient(e) {
    e.preventDefault();

    // let dob = this.refs.date.value,
    let uuid = this.UUID();

    const {
      firstName,
      lastName,
      dob,
      pending,
      allowCorporateAccess,
      is_valid_date = true,
    } = this.state;

    // if (!moment(dob).isValid()) {
    //     this.handleErrorNotification();
    //     return;
    // }

    if (
      new Date(dob) > new Date() ||
      new Date(dob) < new Date("01-01-1901") ||
      !is_valid_date
    ) {
      this.handleErrorNotification("Date of birth not valid.");
      return;
    }

    this.setState({
      isCreatingGroup: true,
      createGroupState: "none",
    });

    let self = this;

    const payload = {
      firstName: firstName.toUpperCase(),
      lastName: lastName.toUpperCase(),
      dob,
      pending,
      uuid,
      addToOrganization: allowCorporateAccess,
    };

    const headers = {
      "Content-Type": "application/json",
    };

    parseAction(
      "post",
      config.BASE_URL + "/parse/functions/createPatientGroup",
      payload,
      headers
    )
      .then((res) => {
        let patient = res.result.circle;
        patient.ccmProgram = res.result.ccmProgram;
        self.props.newGroup(patient);
        this.showPatientInfo(
          {
            editMode: true,
          },
          res.result.circle.objectId
        );
        $("#createPatientModal").modal("hide");
        $("#allPatientCollapse").collapse("show");
      })
      .catch((error) => {
        self.setState({
          isCreatingGroup: false,
          createGroupState: "ERROR",
        });
      });
  }

  showPatientInfo(data, threadId) {
    if (data.editMode) {
      localStorage.setItem("fsState", JSON.stringify(data));
    }
    const filesUrl = `${window.location.origin}/facesheet/${threadId}`;
    window.open(filesUrl, "Hubchart Facesheet");
  }

  handleChangeCorporate(e) {
    this.setState({
      allowCorporateAccess: e.target.checked,
    });
  }

  handleChange(e) {
    const { name, value = "" } = e.target;

    const valueList = value.split(" ");
    const upperCased = valueList.map((str) => {
      if (str.length) {
        const new_str = str.replace(/[^\w\s]/gi, "");
        return new_str.replace(/^./, new_str.charAt(0).toUpperCase());
      }

      return str;
    });

    this.setState({
      [name]: upperCased.join(" "),
    });
  }

  handleDateChange(e) {
    const { value = "" } = e.target;
    function isValidDate(d) {
      const date = new Date(d);
      return date instanceof Date && !isNaN(date);
    }
    const isValid = isValidDate(value);
    this.setState({
      dob: value,
      is_valid_date: isValid,
    });
  }

  handleCheckboxChange(e) {
    const { name, checked = false } = e.target;
    this.setState({
      [name]: checked,
    });
  }

  handleErrorNotification(msg = "Date of birth format is invalid.") {
    const content = (
      <span>
        <i className="fas fa-exclamation-circle mr-1"></i>
        {msg}
      </span>
    );
    toast.error(content, {
      position: "top-right",
      className: "toast-base-main",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
      pauseOnHover: true,
    });
  }

  render() {
    const {
      firstName,
      lastName,
      dob,
      pending = false,
      allowCorporateAccess,
    } = this.state;

    return (
      <Fragment>
        <div
          id="createPatientModal"
          className="modal"
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body" style={{ paddingBottom: "0px" }}>
                <h5 className="hc-primary-text" style={{ fontSize: "1.25rem" }}>
                  Create Patient
                </h5>
                {this.state.createGroupState === "ERROR" && (
                  <p style={{ fontSize: "0.9em" }} className="hc-red-text">
                    Failed creating new patient, please try again later.
                  </p>
                )}
                <div
                  className="Form-Container form-bypass"
                  style={{ margin: "20px 0", maxWidth: "100%" }}
                >
                  <form onSubmit={this.createPatient}>
                    <label for="lastName" className="label-cls">
                      Last Name:
                    </label>
                    <div className="form-group">
                      <div className="card">
                        <input
                          id="lastName"
                          name="lastName"
                          value={lastName}
                          onChange={(e) => this.handleChange(e)}
                          type="text"
                          placeholder="Last Name"
                          style={{textTransform:'uppercase'}}
                          required
                        />
                        <i className="fas fa-user hc-primary-text"></i>
                      </div>
                    </div>
                    <label for="firstName" className="label-cls">
                      First Name:
                    </label>
                    <div className="form-group">
                      <div className="card">
                        <input
                          name="firstName"
                          value={firstName}
                          onChange={(e) => this.handleChange(e)}
                          type="text"
                          placeholder="First Name"
                          style={{textTransform:'uppercase'}}
                          required
                        />
                        <i className="fas fa-user hc-primary-text"></i>
                      </div>
                    </div>
                    <label className="label-cls">Date of Birth:</label>
                    <div className="form-group">
                      <div className="card">
                        <div
                          className="input-group"
                          id="date-container"
                          style={{ boxShadow: "none" }}
                        >
                          <InputMask
                            mask="99/99/9999"
                            maskChar="-"
                            value={dob}
                            onChange={(e) => this.handleDateChange(e)}
                            onPaste={(e) => this.handleDateChange(e)}
                            placeholder="mm/dd/yyyy"
                          />
                          <div className="input-group-addon">
                            <span className="glyphicon glyphicon-th"></span>
                          </div>
                        </div>
                        <i className="fas fa-calendar-alt hc-primary-text"></i>
                      </div>
                      <div className="is-pending">
                        <span>Mark as Pending</span>
                        <input
                          name="pending"
                          checked={pending}
                          onChange={(e) => this.handleCheckboxChange(e)}
                          type="checkbox"
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="checkbox ml-1">
                        <label>
                          <input
                            type="checkbox"
                            checked={allowCorporateAccess}
                            className="mr-1"
                            onChange={(e) => this.handleChangeCorporate(e)}
                          />
                          <span className="hc-primary-text">
                            Allow corporate access
                          </span>
                        </label>
                      </div>
                    </div>

                    {!this.state.isCreatingGroup ? (
                      <div
                        className="form-group"
                        style={{ textAlign: "right" }}
                      >
                        <button
                          type="submit"
                          style={{ marginRight: "10px" }}
                          className="btn btn-hubchart btn-hubchart-primary"
                        >
                          Create
                        </button>
                        <button
                          type="button"
                          className="btn btn-hubchart btn-hubchart-close"
                          data-dismiss="modal"
                        >
                          Later
                        </button>
                      </div>
                    ) : (
                      <div
                        className="form-group"
                        style={{
                          textAlign: "right",
                          position: "relative",
                          height: "40px",
                        }}
                      >
                        <Loading />
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </Fragment>
    );
  }
}

export default CreatePatient;
