
import { HAS_A_CALLER, END_CALL } from './types';
import { parseAction } from './REST'
import Parse from 'parse'
import config from '../config';

export const incomingCall = (data) => dispatch => {
    dispatch({
        type : HAS_A_CALLER,
        incomingCallData : data
    })
}

export const declineCall = (uuid, deviceId) => dispatch => {
    parseAction("post", `${config.BASE_URL}/parse/functions/declineCall`, {
        uuid : uuid,
        device_id : deviceId
    }).then((res) => {
        dispatch({
            type : END_CALL,
        })
    }).catch((error) => {
        console.log(error);
        // console.log("Error on receiveCall");
    })
}

export const receiveCall = (uuid, deviceId) => dispatch => {
    parseAction("post", config.BASE_URL + "/parse/functions/call_received", {
        uuid : uuid,
        device_id : deviceId
    }).then((res) => {
    }).catch((error) => {
        console.log("Error on receiveCall");
    })
}

export const answerCall = (uuid, deviceId) => dispatch => {
    parseAction("post", config.BASE_URL + "/parse/functions/answerCall", {
        uuid : uuid,
        device_id : deviceId
    }).then((res) => {
    }).catch((error) => {
        console.log("Error on answerCall");
    })
}

export const cancelCall = (uuid, deviceId) => dispatch => {
    parseAction("post", config.BASE_URL + "/parse/functions/cancelCall", {
        uuid : uuid,
        device_id : deviceId
    }).then(res => {
    }).catch((error) => {
        console.log(error);
    })
}

export const endCall = (uuid, deviceId) => dispatch => {
    parseAction("post", config.BASE_URL + "/parse/functions/endCall", {
        uuid : uuid,
        device_id : deviceId
    }).then(res => {
    }).catch((error) => {
        console.log("Error on answerCall");
    })
}

export const requestCallToken = (destination, room) => dispatch => {
    return new Promise((resolve, reject) => {
        parseAction("post", config.BASE_URL + "/parse/functions/requestCallToken", {
            destination : destination,
            room : room
        }).then(res => {
            resolve(res.result)
        }).catch(error => {
            reject(new Error(error));
        });
    });
}

export const initiateCall = (destination, uuid, hasVideo) => dispatch => {
    return new Promise((resolve, reject) => {
        parseAction("post", config.BASE_URL + "/parse/functions/initiateCall", {
            destination : destination,
            uuid : uuid,
            has_video : hasVideo
        }).then(res => {
            resolve(res.result)
        }).catch(error => {
            reject(new Error(error));
        })
    })
}
