import React, { Component, Fragment } from "react";
import Parse from "parse";
import moment from "moment";
import { connect } from "react-redux";
import { parseAction } from "../../actions/REST";
import MessageApi from "../../api/Message";
import config from "../../config";
import _ from "lodash";
import util from "../../helper/util";
import Notify from "../Common/Notify";
import { getConferenceCallsById } from "../../actions/ConferenceAction";
import { getProgressNotes } from "../../actions/ProgressNotesAction";
import { fetchSelectedThread } from "../../actions/ThreadAction";
import {
  //getPatientOrdersHistory,
  //getPrescriptionsHistory,
  getGroupedLists,
  fetchPatientInfo,
} from "../../actions/PatientAction";
import { getConferenceText, removeAllExtraLines } from "../../helper/util";

const $ = window.$;

export class SideTab extends Component {
  constructor() {
    super();
    this.state = {
      rxModalShown: false,
      problemModalShown: false,
      showMoreMedications: false,
      showMoreProblems: false,
      showMoreProgressNotes: false,
    };

    this.showHideRxModal = this.showHideRxModal.bind(this);
    this.showHideListModal = this.showHideListModal.bind(this);
    this.openProgressNotes = this.openProgressNotes.bind(this);
    this.handleRichTextData = this.handleRichTextData.bind(this);
    this.handleShowMoreData = this.handleShowMoreData.bind(this);
    this.handleMedsLength = this.handleMedsLength.bind(this);
    this.handleRichTextDataLength = this.handleRichTextDataLength.bind(this);
    this.openProgressNote = this.openProgressNote.bind(this);
    //this.openPrescriptionHistory = this.openPrescriptionHistory.bind(this);
    this.copyPrescriptionLink = this.copyPrescriptionLink.bind(this);
    this.handleMedsDisplay = this.handleMedsDisplay.bind(this);
    this.displayRichTextinModal = this.displayRichTextinModal.bind(this);
  }

  showHideRxModal(bool) {
    this.setState({ rxModalShown: bool });
  }

  showHideListModal(bool) {
    this.setState({ listModalShown: bool });
  }

  handleShowMoreData(stateKey, currentValue) {
    this.setState({
      showMoreMedications: false,
      showMoreProblems: false,
      showMoreProgressNotes: false,
      [stateKey]: !currentValue,
    });
  }

  openProgressNotes() {
    const { activeThread } = this.props;
    const url = [
      window.location.origin,
      "/progressnotes",
      "/" + activeThread.threadId,
      ,
    ].join("");
    // window.open(url, "test");

    window.open(url, activeThread.groupName, "fullscreen=yes");
  }

  openProgressNote(noteId, param = "") {
    const { activeThread } = this.props;
    const filesUrl = [
      window.location.origin,
      "/progressnotes/" + activeThread.threadId,
      "/view/" + noteId,
      param,
    ].join("");

    window.open(filesUrl, "Hubchart Files", "_blank");
  }

  openPrescriptionHistory(prescriptionId, param = "") {
    const { activeThread } = this.props;
    const filesUrl = [
      window.location.origin,
      "/prescription-history/" + activeThread.threadId,
      "/" + prescriptionId,
      param,
    ].join("");

    window.open(filesUrl, "Hubchart Prescription", "_blank");
  }

  copyPrescriptionLink(prescriptionHistoryId) {
    const { activeThread } = this.props;
    const OrderUrl = `${window.location.origin}/prescription-history/${activeThread.threadId}/${prescriptionHistoryId}`;
    const elem = document.createElement("textarea");

    elem.value = OrderUrl;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);

    Notify.success("Prescription link copied!");
  }

  handleRichTextData(richText) {
    const richTextArray = [];

    for (let i = 0; i < (richText.blocks || []).length; i++) {
      if (
        richText.blocks[i].type === "unstyled" &&
        i === 0 &&
        richText.blocks[i].depth === 0
      ) {
        richTextArray.push(richText.blocks[i].text);
      }
      if (
        richText.blocks[i].type === "unstyled" &&
        (richText.blocks[i - 1] || {}).text === "" &&
        richText.blocks[i].depth === 0
      ) {
        richTextArray.push(richText.blocks[i].text);
      }
      if (
        (richText.blocks[i].type === "unordered-list-item" ||
          richText.blocks[i].type === "ordered-list-item") &&
        richText.blocks[i].depth === 0
      ) {
        richTextArray.push(richText.blocks[i].text);
      }
    }

    return richTextArray.map((item) => (
      <div className="card-item" key={item}>
        <div title={item}>{item}</div>
      </div>
    ));
  }

  handleRichTextArray(array) {
    const newArray = array.filter(Boolean);
    const textArray = [];

    for (let i = 0; i < (newArray || []).length; i++) {
      let txt = newArray[i].split("\n");
      textArray.push(txt[0]);
    }

    return textArray.map((item) => (
      <div className="card-item" key={item}>
        <div title={item}>{item}</div>
      </div>
    ));
  }

  handleRichTextDataLength(richText) {
    const richTextArray = [];

    for (let i = 0; i < (richText.blocks || []).length; i++) {
      if (
        richText.blocks[i].type === "unstyled" &&
        i === 0 &&
        richText.blocks[i].depth === 0
      ) {
        richTextArray.push(richText.blocks[i].text);
      }
      if (
        richText.blocks[i].type === "unstyled" &&
        (richText.blocks[i - 1] || {}).text === "" &&
        richText.blocks[i].depth === 0
      ) {
        richTextArray.push(richText.blocks[i].text);
      }
      if (
        (richText.blocks[i].type === "unordered-list-item" ||
          richText.blocks[i].type === "ordered-list-item") &&
        richText.blocks[i].depth === 0
      ) {
        richTextArray.push(richText.blocks[i].text);
      }
    }

    return richTextArray.length;
  }

  handleMedsLength(medications) {
    let count = 0;

    for (let i = 0; i < medications.length; i++) {
      if (medications[i].richText) {
        count = count + this.handleRichTextDataLength(medications[i].richText);
      } else if (medications[i].textArray) {
        count = count + (medications[i].textArray || []).length;
      } else {
        count++;
      }
    }
    return count;
  }

  displayRichTextinModal(richTextData) {
    const richText = richTextData;
    // currentTab === "current"
    //   ? richTextData
    //   : convertToRaw(richTextData.getCurrentContent());

    return (richText.blocks || [])
      .map((item, i) => {
        return item.text;
      })
      .join(", ");
  }

  handleMedsDisplay(data) {
    if (!data.textArray && !data.richText && !data.free_text) {
      return data.displayName;
    }
    if (data.textArray) {
      return data.textArray.map((item) => item).join(", ");
    }
    if (data.richText) {
      return this.displayRichTextinModal(data.richText);
    }
    if (data.free_text) {
      return data.free_text;
    }

    return "--";
  }

  render() {
    const {
      problems = [],
      activeThread,
      threadType,
      isPatient,
      progressNotes = [],
      //prescriptionsHistory = [],
      patientInfo = { medications: [] },
      showHideRxModal = () => {},
      showHideProblemModal = () => {},
    } = this.props;
    const {
      showMoreMedications,
      showMoreProblems,
      showMoreProgressNotes,
    } = this.state;
    const displayMedDx = threadType === "group" && isPatient ? true : false;

    //const sliced_prescriptions = prescriptionsHistory.slice(0, 3);
    const sliced_problems = problems.slice(0, 3);
    const sliced_progress_notes = progressNotes.slice(0, 3);
    const sliced_patienInfo = (patientInfo.medications || []).slice(0, 3);

    return (
      <div className="lists-side-container">
        {displayMedDx && (
          <Fragment>
            <div className="card-container">
              <div className="card-body card-custom">
                <div
                  className="card-title-container"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    window.open(
                      `${window.location.origin}/medications/${activeThread.threadId}`,
                      "Hubchart Medications"
                    );
                  }}
                >
                  <span
                    className="card-title"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      showHideRxModal(true);
                    }}
                  >
                    Medications List
                  </span>
                  <button
                    className="btn btn-primary btn-sm"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.handleShowMoreData(
                        "showMoreMedications",
                        showMoreMedications
                      );
                    }}
                  >
                    {!showMoreMedications && (
                      <i class="fas fa-fw fa-chevron-right"></i>
                    )}
                    {showMoreMedications && (
                      <i class="fas fa-fw fa-chevron-down"></i>
                    )}
                  </button>
                </div>
                <hr />
                <div className="card-details-container-2">
                  {(showMoreMedications
                    ? patientInfo.medications
                    : sliced_patienInfo
                  ).map((prescription) => {
                    return (
                      <div
                        className="card-item"
                        key={prescription.objectId}
                        style={{ height: 30 }}
                        onClick={() => {}}
                      >
                        <div style={{ padding: 0 }}>
                          {this.handleMedsDisplay(prescription)}
                        </div>
                      </div>
                    );
                  })}
                  {sliced_patienInfo.length === 0 && (
                    <div className="em-container">
                      <em>No Medication Found</em>
                    </div>
                  )}
                  {/* {sliced_patienInfo.length > 1 && (
                    <Fragment>
                      <hr />
                      <div className="show-more">
                        <a
                          className="btn btn-link btn-sm"
                          type="button"
                          onClick={() =>
                            this.handleShowMoreData(
                              "showMoreMedications",
                              showMoreMedications
                            )
                          }
                        >
                          {showMoreMedications ? "Show Less" : "Show More"}
                        </a>
                      </div>
                    </Fragment>
                  )} */}
                </div>
              </div>
            </div>
          </Fragment>
        )}
        <div className="card-container">
          <div className="card-body card-custom">
            <div
              className="card-title-container"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                window.open(
                  `${window.location.origin}/patient-problems/${activeThread.threadId}`,
                  activeThread.groupName,
                  "fullscreen=yes"
                );
              }}
            >
              <span
                className="card-title"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  showHideProblemModal(true);
                }}
              >
                Problems List
                {/* &nbsp;&nbsp;
                <i className="fas fa-head-side-cough"></i> */}
              </span>
              <button
                className="btn btn-primary btn-sm"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.handleShowMoreData("showMoreProblems", showMoreProblems);
                }}
              >
                {!showMoreProblems && (
                  <i class="fas fa-fw fa-chevron-right"></i>
                )}
                {showMoreProblems && <i class="fas fa-fw fa-chevron-down"></i>}
              </button>
            </div>
            <hr />
            <div className="card-details-container-2">
              {(showMoreProblems ? problems : sliced_problems).map(
                (problem) => (
                  <div className="card-item-problem" key={problem.problem}>
                    <div>{removeAllExtraLines(problem.problem)}</div>
                  </div>
                )
              )}
              {problems.length === 0 && (
                <div className="em-container">
                  <em>No Problems Found</em>
                </div>
              )}
              {/* {progressNotes.length > 1 && (
                <Fragment>
                  <hr />
                  <div className="show-more">
                    <a
                      className="btn btn-link btn-sm"
                      type="button"
                      onClick={() =>
                        this.handleShowMoreData(
                          "showMoreProblems",
                          showMoreProblems
                        )
                      }
                    >
                      {showMoreProblems ? "Show Less" : "Show More"}
                    </a>
                  </div>
                </Fragment>
              )} */}
            </div>
          </div>
        </div>
        <div className="card-container">
          <div className="card-body card-custom">
            <div
              className="card-title-container"
              onClick={() => this.openProgressNotes()}
            >
              <span className="card-title">
                Progress Notes
                {/* &nbsp;&nbsp;
                <i className="fas fa-file-signature"></i> */}
              </span>
              <button
                className="btn btn-primary btn-sm"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.handleShowMoreData(
                    "showMoreProgressNotes",
                    showMoreProgressNotes
                  );
                }}
              >
                {!showMoreProgressNotes && (
                  <i class="fas fa-fw fa-chevron-right"></i>
                )}
                {showMoreProgressNotes && (
                  <i class="fas fa-fw fa-chevron-down"></i>
                )}
              </button>
            </div>
            <hr />
            <div className="card-details-container-2">
              {(showMoreProgressNotes
                ? progressNotes
                : sliced_progress_notes
              ).map((note) => (
                <div
                  className="card-item"
                  key={note.objectId}
                  onClick={() => this.openProgressNote(note.objectId)}
                >
                  <span style={{ minWidth: "85px" }}>
                    {moment(
                      note.dateSeen || note.updatedAt || note.createdAt
                    ).format("l")}
                  </span>
                  <div>{note.chiefComplaints.join(", ") || "--"}</div>
                </div>
              ))}
              {progressNotes.length === 0 && (
                <div className="em-container">
                  <em>No Progress Notes Found</em>
                </div>
              )}
              {/* {progressNotes.length > 1 && (
                <Fragment>
                  <hr />
                  <div className="show-more">
                    <a
                      className="btn btn-link btn-sm"
                      type="button"
                      onClick={() =>
                        this.handleShowMoreData(
                          "showMoreProgressNotes",
                          showMoreProgressNotes
                        )
                      }
                    >
                      {showMoreProgressNotes ? "Show Less" : "Show More"}
                    </a>
                  </div>
                </Fragment>
              )} */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeTab: state.thread.activeTab,
  conferences: state.conference.patient_conferences,
  initial_conference: state.conference.initial_conference,
  progressNotes: state.notes.progressNotes,
  rxHistory: state.notes.rxHistory,
  patientOrdersHistory: state.patient.patientOrdersHistory,
  //prescriptionsHistory: state.patient.prescriptionsHistory,
  groupedLists: state.patient.groupedLists,
  activeSideNavTab: state.group.activeSideNavTab,
  patientInfo: state.patient.patientInfo,
});

const Hoverlay = ({ children }) => {
  return <div className="hoverlay">{children}</div>;
};

export default connect(mapStateToProps, {
  getConferenceCallsById,
  getProgressNotes,
  //getPatientOrdersHistory,
  //getPrescriptionsHistory,
  getGroupedLists,
  fetchSelectedThread,
  fetchPatientInfo,
})(SideTab);
