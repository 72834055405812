import { HAS_A_CALLER, ACCEPT_CALL, END_CALL } from '../actions/types'

const initialState = {
    hasCall : false,
    onCall : false,
    isCalling : false,
    callerName : "",
    callerProfilePic : "",
    callerId : "",
    hasVideo : false,
    callRoom : "",
    callUUID : "",
    callDestination : ""
}

 // caller_id: "RgEiL86e2w"
// caller_name: "Kristopher Amiel Reyes"
// destination: "53wLRMbcNa" - check if ako
// has_video: false
// image_url: "https://medchat.s3.amazonaws.com/daedbdac82db8688fa4bf641d14513df_file.jpeg"
// room: "53wLRMbcNa_RgEiL86e2w" - join this room
// timestamp: 1557203631151 - check if 2min delay
// type: "INCOMING_CALL"
// uuid: "4f132e60-7081-11e9-a712-173ce0c07815" - para call

export default function(state = initialState, action) {
	switch(action.type) {
        case HAS_A_CALLER:
            return {
                ...state,
                hasCall : true,
                callerName : action.incomingCallData.caller_name,
                callerProfilePic : action.incomingCallData.image_url,
                callerId : action.incomingCallData.caller_id,
                hasVideo : action.incomingCallData.has_video,
                callRoom : action.incomingCallData.room,
                callUUID : action.incomingCallData.uuid,
                callDestination : action.incomingCallData.destination
            }
        break;

        case ACCEPT_CALL:
        break;

        case END_CALL:
            return {
                hasCall : false,
                onCall : false,
                isCalling : false,
                callerName : "",
                callerProfilePic : "",
                callerId : "",
                hasVideo : false,
                callRoom : "",
                callUUID : "",
                callDestination : ""
            }
            break;

		default:
		return state;
	}
}

