import React, { useEffect } from "react";

import "./index.css";

const $ = window.$;

function ChiefComplaintsComponent({ chiefComplaints = [] }) {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="card custom-card cc-id">
      <h5 className="card-header">
        <strong>CHIEF COMPLAINTS</strong>
      </h5>
      <div className="card-body cust-card-body">
        <div className="custom-form-group-container">
          <div className="badges-container">
            <div className="cc-li-container">
              {chiefComplaints.map((item, i) => (
                <li key={item + i}>{item}</li>
              ))}
            </div>
            {chiefComplaints.length === 0 && <span></span>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChiefComplaintsComponent;
