import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Parse from "parse";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Input, Select, Label, Spinner } from "../../Common/Forms";
import TextareaAutosize from "react-textarea-autosize";
import Loading from "../../Pages/Signup/Loading";
import InputMask from "react-input-mask";
import imageCompression from "browser-image-compression";
import UserItem from "./UserItem";
import PatientItem from "./PatientItem";
import {
  fetchOrganization,
  createOrganization,
  updateOrganizationName,
  updateOrganizationProfile,
  addMembersToOrganization,
  addGuestsToOrganization,
  removeMembersFromOrganization,
  removeGuestsFromOrganization,
  addAdminsToOrganization,
  removeAdminsFromOrganization,
  fetchOrganizationGroups,
  addGroupsToOrganization,
  removeGroupsFromOrganization,
  addOrgEmail,
  removeOrgEmail,
  setOrganizationRole,
} from "../../../actions/SessionAction";
import { createThread } from "../../../actions/ThreadAction";
import { fetchSubscriber } from "../../../actions/RevenueCatAction";
import AddMemberModal from "./AddMemberModal";
import AddGuestModal from "../../Conversation/Setting/AddGuestModal";
import _ from "lodash";
import Notify from "../../Common/Notify";
import GuestItem from "./GuestItem";
import Logo from "../../../assets/images/logo-blue.png";
import CorpAcc from "../../../assets/images/subscription.png";
import "./CorporateSetting.css";
//import { Doughnut } from 'react-chartjs-2';
//import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
//import { Chart } from 'chart.js'
//import { Chart } from "react-chartjs-2/dist";
//import Chart from 'chart.js/auto';
//ChartJS.register(ArcElement, Tooltip, Legend);

const $ = window.$;
const defaultImage = require("../../../assets/images/group-default.png");
let currentTab = false;

export class CorporateSetting extends Component {
  constructor() {
    super();
    this.state = {
      isUploading: false,
      willEditName: false,
      nameIsUpdating: false,
      failedToUpdateName: false,
      willUpload: false,
      isUploading: false,
      profilePictureURL: defaultImage,
      openAddMemberModal: false,
      openCreatePatientModal: false,
      isFetchingGroups: true,
      isFetchingGroupsStatus: "Error",
      isSearching: false,
      typeValue: "",
      errorAddMember: false,
      errorAddMemberMessage: "",
      newOrgEmail: "",
      addingOrgEmail: false,
    };

    this.fileHandleChange = this.fileHandleChange.bind(this);
    this.removeMember = this.removeMember.bind(this);
    this.removeGuest = this.removeGuest.bind(this);
    this.assignAdmin = this.assignAdmin.bind(this);
    this.removeAdmin = this.removeAdmin.bind(this);
    this.updateCorporateName = this.updateCorporateName.bind(this);
    this._renderLayout = this._renderLayout.bind(this);
    this.readURL = this.readURL.bind(this);
    this.openAddMemberModal = this.openAddMemberModal.bind(this);
    this.openCreatePatientModal = this.openCreatePatientModal.bind(this);
    this._renderNoSubscription = this._renderNoSubscription.bind(this);
    this.openAddGuestModal = this.openAddGuestModal.bind(this);
    this.selectTab = this.selectTab.bind(this);
  }

  componentDidMount() {
    this.props.fetchOrganization();
    this.props.fetchSubscriber();
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    $('[data-toggle="tooltip"]').tooltip();
    if (this.props.organization) {
      if (this.props.organization.image) {
        if (
          this.props.organization.image.url !== this.state.profilePictureURL &&
          !this.state.willUpload
        ) {
          this.setState({
            profilePictureURL: this.props.organization.image.url,
          });
        }
      }
    }

    return null;
  }

  fetchOrgGroups() {
    if (
      this.props.organizationFetchStatus === "SUCCESS" &&
      this.props.organization.objectId
    ) {
      this.props
        .fetchOrganizationGroups(this.props.organization.owner.objectId)
        .then(() => {
          this.setState({
            isFetchingGroups: false,
          });
        })
        .catch(() => {
          this.setState({
            isFetchingGroups: false,
            isFetchingGroupsStatus: "Error",
          });
        });
    }
  }

  componentDidUpdate() {}

  openFileImage() {
    $(this.refs.corporateImage).trigger("click");
  }

  async fileHandleChange(e) {
    const imageFile = e.target.files[0];
    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB
      const URLFile = await imageCompression.getDataUrlFromFile(compressedFile);
      this.setState({
        file: URLFile,
        willUpload: true,
      });

      this.readURL(compressedFile);
    } catch (error) {
      console.log(error);
    }
  }

  assignAdmin(member) {
    this.props.addAdminsToOrganization(member.user);
  }

  removeMember(objectId) {
    this.props.removeMembersFromOrganization(objectId);
  }

  removeGuest(objectId) {
    this.props.removeGuestsFromOrganization(objectId);
  }

  removeAdmin(member) {
    this.props.removeAdminsFromOrganization(member.user.objectId);
  }

  willEditName() {
    this.setState({
      willEditName: true,
      failedToUpdateName: false,
    });
  }

  updateCorporateName(e) {
    e.preventDefault();
    let corporateName = this.refs.corporateName.value;

    if (
      corporateName === "" ||
      this.props.organization.name === corporateName
    ) {
      this.setState({
        nameIsUpdating: false,
        willEditName: false,
        failedToUpdateName: false,
      });
      return;
    }

    this.setState({
      nameIsUpdating: true,
    });

    let self = this;

    this.props
      .updateOrganizationName(corporateName, this.props.organization.objectId)
      .then(() => {
        self.setState({
          nameIsUpdating: false,
          willEditName: false,
          failedToUpdateName: false,
        });
      })
      .catch((error) => {
        self.setState({
          failedToUpdateName: true,
          nameIsUpdating: false,
          willEditName: false,
        });
      });
  }

  readURL(file) {
    if (file) {
      var reader = new FileReader();

      reader.onload = function(e) {
        this.setState({
          profilePictureURL: e.target.result,
        });
      }.bind(this);

      reader.readAsDataURL(file);
    }
  }

  uploadPicture() {
    if (!this.state.willUpload) return;

    this.setState({
      isUploading: true,
    });

    let file = this.state.file;
    this.props
      .updateOrganizationProfile(file, this.props.organization.objectId)
      .then(() => {
        this.setState({
          isUploading: false,
          willUpload: false,
        });
      });
  }

  openAddMemberModal() {
    this.setState({
      openAddMemberModal: true,
      errorAddMember: false,
    });
  }

  openAddGuestModal() {
    this.setState({
      openAddGuestModal: true,
      errorAddMember: false,
    });
  }

  closeAddMemberModal() {
    this.setState({
      openAddMemberModal: false,
    });
  }

  closeAddGuestModal() {
    this.setState({
      openAddGuestModal: false,
    });
  }

  openCreatePatientModal() {
    this.setState({
      openCreatePatientModal: true,
    });
  }

  closeAddPatientModal() {
    this.setState({
      openCreatePatientModal: false,
    });
  }

  addMember(user) {
    this.props
      .addMembersToOrganization([user])
      .then(() => {
        this.closeAddMemberModal();
      })
      .catch((message) => {
        if (
          message ===
          "One or more users cannot be added because they are already members of another organization."
        ) {
          Notify.errorWithTitle(
            "Failed to add members",
            "One or more users are already members of another organization"
          );
        } else if (
          message ===
          "One or more users cannot be added because they are not using a corporate email."
        ) {
          Notify.errorWithTitle(
            "Failed to add members",
            "One or more users are not using a corporate email"
          );
        } else {
          Notify.error(
            "We're having problem right now, please try again later."
          );
        }
      });
  }

  addGuest(toBeInvited) {
    this.closeAddGuestModal();

    this.props.addGuestsToOrganization(toBeInvited).catch((message) => {
      Notify.error("We're having problem right now, please try again later.");
    });
  }

  isTyping(e) {
    let isSearching = true;
    if (this.input.value === "") isSearching = false;
    this.setState({
      typeValue: this.input.value,
      isSearching: isSearching,
    });
  }

  addOrgEmail(e) {
    e.preventDefault();

    this.setState({ addingOrgEmail: true });

    this.props
      .addOrgEmail(this.state.newOrgEmail, this.props.organization.objectId)
      .then((res) => {
        this.setState({ addingOrgEmail: false });
        this.setState({ newOrgEmail: "" });
      })
      .catch((err) => {
        this.setState({ addingOrgEmail: false });
      });
  }

  removeOrgEmail(e, email) {
    e.preventDefault();

    this.props.removeOrgEmail(email, this.props.organization.objectId);
  }

  handleOrgEmailChange(e) {
    this.setState({ newOrgEmail: e.target.value });
  }

  openCorporateSubscriptionView() {
    const { activeThread } = this.props;
    const filesUrl = [window.location.origin, "/corporate-subscription"].join(
      ""
    );
    window.open(filesUrl, "Corporate Subscription");
  }

  selectTab(tab) {
    var x = document.getElementById("corporateDash");
    var y = document.getElementById("corporateSett");
    var z = document.getElementById("corporateMem");

    x.style.display = tab == "dashboard" ? "block" : "none";
    y.style.display = tab == "setting" ? "block" : "none";
    z.style.display = tab == "member" ? "block" : "none";
  }

  _renderLayout() {
    if (this.props.organizationFetchStatus === "SUCCESS") {
      if (this.props.organization.objectId) {
        const organizationName = this.props.organization.name;
        const currentUser = Parse.User.current();
        let isOwner = this.props.organization.owner.objectId === currentUser.id;

        let tempAdminstrators = this.props.organization.administrators || [];
        let tempOwner = this.props.organization.administrators || [];
        let tempMembers = this.props.organization.members || [];
        let tempPatients = this.props.organizationGroups || [];
        let tempGuests = this.props.organization.guests || [];

        const { owner } = this.props.organization;

        // Filter out owner from members and admins
        tempAdminstrators = tempAdminstrators.filter((user) => {
          return user.objectId != owner.objectId;
        });

        tempOwner = (this.props.organization.administrators || []).filter(
          (user) => {
            return user.objectId == owner.objectId;
          }
        );

        tempMembers = tempMembers.filter((user) => {
          return user.objectId != owner.objectId;
        });

        if (this.state.isSearching) {
          const { typeValue = "" } = this.state;

          tempAdminstrators = tempAdminstrators.filter((admin) => {
            return admin.displayNameLower.includes(
              typeValue.toLocaleLowerCase()
            );
          });

          tempMembers = tempMembers.filter((member) => {
            return member.displayNameLower.includes(
              typeValue.toLocaleLowerCase()
            );
          });

          tempPatients = tempPatients.filter((patient) => {
            return patient.nameLower.includes(typeValue.toLocaleLowerCase());
          });

          tempGuests = tempGuests.filter((member) => {
            return member.displayNameLower.includes(
              typeValue.toLocaleLowerCase()
            );
          });
        }

        const tempAdminstratorsSorted = _.sortBy(
          tempAdminstrators,
          "displayNameLower"
        );

        const Admins = tempAdminstratorsSorted.map((user, i) => {
          if (isOwner) {
            if (user.objectId === currentUser.id) return null;
          }

          const admin = {
            user: user,
            isAdministrator: true,
            orgId: this.props.organization.objectId,
          };
          return (
            <UserItem
              setRole={(data) =>
                this.props.setOrganizationRole({
                  data,
                  organizations: this.props.organization,
                  strRole: "administrators",
                })
              }
              key={"admin_" + i}
              member={admin}
              isCreator={isOwner}
              isAdmin={isOwner}
              removeMember={this.removeMember}
              removeAdmin={this.removeAdmin}
              isSuperAdmin={!this.state.willEditName}
            />
          );
        });

        const Owner = tempOwner.map((user, i) => {
          const admin = {
            user: user,
            isAdministrator: true,
            orgId: this.props.organization.objectId,
          };
          return (
            <UserItem
              setRole={(data) =>
                this.props.setOrganizationRole({
                  data,
                  organizations: this.props.organization,
                  strRole: "administrators",
                })
              }
              key={"admin_" + i}
              member={admin}
              isCreator={isOwner}
              isAdmin={isOwner}
              removeMember={this.removeMember}
              removeAdmin={this.removeAdmin}
              isSuperAdmin={!this.state.willEditName}
            />
          );
        });

        const tempMembersSorted = _.sortBy(tempMembers, "displayNameLower");

        const Members = tempMembersSorted.map((user, i) => {
          if (isOwner) {
            if (user.objectId === currentUser.id) return null;
          }
          const member = {
            user: user,
            orgId: this.props.organization.objectId,
          };
          return (
            <UserItem
              setRole={(data) =>
                this.props.setOrganizationRole({
                  data,
                  organizations: this.props.organization,
                  strRole: "members",
                })
              }
              key={"member_" + i}
              member={member}
              isCreator={isOwner}
              isAdmin={false}
              removeMember={this.removeMember}
              assignAdmin={this.assignAdmin}
            />
          );
        });

        const tempGuestsSorted = _.sortBy(tempGuests, "displayNameLower");

        const Guests = tempGuestsSorted.map((user, i) => {
          if (isOwner) {
            if (user.objectId === currentUser.id) return null;
          }
          const member = {
            user: user,
          };
          return (
            <GuestItem
              key={"member_" + i}
              member={member}
              isCreator={isOwner}
              isAdmin={false}
              removeGuest={this.removeGuest}
              assignAdmin={this.assignAdmin}
              isGuest={true}
            />
          );
        });

        const Patients = tempPatients.map((patient, i) => {
          return (
            <PatientItem
              key={"patient_" + i}
              patient={patient}
              createThread={this.props.createThread}
              history={this.props.history}
              addGroupsToOrganization={this.props.addGroupsToOrganization}
              removeGroupsFromOrganization={
                this.props.removeGroupsFromOrganization
              }
            />
          );
        });

        let memberIsEmpty = false;
        if (this.props.organization.members.length === 1) {
          if (
            this.props.organization.members[0].objectId ===
            Parse.User.current().id
          ) {
            memberIsEmpty = true;
          }
        } else if (this.props.organization.members.length === 0) {
          memberIsEmpty = true;
        }

        let fullWidth = isOwner ? {} : { width: "100%" };
        let defaultPlaceholder = isOwner
          ? "Search members or patient group"
          : "Search members";

        const { organization } = this.props;
        const { guests = [] } = organization;

        let userObject = JSON.stringify(Parse.User.current().toJSON());
        userObject = JSON.parse(userObject);

        let imageURL = require("../../../assets/images/default.png");
        if (userObject.picture) {
          if (userObject.picture.url) {
            imageURL = userObject.picture.url;
          }
        }
        let Name = "";
        let Email = "";
        const versionNumber = "00.00";
        //const currentTab = "";

        let statusStyle = {
          padding: "0",
          fontSize: "12px",
          fontWeight: "500",
          color: "rgb(95, 93, 93)",
          whiteSpace: "pre-wrap",
        };

        const containerStyles = {
          position: "relative",
          height: "100%",
          overflowY: "auto",
        };

        const {
          firstName,
          lastName,
          dob,
          pending,
          allowCorporateAccess,
          is_valid_date = true,
        } = this.state;

        const members = [
          {
            name: "Carlota Sulit",
            position: "Physician",
            access: "admin",
            pic:
              "https://images.squarespace-cdn.com/content/v1/5cf0d08d5fc69d000172462a/1599805610146-J0G5GMGFBXVWND4Z71UK/Aleem+Business+Headshot+for+LinkedIn+Profile.jpg",
          },
          {
            name: "Rose Reyes",
            position: "Healthcare VA",
            access: "member",
            pic:
              "https://images.squarespace-cdn.com/content/v1/5cf0d08d5fc69d000172462a/1636100249202-5NY98C6ASRIFFPO9GZTU/Tom+Professional+Business+Headshot.jpg?format=500w",
          },
          {
            name: "Anthony Caedo",
            position: "App Tester",
            access: "guest",
            pic:
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGQHnde70uCkEfpS4tBgxuBZGWCSt1ZGBOnA&usqp=CAU",
          },
        ];
        const city = [
          {
            name: "Muskogee (US)",
            objectId: 1,
          },
          {
            name: "Windsor (CA)",
            objectId: 2,
          },
          {
            name: "Barre (US)",
            objectId: 3,
          },
        ];
        const state = [
          {
            name: "Arizona",
          },
          {
            name: "Hawaii",
          },
          {
            name: "Oregon",
          },
        ];
        const country = [
          {
            name: "Mexico",
          },
          {
            name: "Cabo Verde",
          },
          {
            name: "Belarus",
          },
        ];
        const isQuantifiable = false;
        const address = {};
        const selectTab = () => {};

        const config = {
          type: "doughnut",
          data: {
            labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
            datasets: [
              {
                label: "# of Votes",
                data: [12, 19, 3, 5, 2, 3],
                backgroundColor: [
                  "Red",
                  "Blue",
                  "Yellow",
                  "Green",
                  "Purple",
                  "Orange",
                ],
                borderRadius: 1000,
              },
            ],
          },
        };
        const ctx = document.getElementById("myChart");
        //const myChart = new Chart(ctx, config);

        return (
          <div className="App-Row">
            <div
              className="shadow-2 mb-5 bg-white border-0 setting-container-2"
              style={{
                width: "250px",
                position: "fixed",
                height: "100vh",
                zIndex: 1,
              }}
            >
              {/* Tab-Container */}
              <div className="huchart-flex">
                <img
                  className="d-none d-sm-inline"
                  width="50"
                  height="50"
                  src={Logo}
                  alt="H"
                />
                <span
                  className="hc-primary-text d-none d-md-inline"
                  style={{ fontWeight: "500", fontSize: 22 }}
                >
                  HUBCHART
                </span>
              </div>
              <h5 style={{ margin: "15px 0px 15px 5px" }}>
                Corporate Organization
              </h5>
              <div className="detail-container-2">
                <p
                  className="action active"
                  onClick={(e) => {
                    this.selectTab("dashboard");
                  }}
                >
                  <i
                    className="fas fa-solid fa-list-ul"
                    style={{ margin: "0 10px" }}
                  ></i>
                  Dashboard
                </p>
              </div>
              <div className="detail-container-2">
                <p
                  className="action"
                  onClick={(e) => {
                    this.selectTab("setting");
                  }}
                >
                  <i
                    className="fa fa-sharp fa-solid fa-gear"
                    style={{ margin: "0 10px" }}
                  ></i>
                  Settings
                </p>
              </div>
              <div className="detail-container-2">
                <p
                  className="action"
                  onClick={(e) => {
                    this.selectTab("member");
                  }}
                >
                  {" "}
                  <i
                    className="fas fa-solid fa-users"
                    style={{ margin: "0 10px" }}
                  ></i>
                  Members
                </p>
              </div>
              <div className="detail-container-2">
                <p
                  className="action"
                  //onClick={(e) => { this.selectTab("subscription")}}
                >
                  <i
                    className="fas fa-solid fa-hospital-user"
                    style={{ margin: "0 10px" }}
                  ></i>
                  Patient Hubs
                </p>
              </div>
              <div className="detail-container-2">
                <p
                  className="action"
                  // onClick={(e) => {
                  //   this.selectTab("subscription");
                  // }}
                  onClick={this.openCorporateSubscriptionView.bind(this)}
                >
                  <i
                    className="fas fa-shard fa-solid fa-briefcase"
                    style={{ margin: "0 10px" }}
                  ></i>
                  Subscription
                </p>
              </div>
            </div>
            <div
              className="Main-Panel setting-container-2"
              style={{ paddingLeft: "250px" }}
            >
              <div
                className="container"
                id="corporateSett"
                style={{ display: "none" }}
              >
                <form onSubmit={this.createPatient}>
                  <div className="row corp-setting">
                    <h4>Corporate Setting</h4>
                    <small className="">Corporate Name</small>
                    <TextareaAutosize
                      className="form-control shadow-sm border-0"
                      placeholder=""
                      //onChange={(e) => setText(e.target.value)}
                      style={{
                        resize: "none",
                      }}
                      required
                    />
                    <h5 style={{ "padding-top": "15px" }}>Business Address</h5>
                    <small className="">Address 1</small>
                    <TextareaAutosize
                      className="form-control shadow-sm border-0"
                      placeholder=""
                      style={{
                        resize: "none",
                      }}
                      required
                    />
                    <small style={{ marginTop: 5 }}>Address 2</small>
                    <TextareaAutosize
                      className="form-control shadow-sm border-0"
                      placeholder=""
                      style={{
                        resize: "none",
                      }}
                      required
                    />
                    <div className="row">
                      <span className="col">
                        <small className="">City</small>
                        <Select className="form-control" value="">
                          <option value=""></option>
                          {city.map((index) => (
                            <option
                              key={index.objectId}
                              value={index.objectId}
                              onClick={(address.city = index.name)}
                            >
                              {index.name}
                            </option>
                          ))}
                        </Select>
                      </span>
                      <span className="col">
                        <small className="">State</small>
                        <Select className="form-control" value="">
                          <option value=""></option>
                          {state.map((index) => (
                            <option
                              key={index.objectId}
                              value={index.objectId}
                              onClick={(address.state = index.name)}
                            >
                              {index.name}
                            </option>
                          ))}
                        </Select>
                      </span>
                      <span className="col">
                        <small className="">Country</small>
                        <Select className="form-control" value="">
                          <option value=""></option>
                          {country.map((index) => (
                            <option
                              key={index.objectId}
                              value={index.objectId}
                              onClick={(address.country = index.name)}
                            >
                              {index.name}
                            </option>
                          ))}
                        </Select>
                      </span>
                      <br />
                      <span className="w-100"></span>
                      <span className="col-4">
                        <small className="">Postal Code</small>
                        <TextareaAutosize
                          className="form-control shadow-sm border-0"
                          placeholder=""
                          //onChange={(e) => setText(e.target.value)}
                          style={{
                            resize: "none",
                          }}
                          required
                        />
                      </span>
                    </div>
                    <div style={{ marginTop: 10, textAlign: "right" }}>
                      <button
                        type="button"
                        title="Edit Medication"
                        className="btn btn-primary btn-md"
                        style={{ borderRadius: "12px", marginLeft: "auto" }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              <div style={{ "padding-left": "0px" }}>
                <div
                  className="container"
                  id="corporateMem"
                  style={{ display: "none" }}
                >
                  <div
                    style={{ margin: "10px 0 0 0" }}
                    className="Tab-Search-Container"
                  >
                    <i className="fas fa-search"></i>
                    <input
                      //onChange={this.filterPatients.bind(this)}
                      id="search"
                      placeholder="Search members"
                      //ref={i=>this.input=i}Profile-Setting
                      type="search"
                    />
                  </div>
                  <br />
                  <h3 style={{ color: "black" }}>Corporate Team</h3>
                  <br />
                  <small>Owner</small>
                  <div className="row">
                    <div className="members-img" style={{ display: "flex" }}>
                      <img src={imageURL} alt="Group Profile Picture" />
                      <p>
                        Rjill Ham Manuel
                        <p>Patient</p>
                      </p>
                    </div>

                    {/* <div className="img-container">
                      <img src={imageURL} alt="Group Profile Picture" />
                      <p>{this.props.organization.owner.displayName}</p>
                      <p className="hc-primary-text p-0">rhill.manuel@gmail.com</p>
                    </div> */}

                    {/* <span className="">
                      <div className="Profile-Setting-2"> 
                        <img src={imageURL} alt="Group Profile Picture" />
                        
                        <p style={{ color: "black"}}>
                          {this.props.organization.owner.displayName}
                        </p>
                        <p style={{ color: "black"}}>
                          Patient
                        </p>
                        <small style={{ fontStyle: "Italic" }}>
                            {this.props.organization.owner.profession}
                        </small> 
                      </div>
                    </span> */}
                    <span className="col">
                      <small className="badge badge-danger text-capitalize">
                        {this.props.organization.owner.orgRole}
                      </small>
                    </span>
                    <span className="col">
                      <div class="dropdown">
                        <button
                          class="btn dropdown-toggle"
                          type="button"
                          id="accountDropdownMenu"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          style={{ float: "right" }}
                        >
                          <i className="fas fa-ellipsis-v"></i>
                        </button>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="accountDropdownMenu"
                        >
                          <button class="dropdown-item" type="button">
                            Action 1
                          </button>
                          <button class="dropdown-item" type="button">
                            Action 2
                          </button>
                        </div>
                      </div>
                    </span>
                  </div>
                  <div class="row border-bottom">
                    <div
                      className="col"
                      data-bs-toggle="tab"
                      style={{ width: "150px" }}
                      type="button"
                      role="tab"
                    >
                      All Members
                    </div>
                    <div
                      className="col"
                      style={{ width: "150px" }}
                      data-bs-toggle="tab"
                      type="button"
                      role="tab"
                    >
                      Administrators
                    </div>
                    <div
                      className="col"
                      style={{ width: "150px" }}
                      data-bs-toggle="tab"
                      type="button"
                      role="tab"
                    >
                      Regular Members
                    </div>
                    <div
                      className="col"
                      style={{ width: "150px" }}
                      data-bs-toggle="tab"
                      type="button"
                      role="tab"
                    >
                      Guest
                    </div>
                    <div className="col-5">
                      <span style={{ float: "right" }}>
                        <button
                          type="button"
                          className="btn btn-success btn-sm"
                          style={{ borderRadius: "12px" }}
                        >
                          <i className="fas fa-plus"></i> Add member
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className="row" style={{ backgroundColor: "#f8f9fa" }}>
                    <span className="col-sm" style={{ color: "black" }}>
                      Name
                    </span>
                    <span className="col-sm" style={{ color: "black" }}>
                      Corp. position
                    </span>
                    <span className="col-sm" style={{ color: "black" }}>
                      Authorization
                    </span>
                    <span className="col-sm"></span>
                  </div>
                  {members.map((item, i) => {
                    return (
                      <div className="row">
                        <span className="col-sm">
                          <div
                            className="members-img"
                            style={{ display: "flex" }}
                          >
                            <img src={item.pic} alt="Group Profile Picture" />
                            <p>
                              {item.name}
                              <p>App Tester</p>
                            </p>
                          </div>
                        </span>
                        <span className="col-sm">
                          <small
                            className={
                              item.access == "admin"
                                ? "badge badge-primary members-badge"
                                : ""
                            }
                          >
                            {item.access == "admin" ? "Administrator" : ""}
                          </small>
                          <small
                            className={
                              item.access == "member"
                                ? "badge badge-success members-badge"
                                : ""
                            }
                          >
                            {item.access == "member" ? "Member" : ""}
                          </small>
                          <small
                            className={
                              item.access == "guest"
                                ? "badge badge-secondary members-badge"
                                : ""
                            }
                          >
                            {item.access == "guest" ? "Guest" : ""}
                          </small>
                        </span>
                        <span className="col-sm">
                          <Select className="form-control" value={item.access}>
                            <option>Perscriber</option>
                            {state.map((index) => (
                              <option
                                key={index.objectId}
                                value={index.objectId}
                                onClick={(address.state = index.name)}
                              >
                                {index.name}
                              </option>
                            ))}
                          </Select>
                        </span>
                        <span className="col">
                          <div class="dropdown">
                            <button
                              class="btn dropdown-toggle"
                              type="button"
                              id="memberDropdownMenu"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              style={{ float: "right" }}
                            >
                              <i className="fas fa-ellipsis-v"></i>
                            </button>
                            <div
                              class="dropdown-menu"
                              aria-labelledby="memberDropdownMenu"
                            >
                              <button class="dropdown-item" type="button">
                                Action 1
                              </button>
                              <button class="dropdown-item" type="button">
                                Action 2
                              </button>
                            </div>
                          </div>
                        </span>
                      </div>
                    );
                  })}
                </div>

                <div className="container" id="corporateDash">
                  <h4 style={{ color: "black" }}>
                    Welcome back, {this.props.organization.owner.displayName}
                  </h4>
                  <small>Here's what's going on with your Organization</small>
                  <br />
                  <br />
                  <div className="row" style={{ flexWrap: "nowrap" }}>
                    <span className="col">
                      <div
                        className="shadow-sm container-padding-md"
                        style={{ background: "#FFFFFF" }}
                      >
                        <div className="d-flex w-100 justify-content-between">
                          <h4 className="mb-0">Notes Posted</h4>
                          <h4>300 / 1000</h4>
                        </div>
                        {/* <div className="">
                        <canvas id="myChart" width="600" height="400"></canvas>
                        <script src="https://cdn.jsdelivr.net/npm/chart.js"></script>
                        </div> */}
                        <img
                          src="https://chartio.com/assets/cad7f1/tutorials/charts/pie-charts/eaeaa38b48b5dc5229d876831ab031f38c2bedb2140cfab55b8f1ff22842cce9/pie-chart-example-1.png"
                          width="280"
                          height="200"
                        ></img>
                      </div>
                      <small style={{ fontStyle: "italic" }}>
                        Notes posted in the past 6 months
                      </small>
                    </span>
                    <span className="col">
                      <div
                        className="shadow-sm container-padding-md"
                        style={{ background: "#FFFFFF" }}
                      >
                        <div className="d-flex w-100 justify-content-between">
                          <h4 className="mb-0">Active Hubs</h4>
                          <h4>500 / 1000</h4>
                        </div>
                        <img
                          src="https://chartio.com/assets/cad7f1/tutorials/charts/pie-charts/eaeaa38b48b5dc5229d876831ab031f38c2bedb2140cfab55b8f1ff22842cce9/pie-chart-example-1.png"
                          width="280"
                          height="200"
                        ></img>
                      </div>
                      <small style={{ fontStyle: "italic" }}>
                        Hubs with NO activity in the last 6 months
                      </small>
                    </span>
                    <span className="col-5"></span>
                  </div>
                  <br />
                  <div className="row">
                    <span className="col">
                      <div
                        className="shadow-sm border-0 container-padding-md"
                        style={{ background: "#FFFFFF" }}
                      >
                        <h6 style={{ color: "black" }}>Corporate members</h6>
                        <i
                          className="fas fa-arrow-right"
                          style={{
                            lineHeight: "0px !important",
                            display: "flex",
                            justifyContent: "right",
                            alignItems: "right",
                            color: "black",
                            position: "absolute",
                            right: "20px",
                            top: "10px",
                            transform: "rotate(-45deg)",
                          }}
                        ></i>
                        <div>
                          <i
                            className="fas fa-circle"
                            style={{ color: "red" }}
                          ></i>{" "}
                          Administrators{" "}
                          <span style={{ float: "right" }}>5</span>
                        </div>
                        <div>
                          <i
                            className="fas fa-circle"
                            style={{ color: "blue" }}
                          ></i>{" "}
                          Members <span style={{ float: "right" }}>10</span>
                        </div>
                        <div>
                          <i className="fas fa-circle"></i> Guests{" "}
                          <span style={{ float: "right" }}>4</span>
                        </div>
                      </div>
                    </span>
                    <span className="col-9"></span>
                  </div>
                  <br />
                  <div className="row" style={{ flexWrap: "nowrap" }}>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                      <div
                        className="shadow-2 p-3 mb-5 bg-white detail-container-2"
                        style={{ display: "block", height: "300px" }}
                      >
                        <div className="d-flex w-100 justify-content-between">
                          <h4 style={{ color: "black", height: "80px" }}>
                            Complete your Organization information
                          </h4>
                          <i
                            className="fas fa-arrow-right"
                            style={{
                              lineHeight: "0px !important",
                              display: "flex",
                              justifyContent: "right",
                              alignItems: "right",
                              color: "black",
                              position: "absolute",
                              right: "10px",
                              top: "10px",
                              transform: "rotate(-45deg)",
                            }}
                          ></i>
                        </div>
                        <br />
                        <small>
                          To make the most of our platform, we suggest
                          completing your organization's information. Its vital
                          for establishing credibility and trust with patients,
                          streamlining team workflows, and personalizing the
                          patient experience.
                        </small>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                      <div
                        className="shadow-2 p-3 mb-5 bg-white detail-container-2"
                        style={{ display: "block", height: "300px" }}
                      >
                        <div className="d-flex w-100 justify-content-between">
                          <h4 style={{ color: "black", height: "80px" }}>
                            Add members to your organization
                          </h4>
                          <i
                            className="fas fa-arrow-right"
                            style={{
                              lineHeight: "0px !important",
                              display: "flex",
                              justifyContent: "right",
                              alignItems: "right",
                              color: "black",
                              position: "absolute",
                              right: "10px",
                              top: "10px",
                              transform: "rotate(-45deg)",
                            }}
                          ></i>
                        </div>
                        <br />
                        <small>
                          By assembling a collaborative healthcare team, you can
                          deliver comprehensive care and improve patient
                          outcomes. Adding team members fosters collaboration,
                          expands expertise, and enchances communication within
                          your organization.
                        </small>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                      <div
                        className="shadow-2 p-3 mb-5 bg-white detail-container-2"
                        style={{ display: "block", height: "300px" }}
                      >
                        <div className="d-flex w-100 justify-content-between">
                          <h4 style={{ color: "black", height: "80px" }}>
                            Create a patient hub
                          </h4>
                          <i
                            className="fas fa-arrow-right"
                            style={{
                              lineHeight: "0px !important",
                              display: "flex",
                              justifyContent: "right",
                              alignItems: "right",
                              color: "black",
                              position: "absolute",
                              right: "10px",
                              top: "10px",
                              transform: "rotate(-45deg)",
                            }}
                          ></i>
                        </div>
                        <br />
                        <small>
                          Create a patient hub for each individual under your
                          care. A patient hub serves as a centralized
                          repository, housing vital information such as medical
                          charts, progress notes, medication orders, and chats
                          between healthcare providers patients, and their
                          family members
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
      }
    }
  }

  _renderNoSubscription(message) {
    return (
      <>
        <h4 style={{ textAlign: "center" }} className="hc-primary-text">
          Corporate Account Subscription
        </h4>
        <p style={{ textAlign: "center", marginTop: "15px" }}>{message}</p>
      </>
    );
  }

  render() {
    if (this.props.organization) {
      if (this.props.organization.image) {
        if (
          this.props.organization.image.url !== this.state.profilePictureURL &&
          !this.state.willUpload
        ) {
          this.setState({
            profilePictureURL: this.props.organization.image.url,
          });
        }
      }
    }

    return (
      <div
        style={{
          height: "100vh",
          background: "#FFFFFF",
          overflowY: "auto",
        }}
      >
        {this._renderLayout()}
        {/* <AddMemberModal
          isOpen={this.state.openAddMemberModal}
          onClose={this.closeAddMemberModal.bind(this)}
          onSelect={this.addMember.bind(this)}
        />
        {this.state.openAddGuestModal && (
          <AddGuestModal
            from="organization"
            members={this.props.organization.guests}
            inviteGuest={this.addGuest.bind(this)}
            isOpen={this.state.openAddGuestModal}
            modalClose={this.closeAddGuestModal.bind(this)}
          />
        )} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  organization: state.session.organization,
  organizationFetchStatus: state.session.organizationFetchStatus,
  organizationGroups: state.session.organizationGroups,
  revenueCatSubscriber: state.session.revenueCatSubscriber,
});
export default connect(mapStateToProps, {
  fetchOrganization,
  createOrganization,
  updateOrganizationName,
  updateOrganizationProfile,
  addMembersToOrganization,
  addGuestsToOrganization,
  removeMembersFromOrganization,
  removeGuestsFromOrganization,
  addAdminsToOrganization,
  removeAdminsFromOrganization,
  fetchOrganizationGroups,
  addGroupsToOrganization,
  removeGroupsFromOrganization,
  setOrganizationRole,
  createThread,
  addOrgEmail,
  removeOrgEmail,
  fetchSubscriber,
})(CorporateSetting);
