import React, { Component, useMemo, useState } from "react";
import autosize from "autosize";
import Dropzone from "react-dropzone";
import Parse from "parse";
import PUBNUB from "pubnub";
import moment from "moment";
import PubNub from "pubnub";
import LoadingSpinner from "./Bubble/LoadingSpinner";
import TimeMessage from "./Bubble/TimeMessage";
import SystemMessage from "./Bubble/SystemMessage";
import FromMe from "./Bubble/FromMe";
import FromThem from "./Bubble/FromThem";
import FromConference from "./Bubble/FromConference";
import ConversationMenu from "./ConversationMenu";
import Lightbox from "./Lightbox";
import {
  randomID,
  setEmojiShown,
  checkUncheckMessages,
  resetSelectedMessages,
  openCloseCheckBoxes,
  hideReplyPreview,
  createActionItems,
  setActiveThread,
  fetchThreads,
  setAttentionedMessage,
} from "../../actions/ThreadAction";
import DeleteMessageModal from "./Bubble/DeleteMessageModal";
import DeleteForEveryoneModal from "./Bubble/DeleteForEveryoneModal";
import ForwardModal from "./Forward/ForwardModal2";
import ForwardMultipleModal from "./Forward/ForwardMultipleModal";
import ReplySMSModal from "./Reply/ReplySMSModal";
import ForwardToCCMModal from "../CCM/ForwardToCCMModal";
import ManageEventModal from "../Pages/ConferenceMain/ManageEventModal";
import ConfirmModal from "../TabList/ConferenceTab/ConfirmModal";
import ForwardFilterModal from "./Forward/ForwardFilterModal";
import { Document, Page, pdfjs } from "react-pdf";
import { setDraftMessage, setDefaultDraftMessages, fetchUnreadCount } from "../../actions/ThreadAction";
import { connect } from "react-redux";
import SideTab from "./SideTab";
import FilePreview from "../FilePreview2";
import MMSPreview from "../MMSPreview";
import { fetchDocuments, saveDocument } from "../../actions/DocumentAction";
import util from "../../helper/util";
import { Picker } from "emoji-mart";
import UrgentModal from "./UrgentModal";
import AlertModal from "./AlertModal";
import ReactTooltip from "react-tooltip";
import OverlayLoader from "../Common/OverlayLoader";
import ReplyTo from "./ReplyTo";
import ReplyPreviewModal from "./ReplyPreviewModal";
import ImagePlaceholder from "../Common/ImagePlaceholder";
import AttentionNotify from "./AttentionNotify";
import { parseAction } from "../../actions/REST";
import config from "../../config";
import ActionsModal from "../Conversation/Setting/ActionsModal";
import CriticalModal from "../Conversation/Setting/CriticalModal";
import ReminderModal from "./Reminder/ReminderModal";

import { filter } from "lodash";

const $ = window.$;

export class ConversationBody extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scrollDown: false,
      focusTextArea: false,
      textAreaEventListening: false, //checking so that we don't add another eventListener
      isLoadingMore: false,
      initialHeight: 0,
      isLoading: false,
      openPreview: false,
      openLightbox: false,
      mobileCardsOpened: false,
      files: [],
      currentURL: "",
      hasTextInTextArea: false,
      openAttention: false,
      assignAttention: [],
      assignAttentionName: [],
      isUrgent: false,
      isTask: false,
      willOpenFile: false,
      openDeleteModal: false,
      openDeleteForEveryone: false,
      toBeDelete: "",
      toBeDeleteForEveryone: "",
      openForwardModal: false,
      openForwardMultipleModal: false,
      openForwardToCCMModal: false,
      openForwardFilter: false,
      showManageEventModal: false,
      showConfirmModal: false,
      totalDuration: 0,
      currentIndex: 1,
      selectedConference: {},
      urgentModalShown: false,
      alertModalShown: false,
      scrollLeftShown: false,
      replyTo: null,
      isEndOfConversation: false,
      isAttentionNotify: false,
      actionsModalOpened: false,
      criticalModalOpened: false,
      selectedActionItem: "",
      assignMesage: "",
      selectedPriority: -1,
      resizeCount: 0,
      groupFilter: "",
      forwardBy: "",
      openReminder: false,
      criticalMsg: '',
    };

    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.textAreaResize = this.textAreaResize.bind(this);
    this.createNewMessage = this.createNewMessage.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.sendMessageButton = this.sendMessageButton.bind(this);
    this.postOnConversation = this.postOnConversation.bind(this);
    this.getNextMessage = this.getNextMessage.bind(this);
    this.setShowManageEventModal = this.setShowManageEventModal.bind(this);
    this.setShowConfirmModal = this.setShowConfirmModal.bind(this);
    this.openForwardMultiple = this.openForwardMultiple.bind(this);
    this.handleUrgentClose = this.handleUrgentClose.bind(this);
    this.handleUrgentSubmit = this.handleUrgentSubmit.bind(this);
    this.handleAlertClose = this.handleAlertClose.bind(this);
    this.handleAlertSubmit = this.handleAlertSubmit.bind(this);
    this.handleSetAsUrgent = this.handleSetAsUrgent.bind(this);
    this.handleSetAsTask = this.handleSetAsTask.bind(this);
    this.handleSetTaskAndMessage = this.handleSetTaskAndMessage.bind(this);
    this.handleCreateTask = this.handleCreateTask.bind(this);
    this.sendAsUrgent = this.sendAsUrgent.bind(this);
    this.handleSendAfterAssign = this.handleSendAfterAssign.bind(this);
    this.sendMessageFromAssignButton = this.sendMessageFromAssignButton.bind(this);
    this.slideScroll = this.slideScroll.bind(this);
    this.showHideActionItemsModal = this.showHideActionItemsModal.bind(this);
    this.handleOpenActionsModal = this.handleOpenActionsModal.bind(this);
    this.handleOpenCriticalModal = this.handleOpenCriticalModal.bind(this);
    this.setPriority = this.setPriority.bind(this);
    this.resetMessages = this.resetMessages.bind(this);
    this.openReminder = this.openReminder.bind(this);

    // constants
    this.links = this.getLinks();
  }

  setPriority(prio) {
    this.setState(
      {
        selectedPriority: prio,
      },
      () => {
        this.sendMessageButton();
      }
    );
  }

  showHideActionItemsModal(bool) {
    this.setState({ actionsModalOpened: bool, selectedActionItem: "" });
    this.setState({ criticalModalOpened: bool });
  }

  handleOpenActionsModal(actionsModalOpened, selectedActionItem) {
    this.setState({ actionsModalOpened, selectedActionItem });
  }

  handleOpenCriticalModal(criticalModalOpened, selectedActionItem) {
    this.setState({ criticalModalOpened });
  }

  handleUrgentClose() {
    this.setState({
      urgentModalShown: false,
    });
  }

  handleUrgentSubmit(users) {
    this.setState({
      urgentModalShown: false,
      isUrgent: true,
      assignAttention: [...users],
    });
  }

  handleAlertClose() {
    this.setState({
      alertModalShown: false,
    });
  }

  handleAlertSubmit(users) {
    this.setState({
      // isUrgent: false,
      alertModalShown: false,
      assignAttention: [...users],
    });
  }

  handleSetAsUrgent(isUrgent) {
    this.setState({ isUrgent });
  }

  handleSetAsTask(isTask) {
    this.setState({ isTask });
  }

  handleSetTaskAndMessage(isTask, assignMesage, alertModalShown) {
    this.setState({ isTask, assignMesage, alertModalShown });
  }

  async handleCreateTask() {
    const { assignAttention } = this.state;
    const { activeThread } = this.props;
    if (assignAttention.length > 0 && this.textarea.value) {
      const data = {
        text: this.textarea.value,
        assignedTo: assignAttention[0],
        threadName: activeThread.groupName,
        threadId: activeThread.threadId,
      };
      const actionItem = await this.props.createActionItems(activeThread.threadId, data);
      this.handleSendAfterAssign(assignAttention, actionItem);
    }
  }

  sendAsUrgent() {
    this.setState({ isUrgent: true }, () => {
      this.sendMessageButton();
    });
  }

  handleSendAfterAssign(users, actionItem) {
    this.setState(
      {
        // isUrgent: false,
        assignMesage: "",
        alertModalShown: false,
        assignAttention: [...users],
      },
      () => {
        this.sendMessageFromAssignButton(actionItem);
      }
    );
  }

  closeFilePreview() {
    this.setState({
      openLightbox: false,
    });
  }

  componentWillUnmount() {
    this.state.files.forEach((file) => URL.revokeObjectURL(file.preview));
    if (this.textarea) {
      autosize.destroy(this.textarea);
      this.textarea.removeEventListener("autosize:resized", this.textAreaResize);
      this.ul_content.removeEventListener("scroll", this.handleScroll);
    }
  }

  getNextMessage(threadId) {
    const { draftMessages } = this.props;
    const decryptedId = `${util.encrypt(Parse.User.current().id)}_${util.encrypt(threadId)}`;

    return draftMessages[decryptedId] || "";
  }

  setTextArea(message) {
    this.textarea.value = message;
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    // Are we adding new items to the list?
    // Capture the scroll position so we can adjust scroll later.
    if (
      typeof prevProps.conversation.messages === "undefined" ||
      typeof this.props.conversation.messages === "undefined"
    )
      return null;

    if (prevProps.activeThread.threadId !== this.props.activeThread.threadId) {
      this.props.openCloseCheckBoxes(false);
      this.setState({
        isUrgent: false,
        assignAttention: [],
        openAttention: false,
        openPreview: false,
        files: [],
        focusTextArea: true,
        isLoadingMore: false,
        scrollDown: true,
        replyTo: null,
      });

      const currentMessage = this.textarea.value || "";

      const nextMessage = this.getNextMessage(this.props.activeThread.threadId);

      this.props.setDraftMessage(prevProps.activeThread.threadId, currentMessage);

      this.setTextArea(nextMessage);
    }

    if (this.props.activeThread) {
      if (!this.state.textAreaEventListening) {
        // this.props.setDefaultDraftMessages(localStorage.getItem('draftMessages'));
        const defaultMessage = this.getNextMessage(this.props.activeThread.threadId);
        this.setTextArea(defaultMessage);

        autosize(this.textarea);
        this.textarea.addEventListener("autosize:resized", this.textAreaResize);
        this.ul_content.addEventListener("scroll", this.handleScroll);
        this.setState({
          textAreaEventListening: true,
          focusTextArea: true,
        });
      }

      // const { activeThread, pubNub } = this.props;

      // if (activeThread.threadId) {
      //   pubNub.subscribe({
      //     channels: [`group_${activeThread.threadId}`]
      //   });
      //   pubNub.hereNow(
      //     {
      //       channels: [`group_${activeThread.threadId}`]
      //     },
      //     function(status, response) {
      //       console.log(status, response);
      //     }
      //   );
      // }
    }

    if (prevProps.conversation.messages.length < this.props.conversation.messages.length) {
      this.setState({
        scrollDown: true,
      });
    }

    if (this.props.appState === "Hidden") {
      if (prevProps.conversation.messages.length < this.props.conversation.messages.length) {
        // TODO  add seen
        // TODO if idle
        // TODO dont seen
      }
    }
    return null;
  }

  // fetchPubNubKey() {
  //   parseAction("post", config.BASE_URL + "/parse/functions/getPubNubKeys")
  //     .then(keys => {
  //       this.initializePubNub(keys.result);
  //     })
  //     .catch(error => {
  //       Parse.User.logOut().then(() => {
  //         localStorage.clear();
  //         window.location.href = "/";
  //       });
  //     });
  // }

  // initializePubNub(keys) {
  //   const { activeThread } = this.props;
  //   this.PubNub = new PUBNUB({
  //     publish_key: keys.publishKey,
  //     subscribe_key: keys.subscribeKey,
  //     ssl: window.location.protocol.toLowerCase() === "https:",
  //     origin: "pubsub.pubnub.com",
  //     uuid: Parse.User.current().id,
  //     presenceTimeout: 120,
  //     heartbeatInterval: 30
  //   });

  //   let channels = [`group_${activeThread.threadId}`];
  //   this.subscribeGroup(channels);
  //   this.hereNow(channels);
  // }

  // subscribeGroup(channels) {
  //   this.PubNub.subscribe({
  //     channels: channels
  //   });
  // }

  // hereNow(channels) {
  //   this.PubNub.hereNow({ channels: channels }, function(status, response) {
  //     console.log(status, response);
  //   });
  // }

  componentDidMount() {
    const dis = this;
    window.addEventListener("resize", () => {
      const { resizeCount } = dis.state;
      if (window.innerWidth < 1154 && resizeCount === 0) {
        dis.setState({ mobileCardsOpened: true, resizeCount: 1 });
      }

      if (window.innerWidth > 1154 && resizeCount === 1) {
        dis.setState({ mobileCardsOpened: false, resizeCount: 0 });
      }
    });
  }

  componentDidUpdate() {
    const { attentionedMessage, conversation } = this.props;

    $('[data-toggle="tooltip"]').tooltip();
    if (this.state.scrollDown) {
      if (this.state.isLoadingMore) {
        const rawHeight = this.ul_content.scrollHeight - this.state.initialHeight;
        this.ul_content.scrollTop = rawHeight;
      } else {
        this.ul_content.scrollTop = this.ul_content.scrollHeight;
      }

      this.setState({
        scrollDown: false,
        isLoadingMore: false,
        initialHeight: 0,
      });
    }
    if (this.state.focusTextArea) {
      // this.textarea.focus();

      this.setState({
        focusTextArea: false,
      });
    }
    if (conversation.messages && conversation.messages.length > 0 && attentionedMessage) {
      const element = document.getElementById(`id_${attentionedMessage.objectId}`);
      if (element) {
        element.scrollIntoView();
        setTimeout(() => {
          this.props.setAttentionedMessage(null);
        }, 1000);
      }
    }
  }

  loadMoreMessage() {
    let startDate = moment().toDate();
    switch (this.props.activeThread.threadType) {
      case "private":
        if (this.props.conversation.messages.length > 0) {
          startDate = (this.props.conversation.messages[0].originalMessageDate || {}).iso;
        }

        if (!startDate) {
          startDate = this.props.conversation.messages[1].originalMessageDate.iso;
        }

        this.props.fetchPrivateMessages(
          this.props.activeThread.partnerObjectId,
          startDate,
          this.props.activeThread.threadId,
          true,
          (hasMore) => {
            this.setState({
              isLoadingMore: false,
            });

            if (!hasMore) {
              this.setState({ isEndOfConversation: true });
            }
          }
        );
        break;

      case "group":
        // threadId as groupObjectId since threadId is base on the groupObjectId
        if (this.props.conversation.messages.length > 0) {
          startDate = (this.props.conversation.messages[0].originalMessageDate || {}).iso;
        }
        if (!startDate) {
          startDate = this.props.conversation.messages[1].originalMessageDate.iso;
        }
        if (!this.props.activeThread.subgroupSession) {
          this.props.fetchGroupMessages(
            this.props.activeThread.threadId,
            startDate,
            this.props.activeThread.threadId,
            true,
            false,
            (hasMore) => {
              this.setState({
                isLoadingMore: false,
              });

              if (!hasMore) {
                this.setState({ isEndOfConversation: true });
              }
            }
          );
        } else {
          this.props.fetchSubgroupMessages(this.props.activeThread.threadId, startDate, true);
        }

        break;

      case "adhoc":
        if (this.props.conversation.messages.length > 0) {
          startDate = (this.props.conversation.messages[0].originalMessageDate || {}).iso;
        }
        if (!startDate) {
          startDate = this.props.conversation.messages[1].originalMessageDate.iso;
        }
        this.props.fetchAdhocMessages(this.props.activeThread.threadId, startDate, true);
        break;

      default:
        break;
    }
  }

  /**
   * Handle scrolling to top for fetching messages
   */
  handleScroll(event) {
    let initialHeight = event.target.scrollHeight;
    let scrollTop = event.target.scrollTop;
    if (scrollTop === 0) {
      if (this.props.isLoading) return;
      if (this.ul_content.clientHeight >= this.ul_content.scrollHeight) return; // Check if there is no scroll so that it will not load more messages
      this.setState({
        isLoadingMore: true,
        initialHeight: this.ul_content.scrollHeight,
      });

      this.props.setAttentionedMessage(null);
      this.loadMoreMessage();
    }
  }

  textAreaResize(e) {
    let el2 = $(this.refs.imagePreviewContainer);
    let contentHeight2 = el2.css("bottom");

    let getValue2 = parseInt(contentHeight2);
    let bottom2 = getValue2 + 22;
    if (this.textarea.scrollHeight < 41) {
      bottom2 = 1;
    }
    // this.content.setAttribute("style", "bottom:"+contentHeight+"px");
    // $(this.content).animate({ scrollTop: this.content.scrollHeight }, '1500');
    // contentHeight = contentHeight - 80;
    // let getValue = parseInt(contentHeight);
    // getValue = getValue + 40;
    // $(this.textarea).css( "bottom", "-"+contentHeight+"px");
    el2.css("bottom", bottom2 + "px");
  }

  handleKeyPress(e) {
    if (e.shiftKey) {
      return;
    }

    if (e.charCode === 13) {
      // e.preventDefault(); // Ensure it is only this code that run
      // const text = this.textarea.value;
      // this.textarea.value = "";
      // this.setState({
      //     hasTextInTextArea : false
      // })
      // autosize.update(this.textarea);
      // if (this.state.files.length === 0) {
      //     if (text === "") {
      //         return;
      //     }
      //     if (this.isEmpty(text)) {
      //         return;
      //     }
      // }
      // this.createNewMessage(text)
    }
  }

  sendMessageButton() {
    const { activeThread } = this.props;
    const isPatientofPatient =
      activeThread.threadType === "group"
        ? // && activeThread.groupType === "patient"
          true
        : false;
    const text = this.textarea.value;
    // this.textarea.value = "";

    // this.setState({
    //   hasTextInTextArea: false,
    // });
    autosize.update(this.textarea);
    if (this.state.files.length === 0) {
      if (text === "") {
        return;
      }

      if (this.isEmpty(text)) {
        return;
      }
    }
    if (isPatientofPatient && this.state.assignAttention.length === 0) {
      this.setState({ isAttentionNotify: true, assignMesage: text });
    } else {
      this.textarea.value = "";

      this.setState({
        hasTextInTextArea: false,
      });
      this.createNewMessage(text);
    }
  }

  sendMessageFromAssignButton(actionItem) {
    const text = actionItem
      ? `New task(s) are assigned to you on action items. \n\nNote: ${actionItem.text}`
      : this.state.assignMesage;

    // this.setState({
    //   hasTextInTextArea: false,
    //   assignMesage: "",
    // });

    autosize.update(this.textarea);
    if (this.state.files.length === 0) {
      if (text === "") {
        return;
      }

      if (this.isEmpty(text)) {
        return;
      }
    }
    this.textarea.value = "";
    this.createNewMessage(text, actionItem);
  }

  sendQuickMessage(text) {
    this.textarea.value = "";

    this.setState({
      hasTextInTextArea: false,
    });
    autosize.update(this.textarea);

    this.createNewMessage(text);
  }

  postOnConversation(text) {
    this.createNewMessage(text);
  }

  isTyping(e) {
    if (e.target.value !== "") {
      this.setState({
        hasTextInTextArea: true,
      });
    } else {
      this.setState({
        hasTextInTextArea: false,
      });
    }
  }

  isClicked() {
    this.props.fetchUnreadCount();
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  handleResendMessage(message) {
    const resend = true;
    this.props.sendNewMessage(message, this.props.activeThread, resend);
  }

  async createNewMessage(composeMessage = "", actionItem = {}) {
    const { replyTo } = this.state;

    const thread = this.props.activeThread;

    if (this.state.files.length === 0) {
      if (this.isEmpty(thread)) return;
    }

    const date = new Date();
    let newMessage = {
      text: composeMessage.trim(),
      originalMessageDate: date,
      user: {
        __type: "Pointer",
        className: "_User",
        objectId: Parse.User.current().id,
      },
      objectId: randomID(),
      threadType: thread.threadType,
      threadId: thread.threadId,
      newMessageStatus: "sending",
      createdAt: date,
      attention: this.state.assignAttention,
      urgent: this.state.isUrgent,
      attentionName: this.state.assignAttentionName,
      actionItem: actionItem.objectId,
      priorityLevel: this.state.selectedPriority,
    };

    if (replyTo) {
      newMessage.replyTo = {
        ...replyTo,
      };
    }

    this.cancelReply();

    switch (thread.threadType) {
      case "private":
        newMessage.contact = {
          __type: "Pointer",
          className: "_User",
          objectId: thread.partnerObjectId,
        };
        break;

      case "group":
        newMessage.circle = {
          __type: "Pointer",
          className: "Circle",
          objectId: thread.threadId,
        };
        break;

      case "adhoc":
        newMessage.adhocGroupId = thread.threadId;
        break;

      default:
        break;
    }

    let files = this.state.files;

    this.setState({
      assignAttention: [],
      assignAttentionName: [],
      openAttention: false,
      openPreview: false,
      files: [],
      isUrgent: false,
      selectedPriority: -1,
    });

    if (files.length === 0) {
      this.props.sendNewMessage(newMessage, thread);
      return;
    } else if (files.length === 1) {
      const myFile = files[0];

      let newMessageTemp = {
        ...newMessage,
        objectId: randomID(),
        file: myFile,
        fileIndex: 0,
        fileInfo: {
          filename: myFile.name,
          size: myFile.size,
          filetype: myFile.type,
          preview: myFile.preview,
          totalDuration: this.state.totalDuration,
        },
      };

      // 1.) Check whether video file (mime type)
      // 2.) Get thumbnail from element
      // 3.) Include thumbnail to newMessageTemp
      if (util.detectType(myFile.type) === "video") {
        const videoThumb = await util.generateVideoThumbnail(myFile);

        newMessageTemp.fileInfo.preview = videoThumb;
      }

      this.props.sendNewMessage(newMessageTemp, thread);
      return;
    } else {
      let newDate;

      if (newMessage.text !== "") {
        this.props.sendNewMessage(newMessage, thread);
      }

      for (let x = 0; x < files.length; x++) {
        const myFile = files[x];

        newDate = new Date();

        let newMessageTemp = {
          ...newMessage,
          objectId: randomID(),
          file: files[x],
          fileIndex: x,
          text: "",
          fileInfo: {
            filename: files[x].name,
            size: files[x].size,
            filetype: files[x].type,
            preview: files[x].preview,
          },
        };

        // 1.) Check whether video file (mime type)
        // 2.) Get thumbnail from element
        // 3.) Include thumbnail to newMessageTemp
        if (util.detectType(myFile.type) === "video") {
          const videoThumb = await util.generateVideoThumbnail(myFile);

          newMessageTemp.fileInfo.preview = videoThumb;
        }
        this.props.sendNewMessage(newMessageTemp, thread);
      }
      return;
    }
  }

  uploadAudioFile(file, totalDuration) {
    this.setState({
      files: [...this.state.files, file],
      totalDuration: totalDuration,
      // openPreview : true
    });
    setTimeout(() => {
      this.createNewMessage();
    }, 1000);
  }

  sendCapturedPhoto(uri) {
    let file = {
      name: "capture-photo",
      type: "base64/image",
      preview: uri,
      size: 0,
      uri: uri,
    };
    this.setState({
      files: [file],
    });
    setTimeout(() => {
      this.createNewMessage();
    }, 1000);
  }

  onDrop(files) {
    if (!this.props.activeThread.threadId) {
      return;
    }

    let tempFiles = [...this.state.files];

    let newFile = files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    if (newFile === 1) {
      tempFiles = [...tempFiles, newFile];
    } else {
      tempFiles = [...tempFiles, ...newFile];
    }

    this.setState({
      files: tempFiles,
      openPreview: true,
    });
  }

  openLightbox(url, conversationId) {
    const { conversation = {} } = this.props;
    const { files = [] } = conversation;

    let fileIndex = files.findIndex((f) => {
      return f.conversationId === conversationId;
    });
    if (fileIndex != -1) {
      fileIndex += 1;
    } else {
      console.error(`Cannot find image with conversationId: ${conversationId}`);
      fileIndex = 1;
    }

    this.setState({
      currentIndex: fileIndex,
      openLightbox: true,
      currentURL: url,
    });
  }

  handleClickMMS(url) {
    this.setState({
      mmsUrl: url,
      mmsPreviewShown: true,
    });
  }

  handleCloseMMS() {
    this.setState({
      mmsPreviewShown: false,
    });
  }

  openPreview() {
    this.setState({
      openPreview: true,
    });
  }

  handleItemClick() {
    console.log("clicked");
  }

  closeModal() {
    this.setState({
      openPreview: false,
      openLightbox: false,
      openForwardModal: false,
      openForwardToCCMModal: false,
      openForwardFilter: false,
      openReminder: false
    });
  }

  closeMFModal() {
    this.setState({
      openPreview: false,
      openLightbox: false,
      openForwardMultipleModal: false,
    });
  }

  closeReplySMSModal() {
    this.setState({
      openReplySMSModal: false,
    });
  }

  selectedEmoji(emoji) {
    const text = this.textarea.value;
    this.textarea.value = text + emoji.native;

    this.setState({
      hasTextInTextArea: true,
    });
  }

  openAttention() {
    this.setState((prevProps) => ({
      openAttention: !prevProps.openAttention,
    }));
  }

  closeAttention() {
    this.setState({
      openAttention: false,
    });
  }

  closeDeleteModal() {
    this.setState({
      openDeleteModal: false,
      openDeleteForEveryone: false,
    });
  }

  openForwardFilterModal() {
    this.setState({
      openForwardModal: true,
      // toBeForward: message,
    });
  }

  openForward(data, message, filter) {
    this.setState({
      toBeForward: message,
      forwardBy: filter,
      openForwardFilter: true,
    });
  }

  openForwardMultiple() {
    this.setState({
      openForwardMultipleModal: true,
    });
  }

  openSelectMultiple(message, messages, isChecked) {
    this.props.openCloseCheckBoxes(true);
    this.props.checkUncheckMessages(messages, message, isChecked);
  }

  openForwardToCCMModal(message) {
    this.setState({
      openForwardToCCMModal: true,
      toBeForward: message,
    });
  }

  openFile = () => {
    if (this.dropzoneRef) {
      this.dropzoneRef.open();
    }
  };

  toggleSelectedAttention(user, isSelected) {
    const userPointer = {
      __type: "Pointer",
      className: "_User",
      objectId: user.objectId,
      firstName: user.firstName,
      lastName: user.lastName,
      displayName: user.displayName,
    };

    if (isSelected) {
      let newAssignAttention = [
        ...this.state.assignAttention.filter((data) => {
          return data.objectId !== user.objectId;
        }),
      ];
      let newAssignAttentionName = [
        ...this.state.assignAttentionName.filter((data) => {
          return data !== user.firstName;
        }),
      ];

      this.setState({
        hasTextInTextArea: this.textarea.value ? true : false,
        assignAttention: newAssignAttention,
        assignAttentionName: newAssignAttentionName,
      });
    } else {
      let newAssignAttention = [...this.state.assignAttention, userPointer];
      let newAssignAttentionName = [...this.state.assignAttentionName, user.firstName];

      this.setState({
        hasTextInTextArea: this.textarea.value ? true : false,
        assignAttention: newAssignAttention,
        assignAttentionName: newAssignAttentionName,
      });
    }
  }

  toggleUrgent() {
    this.setState((prevProps) => ({
      isUrgent: !prevProps.isUrgent,
    }));
  }

  toBeDelete(message) {
    this.setState({
      toBeDelete: message,
      openDeleteModal: true,
    });
  }

  toBeDeleteForEveryone(message) {
    this.setState({
      toBeDeleteForEveryone: message,
      openDeleteForEveryone: true,
    });
  }

  confirmDelete() {
    let toBeDelete = { ...this.state.toBeDelete };

    this.setState({
      toBeDelete: "",
      openDeleteModal: false,
    });

    this.props.archivedMessage(toBeDelete);
  }

  confirmDeleteForEveryone() {
    let toBeDeleteForEveryone = { ...this.state.toBeDeleteForEveryone };

    this.setState({
      toBeDeleteForEveryone: "",
      openDeleteForEveryone: false,
    });

    // delete message here
    this.props.deleteMessage(toBeDeleteForEveryone);
  }

  initReply(message) {
    this.textarea.focus();
    const userId = Parse.User.current().id;
    const attentionTo = message.user.objectId === userId ? [] : [message.user];
    const replyTo = this.createReplyTo(message);

    this.setState({
      replyTo,
      assignAttention: attentionTo,
    });
  }

  cancelReply() {
    /*
            When changing the message to reply to, or even when it is removed,
            focus on textarea
        */
    this.textarea.focus();
    this.setState({
      replyTo: null,
    });
  }

  createReplyTo(message) {
    const attachment = this.getMessageAttachment(message) || {};

    return {
      text: message.text,
      sender: message.user.firstName || message.user.lastName,
      senderId: message.user.objectId,
      thumbUrl: attachment.thumbUrl,
      fileType: attachment.fileType,
      fileUrl: attachment.fileUrl,
      originalMessageDate: message.originalMessageDate,
      messageId: message.objectId,
    };
  }

  getMessageAttachment(message) {
    const attachments = message.attachments;

    if (!attachments) return null;

    if (Array.isArray(attachments) && attachments.length) {
      const first = attachments[0];

      const thumbnail = first.thumbNail;
      const file = first.file;
      const fileType = first.fileType;

      console.log(first);

      return {
        thumbUrl: thumbnail ? thumbnail.url : "",
        fileUrl: file ? file.url : "",
        fileType,
      };
    }
  }

  removeSelectedImage(data, i) {
    let tempFiles = [...this.state.files];
    tempFiles.splice(i, 1);

    let showImagePreview = true;

    if (tempFiles.length === 0) {
      showImagePreview = false;
    }

    this.setState({
      files: tempFiles,
      openPreview: showImagePreview,
    });
  }

  seenBy(id) {
    const members = this.props.groupMembers;
    let seen = [];
    if (typeof members == "undefined") {
      return seen;
    }
    for (let x = 0; x < members.length; x++) {
      if (typeof members[x].user === "undefined") {
        continue;
      }

      if (members[x].user.objectId === Parse.User.current().id) {
        continue;
      }

      if (members[x].lastMessageRead === id) {
        seen.push(members[x].user);
      }
    }
    return seen;
  }

  createForwarderMessage(message) {
    this.props.createForwardedMessage(message);
    return;
  }

  unblockUser() {
    this.props.unblockContact(this.props.activeThread.partnerObjectId, this.props.activeThread.threadId);
  }

  getLinks() {
    const links = [
      {
        dropzoneRef: "encounter_notes",
        label: "Encounter Notes",
        imageSrc: require("../../assets/images/notepad.png"),
      },
      {
        dropzoneRef: "meds_rx",
        label: "Meds/Rx",
        imageSrc: require("../../assets/images/medicine.png"),
      },
      {
        dropzoneRef: "authorization",
        label: "Authorizations",
        imageSrc: require("../../assets/images/copyright.png"),
      },
      {
        dropzoneRef: "labs",
        label: "Labs",
        imageSrc: require("../../assets/images/laboratory.png"),
      },
      {
        dropzoneRef: "radiology",
        label: "Radiology/Imaging",
        imageSrc: require("../../assets/images/x-ray.png"),
      },
      {
        dropzoneRef: "pathology",
        label: "Pathology",
        imageSrc: require("../../assets/images/bacteria.png"),
      },
      {
        dropzoneRef: "consult_notes",
        label: "MD Specialist Notes",
        imageSrc: require("../../assets/images/notepad.png"),
      },
      {
        dropzoneRef: "hospital_records",
        label: "Hospital records",
        imageSrc: require("../../assets/images/medical-history.png"),
      },
      {
        dropzoneRef: "photos",
        label: "Photo Files",
        imageSrc: require("../../assets/images/photos.png"),
      },
      {
        dropzoneRef: "admin_docs",
        label: "Admin/Legal Docs",
        imageSrc: require("../../assets/images/case.png"),
      },
      {
        dropzoneRef: "other_files",
        label: "Other Files",
        imageSrc: require("../../assets/images/papers.png"),
      },
    ];

    return links;
  }

  handleSelectDocument(link) {
    const { conversation = {} } = this.props;
    const { messages = [] } = conversation;

    const files = conversation.files || [];

    const { currentIndex } = this.state;

    const file = files[currentIndex - 1];

    const supportedFileTypes = ["image", "pdf"];

    const message = messages.find((m) => {
      return m.objectId === file.conversationId;
    });

    const fileType = this.getFileType(message);

    if (file && message && supportedFileTypes.includes(fileType)) {
      this.categorizeDocument(message, link.dropzoneRef);
    }
  }

  getFileType(message) {
    if (message.video) {
      return "video";
    }
    // It might be a PDF, because...
    // becase why not? Hahaha
    else if (message.picture) {
      const { url } = message.picture;

      const fileExt = util.getFileExtension(url);

      const isPDF = fileExt === "pdf";

      return isPDF ? "pdf" : "image";
    } else {
      return "image";
    }
  }

  categorizeDocument(document, category) {
    this.props.saveDocument(document, category);
  }

  handleClose() {
    this.props.setEmojiShown(false);
  }

  handleReplySMS(message) {
    this.setState({
      toReplySMS: message,
      openReplySMSModal: true,
    });
  }

  setShowManageEventModal(bool, conf) {
    this.setState({
      selectedConference: conf,
      showManageEventModal: bool,
    });
  }

  setShowConfirmModal(bool, conf) {
    this.setState({
      selectedConference: conf,
      showConfirmModal: bool,
    });
  }

  handleClickUrgent(e) {
    const { isUrgent } = this.state;

    if (isUrgent) {
      this.setState({
        isUrgent: false,
        assignAttention: [],
      });
    } else {
      this.setState({
        urgentModalShown: true,
      });
    }
  }

  handleClickAlert(e) {
    const { assignAttention, isUrgent } = this.state;

    if (assignAttention.length && !isUrgent) {
      this.setState({
        isUrgent: false,
        assignAttention: [],
        openAttention: false,
      });
    } else {
      this.setState({
        alertModalShown: true,
      });
    }
  }

  onFocus() {
    const { activeThread } = this.props;

    if (activeThread.threadType != "group") return;

    this.setState({ openAttention: true }, () => {
      let chips = document.getElementById("scroll-chips");
      if (!chips) return;
      if (chips.scrollWidth > chips.clientWidth) {
        document.getElementById("arrow-right").style.display = "flex";
      }
    });
  }

  cancelAttention() {
    this.setState({
      isUrgent: false,
      assignAttention: [],
      openAttention: false,
    });
  }

  slideScroll(direction) {
    let container = document.getElementById("scroll-chips");
    let scrollCompleted = 0;
    let slideVar = setInterval(
      function() {
        if (direction == "left") {
          container.scrollLeft -= 350;
        } else {
          container.scrollLeft += 350;
        }
        scrollCompleted += 350;
        if (scrollCompleted >= 100) {
          window.clearInterval(slideVar);
        }
      }.bind(this)
    );
  }

  checkScroll() {
    const scroll = document.getElementById("scroll-chips");
    const scrollLeft = scroll.scrollLeft;
    if (scrollLeft > 0) {
      document.getElementById("arrow-left").style.display = "flex";
    } else {
      document.getElementById("arrow-left").style.display = "none";
    }

    if (scrollLeft >= scroll.scrollWidth - scroll.offsetWidth) {
      document.getElementById("arrow-right").style.display = "none";
    } else {
      document.getElementById("arrow-right").style.display = "flex";
    }
  }

  /* 
        Check whether a message has an attachment without file
        but is present from picture
    */
  hasAttachmentFileFromPicture(message) {
    if (!message.attachments || !message.attachments.length) return false;

    const first = message.attachments[0];

    // Missing file but with picture
    return !first.file && !!message.picture;
  }

  findMissingFile(attachments = [], picture = {}) {
    return [
      {
        ...attachments[0],
        file: {
          ...picture,
        },
      },
    ];
  }

  closeReplyPreview() {
    this.props.hideReplyPreview();
  }

  resetMessages(msgs) {
    this.props.openCloseCheckBoxes(false);
    this.props.resetSelectedMessages(msgs);
  }

  setGroupFilter(group) {
    this.setState({
      groupFilter: group,
    });
  }

  openReminder(msgs) {
    this.setState({
      openReminder: true,
      criticalMsg: msgs
    });
  }

  render() {
    let { conversation, activeThread, attentionedMessage = null, setAttentionedMessage } = this.props;
    let { mobileCardsOpened } = this.state;
    let files = conversation.files || [];
    let changeBottom = {};
    let sendingAttention = false;
    let textUrgentOrAttention;
    let attentionStyleText = {
      fontSize: "14px",
      fontWeight: "500",
      color: "#478dc6",
    };

    const openthreadID = (cthread) => {
      this.setState({ criticalModalOpened: false });

      this.props.history.push({
        pathname: "/u/" + cthread.threadId,
      });
    };

    // Update action item status
    const handleActionUpdate = (threadId) => {
      let actionItems = conversation.messages;
      actionItems.map((item) => {
        if (item.actionItem) {
          if (item.actionItem.objectId === threadId) {
            return (item.actionItem.status = "DONE");
          }
        }
      });
    };

    const currentUser = Parse.User.current();
    let isLoading = false;
    if (conversation.initialLoad) {
      if (conversation.initialLoad.isFetching === true && conversation.initialLoad.fetchResult === "none") {
        isLoading = true;
      }
    }

    // Use to manually open dropzone
    let disableDropdown = true;
    if (activeThread.threadId) {
      disableDropdown = false;
    }

    if (this.state.assignAttention.length > 0) {
      if (typeof this.cardAction != "undefined") {
        let _tempHeight = this.cardAction.scrollHeight - 6;
        changeBottom = {
          bottom: _tempHeight + "px",
        };
      }
      sendingAttention = true;
    } else {
      changeBottom = { bottom: "40px" };
    }

    if (this.state.isUrgent) {
      textUrgentOrAttention = "Sent Urgent To: ";
      attentionStyleText.color = "#b71e53";
    } else {
      textUrgentOrAttention = "Sent Attention To: ";
    }

    let messages = [];
    if (conversation.messages) {
      messages = conversation.messages;
    }
    if (messages.length > 0 && attentionedMessage) {
      const data = messages.find((message) => message.objectId === attentionedMessage.objectId);
      if (!data) {
        const spliced = messages.splice(2, 0, attentionedMessage);
        messages = [...spliced];
      }
    } else {
      messages = conversation.messages || [];
    }

    const List = messages.map((data, i) => {
      if (typeof data.__TimeType !== "undefined") {
        return <TimeMessage key={`${i}_idx`} message={data} />;
      }

      if (this.hasAttachmentFileFromPicture(data)) {
        data.attachments = this.findMissingFile(data.attachments, data.picture);
      }

      /**
       * checking base on threadType
       */
      switch (activeThread.threadType) {
        case "private":
          if (!data.user) return null;
          break;

        case "group":
          break;

        default:
          break;
      }

      let SenderUser = data.user;
      if (!SenderUser) return;

      if (data.isSystemMessage) {
        return <SystemMessage key={i} message={data} />;
      }

      let continuesMessage = false;
      if (typeof data.user !== "undefined") {
        if (typeof messages[i + 1] !== "undefined") {
          if (typeof messages[i + 1].user !== "undefined") {
            if (data.user.objectId === messages[i + 1].user.objectId) {
              continuesMessage = true;
            }
          }
        }
      }

      const conversationId = data.objectId;

      const fromMe = SenderUser.objectId === currentUser.id;

      if (fromMe) {
        if (data.conference) {
          return (
            <FromConference
              openConfirmModal={(c) => this.setShowConfirmModal(true, c)}
              openModal={(c) => this.setShowManageEventModal(true, c)}
              message={data}
              conference={data.conference}
              fromMe={true}
            />
          );
        }

        return (
          <FromMe
            isCCM={activeThread.isCCM}
            seenBy={this.seenBy.bind(this, data.objectId)}
            openForward={this.openForward.bind(this, data)}
            openSelectMultiple={this.openSelectMultiple.bind(this, data, messages)}
            handleCheckUncheckMessages={(isChecked) => this.props.checkUncheckMessages(messages, data, isChecked)}
            isChecked={data.isChecked}
            showCheckBoxes={this.props.showCheckBoxes}
            toBeDeleteForEveryone={this.toBeDeleteForEveryone.bind(this, data)}
            toBeDelete={this.toBeDelete.bind(this, data)}
            openForwardToCCM={this.openForwardToCCMModal.bind(this, data)}
            key={`${data.messageUUID}_${data.dateTime}_${data.objectId}`}
            message={data}
            continuesMessage={continuesMessage}
            resendMessage={this.handleResendMessage.bind(this, data)}
            initReply={this.initReply.bind(this)}
            openLightbox={(url) => this.openLightbox(url, conversationId)}
            isDragging={this.props.isDragging}
            openActionsModal={this.handleOpenActionsModal}
            handleSetTaskAndMessage={this.handleSetTaskAndMessage}
            threadType={activeThread.threadType}
            openReminder={this.openReminder}
          />
        );
      } else {
        if (data.conference) {
          return (
            <FromConference
              openConfirmModal={(c) => this.setShowConfirmModal(true, c)}
              openModal={(c) => this.setShowManageEventModal(true, c)}
              message={data}
              conference={data.conference}
              fromMe={false}
            />
          );
        }

        return (
          <FromThem
            isCCM={activeThread.isCCM}
            seenBy={this.seenBy.bind(this, data.objectId)}
            openForward={this.openForward.bind(this, data)}
            openSelectMultiple={this.openSelectMultiple.bind(this, data, messages)}
            handleCheckUncheckMessages={(isChecked) => this.props.checkUncheckMessages(messages, data, isChecked)}
            showCheckBoxes={this.props.showCheckBoxes}
            toBeDelete={this.toBeDelete.bind(this, data)}
            openForwardToCCM={this.openForwardToCCMModal.bind(this, data)}
            key={`${data.messageUUID}_${data.dateTime}_${data.objectId}`}
            currentUser={currentUser}
            message={data}
            isChecked={data.isChecked}
            isSelected={data.isSelected}
            fromSMS={!!data.smsFrom}
            onReplySMS={() => this.handleReplySMS(data)}
            continuesMessage={continuesMessage}
            initReply={this.initReply.bind(this)}
            openLightbox={(url) => this.openLightbox(url, conversationId)}
            onClickMMS={(url) => this.handleClickMMS(url)}
            isDragging={this.props.isDragging}
            openActionsModal={this.handleOpenActionsModal}
            handleSetTaskAndMessage={this.handleSetTaskAndMessage}
            threadType={activeThread.threadType}
            openReminder={this.openReminder}
          />
        );
      }
    });

    const dropdownBaseStyle = {
      height: "100%",
      zIndex: 0,
      outline: "none",
      overflowY: "auto",
      position: "relative",
    };
    const activeStyle = {
      display: "block",
      border: "2px dashed green",
    };

    let wholeMembers = this.props.groupMembers.regMembers;
    const guestMembers = this.props.groupMembers.guests || [];

    if (this.props.groupMembers.orgMembers.length !== 0) {
      wholeMembers = wholeMembers.concat(this.props.groupMembers.orgMembers);
    }

    let tempMembers = [];
    if (wholeMembers.length !== 0) {
      tempMembers = Array.from(wholeMembers.reduce((m, t) => m.set(t.objectId, t), new Map()).values());
    }
    let allMembers = [...tempMembers, ...guestMembers];
    let allMembersSorted = allMembers.sort((a, b) => b.isAvailable - a.isAvailable);

    let membersAvailableSorted = allMembersSorted
      .filter((item) => item.isAvailable)
      .sort((a, b) => new Date((b.lastDateActive || {}).iso) - new Date((a.lastDateActive || {}).iso));

    let membersNotAvailableSorted = allMembersSorted
      .filter((item) => !item.isAvailable)
      .sort((a, b) => new Date((b.lastDateActive || {}).iso) - new Date((a.lastDateActive || {}).iso));

    let combinedMembers = [...membersAvailableSorted, ...membersNotAvailableSorted];

    let Chips = combinedMembers.map((member, i) => {
      if (member.objectId === Parse.User.current().id) {
        return;
      }
      let isSelected = this.state.assignAttention.find((c) => c.objectId === member.objectId);
      // let isSelected = this.state.assignAttentionName.includes(member.firstName);
      return (
        <Chip
          key={i}
          isUrgent={this.state.isUrgent}
          isAvailable={member.isAvailable}
          member={member}
          isSelected={isSelected}
          toggleSelectedAttention={this.toggleSelectedAttention.bind(this, member, isSelected)}
        />
      );
    });

    let urgetStyle = {
      cursor: "pointer",
    };
    if (this.state.isUrgent) {
      urgetStyle.color = "#b71e53";
    }

    let isBlocked = false;
    if (conversation.blocked) {
      isBlocked = conversation.blocked.userIsBlocked || conversation.blocked.userHasBlocked;
    }

    let textAreaContainerStyle = {
      display: "flex",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    };
    let hide = false;

    if (isBlocked || isLoading) {
      textAreaContainerStyle.display = "none";
      hide = true;
    }

    const hasActiveThread = !this.isEmpty(activeThread);

    const {
      mmsUrl,
      mmsPreviewShown,
      isUrgent,
      urgentModalShown,
      assignAttention,
      alertModalShown,
      replyTo,
      selectedPriority = -1,
    } = this.state;
    const isPrivate = activeThread.threadType === "private" ? true : false;
    const isPatient = activeThread.threadType === "group" && activeThread.groupType === "patient" ? true : false;

    const isPatientOrGroup = activeThread.threadType === "group" ? true : false;

    return (
      <div className="Conversation-Container">
        <div className="Conversation-Body">
          {/* {!isPrivate && window.innerWidth > 1154 && (
            // <div className="side-tab-mobile-close-container">
            <div
              className="side-tab-mobile-close new-cards-cls"
              onClick={() => this.setState({ mobileCardsOpened: !mobileCardsOpened })}
            >
              {!mobileCardsOpened && <i className="fas fa-layer-group" />}
              {mobileCardsOpened && <i className="fal fa-times fa-md" style={{ fontWeight: "bold" }} />}
              &nbsp;&nbsp;{`${mobileCardsOpened ? "Hide" : "Show"} Cards`}
            </div>
            // </div>
          )} */}
          {isLoading && <LoadingSpinner />}
          <Dropzone
            onDrop={this.onDrop.bind(this)}
            ref={(ref) => {
              this.dropzoneRef = ref;
            }}
            disableClick={true}
            multiple={true}
            disabled={disableDropdown}
          >
            {({ getRootProps, getInputProps, isDragActive, open }) => {
              let styles = { ...dropdownBaseStyle };
              styles = isDragActive ? { ...styles, ...activeStyle } : styles;

              return (
                <div {...getRootProps()} style={styles}>
                  <input {...getInputProps()} accept="image/*,.pdf,.heic,video/mp4,video/quicktime" />
                  <ul className="Bubble-Container" ref={(ul) => (this.ul_content = ul)}>
                    {((messages.length == 2 && !this.state.isLoadingMore) || this.state.isEndOfConversation) && (
                      <li className="Bubble-List-Container">
                        <span className="text-convo-header">{`Start of ${
                          this.props.activeThread.groupType === "patient" ? "HUB" : "conversation"
                        }`}</span>
                      </li>
                    )}
                    {this.state.isLoadingMore && (
                      <li className="Bubble-List-Container">
                        <LoadingSpinner />
                      </li>
                    )}
                    {List}
                  </ul>
                </div>
              );
            }}
          </Dropzone>

          {isBlocked && (
            <div
              style={{
                borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                height: "100%",
                textAlign: "center",
              }}
            >
              {conversation.blocked.userHasBlocked && (
                <div style={{ paddingTop: "25px", color: "#0000008f" }}>
                  You blocked message from this person{" "}
                  <a onClick={this.unblockUser.bind(this)} href="#!">
                    Unblock now
                  </a>
                </div>
              )}
              {conversation.blocked.userIsBlocked && (
                <div style={{ paddingTop: "25px", color: "#0000008f" }}>
                  You can't send message to this person at the moment
                </div>
              )}
            </div>
          )}

          {this.props.emojiShown ? (
            <div style={{ position: "relative" }}>
              <Picker
                set="google"
                showPreview={false}
                onSelect={(emoji) => this.selectedEmoji(emoji)}
                style={{ position: "absolute", bottom: "3px", left: "3px" }}
              />
            </div>
          ) : (
            ""
          )}

          {activeThread.threadType === "group" && (this.state.openAttention || assignAttention.length) ? (
            <div ref="attentionContainer" className="Attention-Container">
              <div style={{ position: "relative", width: "100%", height: "100%" }}>
                <div className="arrow-keys-container">
                  <div id="arrow-left" className="arrow-key-left" onClick={() => this.slideScroll("left")}>
                    <i className="fas fa-chevron-left" />
                  </div>
                  <div style={{ width: "20px" }} />
                  <div id="arrow-right" className="arrow-key-right" onClick={() => this.slideScroll("right")}>
                    <i className="fas fa-chevron-right" />
                  </div>
                </div>
                <div id="scroll-chips" class="Scroll-Chips" onScroll={this.checkScroll.bind(this)}>
                  {Chips}
                </div>
                <OverlayLoader isLoading={this.props.isLoadingMembers} />
              </div>
              <button
                className="btn btn-link text-decoration-none text-danger p-0"
                onClick={(e) => this.cancelAttention(e)}
                style={{ margin: "15px" }}
              >
                <i className="fas fa-times-circle"></i>
                {/* Cancel */}
              </button>
            </div>
          ) : (
            ""
          )}

          {this.state.openPreview && (
            <div ref="imagePreviewContainer" className="Image-Preview-Container position-relative">
              <div className="d-flex flex-row position-absolute">
                {this.state.files.map((data, i) => {
                  if (data.type === "application/pdf") {
                    let docuStyle = { height: "100px" };
                    // if (this.state.files.length === 1) docuStyle.top = "-20px";
                    return (
                      <div key={i} className="selected-image-container" style={docuStyle}>
                        <Document
                          file={data.preview}
                          loading={
                            <div>
                              <i className="fas fa-circle-notch fa-spin"></i> Loading PDF
                            </div>
                          }
                        >
                          <Page pageNumber={1} className="react-pdf__Page-canvas-container" />
                        </Document>
                        <img
                          onClick={() => this.removeSelectedImage(data, i)}
                          className="remove-selected-image"
                          src={require("../../assets/images/remove-image.png")}
                          alt="remove_image"
                        />
                      </div>
                    );
                  }
                  if (["video/mp4", "video/quicktime"].includes(data.type)) {
                    const videoWrapper = {
                      width: "100px",
                      height: "100px",
                      backgroundColor: "black",
                      borderRadius: "5px",
                    };

                    const videoStyles = {
                      maxHeight: "100%",
                      width: "100%",
                    };

                    return (
                      <div key={i} className="selected-image-container">
                        <div className="d-flex align-items-center" style={videoWrapper}>
                          <video src={data.preview} style={videoStyles}></video>
                        </div>
                        <img
                          onClick={() => this.removeSelectedImage(data, i)}
                          className="remove-selected-image"
                          src={require("../../assets/images/remove-image.png")}
                          alt="remove_image"
                        />
                      </div>
                    );
                  }
                  if (["image/heic"].includes(data.type)) {
                    const videoWrapper = {
                      width: "100px",
                      height: "100px",
                      borderRadius: "5px",
                    };

                    return (
                      <div key={i} className="selected-image-container">
                        <div
                          className="d-flex justify-content-center align-items-center shadow-sm border"
                          style={videoWrapper}
                        >
                          <ImagePlaceholder type="image" />
                        </div>
                        <img
                          onClick={() => this.removeSelectedImage(data, i)}
                          className="remove-selected-image"
                          src={require("../../assets/images/remove-image.png")}
                          alt="remove_image"
                        />
                      </div>
                    );
                  }
                  return (
                    <div key={i} className="selected-image-container">
                      <img className="selected-image" src={data.preview} alt={data.name} />
                      <img
                        onClick={() => this.removeSelectedImage(data, i)}
                        className="remove-selected-image"
                        src={require("../../assets/images/remove-image.png")}
                        alt="remove_image"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {this.props.showCheckBoxes && (
            <div class="select-send">
              <div>
                <i
                  className="fas fa-times"
                  style={{ color: "#FFFFFF", marginRight: "20px" }}
                  onClick={() => this.resetMessages(messages)}
                />
                <span style={{ color: "#FFFFFF" }}>{`${this.props.selected_messages.length || "0"}`} selected</span>
              </div>
              <button
                className="btn btn-default custom-send"
                type="button"
                // onClick={() => this.openForwardMultiple()}
                onClick={() => this.openForward([], [], "forwardMultiple")}
              >
                <i className="fas fa-paper-plane"></i> Forward
              </button>
            </div>
          )}
          {activeThread.threadType === "private" &&
          conversation &&
          conversation.messages &&
          !conversation.messages.length &&
          hasActiveThread ? (
            <div className="row p-2 justify-content-end">
              <div className="col-12 col-md-auto">
                <button onClick={(e) => this.sendQuickMessage("👋")} className="btn btn-sm btn-outline-primary">
                  👋
                </button>
              </div>
              <div className="col-12 col-md-auto">
                <button onClick={(e) => this.sendQuickMessage("Hello")} className="btn btn-sm btn-outline-primary">
                  Hello
                </button>
              </div>
              <div className="col-12 col-md-auto">
                <button
                  onClick={(e) => this.sendQuickMessage("HI. I sent you a contact request")}
                  className="btn btn-sm btn-outline-primary"
                >
                  HI. I sent you a contact request
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
          {hasActiveThread ? (
            <div style={textAreaContainerStyle}>
              {/* {activeThread.threadType === "group" ? (
                <div
                  className="d-flex flex-column m-1"
                  style={{
                    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                    paddingRight: "5px"
                  }}
                >
                  <AlertButton
                    active={assignAttention.length && !isUrgent}
                    onClick={this.handleClickAlert.bind(this)}
                    className="mb-1"
                  />
                  <UrgentButton
                    active={isUrgent}
                    onClick={this.handleClickUrgent.bind(this)}
                  />
                </div>
              ) : (
                ""
              )} */}
              <div className="d-flex flex-column w-100 my-2 mr-2 ml-1">
                {replyTo ? (
                  <div
                    className="px-2 py-1 mb-2 rounded"
                    style={{
                      fontSize: "12px",
                      position: "relative",
                      minWidth: "0",
                      backgroundColor: "#F2F4FF",
                      display: "grid",
                      gridTemplateColumns: "1fr auto",
                    }}
                  >
                    <ReplyTo replyTo={replyTo} />
                    <button
                      type="button"
                      class="btn btn-link text-danger p-0 ml-auto"
                      onClick={(e) => this.cancelReply()}
                    >
                      <i className="fas fa-times-circle fa-rotate-180 mr-1"></i>
                    </button>
                  </div>
                ) : (
                  ""
                )}
                <textarea
                  onFocus={(e) => this.onFocus(e)}
                  className="textarea-style mb-1"
                  ref={(ta) => (this.textarea = ta)}
                  onKeyPress={this.handleKeyPress.bind(this)}
                  onChange={this.isTyping.bind(this)}
                  onClick={this.isClicked.bind(this)}
                  placeholder={!replyTo ? "Click @ or type your message here..." : "Type your reply..."}
                  rows={1}
                  defaultValue=""
                />
                {this.isEmpty(activeThread) ? null : (
                  <ConversationMenu
                    recent={activeThread}
                    handleClick={this.handleItemClick.bind(this)}
                    activeSend={this.state.hasTextInTextArea}
                    handleSendButton={this.sendMessageButton}
                    postOnConversation={this.postOnConversation}
                    selectedEmoji={this.selectedEmoji.bind(this)}
                    closeAttention={this.closeAttention.bind(this)}
                    openAttention={this.openAttention.bind(this)}
                    attentionIsOpen={this.state.openAttention}
                    activeThread={this.props.activeThread}
                    openCallPortal={this.toggleWindowPortal}
                    openFile={this.openFile.bind(this)}
                    target={this.textarea}
                    hide={hide}
                    installationId={this.props.installationId}
                    uploadAudioFile={this.uploadAudioFile.bind(this)}
                    sendCapturedPhoto={this.sendCapturedPhoto.bind(this)}
                    guests={guestMembers}
                    options={tempMembers}
                    members={wholeMembers}
                    // alert
                    AlertButton={AlertButton}
                    assignAttentionLength={assignAttention.length}
                    alertOnClick={this.handleClickAlert.bind(this)}
                    createTask={this.handleCreateTask}
                    sendAsUrgent={this.sendAsUrgent}
                    openActionsModal={this.handleOpenActionsModal}
                    openCriticalModal={this.handleOpenCriticalModal}
                    files={this.state.files}
                    // priority
                    selectedPriority={selectedPriority}
                    setPriority={this.setPriority}
                  />
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          <FilePreview
            onSelect={(link) => this.handleSelectDocument(link)}
            links={this.links}
            documentName="Document name"
            images={files}
            currentIndex={this.state.currentIndex}
            isOpen={this.state.openLightbox}
            modalClose={this.closeFilePreview.bind(this)}
            fromThread={true}
          />

          {this.props.replyModalShown ? (
            <ReplyPreviewModal
              fileType={this.props.replyTo.fileType}
              fileUrl={this.props.replyTo.fileUrl}
              isOpen={this.props.replyModalShown}
              onClose={this.closeReplyPreview.bind(this)}
            />
          ) : (
            ""
          )}

          {mmsPreviewShown ? (
            <MMSPreview mmsUrl={mmsUrl} isOpen={mmsPreviewShown} onClose={this.handleCloseMMS.bind(this)} />
          ) : (
            ""
          )}

          {this.state.showConfirmModal && (
            <ConfirmModal
              event={this.state.selectedConference}
              isOpen={this.state.showConfirmModal}
              onClose={() => this.setShowConfirmModal(false, {})}
              title="Confirm Schedule"
              message={"Are you sure you want to confirm this telemed schedule?"}
            />
          )}
          {this.state.showManageEventModal && (
            <ManageEventModal
              method="update"
              dataEvent={this.state.selectedConference}
              isOpen={this.state.showManageEventModal}
              onClose={() => this.setShowManageEventModal(false, {})}
            />
          )}
          {this.state.openDeleteModal && (
            <DeleteMessageModal
              confirmDelete={this.confirmDelete.bind(this)}
              isOpen={this.state.openDeleteModal}
              modalClose={this.closeDeleteModal.bind(this)}
            />
          )}
          {this.state.openDeleteForEveryone && (
            <DeleteForEveryoneModal
              confirmDeleteForEveryone={this.confirmDeleteForEveryone.bind(this)}
              isOpen={this.state.openDeleteForEveryone}
              modalClose={this.closeDeleteModal.bind(this)}
            />
          )}
          {this.state.openForwardFilter && (
            <ForwardFilterModal
              isOpen={this.state.openForwardFilter}
              onClose={this.closeModal}
              openForward={this.openForwardFilterModal.bind(this)}
              openForwardMultiple={this.openForwardMultiple.bind(this)}
              setGroupFilter={this.setGroupFilter.bind(this)}
              openNextModal={this.state.forwardBy}
            />
          )}
          {this.state.openForwardModal && (
            <ForwardModal
              isOpen={this.state.openForwardModal}
              onClose={this.closeModal}
              messages={[this.state.toBeForward]}
              history={this.props.history}
              groupFilter={this.state.groupFilter}
            />
          )}
          {this.state.openForwardMultipleModal ? (
            <ForwardModal
              isOpen={this.state.openForwardMultipleModal}
              onClose={this.closeMFModal.bind(this)}
              messages={this.props.selected_messages}
              resetSelectedMessages={() => this.resetMessages(messages)}
              history={this.props.history}
              groupFilter={this.state.groupFilter}
            />
          ) : (
            ""
          )}
          {this.state.openForwardToCCMModal && (
            <ForwardToCCMModal
              confirmAction={this.createForwarderMessage.bind(this, this.state.toBeForward)}
              isOpen={this.state.openForwardToCCMModal}
              modalClose={this.closeModal.bind(this)}
              message={this.state.toBeForward}
            />
          )}
          {this.state.openReplySMSModal && (
            <ReplySMSModal
              isOpen={this.state.openReplySMSModal}
              modalClose={this.closeReplySMSModal.bind(this)}
              message={this.state.toReplySMS}
            />
          )}
        </div>
        {!isPrivate && (
          <SideTab activeThread={activeThread} threadType={activeThread.threadType} isPatient={isPatient} />
        )}

        {urgentModalShown && (
          <UrgentModal
            guests={guestMembers}
            options={tempMembers}
            isOpen={urgentModalShown}
            onClose={this.handleUrgentClose}
            onSubmit={this.handleUrgentSubmit}
          />
        )}

        {alertModalShown && (
          <AlertModal
            guests={guestMembers}
            options={tempMembers}
            isOpen={alertModalShown}
            emptyAttentionFromHubOrGroup={isPatientOrGroup && this.state.assignAttention.length === 0 ? true : false}
            onClose={this.handleAlertClose}
            onSubmit={this.handleAlertSubmit}
            onSendAfterAssign={this.handleSendAfterAssign}
            threadId={this.props.activeThread.threadId}
            threadName={this.props.activeThread.groupName}
            // from conversation
            setAsUrgent={this.handleSetAsUrgent.bind(this)}
            isUrgent={this.state.isUrgent}
            setAsTask={this.handleSetAsTask.bind(this)}
            isTask={this.state.isTask}
            message={this.textarea && this.textarea.value ? this.textarea.value : this.state.assignMesage}
          />
        )}

        {this.state.isAttentionNotify && (
          <AttentionNotify
            continueWithoutAssignment={() => {
              this.sendMessageFromAssignButton();
              this.setState({ isAttentionNotify: false });
            }}
            closeNotification={() => this.setState({ isAttentionNotify: false })}
            assignMember={() => {
              this.setState({
                alertModalShown: true,
                isAttentionNotify: false,
              });
            }}
          />
        )}
        {this.state.actionsModalOpened && (
          <ActionsModal
            activeTab={""}
            thread={this.props.activeThread}
            threadId={this.props.activeThread.threadId}
            isOpen={this.state.actionsModalOpened}
            onClose={() => this.showHideActionItemsModal(false)}
            refreshData={() => this.refreshData(this.props.activeThread)}
            selectedActionItem={this.state.selectedActionItem}
            handleActionUpdate={handleActionUpdate}
          />
        )}
        {this.state.criticalModalOpened && (
          <CriticalModal
            activeTab={""}
            thread={this.props.activeThread}
            threadId={this.props.activeThread.threadId}
            isOpen={this.state.criticalModalOpened}
            onClose={() => this.showHideActionItemsModal(false)}
            openThread={openthreadID}
          />
        )}
        {this.state.openReminder && (
          <ReminderModal
            isOpen={this.state.openReminder}
            modalClose={this.closeModal.bind(this)}
            circleId={this.props.activeThread.threadId}
            criticalMsg={this.state.criticalMsg}
            members={wholeMembers}
          />
        )}
      </div>
    );
  }
}

const Chip = (props) => {
  var contact = props.member;
  let contactURL;

  const { isAvailable } = props;

  if (typeof contact.picture === "undefined") {
    contactURL = require("../../assets/images/default.png");
  } else {
    contactURL = contact.picture.url;
  }

  const styles = {
    cursor: "pointer",
    position: "relative",
  };

  const classNames = ["chips", props.isSelected ? (props.isUrgent ? "active-urgent" : "active") : ""].join(" ");

  return (
    <div onClick={() => props.toggleSelectedAttention()} className={classNames} style={styles}>
      <img src={contactURL} className="chip-img" alt={contact.displayName} />
      {isAvailable ? <span className="hc-online-badge bg-success"></span> : ""}
      {props.isSelected ? <i className="fas fa-at font-weight-bold at-icon"></i> : ""}
      <span>{contact.firstName}</span>
    </div>
  );
};

const mapStateToProps = (state) => ({
  draftMessages: state.thread.draftMessages,
  emojiShown: state.thread.emojiShown,
  showCheckBoxes: state.thread.showCheckBoxes,
  selected_messages: state.thread.selected_messages,
  isLoadingMembers: state.group.isLoadingMembers,
  replyModalShown: state.thread.replyModalShown,
  replyTo: state.thread.replyTo,
  threads: state.thread.threads,
  attentionedMessage: state.thread.attentionedMessage,
});

const UrgentButton = ({ active, onClick, className }) => {
  const classNames = useMemo(() => {
    return [
      "btn btn-sm btn-outline-danger",
      "d-flex align-items-center",
      "rounded-pill",
      className,
      active ? "active" : "",
    ].join(" ");
  }, [active]);

  const iconClass = useMemo(() => {
    return active ? "fas fa-times-circle mr-1" : "fas fa-exclamation-triangle mr-1";
  }, [active]);

  const tooltip = useMemo(() => {
    return active ? "Cancel urgent" : "Send as urgent message";
  }, [active]);

  return (
    <>
      <button data-tip={tooltip} className={classNames} type="button" onClick={(e) => onClick(e)}>
        <i className={iconClass}></i>
        <span className="font-weight-bold">Urgent</span>
      </button>
      <ReactTooltip effect="solid" />
    </>
  );
};

const AlertButton = ({ active, onClick, className, disabled }) => {
  const classNames = useMemo(() => {
    return [
      "btn btn-sm btn-outline-warning",
      "d-flex align-items-center",
      "rounded-pill",
      className,
      active ? "active" : "",
    ].join(" ");
  }, [active]);

  const iconClass = useMemo(() => {
    return active ? "fas fa-times-circle" : "fas fa-at";
  }, [active]);

  const tooltip = useMemo(() => {
    return active ? "Cancel alert" : "Send as alert message";
  }, [active]);

  return (
    <>
      <button
        data-tip={tooltip}
        className={classNames}
        type="button"
        onClick={(e) => onClick(e)}
        disabled={disabled}
        style={{
          marginTop: "4px",
          marginRight: "5px",
          height: "30px",
        }}
      >
        <i className={`fa-fw ${iconClass}`}></i>
        {/* <span className="font-weight-bold">Alert</span> */}
      </button>
      <ReactTooltip effect="solid" />
    </>
  );
};

const UrgentMembers = ({ users = [], isUrgent }) => {
  const names = useMemo(() => {
    return users.map((u) => "@" + u.firstNameLower).join(", ");
  }, [users]);

  const nameClass = useMemo(() => {
    return isUrgent ? "text-danger font-weight-bold text-capitalize" : "text-warning font-weight-bold text-capitalize";
  }, [isUrgent]);

  return (
    <div className="d-flex p-1">
      <label className="mb-0 mr-1 text-gray-800">
        <i className="fas fa-bell mx-1"></i>
        Notify:
      </label>
      <div className={nameClass}>{names}</div>
    </div>
  );
};

export default connect(mapStateToProps, {
  setDraftMessage,
  setDefaultDraftMessages,
  saveDocument,
  setEmojiShown,
  fetchUnreadCount,
  checkUncheckMessages,
  resetSelectedMessages,
  openCloseCheckBoxes,
  hideReplyPreview,
  createActionItems,
  setActiveThread,
  fetchThreads,
  setAttentionedMessage,
})(ConversationBody);
