import React from 'react';
import { OutPortal } from 'react-reverse-portal';

const SpeakerLayout = ({
  remoteParticipantNodes,
  localParticipantNode,
  remoteScreenSharer,
  pinnedParticipant,
  dominantSpeaker,
}) => {

  const firstParticipant = remoteParticipantNodes.length ?
    remoteParticipantNodes[0].participant : null;

  const currentSpeaker = remoteScreenSharer || pinnedParticipant ||
    dominantSpeaker || firstParticipant;

  // Find the speaker node
  const speakerNode = remoteParticipantNodes.find(
    ({participant}) => participant === currentSpeaker
  );
  
  // Remove the speaker node from viewers
  const viewerNodes = remoteParticipantNodes.filter(
    ({participant}) => participant != currentSpeaker
  );

  return (<>
    <div className="speaker-layout-container">
      <div className="speaker-container">
        <div className="video-item" style={{ height: '100%', maxHeight: 'unset' }}>
          { speakerNode ?
            <OutPortal node={speakerNode.node}></OutPortal> :
            <SpeakerPlaceholder />
          }
        </div>
      </div>
      <div className="viewers-container">
        <ViewerWrapper>
          <OutPortal node={localParticipantNode}/>
        </ViewerWrapper>
        {viewerNodes.map(({participant, node}) =>
          <ViewerWrapper key={participant.identity}>
            <OutPortal node={node} />
          </ViewerWrapper>
        )}
      </div>
    </div>
  </>);
}

const SpeakerPlaceholder = () => {
  return (
    <div
      className="d-flex flex-column justify-content-center text-center"
      style={{ background: '#343a40', width: '100%', height: '100%', color: 'white' }}>
      <h3>Waiting for participants...</h3>
    </div>
  )
}

const ViewerWrapper = (props) => {
  return (
    <div className="video-item viewer-wrapper">
      {props.children}
    </div>
  );
}

export default SpeakerLayout;
