import React from 'react';
import moment from 'moment';

import './index.css';

function ESignComponent({
  dateOfService = '',
  prescriber = {},
  status = '',
}) {

  const dateSeen = moment(dateOfService).format("lll");

  return (
    <div className="custom-form-group-container">
      <div className="custom-form-group" style={{ maxWidth: '200px', width: '100%' }}>
        <strong htmlFor="cptCode">Date of Service</strong>
        <div>
          <span>{dateSeen || '--'}</span>
        </div>
      </div>
      <div className="custom-form-group" style={{ maxWidth: '200px', width: '100%' }}>
        <strong htmlFor="cptCode">Electronically Signed</strong>
        <div>
          {prescriber.displayName && <span>{status === 'DONE' ? `${prescriber.displayName}, ${prescriber.title}` : `--`}</span>}
          {!prescriber.displayName && <span>{`--`}</span>}
        </div>
      </div>
    </div>
  );
}

export default ESignComponent;