import React, { Component } from 'react'
import moment from 'moment'

export class TimeMessage extends Component {

    beautifyTime(date) {
        let today = moment(new Date());
        let yesterday =  moment().subtract(1, 'day');
        let dateSend = moment(new Date(date));
        if (typeof date === "undefined") {
        	return;
        }


        // TODAY
        if(moment(dateSend).isSame(today, 'day')) {
            return moment(dateSend).format('LT');
        } else if(moment(dateSend).isSame(yesterday, 'day')) { // YESTERDAY
            return "Yesterday " + moment(dateSend).format('LT');
        } else if(moment(dateSend).isSame(today, 'week')) { // SAME WEEK
            return moment(dateSend).format('ddd LT').toUpperCase();
        } else if(moment(dateSend).isSame(today, 'month')) { // SAME MONTH
            return moment(new Date(date)).format('ddd, MMM D LT').toUpperCase();
        } else if(moment(dateSend).isSame(today, 'year')) { // SAME YEAR
            return moment(new Date(date)).format('MMM D, LT').toUpperCase();
        } else {
            return moment(new Date(date)).format('MMM D, YYYY LT');
        }


    }

    render() {
        const { message } = this.props;

        return (
            <li className="Bubble-List-Container">
				<div className="_time-message">
            	    {this.beautifyTime(message.dateTime)}
            	</div>
           	</li>
        )
    }
}

export default TimeMessage
