import Storage from 'web-storage-manager';

// Store result of createConferenceCall to local storage
// so it can be used when opening new window
function save(result = {}) {

    // true = encrypt
    Storage.setItem('createConferenceResult', result);
}

function load() {

    return Storage.getItem('createConferenceResult');
}

function clear() {

    Storage.removeItem('createConferenceResult');
}

function setEngaged(boolean) {
    Storage.setItem('isEngagedInConference', boolean);
}

function isEngaged() {
    Storage.getItem('isEngagedInConference');
}

function saveConferenceId(roomId) {

    Storage.setItem('conferenceId', roomId);
}

function loadConferenceId() {
    
    const item = Storage.getItem('conferenceId');
    
    debugger

    return item;
}

function clearConferenceId() {

    Storage.removeItem('conferenceId');
}

export default {
    save,
    load,
    clear,
    setEngaged,
    isEngaged,
    saveConferenceId,
    loadConferenceId,
    clearConferenceId,
}
