import {
  FETCH_CONTACTS,
  GET_CONTACTS,
  FILTER_CONTACTS,
  OTHER_USERS,
  CONTACT_PROFILE,
  CONTACT_PROFILE_LOADING,
  LOAD_MORE_CONTACTS,
  CONTACT_SEARCHING_STATUS,
  BLOCKED_USER,
  UPDATE_HAS_BLOCKED_USER,
  UPDATE_IS_BLOCKED_USER,
  IS_SEARCHING,
  SET_SELECTED_CONTACTS,
  RESET_SELECTED_CONTACTS,
} from "./types";
import Parse from "parse";
import sort from "fast-sort";
import { parseAction } from "./REST";
import config from "../config";
import util from "../helper/util";

export const removeContact = (contact) => (dispatch) => {
  const id = contact.objectId;

  const method = "post";

  const url = config.BASE_URL + "/parse/functions/removeContact:v2";

  const payload = {
    userId: id,
  };

  dispatch({
    type: "SET_LOADING",
    value: true,
  });

  return parseAction(method, url, payload)
    .then((response) => {
      dispatch({
        type: "SET_LOADING",
        value: false,
      });

      dispatch({
        type: "REMOVE_CONTACT_SUCCESS",
        contact,
      });

      return response;
    })
    .catch((error) => {
      // TODO handle error when fetching search contact
      console.log(error);

      dispatch({
        type: "SET_LOADING",
        value: false,
      });

      return Promise.reject(error);
    });
};

export const addContact = (contact) => async (dispatch) => {
  const id = contact.objectId;

  const method = "post";

  const url = config.BASE_URL + "/parse/functions/addToContacts:v2";

  const payload = {
    userId: id,
  };

  const headers = {
    "Content-Type": "application/json",
  };

  const userId = Parse.User.current().id;
  const threadId = util.getThreadId(userId, id);

  dispatch({
    type: BLOCKED_USER,
    blocked: {},
    contactStatus: "pending_request",
    threadId,
  });

  await parseAction(method, url, payload, headers);
};

export const acceptContact = (contact) => async (dispatch) => {
  const id = contact.objectId;

  const method = "post";

  const url = config.BASE_URL + "/parse/functions/acceptContact";

  const payload = {
    userId: id,
  };

  const headers = {
    "Content-Type": "application/json",
  };

  const userId = Parse.User.current().id;
  const contactId = contact.objectId;

  dispatch({
    type: "ACCEPT_CONTACT",
    userId,
    contactId,
  });

  dispatch({
    type: BLOCKED_USER,
    blocked: {},
    contactStatus: "accepted",
    threadId: util.getThreadId(userId, contactId),
  });

  return parseAction(method, url, payload, headers);
};

export const fetchContacts = (willLoadMore = false, page = 0) => async (dispatch) => {
  const url = config.BASE_URL + "/parse/functions/getContacts:v3";

  const headers = {
    "Content-Type": "application/json",
  };

  const payload = {
    page: page,
    pageSize: 10,
  };

  const res = await parseAction("post", url, payload, headers);

  if (willLoadMore) {
    const { rosters } = res.result;

    dispatch({
      type: LOAD_MORE_CONTACTS,
      payload: rosters,
    });
  } else {
    const { rosters } = res.result;

    // Filter out rosters without contacts
    const withContact = rosters.filter((r) => !!r.contact);

    dispatch({
      type: FETCH_CONTACTS,
      payload: withContact,
    });
  }

  return res.result.rosters.length;
};

export const fetchRequests = (willLoadMore = false, page = 0) => async (dispatch) => {
  const url = config.BASE_URL + "/parse/functions/getContactRequests";

  const headers = {
    "Content-Type": "application/json",
  };

  const payload = {
    page: page,
    pageSize: 10,
  };

  const res = await parseAction("post", url, payload, headers);

  dispatch({
    type: "FETCH_CONTACT_REQUESTS",
    rosters: res.result.rosters,
  });
};

export const searchContacts = (text, type) => (dispatch) => {
  const method = "post";
  const url = config.BASE_URL + "/parse/functions/searchUsersV2";
  const payload = {
    filter: text,
    filterType: type,
  };

  dispatch({
    type: IS_SEARCHING,
    value: true,
  });

  return parseAction(method, url, payload)
    .then((response) => {
      const { result } = response;

      const { rosters } = result;

      const contacts = rosters.filter((r) => !!r.contact).map((r) => r.contact);

      // Response contain all users,
      // must filter out contacts to generate list of notContacts
      const allUsers = result.users;

      const contactIds = contacts.map((c) => c.objectId);

      const notContacts = allUsers.filter((u) => !contactIds.includes(u.objectId));

      notContacts.forEach((c) => (c.isNotContact = true));

      dispatch({
        type: FILTER_CONTACTS,
        results: contacts,
        text: text,
      });

      dispatch({
        type: OTHER_USERS,
        otherUsers: notContacts,
        success: true,
      });

      dispatch({
        type: IS_SEARCHING,
        value: false,
      });

      return contacts;
    })
    .catch((error) => {
      // TODO handle error when fetching search contact
      dispatch({
        type: IS_SEARCHING,
        value: false,
      });

      return Promise.reject(error);
    });
};

export const otherUsers = (searchText) => (dispatch) => {
  new Promise((resolve, reject) => {
    var User = Parse.Object.extend("User");
    var query = new Parse.Query(User);

    query.fullText("displayName", searchText);
    query
      .find()
      .then(function(results) {
        let _results = JSON.stringify(results);
        _results = JSON.parse(_results);
        dispatch({
          type: OTHER_USERS,
          otherUsers: _results,
          success: true,
        });
      })
      .catch(function(error) {
        // There was an error.
        dispatch({
          type: OTHER_USERS,
          success: false,
        });
      });
  });
};

export const getContacts = () => (dispatch) => {
  dispatch({
    type: GET_CONTACTS,
  });
};

export const searchStatus = (isSearching = false) => (dispatch) => {
  dispatch({
    type: CONTACT_SEARCHING_STATUS,
    isSearching: isSearching,
  });
};

/**
 * Filter contact base on user search
 * @param {String} text
 */
export const filterContacts = (text) => (dispatch) => {
  dispatch({
    type: FILTER_CONTACTS,
    text: text,
  });
};

export const blockContact = (userObjectId, threadId) => async (dispatch) => {
  const method = "post";
  const url = config.BASE_URL + "/parse/functions/blockContact";
  const data = {
    userObjectId: userObjectId,
  };

  dispatch({
    type: BLOCKED_USER,
    blocked: {
      userHasBlocked: true,
    },
    threadId,
  });

  dispatch({
    type: UPDATE_HAS_BLOCKED_USER,
    hasBlock: true,
    threadId: threadId,
  });

  const res = await parseAction(method, url, data);

  return res.result;
};

// Block contact without threadId
export const blockContactNoThread = (contact) => (dispatch) => {
  const id = contact.objectId;

  const method = "post";
  const url = config.BASE_URL + "/parse/functions/blockContact";
  const data = {
    userObjectId: id,
  };

  dispatch({
    type: "SET_LOADING",
    value: true,
  });

  return parseAction(method, url, data)
    .then((res) => {
      dispatch({
        type: "SET_BLOCKED_CONTACT",
        value: true,
        contact,
      });

      dispatch({
        type: "SET_LOADING",
        value: false,
      });

      return res.result;
    })
    .catch((error) => {
      // TODO handle error when fetching search contact

      dispatch({
        type: "SET_LOADING",
        value: false,
      });

      return Promise.reject(error);
    });
};

export const unblockContact = (userObjectId, threadId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    parseAction("post", config.BASE_URL + "/parse/functions/unblockContact", {
      userObjectId: userObjectId,
    })
      .then((res) => {
        dispatch({
          type: UPDATE_HAS_BLOCKED_USER,
          hasBlock: false,
          threadId: threadId,
        });
        resolve(res.result);
      })
      .catch((error) => {
        // TODO handle error when fetching search contact
      });
  });
};

// Block contact without threadId
export const unblockContactNoThread = (contact) => (dispatch) => {
  const id = contact.objectId;

  const method = "post";
  const url = config.BASE_URL + "/parse/functions/unblockContact";
  const data = {
    userObjectId: id,
  };

  dispatch({
    type: "SET_LOADING",
    value: true,
  });

  return parseAction(method, url, data)
    .then((res) => {
      dispatch({
        type: "SET_BLOCKED_CONTACT",
        value: false,
        contact,
      });

      dispatch({
        type: "SET_LOADING",
        value: false,
      });

      return res.result;
    })
    .catch((error) => {
      // TODO handle error when fetching search contact

      dispatch({
        type: "SET_LOADING",
        value: false,
      });

      return Promise.reject(error);
    });
};

export const updateBlock = (userObjectId, blocked) => (dispatch) => {
  let idArray = [Parse.User.current().id, userObjectId];
  idArray = sort(idArray).asc((p) => p.toLowerCase());
  let tempThreadId = idArray.join("_");

  dispatch({
    type: UPDATE_IS_BLOCKED_USER,
    isBlock: blocked,
    threadId: tempThreadId,
  });
};

export const checkIfBlocked = (userObjectId, activeThread) => (dispatch) => {
  return new Promise((resolve, reject) => {
    parseAction("get", config.BASE_URL + "/parse/classes/Roster", {
      where: {
        $or: [
          {
            contact: {
              __type: "Pointer",
              className: "_User",
              objectId: Parse.User.current().id,
            },
            user: {
              __type: "Pointer",
              className: "_User",
              objectId: userObjectId,
            },
          },
          {
            contact: {
              __type: "Pointer",
              className: "_User",
              objectId: userObjectId,
            },
            user: {
              __type: "Pointer",
              className: "_User",
              objectId: Parse.User.current().id,
            },
          },
        ],
      },
    })
      .then((roster) => {
        let blocked = {
          userHasBlocked: false,
          userIsBlocked: false,
        };
        for (let x = 0; x < roster.results.length; x++) {
          if (roster.results[x].user.objectId === Parse.User.current().id) {
            if (roster.results[x].isBlocked) {
              blocked.userHasBlocked = true;
            }
          } else {
            if (roster.results[x].isBlocked) {
              blocked.userIsBlocked = true;
            }
          }
        }

        const rosters = roster.results;

        const contactStatus = rosters.find((r) => {
          return r.contact && r.contact.objectId === userObjectId;
        });

        dispatch({
          type: BLOCKED_USER,
          blocked: blocked,
          threadId: activeThread.threadId,
          isContact: Boolean(contactStatus),
          isPendingContact: contactStatus && contactStatus.isPending,
        });
        resolve({
          blocked,
          isContact: Boolean(contactStatus),
          isPendingContact: contactStatus && contactStatus.isPending,
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getContactStatus = (userObjectId, activeThread) => async (dispatch) => {
  const result = await Parse.Cloud.run("getContactStatus", {
    userId: userObjectId,
  });

  const { blocked, status } = result;
  // debugger;
  dispatch({
    type: BLOCKED_USER,
    blocked: {
      userHasBlocked: blocked,
    },
    contactStatus: status,
    threadId: activeThread.threadId,
  });

  return result;
};

export const getContactInformation = (contactId) => (dispatch) => {
  dispatch({
    type: CONTACT_PROFILE_LOADING,
    isLoadingContact: true,
  });

  parseAction("get", config.BASE_URL + `/parse/classes/_User/${contactId}`)
    .then((res) => {
      const contact = res;

      dispatch({
        type: CONTACT_PROFILE,
        contact_profile: contact,
      });

      dispatch({
        type: CONTACT_PROFILE_LOADING,
        isLoadingContact: false,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const checkUncheckContact = (contact, contactsObject, isChecked) => (dispatch) => {
  const {
    contacts = [],
    filterResult = [],
    otherUsersResult = [],
    selectedContacts = [],
    isSearchingContact,
  } = contactsObject;

  if (isSearchingContact) {
    const hasCheckedSearchedContacts = filterResult.map((item) => ({
      ...item,
      isChecked: contact.objectId === item.objectId ? isChecked : item.isChecked || false,
    }));
    const hasCheckedNonContacts = otherUsersResult.map((item) => ({
      ...item,
      isChecked: contact.objectId === item.objectId ? isChecked : item.isChecked || false,
    }));

    const hasCheckedContacts = contacts.map((item) => ({
      ...item,
      contact: {
        ...item.contact,
        isChecked: contact.objectId === item.contact.objectId ? isChecked : item.contact.isChecked || false,
      },
    }));
    const combinedSelectedContacts = mapSelectedContacts([
      ...hasCheckedSearchedContacts.filter((item) => item.isChecked),
      ...hasCheckedNonContacts.filter((item) => item.isChecked),
      ...hasCheckedContacts.filter((item) => (item.contact || {}).isChecked),
    ]);

    const selectedC = [
      ...Array.from(new Set(combinedSelectedContacts.map((a) => a.objectId))).map((id) =>
        combinedSelectedContacts.find((a) => a.objectId === id)
      ),
      ...selectedContacts,
    ];
    const newSelectedContacts = Array.from(new Set(selectedC.map((a) => a.objectId))).map((id) =>
      selectedC.find((a) => a.objectId === id)
    );
    const filteredSelectedContacts = !isChecked ? filterUnchecked(newSelectedContacts, contact) : newSelectedContacts;

    dispatch({
      type: SET_SELECTED_CONTACTS,
      selectedContacts: filteredSelectedContacts,
    });

    dispatch({
      type: FILTER_CONTACTS,
      results: hasCheckedSearchedContacts,
      text: "bypass",
    });
    dispatch({
      type: OTHER_USERS,
      otherUsers: hasCheckedNonContacts,
      success: true,
    });

    dispatch({
      type: FETCH_CONTACTS,
      payload: hasCheckedContacts,
    });
  } else {
    const hasCheckedContacts = contacts.map((item) => ({
      ...item,
      contact: {
        ...item.contact,
        isChecked: contact.objectId === item.contact.objectId ? isChecked : item.contact.isChecked || false,
      },
    }));

    const selectedC = hasCheckedContacts.filter((item) => item.contact.isChecked);
    const mappedSelectedContacts = mapSelectedContacts([...selectedC, ...selectedContacts]);
    const newSelectedContacts = Array.from(new Set(mappedSelectedContacts.map((a) => a.objectId))).map((id) =>
      mappedSelectedContacts.find((a) => a.objectId === id)
    );
    const filteredSelectedContacts = !isChecked ? filterUnchecked(newSelectedContacts, contact) : newSelectedContacts;

    dispatch({
      type: FETCH_CONTACTS,
      payload: hasCheckedContacts,
    });

    dispatch({
      type: SET_SELECTED_CONTACTS,
      selectedContacts: filteredSelectedContacts,
    });
  }
};

export const resetSelectedContacts = (contacts) => (dispatch) => {
  const newContacts = contacts.map((item) => ({
    ...item,
    contact: {
      ...item.contact,
      isChecked: false,
    },
  }));

  dispatch({
    type: RESET_SELECTED_CONTACTS,
    selectedContacts: [],
  });

  dispatch({
    type: FETCH_CONTACTS,
    payload: newContacts,
  });
};

export const removeSelectedContact = (contactId, contactsObject) => (dispatch) => {
  const { contacts = [], selectedContacts = [], filterResult = [], otherUsersResult = [] } = contactsObject;

  const newSelectedContacts = selectedContacts.filter((item) => item.objectId !== contactId);
  const newContacts = contacts.map((item) => ({
    ...item,
    contact: {
      ...item.contact,
      isChecked: contactId === (item.contact || {}).objectId ? false : (item.contact || {}).isChecked,
    },
  }));
  const newFilterResult = filterResult.map((item) => ({
    ...item,
    isChecked: item.objectId === contactId ? false : item.isChecked,
  }));
  const newOtherUsersResult = otherUsersResult.map((item) => ({
    ...item,
    isChecked: item.objectId === contactId ? false : item.isChecked,
  }));

  dispatch({
    type: SET_SELECTED_CONTACTS,
    selectedContacts: newSelectedContacts,
  });

  dispatch({
    type: FETCH_CONTACTS,
    payload: newContacts,
  });

  dispatch({
    type: FILTER_CONTACTS,
    results: newFilterResult,
    text: "bypass",
  });
  dispatch({
    type: OTHER_USERS,
    otherUsers: newOtherUsersResult,
    success: true,
  });
};

const mapSelectedContacts = (contacts = []) => {
  return contacts.map((item) => {
    if (item.contact) {
      return {
        objectId: item.contact.objectId,
        username: item.contact.username,
        displayName: item.contact.displayName,
        firstName: item.contact.firstName,
        imgUrl: (item.contact.picture || {}).url,
      };
    }
    return {
      objectId: item.objectId,
      username: item.username,
      displayName: item.displayName,
      firstName: item.firstName,
      imgUrl: item.picture ? (item.picture || {}).url : item.imgUrl,
    };
  });
};

const filterUnchecked = (contacts, contact) => {
  return contacts.filter((item) => item.objectId !== contact.objectId);
};
