import moment from "moment";
import { EditorState, convertFromRaw } from "draft-js";
import { removeExtraLines } from "../../../helper/util";

const handleArray = (arr = []) => {
  const strArr = arr.join(", ");
  return strArr ? strArr : "--";
};

const handleAssessmentsArray = (assessments = []) => {
  const array = assessments.filter(Boolean);
  let assess = "";

  for (let i = 0; i < array.length; i++) {
    if (array[i].problem) {
      assess =
        assess + `${i + 1}. ${array[i].problem} - ${array[i].assessmentPlan}\n`;
    } else if (typeof array[i] === "string" && array[i]) {
      assess = assess + `${i + 1}. ${array[i]}\n`;
    }
  }

  return assess
    ? `ASSESSMENTS
${assess}`
    : ``;
};

const handleFreeText = (rte = {}) => {
  const editor = EditorState.createWithContent(convertFromRaw(rte));
  return editor.getCurrentContent().getPlainText();
};

const renderVitalSigns = (vitalSigns = {}) => {
  let str = `VITAL SIGNS
${vitalSigns.bp ? `BP: ${vitalSigns.bp}` : ``}
${vitalSigns.hr ? `HR: ${vitalSigns.hr}` : ``}
${vitalSigns.br ? `RR: ${vitalSigns.br}` : ``}
${vitalSigns.temp ? `Temp: ${vitalSigns.temp}` : ``}
${vitalSigns.sat ? `02 sat: ${vitalSigns.sat}` : ``}
${vitalSigns.painScale ? `Pain Scale: ${vitalSigns.painScale}/10` : ``}
${vitalSigns.height ? `Height(in): ${vitalSigns.height}` : ``}
${vitalSigns.weight ? `Weight(lb): ${vitalSigns.weight}` : ``}
${vitalSigns.bmi ? `BMI: ${vitalSigns.bmi}` : ``}`;

  return str.replace(/^\s*$(?:\r\n?|\n)/gm, ``);
};

const renderPhysicalExam = (pe = {}) => {
  let str = `PHYSICAL EXAM
${
  pe.general
    ? `General: 
${pe.general}`
    : ``
}

${
  pe.head
    ? `Head: 
${pe.head}`
    : ``
}

${
  pe.eyes
    ? `Eyes: 
${pe.eyes}`
    : ``
}

${
  pe.ent
    ? `Ear, Nose, Mouth and Throat: 
${pe.ent}`
    : ``
}

${
  pe.neck
    ? `Neck: 
${pe.neck}`
    : ``
}

${
  pe.chestAndLungs
    ? `Chest and Lungs: 
${pe.chestAndLungs}`
    : ``
}

${
  pe.breast
    ? `Breast: 
${pe.breast}`
    : ``
}

${
  pe.genitourinary
    ? `Genitourinary: 
${pe.genitourinary}`
    : ``
}

${
  pe.rectal
    ? `Rectal: 
${pe.rectal}`
    : ``
}

${
  pe.upperExtrimities
    ? `Upper Extremities: 
${pe.upperExtrimities}`
    : ``
}

${
  pe.lowerExtrimities
    ? `Lower Extremities: 
${pe.lowerExtrimities}`
    : ``
}

${
  pe.skin
    ? `Skin: 
${pe.skin}`
    : ``
}

${
  pe.neurologic
    ? `Neurologic: 
${pe.neurologic}`
    : ``
}

${
  pe.psychiatrist
    ? `Psychiatric Eval: 
${pe.psychiatrist}`
    : ``
}`;

  return str;
};

const renderOrdersArray = (labs = {}) => {
  let str = `ORDERS
${
  labs.prescription
    ? `Prescription
${labs.prescription}`
    : ``
}

${
  labs.diagnosticStudies
    ? `Diagnostic Studies
${labs.diagnosticStudies}`
    : ``
}

${
  labs.referrals
    ? `Referrals/Consults
${labs.referrals}`
    : ``
}

${
  labs.medicalSupplies
    ? `Medical Supplies
${labs.medicalSupplies}`
    : ``
}`;

  if (!labs.prescription && !labs.diagnosticStudies && !labs.referrals) {
    return ``;
  } else {
    return str;
  }
};

const handleDefaultTemplate = (data, patient) => {
  return `PROGRESS NOTE

PATIENT: ${patient.name}
DOB: ${patient.dob}

DATE OF SERVICE
${moment(data.dateSeen).format("lll")}

CHIEF COMPLAINTS
${handleArray(data.chiefComplaints)}

${
  data.subjective
    ? `SUBJECTIVE
${data.subjective}`
    : ``
}

${renderVitalSigns(data.vitalSigns)}

${renderPhysicalExam(data.physicalExam)}

${handleAssessmentsArray(data.assesments)}

${
  data.medicalDecisionMaking
    ? `MEDICAL DECISION MAKING / DISCUSSION
${data.medicalDecisionMaking}`
    : ``
}

${
  data.addendumNote
    ? `ADDENDUM NOTE
${data.addendumNote}`
    : ``
}

${
  data.procedureNotes
    ? `PROCEDURE NOTES
${data.procedureNotes}`
    : ``
}

${
  data.followUps.date
    ? `FOLLOW UP DATE
${moment(data.followUps.date).format("l")}`
    : ``
}

${
  data.encounterType
    ? `ENCOUNTER TYPE
${data.encounterType}`
    : ``
}

${renderOrdersArray(data.labs)}

${
  data.cptCode
    ? `CPT CODE
${data.cptCode}`
    : ``
}`;
};

const handleSoapTemplate = (data, patient) => {
  return `PROGRESS NOTE

PATIENT: ${patient.name}
DOB: ${patient.dob}

DATE OF SERVICE
${moment(data.dateSeen).format("lll")}

CHIEF COMPLAINTS
${handleArray(data.chiefComplaints)}

${
  data.subjective
    ? `SUBJECTIVE
${data.subjective}`
    : ``
}

${renderVitalSigns(data.vitalSigns)}

${
  data.objective
    ? `OBJECTIVE
${data.objective}`
    : ``
}

${
  data.assessment
    ? `ASSESSMENT
${data.assessment}`
    : ``
}

${
  data.plan
    ? `PLAN
${data.plan}`
    : ``
}

${
  data.addendumNote
    ? `ADDENDUM NOTE
${data.addendumNote}`
    : ``
}

${
  data.procedureNotes
    ? `PROCEDURE NOTES
${data.procedureNotes}`
    : ``
}

${
  data.followUps.date
    ? `FOLLOW UP DATE
${moment(data.followUps.date).format("l")}`
    : ``
}

${
  data.encounterType
    ? `ENCOUNTER TYPE
${data.encounterType}`
    : ``
}

${renderOrdersArray(data.labs)}

${
  data.cptCode
    ? `CPT CODE
${data.cptCode}`
    : ``
}`;
};

const handleRteTemplate = (data, patient) => {
  return `PROGRESS NOTE 

PATIENT: ${patient.name}
DOB: ${patient.dob}

DATE OF SERVICE
${moment(data.dateSeen).format("lll")}

CHIEF COMPLAINTS
${handleArray(data.chiefComplaints)}

${handleFreeText(data.rte)}

${
  data.addendumNote
    ? `ADDENDUM NOTE
${data.addendumNote}`
    : ``
}

${
  data.procedureNotes
    ? `PROCEDURE NOTES
${data.procedureNotes}`
    : ``
}

${
  data.followUps.date
    ? `FOLLOW UP DATE
${moment(data.followUps.date).format("l")}`
    : ``
}

${
  data.encounterType
    ? `ENCOUNTER TYPE
${data.encounterType}`
    : ``
}

${renderOrdersArray(data.labs)}

${
  data.cptCode
    ? `CPT CODE
  ${data.cptCode}`
    : ``
}`;
};

const handleProblemTemplate = (data, patient) => {
  return `PROGRESS NOTE

PATIENT: ${patient.name}
DOB: ${patient.dob}

DATE OF SERVICE
${moment(data.dateSeen).format("lll")}

CHIEF COMPLAINTS
${handleArray(data.chiefComplaints)}

${handleAssessmentsArray(data.assesments)}

${handleFreeText(data.rte)}

${
  data.medicalDecisionMaking
    ? `MEDICAL DECISION MAKING / DISCUSSION
${data.medicalDecisionMaking}`
    : ``
}

${
  data.addendumNote
    ? `ADDENDUM NOTE
${data.addendumNote}`
    : ``
}

${
  data.procedureNotes
    ? `PROCEDURE NOTES
${data.procedureNotes}`
    : ``
}

${
  data.followUps.date
    ? `FOLLOW UP DATE
${moment(data.followUps.date).format("l")}`
    : ``
}

${
  data.encounterType
    ? `ENCOUNTER TYPE
${data.encounterType}`
    : ``
}

${renderOrdersArray(data.labs)}

${
  data.cptCode
    ? `CPT CODE
${data.cptCode}`
    : ``
}`;
};

const handleNoteObject = (data, patient) => {
  if (data.templateType === "default" || data.templateType === "virtual") {
    return removeExtraLines(handleDefaultTemplate(data, patient));
  } else if (data.templateType === "SOAP") {
    return removeExtraLines(handleSoapTemplate(data, patient));
  } else if (data.templateType === "RTE") {
    return removeExtraLines(handleRteTemplate(data, patient));
  } else if (data.templateType === "PROBLEM") {
    return removeExtraLines(handleProblemTemplate(data, patient));
  }
};

const setLinkFormat = (link, data, patient) => {
  const format = `${link}

${patient.name}
${
  patient.dob
    ? `DOB: 
${patient.dob}`
    : ``
}

${
  data.dateSeen
    ? `Date of Service: 
${moment(data.dateSeen).format("lll")}`
    : ``
}

${
  data.encounterType
    ? `Encounter Type: 
${data.encounterType}`
    : ``
}
${
  data.cptCode
    ? `CPT Code: 
${data.cptCode}`
    : ``
}
${
  data.posCode
    ? `POS: 
${data.posCode}`
    : ``
}`;

  return removeExtraLines(format);
};

const defaultTemplate = {
  assesments: [
    { problem: "", assessmentPlan: "" },
    { problem: "", assessmentPlan: "" },
    { problem: "", assessmentPlan: "" },
    { problem: "", assessmentPlan: "" },
  ],
  chiefComplaints: [],
  physicalExam: {
    general: "General:  Awake, Alert, & Oriented (AAO) x3",
    head: "Normocephalic/Atraumatic (NC/AT) , NO visible scars",
    eyes:
      "PERRL, EOM intact, no conjunctival injection, sclera non-icteric, no discharge, symmetrical eye lids",
    ent:
      "Atraumatic external nose and ears, NO rhinorrhea, NO ear discharge, hearing grossly intact",
    neck: "Trachea midline, NO thyromegaly,  NO masses",
    chestAndLungs:
      "Unlabored respiratory effort, NO accessory muscle use, Symmetrical chest expansion, Clear to auscultation bilaterally (CTAB), NO rales, NO wheezing",
    breast: "",
    cardiovascular: "No JVD, Regular rhythm, S1 normal, S2 normal, NO murmurs",
    abdomen:
      "Normactive bowel sounds (NABS), NON distended, Soft, NON tender, NO guarding, NO rebound tenderness",
    back: "",
    genitourinary: "",
    rectal: "",
    // musculoskeletal: 'Normal gait and station, Joints and limbs grossly normal',
    upperExtrimities:
      "NO gross deformity, NO clubbing, NO cyanosis, Radial pulses 2+",
    lowerExtrimities:
      "NO edema, N0 gangrene, NO cyanosis, Strong Dorsalis pedis pulses",
    skin: "Warm, Dry, NO rashes, NO petechia, NO hematomas",
    neurologic:
      "CNs II-XII grossly intact, Sensation grossly intact, No focal motor deficit, No nuchal rigidity",
    psychiatrist:
      "Awake, Alert, & Oriented (AAO) x3, Appropriate mood and affect, Cooperative, NO hallucinations",
  },
  subjective: "",
  vitalSigns: {
    bp: "",
    hr: "",
    br: "",
    temp: "",
    date: "",
    painScale: "0",
    height: "",
    weight: "",
    bmi: "",
    type: "",
    comments: "",
  },
  planIds: [],
  labs: {
    prescription: "",
    diagnosticStudies: "",
    referrals: "",
    medicalSupplies: "",
  },
  favorites: [],
  procedureNotes: "",
  medicalDecisionMaking: "",
  addendumNote: "",
  followUps: {},
  objective: "",
  plan: "",
  assessment: "",
  encounterType: "",
  cptCode: "",
  posCode: "",
  modifier: "",
  templateType: "default",
};

const defaultVirtualTemplate = {
  ...defaultTemplate,
  physicalExam: {
    general: "Alert, no distress, not grimacing, comfortable",
    head: "NC AT, No deformity",
    eyes:
      "PERRL, EOM intact, no conjunctival injection, sclera non-icteric, no discharge, symmetrical eye lids",
    ent:
      "Atraumatic external nose and ears, NO rhinorrhea, No ear discharge, hearing grossly intact",
    neck: "Trachea midline, NO thyromegaly, NO masses",
    chestAndLungs:
      "Symmetrical chest rise, no accessory muscle use, No tripod breathing",
    breast: "",
    cardiovascular: "No JVD, No orthopnea, no exertional dyspnea, No edema",
    abdomen:
      "Non distended, No obvious surgical scars, no caput medusae, no hernia",
    back: "",
    genitourinary: "",
    rectal: "",
    // musculoskeletal: '',
    upperExtrimities: "NO gross deformity, NO clubbing, NO cyanosis",
    lowerExtrimities: "NO edema, N0 gangrene, NO cyanosis",
    skin: "",
    neurologic:
      "Limited exam, No dysarthria, follows commands, No facial asymmetry, motor strength grossly intact",
    psychiatrist:
      "Normal mood and affect, able to focus, non tangential, follows commands",
  },
  templateType: "virtual",
};

export {
  defaultTemplate,
  defaultVirtualTemplate,
  handleNoteObject,
  setLinkFormat,
};
