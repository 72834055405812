import React from 'react'
import { DragPreviewImage, useDrag, DragSource } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend';
const pictureDefault = require('../../../assets/images/picture.png')
const pdfDefault = require('../../../assets/images/pdf.png')

const style = {
//   border: '1px dashed gray',
//   backgroundColor: 'white',
//   padding: '0.5rem 1rem',
//   marginRight: '1.5rem',
//   marginBottom: '1.5rem',
    // height: '100%',
    cursor: 'move',
    width: "100%",
    // display: 'inline'
//   float: 'left',
}


const DraggableBubble = ( {data, children, willDrag}) => {
    const [{ isDragging, collectionProps }, drag, preview] = useDrag({
        item: { data, type: 'Box' },
        begin: monitor => {
           willDrag();
        },
        collect: (monitor, props) => ({
            isDragging: monitor.isDragging(),
        })
    })

    const getFileExtention = (name) =>  {
        return name.substr((~-name.lastIndexOf(".") >>> 0) + 2);
    }


    const opacity = isDragging ? 0.4 : 1;
    let url;
    // if (data.video) {
    //     if (data.video.url) url = data.video.url;
    // }

    if (data.picture) {
        const ext = getFileExtention(data.picture.name);
        if (ext === "pdf") {
            url = pdfDefault;
        } else {
            url = pictureDefault;
        }
    }

    if (!url) return null;

    // console.log(dataurl);
    return (
        <>
            <DragPreviewImage connect={preview} src={url} />
            <div ref={drag} style={{ ...style, opacity }}>
                {children}
            </div>
        </>
    )
}
export default DraggableBubble
