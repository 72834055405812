import React, { useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  setMessageTab,
  filterByTag,
  unfilterByTag,
  filterThreads,
  setSubMessageTab,
  fetchThreadsUnread,
  fetchThreads,
} from "../../actions/ThreadAction";

const MessageNav = ({ onTabSelect }) => {
  const { tab, circleLabelId, circleLabels, tagsSelectShown, read } = useSelector(mySelector, shallowEqual);

  const dispatch = useDispatch();

  const tabs = {
    ALL: "ALL",
    UNREAD: "UNREAD",
    TASKS: "TASKS",
    ALERTS: "ALERTS",
  };

  const activeClass = "hc-nav-link active";
  const inactiveClass = "hc-nav-link";

  const handleClick = (newTab) => {
    if (newTab === tab) return;
    if (newTab === "ALL") {
      // filterThreads({ attention: true })(dispatch);
      // dispatch(fetchThreadsUnread(0, "all"));
      dispatch(fetchThreads("", false, 0, "", "", read));
    }
    dispatch(setMessageTab(newTab));

    onTabSelect(newTab);
  };

  const handleChange = (circleLabelId) => {
    dispatch(filterByTag(circleLabelId));
  };

  const handleCancel = () => {
    dispatch(unfilterByTag());
  };

  const handleSubMessageTab = (tab) => {
    dispatch(setSubMessageTab(tab));
  };

  if (tagsSelectShown) {
    return (
      <div className="message-nav d-flex">
        <div className="input-group rounded">
          <select
            value={circleLabelId}
            className="custom-select text-primary"
            id="inputGroupSelect01"
            onChange={(e) => handleChange(e.target.value)}
          >
            <option value="" selected>
              Choose tag...
            </option>
            {circleLabels.map((label) => (
              <option value={label.objectId}># {label.name}</option>
            ))}
          </select>
          <div className="input-group-append">
            <button onClick={(e) => handleCancel()} className="btn btn-danger">
              <i className="fas fa-fw fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="message-nav">
      <ul className="nav nav-pills nav-justified hc-nav-pills">
        {/* <li className="nav-item">
          <a
            onClick={() => handleClick(tabs.ALL)}
            className={tab === tabs.ALL ? activeClass : inactiveClass}
            href="#"
          >
            All
          </a>
        </li> */}
        <li className="nav-item">
          <a onClick={() => handleClick(tabs.ALL)} className={tab === tabs.ALL ? activeClass : inactiveClass} href="#">
            Messages
          </a>
        </li>
        {/* <li className="nav-item">
          <a
            onClick={() => handleClick(tabs.TASKS)}
            className={tab === tabs.TASKS ? activeClass : inactiveClass}
            href="#"
          >
            Tasks
          </a>
        </li> */}
        <li className="nav-item">
          <a
            onClick={() => handleClick(tabs.ALERTS)}
            className={tab === tabs.ALERTS ? activeClass : inactiveClass}
            href="#"
          >
            Tasks {/* Alerts */}
          </a>
        </li>
      </ul>
    </div>
  );
};

const mySelector = (state) => ({
  tab: state.thread.activeMessageTab,
  circleLabelId: state.thread.circleLabelId,
  circleLabels: state.dashboard.circleLabels,
  tagsSelectShown: state.thread.tagsSelectShown,
  read: state.thread.read,
});

export default MessageNav;
