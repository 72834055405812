import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import Parse from "parse";
import util from "../../../helper/util";
import ConferenceApi from "../../../api/Conference";
import ConferenceHelper from "../../../helper/conference";
import ErrorLobby from "./ErrorLobby";
import LeaveModal from "./LeaveModal";
import Notify from "./Notify";
import Room from "./Room";
import usePubnub from "./usePubnub";
import InviteContactModal from "./InviteContactModal";
import "./index.css";
import ParticipantsModal from "./ParticipantsModal";
import useConference from "./useConference";

const ConferenceCall = props => {
  const [roomId, setRoomId] = useState("");
  const [token, setToken] = useState("");
  const [invited, setInvited] = useState([]);
  const [hasVideo, setHasVideo] = useState(false);
  const [error, setError] = useState("");
  const [errorLog, setErrorLog] = useState("");
  const [allowRetry, setAllowRetry] = useState(false);
  const [userModalShown, setUserModalShown] = useState(false);
  const [leaveModalShown, setLeaveModalShown] = useState(false);
  const [isGuest, setIsGuest] = useState(true);
  const [participantsModalShown, setParticipantsModalShown] = useState(false);
  const Pubnub = usePubnub();
  const { isHost, localUser, setLocalUser, host, setHost } = useConference();

  const inviteLinkRef = useRef();

  const inviteLink = useMemo(() => {
    return roomId ? getInviteLink(roomId) : "";
  }, [roomId]);

  const handleAddParticipants = users => {
    const { addParticipants } = ConferenceApi;

    // Hide modal
    setUserModalShown(false);

    const participantIds = users.map(u => u.objectId);

    const newInvited = users.map(u => formatUser(u));

    Notify.show("Adding participants");

    addParticipants(roomId, participantIds)
      .then(() => {
        Notify.success("Participants added");

        setInvited(invited => [...invited, ...newInvited]);
      })
      .catch(response => {
        if (response.error) {
          return Notify.error(response.error.message);
        }
        Notify.error("Failed to add participants");
      });
  };

  // When participant clicks the red button
  const handleLeaveButtonClick = useCallback(() => {
    if (!token) {
      // Proceed to error lobby
      setError("Conference call has ended");
    } else {
      setLeaveModalShown(true);
    }
  }, [token]);

  // When the room was disconnected
  const handleRoomDisconnect = () => {
    // Revoke the token
    setToken("");

    // Proceed to error lobby
    setError("Conference call has ended");
  };

  const leaveRoom = () => {
    // Revoke the token
    setToken("");

    // Proceed to error lobby
    setError("You left the room");
  };

  const endRoom = () => {
    ConferenceApi.endConferenceCall(roomId);

    // Revoke the token
    setToken("");

    // Proceed to error lobby
    setError("You ended the conference call");
  };

  const handleInviteGuest = useCallback(() => {
    /* Get the text field */
    var copyText = inviteLinkRef.current;

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");

    Notify.success("Invite link copied!");
  }, [inviteLink]);

  const showErrorLobby = (message, allowRetry, errorLog) => {
    setAllowRetry(allowRetry);
    setErrorLog(errorLog);
    setError(message);
  };

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const hasVideo = params.get("hasVideo") === "true";
    setHasVideo(hasVideo);
  }, []);

  useEffect(() => {
    const recorded = false;

    initConference(props, recorded)
      .then(result => {
        const { roomId, token, invited, hasVideo, localUser, host } = result;

        setRoomId(roomId);
        setToken(token);
        setLocalUser(localUser);
        setHasVideo(hasVideo);
        setHost(host);

        setInvited(() => {
          // Filter out localUser from invited
          return (
            invited
              .filter(user => user.objectId != localUser.id)

              // Get only the needed fields from each user
              .map(user => formatUser(user))
          );
        });
      })
      .catch(res => {
        if (res.responseJSON) {
          const { responseJSON } = res;

          const errorString = JSON.stringify(responseJSON);

          return showErrorLobby(responseJSON.error, false, errorString);
        }

        if (res instanceof Error) {
          const errorString = res.toString();

          return showErrorLobby("Something went wrong.", false, errorString);
        }

        showErrorLobby("Something went wrong.", false, String(res));
      });
  }, []);

  useEffect(() => {
    ConferenceHelper.setEngaged(true);

    const cleanup = () => {
      // do your cleanup
      ConferenceHelper.setEngaged(false);
    };

    window.addEventListener("beforeunload", cleanup);

    return () => {
      window.removeEventListener("beforeunload", cleanup);
    };
  }, []);

  useEffect(() => {
    if (!localUser || isGuest) return;

    Pubnub.init();

    Pubnub.addUserChannel(localUser, isGuest);

    Pubnub.on("conference_join", user => {
      setInvited(invited => {
        const existingInvited = invited.find(
          u => u.objectId === user.participantId
        );

        const newInvited = formatInvited(user);

        return !existingInvited ? [...invited, newInvited] : [...invited];
      });
    });
  }, [localUser, isGuest]);

  useEffect(() => {
    setIsGuest(!Parse.User.current());
  }, []);

  return (
    <>
      <div
        className="d-flex flex-column"
        style={{ position: "fixed", height: "100%", width: "100%" }}
      >
        {!error ? (
          <Room
            isHost={isHost}
            isGuest={isGuest}
            token={token}
            roomId={roomId}
            invited={invited}
            localUser={localUser}
            hasVideo={hasVideo}
            onLeaveButtonClick={handleLeaveButtonClick}
            onInviteGuest={handleInviteGuest}
            setUserModalShown={setUserModalShown}
            onDisconnected={handleRoomDisconnect}
            showErrorLobby={showErrorLobby}
            setParticipantsModalShown={setParticipantsModalShown}
          />
        ) : (
          <ErrorLobby
            message={error}
            allowRetry={allowRetry}
            errorLog={errorLog}
          />
        )}
      </div>

      <LeaveModal
        isHost={isHost}
        isOpen={leaveModalShown}
        onLeave={leaveRoom}
        onEnd={endRoom}
        modalClose={() => setLeaveModalShown(false)}
      />

      {!isGuest ? (
        <InviteContactModal
          onSubmit={participants => handleAddParticipants(participants)}
          onClose={() => setUserModalShown(false)}
          exclude={invited}
          isOpen={userModalShown}
        />
      ) : (
        ""
      )}

      {!isGuest && participantsModalShown ? (
        <ParticipantsModal
          isHost={false}
          roomId={roomId}
          invited={invited}
          onClose={() => setParticipantsModalShown(false)}
          isOpen={participantsModalShown}
        />
      ) : (
        ""
      )}

      <input
        style={{ zIndex: "-1", opacity: "0" }}
        ref={inviteLinkRef}
        value={inviteLink}
        type="text"
      />
    </>
  );
};

function initConference(props, recorded) {
  const params = new URLSearchParams(props.location.search);
  const action = params.get("action");
  const hasVideo = params.get("hasVideo") === "true";
  const roomId = params.get("roomId");
  const participantIds = params.get("participantIds") || "";
  const circleId = params.get("circleId");
  const guestName = params.get("guestName") || "Guest";

  // Retrieve result of createConferenceCall from local storage
  const hostRoomId = JSON.parse(localStorage.getItem("conferenceId"));

  const currentUser = Parse.User.current();

  const isLoggedin = currentUser && currentUser.isValid();

  // If window was opened from participant selection
  // by the host of conference call
  if (action === "create" && !hostRoomId) {
    const userIds = participantIds ? participantIds.split(",") : [];

    return createConference(userIds, recorded, circleId);
  } else if (action === "create") {
    return joinConference(hostRoomId, hasVideo);
  } else if (isLoggedin) {
    return joinConference(roomId, hasVideo);
  } else {
    const storedIdentity = JSON.parse(
      localStorage.getItem("conference_guest_" + roomId)
    );

    const guestIdentity = storedIdentity || util.uuid();

    localStorage.setItem(
      "conference_guest_" + roomId,
      JSON.stringify(guestIdentity)
    );

    return joinConferenceAsGuest(roomId, guestName, guestIdentity);
  }
}

async function createConference(userIds, recorded, circleId) {
  const { createConferenceCall } = ConferenceApi;

  const result = await createConferenceCall(userIds, recorded, circleId);

  const { conference } = result;
  const { host } = conference;

  const localUser = getLocalUser();

  // Upon successful creating conference, save to local storage the room id
  localStorage.setItem("conferenceId", JSON.stringify(result.roomId));

  return {
    token: result.token,
    roomId: result.roomId,
    invited: conference.participants,
    localUser,
    host
  };
}

function joinConference(roomId) {
  const { joinConferenceCall } = ConferenceApi;

  return joinConferenceCall(roomId).then(result => {
    const { conference } = result;
    const { host } = conference;

    const localUser = getLocalUser();

    return {
      token: result.token,
      roomId: result.roomId,
      invited: conference.participants,
      localUser,
      host
    };
  });
}

async function joinConferenceAsGuest(roomId, displayName, identity) {
  const { joinConferenceCallAsGuest } = ConferenceApi;

  const result = await joinConferenceCallAsGuest(roomId, displayName, identity);

  const { conference } = result;
  const { host } = conference;

  const guestUser = createGuestUser(identity, displayName);

  return Promise.resolve({
    token: result.token,
    roomId: result.roomId,
    invited: conference.participants,
    localUser: guestUser,
    host
  });
}

function getLocalUser() {
  const user = Parse.User.current();

  const defaultImage = require("../../../assets/images/default.png");

  const imgUrl = user.get("picture") ? user.get("picture").url() : defaultImage;

  const thumbUrl = user.get("thumbnail")
    ? user.get("thumbnail").url()
    : defaultImage;

  return {
    id: user.id,
    displayName: user.get("displayName"),
    imgUrl,
    thumbUrl
  };
}

function createGuestUser(identity, displayName) {
  const defaultImage = require("../../../assets/images/default.png");

  const imgUrl = defaultImage;
  const thumbUrl = defaultImage;

  return {
    id: identity,
    displayName,
    imgUrl,
    thumbUrl
  };
}

function formatUser(user) {
  const defaultImage = require("../../../assets/images/default.png");

  const imgUrl = user.picture ? user.picture.url : defaultImage;

  const thumbUrl = user.thumbnail ? user.thumbnail.url : defaultImage;

  return {
    objectId: user.objectId,
    displayName: user.displayName,
    imgUrl,
    thumbUrl
  };
}

function formatInvited(user) {
  const defaultImage = require("../../../assets/images/default.png");

  return {
    objectId: user.participantId,
    thumbUrl: user.participantImage || defaultImage,
    imgUrl: user.participantImage || defaultImage,
    displayName: user.participantName
  };
}

function getInviteLink(roomId) {
  return [
    window.location.origin,
    "/redirect_conferencecall",
    "?roomId=" + roomId
  ].join("");
}

export default ConferenceCall;
