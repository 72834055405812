import React, { useEffect, useState } from "react";
import moment from "moment";
import { getMembers } from "../../../../actions/GroupAction";
import {
  fetchSelectedThread,
  forwardMessage,
  fetchGroupMessages
} from "../../../../actions/ThreadAction";
import {
  fetchOrganization,
  fetchphysiciansAndEditors
} from "../../../../actions/SessionAction";
import {
  createNewPrescriptionHistory,
  createNewOrderHistory
} from "../../../../actions/PatientAction";
import PatientOrdersComponent from "./PatientOrdersComponent";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Notify from "../../../Common/Notify";

const MedicationView = ({ threadId = "", ...props }) => {
  const dispatch = useDispatch();
  const [groupMembers, setGroupMembers] = useState([]);
  const [tempMembers, setTempMembers] = useState([]);
  const [guestMembers, setGuestMembers] = useState([]);
  const { selectedThread, organization, physicians, editors } = useSelector(
    mySelector,
    shallowEqual
  );

  useEffect(() => {
    if (threadId) {
      handleGetMembers(threadId);
      fetchSelectedThread(threadId)(dispatch);
    }
    fetchOrganization()(dispatch);
  }, [threadId]);

  function handleFetchOrganization(objectId) {
    fetchphysiciansAndEditors(objectId)(dispatch);
  }

  function handleGetMembers(id) {
    getMembers(id)(dispatch)
      .then(result => {
        const { regMembers = [], orgMembers = [], guests = [] } = result;
        const filteredMembers = orgMembers.filter(item => item.objectId !== id);

        let wholeMembers = regMembers;
        if (orgMembers.length !== 0) {
          wholeMembers = wholeMembers.concat(orgMembers);
        }

        let tempMembers = [];
        if (wholeMembers.length !== 0) {
          tempMembers = Array.from(
            wholeMembers
              .reduce((m, t) => m.set(t.objectId, t), new Map())
              .values()
          );
        }

        setGroupMembers(tempMembers);
        setTempMembers(tempMembers);
        setGuestMembers(guests);
      })
      .catch(error => {
        console.log(error);
      });
  }

  return (
    <div className="componentContainer">
      {selectedThread.objectId && organization.objectId && (
        <PatientOrdersComponent
          {...props}
          thread={selectedThread}
          threadId={threadId}
          fetchphysiciansAndEditors={handleFetchOrganization}
          organization={organization}
          physicians={physicians}
          editors={editors}
          groupMembers={groupMembers}
          tempMembers={tempMembers}
          guestMembers={guestMembers}
        />
      )}
    </div>
  );
};

const mySelector = state => ({
  selectedThread: state.thread.selectedThread,
  physicians: state.session.physicians,
  organization: state.session.organization,
  editors: state.session.editors
});

export default MedicationView;
