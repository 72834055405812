import React, { Component, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { hideReplyPreview } from '../../../actions/ThreadAction';
import Image from './Image';
import Video from './Video';
import Audio from './Audio';
import Pdf from './Pdf';
import './index.css';

const ReplyPreviewModal = ({ fileType, fileUrl, isOpen }) => {

    const dispatch = useDispatch();
    const modalRef = useRef();

    const onClose = () => {
        dispatch(
            hideReplyPreview()
        )
    };

    const detectType = (type = '') => {

        const typeRegex = {
            'image': /(gif|jpe?g|tiff?|png|webp|bmp)$/i,
            'video': /(mov|avi|wmv|flv|3gp|mp4|mpg)$/i,
            'audio': /(mp3|wav)$/i,
            'pdf': /pdf/i,
        }

        if (typeRegex.image.test(type)) return 'image';
        if (typeRegex.video.test(type)) return 'video';
        if (typeRegex.audio.test(type)) return 'audio';
        if (typeRegex.pdf.test(type)) return 'pdf';

        return '';
    }

    const type = useMemo(() => {
        return detectType(fileType);
    }, [fileType])

    return (
        <div ref={modalRef} className="reply-preview-modal">

            <div className="reply-action-container">
                <a href="#!" className="File-Action-Button no-underline">
                    <i className="fas fa-download mr-2"></i>
                    <span style={{ fontSize: "18px" }}>Download</span>
                </a>
                <button className="File-Action-Button" onClick={onClose} style={{ float: "right", position: "relative", top: "0px", right: "5px", width: "35px" }}><img style={{ width: "100%" }} src={require("../../../assets/images/close_file_preview.png")} />
                </button>
            </div>

            <div className="reply-preview-modal-content">
                {type === 'image' ? <Image url={fileUrl} /> : ''}
                {type === 'video' ? <Video url={fileUrl} /> : ''}
                {type === 'audio' ? <Audio url={fileUrl} type={fileType} /> : ''}
                {type === 'pdf' ? <Pdf url={fileUrl} /> : ''}
            </div>

            <div className='reply-preview-modal-footer'></div>
        </div>
    )
}

export default ReplyPreviewModal;
