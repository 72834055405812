import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Parse from "parse";
import { handleMessageFormat, checkFormValidity } from "../../../../helper/util";
import PrescriptionModal from "../../../Conversation/Setting/PrescriptionModal";
import Spinner from "../../../Common/Spinner";
import Notify from "../../../Common/Notify";
import PatientInfo from "./PatientInfo";
import Subjective from "./Subjective";
import ChiefComplaints from "./ChiefComplaints";
import VitalSigns from "./VitalSigns";
import PhysicalExam from "./PhysicalExam";
import Assessments from "./Assessments";
import Plan from "./Plan";
import MedicalDecisionMaking from "./MedicalDecisionMaking";
import AddendumNote from "./AddendumNote";
import FollowUps from "./FollowUps";
import CptCode from "./CptCode";
import SaveChanges from "./SaveChanges";
import {
  createProgressNote,
  updateProgressNote,
  getProgressNotesPlans,
  checkUncheckPlansArray,
  getProgressNote,
} from "../../../../actions/ProgressNotesAction";
import { randomID, sendNewMessage } from "../../../../actions/ThreadAction";
import { handleNoteObject } from "../config";

import "./index.css";
import ProcedureNotes from "./ProcedureNotes";

const $ = window.$;

function ProgressNoteModal({
  isOpen = false,
  patientId = "",
  patientInfo = {},
  orgId = "",
  template = {},
  title = "",
  progressNoteId = "",
  groupMembers = [],
  tempMembers = [],
  guests = [],
  onClose = () => {},
  refreshData = () => {},
  refreshPatient = () => {},
  isAddendumNote = false,
  errorNotification = () => {},
  setLocalStorageNull = () => {},
}) {
  const dispatch = useDispatch();
  const { isUpdating, plansArray } = useSelector(mySelector, shallowEqual);
  const modalId = "#progressnote_modal";
  const [temp, setTemplate] = useState(template);
  const [patient, setPatient] = useState(patientInfo);
  const [rxModalShown, setRxModalShown] = useState(false);
  const [showSaveChanges, setShowSaveChanges] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);

  const {
    subjective = "",
    chiefComplaints = [],
    vitalSigns = {},
    physicalExam = {},
    assesments = [
      { problem: "", assessmentPlan: "" },
      { problem: "", assessmentPlan: "" },
      { problem: "", assessmentPlan: "" },
      { problem: "", assessmentPlan: "" },
    ],
    planIds = [],
    labs = {
      prescription: "",
      diagnosticStudies: "",
      referrals: "",
      medicalSupplies: "",
    },
    favorites = [],
    procedureNotes = "",
    medicalDecisionMaking = "",
    followUps = {},
    addendumNote = "",
    dateSeen = "",
    visitProvider = "",
    clinicLocation = "",
    encounterType = "",
    cptCode = "",
    posCode = "",
    modifierCode = "",
    templateType = "default",
  } = temp;

  function handleSetDataChanged(data) {
    localStorage.setItem(`${patientId}_progressnote`, JSON.stringify(data));
    setDataChanged(true);
  }

  function handleUpdateField(e) {
    const { name, value } = e.target;
    const data = {
      ...temp,
      [name]: value,
    };
    setTemplate(data);
    handleSetDataChanged(temp);
  }

  function handleClearField(name) {
    const data = {
      ...temp,
      [name]: "",
    };
    setTemplate(data);
    handleSetDataChanged(data);
  }

  function handleUpdatePatientInfo(name, value) {
    const data = {
      ...patient,
      [name]: value,
    };
    setPatient(data);
    handleSetDataChanged(data);
  }

  function handleUpdateChiefComplaints(data) {
    const { chiefComplaints = [] } = temp;
    const newData = {
      ...temp,
      chiefComplaints: [...chiefComplaints, data],
    };
    setTemplate(newData);
    handleSetDataChanged(newData);
  }

  function handleUpdateVitalSigns(name, value) {
    const data = {
      ...temp,
      vitalSigns: {
        ...temp.vitalSigns,
        [name]: value,
      },
    };
    setTemplate(data);
    handleSetDataChanged(data);
  }

  function handleRemoveChiefComplaint(data) {
    const { chiefComplaints = [] } = temp;
    const filtered = chiefComplaints.filter((item) => item !== data);
    const newData = {
      ...temp,
      chiefComplaints: filtered,
    };
    setTemplate(newData);
    handleSetDataChanged(newData);
  }

  function handleUpdatePhysicalExam(name, value) {
    const data = {
      ...temp,
      physicalExam: {
        ...temp.physicalExam,
        [name]: value,
      },
    };
    setTemplate(data);
    handleSetDataChanged(data);
  }

  function handleUpdateAssessment(value, index) {
    let newAssessment = assesments;
    newAssessment[index] = value;
    const data = {
      ...temp,
      assesments: newAssessment,
    };
    setTemplate(data);
    handleSetDataChanged(data);
  }

  function handleUpdateAssessmentObject(value, name, index) {
    let newAssessment = assesments;
    newAssessment[index] = {
      ...newAssessment[index],
      [name]: value,
    };
    const data = {
      ...temp,
      assesments: newAssessment,
    };

    setTemplate(data);
    handleSetDataChanged(data);
  }

  function handleRemoveAssessment(i) {
    let newAssessments = assesments;
    newAssessments.splice(i, 1);
    const data = {
      ...temp,
      assesments: newAssessments,
    };
    setTemplate(data);
    handleSetDataChanged(data);
  }

  function handleUpdateProcedureNotes(e) {
    e.preventDefault();
    const { value } = e.target;
    const data = {
      ...temp,
      procedureNotes: value,
    };
    setTemplate(data);
    handleSetDataChanged(data);
  }

  function handleUpdateFollowUps(name, value) {
    const {
      followUps: { duration = [], in: fIn },
    } = temp;
    if (name !== "in" && name !== "duration") {
      const data = {
        ...temp,
        followUps: {
          ...temp.followUps,
          [name]: value,
        },
      };
      setTemplate(data);
      handleSetDataChanged(data);
    }

    if (name === "in") {
      const currentDate = new Date();
      const data = {
        ...temp,
        followUps: {
          ...temp.followUps,
          [name]: (parseInt(value) >= 100 ? 99 : parseInt(value) <= 0 ? 0 : value).toString(),
          date:
            duration.length > 0
              ? currentDate.setDate(currentDate.getDate() + parseInt(value) * (duration[0].val || 0))
              : currentDate,
        },
      };
      setTemplate(data);
      handleSetDataChanged(data);
    }

    if (name === "duration") {
      const currentDate = new Date();
      const data = {
        ...temp,
        followUps: {
          ...temp.followUps,
          [name]: value,
          date:
            value.length > 0
              ? currentDate.setDate(currentDate.getDate() + parseInt(value[0].val) * (fIn || 0))
              : currentDate,
        },
      };
      setTemplate(data);
      handleSetDataChanged(data);
    }
  }

  function handleClose() {
    localStorage.removeItem("someModalIsOpen");
    $(modalId).modal("hide");
  }

  function handleSendMessageToHub(progressNoteId) {
    const noteSummary = handleNoteObject(temp, patient);
    const noteUrl = `${window.location.origin}/progressnotes/${patientId}/view/${progressNoteId}`;
    const message = `${noteSummary}This note has been marked as completed. \nLink:\n${noteUrl}`;
    const thread = {
      ...patientInfo,
      threadId: patientInfo.objectId,
      threadType: "group",
    };
    const date = new Date();

    let newMessage = {
      text: message.trim(),
      originalMessageDate: date,
      user: {
        __type: "Pointer",
        className: "_User",
        objectId: Parse.User.current().id,
      },
      circle: {
        __type: "Pointer",
        className: "Circle",
        objectId: thread.threadId,
      },
      objectId: randomID(),
      threadType: "group",
      threadId: thread.threadId,
      newMessageStatus: "sending",
      createdAt: date,
      attention: [],
      urgent: false,
      attentionName: [],
      priorityLevel: -1,
    };

    sendNewMessage(
      newMessage,
      thread
    )(dispatch).then(() => {
      console.log("done here");
    });
  }

  function handleCreateProgressNote(doneObj = {}, isSignedAndComplete = false) {
    createProgressNote(
      {
        ...temp,
        ...doneObj,
        circleId: patientId,
      },
      orgId
    )(dispatch).then(
      (response) => {
        Notify.success("Progress note successfully created.");
        setLocalStorageNull();
        handleClose();
        onClose();
        refreshData();
        setDataChanged(false);
        if (isSignedAndComplete) {
          handleSendMessageToHub(response.result.progressNote.objectId);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  function handleUpdateProgressNote(status = {}, isSignedAndComplete = false) {
    updateProgressNote(
      {
        ...temp,
        ...status,
      },
      progressNoteId
    )(dispatch).then(
      () => {
        // handleClose();
        // onClose();
        Notify.success("Progress note successfully updated.");
        refreshData();
        setLocalStorageNull();
        setDataChanged(false);
        if (isSignedAndComplete) {
          handleSendMessageToHub(progressNoteId);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  function handleUpdateMedicalDecisionMaking(e) {
    e.preventDefault();
    const { value } = e.target;
    const data = {
      ...temp,
      medicalDecisionMaking: value,
    };
    setTemplate(data);
    handleSetDataChanged(data);
  }

  function handleUpdateAddendum(e) {
    e.preventDefault();
    const { value } = e.target;
    const data = {
      ...temp,
      addendumNote: value,
    };
    setTemplate(data);
    handleSetDataChanged(data);
  }

  function handleUpdatePlans(name, value) {
    const data = {
      ...temp,
      labs: {
        ...temp.labs,
        [name]: value,
      },
    };
    setTemplate(data);
    handleSetDataChanged(data);
  }

  function handleNewMedsImport(old_meds = "", meds = []) {
    return old_meds
      ? `--------------------\n\nNEW PRESCRIPTION:\n${handleMessageFormat(meds)}`
      : handleMessageFormat(meds);
  }

  function handleImportedPrescription(meds = []) {
    const data = {
      ...temp,
      labs: {
        ...temp.labs,
        prescription: `${temp.labs.prescription || ""} ${handleNewMedsImport(temp.labs.prescription || "", meds)}`,
      },
    };
    setTemplate(data);
    setRxModalShown(false);
    handleSetDataChanged(data);
  }

  function handleUpdatePlansCheckbox(data, checked) {
    const { diagnosticStudies } = temp.labs;
    checkUncheckPlansArray(data.objectId, checked, plansArray)(dispatch);
    const newData = {
      ...temp,
      labs: {
        ...temp.labs,
        diagnosticStudies: checked
          ? addStr(`${diagnosticStudies}% ${data.name}`)
          : removeStr(diagnosticStudies, data.name),
      },
      planIds: filterPlanIds(planIds, data.objectId, checked),
    };
    setTemplate(newData);
    handleSetDataChanged(newData);

    function filterPlanIds(ids, id, checked) {
      const pIds = ids;

      if (checked) {
        pIds.push(id);
      } else if (!checked) {
        return pIds.filter((i) => i !== id);
      }
      return pIds;
    }

    function addStr(str) {
      return str
        .split("%")
        .filter(Boolean)
        .join(";");
    }

    function removeStr(ds, str) {
      const strData = ds.replace(str, "");
      return strData
        .split(";")
        .filter((entry) => {
          return entry.trim() !== "";
        })
        .join(";");
    }
  }

  function handleSaveChangesOnClose(bool, isValid) {
    if (bool) {
      if (!isValid) {
        errorNotification();
        setShowSaveChanges(false);
        return;
      }
      if (progressNoteId) {
        handleUpdateProgressNote();
      } else {
        handleCreateProgressNote();
      }
    } else {
      setDataChanged(false);
      setLocalStorageNull();
      handleClose();
      onClose();
    }
    setShowSaveChanges(false);
  }

  function handleAddNewAssessment() {
    temp.assesments.unshift({
      problem: "",
      assessmentPlan: "",
    });

    const data = {
      ...temp,
      assesments: [...temp.assesments],
    };
    setTemplate(data);
    handleSetDataChanged(data);
  }

  function handleSetAssessments(assesments = []) {
    setTemplate({
      ...temp,
      assesments: [...assesments],
    });
  }

  function handleUpdateCptComp(name, value, subObptions = [], posOptions = [], modifier = "") {
    if (name === "encounterType") {
      const data = {
        ...temp,
        [name]: value,
        cptCode: "",
        posCode: "",
        modifierCode: "",
      };
      setTemplate(data);
      handleSetDataChanged(data);
    } else {
      const data = {
        ...temp,
        [name]: value,
      };
      setTemplate(data);
      handleSetDataChanged(data);
    }
  }

  useEffect(() => {
    componentDidMount();
    if (progressNoteId && !temp.objectId) {
      getProgressNote(progressNoteId)(dispatch).then(
        (res) => {
          setTemplate(res);
          getProgressNotesPlans(res.planIds)(dispatch);
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      getProgressNotesPlans(planIds)(dispatch);
    }
  }, [isOpen, progressNoteId]);

  function componentDidMount() {
    if (isOpen) {
      localStorage.setItem("someModalIsOpen", "true");
      $(modalId).modal({
        backdrop: "static",
        keyboard: false,
      });
      $(modalId).modal("show");
    } else {
      $(modalId).modal("hide");
    }
  }

  return (
    <form id="default-template-form" className="needs-validation" novalidate>
      <div id="progressnote_modal" className="modal fade" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document" style={{ maxWidth: "1200px" }}>
          <div className="modal-content modal-full-height">
            <div className="modal-header">
              <h5 className="modal-title hc-primary-text" style={{ fontSize: "1.25rem" }}>
                <i className="fas fa-fw fa-file-contract"></i>
                {title}
              </h5>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={() => {
                  if (dataChanged) {
                    setShowSaveChanges(true);
                  } else {
                    handleClose();
                    onClose();
                  }
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ overflowY: "auto" }}>
              <div className="pn-container">
                <div className="pn-header">
                  <span>Vital Health Medical Group</span>
                  <span className="hc-primary-text">6245 2nd flr De Longpre Ave, Hollywood, CA 90028</span>
                  <span className="hc-primary-text">Tel no. (323)315-0911 Fax (323)</span>
                </div>
                <PatientInfo
                  patientInfo={patient}
                  visitProvider={visitProvider}
                  clinicLocation={clinicLocation}
                  dateSeen={dateSeen}
                  isDisabled={isAddendumNote}
                  updateAnyInPatient={(e) => handleUpdateField(e)}
                  updatePatientInfo={(name, value) => handleUpdatePatientInfo(name, value)}
                  refreshPatientData={refreshPatient}
                />
                <ChiefComplaints
                  chiefComplaints={chiefComplaints}
                  isDisabled={isAddendumNote}
                  updateChiefComplaints={(data) => handleUpdateChiefComplaints(data)}
                  removeChiefComplaint={(data) => handleRemoveChiefComplaint(data)}
                />
                <Subjective
                  subjective={subjective}
                  isDisabled={isAddendumNote}
                  updateSubjective={(e) => handleUpdateField(e)}
                />
                <VitalSigns
                  vitalSigns={vitalSigns}
                  isDisabled={isAddendumNote}
                  updateVitalSigns={(name, value) => handleUpdateVitalSigns(name, value)}
                />
                <PhysicalExam
                  physicalExam={physicalExam}
                  isDisabled={isAddendumNote}
                  templateType={templateType}
                  updatePhysicalExam={(name, value) => handleUpdatePhysicalExam(name, value)}
                />
                <Assessments
                  assesments={assesments}
                  isDisabled={isAddendumNote}
                  updateAssessment={handleUpdateAssessment}
                  updateAssessmentObject={handleUpdateAssessmentObject}
                  removeAssessment={handleRemoveAssessment}
                  addNewAssessment={handleAddNewAssessment}
                  setAssessments={handleSetAssessments}
                />
                <Plan
                  prescriptions={patientInfo.medications || []}
                  planIds={planIds}
                  favorites={favorites}
                  plansArray={plansArray}
                  labs={labs}
                  isDisabled={isAddendumNote}
                  openPrescription={() => setRxModalShown(true)}
                  updatePlans={handleUpdatePlans}
                  updatePlansCheckbox={handleUpdatePlansCheckbox}
                  thread={{ ...patientInfo, threadId: patientInfo.objectId }}
                  guests={guests}
                  options={groupMembers}
                  isPatient={true}
                  patientName={patientInfo.name}
                  patientDob={patientInfo.dob}
                />
                {/* <Images /> */}
                <MedicalDecisionMaking
                  medicalDecisionMaking={medicalDecisionMaking}
                  updateMedicalDecisionMaking={handleUpdateMedicalDecisionMaking}
                  clearField={handleClearField}
                  isDisabled={isAddendumNote}
                />
                {isAddendumNote && (
                  <AddendumNote addendumNote={addendumNote} updateAddendumNote={(e) => handleUpdateAddendum(e)} />
                )}
                <ProcedureNotes
                  procedureNotes={procedureNotes}
                  isDisabled={isAddendumNote}
                  updateProcedureNotes={(e) => handleUpdateProcedureNotes(e)}
                />
                <hr />
                <div className="two-columns">
                  <FollowUps
                    followUps={followUps}
                    isDisabled={isAddendumNote}
                    updateFollowUps={(name, value) => handleUpdateFollowUps(name, value)}
                  />
                  <CptCode
                    encounterType={encounterType}
                    cptCode={cptCode}
                    posCode={posCode}
                    modifierCode={modifierCode}
                    isDisabled={isAddendumNote}
                    updateCpt={(name, value, options, posOptions, modifier) =>
                      handleUpdateCptComp(name, value, options, posOptions, modifier)
                    }
                  />
                </div>
                <div style={{ height: "1px" }} />
              </div>
            </div>
            <div className="modal-footer">
              <div className="form-group">
                {temp.status !== "DONE" && (
                  <button
                    type="button"
                    className="btn btn-hubchart btn-hubchart-success"
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      const status = { status: "DONE" };
                      const isValid = checkFormValidity("default-template-form");
                      if (!isValid) {
                        errorNotification();
                        return;
                      }

                      if (progressNoteId) {
                        handleUpdateProgressNote(status, true);
                      } else {
                        handleCreateProgressNote(status, true);
                      }
                    }}
                  >
                    SIGN & POST
                  </button>
                )}
                <button
                  type="button"
                  style={{ marginRight: "10px" }}
                  className="btn btn-hubchart btn-hubchart-primary"
                  onClick={() => {
                    const isValid = checkFormValidity("default-template-form");
                    if (!isValid) {
                      errorNotification();
                      return;
                    }

                    if (progressNoteId) {
                      handleUpdateProgressNote();
                    } else {
                      handleCreateProgressNote();
                    }
                  }}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-hubchart btn-hubchart-close"
                  onClick={() => {
                    if (dataChanged) {
                      setShowSaveChanges(true);
                    } else {
                      handleClose();
                      onClose();
                      setLocalStorageNull();
                    }
                  }}
                >
                  Close
                </button>
              </div>
            </div>
            {isUpdating && (
              <div className="tab-loading">
                <Spinner size={5} color={"#fbba09"} loading={true} />
              </div>
            )}
            {showSaveChanges && (
              <SaveChanges
                onClose={(bool) => {
                  const isValid = checkFormValidity("default-template-form");
                  handleSaveChangesOnClose(bool, isValid);
                }}
                title="Confirm Navigation"
                message="Some changes were made to this progress note. Do you want to save before closing?"
              />
            )}
          </div>
        </div>
        {rxModalShown && (
          <PrescriptionModal
            threadId={patientId}
            isOpen={rxModalShown}
            onClose={() => setRxModalShown(false)}
            refreshData={() => {}}
            importPrescription={(data) => handleImportedPrescription(data)}
            fromProgressNotes={true}
          />
        )}
      </div>
    </form>
  );
}

const mySelector = (state) => ({
  isUpdating: state.notes.isUpdating,
  plansArray: state.notes.plans,
});

export default ProgressNoteModal;
