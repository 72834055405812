import React, { Component } from 'react'
import CreditCardInput from 'react-credit-card-input';
import Parse from 'parse'

export class PaymentContainer extends Component {
    constructor() {
        super();

        this.state = {
            cardNumber : "",
            expiry : "",
            cvc : ""
        }

        this.handleCardNumberChange = this.handleCardNumberChange.bind(this);
        this.handleCardExpiryChange = this.handleCardExpiryChange.bind(this);
        this.handleCardCVCChange = this.handleCardCVCChange.bind(this);
    }


    handleCardNumberChange(e) {
        this.setState({
            cardNumber : e.target.value
        })
    }

    handleCardExpiryChange(e) {
        this.setState({
            expiry : e.target.value
        })
    }

    handleCardCVCChange(e) {
        this.setState({
            cvc : e.target.value
        })
    }

    render() {
        return (
                <div className="Form-Container" style={{margin: "0", padding: "0px", maxWidth: "100%"}}>
                    <a href="#!" style={{fontSize: "20px", margin: "0", padding: "0", position: "fixed" ,top: "12px", zIndex: "2001"}} onClick={this.props.closePayment.bind(this)}>
                        <i className="fas fa-chevron-left"></i>
                    </a>
                    <div className="form-group">
                        <h5 style={{fontSize: "1rem"}}>Subscription</h5>
                        <div style={{paddingLeft: "75px", height: "75px", position: "relative"}} >
                            <img style={{height: "50px", position: "absolute", left: "0"}} src={require("../../../../assets/images/certified_regular.png")}/>
                            <p style={{margin: "0", fontSize: "1.2rem"}}>Plan Name</p>
                            <span>Monthly</span>

                            <p style={{margin: "0", position: "absolute", right: "0", top: "0"}}>$30.00</p>
                        </div>
                        <div className="row justify-content-end">
                            <div className="col-4" style={{textAlign: "right", fontSize: ".9rem"}}>
                                <p style={{margin: "0"}}>Subtotal:</p>
                                <p style={{margin: "0"}}>Tax:</p>
                                <p style={{margin: "0"}}>Total:</p>
                            </div>
                            <div className="col-4" style={{textAlign: "right", fontSize: ".9rem"}}>
                                <p style={{margin: "0"}}>$30.00</p>
                                <p style={{margin: "0"}}>$0.00</p>
                                <hr style={{margin: "0"}}/>
                                <p style={{margin: "0"}}>$30.00</p>
                            </div>
                        </div>
                    </div>

                    <hr/>

                    <div className="form-group">
                        <h5 style={{fontSize: "1rem"}}>Card details</h5>
                        <CreditCardInput
                            cardNumberInputProps={{ value: this.state.cardNumber, onChange: this.handleCardNumberChange }}
                            cardExpiryInputProps={{ value: this.state.expiry, onChange: this.handleCardExpiryChange }}
                            cardCVCInputProps={{ value: this.state.cvc, onChange: this.handleCardCVCChange }}
                            fieldStyle={{height: "45px"}}
                            containerStyle={{padding: "0px !important"}}
                            containerClassName="card"
                            inputStyle={{marginTop: "4px"}}
                            invalidStyle={{borderRadius: "0px"}}
                            dangerTextStyle={{position: "absolute"}}
                        />
                    </div>


                    <form style={{position: "relative", marginTop: "50px"}}>
                        <h5 style={{fontSize: "1rem"}}>Billing Information</h5>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <span style={{fontSize: "13px", color:"#00000096", fontWeight: "500"}}>First name</span>
                                <div className="card" style={{paddingLeft: "10px"}}>
                                    <input ref="firstName" type="text"/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <span style={{fontSize: "13px", color:"#00000096", fontWeight: "500"}}>Last name</span>
                                <div className="card" style={{paddingLeft: "10px"}}>
                                    <input ref="lastname" type="text"/>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <span style={{fontSize: "13px", color:"#00000096", fontWeight: "500"}}>Billing address</span>
                            <div className="card" style={{paddingLeft: "10px"}}>
                                <input ref="address" type="text"/>
                            </div>
                        </div>
                        <div className="form-group">
                            <span style={{fontSize: "13px", color:"#00000096", fontWeight: "500"}}>State / Province</span>
                            <div className="card" style={{paddingLeft: "10px"}}>
                                <input ref="state" type="text"/>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <span style={{fontSize: "13px", color:"#00000096", fontWeight: "500"}}>City</span>
                                <div className="card" style={{paddingLeft: "10px"}}>
                                    <input ref="city" type="text"/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <span style={{fontSize: "13px", color:"#00000096", fontWeight: "500"}}>Zip or postal code</span>
                                <div className="card" style={{paddingLeft: "10px"}}>
                                    <input ref="zip" type="text"/>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <span style={{fontSize: "13px", color:"#00000096", fontWeight: "500"}}>Email</span>
                                <div className="card" style={{paddingLeft: "10px"}}>
                                    <input ref="email" type="email" defaultValue={Parse.User.current().get("email")}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <span style={{fontSize: "13px", color:"#00000096", fontWeight: "500"}}>Phone number</span>
                                <div className="card" style={{paddingLeft: "10px"}}>
                                    <input ref="phoneNumber" type="text"/>
                                </div>
                            </div>
                        </div>


                        <button type="submit" className="btn btn-hubchart" style={{width: "150px", fontSize: "16px", float: "right", display: "block"}}>Confirm</button>
                    </form>
                </div>
        )
    }
}

export default PaymentContainer
