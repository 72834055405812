import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { parseAction } from '../../../actions/REST';
import Loader from "../../Common/Loader";
import PatientInfo from './PatientInfo';
import ChiefComplaints from './ChiefComplaints';
import Subjective from './Subjective';
import VitalSigns from './VitalSigns';
import PhysicalExam from './PhysicalExam';
import Assessments from './Assessments';
import MedicalDecisionMaking from './MedicalDecisionMaking';
import AddendumNote from './AddendumNote';
import ProcedureNotes from './ProcedureNotes';
import FollowUps from './FollowUps';
import CptCode from './CptCode';
import Orders from './Orders';
import SoapNote from './SoapNote';
import RteNote from './RteNote';
import ProblemNote from './ProblemNote';
import ESign from './ESign';
import { getProgressNote } from '../../../actions/ProgressNotesAction';
import config from '../../../config';

import './index.css';

const $ = window.$;

function ProgressNoteView({ patientId, progressNoteId, printView = 0 }) {
  const dispatch = useDispatch();
  const [patient, setPatient] = useState({});
  const [progressNote, setProgressNote] = useState({});
  const [templateType, setTemplateType] = useState('default');
  const [isLoading, setIsLoading] = useState(false);
  const {
    chiefComplaints = [],
    subjective = '',
    vitalSigns = {},
    physicalExam = {},
    assesments = [
      { problem: '', assessmentPlan: ''},
      { problem: '', assessmentPlan: ''},
      { problem: '', assessmentPlan: ''},
      { problem: '', assessmentPlan: ''},
    ],
    labs = {},
    medicalDecisionMaking = '',
    addendumNote = '',
    procedureNotes = '',
    followUps = {
      in: 0,
      duration: [],
      date: '',
      provider: '',
    },
    dateSeen,
    cptCode = '',
    posCode = '',
    modifierCode = '',
    encounterType = '',
    status
  } = progressNote;

  function fetchPatientInfo (objectId) {
    return parseAction("get", config.BASE_URL + "/parse/classes/Circle", {
      where: { objectId },
      include: [
        "facility",
        "serviceLocation",
        "primaryInsurance",
        "primaryInsurance.provider",
        "secondaryInsurance",
        "secondaryInsurance.provider",
        "otherInsurance",
        "otherInsurance.provider",
        "prescriber"
      ],
    })
    .then(result => {
      const { results = [] } = result;
      setPatient(results[0]);
    })
    .catch(error => {
      console.log(error);
    });
  }

  function handleGetProgressNote (noteId) {
    return getProgressNote(noteId)(dispatch).then(res => {
      setProgressNote(res);
      setTemplateType(res.templateType || 'default');
    }, err => {
      console.log(err);
    });
  }

  function handlePrint () {
    window.print();
    window.close();
  }

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      fetchPatientInfo(patientId),
      handleGetProgressNote(progressNoteId),
    ])
    .then(() => {
      setIsLoading(false);
      if (printView) {
        handlePrint();
      }
    })
    .catch(() => setIsLoading(false));
  }, [patientId, progressNoteId]);

  return (
    <div className="note-view">
      <div className="container">
        <div id="section-to-print">
          {(templateType === 'default' || templateType === 'virtual') && (
            <div className="pn-container">
              <div className="pn-header">
                <span>Vital Health Medical Group</span>
                <span className="hc-primary-text">6245 2nd flr De Longpre Ave, Hollywood, CA 90028</span>
                <span className="hc-primary-text">Tel no. (323)315-0911 Fax (323)</span>
              </div>
              <PatientInfo patient={patient} dateOfService={dateSeen} encounterType={encounterType} />
              <div style={{ textAlign:'center' }}>
                <h2>Progress Note</h2>
              </div>
              <ChiefComplaints chiefComplaints={chiefComplaints} />
              <Subjective subjective={subjective} />
              <VitalSigns vitalSigns={vitalSigns} />
              <PhysicalExam physicalExam={physicalExam} templateType={templateType} />
              <div className="two-columns">
                <Assessments assessments={assesments} />
                <Orders orders={labs} />
              </div>
              <MedicalDecisionMaking medicalDecisionMaking={medicalDecisionMaking} />
              <AddendumNote addendumNote={addendumNote} />
              <ProcedureNotes procedureNote={procedureNotes} />
              <div className="two-columns">
                <FollowUps followUps={followUps} />
                <CptCode 
                  cptCode={cptCode} 
                  encounterType={encounterType} 
                  posCode={posCode}
                  modifierCode={modifierCode}
                />
              </div>
              <hr style={{ margin: '10px 0px' }} />
              <div className="two-columns">
                <div />
                <ESign 
                  dateOfService={dateSeen} 
                  prescriber={patient.prescriber} 
                  status={status}
                />
              </div>
            </div>
          )}
        </div>
        {templateType === 'SOAP' && <SoapNote progressNote={progressNote} patient={patient} />}
        {templateType === 'RTE' && <RteNote progressNote={progressNote} patient={patient} />}
        {templateType === 'PROBLEM' && <ProblemNote progressNote={progressNote} patient={patient} />}
      </div>
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default ProgressNoteView;
