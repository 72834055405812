import React, { useEffect } from 'react';

const DataTrack = ({ track, onMessage }) => {

  useEffect(() => {

    if (!track) return;

    const handleMessage = (message) => {

      try {

        const obj = JSON.parse(message);

        onMessage(obj);
      }
      catch(err) {
        console.error('handleMessage(): Invalid message. We only accept JSON');
        console.log('Received message: ' + message);
      }
    }

    track.on('message', handleMessage);

    return () => {
      track.off('message', handleMessage);
    };
  }, [track]);

  return null;
}

export default DataTrack;
