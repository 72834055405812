import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import './index.css';

function AssessmentComponent({
  assessment = '',
  updateAssessment = () => {},
  clearField = () => {},
  isDisabled = false,
}) {

  function handleOnchange (e) {
    e.preventDefault();
    updateAssessment(e);
  }

  function handleClear (name) {
    clearField(name);
  }

  return (
    <div className="card custom-card">
      <h5 className="card-header ">Assessment</h5>
      <div className="card-body" style={{ padding: '10px' }}>
        <div className="custom-form-group-container txt-clr" style={{ margin: '5px' }}>
          <TextareaAutosize 
            id="assessment" 
            name="assessment" 
            type="text" 
            className="form-control custom-textarea" 
            placeholder="Assessment" 
            onChange={e => handleOnchange(e)}
            value={assessment}
            disabled={isDisabled}
            minRows={2} />
          {!isDisabled && (
            <i 
            className="fal fa-times clear-textarea text-danger"
            onClick={() => handleClear('assessment')}></i>
          )}
        </div>
      </div>
    </div>
  );
}

export default AssessmentComponent;
