import React, { useEffect, Fragment, useState } from 'react';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import {  useSelector, useDispatch, shallowEqual, connect } from 'react-redux';
import { Calendar as BigCalendar, momentLocalizer  } from 'react-big-calendar';
import { getMeetings, signInToGoogle, loadGoogleApi, setFilteredSchedules } from '../../../actions/ConferenceAction';
import ConferenceMain from '../../../components/Pages/ConferenceMain';
import TabLoader from '../../Common/TabLoader';
import config from '../../../../src/config';
import { telemedUrl } from '../../../helper/util';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './index.css';

const $ = window.$;

const CalendarView = () => {
  const dispatch = useDispatch();
  const localizer = momentLocalizer(moment);
  const [active_button, setActiveButton] = useState('TELEMED');
  const {
    isLoadingSchedules,
    isGoogleSignedIn,
    google_calendar_schedules,
    selected_schedules,
    schedules,
  } = mapStateToProps();

  function mapStateToProps() {
    return useSelector(state => ({
        isLoadingSchedules: state.conference.isLoadingSchedules,
        isGoogleSignedIn: state.conference.isGoogleSignedIn,
        google_calendar_schedules: state.conference.google_calendar_schedules,
        selected_schedules: state.conference.selected_schedules,
        schedules: state.conference.schedules,
    }), shallowEqual);
  }

  function handleAuthClick() {
    signInToGoogle()(dispatch);
  }

  function listUpcomingEvents() {
    getMeetings()(dispatch);
  }

  function restructureSchedules (scheds = []) {
    const rsScheds = scheds.map(sched => {
      if (sched.id) {
        return {
          orig: sched,
          id: sched.id,
          title: sched.summary,
          start: new Date(sched.start.dateTime),
          end: new Date(sched.end.dateTime),
          allDay: false,
          description: sched.description || '',
          attendees: sched.attendees,
        }
      } else {
        const dateFinished = !sched.dateFinished ? sched.dateScheduled.iso : sched.dateFinished.iso;
        return {
          orig: sched,
          id: sched.objectId,
          title: sched.label,
          start: new Date(sched.dateScheduled.iso),
          end: new Date(dateFinished),
          allDay: false,
          description: sched.comments || '',
          attendees: sched.participants,
        }
      }
    });

    return Array.from(new Set(rsScheds.map(a => a.title)))
    .map(title => (rsScheds.find(a => a.title === title)));

    // return scheds.map(sched => ({
    //   orig: sched,
    //   id: sched.id,
    //   title: sched.summary,
    //   start: new Date(sched.start.dateTime),
    //   end: new Date(sched.end.dateTime),
    //   allDay: false,
    //   description: sched.description || '',
    // }));
  }

  function handleClientLoad() {
    loadGoogleApi({ 
      apiKey: config.REACT_APP_GOOGLE_API_KEY, 
      clientId: config.REACT_APP_GOOGLE_CLIENT_ID, 
    }, () => {
      listUpcomingEvents();
    })(dispatch);
  }

  function handleSetFilteredSchedules (str = 'all') {
    setFilteredSchedules([...google_calendar_schedules, ...schedules], str)(dispatch);
  }

  function Event({ event }) {
    $('[data-toggle="popover"]').popover();
    function dataHtml (ev) {
      const start = moment(ev.start).format('hh:mm a');
      const end = moment(ev.end).format('hh:mm a');
      const splitDesc = ev.description.split('Hubchart Link:');
      const link = splitDesc[splitDesc.length - 1].replace(/\s/g, '');
      const attendees = ev.attendees || [];
      let comments = 'N/A';
      let att = '';
      
      if (splitDesc.length > 1) 
        comments = splitDesc[0];
      
      if (attendees.length > 0) {
        for (let i = 0; i < attendees.length; i++) {
          att = att + `<li>${attendees[i].email || attendees[i].displayName}</li>`
        }
      } else {
        att = 'N/A'
      }
      
      return `
        <div>
          <div class="mb-xs">
            <strong>Time: </strong><br />
            <span>${start}</span> - <span>${end}</span>
          </div>
          <div class="mb-xs">
            <strong>Description:</strong><br />
              <span>${comments}</span><br />
          </div>
          <div class="mb-xs">
            ${link ? `<strong>Hubchart Link:</strong><br /><span>${link}</span>` : `` }
          </div>
          <div class="mb-xs">
            <strong>Attendees:</strong><br />
            ${att !== 'N/A' ? `<ul>${att}</ul>` : att }
            </div>
          <div>
        </div>
      `
    }

    return (
      <a 
        style={{ fontSize: '11px' }}
        data-toggle="popover" 
        data-trigger="hover"
        data-html="true"
        data-title={event.title}
        data-content={dataHtml(event)}>{event.title}</a>
    )
  }

  useEffect(() => {
    handleClientLoad();
  }, []);

  return (
    <Fragment>
      {/* {this.props.isMobile && (
        <div className="calendar-header">
          <i 
            onClick={() => {}} 
            className="fas fa-chevron-left" 
            style={{ 
              fontSize: "24px", 
              color: "#908e8e",
            }} 
          />
          <span style={{ fontSize: "20px" }}>View Logs</span>
        </div>
      )} */}
      <div className="calendar-view-container">
        {!isLoadingSchedules && (
          <Fragment>
            {!isGoogleSignedIn && (
              <button
                onClick={() => handleAuthClick()}
                className="btn btn-hubchart btn-hubchart-primary">
                  Sync With Google Calendar
              </button>
            )}
            <div className="calendar-container">
              <BigCalendar
                localizer={localizer}
                events={restructureSchedules([...selected_schedules, ...schedules])}
                startAccessor="start"
                endAccessor="end"
                views={['month', 'week', 'day']}
                className="big-calendar"
                eventPropGetter={event => {
                  const joined = event.orig.joined || [];
                  let bg = event.description.includes(telemedUrl) ? '#2194f3' : 'red';
                  let newBg = joined.length > 0 ? bg : '#949494';
                  var style = {
                      backgroundColor: newBg,
                      opacity: 0.8,
                      border: '0px',
                      display: 'block'
                  };
                  return { style: style };
                }}
                components={{
                  event: Event
                }}
                messages={{ 
                  showMore: (target) => (
                    <span 
                      style={{ fontSize: '11px' }}
                      className="ml-2" 
                      role="presentation"> +{target} schedules</span>
                  )
                }}
                // onNavigate={(date) => this.setState({ date })}
              />
              <div className="btn-group" role="group" style={{ marginTop: '10px' }}>
                <button 
                  type="button" 
                  className={`event-button btn btn-light btn-sm ${active_button === 'ALL' ? 'btn-active' : ''}`}
                  onClick={() => {
                    handleSetFilteredSchedules('ALL');
                    setActiveButton('ALL');
                  }}>All Events</button>
                <button 
                  type="button" 
                  className={`event-button btn btn-light btn-sm ${active_button === 'TELEMED' ? 'btn-active' : ''}`}
                  onClick={() => {
                    handleSetFilteredSchedules('TELEMED');
                    setActiveButton('TELEMED');
                  }}>Telemed Events</button>
              </div>
            </div>
          </Fragment>
        )}
        <ConferenceMain />
        {isLoadingSchedules && <TabLoader isLoading={isLoadingSchedules} />}
      </div>
    </Fragment>
  );
}

const mapStateToProps = state => ({
  contactInitialLoad : state.contact.initialLoad,
  contacts : state.contact.contacts,
  page : state.contact.contactPage,
})

export default connect(mapStateToProps, {})(CalendarView);