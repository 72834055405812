import React, { useState } from 'react';
import moment from 'moment';
import './AvailableTimeChecker.css';
import { TimeSlots } from './config';

const AvailableTimeChecker = ({
    schedule_date = moment(),
    setStartTime = () => {},
    schedules = [],
}) => {
    const [time_slots, setTimeSlots] = useState(TimeSlots);
    
    function handleDisabledSchedules (scheds) {
        const datesToday = scheds.filter(sched => {
            const dateObj = sched.objectId ? sched.dateScheduled.iso : sched.start.dateTime;
            return schedule_date.isSame(dateObj, 'day');
        });
        const start_times = [];
        const end_times = [];
        
        const newTimeSlots = time_slots.map(timeslot => {
            const filtered_start = datesToday.filter(sched => {
                const dateObj = sched.objectId ? sched.dateScheduled.iso : sched.start.dateTime;
                return moment(dateObj).format('HH:mm') === timeslot.value;
            });
            const filtered_end = datesToday.filter(sched => {
                const dateObj = sched.objectId ? sched.dateFinished.iso : sched.end.dateTime;
                return moment(dateObj).format('HH:mm') === timeslot.value;
            });
            
            if (filtered_start.length > 0) {
                const dateObj = filtered_start[0].objectId 
                    ? filtered_start[0].dateScheduled.iso : filtered_start[0].start.dateTime;
                const myString = moment(dateObj).format('HH:mm');
                start_times.push(`${myString}`);
            }
            
            if (filtered_end.length > 0) {
                const dateObj = filtered_end[0].objectId 
                    ? filtered_end[0].dateFinished.iso : filtered_end[0].end.dateTime;
                const myString = moment(dateObj).format('HH:mm');
                end_times.push(`${myString}`);
            }

            const has_same = filtered_start.length > 0 ? true : false;
            const meeting_details = filtered_start.length > 0 ? ((filtered_start[0].label || filtered_start[0].summary) || 'No title') : '';

            return {
                ...timeslot,
                meeting_details, 
                disabled: has_same,
            }
        });

        let isInside = false;
        const finalTimeSlots = newTimeSlots.map(timeslot => {
            const filtered_start = start_times.filter(starttime => starttime === timeslot.value);
            const filtered_end = end_times.filter(endtime => endtime === timeslot.value);

            if (filtered_start.length > 0) {
                isInside = true;
            }
            if (filtered_end.length > 0) {
                isInside = false;
            }
            if (filtered_start.length > 0 && filtered_end.length > 0) {
                isInside = true;
            }

            return {
                ...timeslot,
                disabled: isInside,
            }
        });

        setTimeSlots(finalTimeSlots);
    }

    function handleAppendTime (time, isDisabled) {
        if (isDisabled) return;
        const parsedTime = time.split(':');
        const schedDate = moment(schedule_date).toDate();
        schedDate.setHours(parsedTime[0]);
        schedDate.setMinutes(parsedTime[1]);

        setStartTime(moment(schedDate));
    }

    return (
        <div className="dropdown">
            <button 
                type="button" 
                className="btn contact-menu-button dropdown-toggle" 
                id="avTimeMenuButton" 
                data-toggle="dropdown" 
                aria-haspopup="true" 
                aria-expanded="false"
                style={{ marginRight: '2px' }}
                onClick={() => handleDisabledSchedules(schedules)}>
                Find a time
            </button>
            <div 
                style={{ boxShadow: '0px 4px 8px 0px #888888' }}
                className="dropdown-menu dropdown-menu-right" 
                aria-labelledby="avTimeMenuButton">
                <h4 
                    className="dropdown-header" 
                    style={{ width: '400px' }}>
                    {schedule_date.format('dddd, MMMM Do YYYY')}
                </h4>
                <br />
                <div className="timeslot-container">
                    {
                        time_slots.map(item => (
                            <div className="timeslot-item" key={item.value}>
                                <div>
                                    <span>{item.label}</span>
                                </div>
                                <div 
                                    className={item.disabled ? 'disabled-background' : ''}
                                    onClick={() => handleAppendTime(item.value, item.disabled)}>
                                    {item.meeting_details && <div className="slot-divider" />}
                                    <span 
                                        className="timeslot-item-title">
                                        {item.meeting_details ? `(${item.meeting_details})` : ''}
                                    </span>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default AvailableTimeChecker;
