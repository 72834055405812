import React, { useEffect, useState, useMemo, Fragment } from "react";
import moment from "moment";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import ConfirmModal from "../../TabList/ConferenceTab/ConfirmModal";
import ForwardModal from "../../Conversation/Forward/ForwardModal2";
import ForwardFilterModal from "../../Conversation/Forward/ForwardFilterModal";
import { parseAction } from "../../../actions/REST";
import TabLoader from "../../Common/TabLoader";
import ProgressNoteModal from "./ProgressNotesModal";
import SoapProgressNoteModal from "./ProgressNotesModal/SoapNote";
import RteNote from "./ProgressNotesModal/RteNote";
import ProblemNote from "./ProgressNotesModal/ProblemNote";
import Notify from "../ConferenceCall2/Notify";
import config from "../../../config";
import { defaultTemplate, defaultVirtualTemplate, handleNoteObject, setLinkFormat } from "./config";
import {
  getProgressNotes,
  getDeletedProgressNotes,
  deleteProgressNote,
  restoreProgressNote,
  setProgressNotesPage,
} from "../../../actions/ProgressNotesAction";
import { fetchThreads } from "../../../actions/ThreadAction";
import { fetchOrganization } from "../../../actions/SessionAction";
import { getMembers } from "../../../actions/GroupAction";
import defaultPn from "../../../assets/images/progress-notes/default-pn.png";
import soapPn from "../../../assets/images/progress-notes/soap-pn.png";
import freeText from "../../../assets/images/progress-notes/freetext-pn.png";
import util from "../../../helper/util";

import "./index.css";

const ProgressNotes = ({ patientId = "" }) => {
  const [progressNoteModalShown, setProgressNoteModalShown] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [template, setTemplate] = useState({});
  const [patientInfo, setPatientInfo] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [preventReload, setPreventReload] = useState(false);
  const [isLoadingMorePatients, setIsLoadingMorePatients] = useState(false);
  const [isAddendumNote, setIsAddendumNote] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [progressNoteId, setProgressNoteId] = useState("");
  const [templateType, setTemplateType] = useState("default");
  const [activeNotesList, setActiveNotesList] = useState("active");
  const [message, setMessage] = useState(null);
  const [forwardModalShown, setForwardModalShown] = useState(false);
  const [groupMembers, setGroupMembers] = useState([]);
  const [tempMembers, setTempMembers] = useState([]);
  const [guestMembers, setGuestMembers] = useState([]);
  const [openForwardFilter, setForwardFilter] = useState(false);
  const [groupFilter, setGroupFilter] = useState("");

  const dispatch = useDispatch();
  const {
    progressNotes: progressNotesData,
    deletedProgressNotes: deletedProgressNotesData,
    currentPage,
    deletedCurrentPage,
    isLoading,
    organization,
  } = useSelector(mySelector, shallowEqual);

  useEffect(() => {
    dispatch(fetchThreads());
  }, []);

  const progressNotes = useMemo(() => {
    return progressNotesData.length > 0
      ? progressNotesData.sort((a, b) => {
          return new Date(b.dateSeen) - new Date(a.dateSeen);
        })
      : [];
  }, [progressNotesData]);

  const deletedProgressNotes = useMemo(() => {
    return deletedProgressNotesData.length > 0
      ? deletedProgressNotesData.sort((a, b) => {
          return new Date(b.dateSeen) - new Date(a.dateSeen);
        })
      : [];
  }, [deletedProgressNotesData]);

  function handleOpenProgressNoteModalShown(title = "CREATE PROGRESS NOTE", item = {}, isAppend, isDuplicate) {
    handleStandByPrompt(true);
    setTemplateType(item.templateType || "default");
    setModalTitle(title);
    setTemplate(item);

    if (isDuplicate) {
      setProgressNoteId("");
    }
    if (!isDuplicate) {
      setProgressNoteId(item.objectId);
    }
    if (isAppend) {
      setIsAddendumNote(true);
    }

    setProgressNoteModalShown(true);
  }

  function handleCloseProgressNoteModalShown() {
    handleStandByPrompt(false);
    setProgressNoteModalShown(false);
    setIsAddendumNote(false);
    setTemplate({});
  }

  function fetchPatientInfo(objectId) {
    parseAction("get", config.BASE_URL + "/parse/classes/Circle", {
      where: { objectId },
      include: [
        "facility",
        "serviceLocation",
        "primaryInsurance",
        "primaryInsurance.provider",
        "secondaryInsurance",
        "secondaryInsurance.provider",
        "otherInsurance",
        "otherInsurance.provider",
      ],
    })
      .then((result) => {
        const { results = [] } = result;
        setPatientInfo(results[0]);
        const temp = JSON.parse(localStorage.getItem(`${patientId}_progressnote`) || null);
        if (temp) {
          const title = temp.objectId ? "UPDATE PROGREDSS NOTE" : "CREATE PROGRESS NOTE";
          setProgressNoteId(temp.objectId);
          handleStandByPrompt(true);
          setTemplateType(temp.templateType || "default");
          setModalTitle(title);
          setTemplate(temp);
          setProgressNoteModalShown(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleSetLocalStorageNull() {
    localStorage.setItem(`${patientId}_progressnote`, null);
    localStorage.removeItem("someModalIsOpen");
  }

  function handleRefreshData() {
    setIsAddendumNote(false);
    // setTemplate({});
    setPreventReload(false);
    setIsLoadingMorePatients(false);

    setProgressNotesPage(0, () => {
      getProgressNotes(patientId)(dispatch);
    })(dispatch);
  }

  function handleScroll(event) {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    if (preventReload) return;
    const bottom = scrollHeight - scrollTop <= clientHeight;
    if (bottom) {
      if (!isLoadingMorePatients && !preventReload) {
        loadMoreProgressNotes();
        setIsLoadingMorePatients(true);
      }
    }
  }

  function loadMoreProgressNotes() {
    if (activeNotesList === "active") {
      getProgressNotes(
        patientId,
        currentPage + 1,
        progressNotes
      )(dispatch)
        .then((items) => {
          if (items.length === 0) {
            setPreventReload(true);
          }
          setIsLoadingMorePatients(false);
        })
        .catch(() => {
          setIsLoadingMorePatients(false);
        });
    }
    if (activeNotesList === "deleted") {
      getDeletedProgressNotes(
        patientId,
        deletedCurrentPage + 1,
        deletedProgressNotes
      )(dispatch)
        .then((items) => {
          if (items.length === 0) {
            setPreventReload(true);
          }
          setIsLoadingMorePatients(false);
        })
        .catch(() => {
          setIsLoadingMorePatients(false);
        });
    }
  }

  function handleDeleteProgressNote(data) {
    setSelectedItem(data);
    setShowConfirmModal(true);
    setIsAddendumNote(false);
  }

  function handleConfirmOnClose(data) {
    setShowConfirmModal(false);
    deleteProgressNote(progressNotes, data.objectId)(dispatch);
  }

  function handleStatus(status) {
    return status === "DONE" ? status : "--";
  }

  function openProgressNote(noteId, param = "") {
    const filesUrl = [window.location.origin, "/progressnotes/" + patientId, "/view/" + noteId, param].join("");

    window.open(filesUrl, "Hubchart Files");
  }

  function handleStandByPrompt(modalOpened) {
    if (modalOpened) {
      window.onbeforeunload = (event) => {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = "";
        }
        return "";
      };
    } else {
      window.onbeforeunload = () => {};
    }
  }

  function handleForwardLinkModalShown(data) {
    let msLink = "\n";
    msLink = `${msLink} ${window.location.origin}/progressnotes/${patientId}/view/${data.objectId}\n`;
    const newMessage = setLinkFormat(msLink, data, patientInfo);
    setSelectedItem(data);
    setMessage(newMessage);
    setForwardFilter(true);
    // setForwardModalShown(true);
  }

  function handleCopyLink(data) {
    var copyText = `${window.location.origin}/progressnotes/${patientId}/view/${data.objectId}`;
    const elem = document.createElement("textarea");

    elem.value = copyText;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);

    Notify.success("Progress note link copied!");
  }

  function handleForwardObjectModalShown(data) {
    setForwardFilter(true);
    const toBeForwarded = handleNoteObject(data, patientInfo);
    setSelectedItem(data);
    setMessage(toBeForwarded);
    // setForwardModalShown(true);
  }

  function handleForwardModalShown(data) {
    setForwardModalShown(true);
  }

  function closeAllForwardModal() {
    setForwardModalShown(false);
    setForwardFilter(false);
  }

  function handleErrorNotification() {
    const content = (
      <span>
        <i className="fas fa-exclamation-circle mr-1"></i>
        Please fill in the required fields.
      </span>
    );
    toast.error(content, {
      position: "top-right",
      className: "toast-base-main",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
      pauseOnHover: true,
    });
  }

  function handleGetMembers(id) {
    getMembers(id)(dispatch)
      .then((result) => {
        const { regMembers = [], orgMembers = [], guests = [] } = result;
        const filteredMembers = orgMembers.filter((item) => item.objectId !== patientId);

        let wholeMembers = regMembers;
        if (orgMembers.length !== 0) {
          wholeMembers = wholeMembers.concat(orgMembers);
        }

        let tempMembers = [];
        if (wholeMembers.length !== 0) {
          tempMembers = Array.from(wholeMembers.reduce((m, t) => m.set(t.objectId, t), new Map()).values());
        }

        setGroupMembers(tempMembers);
        setTempMembers(tempMembers);
        setGuestMembers(guests);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    fetchPatientInfo(patientId);
    handleGetMembers(patientId);
    if (activeNotesList === "active") {
      getProgressNotes(patientId)(dispatch);
    }
    if (activeNotesList === "deleted") {
      getDeletedProgressNotes(patientId)(dispatch);
    }
    fetchOrganization()(dispatch);
    return () => {};
  }, [patientId, activeNotesList]);

  return (
    <div className="progress-notes-container pn-con" onScroll={handleScroll}>
      <div className="container mb-15 pn-title-flex">
        <span className="pn-title">
          <i className="fas fa-file-alt"></i> Progress Notes
        </span>
        <div className="pn-patient-details-flex">
          <div>
            <span>
              <strong>{patientInfo.name || "--"}</strong>
            </span>
            <span>{`${patientInfo.dob || "--"} (${util.computeAge(patientInfo.dob) || "0"} yo)`}</span>
          </div>
          {(typeof (patientInfo.image || {}).url === "undefined" || (patientInfo.image || {}).url === "") && (
            <img src={require("../../../assets/images/default.png")} />
          )}
          {(patientInfo.image || {}).url && <img src={(patientInfo.image || {}).url} />}
        </div>
      </div>
      <div className="pn-template-container">
        <div className="container pb-10">
          <div className="document-titles">
            <span>Start New Progress Note</span>
          </div>
          <div className="template-flex">
            <div
              className="template-container"
              onClick={() => {
                handleOpenProgressNoteModalShown("CREATE PROGRESS NOTE", {
                  ...defaultTemplate,
                  templateType: "default",
                  physicalExam: {},
                });
              }}
            >
              <div className="template-item">
                <i className="fal fa-plus fa-fw fa-3x"></i>
              </div>
              <div className="template-item-text">
                <span>Progress Note</span>
              </div>
            </div>
            <div
              className="template-container"
              onClick={() => {
                handleOpenProgressNoteModalShown("CREATE PROGRESS NOTE", {
                  ...defaultTemplate,
                  templateType: "default",
                });
              }}
            >
              <div className="template-item">
                {/* <i className="fas fa-file-contract fa-fw fa-3x"></i> */}
                <img src={defaultPn} alt="pr-default" />
              </div>
              <div className="template-item-text">
                <span>Note W/ NL P.E Template</span>
                <span>Progress Note</span>
              </div>
            </div>
            <div
              className="template-container"
              onClick={() => {
                handleOpenProgressNoteModalShown("CREATE VIRTUAL EXAM PROGRESS NOTE", {
                  ...defaultVirtualTemplate,
                  templateType: "virtual",
                });
              }}
            >
              <div className="template-item">
                {/* <i className="fas fa-file-contract fa-fw fa-3x"></i> */}
                <img src={defaultPn} alt="pr-default" />
              </div>
              <div className="template-item-text">
                <span>Virtual Exam Normal Template</span>
                <span>Progress Note</span>
              </div>
            </div>
            <div
              className="template-container"
              onClick={() => {
                handleOpenProgressNoteModalShown("CREATE SOAP PROGRESS NOTE", {
                  ...defaultTemplate,
                  templateType: "SOAP",
                });
              }}
            >
              <div className="template-item">
                {/* <i className="fas fa-file-contract fa-fw fa-3x"></i> */}
                <img src={soapPn} alt="pr-soap" />
              </div>
              <div className="template-item-text">
                <span>SOAP Template</span>
                <span>SOAP Note</span>
              </div>
            </div>
            <div
              className="template-container"
              onClick={() => {
                handleOpenProgressNoteModalShown("CREATE PROGRESS NOTE", {
                  ...defaultTemplate,
                  templateType: "RTE",
                });
              }}
            >
              <div className="template-item">
                {/* <i className="fas fa-file-contract fa-fw fa-3x"></i> */}
                <img src={freeText} alt="pr-freetext" />
              </div>
              <div className="template-item-text">
                <span>Free Text Template</span>
                <span>Rich Text Editor</span>
              </div>
            </div>
            <div
              className="template-container"
              onClick={() => {
                handleOpenProgressNoteModalShown("CREATE PROGRESS NOTE", {
                  ...defaultTemplate,
                  templateType: "PROBLEM",
                });
              }}
            >
              <div className="template-item">
                {/* <i className="fas fa-file-contract fa-fw fa-3x"></i> */}
                <img src={freeText} alt="pr-freetext" />
              </div>
              <div className="template-item-text">
                <span>Problem Based Template</span>
                <span>Rich Text Editor</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container pb-10">
          <div
            style={{
              marginBottom: "10px",
              marginRight: "10px",
              textAlign: "right",
            }}
          >
            {activeNotesList === "deleted" && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setActiveNotesList("active");
                }}
              >
                View Active Notes
              </button>
            )}
            {activeNotesList === "active" && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setActiveNotesList("deleted");
                }}
              >
                View Deleted Notes
              </button>
            )}
          </div>
          {activeNotesList === "active" && (
            <div className="progress-table-container">
              <div className="progress-list-header">
                <div>Created</div>
                <div>Date Seen</div>
                <div>Compliant</div>
                {/* <div>Modified By</div> */}
                <div>Provider</div>
                <div>Status</div>
                <div>Action</div>
              </div>
              {progressNotes.map((item) => {
                const seen = item.dateSeen ? moment(item.dateSeen).format("lll") : "--";

                return (
                  <div className="progress-list-item" key={item.objectId}>
                    <div>
                      <span>{moment(item.createdAt).format("l")}</span>
                    </div>
                    <div>{seen}</div>
                    <div>{(item.chiefComplaints || []).join(", ") || "--"}</div>
                    {/* <div>Carlota Sulit</div> */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "center",
                      }}
                    >
                      {(item.visitProvider || item.clinicLocation) && (
                        <Fragment>
                          <span style={{ fontWeight: "bold" }}>{item.visitProvider}</span>
                          <span>{item.clinicLocation}</span>
                        </Fragment>
                      )}
                      {!item.visitProvider && !item.clinicLocation && (
                        <Fragment>
                          <span>--</span>
                        </Fragment>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "center",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>{handleStatus(item.status)}</span>
                      <span>{item.cptCode}</span>
                    </div>
                    <div className="dropdown dropleft drpdwn-pn">
                      <button
                        className="btn btn-default btn-sm"
                        type="button"
                        id="progressNotesDropdown"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <i className="fas fa-ellipsis-v fa-fw"></i>
                      </button>
                      <div className="dropdown-menu" aria-labelledby="progressNotesDropdown">
                        <a className="dropdown-item" onClick={() => openProgressNote(item.objectId)}>
                          View
                        </a>
                        {item.status !== "DONE" && (
                          <a
                            className="dropdown-item"
                            onClick={() => {
                              handleOpenProgressNoteModalShown("UPDATE PROGRESS NOTE", item);
                            }}
                          >
                            Edit
                          </a>
                        )}
                        {item.status === "DONE" && (
                          <Fragment>
                            <a
                              className="dropdown-item"
                              onClick={() => {
                                handleOpenProgressNoteModalShown("UPDATE PROGRESS NOTE", item, true);
                              }}
                            >
                              Append
                            </a>
                            <a
                              className="dropdown-item"
                              onClick={() => {
                                const { status, ...rest } = item;
                                handleOpenProgressNoteModalShown(
                                  "CREATE PROGRESS NOTE",
                                  {
                                    ...defaultTemplate,
                                    ...rest,
                                    dateSeen: "",
                                    followUps: {
                                      ...rest.followUps,
                                      date: "",
                                    },
                                    labs: {},
                                    encounterType: "",
                                    cptCode: "",
                                    posCode: "",
                                    modifierCode: "",
                                    addendumNote: "",
                                  },
                                  false,
                                  true
                                );
                              }}
                            >
                              Use as Template
                            </a>
                          </Fragment>
                        )}
                        <a className="dropdown-item" onClick={() => handleForwardLinkModalShown(item)}>
                          Forward Link
                        </a>
                        <a className="dropdown-item" onClick={() => handleCopyLink(item)}>
                          Copy Link
                        </a>
                        <a className="dropdown-item" onClick={() => handleForwardObjectModalShown(item)}>
                          Send as Text
                        </a>
                        <a className="dropdown-item" onClick={() => openProgressNote(item.objectId, "?print=1")}>
                          Print
                        </a>
                        <a className="dropdown-item" onClick={() => handleDeleteProgressNote(item)}>
                          Delete
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
              {progressNotes.length === 0 && !isLoading && (
                <div className="progress-table-empty">
                  <em>
                    <span>No progress notes found.</span>
                  </em>
                </div>
              )}
            </div>
          )}
          {activeNotesList === "deleted" && (
            <div className="progress-table-container">
              <div className="progress-list-header">
                <div>Created</div>
                <div>Date Seen</div>
                <div>Title</div>
                {/* <div>Modified By</div> */}
                <div>Author</div>
                <div>Status</div>
                <div>Action</div>
              </div>
              {deletedProgressNotes.map((item) => {
                const seen = item.dateSeen ? moment(item.dateSeen).format("l") : "--";

                return (
                  <div className="progress-list-item" key={item.objectId} style={{ opacity: 0.7 }}>
                    <div>
                      <span>{moment(item.createdAt).format("l")}</span>
                    </div>
                    <div>{seen}</div>
                    <div>{(item.chiefComplaints || []).join(", ") || "--"}</div>
                    {/* <div>Carlota Sulit</div> */}
                    <div>--</div>
                    <div>{handleStatus(item.status)}</div>
                    <div className="dropdown dropleft drpdwn-pn">
                      <button
                        className="btn btn-default btn-sm"
                        type="button"
                        id="progressNotesDropdown"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <i className="fas fa-ellipsis-v fa-fw"></i>
                      </button>
                      <div className="dropdown-menu" aria-labelledby="progressNotesDropdown">
                        <a
                          className="dropdown-item"
                          onClick={() => restoreProgressNote(deletedProgressNotes, item.objectId)(dispatch)}
                        >
                          Restore
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
              {deletedProgressNotes.length === 0 && !isLoading && (
                <div className="progress-table-empty">
                  <em>
                    <span>No progress notes found.</span>
                  </em>
                </div>
              )}
            </div>
          )}
          <TabLoader isLoading={isLoading} />
        </div>
      </div>
      {progressNoteModalShown && templateType === "default" && (
        <ProgressNoteModal
          isOpen={progressNoteModalShown}
          title={modalTitle}
          patientId={patientId}
          patientInfo={patientInfo}
          orgId={organization.objectId}
          template={template}
          progressNoteId={progressNoteId}
          isAddendumNote={isAddendumNote}
          groupMembers={groupMembers}
          tempMembers={tempMembers}
          guests={guestMembers}
          refreshPatient={fetchPatientInfo}
          setLocalStorageNull={handleSetLocalStorageNull}
          onClose={() => handleCloseProgressNoteModalShown()}
          refreshData={() => handleRefreshData()}
          errorNotification={() => handleErrorNotification()}
        />
      )}
      {progressNoteModalShown && templateType === "virtual" && (
        <ProgressNoteModal
          isOpen={progressNoteModalShown}
          title={modalTitle}
          patientId={patientId}
          orgId={organization.objectId}
          patientInfo={patientInfo}
          template={template}
          progressNoteId={progressNoteId}
          isAddendumNote={isAddendumNote}
          groupMembers={groupMembers}
          tempMembers={tempMembers}
          guests={guestMembers}
          refreshPatient={fetchPatientInfo}
          setLocalStorageNull={handleSetLocalStorageNull}
          onClose={() => handleCloseProgressNoteModalShown()}
          refreshData={() => handleRefreshData()}
          errorNotification={() => handleErrorNotification()}
        />
      )}
      {progressNoteModalShown && templateType === "SOAP" && (
        <SoapProgressNoteModal
          isOpen={progressNoteModalShown}
          title={modalTitle}
          patientId={patientId}
          orgId={organization.objectId}
          patientInfo={patientInfo}
          template={template}
          progressNoteId={progressNoteId}
          isAddendumNote={isAddendumNote}
          groupMembers={groupMembers}
          tempMembers={tempMembers}
          guests={guestMembers}
          setLocalStorageNull={handleSetLocalStorageNull}
          onClose={() => handleCloseProgressNoteModalShown()}
          refreshData={() => handleRefreshData()}
          errorNotification={() => handleErrorNotification()}
        />
      )}
      {progressNoteModalShown && templateType === "RTE" && (
        <RteNote
          isOpen={progressNoteModalShown}
          title={modalTitle}
          patientId={patientId}
          patientInfo={patientInfo}
          orgId={organization.objectId}
          template={template}
          progressNoteId={progressNoteId}
          isAddendumNote={isAddendumNote}
          groupMembers={groupMembers}
          tempMembers={tempMembers}
          guests={guestMembers}
          setLocalStorageNull={handleSetLocalStorageNull}
          onClose={() => handleCloseProgressNoteModalShown()}
          refreshData={() => handleRefreshData()}
          errorNotification={() => handleErrorNotification()}
        />
      )}
      {progressNoteModalShown && templateType === "PROBLEM" && (
        <ProblemNote
          isOpen={progressNoteModalShown}
          title={modalTitle}
          patientId={patientId}
          patientInfo={patientInfo}
          orgId={organization.objectId}
          template={template}
          progressNoteId={progressNoteId}
          isAddendumNote={isAddendumNote}
          groupMembers={groupMembers}
          tempMembers={tempMembers}
          guests={guestMembers}
          setLocalStorageNull={handleSetLocalStorageNull}
          onClose={() => handleCloseProgressNoteModalShown()}
          refreshData={() => handleRefreshData()}
          errorNotification={() => handleErrorNotification()}
        />
      )}
      {showConfirmModal && (
        <ConfirmModal
          event={selectedItem}
          isOpen={showConfirmModal}
          onClose={(data) => handleConfirmOnClose(data)}
          title="Delete Progress Note"
          message="Are you sure you want to delete this progress note?"
        />
      )}
      {openForwardFilter && (
        <ForwardFilterModal
          isOpen={openForwardFilter}
          onClose={setForwardFilter}
          openForward={handleForwardModalShown}
          setGroupFilter={setGroupFilter}
          openNextModal={"forward"}
        />
      )}
      {forwardModalShown && (
        <ForwardModal
          isOpen={forwardModalShown}
          onClose={closeAllForwardModal}
          messages={[{ text: message }]}
          groupFilter={groupFilter}
        />
      )}
      <ToastContainer />
    </div>
  );
};

const mySelector = (state) => ({
  progressNotes: state.notes.progressNotes,
  deletedProgressNotes: state.notes.deletedProgressNotes,
  currentPage: state.notes.currentPage,
  deletedCurrentPage: state.notes.deletedCurrentPage,
  isLoading: state.notes.isLoading,
  organization: state.session.organization,
});

export default ProgressNotes;
