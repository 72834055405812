import React from 'react'
import Block from './Block';

const Spinner = (props) => {
    const Style = {
        display: "block",
        textAlign: "center",
        position: "relative",
        top: "45%",
        transform: "translateY(45%)"
    }

    if (props.top) {
        Style.top = props.top;
    }

    return (
        <div style={Style}>
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Spinner;