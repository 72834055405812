import React from 'react';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { deletePriority, deletePriorities } from '../../../actions/ThreadAction';

const PriorityMenu = ({priorities}) => {

  const dispatch = useDispatch();

  const handleMarkAsRead = (e) => {

    const priorityIds = priorities.map(p => p.objectId);

    e.stopPropagation();
    e.preventDefault();

    dispatch(deletePriorities(priorityIds));
  };

  return (
    <div>
      <button onClick={(e) => handleMarkAsRead(e)} type="button" className="btn btn-light btn-sm rounded" data-tip="Mark as read" data-place="right">
        <i className="fas fa-check text-primary"></i>
      </button>

      <ReactTooltip effect="solid" type="dark" />
    </div>
  );
}

export default PriorityMenu;
