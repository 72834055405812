const initialState = {

	// Whether the app is in a loading state
	// which should not be interrupted
	isLoading: false,

	// Whether profile sidebar is open
	profileSidebarShown: false,

	// Whether corporate account is open
	openAccount: false,

	// The type of account shown: 'corporate', 'account', 'teams' 
	accountType: 'account',
}

export default function (state = initialState, action) {

	switch (action.type) {

		// Set isLoading
		case 'SET_LOADING':
			return {
				...state,
				isLoading: action.value
			};

		case 'SHOW_PROFILE_SIDEBAR':
			return {
				...state,
				profileSidebarShown: true,
			};

		case 'HIDE_PROFILE_SIDEBAR':
			return {
				...state,
				profileSidebarShown: false,
			};
		
		case 'SHOW_ACCOUNT':
			return {
				...state,
				profileSidebarShown: true,
				openAccount: true,
				accountType: action.accountType,
			};

		case 'HIDE_ACCOUNT':
			return {
				...state,
				openAccount: false,
			};

		default:
			return state;
	}
}
