import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Spinner from "./Spinner";
import Logo from "../../assets/images/logo-blue.png";

class Loader extends Component {
  render() {
    const { isLoading, message = "Please wait..." } = this.props;
    return (
      <div
        className="loader"
        style={{
          display: isLoading ? "inline-grid" : "none",
        }}
      >
        <div align="center">
          <div className="loader-container">
            <div>
              <div>
                <img
                  className="loader-logo"
                  src={Logo}
                  style={{
                    width: "40px",
                  }}
                  alt="Solar Suite"
                />
                <span className="loader-brand">HUBCHART</span>
              </div>
              <div className="loader-message">
                <span>{message}</span>
                <Spinner size={5} color={"#fbba09"} loading={isLoading} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Loader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  message: PropTypes.string,
};

export default withRouter(Loader);
