import React, { useEffect } from 'react';

import './index.css';

function PlanComponent({
  plan = '',
}) {

  return (
    <div className="card custom-card">
      <h5 className="card-header ">
        <strong>Plan</strong>
      </h5>
      <div className="card-body">
        <div className="custom-form-group-container pe-text">
          {plan || 'No plan added.'}
        </div>
      </div>
    </div>
  );
}

export default PlanComponent;
