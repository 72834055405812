import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { getRecentPatients } from '../../../../../actions/PatientAction';

const usePatients = () => {

  // Dispatch
  const dispatch = useDispatch();

  // Get values from redux store
  const {
    initialLoad,
    recentPatients,
    isLoadingSearch,
    searchResults,
    isSearching,
  } = useSelector(mySelector, shallowEqual);

  useEffect(() => {
    
    if (!initialLoad) return;

    dispatch(getRecentPatients());
  }, [initialLoad]);

  return {
    items: recentPatients,
    searchItems: searchResults,
    isLoading: initialLoad || isLoadingSearch,
    isSearching,
  };
}

const mySelector = state => ({
  initialLoad: state.patient.initialLoad,
  recentPatients: state.patient.recentPatients,
  isLoadingSearch: state.patient.isLoadingSearch,
  searchResults: state.patient.searchResults,
  isSearching: state.patient.isSearching,
});

export default usePatients;
