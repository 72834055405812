import {
  SET_PROGRESS_NOTES,
  SET_PROGRESS_NOTES_PAGE,
  SET_PROGRESS_NOTES_ALL,
  SET_PROGRESS_NOTES_PENDING,
  SET_PROGRESS_NOTES_DONE,
  SET_PROGRESS_NOTES_RECENT,
  SET_PROGRESS_NOTES_PAGE_ALL,
  SET_DELETED_PROGRESS_NOTES,
  SET_DELETED_PROGRESS_NOTES_PAGE,
  PROGRESS_NOTES_LOADING,
  SET_PROGRESS_NOTE,
  PROGRESS_NOTE_LOADING,
  IS_CREATING_PROGRESS_NOTE,
  IS_UPDATING_PROGRESS_NOTE,
  IS_DELETING_PROGRESS_NOTE,
  DELETE_PROGRESS_NOTE_SUCCESS,
  RESTORE_PROGRESS_NOTE_SUCCESS,
  SET_PROGRESS_NOTES_PLANS,
  PROGRESS_NOTES_PLANS_LOADING,
  SET_DIAGNOSTIC_STUDIES,
  SET_REFERRALS,
  SET_SUPPLIES,
  CHECK_UNCHECK_PLANS,
  HAS_MORE_PROGRESS_NOTE
} from "../actions/types";

const initialState = {
  progressNote: {},
  progressNotes: [],
  deletedProgressNotes: [],
  allProgressNotes: [],
  allProgressNotesRecent: [],
  plans: [],
  diagnosticStudies: [],
  referrals: [],
  medicalSupplies: [],
  isLoading: false,
  isUpdating: false,
  currentPage: 0,
  deletedCurrentPage: 0,
  allCurrentPage: 0,
  hasMorePN: true
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_PROGRESS_NOTES_ALL:
      return {
        ...state,
        allProgressNotes: action.data,
        pageProgressNotesAll: action.page,
        hasMoreProgressNotesAll: action.hasMore
      };

    case SET_PROGRESS_NOTES_PENDING:
      return {
        ...state,
        allProgressNotesPending: action.data,
        pageProgressNotesPending: action.page,
        hasMoreProgressNotesPending: action.hasMore
      };

    case SET_PROGRESS_NOTES_DONE:
      return {
        ...state,
        allProgressNotesDone: action.data,
        pageProgressNotesDone: action.page,
        hasMoreProgressNotesDone: action.hasMore
      };

    case SET_PROGRESS_NOTES_RECENT:
      return {
        ...state,
        allProgressNotesRecent: action.data,
        pageProgressNotesRecent: action.page,
        hasMoreProgressNotesRecent: action.hasMore
      };

    case SET_PROGRESS_NOTES_PAGE_ALL:
      return {
        ...state,
        allCurrentPage: action.allCurrentPage
      };

    case SET_PROGRESS_NOTES:
      return {
        ...state,
        progressNotes: action.data,
        currentPage: action.currentPage
      };

    case SET_PROGRESS_NOTES_PAGE:
      return {
        ...state,
        currentPage: action.currentPage
      };

    case SET_DELETED_PROGRESS_NOTES:
      return {
        ...state,
        deletedProgressNotes: action.data,
        deletedCurrentPage: action.deletedCurrentPage
      };

    case SET_DELETED_PROGRESS_NOTES_PAGE:
      return {
        ...state,
        deletedCurrentPage: action.deletedCurrentPage
      };

    case PROGRESS_NOTES_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };

    case SET_PROGRESS_NOTE:
      return {
        ...state,
        progressNote: action.data
      };

    case DELETE_PROGRESS_NOTE_SUCCESS:
      return {
        ...state,
        progressNotes: action.data
      };

    case RESTORE_PROGRESS_NOTE_SUCCESS:
      return {
        ...state,
        deletedProgressNotes: action.data
      };

    case PROGRESS_NOTE_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };

    case IS_CREATING_PROGRESS_NOTE:
      return {
        ...state,
        isUpdating: action.isUpdating
      };

    case IS_UPDATING_PROGRESS_NOTE:
      return {
        ...state,
        isUpdating: action.isUpdating
      };

    case IS_DELETING_PROGRESS_NOTE:
      return {
        ...state,
        isLoading: action.isDeleting
      };

    case SET_PROGRESS_NOTES_PLANS:
      return {
        ...state,
        plans: action.data
      };

    case CHECK_UNCHECK_PLANS:
      return {
        ...state,
        plans: action.plans
      };

    case PROGRESS_NOTES_PLANS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };

    case SET_DIAGNOSTIC_STUDIES:
      return {
        ...state,
        diagnosticStudies: action.data
      };

    case SET_REFERRALS:
      return {
        ...state,
        referrals: action.data
      };

    case SET_SUPPLIES:
      return {
        ...state,
        medicalSupplies: action.data
      };

    case HAS_MORE_PROGRESS_NOTE:
      return {
        ...state,
        hasMorePN: action.hasMorePN
      };

    default:
      return state;
  }
}
