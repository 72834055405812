import React, { useEffect, useState } from 'react';

const SearchInput = (props) => {

  const { onSearch, onClear } = props;

  const [keyword, setKeyword] = useState('');

  useEffect(() => {

    if (!keyword) return;

    // Everytime keyword changes,
    // Wait for half a second, then trigger onSearch
    const timeout = setTimeout(() => {
      onSearch(keyword);
    }, 500);

    // But if user types within the timeout,
    // The timeout will be stopped
    return () => {
      clearTimeout(timeout);
    }

  }, [keyword]);

  useEffect(() => {
    if (!keyword) onClear();
  }, [keyword]);

  return (
    <div className="Tab-Search-Container m-0">
      <i className="fas fa-search"></i>
      <input value={keyword} onChange={(e) => setKeyword(e.target.value)} placeholder="Type a patient name" type="search" />
    </div>
  );
}

export default SearchInput;
