import React, { useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import TextAreaList from "../../TextareaComponent/TextAreaList";
import {
  actions,
  dosage_forms,
  routes,
  locations,
  frequencies,
  duration_strings
} from "../config";

export function AddPrescriptionModal({
  showPill = false,
  handleSelectTab = () => {},
  handleMedicationChange = () => {},
  handleQuantityChange = () => {},
  handleChangeTextAreaDetails = () => {},
  handleChangeDrugDetails = () => {},
  handleCloseErxModal = () => {},
  addTextAreaField = () => {},
  removeTextAreaField = () => {},
  openDocuments = () => {},
  saveMedication = () => {},
  saveCurrentMedication = () => {},
  selectedPill = "free_text",
  selectedDrug = {},
  selectedDrugs = [],
  medicationModalShown = false,
  isCurrent = false,
  method
}) {
  useEffect(() => {
    if (selectedPill === "labeled_entry") {
      (function() {
        var previousValue = document.getElementById("quantity").value;
        var pattern = /^\d*((\.|,)\d*)?$/;

        function validateInput(event) {
          event = event || window.event;
          var newValue = event.target.value || "";
          if (newValue.match(pattern)) {
            if (newValue === "0") {
              event.target.value = "";
            }
            previousValue = newValue;
          } else {
            event.target.value = previousValue;
          }
        }

        document.getElementById("quantity").onkeyup = validateInput;
      })();
    }
  }, [selectedPill]);

  return (
    <div id="erxMedicationModal" className="modal" tabIndex="-1" role="dialog">
      <div
        className="modal-dialog modal-print"
        role="document"
        style={{ zIndex: "1050" }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="hc-primary-text" style={{ fontSize: "1.25rem" }}>
              Medication Details
            </h5>
          </div>
          <div className="modal-body">
            {showPill && (
              <ul
                className="nav nav-pills nav-justified hc-nav-pills"
                style={{ marginBottom: "10px" }}
              >
                <li className="nav-item">
                  <a
                    onClick={() => handleSelectTab("labeled_entry")}
                    className={
                      selectedPill === "labeled_entry"
                        ? `hc-nav-link active`
                        : "hc-nav-link"
                    }
                    href="#"
                  >
                    Manual Entry
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={() => handleSelectTab("free_text")}
                    className={
                      selectedPill === "free_text"
                        ? `hc-nav-link active`
                        : "hc-nav-link"
                    }
                    href="#"
                  >
                    Free Text
                  </a>
                </li>
              </ul>
            )}
            {selectedPill === "labeled_entry" && (
              <div className="rx-input-container input-handler">
                <div className="rx-input-flex">
                  <span>Name / Dose</span>
                  <input
                    type="text"
                    id="displayName"
                    name="displayName"
                    className="form-control"
                    placeholder="Drug name"
                    value={selectedDrug.displayName}
                    onChange={e => handleMedicationChange(e)}
                  />
                </div>
                <div className="rx-input-flex">
                  <span>Direction</span>
                  <Typeahead
                    id="prescription-action"
                    placeholder="Take, Apply, As Directed, Chew..."
                    onChange={selected =>
                      handleChangeDrugDetails(selected, "direction")
                    }
                    allowNew={true}
                    options={actions}
                    maxResults={999999999}
                    selected={selectedDrug.direction}
                    clearButton
                  />
                </div>
                <div className="rx-input-flex">
                  <span>Amount</span>
                  <input
                    type="text"
                    id="amount"
                    name="amount"
                    className="form-control"
                    value={selectedDrug.amount}
                    onChange={e => handleMedicationChange(e)}
                  />
                </div>
                <div className="rx-input-flex">
                  <span>Dosage Form</span>
                  <Typeahead
                    id="prescription-dosage-form"
                    placeholder="Tablet, Capsule, Drop, Dose..."
                    onChange={selected =>
                      handleChangeDrugDetails(selected, "dosage_form")
                    }
                    allowNew={true}
                    options={dosage_forms}
                    maxResults={999999999}
                    selected={selectedDrug.dosage_form}
                    clearButton
                  />
                </div>
                <div className="rx-input-flex">
                  <span>Route</span>
                  <Typeahead
                    id="prescription-route"
                    placeholder="Orally, Sublingual, Nasal, Topically..."
                    onChange={selected =>
                      handleChangeDrugDetails(selected, "route")
                    }
                    labelKey="text"
                    allowNew={true}
                    options={routes}
                    maxResults={999999999}
                    defaultSelected={selectedDrug.route}
                    clearButton
                  />
                </div>
                {/* <div className="rx-input-flex">
                  <span>Anatomic Site</span>
                  <Typeahead
                    id="prescription-location"
                    placeholder="Select anatomic site"
                    onChange={selected => handleChangeDrugDetails(selected, 'location')}
                    labelKey="text"
                    allowNew={true}
                    options={locations}
                    maxResults={999999999}
                    selected={selectedDrug.location}
                  />
                </div> */}
                <div className="rx-input-flex">
                  <span>Frequency</span>
                  <Typeahead
                    id="prescription-frequency"
                    placeholder="Daily, 2x a day, 3x a day, 4x a day..."
                    onChange={selected =>
                      handleChangeDrugDetails(selected, "frequency")
                    }
                    labelKey="text"
                    allowNew={true}
                    options={frequencies}
                    maxResults={999999999}
                    selected={selectedDrug.frequency}
                    clearButton
                  />
                </div>
                <div className="rx-input-flex">
                  <span>Duration</span>
                  <div className="duration-flex">
                    <input
                      id="duration"
                      name="duration"
                      type="number"
                      className="form-control"
                      min={0}
                      value={selectedDrug.duration}
                      onChange={e => handleMedicationChange(e)}
                    />
                    <Typeahead
                      id="durationStr"
                      placeholder="Duration"
                      onChange={selected =>
                        handleChangeDrugDetails(selected, "durationStr")
                      }
                      labelKey="text"
                      options={duration_strings}
                      maxResults={999999999}
                      selected={selectedDrug.durationStr}
                      clearButton
                    />
                  </div>
                </div>
                <div className="rx-input-flex">
                  <span>Quantity</span>
                  <input
                    type="text"
                    id="quantity"
                    name="quantity"
                    className="form-control"
                    placeholder="Quantity"
                    value={selectedDrug.quantity}
                    pattern="^(?=.)\d*(?:[.,]\d+)?$"
                    onChange={e => handleQuantityChange(e)}
                  />
                </div>
                <div className="rx-input-flex">
                  <span>Refill</span>
                  <input
                    type="number"
                    id="refill"
                    name="refill"
                    className="form-control"
                    min={0}
                    max={12}
                    value={selectedDrug.refill}
                    onChange={e => handleMedicationChange(e)}
                  />
                </div>
                <div className="rx-input-flex">
                  <span>Additional Instructions</span>
                  <div>
                    <textarea
                      rows="3"
                      id="additionalDirections"
                      name="additionalDirections"
                      className="form-control"
                      placeholder="Additional Instructions"
                      value={selectedDrug.additionalDirections}
                      onChange={e => handleMedicationChange(e)}
                    />
                  </div>
                </div>
                <div className="rx-input-flex">
                  <span>PRN</span>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "nowrap"
                    }}
                  >
                    <input
                      type="checkbox"
                      id="prn"
                      name="prn"
                      checked={selectedDrug.prn}
                      onChange={e => handleMedicationChange(e)}
                    />
                    {selectedDrug.prn && (
                      <input
                        style={{ marginLeft: "10px" }}
                        id="prn_reason"
                        name="prn_reason"
                        className="form-control"
                        placeholder="PRN reason"
                        value={selectedDrug.prn_reason}
                        onChange={e => handleMedicationChange(e)}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
            {selectedPill === "free_text" && (
              <div className="rx-input-container input-handler">
                <TextAreaList
                  openDocuments={openDocuments}
                  data={selectedDrugs}
                  handleChangeTextAreaDetails={handleChangeTextAreaDetails}
                  addTextAreaField={addTextAreaField}
                  removeTextAreaField={removeTextAreaField}
                  method={method}
                />
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-hubchart btn-hubchart-primary"
              onClick={() => {
                if (isCurrent) {
                  saveCurrentMedication();
                } else {
                  saveMedication();
                }
              }}
            >
              Done
            </button>
            <button
              type="button"
              className="btn btn-hubchart btn-hubchart-close"
              onClick={() => handleCloseErxModal()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      {medicationModalShown && <div className="modal-backdrop show"></div>}
    </div>
  );
}

export default AddPrescriptionModal;
