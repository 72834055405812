import React from 'react'

export default function (props) {

  const { level } = props

  const placeholderStyles = {
    color: '#959596',
    position: 'relative',
    bottom: '0',
    left: '0',
    height: '100%',
  };

  const signalColor = getSignalColor(level);

  const signalStyles = {
    color: signalColor,
    position: 'absolute',
    bottom: '0',
    left: '0',
  };

  let signalClassName = getSignalClassName(level);

  const containerStyles = {
    position: 'relative',
    height: '20px'
  };

  return (
    <span {...props} style={containerStyles} className="mr-2">
      <i className="fas fa-signal" style={placeholderStyles}></i>
      <i className={signalClassName} style={signalStyles}></i>
    </span>
  )
}

function getSignalClassName(level) {

  let className = '';

  switch (level) {
    case -1: className = `fas fa-signal`; break;
    case 1:
    case 2:
    case 3:
    case 4: className = `fas fa-signal-${level}`; break;
    case 5: className = `fas fa-signal`; break;
    default:
  }

  return className;
}

function getSignalColor(level) {
  switch (level) {
    case -1: return '#ff9800';
    case 1:
    case 2: return '#bd2130';
    case 3: return '#ff9800';
    case 4:
    case 5: return '#00ff0a';
    default:
      return '#959596';
  }
}
