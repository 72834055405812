import React, { Component } from 'react'
import { parseAction } from '../../actions/REST'
import sort from 'fast-sort'
import moment from 'moment'
import DocumentLogItem from './DocumentLogItem'
import './CCM.css'
import config from '../../config'

export class CCMContainer extends Component {

    constructor() {
        super();

        this.state = {
            logs : [],
            logsFor : "",
            ccmProgram : {}
        }
    }

    componentDidMount() {
        if (this.props.activeThread.groupType === "patient") {
            if (this.props.activeThread.isCCM) {
                if (this.state.logsFor !== this.props.activeThread.threadId) {
                    this.setState({
                        logs : [],
                        logsFor : this.props.activeThread.threadId,
                        ccmProgram : {}
                    })
                    this.getCCMProgram(this.props.activeThread.threadId)
                }
            }
        }
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        if (prevProps.ccmIsOpen === false && this.props.ccmIsOpen === true) {
            this.openCCMContainer();
        }

        if (prevProps.ccmIsOpen === true && this.props.ccmIsOpen === false) {
            this.closeCCMContainer();
        }

        // Check first if active thread is a group
        if (this.props.activeThread.groupType === "patient") {
            if (this.props.activeThread.isCCM) {
                if (this.props.activeThread.threadId !== prevProps.activeThread.threadId) {
                    if (this.state.logsFor !== this.props.activeThread.threadId) {
                        this.setState({
                            logs : [],
                            logsFor : this.props.activeThread.threadId,
                            ccmProgram : {}
                        })
                        this.getCCMProgram(this.props.activeThread.threadId)
                    }

                }
            }
        } else {
            this.props.closeCCM();
        }
        return null;
    }

    componentDidUpdate() {

    }

    openCCMContainer() {
        this.refs.ccmContainer.style.width = "380px";
    }

    closeCCMContainer() {
        this.refs.ccmContainer.style.width = "0";
    }

    getDocumentLogs(groupObjectId) {
        parseAction("get", config.BASE_URL + "/parse/classes/Document",
        {
            where: {
                "circle":{
                  "__type": "Pointer",
                  "className":"Circle",
                  "objectId": groupObjectId
                },
                "ccmData" : {
                    $exists: true
                }
            },
            include: ["user", "ccmData"]
        }).then((res) => {
            let sortedResult = sort(res.results).desc([
        		(p) => p.ccmData.serviceYear,
        		(p) => p.ccmData.serviceMonth
            ]);

            this.setState({
                logs : sortedResult
            })
        }).catch((error) => {
            console.log(error)
        })
    }

    getCCMProgram(groupObjectId) {
        parseAction("get", config.BASE_URL + "/parse/classes/CCMProgram",
        {
            where: {
                "patientCircle":{
                  "__type": "Pointer",
                  "className":"Circle",
                  "objectId": groupObjectId
                }
            },
        }).then((res) => {
            if (res.results.length !== 0) {
                this.setState({
                    ccmProgram : res.results[0]
                })
                this.getDocumentLogs(groupObjectId);
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    render() {
        const logList = this.state.logs.map((data, i) => {
			return <DocumentLogItem key={data.objectId} log={data} group={this.state.group}/>
        });

        let startDate;

        if (this.state.ccmProgram.startDate) {
            startDate = moment(this.state.ccmProgram.startDate.iso).format("MMM DD, YYYY")
        }


        return (
            <div className="CCM-Panel" ref="ccmPanel">
                <div className="CCM-Container" ref="ccmContainer" style={{width: "380px"}}>
                    <div className="CCM-Details">
                        <table className="table table-borderless" style={{fontSize: "15px", height: "120px",margin: "10px 0"}}>
                            <tbody>
                                <tr>
                                    <td style={{padding: "2px 5px"}}><i className="fas fa-clock"></i>CCM Minutes this month</td>
                                    <td style={{padding: "2px 5px", textAlign: "right"}}><span className="badge" style={{width: "100px", fontSize: "13px", background: "rgb(149, 80, 179)", color: "white"}}>{this.props.activeThread.ccmMinutes} Minutes</span></td>
                                </tr>
                                <tr>
                                    <td style={{padding: "2px 5px"}}><i className="fas fa-calendar-alt"></i>Start of CCM Service</td>
                                    <td style={{padding: "2px 5px", textAlign: "right"}}><span className="badge" style={{width: "100px", fontSize: "13px", background: "rgb(211, 47, 47)", color: "white"}}>{startDate}</span></td>
                                </tr>
                                <tr>
                                    <td style={{padding: "2px 5px"}}><i className="fas fa-clipboard-list"></i>Care Plan</td>
                                    <td style={{padding: "2px 5px", textAlign: "right"}}></td>
                                </tr>
                                <tr>
                                    <td style={{padding: "2px 5px"}}></td>
                                    <td style={{padding: "2px 5px", textAlign: "right"}}><span className="badge" style={{width: "100px", fontSize: "13px", background: "#2194f3", color: "white"}}>Billing</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="list-group document-list">
                        {logList}
                    </div>
                </div>
            </div>
        )
    }
}

export default CCMContainer
