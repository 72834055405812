import {
  FETCH_CONTACTS,
  NEW_CONTACT,
  CONTACT_PROFILE,
  CONTACT_PROFILE_LOADING,
  GET_CONTACTS,
  FILTER_CONTACTS,
  OTHER_USERS,
  LOAD_MORE_CONTACTS,
  CONTACT_SEARCHING_STATUS,
  IS_SEARCHING,
  SET_SELECTED_CONTACTS,
  RESET_SELECTED_CONTACTS
} from "../actions/types";
import _ from "lodash";

const initialState = {
  requests: [],
  contacts: [],
  filterResult: [],
  otherUsersResult: [],
  selectedContacts: [],
  fetchStatus: "",
  initialLoad: false,
  contactPage: 0,
  isSearchingContact: false,
  isLoadingSearch: false,
  contact_profile: {},

  // Whether to show the full screen loading indicator
  isLoading: false,
  isLoadingContact: false
};

const formatBlocked = (contacts = []) => {
  return contacts.map(c => {
    if (c.contact) {
      c.contact.isBlocked = c.isBlocked;
    }

    return c;
  });
};

const filterUnique = (contacts = []) => {
  return _.uniqBy(contacts, c => {
    if (!c.contact) {
      return c.objectId;
    } else {
      return c.contact.objectId;
    }
  });
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "ACCEPT_CONTACT":
      const theRequest = state.requests.find(
        roster =>
          roster.user.objectId === action.contactId &&
          roster.contact.objectId === action.userId
      );

      /* 
                Remove from requests
            */
      const requests = state.requests.filter(
        r => r.objectId != theRequest.objectId
      );

      /* 
                Add to contacts    
            */
      const contacts3 = [
        {
          ...theRequest,
          contact: theRequest.user,
          user: theRequest.contact
        },
        ...state.contacts
      ];

      return {
        ...state,
        requests,
        contacts: contacts3
      };
      break;

    case "SET_BLOCKED_CONTACT":
      const contact2 = action.contact;

      contact2.isBlocked = action.value;

      const contacts2 = state.contacts.map(c => {
        if (c.contact && c.contact.objectId === contact2.objectId) {
          c.contact = contact2;

          return c;
        }

        if (c.objectId === contact2.objectId) {
          c.contact = contact2;

          return c;
        }

        return c;
      });

      return {
        ...state,
        contacts: contacts2
      };
      break;

    case "REMOVE_CONTACT_SUCCESS":
      const contact1 = action.contact;

      // Set isNotContact flag to true
      contact1.isNotContact = true;

      const contacts1 = state.contacts.filter(r => {
        if (r.contact) {
          return r.contact.objectId != contact1.objectId;
        } else {
          return r.objectId != contact1.objectId;
        }
      });

      const filterResult1 = state.filterResult.filter(
        r => r.objectId != contact1.objectId
      );

      const otherUsersResult1 = [contact1, ...state.otherUsersResult];

      return {
        ...state,
        filterResult: filterResult1,
        otherUsersResult: otherUsersResult1,
        contacts: contacts1
      };
      break;

    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.value
      };
      break;

    case "ADD_CONTACT_SUCCESS":
      const { contact } = action;

      // Remove isNotContact flag from new contact
      delete contact.isNotContact;

      const filterResult = [contact, ...state.filterResult];

      const otherUsersResult = state.otherUsersResult.filter(
        r => r.objectId != contact.objectId
      );

      return {
        ...state,
        filterResult,
        otherUsersResult
      };
      break;

    case FETCH_CONTACTS:
      if (action.status === "FAILED") {
        return {
          ...state,
          fetchStatus: action.status
        };
      }

      const parentContacts = action.payload || [];

      // Set blocked for contacts
      const formatted = formatBlocked(action.payload);

      // Filter unique for contacts
      const unique1 = filterUnique(formatted);
      return {
        ...state,
        contacts: unique1,
        initialLoad: true,
        fetchStatus: action.status,
        contactPage: state.contactPage + 1
      };
      break;

    case "FETCH_CONTACT_REQUESTS":
      return {
        ...state,
        requests: action.rosters
      };
      break;

    case LOAD_MORE_CONTACTS:
      // Set blocked for contact
      const formatted1 = formatBlocked(action.payload);
      const newContacts = [...state.contacts, ...formatted1].map(item => {
        const arr = state.selectedContacts.filter(
          sel => sel.objectId === (item.contact || {}).objectId
        );
        const isChecked = arr.length > 0 ? true : false;
        return {
          ...item,
          contact: {
            ...item.contact,
            isChecked
          }
        };
      });
      return {
        ...state,
        contacts: newContacts,
        contactPage: state.contactPage + 1
      };
      break;

    case GET_CONTACTS:
      return {
        ...state
      };
      break;

    /**
     * Filter contacts
     * @param {string} action.text
     */
    case FILTER_CONTACTS:
      if (action.text === "") {
        return {
          ...state,
          filterResult: [],
          isSearchingContact: false
        };
      }

      const results = action.results.map(item => {
        const arr = state.selectedContacts.filter(
          sel => sel.objectId === item.objectId
        );
        const isChecked = arr.length > 0 ? true : false;
        return {
          ...item,
          isChecked
        };
      });

      // Filter out duplicates from contacts
      const unique = filterUnique(results);

      return {
        ...state,
        filterResult: unique,
        isSearchingContact: true
      };
      break;

    case OTHER_USERS:
      if (action.success) {
        const results = action.otherUsers.map(item => {
          const arr = state.selectedContacts.filter(
            sel => sel.objectId === item.objectId
          );
          const isChecked = arr.length > 0 ? true : false;
          return {
            ...item,
            isChecked
          };
        });
        return {
          ...state,
          otherUsersResult: [...results]
        };
      } else {
        return {
          ...state
        };
      }
      break;

    case CONTACT_SEARCHING_STATUS:
      return {
        ...state,
        filterResult: [],
        isSearchingContact: action.isSearching
      };
      break;

    case IS_SEARCHING:
      return {
        ...state,
        isLoadingSearch: action.value
      };
      break;

    case SET_SELECTED_CONTACTS:
      return {
        ...state,
        selectedContacts: action.selectedContacts
      };
      break;

    case RESET_SELECTED_CONTACTS:
      return {
        ...state,
        selectedContacts: action.selectedContacts
      };
      break;

    case CONTACT_PROFILE:
      return {
        ...state,
        contact_profile: action.contact_profile
      };

    case CONTACT_PROFILE_LOADING:
      return {
        ...state,
        isLoadingContact: action.isLoadingContact
      };

    default:
      return state;
  }
}
