import React, { useEffect, useState, Fragment } from 'react';

import './index.css';

const $ = window.$;

function ChiefComplaintsComponent({
  chiefComplaints = [],
  updateChiefComplaints = () => {},
  removeChiefComplaint = () => {},
  isDisabled = false,
}) {
  const [cc, setCC] = useState('');
  const [ccDummy, setCCDummy] = useState('');
  const [btnClass, setBtnClass] = useState('default');

  function handleCcOnChange (e) {
    e.preventDefault();
    const { value } = e.target;
    setCC(value);

    if (value) {
      setBtnClass('secondary');
    } else {
      setBtnClass('default');
    }
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter' && cc) {
      updateChiefComplaints(cc);
      setCC('');
      setBtnClass('default');
    }
  }

  useEffect(() => {
    if (chiefComplaints.length === 0) {
      setCCDummy('');
    } else {
      setCCDummy('filled');
    }
    return () => {}
  }, [chiefComplaints]);

  return (
    <div className="card custom-card cc-id">
      <h5 className="card-header ">CHIEF COMPLAINTS</h5>
      <div className="card-body cust-card-body">
        <div className="custom-form-group-container-2" >
          <div className="custom-form-group">
            <input 
              id="chiefComplaint" 
              type="text" 
              className="form-control" 
              placeholder="Type chief complaint" 
              onKeyDown={e => handleKeyPress(e)}
              onChange={e => handleCcOnChange(e)}
              value={cc} 
              disabled={isDisabled}
            />
          </div>
          <button 
            type="button" 
            className={`btn btn-${btnClass} btn-sm`}
            style={{ border: 'solid 1px #d1d3e2' }}
            disabled={btnClass == 'default'}
            onClick={() => {
              if (cc) {
                updateChiefComplaints(cc);
                setCC('');
                setBtnClass('default');
              }
            }}>Add</button>
        </div>
        <div className="custom-form-group-container">
          <div className="badges-container" style={{ padding: '5px' }}>
            {chiefComplaints.map((item, i) => (
              <span className="badge badge-primary cc-badge" key={item+i}>{item}
                {!isDisabled && (
                  <i 
                    className="fas fa-times" 
                    onClick={() => removeChiefComplaint(item)} 
                  />
                )}
              </span>
            ))}
            <input 
              type="text" 
              className="form-control" 
              style={{ 
                height: '0px', 
                width: '0px',
                padding: '5px 0 0 0',
                visibility: 'hidden',
              }}
              value={ccDummy}
              required={true} />
            <div class="invalid-feedback">
              Chief complaint is required.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChiefComplaintsComponent;
