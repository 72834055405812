import React, { Component } from 'react'

const $ = window.$;
export class ForwardToCCMModal extends Component {

    componentDidMount() {
        if (this.props.isOpen) {
            $("#forwardToCCM").modal('show');
        } else {
            $("#forwardToCCM").modal('hide');
        }
        $("#forwardToCCM").on('hidden.bs.modal', function (e) {
            this.props.modalClose();
        }.bind(this))
    }

    confirm() {
        this.props.confirmAction();
        $("#forwardToCCM").modal('hide');
    }

    render() {
        return (
            <div id="forwardToCCM" className="modal" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-body" style={{paddingBottom: "0px"}}>
                        <h5 className="hc-primary-text" style={{fontSize: "1.25rem"}}>Forwarding Message to CCM</h5>
                        <p style={{fontSize: "0.9em"}}>Are you sure you want to create a document log from this message?</p>
                    </div>
                    <div className="modal-footer" style={{border: "0", padding: "0 20px 20px 0"}}>
                        <button onClick={this.confirm.bind(this)} type="button" className="btn btn-hubchart btn-hubchart-primary">Confirm</button>
                        <button type="button" className="btn btn-hubchart btn-hubchart-close" data-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ForwardToCCMModal
