import React from "react";

const $ = window.$;

function ConfirmTask({ onClose = () => {}, actionItemId = "" }) {
  const completeTask = () => {
    onClose(actionItemId);
  };

  return (
    <div className="save-changes-container">
      <div className="save-changes-box" style={{ padding: "20px 15px" }}>
        <i className="fas fa-exclamation-circle hc-primary-text fa-2x"></i>
        <div className="save-changes-flex">
          <h5 style={{ marginBottom: "20px" }}>Want to mark this task as Done?</h5>
          <div style={{ textAlign: "right" }}>
            <button
              type="button"
              style={{ marginRight: "10px" }}
              className="btn btn-hubchart btn-hubchart-primary"
              onClick={() => {
                completeTask();
              }}
            >
              Mask as Done
            </button>
            <button type="button" className="btn btn-hubchart btn-hubchart-close" onClick={() => onClose()}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmTask;
