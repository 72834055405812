import React, { useCallback, useState, Fragment } from "react";
import ImageLoad from "./ImageLoad";
import ImagePlaceholder from "../../Common/ImagePlaceholder";
import { showReplyPreview } from "../../../actions/ThreadAction";
import { useDispatch } from "react-redux";
import moment from "moment";

const ReplyTo = ({ replyTo = {} }) => {
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(false);

  const { fileType } = replyTo;

  const detectType = (type = "") => {
    const typeRegex = {
      image: /(gif|jpe?g|tiff?|png|webp|bmp)$/i,
      video: /(mov|avi|wmv|flv|3gp|mp4|mpg)$/i,
      audio: /(mp3|wav)$/i,
      pdf: /pdf/i
    };

    if (typeRegex.image.test(type)) return "image";
    if (typeRegex.video.test(type)) return "video";
    if (typeRegex.audio.test(type)) return "audio";
    if (typeRegex.pdf.test(type)) return "pdf";

    return "";
  };

  const getFileText = (url, type = "") => {
    if (!url) return "";

    return `${type} message`;
  };

  const handleOpenFile = useCallback(() => {
    if (!fileType) return;

    dispatch(showReplyPreview(replyTo));
  }, [fileType, replyTo]);

  const type = detectType(fileType);

  const thumbUrl =
    type === "image" ? replyTo.thumbUrl || replyTo.fileUrl : replyTo.thumbUrl;

  const fileText = getFileText(fileType, type);

  const dateString = replyTo.originalMessageDate
    ? moment(replyTo.originalMessageDate.iso).format("MMM D, YYYY, h:mm A")
    : "";

  const handleTextDisplay = (text = "", isViewMore) => {
    const textLength = text.length;
    if (isViewMore) {
      return isExpanded ? text : `${text.substr(0, 700)}...`;
    }
    return textLength < 700 ? text : `${text.substr(0, 700)}...`;
  };

  return (
    <div
      className="bg-gray-100 p-2 mb-2"
      style={{
        fontSize: "12px",
        position: "relative",
        minWidth: "0",
        marginLeft: "-18px",
        marginRight: "-18px",
        marginTop: "-7px"
      }}
    >
      <div className="d-flex" onClick={e => handleOpenFile()}>
        <i
          className="fas fa-reply fa-rotate-180 mr-1 text-gray-800 mr-2"
          style={{ alignSelf: "center" }}
        ></i>

        {thumbUrl ? (
          <div className="mr-2">
            <ImageLoad openLightbox={() => {}} imageURL={thumbUrl} />
          </div>
        ) : (
          ""
        )}

        {fileType && !thumbUrl ? (
          <div className="mr-2">
            <ImagePlaceholder type={type} />
          </div>
        ) : (
          ""
        )}

        <div
          className="w-100 mr-2"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat( auto-fill, 1fr );"
          }}
        >
          <div style={{ minWidth: "0" }}>
            <div className="font-weight-bold">
              <span>{replyTo.sender}</span>
            </div>
            {!isExpanded && (
              <span
                className="text-gray-800 mb-0"
                style={{ fontStyle: "italic" }}
              >
                {handleTextDisplay(replyTo.text)}
              </span>
            )}
            {isExpanded && (
              <span
                className="text-gray-800 mb-0"
                style={{ fontStyle: "italic" }}
              >
                {replyTo.text}
              </span>
            )}
            {(replyTo.text || "").length > 1000 && (
              <Fragment>
                {!isExpanded && (
                  <div align="right">
                    <span
                      className="message-text-content"
                      style={{ color: "#2193f3", cursor: "pointer" }}
                      onClick={() => setIsExpanded(true)}
                    >
                      Show more
                    </span>
                  </div>
                )}
                {isExpanded && (
                  <div align="right">
                    <span
                      className="message-text-content"
                      style={{ color: "#2193f3", cursor: "pointer" }}
                      onClick={() => setIsExpanded(false)}
                    >
                      Show less
                    </span>
                  </div>
                )}
              </Fragment>
            )}
            {/* {replyTo.text ? (
              <p className="text-gray-800 mb-0" style={{ fontStyle: "italic" }}>
                {handleTextDisplay(replyTo.text)}
              </p>
            ) : (
              ""
            )} */}

            {!replyTo.text && fileText ? (
              <p className="text-capitalize mb-0">{fileText}</p>
            ) : (
              ""
            )}
            <div>
              {dateString ? (
                <p className="text-gray-600 text-right mb-0">{dateString}</p>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReplyTo;
