import React, { Component } from 'react'

export class Seen extends Component {
    render() {
        return (
            <div className="_avatar-column-seen" style={{marginBottom: "5px"}}>
                <div className="avatar">
                    <img src={this.props.url} title={this.props.name}/>
                </div>
            </div>
        )
    }
}

export default Seen
