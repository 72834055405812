import React, { Component, Fragment } from "react";
import { format } from "date-fns";
import { connect } from "react-redux";
import { Offline } from "react-detect-offline";
import sort from "fast-sort";
import Parse from "parse";
import PUBNUB from "pubnub";
import TabList from "../TabList";
import Conversation from "../Conversation";
import VideoContainer from "../Conversation/Video/VideoContainer";
import VideoPortal from "../Conversation/Video/VideoPortal";
import CallerConfirmationModal from "./CallerConfirmationModal";
import { parseAction } from "../../actions/REST";
import { fetchSubscriber } from "../../actions/RevenueCatAction";
import { fetchHCUSubscription } from "../../actions/SubscriptionAction";
import { requestOTP, validateOTP } from "../../actions/PatientAction";
import {
  receiveNewMessage,
  recentUpdated,
  fetchMessage,
  fetchUnreadCount,
  setActiveTab,
  fetchThreads,
} from "../../actions/ThreadAction";
import { fetchGroups, fetchPatients } from "../../actions/GroupAction";
import { loadGoogleApi, getMeetings, onConferenceEnd, getActiveConferenceCall } from "../../actions/ConferenceAction";
import { setAppState, fetchOrganization, updateInstallationId } from "../../actions/SessionAction";
import { incomingCall } from "../../actions/CallAction";
import { updateBlock, fetchRequests } from "../../actions/ContactAction";
import { isMobile } from "react-device-detect";
import CCMContainer from "../CCM/CCMContainer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConferenceInvite from "./ConferenceCall/ConferenceInvite";
import ConferenceHelper from "../../helper/conference";
import Notify from "../Common/Notify";
import { receiveCall, declineCall } from "../../actions/PrivateCallAction";
import config from "../../config";
import Navbar from "./Navbar";
import CalendarView from "./CalendarView";
import CreateChat from "./CreateChat";
import ConferenceToastContainer from "./ConferenceToastContainer";
import Dashboard from "./Dashboard";
import ProgressNotesCard from "../TabList/ProgressNoteTab/ProgressNotesCard";
import ProfileSetting from "../TabList/ProfileSetting/ProfileSetting";
import RequirePassword from "../Conversation/Setting/PrescriptionPDF/RequirePasswordESign";

const $ = window.$;
const localStorage = window.localStorage;
export class Main extends Component {
  constructor() {
    super();
    this.state = {
      newMessageUnreadCount: 0,
      openAnswerCallModal: false,
      showWindowPortal: false,
      openCCM: false,
      openDocument: false,
      incomingCall: null,
      didCancel: false,
      showConferenceInvite: false,
      isValidSession: true,
      isValidating: false,
      hostName: "",
    };

    this.openCCM = this.openCCM.bind(this);
    this.closeCCM = this.closeCCM.bind(this);
    this.openDocument = this.openDocument.bind(this);
    this.closeDocument = this.closeDocument.bind(this);

    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.hiddenEvent = this.hiddenEvent.bind(this);

    this.toggleWindowPortal = this.toggleWindowPortal.bind(this);
    this.closeWindowPortal = this.closeWindowPortal.bind(this);

    this.handleJoin = this.handleJoin.bind(this);
    this.handleEnd = this.handleEnd.bind(this);
    this.handleLastDateActive = this.handleLastDateActive.bind(this);
    this.updateLastDateActive = this.updateLastDateActive.bind(this);
  }

  componentDidMount() {
    this.handleLastDateActive();
    this.updateLastDateActive();
    this.fetchPubNubKey();
    this.props.updateInstallationId();
    this.props.fetchRequests();
    this.props.fetchSubscriber();
    this.props.fetchOrganization();
    this.props.fetchHCUSubscription();
    this.props.fetchUnreadCount();

    this.props.loadGoogleApi(
      {
        apiKey: config.REACT_APP_GOOGLE_API_KEY,
        clientId: config.REACT_APP_GOOGLE_CLIENT_ID,
      },
      () => {
        this.props.getMeetings();
      }
    );
    this.props.getActiveConferenceCall();

    this.visProp = this.getHiddenProp();
    if (this.visProp) {
      let evtname = this.visProp.replace(/[H|h]idden/, "") + "visibilitychange";
      document.addEventListener(evtname, this.hiddenEvent, false);
    }
    localStorage.setItem("onCall", false);
    // This will detect if the our app is covered by other window or browser is on minimize
    window.addEventListener("focus", this.onFocus);
    window.addEventListener("blur", this.onBlur);

    // Set tab to
    if (this.props.tab === "conference") {
      this.props.setActiveTab("conference");
    }

    // let isLogin = false;
    // if (Parse.User.current()) isLogin = true;
    // if (isLogin) {
    //   const contactNumber = Parse.User.current().get("username");
    //   const lastDateActive = Parse.User.current().get("lastDateActive");
    //   if (
    //     format(lastDateActive, "dd-MM-yy") !== format(new Date(), "dd-MM-yy")
    //   ) {
    //     this.props.requestOTP(contactNumber);
    //     this.setState({ isValidSession: false });
    //   } else {
    //     this.setState({ isValidSession: true });
    //   }
    // }
  }

  componentWillUnmount() {
    if (this.visProp) {
      let evtname = this.visProp.replace(/[H|h]idden/, "") + "visibilitychange";
      document.removeEventListener(evtname, this.hiddenEvent, false);
    }

    this.PubNub.removeListener(this.listiningTo);
    this.unsubscribeAllChannel();

    window.removeEventListener("focus", this.onFocus);
    window.removeEventListener("blur", this.onBlur);
    clearInterval(this.stateInterval);
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (!prevProps.hasCall && this.props.hasCall) {
      if (!this.state.showWindowPortal) {
        this.setState({
          openAnswerCallModal: true,
        });
      }
    }
    return null;
  }

  openCCM() {
    this.setState({
      openDocument: false,
      openCCM: true,
    });
  }

  closeCCM() {
    this.setState({
      openCCM: false,
    });
  }

  openDocument() {
    this.setState({
      openDocument: true,
      openCCM: false,
    });
  }

  closeDocument() {
    this.setState({
      openDocument: false,
    });
  }

  toggleWindowPortal() {
    this.setState((state) => ({
      ...state,
      showWindowPortal: !state.showWindowPortal,
    }));
  }

  closeWindowPortal() {
    this.setState({ showWindowPortal: false });
  }

  hiddenEvent() {
    if (!document[this.visProp]) {
      this.props.setAppState("Visible");
    } else {
      this.props.setAppState("Hidden");
    }
    this.setState({
      newMessageUnreadCount: 0,
    });
    clearInterval(this.stateInterval);
    document.title = "Hubchart";
  }

  onFocus() {
    this.props.setAppState("Visible");
    if (this.state.newMessageUnreadCount !== 0) {
      this.setState({
        newMessageUnreadCount: 0,
      });
    }

    clearInterval(this.stateInterval);
    document.title = "Hubchart";
  }

  onBlur() {
    this.props.setAppState("Hidden");
    if (this.state.newMessageUnreadCount !== 0) {
      this.setState({
        newMessageUnreadCount: 0,
      });
    }
    clearInterval(this.stateInterval);
    document.title = "Hubchart";
  }

  getHiddenProp() {
    let prefixes = ["webkit", "moz", "ms", "o"];
    // test for native support
    if ("hidden" in document) return "hidden";

    //find prefixes
    for (let i = 0; i < prefixes.length; i++) {
      if (prefixes[i] + "Hidden" in document) return prefixes[i] + "Hidden";
    }

    // otherwise it's not supported
    return null;
  }

  blinkTitle(title, messageTitle) {
    this.stateInterval = setInterval(() => {
      if (document.title === title) {
        document.title = messageTitle;
      } else {
        document.title = title;
      }
    }, 1000);
  }

  fetchPubNubKey() {
    parseAction("post", config.BASE_URL + "/parse/functions/getPubNubKeys")
      .then((keys) => {
        this.initializePubNub(keys.result);
      })
      .catch((error) => {
        // TODO handle error when fetching thread
        // console.error("Error fetching", error)
        Parse.User.logOut().then(() => {
          // localStorage.removeItem("activeTab");
          localStorage.setItem("is_logged_in", false);
          // localStorage.clear();
          window.location.href = "/";
        });
      });
  }

  initializePubNub(keys) {
    this.PubNub = new PUBNUB({
      publish_key: keys.publishKey,
      subscribe_key: keys.subscribeKey,
      ssl: window.location.protocol.toLowerCase() === "https:",
      origin: "pubsub.pubnub.com",
      uuid: Parse.User.current().id,
      presenceTimeout: 120,
      heartbeatInterval: 30,
    });

    this.addListener();

    let channels = ["priv_" + Parse.User.current().id];
    this.subscribeUser(channels);
  }

  addListener() {
    this.listiningTo = {
      message: (m) => {
        if (m.message.type === "recent_deleted") {
          this.props.fetchThreads("", false, 0, "", "", this.props.read);
          if (m.message.recent.groupType === "regular") {
            this.props.fetchGroups("regular", false);
          }
          if (m.message.recent.groupType === "patient") {
            this.props.fetchPatients("patient", false);
          }
        }
        // handle message
        /*
                var channelName = m.channel; // The channel for which the message belongs
                var channelGroup = m.subscription; // The channel group or wildcard subscription match (if exists)
                var pubTT = m.timetoken; // Publish timetoken
                var publisher = m.publisher; //The Publisher
                */
        var msg = m.message; // The Payload
        var currentUser = Parse.User.current().id;
        // const documentIsFocus = this.checkIfDocumentIsFocus();

        if (msg.type === "private_message" || msg.type === "group_message" || msg.type === "adhoc_group_message") {
          if (msg.message && msg.conference) {
            msg.message.conference = msg.conference;
          }

          if (msg.message.isSystemMessage === true) {
            this.props.receiveNewMessage(msg.message, msg.message.threadId);
            return;
          }
          if (msg.message.user.objectId === currentUser) return;

          if (this.stateInterval) {
            clearInterval(this.stateInterval);
          }

          let willShowNotif = false;
          if (this.props.appState === "Hidden") {
            willShowNotif = true;
          }
          if (this.props.appState === "Idle") {
            willShowNotif = true;
          }

          if (willShowNotif) {
            this.playNotif();

            let nMCount = this.state.newMessageUnreadCount + 1;
            if (this.state.newMessageUnreadCount === 0) {
              document.title = "Hubchart";
            } else {
              document.title = "(" + nMCount + ") Hubchart";
            }

            let secondTitle;
            if (msg.type === "private_message") {
              secondTitle = msg.message.user.displayName + " messaged you";
            }
            if (msg.type === "group_message") {
              secondTitle = msg.message.user.displayName + " messaged in " + msg.message.circle.name;
            }
            this.blinkTitle("(" + nMCount + ") Hubchart", secondTitle);
            this.setState({
              newMessageUnreadCount: nMCount,
            });
          }

          if (msg.attention && msg.attention.length) {
            msg.message.attention = msg.attention;
          }

          this.props.receiveNewMessage(msg.message, msg.message.threadId);

          if (msg.message.attachments) {
            if (!msg.message.attachments[0].file) {
              this.props.fetchMessage(msg.message.objectId, msg.message.threadId);
            }
          }

          return;
        }

        if (msg.type === "recent_updated") {
          if (msg.recent.senderObjectId === currentUser) return;
          this.props.recentUpdated(msg.recent);
          return;
        }

        if (msg.type === "roster_updated") {
          this.props.updateBlock(msg.roster.user.objectId, msg.roster.isBlocked);
          return;
        }

        // check for delete message pubnub
        if (msg.type === "message_deleted") {
          // console.log(msg.type);
          return;
        }

        if (msg.type === "INCOMING_CALL") {
          let isOnCall = localStorage.getItem("onCall");
          let _onCall = JSON.parse(isOnCall);
          if (_onCall === true) {
            return;
          }

          if (this.state.openAnswerCallModal) {
            return;
          }
          msg.status = "INCOMING_CALL";

          let _callData = localStorage.getItem(msg.roomId);
          if (_callData) {
            let callData = JSON.parse(_callData);
            callData = msg;
            localStorage.setItem(msg.roomId, JSON.stringify(callData));
          } else {
            localStorage.setItem(msg.roomId, JSON.stringify(msg));
          }

          this.setState({
            incomingCall: msg,
            openAnswerCallModal: true,
            didCancel: false,
            callUUID: msg.uuid,
          });

          // send receive call will add connecting status from other caller
          this.props.receiveCall(msg.roomId);
          // this.props.incomingCall(msg)

          return;
        }

        if (msg.type === "CANCEL_CALL") {
          this.setState({
            didCancel: true,
          });
          return;
        }

        if (msg.type === "ACK_CALL") {
          if (msg.caller !== Parse.User.current().id) {
            return;
          }
          let idArray = [msg.caller, msg.callee];
          idArray = sort(idArray).asc((p) => p.toLowerCase());
          let room = idArray.join("_");

          let _callData = localStorage.getItem(room);
          if (_callData) {
            let callData = JSON.parse(_callData);
            callData.isRinging = true;
            localStorage.setItem(room, JSON.stringify(callData));
          }
          return;
        }

        // When a call receiver acknowledges that he
        // received your call and is ringing his device
        if (msg.type === "CALL_RECEIVED") {
          const roomId = msg.roomId;

          let _callData = localStorage.getItem(roomId);

          if (_callData) {
            let callData = JSON.parse(_callData);
            callData.isRinging = true;
            localStorage.setItem(roomId, JSON.stringify(callData));
          } else {
            localStorage.setItem(
              roomId,
              JSON.stringify({
                ...msg,
                isRinging: true,
              })
            );
          }
          return;
        }

        if (msg.type === "CALL_DECLINED") {
          const roomId = msg.roomId;

          let _callData = localStorage.getItem(roomId);

          if (_callData) {
            let callData = JSON.parse(_callData);
            callData.status = "CALL_DECLINED";
            localStorage.setItem(roomId, JSON.stringify(callData));
          }

          return;
        }

        if (msg.type === "CALL_ANSWERED") {
          const user_id = Parse.User.current().id;

          // If you answered a call from another device
          if (msg.callee_id === user_id) {
            // Dismiss ringing dialog
            this.setState({ didCancel: true });
          } else {
            const roomId = msg.roomId;

            let _callData = localStorage.getItem(roomId);

            if (_callData) {
              let callData = JSON.parse(_callData);
              callData.status = "CALL_ANSWERED";
              localStorage.setItem(roomId, JSON.stringify(callData));
            }

            return;
          }
        }

        if (msg.type === "ANSWER_CALL") {
          this.setState({
            didCancel: true,
          });
        }

        if (msg.type === "conference_invite") {
          if (ConferenceHelper.isEngaged()) {
            const message = "You missed a conference call with " + msg.hostName;

            Notify.info(message);
          } else {
            this.setState({
              hostName: msg.hostName,
              roomId: msg.roomId,
              showConferenceInvite: true,
            });
          }
        }

        if (msg.type === "conference_join") {
          // Check if your other device joined
          if (msg.participantId === Parse.User.current().id) {
            // Dismiss ring notification
            this.setState({
              showConferenceInvite: false,
            });
          }
          // Show notification that another user has joined
          else {
            this.notifyJoin(msg);
          }
        }

        if (msg.type === "conference_end") {
          Notify.errorWithTitle("Conference call has ended");
          this.props.onConferenceEnd();
        }
      },
      presence: (p) => {
        // handle presence
        /*
                var action = p.action; // Can be join, leave, state-change or timeout
                var channelName = p.channel; // The channel for which the message belongs
                var occupancy = p.occupancy; // No. of users connected with the channel
                var state = p.state; // User State
                var channelGroup = p.subscription; //  The channel group or wildcard subscription match (if exists)
                var publishTime = p.timestamp; // Publish timetoken
                var timetoken = p.timetoken;  // Current timetoken
                var uuid = p.uuid; // UUIDs of users who are connected with the channel
                */
      },
      objects: (objectEvent) => {
        // console.log("objectEvent: ", objectEvent);
      },
    };

    this.PubNub.addListener(this.listiningTo);
  }

  notifyJoin(msg) {
    const { participantName, roomId } = msg;

    const content = (
      <span className="text-white">
        <div className="hc-nobreak">
          <i className="fas fa-video-plus mr-1"></i>
          <strong className="mr-1">{participantName}</strong>
        </div>
        <div className="hc-nobreak">is joining the conference</div>
      </span>
    );

    Notify.success(content, {
      autoClose: 10000,
    });
  }

  unsubscribeAllChannel() {
    this.PubNub.unsubscribeAll();
  }

  subscribeUser(channels) {
    this.PubNub.subscribe({
      channels: channels,
    });
  }

  checkIfDocumentIsFocus() {
    if ($(document).is(":focus")) {
      return true;
    }
    return false;
  }

  playNotif() {
    var audio = new Audio(require("../../assets/audio/mdsalert03.wav"));
    audio.play();
  }

  confirmAction() {
    this.setState({
      openAnswerCallModal: false,
    });

    localStorage.setItem("onCall", true);

    const url = [window.location.origin, "/privatecall?roomId=", this.state.incomingCall.roomId].join("");

    this.videoCallPopup = window.open(url, "Hubchart Call", "status=1,menubar=0");

    this.videoCallPopup.onbeforeunload = () => {
      localStorage.setItem("onCall", false);
      localStorage.removeItem(this.state.incomingCall.roomId);
    };
  }

  declineCall() {
    this.setState({
      openAnswerCallModal: false,
    });
    this.props.declineCall(this.state.incomingCall.roomId);
  }

  modalClose() {
    this.setState({
      openAnswerCallModal: false,
    });
  }

  callStatus(event) {
    console.log(event);
  }

  _renderThirdColumn() {
    const { activeThread } = this.props;

    if (this.state.openCCM) {
      return (
        <CCMContainer activeThread={this.props.activeThread} ccmIsOpen={this.state.openCCM} closeCCM={this.closeCCM} />
      );
    }

    // if (this.state.openDocument) {
    // else if (activeThread.threadType === "group") {
    //     return(<DocumentContainer onDocumentClose={this.closeDocument} onDocumentOpen={this.openDocument} activeThread={this.props.activeThread} documentIsOpen={this.state.openDocument} closeDocument={this.clodeDocument}/>)
    // }

    // return null;
  }

  handleJoin() {
    const { roomId } = this.state;

    const conferenceUrl = [window.location.origin, "/conferencecall", "?roomId=" + roomId].join("");

    window.open(conferenceUrl, "Hubchart Conference Call", "status=1,menubar=0");

    this.setState({ showConferenceInvite: false });
  }

  handleEnd() {
    this.setState({ showConferenceInvite: false });
  }

  handleLastDateActive = async (otp) => {
    console.log("here");
    // this.setState({ isValidating: true });
    // await this.props.validateOTP(
    //   { otp, phoneNumber: Parse.User.current().get("username") },
    //   response => {
    let currentUser = Parse.User.current();
    currentUser.set("lastDateActive", {
      iso: new Date().toISOString(),
      __type: "Date",
    });
    currentUser.save();
    //     return;
    //   }
    // );
    // setTimeout(() => {
    //   this.setState({ isValidating: false });
    //   window.location.href = "/u";
    // }, 2500);
  };

  updateLastDateActive = () => {
    window.onbeforeunload = setLastDateActive;
    function setLastDateActive() {
      let currentUser = Parse.User.current();
      currentUser.set("lastDateActive", {
        iso: new Date().toISOString(),
        __type: "Date",
      });
      currentUser.save();
      return null;
    }
  };

  render() {
    let hideConversation = false;
    let hideList = false;
    if (isMobile) {
      if (this.props.activeThread.objectId) {
        hideList = true;
      } else {
        hideConversation = true;
      }
    }

    const { isValidSession, isValidating } = this.state;

    const { activeTab } = this.props;

    return (
      <Fragment>
        {/* {!isValidSession && (
          <RequirePassword
            onClose={otp => {
              if (otp) {
                this.handleLastDateActive(otp);
              } else {
                Parse.User.logOut().then(() => {
                  localStorage.setItem("is_logged_in", false);
                  setTimeout(() => {
                    window.location.href = "/";
                  }, 200);
                });
              }
            }}
            isValidating={isValidating}
            title="One-Time Passcode Required"
            message="You need to enter an OTP to sign in."
          />
        )}
        {isValidSession && ( */}
        <>
          <div>
            {/* <Offline>
                    <div className="offline-container">
                        <span>
                            <i className="fas fa-wifi-slash"></i> No internet connection
                        </span>
                    </div>
                </Offline> */}
            <Navbar history={this.props.history} />
          </div>
          <div className="App-Row">
            {["message", "group", "chart", "contact", "conference", "create_chat", "todos"].includes(activeTab) ? (
              <TabList {...this.props} hideList={hideList} isMobile={isMobile} pubNub={this.PubNub} />
            ) : (
              ""
            )}

            {["message", "group", "chart", "contact", "todos"].includes(activeTab) ? (
              <Conversation
                {...this.props}
                ccmIsOpen={this.state.openCCM}
                documentIsOpen={this.state.openDocument}
                openCCM={this.openCCM}
                closeCCM={this.closeCCM}
                openDocument={this.openDocument}
                closeDocument={this.closeDocument}
                hideConversation={hideConversation}
                isMobile={isMobile}
                pubNub={this.PubNub}
              />
            ) : (
              ""
            )}

            {activeTab === "conference" ? <CalendarView /> : ""}

            {activeTab === "create_chat" ? <CreateChat history={this.props.history} /> : ""}

            {activeTab === "dashboard" ? <Dashboard /> : ""}

            {activeTab === "progressNote" ? <ProgressNotesCard /> : ""}
            {this._renderThirdColumn()}

            {this.state.showWindowPortal && (
              <VideoPortal closeWindowPortal={this.closeWindowPortal}>
                <VideoContainer />
              </VideoPortal>
            )}

            {this.state.openAnswerCallModal && (
              <CallerConfirmationModal
                didCancel={this.state.didCancel}
                declineCall={this.declineCall.bind(this)}
                confirmAction={this.confirmAction.bind(this)}
                isOpen={this.state.openAnswerCallModal}
                modalClose={this.modalClose.bind(this)}
                incomingCall={this.state.incomingCall}
              />
            )}

            <ProfileSetting />

            <ToastContainer
              className="toast-container-main"
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
            <ConferenceToastContainer conference={this.props.conference} />
            <ConferenceInvite
              onJoin={this.handleJoin}
              onEnd={this.handleEnd}
              message={`${this.state.hostName} has invited you to a conference call.`}
              show={this.state.showConferenceInvite}
            />
          </div>
        </>
        {/* )} */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  conference: state.conference.activeConference,
  appState: state.session.appState,
  activeThread: state.thread.activeThread,
  hasCall: state.call.hasCall,
  onCall: state.call.onCall,
  isCalling: state.call.isCalling,
  callerName: state.call.callerName,
  callerProfilePic: state.call.callerProfilePic,
  callDestination: state.call.callDestination,
  hasVideo: state.call.hasVideo,
  callRoom: state.call.callRoom,
  callUUID: state.call.callUUID,
  installationId: state.session.installationId,
  activeTab: state.thread.activeTab,
  read: state.thread.read,
});

export default connect(mapStateToProps, {
  receiveNewMessage,
  fetchMessage,
  recentUpdated,
  setAppState,
  incomingCall,
  receiveCall,
  declineCall,
  fetchOrganization,
  updateBlock,
  updateInstallationId,
  fetchSubscriber,
  fetchHCUSubscription,
  fetchUnreadCount,
  setActiveTab,
  fetchThreads,
  fetchGroups,
  fetchPatients,
  loadGoogleApi,
  getMeetings,
  onConferenceEnd,
  getActiveConferenceCall,
  fetchRequests,
  requestOTP,
  validateOTP,
})(Main);
