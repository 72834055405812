import Parse from "parse";
import { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  forwardMessage,
  fetchThreads,
  forwardToGroup,
  forwardToContact,
  sendGroupMessage,
  sendPrivateMessage,
} from "../actions/ThreadAction";
import sort from "fast-sort";
import util from "../helper/util";

export default () => {
  const dispatch = useDispatch();

  const { initialLoad } = useSelector(
    (state) => ({
      initialLoad: state.thread.initialLoad,
    }),
    shallowEqual
  );

  /* 
    Generate random id
  */
  const randomID = () => {
    const s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };
    return s4() + s4();
  };

  /* 
    Accepts a message to be
    forwarded to another thread
  */
  const forward = ({ message, thread, comments = "" }) => {
    const text = getForwardText({
      originalMessageDate: getMessageDate(message),
      originalText: message.text,
      sender: getSenderName(message),
      comments,
    });

    switch (thread.threadType) {
      case "group":
        return dispatch(
          message.objectId
            ? forwardToGroup({ message, thread, text })
            : sendGroupMessage({ text, threadId: thread.threadId })
        );

      case "private":
        return dispatch(
          message.objectId
            ? forwardToContact({ message, thread, text })
            : sendPrivateMessage({
                text,
                partnerObjectId: thread.partnerObjectId,
                threadId: thread.threadId,
              })
        );

      default:
        break;
    }
  };

  /* 
    Accepts a multiple messages to be
    forwarded to another thread
  */
  const forwardMultiple = ({ messages = [], thread, comments }) => {
    const promises = messages.map((m, index) =>
      forward({
        message: m,
        comments: index === messages.length - 1 ? comments : "",
        thread,
      })
    );

    return Promise.all(promises);
  };

  const getCircleThread = (circle) => {
    const thread = {
      senderName: "",
      senderObjectId: "",
      threadDetail: "",
      unreadMessageCount: 0,
      threadId: "",
      objectId: "",
    };

    thread.threadType = "group";
    thread.threadId = circle.objectId;
    thread.objectId = circle.objectId;
    if (circle.image) {
      thread.groupImageURL = circle.image.url;
    }
    thread.groupName = circle.name;
    thread.groupType = circle.groupType;

    return thread;
  };

  const getContactThread = (contact) => {
    const thread = {
      senderName: "",
      senderObjectId: "",
      threadDetail: "",
      unreadMessageCount: 0,
      threadId: "",
      objectId: "",
    };

    let tempThreadId;
    let idArray = [Parse.User.current().id, contact.objectId];
    idArray = sort(idArray).asc((p) => p.toLowerCase());
    tempThreadId = idArray.join("_");
    thread.threadId = tempThreadId;
    thread.objectId = contact.rosterId;
    thread.partnerHcuType = "regular";
    thread.partnerName = contact.displayName;
    thread.partnerObjectId = contact.objectId;
    thread.threadType = "private";
    if (contact.picture) thread.partnerImageURL = contact.picture.url;
    if (contact.hcuSubscriptionType) thread.partnerHcuType = contact.hcuSubscriptionType;

    return thread;
  };

  const getForwardText = ({ originalText = "", comments = "", sender = "", originalMessageDate = "" }) => {
    const forwardText = [`Fwd: "${originalText}"`];

    if (sender) {
      const senderWithDate = [sender, originalMessageDate].join(" - ");

      forwardText.push(senderWithDate);
    }

    if (comments) {
      forwardText.push(comments);
    }

    return forwardText.join("\n--------\n");
  };

  const getSenderName = (message = {}) => {
    const { user } = message;

    if (!user) return "";

    return user.displayName;
  };

  const getMessageDate = (message = {}) => {
    const { originalMessageDate } = message;

    if (!originalMessageDate) {
      return util.formatForwardDate(new Date().toISOString());
    }

    if (originalMessageDate instanceof Date) {
      return util.formatForwardDate(originalMessageDate.toISOString());
    }

    return util.formatForwardDate(originalMessageDate.iso);
  };

  useEffect(() => {
    if (!initialLoad) {
      dispatch(fetchThreads());
    }
  }, [initialLoad]);

  return {
    forward,
    getCircleThread,
    getContactThread,
    forwardMultiple,
  };
};
