import React from 'react';
import Member from './Participant';
import _ from 'lodash';

export default function MemberList(props) {

    const {
        members,
        admins,
        owner,
        isAdmin,
        isCreator,
        selected,
    } = props;

    const allMembers = [
        ...members,
        ...admins,
    ];

    if (owner.objectId) {
        allMembers.push(owner);
    }

    const uniqueMembers = _.uniqBy(allMembers, 'objectId');

    const handleClick = (member) => {
        props.onSelect(member);
    }

    const displayMembers = uniqueMembers.map((data) => {
        return <Member
            selected={selected.includes(data.objectId)}
            onClick={handleClick}
            key={data.objectId}
            member={data}
            isCreator={isCreator}
            isAdmin={isAdmin}
            removeMember={props.onRemoveMember}
            assignAdmin={props.onAssignAdmin}
            removeAdmin={props.onRemoveAdmin} />
    });

    return (
        <div className="accordion" id="accordionExample">

            <div className="card-body" style={{ padding: "0" }}>
                <div className="list-group">
                    {displayMembers}
                </div>
            </div>
        </div>
    );
}