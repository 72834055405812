import React, { useEffect, useState } from "react";
import Parse from "parse";
import moment from "moment";
import Notify from "../../../Common/Notify";
import { computeAge } from "../../../../helper/util";

import "./index.css";

const $ = window.$;

function PatientInfoComponent({
  patientInfo: patient = {},
  dateSeen = new Date(),
  visitProvider = "",
  clinicLocation = "",
  encounterType = "",
  updatePatientInfo = () => {},
  updateAnyInPatient = () => {},
  refreshPatientData = () => {},
  isDisabled = false,
}) {
  const maxDate = moment(new Date()).format("YYYY-MM-DDTHH:mm");
  const dateOfService = moment(dateSeen).format("YYYY-MM-DDTHH:mm");
  const patientInfo = {
    ...patient,
    dob: moment(patient.dob).format("YYYY-MM-DD"),
    age: parseInt(computeAge(patient.dob)),
  };

  async function savePatientInfo(data) {
    const info = {
      objectId: patientInfo.objectId,
      lastName: patientInfo.lastName,
      firstName: patientInfo.firstName,
      middleName: patientInfo.middleName,
      gender: patientInfo.gender,
      phoneNumber: patientInfo.phoneNumber,
      dob: patient.dob,
      age: patient.age,
    };
    const Circle = Parse.Object.extend("Circle");
    const newCircle = new Circle(info);
    try {
      await newCircle.save();
      refreshPatientData(patientInfo.objectId);
      Notify.success("Patient information saved.");
    } catch (err) {
      Notify.error("Could not save patient information.");
      console.error(err);
    }
  }

  function handleInputOnChange(e) {
    e.preventDefault();
    const { name, value } = e.target;

    updatePatientInfo(name, value);
  }

  function handleUpdateAny(e) {
    e.preventDefault();
    updateAnyInPatient(e);
  }

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="card custom-card">
      <h5 className="card-header">PATIENT INFORMATION</h5>
      <div className="card-body" style={{ padding: "10px" }}>
        <div className="custom-form-group-container">
          <div className="custom-form-group">
            <span htmlFor="lastName">Last Name</span>
            <input
              id="lastName"
              name="lastName"
              type="text"
              className="form-control"
              placeholder="Last Name"
              onChange={(e) => handleInputOnChange(e)}
              value={patientInfo.lastName || ""}
              disabled={isDisabled}
            />
          </div>
          <div className="custom-form-group">
            <span htmlFor="firstName">First Name</span>
            <input
              id="firstName"
              name="firstName"
              type="text"
              className="form-control"
              placeholder="First Name"
              onChange={(e) => handleInputOnChange(e)}
              value={patientInfo.firstName || ""}
              disabled={isDisabled}
            />
          </div>
          <div className="custom-form-group">
            <span htmlFor="middleName">Middle Name/Init.</span>
            <input
              id="middleName"
              name="middleName"
              type="text"
              className="form-control"
              placeholder="Middle Name/Init."
              onChange={(e) => handleInputOnChange(e)}
              value={patientInfo.middleName || ""}
              disabled={isDisabled}
            />
          </div>
          <div className="custom-form-group">
            <span htmlFor="mrNumber">MR No.</span>
            <input
              id="mrNumber"
              name="mrNumber"
              type="text"
              className="form-control"
              placeholder="MR No."
              onChange={(e) => handleInputOnChange(e)}
              value={patientInfo.mrNumber || ""}
              disabled={isDisabled}
            />
          </div>
          <div className="custom-form-group">
            <span htmlFor="dob">Date of Birth</span>
            <input
              id="dob"
              name="dob"
              type="date"
              className="form-control"
              placeholder="Date of Birth"
              onChange={(e) => handleInputOnChange(e)}
              value={patientInfo.dob || ""}
              disabled={isDisabled}
            />
          </div>
          <div className="custom-form-group">
            <span htmlFor="age">Age</span>
            <input
              id="age"
              name="age"
              type="number"
              className="form-control"
              placeholder="Age"
              onChange={(e) => handleInputOnChange(e)}
              value={patientInfo.age || 0}
              disabled={isDisabled}
            />
          </div>
          <div className="custom-form-group">
            <span htmlFor="gender">Gender</span>
            <select
              id="gender"
              name="gender"
              type="text"
              className="form-control"
              placeholder="Gender"
              onChange={(e) => handleInputOnChange(e)}
              value={patientInfo.gender || ""}
              disabled={isDisabled}
            >
              <option value="" disabled>
                Select Gender
              </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>
          <div className="custom-form-group">
            <span htmlFor="race">Race/Ethnicity</span>
            <input
              id="race"
              name="race"
              type="text"
              className="form-control"
              placeholder="Race/Ethnicity"
              onChange={(e) => handleInputOnChange(e)}
              value={patientInfo.race || ""}
              disabled={isDisabled}
            />
          </div>
          <div className="custom-form-group">
            <span htmlFor="preferredLanguage">Preferred Language</span>
            <input
              id="preferredLanguage"
              name="preferredLanguage"
              type="text"
              className="form-control"
              placeholder="Preferred Language"
              onChange={(e) => handleInputOnChange(e)}
              value={patientInfo.preferredLanguage || ""}
              disabled={isDisabled}
            />
          </div>
          <div className="custom-form-group">
            <span htmlFor="phoneNumber">Phone No.</span>
            <input
              id="phoneNumber"
              name="phoneNumber"
              type="text"
              className="form-control"
              placeholder="Phone No."
              onChange={(e) => handleInputOnChange(e)}
              value={patientInfo.phoneNumber || ""}
              disabled={isDisabled}
            />
          </div>
          {/* <div className="custom-form-group">
            <span htmlFor="primaryMd">Primary MD</span>
            <input 
              id="primaryMd" 
              name="primaryMd" 
              type="text" 
              className="form-control" 
              placeholder="Primary MD" 
              onChange={e => handleInputOnChange(e)}
              value={patientInfo.primaryMd || ''}
              disabled={isDisabled} />
          </div> */}
          <div className="custom-form-group">
            <span htmlFor="pharmacy">Default Pharmacy</span>
            <input
              id="pharmacy"
              name="pharmacy"
              type="text"
              className="form-control"
              placeholder="Default Pharmacy"
              onChange={(e) => handleInputOnChange(e)}
              value={patientInfo.pharmacy || ""}
              disabled={isDisabled}
            />
          </div>
          <div className="custom-form-group">
            {/* <span htmlFor="insurance">Insurance</span>
            <select 
              id="insurance" 
              name="insurance" 
              type="text" 
              className="form-control" 
              placeholder="Insurance"
              onChange={e => handleInputOnChange(e)}
              value={patientInfo.insurance || ''}>
              <option value="Test insurance 1">Test insurance 1</option>
              <option value="Test insurance 2">Test insurance 2</option>
            </select> */}
            <span htmlFor="insurance">Insurance</span>
            <input
              id="insurance"
              name="insurance"
              type="text"
              className="form-control"
              placeholder="Insurance"
              onChange={(e) => handleInputOnChange(e)}
              value={patientInfo.insurance || ""}
              disabled={isDisabled}
            />
          </div>
        </div>
        <button
          type="button"
          className="btn btn-hubchart btn-hubchart-primary btn-sm"
          style={{
            height: "unset",
            marginLeft: "5px",
            marginBottom: "5px",
            fontSize: "12px",
          }}
          onClick={() => savePatientInfo()}
        >
          Update Info
        </button>
        <hr style={{ margin: "5px" }} />
        <div className="custom-form-group-container">
          <div className="custom-form-group" style={{ maxWidth: "310px" }}>
            <span htmlFor="dateSeen">Date Seen</span>
            <input
              id="dateSeen"
              name="dateSeen"
              type="datetime-local"
              className="form-control"
              placeholder="Date Seen"
              onChange={(e) => handleUpdateAny(e)}
              max={maxDate}
              value={dateOfService || ""}
              required={true}
              disabled={isDisabled}
            />
            <div className="invalid-feedback">Date seen is required.</div>
          </div>
          <div className="custom-form-group">
            <span htmlFor="visitProvider">Provider this visit</span>
            <input
              id="visitProvider"
              name="visitProvider"
              type="text"
              className="form-control"
              placeholder="Provider this visit"
              onChange={(e) => handleUpdateAny(e)}
              value={visitProvider || ""}
              disabled={isDisabled}
            />
          </div>
          {/* <div className="custom-form-group">
            <span htmlFor="encounterType">Encounter Type</span>
            <select 
              id="encounterType" 
              name="encounterType" 
              className="form-control" 
              placeholder="Encounter Type"
              onChange={e => handleInputOnChange(e)}
              value={encounterType || ''}>
              <option value="">None</option>
              <option value="clinic">Clinic Visit</option>
              <option value="home">Home</option>
            </select>
          </div> */}
          <div className="custom-form-group">
            <span htmlFor="visitProvider">Clinic Location</span>
            <input
              id="clinicLocation"
              name="clinicLocation"
              type="text"
              className="form-control"
              placeholder="Clinic Location"
              onChange={(e) => handleUpdateAny(e)}
              value={clinicLocation || ""}
              disabled={isDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatientInfoComponent;
