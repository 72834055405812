import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import ImageLoad from "../../Common/ImageLoad";
import ImagePlaceholder from "../../Common/ImagePlaceholder";
import util from "../../../helper/util";
import "./index.css";

const AttachmentContainer = ({ attachments = [], onOpen }) => {
  return (
    <div className="attachment-container">
      {attachments.map((a) => (
        <Attachment attachment={a} onOpen={onOpen} />
      ))}
    </div>
  );
};

const Attachment = ({ attachment, onOpen }) => {
  const detectType = (type = "") => {
    const typeRegex = {
      image: /^(image)/i,
      video: /^(video)/i,
      audio: /^(audio)/i,
      pdf: /(pdf)$/i,
    };

    if (typeRegex.image.test(type)) return "image";
    if (typeRegex.video.test(type)) return "video";
    if (typeRegex.audio.test(type)) return "audio";
    if (typeRegex.pdf.test(type)) return "pdf";

    return "";
  };

  const handleDownloadFile = (url) => {
    util.downloadFile(url);
  };

  const attachmentType = attachment.file ? detectType(attachment.fileType) : "";

  switch (attachmentType) {
    case "image":
      return (
        <Image
          thumbnail={attachment.thumbNail}
          file={attachment.file}
          onOpen={onOpen}
        />
      );
    case "pdf":
      return (
        <Pdf
          thumbnail={attachment.thumbNail}
          file={attachment.file}
          onOpen={onOpen}
        />
      );
    case "video":
      return <Video file={attachment.file} />;
    case "audio":
      return <Audio file={attachment.file} fileType={attachment.fileType} />;
    default:
      return (
        <div>
          <ImagePlaceholder type="" />
          {attachment.file ? (
            <a
              href="#"
              onClick={(e) => handleDownloadFile(attachment.file.url)}
            >
              {attachment.file.name}
            </a>
          ) : (
            ""
          )}
        </div>
      );
  }
};

const Image = ({ thumbnail = {}, file = {}, onOpen }) => {
  const url = thumbnail.url || file.url;

  return (
    <div className="cursor-pointer">
      <ImageLoad openLightbox={() => onOpen(file.url)} imageURL={url} />
    </div>
  );
};

const Pdf = ({ file = {}, thumbnail, onOpen }) => {
  return (
    <div className="clickable">
      <a href="#" onClick={() => window.open(file.url, "_blank")}>
        {" "}
        {/* onOpen(file.url) */}
        {thumbnail ? (
          <ImageLoad openLightbox={() => {}} imageURL={thumbnail.url} />
        ) : (
          <Document
            file={file.url}
            loading={
              <div>
                <i className="fas fa-circle-notch fa-spin"></i> Loading PDF
              </div>
            }
          >
            <Page
              pageNumber={1}
              className="react-pdf__Page-canvas-container-2"
            />
          </Document>
        )}
      </a>
      <a
        style={{
          textDecoration: "underline",
          marginLeft: "3px",
          color: "#2194f3",
        }}
        onClick={() => window.open(file.url, "_blank")}
      >
        {file.name}
      </a>
    </div>
  );
};

const Video = ({ file }) => {
  return (
    <video
      key={1}
      style={{ marginBottom: "15px", display: "block", width: "100%" }}
      controls
    >
      <source src={file.url} />
      Your browser does not support HTML5 video.
    </video>
  );
};

const Audio = ({ file, fileType }) => {
  return (
    <audio
      controls
      style={{ display: "block", marginBottom: "10px", marginTop: "10px" }}
    >
      <source src={file.url} type={fileType} />
      Your browser does not support the audio element.
    </audio>
  );
};

export default AttachmentContainer;
