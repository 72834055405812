import React from "react";
import util from '../../../helper/util';
import moment from 'moment';

function RequestItem(props) {

    const { conference, onOpenDeleteModal } = props;

    const {
        dateScheduled = {},
        timeEnded = {},
        participants = [],
        joined = [],
        label = '',
        comments = '',
        currentUser = {},
    } = conference;

    const containerStyle = {
        minHeight: "65px",
    };

    const split_description = comments.split('Hubchart Link:');
    
    const link = split_description[split_description.length - 1].replace(/\s/g, '');
    
    const imageUrl = require("../../../assets/images/default.png");

    const filteredParticipants = participants.filter(p => p.objectId !== currentUser.id);

    const names = filteredParticipants.map(p => p.firstName).join(', ');

    const pics = filteredParticipants.map(p => {
        const url = p.picture ? p.picture.url : imageUrl;

        return (
            <div key={p.objectId    } className="img-conference-wrapper">
                <img src={url} width="25" height="25" />
            </div>
        );
    });

    const currentDate = moment();
    const startDate = moment(dateScheduled.iso);
    const dayEndTime = moment(startDate).endOf('day');
    const endDate = moment((timeEnded || {}).iso || dayEndTime);
    const showJoin = startDate.isBefore(currentDate);
    const appendJoinButton = (currentDate.isBetween(startDate, endDate)) ? true : false;
    
    function renderPatientInfo() {
        return (
            <div className="mt-2">
                {label ?
                    <p className="name">
                        <i className="fas fa-tablet-alt hc-primary-text mr-2"></i>
                        {label}
                    </p>
                    : ''
                }
            </div>
        );
    }

    function joinMeeting (url) {
        window.open(url, '_blank');
    }

    return (
        <div 
            className="list-card-conference" 
            style={containerStyle} 
            style={{ 
                display: 'unset',
                background: (joined.length === 0 && showJoin && !appendJoinButton) ? '#e5e5e5' : 'unset',
            }}>
            <div>
                <div>
                    <div style={{ height: '15px' }} />
                    {label ?
                        renderPatientInfo():
                        <div className="mt-2">
                            <p className="name">
                                {label || 'No title'}
                                {/* Scheduled conference */}
                            </p>
                        </div>
                    }
                    <span style={{ position: 'absolute', right: '5px', top: '5px' }}>
                        <span className="badge badge-warning mr-1">{`${moment(dateScheduled.iso).format("MMM DD")}`}</span>
                        <span className="badge badge-warning mr-1">{`${moment(dateScheduled.iso).format("hh:mm A")}`}</span>
                    </span>

                    <p className="text-content">
                        {`${(names.length > 0) ? `with ${names}` : ''}`}
                    </p>

                    <p className="name conference-participants">
                        {pics}
                    </p>
                </div>
                <div className="center-vertical">
                    <div style={{ textAlign: 'right' }}>
                        <a 
                            style={{ fontSize: '12px', marginRight: '5px' }} 
                            href="#!" 
                            onClick={() => onOpenDeleteModal()}
                            className="badge badge-danger">
                            Decline
                        </a>
                        <a 
                            style={{ fontSize: '12px' }} 
                            href="#!" 
                            onClick={props.onOpenUpdateModal}
                            className="badge badge-success">
                            Accept
                        </a>
                    </div>
                    {/* <div className="dropdown">
                        <button type="button" className="btn contact-menu-button dropdown-toggle" 
                        id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fas fa-ellipsis-v"></i>
                        </button>
                        <div style={{ boxShadow: '0px 4px 8px 0px #888888' }} className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                            <a className="dropdown-item" href="#!" onClick={props.onOpenUpdateModal}>
                                <i className="fa fa-edit" style={{ marginRight: '5px' }}></i>
                                Accept
                            </a>
                            <a className="dropdown-item" href="#!" onClick={() => onOpenDeleteModal()}>
                                <i className="fa fa-remove" style={{ marginRight: '5px' }}></i>
                                Decline
                            </a>
                            <h6 className="dropdown-header">Action</h6>
                        </div>
                    </div>  */}
                </div>
            </div>
        </div>
    );
}

export default RequestItem;
