import React, { Component } from 'react'
import Parse from 'parse'
import '../Login/Login.css'
import '../Login/Login.mobile.css'
import ReactPhoneInput from "react-phone-input-2";
import Maintenance from '../Maintenance/Maintenanance'
import Logo from '../../../assets/images/logo-blue.png';
import ExceedLimit from '../Signup/ExceedLimit';

export class ForgotPassword extends Component {
    constructor() {
        super();
        this.state = {
            willFindUser : true,
            usingWeb : false,
            hasEmail : false,
            sendingEmailReset : false,
            emailResetSent : false,
            error : false,
            errorTitle : "",
            errorMessage : "",
            requestByUsername : "",
            requestEmail : '',
            noUser : false,
            sendCode : false,
            isSendingSMS : false,
            handleVerificationCode : false,
            isVerifyingSMS : false,
            isVerifyingCode : false,
            validCode : false,
            isResendingSMS : false,
            setTime : 60,
            resendLimit : 3,
            findingUser : false
        }
        this._renderSteps = this._renderSteps.bind(this);
        this.findUser = this.findUser.bind(this);
        this.handlePhoneInput = this.handlePhoneInput.bind(this);
        this.requestPasswordReset = this.requestPasswordReset.bind(this);
        this.openExceedLimit = this.openExceedLimit.bind(this);
    }

    handlePhoneInput(value) {
		this.setState({
            phoneNumber: value
		})
    }

    findUser(e) {
        e.preventDefault();

        this.setState({
            findingUser : true
        })

        let username;
        if (this.state.usingWeb) {
            username = this.refs.username.value;
        } else {
            username = this.state.phoneNumber.replace(/\D/g,'');
        }

        const params =  { username: username };
        Parse.Cloud.run("getUserEmail", params).then(req => {
            if (req.email === null) {
                this.setState({
                    requestByUsername : username,
                    willFindUser : false,
                    sendCode : true,
                    hasEmail : false,
                    findingUser : false,
                })
            }

            let hasEmail = (req.email) ? true : false;

            this.setState({
                requestByUsername : username,
                hasEmail : hasEmail,
                willFindUser : false,
                requestEmail : req.email,
                phoneNumber : "",
                findingUser : false,
            })
        }).catch(error => {
            if (error.message === "A user with this username does not exist.") {
                this.setState({
                    hasEmail : false,
                    willFindUser : false,
                    phoneNumber : "",
                    noUser : true,
                    findingUser : false,
                })
            }
        });
    }

    requestPasswordReset() {
        this.setState({
            sendingEmailReset : true
        })
        const params =  { username: this.state.requestByUsername };
        Parse.Cloud.run("requestPasswordReset", params).then(req => {
            this.setState({
                emailResetSent : true,
                sendingEmailReset : false,
                hasEmail : false
            })
        }).catch(error => {
            this.setState({
                error : true,
                hasEmail : false,
                sendingEmailReset : false,
                errorTitle : "Request Password Reset",
                errorMessage : "Hubchart can't send password reset right now, please try again later."
            })
        });
    }

    sendVerificationCode() {
        this.setState({
            isSendingSMS : true
        })

        let self = this;
        Parse.Cloud.run("requestVerificationCode", {
            to: this.state.requestByUsername
        }).then(function(res) {
            self.setState({
                isVerifyingSMS : true,
                isResendingSMS : false,
                isSendingSMS : false,
                sendCode : false
            })
            self.startTimeout();
        }).catch((error) => {
            if (error.message === "SMS_MAX_RETRIES_EXCEEDED") {
                self.openExceedLimit();
                self.setState({
                    isResendingSMS : false,
                    isSendingSMS : false
                })
            }
        });

        // this.setState({
        //     error : true,
        //     hasEmail : false,
        //     sendCode : false,
        //     isSendingSMS : false,
        //     errorTitle : "Verification Code",
        //     errorMessage : "Can't send verification right now, please try again later."
        // })
    }

    verifySMSCode(e) {
        e.preventDefault();
        this.setState({
            isVerifyingCode : true,
            showValidStatus : false,
        })

        let { requestByUsername } = this.state;

        let self = this;
        Parse.Cloud.run("verifyCode", {
            number : requestByUsername,
            code : self.refs.code.value
        }).then(function(res) {
            self.requestResetForMobile();
        }).catch((error) => {
            this.setState({
                validCode : false,
                isVerifyingCode : false,
                showValidStatus : true
            });
        });
    }

    resendSMSVerification() {
        let { requestByUsername } = this.state;

        let limit = this.state.resendLimit;
		if (limit == 0) {
			return;
		}
		let setTime;
		if (limit == 3) {
			setTime = 120;
		} else if (limit == 2) {
			setTime = 180;
		} else if (limit == 1) {
			setTime = null;
		}
		limit = limit - 1;

        this.setState({
            isResendingSMS : true,
            resendLimit : limit,
			setTime : setTime
        });

        let self = this;
        Parse.Cloud.run("requestVerificationCode", {
            to: requestByUsername
        }).then(function(res) {
            self.setState({
                isVerifyingSMS : true,
                isResendingSMS : false
            })
            self.startTimeout();
        }).catch((error) => {
            if (error.message === "SMS_MAX_RETRIES_EXCEEDED") {
                self.openExceedLimit();
                self.setState({
                    isResendingSMS : false,
                    isSendingSMS : false
                })
            }
        });
    }


    requestResetForMobile() {
        const params =  { username: this.state.requestByUsername };
        Parse.Cloud.run("requestPasswordReset", params).then(req => {
            this.setState({
                mobileResetSent : true,
                isVerifyingSMS : false,
            })
        }).catch(error => {
            this.setState({
                error : true,
                isVerifyingSMS : false,
                errorTitle : "Request Password Reset",
                errorMessage : "Hubchart can't send password reset right now, please try again later."
            })
        });
    }

    startTimeout() {
		this.countdown();

		this.timeOut = setInterval(function() {
			this.countdown();
		}.bind(this), 1000);
	}

	countdown() {
		let setTime = this.state.setTime;
		setTime = setTime - 1;
		this.setState({
			setTime : setTime
		})
		if (setTime == 0) {
			clearInterval(this.timeOut);
		}
	}

    openExceedLimit() {
        this.setState({
            openExceedLimit : true
        })
    }

    searchAgain() {
        this.setState({
            willFindUser : true,
            usingWeb : false,
            hasEmail : false,
            sendingEmailReset : false,
            emailResetSent : false,
            error : false,
            errorTitle : "",
            errorMessage : "",
            requestByUsername : "",
            requestEmail : '',
            noUser : false,
            sendCode : false,
            isSendingSMS : false,
            handleVerificationCode : false,
            isVerifyingSMS : false,
            isVerifyingCode : false,
            validCode : false,
            isResendingSMS : false,
            setTime : 60,
        })
    }

    switchToMobile() {
        this.setState(prevState => ({
            usingWeb: !prevState.usingWeb
        }));
    }

    modalClose() {
        this.setState({
            openExceedLimit : false,
        })
    }

    _renderSteps() {
        if (this.state.willFindUser) {
            let switchText = "Search phone number"
            if (!this.state.usingWeb) {
                switchText = "Search using Web Username"
            }

            return (
                <form onSubmit={this.findUser.bind(this)} style={{marginTop: "20px"}}>
                    <a href="/" style={{float: "right", fontSize: "1rem", fontWeight: "500"}}>Login</a>
                    <h5>Forgot Password</h5>
                    <p>Enter your phone number or your web username</p>
                    <div className="form-group">
                        {this.state.usingWeb ?
                            <div className="card">
                                <input ref="username" type="username" id="username" placeholder="Username" required/>
                                <i className="fas fa-user hc-primary-text"></i>
                            </div>
                            :
                            <div className="card" style={{paddingLeft: "0px"}}>
                                <ReactPhoneInput defaultCountry={'us'} enableLongNumbers={true} onChange={this.handlePhoneInput} value={this.state.phoneNumber} inputStyle={{width: "100%", outline: "none", boxShadow: "none"}}/>
                            </div>
                        }

                        <span style={{fontSize: "13px", color:"#00000096", fontWeight: "500"}}>{switchText}<a href="#!" onClick={this.switchToMobile.bind(this)} className="mobile-login hc-primary-text"> Click here.</a></span>
                    </div>
                    <div className="form-group" style={{marginBottom: "30px"}}>
                        {this.state.findingUser ?
                            <span style={{float: "right"}}><i className="fas fa-circle-notch fa-spin"></i> Searching...</span>
                            :
                            <button ref="submit" type="submit" className="btn btn-hubchart" style={{width: "100%", fontSize: "18px"}}>Search</button>
                        }
                    </div>
                </form>
            )
        } else if (this.state.hasEmail) {
            return (
                <div style={{marginTop: "20px"}}>
                    <a onClick={this.searchAgain.bind(this)} href="#!" style={{float: "right", fontSize: "1rem", fontWeight: "500", paddingTop: "5px"}}>Not me</a>
                    <div>
                        <h5>Request Password Reset <img src={require("../../../assets/images/email-sent.png")} style={{width: "30px", marginBottom: "5px"}}/></h5>
                        <p>Hubchart will send a password reset mail to {this.state.requestEmail}</p>
                        {this.state.sendingEmailReset ?
                            <span style={{float: "right"}}><i className="fas fa-circle-notch fa-spin"></i> Sending...</span>
                            :
                            <button onClick={this.requestPasswordReset.bind(this)} className="btn btn-hubchart" style={{fontSize: "16px", float: "right"}}>Send</button>}
                    </div>
                </div>
            );
        } else if (this.state.emailResetSent) {
            return (
                <div style={{marginTop: "20px"}}>
                    <a href="/" style={{float: "right", fontSize: "1rem", fontWeight: "500"}}>Login</a>
                    <div>
                        <h5>Request Password Reset <img src={require("../../../assets/images/email-sent.png")} style={{width: "30px", marginBottom: "5px", paddingTop: "5px"}}/></h5>
                        <p>Password reset request has been sent to your email, please follow the instruction to change your password.</p>
                    </div>
                </div>
            );
        } else if (this.state.error) {
            return (
                <div style={{marginTop: "20px"}}>
                    <a href="/" style={{float: "right", fontSize: "1rem", fontWeight: "500"}}>Login</a>
                    <div>
                        <h5 style={{color: "#b81f54"}}>{this.state.errorTitle}</h5>
                        <p>{this.state.errorMessage}</p>
                    </div>
                </div>
            );
        } else if (this.state.noUser) {
            return (
                <div style={{marginTop: "20px"}}>
                    <h5>No account found</h5>
                    <p>There's no Hubchart Account with the info you provided.</p>
                    <button onClick={this.searchAgain.bind(this)} className="btn btn-hubchart" style={{fontSize: "16px", float: "right"}}>Try again</button>
                </div>
            )
        } else if (this.state.sendCode) {
            return (
                <div style={{marginTop: "20px"}}>
                    <a onClick={this.searchAgain.bind(this)} href="#!" style={{float: "right", fontSize: "1rem", fontWeight: "500", paddingTop: "5px"}}>Not me</a>
                    <div>
                        <h5>Verification Code</h5>
                        <p>Hubchart will send a verification code to +{this.state.requestByUsername}</p>
                        {this.state.isSendingSMS ?
                            <span style={{float: "right"}}><i className="fas fa-circle-notch fa-spin"></i> Sending...</span>
                            :
                            <>
                                {
                                    !this.state.isVerifyingSMS && <button onClick={this.sendVerificationCode.bind(this)} className="btn btn-hubchart" style={{fontSize: "16px", float: "right"}}>Send</button>
                                }
                            </>
                        }
                    </div>
                </div>
            )
        } else if (this.state.isVerifyingSMS) {
            let codeInputStyle = {}
            if (this.state.showValidStatus && !this.state.validCode) {
                codeInputStyle.border = "1px solid #d84b55";
            }
            return (
                <form onSubmit={this.verifySMSCode.bind(this)} style={{marginTop: "20px"}}>
                    <h5>Verification Code</h5>
                    <div className="form-group">
                        <span style={{fontSize: "13px", color:"#00000096", fontWeight: "500"}}>Please enter the verification code that we sent to your phone number</span>
                        <div className="card" style={codeInputStyle}>
                            <input ref="code" type="text" id="code" placeholder="****" maxLength="4"  required disabled={this.state.validCode}/>
                            <i className="fas fa-lock hc-primary-text"></i>
                            {this.state.isVerifyingCode && <div style={{position: "absolute", right: "-2px", height: "40px", width: "40px", color: "rgb(61, 155, 233)", fontSize: "18px"}}><i style={{position: "absolute", top: "10px"}} className="fas fa-circle-notch fa-spin"></i></div>}
                            {this.state.showValidStatus && this.state.validCode && <div style={{position: "absolute", right: "-2px", height: "40px", width: "40px"}}><i style={{color: "#4AAD52", fontSize: "18px"}} className="fas fa-check"></i></div>}
                            {this.state.showValidStatus && !this.state.validCode && <div style={{position: "absolute", right: "-2px", height: "40px", width: "40px"}}><i style={{color: "#d84b55", fontSize: "18px", marginLeft: "3.5px"}} className="fas fa-times"></i></div>}
                        </div>
                        {
                            !this.state.readyToSignup && <div>
                                {this.state.resendLimit != 0 ?
                                    <small className="form-text" style={{color: "rgb(61, 155, 233)"}}> Did not receive SMS? { this.state.setTime == 0 ? <span onClick={this.resendSMSVerification.bind(this)} className="badge badge-success" style={{fontSize: "12px", cursor: "pointer"}}>RESEND</span> : <strong>RESEND</strong>}  {this.state.setTime == 0 ? null : (this.state.setTime)}</small>
                                    :
                                    <small className="form-text" style={{color: "rgb(61, 155, 233)"}}> Please wait for your verification code. </small>
                                }
                            </div>

                        }

                    </div>

                    {this.state.isResendingSMS || !this.state.validCode && <div className="form-group" style={{marginBottom: "30px"}}>
                        {!this.state.isVerifyingCode && <button ref="submit" type="submit" className="btn btn-hubchart" style={{fontSize: "16px", float: "right"}}>Verify</button> }
                    </div>}
                    {this.state.isResendingSMS && <div className="form-group" style={{position: "relative"}}>
                            <span style={{float: "right"}}>Sending SMS Code</span>
                        </div>}
                </form>
            )
        } else if (this.state.mobileResetSent) {
            return (
                <div style={{marginTop: "20px"}}>
                    <a href="/" style={{float: "right", fontSize: "1rem", fontWeight: "500"}}>Login</a>
                    <div>
                        <h5>Password Reset</h5>
                        <p>A new password has been sent to your phone number +{this.state.requestByUsername}, you also change your password inside of the web app.</p>
                    </div>
                </div>
            );
        }
    }
    render() {
        return (
            <div className="Login-Container">
                <div className="Form-Container">
                    <img className="Logo" src={Logo} alt="Hubchart Logo" />
                    <h1 className="hc-primary-text">HUBCHART</h1>
                    {this._renderSteps()}
                </div>
                {this.state.openExceedLimit && <ExceedLimit isOpen={this.state.openExceedLimit} modalClose={this.modalClose.bind(this)} />}
            </div>
            // <Maintenance></Maintenance>
        )
    }
}

export default ForgotPassword
