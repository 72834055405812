import {
  SET_APP_FIRST_LOAD,
  SET_APP_STATE,
  SET_IDLE_MODAL_STATE,
  ORGANIZATION_FETCH_STATUS,
  FETCH_ORGANIZATION,
  FETCH_ORGANIZATION_GROUPS,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_IMAGE,
  NEW_ORGANIZATION_MEMBERS,
  NEW_ORGANIZATION_GUESTS,
  REMOVE_ORGANIZATION_MEMBERS,
  REMOVE_ORGANIZATION_GUESTS,
  NEW_ADMIN_ORGANIZATION_MEMBERS,
  REMOVE_ADMIN_ORGANIZATION_MEMBERS,
  ADD_GROUP_TO_ORGANIZATION,
  REMOVE_GROUP_TO_ORGANIZATION,
  UPDATE_INSTALLATION_ID,
  FETCH_PHYSICIANS_AND_EDITORS,
  PHYSICIAN_ROLE_USERS,
  EDITOR_ROLE_USERS
} from "./types";
import { parseAction } from "./REST";
import Parse from "parse";
import config from "../config";

export const updateInstallationId = () => dispatch => {
  Parse.Session.current().then(function(session) {
    let installationId = session.get("installationId");
    dispatch({
      type: UPDATE_INSTALLATION_ID,
      installationId: installationId
    });
  });
};

export const setAppFirstLoad = () => dispatch => {
  dispatch({
    type: SET_APP_FIRST_LOAD,
    appFirstLoad: true
  });
};

export const setAppState = state => dispatch => {
  dispatch({
    type: SET_APP_STATE,
    state: state
  });
};

export const setIdleModalState = state => dispatch => {
  dispatch({
    type: SET_IDLE_MODAL_STATE,
    state: state
  });
};

export const fetchOrganization = () => dispatch => {
  dispatch({
    type: ORGANIZATION_FETCH_STATUS,
    status: "FETCHING"
  });

  const currentUser = Parse.User.current();
  parseAction("get", config.BASE_URL + "/parse/classes/Organization", {
    where: {
      owner: {
        __type: "Pointer",
        className: "_User",
        objectId: currentUser.id
      }
    },
    include: ["members", "administrators", "owner", "guests"]
  })
    .then(organization => {
      if (organization.results.length === 0) {
        fetchIfMember(memberOrganization => {
          dispatch({
            type: FETCH_ORGANIZATION,
            status: "SUCCESS",
            organization: memberOrganization
          });
        });
      } else {
        dispatch({
          type: FETCH_ORGANIZATION,
          status: "SUCCESS",
          organization: organization.results[0]
        });
      }
    })
    .catch(error => {
      dispatch({
        type: ORGANIZATION_FETCH_STATUS,
        status: "ERROR"
      });
    });
};

const fetchIfMember = callback => {
  const currentUser = Parse.User.current();
  parseAction("get", config.BASE_URL + "/parse/classes/Organization", {
    where: {
      members: {
        __type: "Pointer",
        className: "_User",
        objectId: currentUser.id
      }
    },
    include: ["members", "administrators", "owner", "guests"]
  })
    .then(organization => {
      if (organization.results.length === 0) {
        callback({});
      } else {
        callback(organization.results[0]);
      }
    })
    .catch(error => {
      console.log(error);
    });
};

export const createOrganization = name => dispatch => {
  return new Promise((resolve, reject) => {
    const params = { name: name };
    Parse.Cloud.run("createOrganization", params)
      .then(req => {
        let jsonObject = JSON.stringify(req.organization.toJSON());
        jsonObject = JSON.parse(jsonObject);

        dispatch({
          type: FETCH_ORGANIZATION,
          status: "SUCCESS",
          organization: jsonObject
        });
        resolve(jsonObject);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const updateOrganizationName = (name, objectId) => dispatch => {
  return new Promise((resolve, reject) => {
    const params = { name: name, objectId: objectId };
    Parse.Cloud.run("updateOrganization", params)
      .then(req => {
        dispatch({
          type: UPDATE_ORGANIZATION,
          name: name
        });
        resolve(true);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const updateOrganizationProfile = (file, objectId) => dispatch => {
  return new Promise((resolve, reject) => {
    uploadFile(file)
      .then(parseFile => {
        const params = { image: parseFile, objectId: objectId };
        Parse.Cloud.run("updateOrganization", params)
          .then(req => {
            dispatch({
              type: UPDATE_ORGANIZATION_IMAGE,
              image: parseFile
            });
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      })
      .catch(error => {
        console.log(error);
        reject(new Error("Failed Upload"));
      });
  });
};

export const addMembersToOrganization = members => dispatch => {
  const params = { objectIds: [] };

  params.objectIds = members.map(item => item.objectId);

  setLoading(true);

  return new Promise((resolve, reject) => {
    Parse.Cloud.run("addMembersToOrganization", params)
      .then(req => {
        dispatch({
          type: NEW_ORGANIZATION_MEMBERS,
          members: members
        });

        setLoading(false);

        resolve(true);
      })
      .catch(error => {
        reject(error.message);

        setLoading(false);
      });
  });

  function setLoading(value) {
    dispatch({
      type: "ADDING_ORGANIZATION_MEMBERS",
      value
    });
  }
};

export const addGuestsToOrganization = (guests = [], objectId) => dispatch => {
  const guest = guests.map(m => m.objectId);

  const params = {
    guest,
    objectId
  };

  return Parse.Cloud.run("addGuestsToOrganization", params)
    .then(req => {
      dispatch({
        type: NEW_ORGANIZATION_GUESTS,
        guests
      });

      return true;
    })
    .catch(error => {
      return Promise.reject(error.message);
    });
};

export const removeMembersFromOrganization = objectId => dispatch => {
  const params = { objectIds: [objectId] };
  return new Promise((resolve, reject) => {
    Parse.Cloud.run("removeMembersFromOrganization", params)
      .then(req => {
        dispatch({
          type: REMOVE_ORGANIZATION_MEMBERS,
          objectId: objectId
        });
        resolve(true);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const removeGuestsFromOrganization = (
  userObjectId,
  orgObjectId
) => dispatch => {
  const params = {
    guest: [userObjectId],
    objectId: orgObjectId
  };

  return Parse.Cloud.run("removeGuestsFromOrganization", params)
    .then(req => {
      dispatch({
        type: REMOVE_ORGANIZATION_GUESTS,
        objectId: userObjectId
      });

      return true;
    })
    .catch(error => {
      return Promise.reject(error.message);
    });
};

export const addAdminsToOrganization = user => dispatch => {
  const params = { objectIds: [user.objectId] };
  return new Promise((resolve, reject) => {
    Parse.Cloud.run("addAdminsToOrganization", params)
      .then(req => {
        dispatch({
          type: NEW_ADMIN_ORGANIZATION_MEMBERS,
          user: user
        });
        resolve(true);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const removeAdminsFromOrganization = objectId => dispatch => {
  const params = { objectIds: [objectId] };
  return new Promise((resolve, reject) => {
    Parse.Cloud.run("removeAdminsFromOrganization", params)
      .then(req => {
        dispatch({
          type: REMOVE_ADMIN_ORGANIZATION_MEMBERS,
          objectId: objectId
        });
        resolve(true);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const fetchOrganizationGroups = ownerObjectId => dispatch => {
  return new Promise((resolve, reject) => {
    parseAction("get", config.BASE_URL + "/parse/classes/Circle", {
      where: {
        owner: {
          __type: "Pointer",
          className: "_User",
          objectId: ownerObjectId
        },
        groupType: "patient"
      }
    })
      .then(groups => {
        resolve(groups.results);
        dispatch({
          type: FETCH_ORGANIZATION_GROUPS,
          groups: groups.results
        });
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const addGroupsToOrganization = objectId => dispatch => {
  const params = { objectIds: [objectId] };
  return new Promise((resolve, reject) => {
    Parse.Cloud.run("addGroupsToOrganization", params)
      .then(req => {
        dispatch({
          type: ADD_GROUP_TO_ORGANIZATION,
          objectId: objectId
        });
        resolve(true);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const removeGroupsFromOrganization = objectId => dispatch => {
  const params = { objectIds: [objectId] };
  return new Promise((resolve, reject) => {
    Parse.Cloud.run("removeGroupsFromOrganization", params)
      .then(req => {
        dispatch({
          type: REMOVE_GROUP_TO_ORGANIZATION,
          objectId: objectId
        });
        resolve(true);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};

export const addOrgEmail = (email, objectId) => dispatch => {
  const params = {
    email,
    objectId
  };

  return new Promise((resolve, reject) => {
    Parse.Cloud.run("addOrgEmail", params)
      .then(req => {
        dispatch({
          type: "ADD_ORG_EMAIL",
          email
        });
        resolve(true);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};

export const removeOrgEmail = (email, objectId) => dispatch => {
  const params = {
    email,
    objectId
  };

  dispatch({
    type: "REMOVE_ORG_EMAIL",
    email
  });

  return Parse.Cloud.run("removeOrgEmail", params);
};

export const fetchphysiciansAndEditors = (orgObjectId = "") => dispatch => {
  Parse.Cloud.run("getOrgPhysicians", { orgObjectId })
    .then(response => {
      const users = response.users.map(user => user.toJSON());
      const physicians = users.filter(user => user.orgRole === "physician");
      const editors = users.filter(user => user.orgRole === "editor");

      dispatch({
        type: FETCH_PHYSICIANS_AND_EDITORS,
        users_with_role: users
      });
      dispatch({
        type: PHYSICIAN_ROLE_USERS,
        physicians
      });
      dispatch({
        type: EDITOR_ROLE_USERS,
        editors
      });
    })
    .catch(error => {
      console.log(error);
    });
};

export const setOrganizationRole = ({
  data,
  organizations,
  strRole
}) => dispatch => {
  const payload = { ...data };
  Parse.Cloud.run("setOrgRole", payload)
    .then(response => {
      const userObjectId = response.user.id;
      const orgRoles = organizations[strRole].map(org => {
        const orgRole = userObjectId === org.objectId ? data.role : org.orgRole;
        return {
          ...org,
          orgRole
        };
      });
      const newStructure = {
        ...organizations,
        [strRole]: orgRoles
      };

      dispatch({
        type: FETCH_ORGANIZATION,
        status: "SUCCESS",
        organization: newStructure
      });
    })
    .catch(error => {
      console.log(error);
    });
};

const uploadFile = file => {
  return new Promise((resolve, reject) => {
    var parseFile = new Parse.File("OrganizationPicture.jpg", { base64: file });
    parseFile.save().then(
      function() {
        resolve(parseFile);
      },
      function(error) {
        reject(error);
      }
    );
  });
};
