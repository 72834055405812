import React from "react";
import util from '../../../helper/util';

function ConferenceItem(props) {
    
    const { conference } = props;

    const {
        host = {},
        createdAt,
        timeStarted = {},
        timeEnded = {},
        participants,
        label,
        comments,
        chiefComplaint,
        dob,
        guests = [],
    } = conference;

    const containerStyle = {
        minHeight: "65px",
    };

    const imageUrl = require("../../../assets/images/default.png");;

    const createdDate = util.formatConferenceDate(conference.createdAt);

    const duration = util.formatConferenceDuration(timeStarted.iso, timeEnded.iso);

    const userNames = participants.map(p => p.firstName+' '+p.lastName);
    const guestNames = guests.map(g => g.displayName);

    const names = [...userNames, ...guestNames].join(', ');

    const userPics = participants.map(p => {
        const url = p.picture ? p.picture.url : imageUrl;

        return (
            <div key={p.objectId} className="img-conference-wrapper">
                <img src={url} width="25" height="25" />
            </div>
        );
    });

    const guestPics = guests.map(g => {
        const url = imageUrl;

        return (
            <div key={g.objectId} className="img-conference-wrapper">
                <img src={url} width="25" height="25" />
            </div>
        );
    });

    function renderPatientInfo() {
        return (
            <div className="mt-2 pt-2" style={{ borderTop: '1px solid lightgrey' }}>
                {label ?
                    <p className="name">
                        <i className="fa fa-user hc-primary-text mr-2"></i>
                        {label}
                    </p>
                    : ''
                }
                {dob ?
                    <p className="name">
                        <i className="fa fa-calendar hc-primary-text mr-2"></i>
                        {dob}
                    </p>
                    : ''
                }
                {comments ?
                    <p className="name">
                        <i className="fa fa-comments hc-primary-text mr-2"></i>
                        {comments}
                    </p>
                    : ''
                }
            </div>
        );
    }

    return (
        <div className="list-card-conference" style={containerStyle}>
            <div>
                <p className="name">
                    Duration:&nbsp;
                    {duration}
                </p>
                { !timeStarted.iso ?
                    <p className="text-missed">
                        Missed call
                    </p>
                    :
                    <p className="text-completed-call">
                        Completed
                    </p>
                }
                <span style={{ position: 'absolute', right: '5px', top: '0' }}>
                    <span className="badge badge-primary mr-1">{createdDate.date}</span>
                    <span className="badge badge-primary">{createdDate.time}</span>
                </span>

                <p className="text-content">
                    with {names}
                </p>

                <p className="name conference-participants">
                    {userPics}
                    {guestPics}
                </p>

                {label ? renderPatientInfo(): ''}
            </div>

            <div className="center-vertical">
                <div className="dropdown">
                    <button /* onClick={(e) => handleClick(e)} */ type="button" className="btn contact-menu-button dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-v"></i>
                    </button>
                    <div style={{ boxShadow: '0px 4px 8px 0px #888888' }} className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                        <h6 className="dropdown-header">Action</h6>
                        <a className="dropdown-item" href="#!" onClick={props.onOpenUpdateModal}>
                            <i className="fa fa-edit" style={{ marginRight: '5px' }}></i>
                            Enter/edit log details
                        </a>
                        <a className="dropdown-item" href="#!" onClick={props.onOpenForwardModal}>
                            <i className="fa fa-share-square" style={{ marginRight: '5px' }}></i>
                            Forward
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConferenceItem;
