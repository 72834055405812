import React, { useMemo } from 'react'

const GroupCollapse = ({ label = '', children, collapseId = '', defaultShown = true }) => {

  const collapseClassname = defaultShown ? "collapse show mt-2 position-relative" :
    "collapse mt-2 position-relative";

  return (
    <div className="w-100">
      <div className="card-header" id="headingOne" style={{ background: "white", border: "0", padding: "0" }}>
        <p className="hc-primary-text mb-0" style={{ position: "relative", cursor: "pointer" }} data-toggle="collapse" data-target={'#' + collapseId} aria-expanded="true" aria-controls="collapseThree">
          <i className="fas fa-chevron-down mr-1" id="collapsed-chevron"></i>
          <span className="mr-1">
            {label}
          </span>
        </p>
      </div>
      <div id={collapseId} className={collapseClassname} aria-labelledby="headingOne">
        {children}
      </div>
    </div>
  );
}

export default GroupCollapse;