import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import Spinner from "../../../../Common/Spinner";
import Notify from "../../../../Common/Notify";
import PatientInfo from "../PatientInfo";
import ChiefComplaints from "../ChiefComplaints";
import MedicalDecisionMaking from "../MedicalDecisionMaking";
import AddendumNote from "../AddendumNote";
import ProcedureNotes from "../ProcedureNotes";
import FollowUps from "../FollowUps";
import CptCode from "../CptCode";
import Orders from "../Plan";
import Assessments from "../Assessments";
import SaveChanges from "../SaveChanges";
import {
  createProgressNote,
  updateProgressNote,
  getProgressNote,
  checkUncheckPlansArray,
  getProgressNotesPlans,
} from "../../../../../actions/ProgressNotesAction";
import {
  handleMessageFormat,
  checkFormValidity,
} from "../../../../../helper/util";
import PrescriptionModal from "../../../../Conversation/Setting/PrescriptionModal";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../index.css";

const $ = window.$;

function RteNoteModal({
  isOpen = false,
  patientId = "",
  patientInfo = {},
  orgId = "",
  template = {},
  title = "",
  progressNoteId = "",
  isAddendumNote = false,
  groupMembers = [],
  tempMembers = [],
  guests = [],
  onClose = () => {},
  refreshData = () => {},
  errorNotification = () => {},
  setLocalStorageNull = () => {},
}) {
  const modalId = "#problem_progressnote_modal";
  const dispatch = useDispatch();
  const { isUpdating, plansArray } = useSelector(mySelector, shallowEqual);
  const [temp, setTemplate] = useState({
    ...template,
    rte: progressNoteId
      ? EditorState.createWithContent(convertFromRaw(template.rte))
      : EditorState.createEmpty(),
  });
  const [patient, setPatient] = useState(patientInfo);
  const [showSaveChanges, setShowSaveChanges] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const [rxModalShown, setRxModalShown] = useState(false);
  const {
    chiefComplaints = [],
    rte,
    dateSeen = "",
    procedureNotes = "",
    encounterType = "",
    medicalDecisionMaking = "",
    addendumNote = "",
    followUps = {},
    cptCode = "",
    assesments = [],
    planIds = [],
    labs = {
      prescription: "",
      diagnosticStudies: "",
      referrals: "",
      medicalSupplies: "",
    },
    favorites = [],
  } = temp;

  function handleSetDataChanged(data) {
    const newData = {
      ...data,
      rte: data.rte ? convertToRaw(data.rte.getCurrentContent()) : null,
    };
    localStorage.setItem(`${patientId}_progressnote`, JSON.stringify(newData));
    setDataChanged(true);
  }

  function onEditorStateChange(editorState) {
    const data = {
      ...temp,
      rte: editorState,
    };
    setTemplate(data);
    handleSetDataChanged(data);
  }

  function handleUpdatePatientInfo(name, value) {
    const data = {
      ...patient,
      [name]: value,
    };
    setPatient(data);
    handleSetDataChanged(data);
  }

  function handleUpdateField(e) {
    e.preventDefault();
    const { name, value } = e.target;
    const data = {
      ...temp,
      [name]: value,
    };
    setTemplate(data);
    handleSetDataChanged(data);
  }

  function handleUpdateChiefComplaints(data) {
    const { chiefComplaints = [] } = temp;
    const newData = {
      ...temp,
      chiefComplaints: [...chiefComplaints, data],
    };
    setTemplate(newData);
    handleSetDataChanged(newData);
  }

  function handleRemoveChiefComplaint(data) {
    const { chiefComplaints = [] } = temp;
    const filtered = chiefComplaints.filter((item) => item !== data);
    const newData = {
      ...temp,
      chiefComplaints: filtered,
    };
    setTemplate(newData);
    handleSetDataChanged(newData);
  }

  function handleUpdateCptComp(name, value, subObptions) {
    if (name === "encounterType") {
      const data = {
        ...temp,
        [name]: value,
        cptCode: subObptions[0].options[0],
      };
      setTemplate(data);
      handleSetDataChanged(data);
    } else {
      const data = {
        ...temp,
        [name]: value,
      };
      setTemplate(data);
      handleSetDataChanged(data);
    }
  }

  function handleUpdateFollowUps(name, value) {
    const {
      followUps: { duration = [], in: fIn },
    } = temp;
    if (name !== "in" && name !== "duration") {
      const data = {
        ...temp,
        followUps: {
          ...temp.followUps,
          [name]: value,
        },
      };
      setTemplate(data);
      handleSetDataChanged(data);
    }

    if (name === "in") {
      const currentDate = new Date();
      const data = {
        ...temp,
        followUps: {
          ...temp.followUps,
          [name]: (parseInt(value) >= 100
            ? 99
            : parseInt(value) <= 0
            ? 0
            : value
          ).toString(),
          date:
            duration.length > 0
              ? currentDate.setDate(
                  currentDate.getDate() +
                    parseInt(value) * (duration[0].val || 0)
                )
              : currentDate,
        },
      };
      setTemplate(data);
      handleSetDataChanged(data);
    }

    if (name === "duration") {
      const currentDate = new Date();
      const data = {
        ...temp,
        followUps: {
          ...temp.followUps,
          [name]: value,
          date:
            value.length > 0
              ? currentDate.setDate(
                  currentDate.getDate() + parseInt(value[0].val) * (fIn || 0)
                )
              : currentDate,
        },
      };
      setTemplate(data);
      handleSetDataChanged(data);
    }
  }

  function handleClose() {
    localStorage.removeItem("someModalIsOpen");
    $(modalId).modal("hide");
  }

  function handleCreateProgressNote(doneObj = {}) {
    createProgressNote(
      {
        ...temp,
        ...doneObj,
        rte: temp.rte ? convertToRaw(temp.rte.getCurrentContent()) : null,
        circleId: patientId,
      },
      orgId
    )(dispatch).then(
      () => {
        Notify.success("Progress note successfully created.");
        setLocalStorageNull();
        handleClose();
        onClose();
        refreshData();
        setDataChanged(false);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  function handleUpdateProgressNote(status = {}) {
    updateProgressNote(
      {
        ...temp,
        ...status,
        rte: temp.rte ? convertToRaw(temp.rte.getCurrentContent()) : null,
      },
      progressNoteId
    )(dispatch).then(
      () => {
        Notify.success("Progress note successfully updated.");
        setLocalStorageNull();
        // handleClose();
        // onClose();
        refreshData();
        setDataChanged(false);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  function handleSaveChangesOnClose(bool, isValid) {
    if (bool) {
      if (!isValid) {
        errorNotification();
        setShowSaveChanges(false);
        return;
      }
      if (progressNoteId) {
        handleUpdateProgressNote();
      } else {
        handleCreateProgressNote();
      }
    } else {
      setLocalStorageNull();
      setDataChanged(false);
      handleClose();
      onClose();
    }
    setShowSaveChanges(false);
  }

  function handleUpdateAssessment(value, index) {
    let newAssessment = assesments;
    newAssessment[index] = value;
    const data = {
      ...temp,
      assesments: newAssessment,
    };
    setTemplate(data);
    handleSetDataChanged(data);
  }

  function handleUpdateAssessmentObject(value, name, index) {
    let newAssessment = assesments;
    newAssessment[index] = {
      ...newAssessment[index],
      [name]: value,
    };
    const data = {
      ...temp,
      assesments: newAssessment,
    };

    setTemplate(data);
    handleSetDataChanged(data);
  }

  function handleRemoveAssessment(i) {
    let newAssessments = assesments;
    newAssessments.splice(i, 1);
    const data = {
      ...temp,
      assesments: newAssessments,
    };

    setTemplate(data);
    handleSetDataChanged(data);
  }

  function handleAddNewAssessment() {
    const data = {
      ...temp,
      assesments: [...temp.assesments, {}],
    };
    setTemplate(data);
    handleSetDataChanged(data);
  }

  function handleSetAssessments(assesments = []) {
    const data = {
      ...temp,
      assesments: [...assesments],
    };
    setTemplate(data);
    // handleSetDataChanged(data);
  }

  function handleUpdateMedicalDecisionMaking(e) {
    e.preventDefault();
    const { value } = e.target;
    const data = {
      ...temp,
      medicalDecisionMaking: value,
    };
    setTemplate(data);
    handleSetDataChanged(data);
  }

  function handleClearField(name) {
    const data = {
      ...temp,
      [name]: "",
    };
    setTemplate(data);
    handleSetDataChanged(data);
  }

  function handleUpdatePlans(name, value) {
    const data = {
      ...temp,
      labs: {
        ...temp.labs,
        [name]: value,
      },
    };
    setTemplate(data);
    handleSetDataChanged(data);
  }

  function handleUpdatePlansCheckbox(data, checked) {
    const { diagnosticStudies } = temp.labs;
    checkUncheckPlansArray(data.objectId, checked, plansArray)(dispatch);
    const newData = {
      ...temp,
      labs: {
        ...temp.labs,
        diagnosticStudies: checked
          ? addStr(`${diagnosticStudies}% ${data.name}`)
          : removeStr(diagnosticStudies, data.name),
      },
      planIds: filterPlanIds(planIds, data.objectId, checked),
    };
    setTemplate(newData);
    handleSetDataChanged(newData);

    function filterPlanIds(ids, id, checked) {
      const pIds = ids;

      if (checked) {
        pIds.push(id);
      } else if (!checked) {
        return pIds.filter((i) => i !== id);
      }
      return pIds;
    }

    function addStr(str) {
      return str
        .split("%")
        .filter(Boolean)
        .join(";");
    }

    function removeStr(ds, str) {
      const strData = ds.replace(str, "");
      return strData
        .split(";")
        .filter((entry) => {
          return entry.trim() !== "";
        })
        .join(";");
    }
  }

  function handleImportedPrescription(meds = []) {
    const data = {
      ...temp,
      labs: {
        ...temp.labs,
        prescription: `${temp.labs.prescription} ${handleMessageFormat(meds)}`,
      },
    };
    setTemplate(data);
    setRxModalShown(false);
    handleSetDataChanged(data);
  }

  useEffect(() => {
    componentDidMount();
    const hasSavedLocal = JSON.parse(
      localStorage.getItem(`${patientId}_progressnote`) || null
    )
      ? true
      : false;
    if (progressNoteId && !temp.objectId) {
      getProgressNote(progressNoteId)(dispatch).then(
        (res) => {
          setTemplate({
            ...res,
            rte: res.rte
              ? EditorState.createWithContent(convertFromRaw(res.rte))
              : EditorState.createEmpty(),
          });
          getProgressNotesPlans(res.planIds)(dispatch);
        },
        (err) => {
          console.log(err);
        }
      );
    } else if (hasSavedLocal) {
      setTemplate({
        ...template,
        rte: template.rte
          ? EditorState.createWithContent(convertFromRaw(template.rte))
          : EditorState.createEmpty(),
      });
      getProgressNotesPlans(planIds)(dispatch);
    } else {
      getProgressNotesPlans(planIds)(dispatch);
    }
  }, [isOpen, progressNoteId]);

  function componentDidMount() {
    if (isOpen) {
      localStorage.setItem("someModalIsOpen", "true");
      $(modalId).modal({
        backdrop: "static",
        keyboard: false,
      });
      $(modalId).modal("show");
    } else {
      $(modalId).modal("hide");
    }
  }

  return (
    <form id="problem-template-form" className="needs-validation" novalidate>
      <div
        id="problem_progressnote_modal"
        className="modal fade"
        tabIndex="-1"
        role="dialog"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ maxWidth: "1200px" }}
        >
          <div className="modal-content modal-full-height">
            <div className="modal-header">
              <h5
                className="modal-title hc-primary-text"
                style={{ fontSize: "1.25rem" }}
              >
                <i className="fas fa-fw fa-file-contract"></i>
                {title}
              </h5>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={() => {
                  if (dataChanged) {
                    setShowSaveChanges(true);
                  } else {
                    handleClose();
                    onClose();
                  }
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ overflowY: "auto" }}>
              <div className="pn-container">
                <div className="pn-header">
                  <span>Vital Health Medical Group</span>
                  <span className="hc-primary-text">
                    6245 2nd flr De Longpre Ave, Hollywood, CA 90028
                  </span>
                  <span className="hc-primary-text">
                    Tel no. (323)315-0911 Fax (323)
                  </span>
                </div>
                <PatientInfo
                  patientInfo={patient}
                  dateSeen={dateSeen}
                  isDisabled={isAddendumNote}
                  updateAnyInPatient={(e) => handleUpdateField(e)}
                  updatePatientInfo={(name, value) =>
                    handleUpdatePatientInfo(name, value)
                  }
                />
                <ChiefComplaints
                  chiefComplaints={chiefComplaints}
                  isDisabled={isAddendumNote}
                  updateChiefComplaints={(data) =>
                    handleUpdateChiefComplaints(data)
                  }
                  removeChiefComplaint={(data) =>
                    handleRemoveChiefComplaint(data)
                  }
                />
                <Assessments
                  title="PROBLEM LIST"
                  subject_placeholder="Problem"
                  body_placeholder="Progress / status"
                  assesments={assesments}
                  isDisabled={isAddendumNote}
                  updateAssessment={handleUpdateAssessment}
                  updateAssessmentObject={handleUpdateAssessmentObject}
                  removeAssessment={handleRemoveAssessment}
                  addNewAssessment={handleAddNewAssessment}
                  setAssessments={handleSetAssessments}
                />
                <Orders
                  prescriptions={patientInfo.medications || []}
                  planIds={planIds}
                  favorites={favorites}
                  plansArray={plansArray}
                  labs={labs}
                  isDisabled={isAddendumNote}
                  openPrescription={() => setRxModalShown(true)}
                  updatePlans={handleUpdatePlans}
                  updatePlansCheckbox={handleUpdatePlansCheckbox}
                  thread={{ ...patientInfo, threadId: patientInfo.objectId }}
                  guests={guests}
                  options={groupMembers}
                  isPatient={true}
                  patientName={patientInfo.name}
                  patientDob={patientInfo.dob}
                />
                <div className="card custom-card cc-id">
                  <h5 className="card-header ">CLINICAL NOTES</h5>
                  <div className="card-body cust-card-body">
                    <Editor
                      // toolbarHidden
                      editorState={rte}
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      toolbarClassName="toolbar-class"
                      onEditorStateChange={onEditorStateChange}
                      toolbar={{
                        options: ["list"],
                        list: {
                          inDropdown: false,
                          className: undefined,
                          component: undefined,
                          dropdownClassName: undefined,
                          options: ["unordered", "ordered"],
                        },
                      }}
                      readOnly={isAddendumNote}
                    />
                  </div>
                </div>
                <MedicalDecisionMaking
                  medicalDecisionMaking={medicalDecisionMaking}
                  updateMedicalDecisionMaking={
                    handleUpdateMedicalDecisionMaking
                  }
                  clearField={handleClearField}
                  isDisabled={isAddendumNote}
                />
                {isAddendumNote && (
                  <AddendumNote
                    addendumNote={addendumNote}
                    clearField={handleClearField}
                    updateAddendumNote={(e) => handleUpdateField(e)}
                  />
                )}
                <ProcedureNotes
                  procedureNotes={procedureNotes}
                  isDisabled={isAddendumNote}
                  updateProcedureNotes={(e) => handleUpdateField(e)}
                />
                <hr />
                <div className="two-columns">
                  <FollowUps
                    followUps={followUps}
                    isDisabled={isAddendumNote}
                    updateFollowUps={(name, value) =>
                      handleUpdateFollowUps(name, value)
                    }
                  />
                  <CptCode
                    encounterType={encounterType}
                    cptCode={cptCode}
                    isDisabled={isAddendumNote}
                    updateCpt={(name, value, options) =>
                      handleUpdateCptComp(name, value, options)
                    }
                  />
                </div>
                <div style={{ height: "1px" }} />
              </div>
            </div>
            <div className="modal-footer">
              <div className="form-group">
                {temp.status !== "DONE" && (
                  <button
                    type="button"
                    className="btn btn-hubchart btn-hubchart-success"
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      const status = { status: "DONE" };
                      const isValid = checkFormValidity(
                        "problem-template-form"
                      );
                      if (!isValid) {
                        errorNotification();
                        return;
                      }

                      if (progressNoteId) {
                        handleUpdateProgressNote(status);
                      } else {
                        handleCreateProgressNote(status);
                      }
                    }}
                  >
                    Sign as Complete
                  </button>
                )}
                <button
                  type="button"
                  style={{ marginRight: "10px" }}
                  className="btn btn-hubchart btn-hubchart-primary"
                  onClick={() => {
                    const isValid = checkFormValidity("problem-template-form");
                    if (!isValid) {
                      errorNotification();
                      return;
                    }

                    if (progressNoteId) {
                      handleUpdateProgressNote();
                    } else {
                      handleCreateProgressNote();
                    }
                  }}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-hubchart btn-hubchart-close"
                  onClick={() => {
                    if (dataChanged) {
                      setShowSaveChanges(true);
                    } else {
                      setLocalStorageNull();
                      handleClose();
                      onClose();
                    }
                  }}
                >
                  Close
                </button>
              </div>
            </div>
            {isUpdating && (
              <div className="tab-loading">
                <Spinner size={5} color={"#fbba09"} loading={true} />
              </div>
            )}
            {showSaveChanges && (
              <SaveChanges
                onClose={(bool) => {
                  const isValid = checkFormValidity("problem-template-form");
                  handleSaveChangesOnClose(bool, isValid);
                }}
                title="Confirm Navigation"
                message="Some changes were made to this progress note. Do you want to save before closing?"
              />
            )}
          </div>
        </div>
        {rxModalShown && (
          <PrescriptionModal
            threadId={patientId}
            isOpen={rxModalShown}
            onClose={() => setRxModalShown(false)}
            refreshData={() => {}}
            importPrescription={(data) => handleImportedPrescription(data)}
            fromProgressNotes={true}
          />
        )}
      </div>
    </form>
  );
}

const mySelector = (state) => ({
  isUpdating: state.notes.isUpdating,
  plansArray: state.notes.plans,
});

export default RteNoteModal;
