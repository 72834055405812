import React, { Component, Fragment } from "react";
import { parseAction } from "../../../actions/REST";
import "../../Pages/Login/Login.css";
import Loader from "../../Common/Loader";
import PatientForm from "./PatientForm";
import {
  updatePatientInfo,
  createFacility,
  createServiceLocation,
  createInsurance
} from "../../../actions/GroupAction";
import { connect } from "react-redux";
import Notify from "../../Common/Notify";
import config from "../../../config";
import PatientApi from "../../../api/Patient";

const $ = window.$;

export class Facesheet extends Component {
  constructor() {
    super();
    this.state = {
      editMode: false,
      isLoading: false,
      patientInfo: {},
      isFacesheetEdited: false
    };

    this.fetchPatientInfo = this.fetchPatientInfo.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleStandByPrompt = this.handleStandByPrompt.bind(this);
  }

  handleStandByPrompt(isEdited) {
    if (isEdited) {
      window.onbeforeunload = event => {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = "";
        }
        return "";
      };
    } else {
      window.onbeforeunload = () => {};
    }
  }

  componentDidMount() {
    const fsState = JSON.parse(localStorage.getItem("fsState") || "{}");
    if (fsState.editMode) {
      this.setState(
        { editMode: fsState.editMode, isFacesheetEdited: true },
        () => {
          this.handleStandByPrompt(true);
          setTimeout(() => {
            if (fsState.inputId) {
              document.getElementById(fsState.inputId).focus();
            }
            localStorage.removeItem("fsState");
          });
        }
      );
    }
    this.fetchPatientInfo(this.props.patientId);
  }

  confirm() {
    this.props.confirmAction();
  }

  fetchPatientInfo(threadId) {
    this.setState({ isLoading: true });

    parseAction("get", config.BASE_URL + "/parse/classes/Circle", {
      where: {
        objectId: threadId
      },
      include: [
        "facility",
        "serviceLocation",
        "primaryInsurance",
        "primaryInsurance.provider",
        "secondaryInsurance",
        "secondaryInsurance.provider",
        "otherInsurance",
        "otherInsurance.provider"
      ]
    })
      .then(result => {
        // Check if result is empty
        if (result.results.length === 0) {
          this.setState({
            isFetching: false
          });
          return;
        }

        var group = result.results[0];

        this.setState({
          patientInfo: group,
          isLoading: false
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({ isLoading: false });
      });
  }

  print() {
    window.print();
  }

  toggleEdit() {
    this.setState(
      {
        editMode: !this.state.editMode,
        isFacesheetEdited: !this.state.editMode ? true : false
      },
      () => {
        const { isFacesheetEdited } = this.state;
        if (isFacesheetEdited) {
          this.handleStandByPrompt(true);
        }
      }
    );
  }

  async handleSave(
    circle,
    primaryInsurance,
    secondaryInsurance,
    otherInsurance,
    serviceLocation
  ) {
    this.setState({ isLoading: true });

    try {
      const group = await PatientApi.savePatient(
        circle,
        primaryInsurance,
        secondaryInsurance,
        otherInsurance,
        serviceLocation
      );

      this.setState({
        patientInfo: group.toJSON(),
        isLoading: false,
        editMode: false,
        isFacesheetEdited: false
      });

      this.handleStandByPrompt(false);

      Notify.success("Face sheet saved!");
    } catch (err) {
      this.setState({ isLoading: false });
      Notify.error("Could not save face sheet.");
      console.error(err);
    }

    /* this.props.updatePatientInfo(patientInfo, this.state.patientInfo.objectId)
            .then(group => {

                if (data.serviceLocation) {

                    let willCreateServiceLocation = true;

                    if (group.serviceLocation) {
                        if (data.serviceLocation.name.toLowerCase() === group.serviceLocation.nameLower) {
                            willCreateServiceLocation = false;
                        }
                    }

                    if (willCreateServiceLocation) this.createServiceLocation(group, data.serviceLocation.name)
                }

                group.members = this.state.patientInfo.members;
                group.orgAdmins = this.state.patientInfo.orgAdmins;
                group.orgMembers = this.state.patientInfo.orgMembers;


                this.setState({
                    patientInfo: group,
                    isLoading: false,
                    editMode: false
                });

                Notify.success('Face sheet saved!');
            }).catch(error => {
                this.setState({
                    isLoading: false
                });
                Notify.error('Could not save face sheet.');
                console.error(error);
            }); */
  }

  createFacility(group, facilityName) {
    this.props
      .createFacility(facilityName, group)
      .then(res => {
        this.setState({
          patientInfo: res
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  createInsurance(group, insuranceName, columnName) {
    return this.props
      .createInsurance(insuranceName, group, columnName)
      .then(res => {
        this.setState({
          patientInfo: res
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  createServiceLocation(group, serviceLocationName) {
    this.props
      .createServiceLocation(serviceLocationName, group)
      .then(res => {
        this.setState({
          patientInfo: res
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  setLoading(boolean) {
    this.setState({ isLoading: boolean });
  }

  render() {
    const { isLoading, patientInfo, editMode } = this.state;

    return (
      <div style={{ overflowY: "auto", background: "#e5e5e5" }}>
        <div
          className="container patient-form-container"
          style={{ background: "#FFFFFF" }}
        >
          <PatientForm
            setLoading={this.setLoading.bind(this)}
            patient={patientInfo}
            editMode={editMode}
            onEdit={e => this.toggleEdit(e)}
            onPrint={e => this.print(e)}
            onSave={this.handleSave}
          />
          <Loader isLoading={isLoading} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeThread: state.thread.activeThread
});

export default connect(mapStateToProps, {
  updatePatientInfo,
  createFacility,
  createServiceLocation,
  createInsurance
})(Facesheet);
