import React, { useEffect, useState } from "react";

import "./index.css";

function SubjectiveComponent({ subjective = "" }) {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="card custom-card">
      <h5 className="card-header ">
        <strong>SUBJECTIVE</strong>
      </h5>
      <div className="card-body">
        <div className="custom-form-group-container pe-text">
          {subjective || ""}
        </div>
      </div>
    </div>
  );
}

export default SubjectiveComponent;
