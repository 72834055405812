import React, { useCallback, useEffect, useState } from "react";
import PubnubApi from "../../../api/Pubnub";
import Pubnub from "pubnub";
import util from "../../../helper/util";

const usePubnub = () => {
  const [pubnub, setPubnub] = useState(null);
  const [handlers, setHandlers] = useState({});
  const [channels, setChannels] = useState([]);

  // This function accepts event handlers from the outside
  // and store them to state. Ex: onUserJoined
  const on = (eventName, handler) => {
    setHandlers(handlers => ({
      ...handlers,
      [eventName]: handler
    }));
  };

  const addRoomChannel = useCallback(
    roomId => {
      const channel = "conference_" + roomId;

      setChannels(channels => [...channels, channel]);
    },
    [pubnub]
  );

  const addUserChannel = useCallback(
    localUser => {
      const channel = "priv_" + localUser.id;

      setChannels(channels => [...channels, channel]);
    },
    [pubnub]
  );

  const addGroupChannel = useCallback(
    threadId => {
      const channel = "group_" + threadId;

      setChannels(channels => [...channels, channel]);
    },
    [pubnub]
  );

  const hereNow = useCallback(
    threadId => {
      const channel = "group_" + threadId;

      return pubnub.hereNow(
        {
          channels: [channel]
        },
        function(status, response) {
          return {
            status,
            response
          };
        }
      );
    },
    [pubnub]
  );

  const init = async () => {
    const result = await PubnubApi.getPubNubKeys();

    const { publishKey, subscribeKey } = result;

    // We can now create a pubnub instance
    const pubnub = createPubnub(publishKey, subscribeKey);

    setPubnub(pubnub);
  };

  useEffect(() => {
    if (!pubnub) return;

    // We should register the event listeners passed to us
    const message = data => {
      const { message } = data;

      const handler = handlers[message.type];

      if (handler) return handler(message);
    };

    pubnub.addListener({
      message
    });
  }, [pubnub, handlers]);

  useEffect(() => {
    if (!pubnub || !channels.length) return;

    console.log("Subscribing to:");
    console.log(channels);

    pubnub.subscribe({
      channels
    });
  }, [pubnub, channels]);

  return {
    on,
    addRoomChannel,
    addUserChannel,
    addGroupChannel,
    hereNow,
    init
  };
};

const createPubnub = (publishKey, subscribeKey) => {
  return new Pubnub({
    publish_key: publishKey,
    subscribe_key: subscribeKey,
    ssl: window.location.protocol.toLowerCase() === "https:",
    origin: "pubsub.pubnub.com",
    uuid: util.uuid(),
    presenceTimeout: 120,
    heartbeatInterval: 30
  });
};

export default usePubnub;
