const initialState = {

    // The current room
    room: null,
    host: null,
    localUser: null,
}

export default function(state = initialState, action) {

	switch(action.type) {
        case 'SET_ROOM':
            return {
                ...state,
                room: action.room
            }
        break;

        case 'SET_HOST':
            return {
                ...state,
                host: action.host
            }
        break;

        case 'SET_LOCAL_USER':
            return {
                ...state,
                localUser: action.localUser
            }
        break;

		default:
		return state;
	}
}
