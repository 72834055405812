import React from 'react';
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';

import './index.css';

function RteComponent({
  rte = {},
}) {

  return (
    <div className="card custom-card cc-id">
      <h5 className="card-header ">
        <strong>CLINICAL NOTES</strong>
      </h5>
      <div className="card-body cust-card-body-2">
        {parse(draftToHtml(rte))}
      </div>
    </div>
  );
}

export default RteComponent;
