import { parseAction } from './REST';
import config from '../config'

export const searchContactByEmail = (text) => dispatch => {

    const params = {
        searchText: text,
    };

    return parseAction("post", config.BASE_URL + '/parse/functions/searchContactByEmail', params)
    .then(res => {
        return res.result.users;
    });
}

export const retrieveGuests = (ownerId) => dispatch => {

    const params = {
        ownerId
    };

    return parseAction("post", config.BASE_URL + '/parse/functions/getOrgGuests', params)
    .then(res => {
        return res.result.guests;
    });
}
