import {
    SET_CONFERENCES_BY_ID,
    SET_LOADING_CONFERENCES_BY_ID
} from '../actions/types';

const initialState = {

    // Current active conference call
    activeConference: {},

    // List of conference call history
    conferences: [],
    patient_conferences: [],
    initial_conference: [],

    // Whether list of conference call history is being fetched
    isLoadingConferences: false,
    isLoadingPatientConferences: false,

    // Whether current active conference call is being fetched
    isLoadingActiveCall: false,

    // The active conference sub tab
    // By default we show conference logs
    activeConferenceTab: 'SCHEDULES',

    // List of scheduled conference calls
    schedules: [],
    google_calendar_schedules: [],
    telemed_schedules: [],
    selected_schedules: [],

    // Whether list of scheduled conference calls is being fetched
    isLoadingSchedules: false,
    isGoogleSignedIn: false,
    isGoogleInitialized: false,
    conferenceScheduleCount: 0,

    // Whether starting a new conference
    isStartingConference: false,
}

export default function(state = initialState, action) {
	switch(action.type) {
        
        case 'SET_CONFERENCE':
            return {
                ...state,
                activeConference: action.conference
            }
        break;

        case 'REMOVE_CONFERENCE':
            return {
                ...state,
                activeConference: {}
            }
        break;

        case 'SET_CONFERENCES':
            return {
                ...state,
                conferences: action.conferences
            }
        break;

        case SET_CONFERENCES_BY_ID:
            return {
                ...state,
                patient_conferences: action.conferences,
                initial_conference: action.initial_conference,
            }
        break;

        case 'APPEND_CONFERENCES':
            return {
                ...state,
                conferences: [
                    ...state.conferences,
                    ...action.conferences
                ]
            }
        break;

        case 'SET_LOADING_CONFERENCES':
            return {
                ...state,
                isLoadingConferences: action.value
            }
        break;

        case SET_LOADING_CONFERENCES_BY_ID:
            return {
                ...state,
                isLoadingPatientConferences: action.value
            }
        break;

        case 'SET_LOADING_ACTIVE_CALL':
            return {
                ...state,
                isLoadingActiveCall: action.value
            }
        break;

        case 'UPDATE_CONFERENCE_SUCCESS':

            const {data, id} = action;

            const conferences = state.conferences.map(c => {
                if (c.objectId === id) {
                    return {
                        ...c,
                        ...data
                    };
                }

                return c;
            });
            
            const patient_conferences = state.patient_conferences.map(c => {
                if (c.objectId === id) {
                    return {
                        ...c,
                        ...data
                    };
                }

                return c;
            });
            const initial_conference = patient_conferences.length > 0 ? [patient_conferences[0]] : [];

            return {
                ...state,
                conferences,
                patient_conferences,
                initial_conference,
            }
        break;

        case 'SET_CONFERENCE_TAB':
			return {
				...state,
				activeConferenceTab: action.value
			}
        break;
        
        case 'SET_LOADING_SCHEDULES':
            return {
                ...state,
                isLoadingSchedules: action.value
            }
        break;

        case 'SET_SCHEDULES':
            return {
                ...state,
                schedules: action.conferences
            }
        break;

        case 'SET_GOOGLE_SCHEDULES':
            return {
                ...state,
                google_calendar_schedules: action.conferences
            }
        break;

        case 'SET_TELEMED_SCHEDULES':
            return {
                ...state,
                telemed_schedules: action.telemed_conferences,
            }
        break;

        case 'SET_SELECTED_SCHEDULES':
            return {
                ...state,
                selected_schedules: action.selected_conferences,
            }
        break;

        case 'UPDATE_SCHEDULE_SUCCESS':

            const _id = action.id;
            const _data = action.data;

            const _conferences = state.schedules.map(c => {
                if (c.objectId === _id) {
                    return {
                        ...c,
                        ..._data
                    };
                }

                return c;
            });
            
            return {
                ...state,
                schedules: _conferences
            }
        break;

        case 'ADD_SCHEDULE':
            return {
                ...state,
                schedules: [
                    action.conference,
                    ...state.schedules
                ]
            };
        break;
        
        case 'SET_GOOGLE_SIGNED_IN':
            return {
                ...state,
                isGoogleSignedIn: action.value
            }
        break;

        case 'SET_GOOGLE_INITIALIZED':
            return {
                ...state,
                isGoogleInitialized: action.value
            }
        break;
        
        case 'SET_GOOGLE_SCHEDULE_COUNT':
            return {
                ...state,
                conferenceScheduleCount: action.value
            }
        break;

        case 'SET_STARTING_CONFERENCE':
            return {
                ...state,
                isStartingConference: action.value
            }
        break;

		default:
		return state;
	}
}
