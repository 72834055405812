import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Modal from '../../Common/Modal';
import { filterByTag } from '../../../actions/ThreadAction';

const $ = window.$;

const TagsFilterModal = ({ isOpen, onClose }) => {

  const dispatch = useDispatch();

  const { circleLabels } = useSelector(mySelector, shallowEqual);

  const handleSelectTag = (circleLabelId) => {
    debugger
    dispatch(filterByTag(circleLabelId));
    onClose();
  };

  return (
    <Modal shown={isOpen} onHide={onClose}>
      <div className="modal-header">
        <h4 className="modal-title text-primary text-truncate">
          <i className="fad fa-hashtag mr-1"></i>
          Select tag to filter
        </h4>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div className="modal-body" style={{ height: '100%' }}>
        <div className="row">
          {circleLabels.map(label =>
            <div className="col-md-6 mb-4">
              <Label label={label} onSelectTag={handleSelectTag} />
            </div>
          )}
        </div>
      </div>

      <div className="modal-footer py-0" align="right">
        <button onClick={e => onClose()} type="button" className="btn btn-hubchart btn-hubchart-close my-3">
          Close
        </button>
      </div>
    </Modal>
  );
}

const Label = ({ label, onSelectTag, }) => {
  return (
    <button onClick={e => onSelectTag(label.objectId)} className="btn btn-primary p-2 w-100">
      <div className="w-100 text-truncate text-left">
        <i className="fas fa-hashtag mr-1"></i>
        {label.name}
      </div>
    </button>
  )
}

const mySelector = state => ({
  circleLabels: state.dashboard.circleLabels,
});

export default TagsFilterModal;
