import React, { Component } from 'react'

export class CreateCorporate extends Component {
    constructor() {
        super();
        this.state = {
            savingCorporateAccount : false,
            errorCreating : false,
            errorMessage : ""
        }

        this.createCorporate = this.createCorporate.bind(this);
    }

    createCorporate(e) {
        e.preventDefault();

        let corporateName = this.refs.corporateName.value;
        if (corporateName === "") {
            this.setState({
                errorCreating : true,
                errorMessage : "You need to provide a name to create an organization."
            })
            return;
        }

        this.setState({
            savingCorporateAccount: true,
            errorCreating : false
        })

        this.props.createOrganization(corporateName).catch((error) => {
            this.setState({
                savingCorporateAccount: false,
                errorCreating : true,
                errorMessage: "Please check you internet connection or try again later."
            })
        });
        // code for creating corporate account

    }

    render() {
        return (
            <>
                <div className="Form-Container">
                    <h5 className="hc-primary-text">Start a corporate account now</h5>
                    <form onSubmit={this.createCorporate} style={{position: "relative"}}>
                        <div className="form-group">
                            <span style={{fontSize: "13px", color:"#00000096", fontWeight: "500"}}>Organization Name</span>
                            <div className="card">
                                <input ref="corporateName" type="text" id="corporateName" placeholder="Enter you organization name" disabled={this.state.savingCorporateAccount}/>
                                <i className="fas fa-user hc-primary-text"></i>
                            </div>
                            {this.state.errorCreating && <span className="hc-red-text" style={{fontSize: "13px", fontWeight: "500"}}>{this.state.errorMessage}</span>}
                        </div>

                        {this.state.savingCorporateAccount ?
                            <div className="form-group" style={{textAlign: "right", position: "relative"}}>
                                <span style={{color: "rgb(74, 173, 82)"}}><i className="fas fa-circle-notch fa-spin" style={{marginRight: "5px"}}></i>Creating...</span>
                            </div>
                            :
                            <div className="form-group" style={{marginBottom: "30px", textAlign: "right"}}>
                                <button ref="submit" type="submit" className="Confirm-Button">CREATE</button>
                            </div>
                        }
                    </form>
                </div>
            </>
        )
    }
}

export default CreateCorporate
