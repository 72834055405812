import React from 'react';

const Audio = ({ url, type }) => {
  return (
    <div className='d-flex flex-column justify-content-center h-100'>
      <audio controls style={{ display: "block", marginBottom: "10px", marginTop: "10px" }}>
        <source src={url} type={type} />
        Your browser does not support the audio element.
      </audio>
    </div>
  )
}

export default Audio;
