import React, { Component } from "react";
import MessageApi from "../../../api/Message";
import Spinner from "../../Common/Spinner";
import "./FilesWindow.css";
import "./Sidebar.css";
import FilePreview2 from "../../FilePreview2";
import Loader from "../../Common/Loader";
import util from "../../../helper/util";
import pdfLogo from "../../../assets/images/pdf.png";
import { Document, Page } from "react-pdf";
import _ from "lodash";
import Sidebar from "./Sidebar";
import Draggable from "./Draggable";
import { ToastContainer } from "react-toastify";
import { fetchDocuments, saveDocument } from "../../../actions/DocumentAction";
import { connect } from "react-redux";
import FilePreview from "../../FilePreview";
import ReactTooltip from "react-tooltip";
import ImageLoad from "../../Common/ImageLoad";
import ImagePlaceholder from "../../Common/ImagePlaceholder";

class FilesWindow extends Component {
  constructor(props) {
    super(props);

    const { threadId } = this.props;

    this.state = {
      threadId,
      messages: [],
      hasScrolled: false,
      monthYears: [],
      grouped: {},

      // Category filtering
      categoryFilter: "",
      category: {
        label: "All files",
        dropzoneRef: "",
      },
      documents: [],
      documentsFiles: [],
      currentDocumentsIndex: 1,
      documentPreviewShown: false,

      // Pagination
      page: 0,
      size: 20,
      total: 0,
      hasMore: true,

      // Modal
      modalShown: false,
      currentIndex: 1,
      files: [],

      // Loading
      isLoading: true,
      loadingMessage: "Loading files. Please wait...",
      isLoadingInitial: true,
      sidebarShown: false,
    };

    this.headerStyles = {
      boxShadow: "grey 0px 0px 8px -2px",
      position: "relative",
      width: "100%",
      background: "white",
      zIndex: "1",
      padding: "10px",
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.getFiles = this.getFiles.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.openModal = this.openModal.bind(this);

    // constants
    this.links = this.getLinks();
  }

  componentDidMount() {
    const { threadId, page, size } = this.state;

    this.getFiles(threadId, { page, size })
      .then(() => {
        this.setState({ isLoadingInitial: false });
      })
      .catch(() => {
        this.setState({ isLoadingInitial: false });
      });

    // Handle scrolling
    const scrollableContent = document.getElementById("main-row-wrapper");

    scrollableContent.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    const scrollableContent = document.getElementById("main-row-wrapper");

    scrollableContent.removeEventListener("scroll", this.handleScroll);
  }

  async getFiles(threadId, options = {}) {
    this.setState({ isLoading: true });

    try {
      const result = await MessageApi.getFilesv2(threadId, {
        page: options.page,
        size: options.size,
      });

      const withoutNull = result.files.filter((f) => !!f);

      const newMessages = withoutNull.map((message) => {
        const fileType = util.detectType(message.fileType);
        const monthYear = util.getMonthYear(message.createdAt);

        return {
          ...message,
          fileType,
          monthYear,
        };
      });

      const grouped = _.groupBy(
        [...this.state.messages, ...newMessages],
        "monthYear"
      );

      const newFiles = newMessages.map((message) => {
        const fileType = util.detectType(message.fileType);

        return {
          ...message.file,
          fileType,
          messageObjectId: message.objectId,
          thumbUrl: message.thumbnail ? message.thumbnail.url : "",
        };
      });

      this.setState({
        isLoading: false,
        hasMore: result.hasMore,
        page: result.page,
        size: result.pageSize,

        messages: [...this.state.messages, ...newMessages],
        files: [...this.state.files, ...newFiles],
        grouped,
      });
    } catch (err) {
      console.error(err);

      this.setState({ isLoading: false });

      alert("Could not fetch files");
    }
  }

  getFileType(message) {
    if (message.video) {
      return "video";
    }
    // It might be a PDF, because...
    // becase why not? Hahaha
    else if (message.picture) {
      const { url } = message.picture;

      const fileExt = util.getFileExtension(url);

      const isPDF = fileExt === "pdf";

      return isPDF ? "pdf" : "image";
    } else {
      return "image";
    }
  }

  loadMore() {
    const { threadId, page, size } = this.state;

    this.getFiles(threadId, { page: page + 1, size }).then(() => {
      this.setState({ hasScrolled: false });
    });
  }

  handleScroll(event) {
    if (this.state.categoryFilter) return;

    let initialHeight = event.target.scrollHeight;
    let height = event.target.offsetHeight;
    let scrollTop = event.target.scrollTop;

    // Consider scrolled to bottom even if 10 pixels before
    const offset = 10;

    if (scrollTop + height >= initialHeight - offset) {
      const { isLoading, hasScrolled, hasMore } = this.state;

      if (!isLoading && hasScrolled === false && hasMore) {
        console.log("handleScroll");

        setTimeout(() => {
          this.setState({ hasScrolled: true });
          this.loadMore();
        }, 0);
      }
    }
  }

  handleModalClose() {
    this.setState({ modalShown: false });
  }

  openModal(objectId) {
    const { files = [] } = this.state;

    const index = files.findIndex((f) => f.messageObjectId === objectId);

    this.setState({
      currentIndex: index + 1,
      modalShown: true,
    });
  }

  setLoading(boolean, message) {
    this.setState({
      isLoading: boolean,
      loadingMessage: message,
    });
  }

  closeDocument() {
    this.setState({ sidebarShown: false });
  }

  openDocument() {
    this.setState({ sidebarShown: true });
  }

  clodeDocument() {}

  toggleSidebar() {
    const { sidebarShown } = this.state;

    this.setState({ sidebarShown: !sidebarShown });
  }

  handleDrag() {
    this.openDocument();
  }

  handleFilter(filter) {
    const { threadId } = this.state;

    const category = this.links.find((link) => {
      return link.dropzoneRef === filter;
    });

    this.setState({
      category,
      categoryFilter: filter,
    });

    if (filter === "") return;

    this.fetchDocuments(threadId, filter);
  }

  fetchDocuments(threadId, filter) {
    this.setLoading(true, "Fetching documents");

    this.props
      .fetchDocuments(threadId, filter, true)
      .then((documents) => {
        this.setLoading(false);

        const documentsFiles = this.formatFiles(documents);

        this.setState({
          documents,
          documentsFiles,
        });
      })
      .catch((err) => {
        this.setLoading(false);
        console.error(err);
        alert("Failed to fetch");
      });
  }

  formatFiles(documents = []) {
    return documents.map((item, i) => {
      if (item.photo) {
        if (item.photo.url) {
          let fileExt = util.getFileExtension(item.photo.name);
          if (fileExt === "pdf") {
            return {
              objectId: item.objectId,
              url: item.photo.url,
              isPDF: true,
              user: item.user,
              originalSender: item.originalSender,
              date: item.createdAt,
              originalDateSent: item.originalDateSent,
            };
          }
          return {
            objectId: item.objectId,
            url: item.photo.url,
            isIMG: true,
            user: item.user,
            originalSender: item.originalSender,
            date: item.createdAt,
            originalDateSent: item.originalDateSent,
          };
        }
      }
      return null;
    });
  }

  getLinks() {
    const links = [
      {
        dropzoneRef: "encounter_notes",
        label: "MD Notes",
        imageSrc: require("../../../assets/images/notepad.png"),
      },
      {
        dropzoneRef: "meds_rx",
        label: "Meds/Rx",
        imageSrc: require("../../../assets/images/medicine.png"),
      },
      {
        dropzoneRef: "authorization",
        label: "Auths",
        imageSrc: require("../../../assets/images/copyright.png"),
      },
      {
        dropzoneRef: "labs",
        label: "Labs",
        imageSrc: require("../../../assets/images/laboratory.png"),
      },
      {
        dropzoneRef: "radiology",
        label: "Xrays/scans",
        imageSrc: require("../../../assets/images/x-ray.png"),
      },
      // { dropzoneRef: 'pathology', label: 'Pathology', imageSrc: require('../../../assets/images/bacteria.png') },
      // { dropzoneRef: 'consult_notes', label: 'MD Specialist Notes', imageSrc: require('../../../assets/images/notepad.png') },
      {
        dropzoneRef: "hospital_records",
        label: "Hospital files",
        imageSrc: require("../../../assets/images/medical-history.png"),
      },
      {
        dropzoneRef: "photos",
        label: "Photos",
        imageSrc: require("../../../assets/images/photos.png"),
      },
      {
        dropzoneRef: "admin_docs",
        label: "Admin/Legal",
        imageSrc: require("../../../assets/images/case.png"),
      },
      {
        dropzoneRef: "other_files",
        label: "Other",
        imageSrc: require("../../../assets/images/papers.png"),
      },
    ];

    return links;
  }

  handleOpenDocument(objectId) {
    const { documentsFiles = [] } = this.state;

    const index = documentsFiles.findIndex((f) => f.objectId === objectId);

    this.setState({
      currentDocumentsIndex: index + 1,
      documentPreviewShown: true,
    });
  }

  closeDocumentPreview() {
    this.setState({
      documentPreviewShown: false,
    });
  }

  handleSelectDocument(link) {
    const { messages, currentIndex } = this.state;

    const document = messages[currentIndex - 1];

    const supportedFileTypes = ["image", "pdf"];

    const { fileType } = document;

    if (document && supportedFileTypes.includes(fileType)) {
      this.categorizeDocument(document, link.dropzoneRef);
    }
  }

  categorizeDocument(document, category) {
    this.props.saveDocument(document, category);
  }

  render() {
    const {
      messages,
      isLoading,
      modalShown,
      currentIndex,
      files,
      loadingMessage,
      isLoadingInitial,
      grouped,
      sidebarShown,
      categoryFilter,
      category,
      documents,
      documentsFiles,
      currentDocumentsIndex,
      documentPreviewShown,
    } = this.state;

    const { imageUrl, threadName } = this.props;

    const imageSrc =
      imageUrl || require("../../../assets/images/group-default.png");

    return (
      <div className="d-flex" id="wrapper">
        <div id="page-content-wrapper">
          <div className="Conversation-Header" style={this.headerStyles}>
            <div className="patient-doc-details">
              <img
                src={imageSrc}
                alt=""
                className="header-img rounded-circle"
              />
              <p className="Thread-Name">{threadName}</p>
            </div>
            {/* <button onClick={() => this.toggleSidebar()}>Toggle</button> */}
          </div>

          <div style={{ margin: "15px 90px 15px 15px" }}>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0">
                <li
                  className={
                    categoryFilter === ""
                      ? "breadcrumb-item active"
                      : "breadcrumb-item"
                  }
                >
                  <a onClick={() => this.handleFilter("")} href="#!">
                    All Files
                  </a>
                </li>
                {categoryFilter != "" ? (
                  <li className="breadcrumb-item active" aria-current="page">
                    {category.label}
                  </li>
                ) : (
                  ""
                )}
              </ol>
            </nav>
          </div>

          <div
            id="main-row-wrapper"
            className="main-row-wrapper"
            style={{ marginRight: "65px" }}
          >
            {categoryFilter != "" ? (
              !isLoading ? (
                documents.length ? (
                  <div className="document-item-container">
                    <Documents
                      items={documents}
                      onSelect={(objectId) => this.handleOpenDocument(objectId)}
                    />
                  </div>
                ) : (
                  <div align="center" className="text-empty">
                    No records
                  </div>
                )
              ) : (
                ""
              )
            ) : (
              <Groups
                items={grouped}
                onSelect={(objectId) => this.openModal(objectId)}
                onDrag={() => this.handleDrag()}
              />
            )}
            {!isLoading && this.state.hasMore ? (
              <div className="d-flex justify-content-center mb-3">
                <button
                  className="btn btn-primary"
                  onClick={(e) => this.loadMore()}
                >
                  <i className="fas fa-chevron-double-down mr-1"></i>
                  Load more
                </button>
              </div>
            ) : (
              ""
            )}
          </div>

          {isLoading ? (
            <div style={{ height: "70px" }} align="center">
              <Spinner top="50" />
            </div>
          ) : (
            ""
          )}

          {!isLoading && !messages.length ? <span>No files</span> : ""}

          <FilePreview2
            onSelect={(link) => this.handleSelectDocument(link)}
            links={this.links}
            documentName="Document name"
            images={files}
            currentIndex={currentIndex}
            isOpen={modalShown}
            modalClose={this.handleModalClose}
          />
          {documentPreviewShown ? (
            <FilePreview
              documentName={"Document"}
              images={documentsFiles}
              currentIndex={currentDocumentsIndex}
              isOpen={documentPreviewShown}
              modalClose={() => this.closeDocumentPreview()}
            />
          ) : (
            ""
          )}
          <Loader isLoading={isLoadingInitial} message={loadingMessage} />
          <ToastContainer />
        </div>

        <div
          className="bg-light border-right"
          id="sidebar-wrapper"
          style={{
            height: "100%",
            position: "absolute",
            right: "0",
            zIndex: "1",
          }}
        >
          <Sidebar
            links={this.links}
            filter={categoryFilter}
            onFilter={(filter) => this.handleFilter(filter)}
            onDocumentClose={() => this.closeDocument()}
            onDocumentOpen={() => this.openDocument()}
            activeThread={this.props.activeThread}
            documentIsOpen={sidebarShown}
            closeDocument={this.clodeDocument}
          />
        </div>
      </div>
    );
  }
}

function Groups(props) {
  const { items = {}, isDragging = false } = props;
  const allKeys = Object.keys(items);

  return allKeys.map((monthYear, index) => {
    const messages = items[monthYear];

    return (
      <div key={index}>
        <div style={{ marginLeft: "15px" }}>
          <h3>{monthYear}</h3>
        </div>
        <div className="document-item-container">
          <Messages
            key={monthYear}
            items={messages}
            onSelect={(objectId) => props.onSelect(objectId)}
            onDrag={props.onDrag}
          />
        </div>
      </div>
    );
  });
}

function Messages(props) {
  const { items } = props;

  function getFileExtention(filename) {
    return filename.substr((~-filename.lastIndexOf(".") >>> 0) + 2);
  }

  function getPdfThumbnail(thumbnail) {
    if (!thumbnail) return "";

    const url = thumbnail.url;

    const ext = getFileExtention(url).toLowerCase();

    if (ext === "pdf") return "";

    return url;
  }

  return items.map((message, index) => {
    const { file, createdAt } = message;

    const formattedDate = util.fromThemDate(createdAt);

    const isPDF = message.fileType === "pdf";

    const thePreviewContent = <Thumbnail file={message} />;

    const thePreviewContent2 = <Thumbnail file={message} />;

    return (
      <div key={index} className="document-item">
        <Draggable data={message} willDrag={props.onDrag}>
          <div
            className="img-thumbnail"
            data-tip
            data-for={"tooltip_" + message.objectId}
          >
            <div className="thumbnail-wrapper-files" align="center">
              {thePreviewContent}

              <a className="overlay">
                <div
                  className="overlay-item"
                  onClick={(e) => props.onSelect(message.objectId)}
                >
                  <i className="fa fa-eye"></i>
                  <span>View</span>
                </div>
                {isPDF && (
                  <a className="overlay-item" href={file.url} target="_blank">
                    <i className="fa fa-file-pdf"></i>
                    <span>Open</span>
                  </a>
                )}
              </a>
            </div>
          </div>
        </Draggable>
        <p className="file-thumbnail-caption">
          <span
            style={{ fontSize: "12px", fontWeight: "bold" }}
            className="hc-primary-text"
          >
            {formattedDate}
          </span>
        </p>
        <ReactTooltip
          id={"tooltip_" + message.objectId}
          type="dark"
          effect="solid"
          place="right"
          data-offset="left"
          // data-iscapture='true'
          data-scroll-hide="false"
        >
          <div
            style={{
              height: "auto",
              maxHeight: "600px",
              maxWidth: "400px",
              width: "auto",
            }}
          >
            {thePreviewContent2}
          </div>
        </ReactTooltip>
      </div>
    );
  });
}

function Documents(props) {
  const { items = [] } = props;

  const documents = items.map((item, i) => {
    if (item.photo) {
      if (item.photo.url) {
        let fileExt = util.getFileExtension(item.photo.name);
        const isPDF = fileExt === "pdf";
        const imgUrl = item.photo.url;
        const formattedDate = util.fromThemDate(item.createdAt);

        return (
          <div className="document-item">
            <div className="img-thumbnail">
              <div className="thumbnail-wrapper-files" align="center">
                {isPDF ? (
                  <Document
                    file={imgUrl}
                    loading={
                      <div>
                        <i className="fas fa-circle-notch fa-spin"></i> Loading
                        PDF
                      </div>
                    }
                  >
                    <Page
                      pageNumber={1}
                      className="react-pdf__Page-canvas-container"
                    />
                  </Document>
                ) : (
                  <img
                    src={imgUrl}
                    className="img-thumbnail-files"
                    alt="Image"
                  />
                )}

                <a
                  className="overlay"
                  onClick={() => props.onSelect(item.objectId)}
                >
                  <span style={{ color: "white", fontWeight: "bold" }}>
                    <i className="fa fa-eye mr-2"></i>
                    View
                  </span>
                </a>
              </div>
            </div>
            <p className="file-thumbnail-caption">
              <span
                style={{ fontSize: "12px", fontWeight: "bold" }}
                className="hc-primary-text"
              >
                {formattedDate}
              </span>
            </p>
          </div>
        );
      }
    }
    return null;
  });

  return documents;
}

function FileThumbnail(props) {
  return (
    <div>
      <div className="img-thumbnail">
        <div className="thumbnail-wrapper-files" align="center">
          {props.children}
          <a className="overlay">
            <span style={{ color: "white", fontWeight: "bold" }}>
              <i className="fa fa-eye mr-2"></i>
              View
            </span>
          </a>
        </div>
      </div>
      <p className="file-thumbnail-caption">
        <span
          style={{ fontSize: "12px", fontWeight: "bold" }}
          className="hc-primary-text"
        >
          Caption
        </span>
      </p>
    </div>
  );
}

function PdfThumb({ thumbUrl }) {
  return thumbUrl ? (
    <ImageLoad openLightbox={() => {}} imageURL={thumbUrl} />
  ) : (
    <ImagePlaceholder type="pdf" />
  );
}

function Thumbnail({ file = {} }) {
  /*  
    For displaying thumbnails, we have 2 cases only
    1.) Thumbnail is present: Display the thumbnail
    2.) No thumbnail: Display appropriate icon

    Exception: Images may not have thumbnail, but we can display them
  */
  const thumbnail =
    file.fileType === "image" ? file.thumbnail || file.file : file.thumbnail;

  return (
    <div className="file-thumbnail">
      {thumbnail ? (
        <ThumbnailImage src={thumbnail.url} />
      ) : (
        <ImagePlaceholder type={file.fileType} />
      )}
    </div>
  );
}

function ThumbnailImage({ src = "" }) {
  return <img src={src} alt="image" className="img-centered" />;
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  fetchDocuments,
  saveDocument,
})(FilesWindow);
