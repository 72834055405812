import { parseAction } from '../actions/REST';
import config from '../config'

function initiateCall(data = {}) {

    const method = 'post';
    
    const url = config.BASE_URL + '/parse/functions/initiate_call';
    
    const payload = {
        call_uuid: data.call_uuid,
        callee_id: data.callee_id,
        has_video: data.has_video,
    };

    const headers = {
        'Content-Type': 'application/json'
    };

    return parseAction(method, url, payload, headers)
        .then(response => {
            return response.result;
        })
        .catch(error => {
            // TODO handle error when fetching search contact
            console.log(error);

            return Promise.reject(error);
        });
}

function cancelCall(roomId) {

    const method = 'post';
    
    const url = config.BASE_URL + '/parse/functions/call_cancelled';
    
    const payload = {
        room_id: roomId
    };

    const headers = {
        'Content-Type': 'application/json'
    };

    return parseAction(method, url, payload, headers)
        .then(response => {
            return response.result;
        })
        .catch(error => {
            // TODO handle error when fetching search contact
            console.log(error);

            return Promise.reject(error);
        });
}

function answerCall(roomId) {

    const method = 'post';
    
    const url = config.BASE_URL + '/parse/functions/call_answered';
    
    const payload = {
        room_id: roomId
    };

    const headers = {
        'Content-Type': 'application/json'
    };

    return parseAction(method, url, payload, headers)
        .then(response => {
            return response.result;
        })
        .catch(error => {
            // TODO handle error when fetching search contact
            console.log(error);

            return Promise.reject(error);
        });
}

function endCall(roomId) {

    const method = 'post';
    
    const url = config.BASE_URL + '/parse/functions/call_ended';
    
    const payload = {
        room_id: roomId
    };

    const headers = {
        'Content-Type': 'application/json'
    };

    return parseAction(method, url, payload, headers)
        .then(response => {
            return response.result;
        })
        .catch(error => {
            // TODO handle error when fetching search contact
            console.log(error);

            return Promise.reject(error);
        });
}

export default {
    initiateCall,
    cancelCall,
    answerCall,
    endCall,
}
