import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { NavLink } from "react-router-dom";
import util from "../../../helper/util";
import moment from "moment";
import OverlayLoader from "../../Common/OverlayLoader";
import OverlayPlaceholder from "../../Common/OverlayPlaceholder";
import { SET_PROGRESS_NOTES_RECENT } from "../../../actions/types";
import RecentImage from "../../Common/RecentImage";
import { linkToRecent } from "../../../actions/ThreadAction";
import { getAllProgressNotes } from "../../../actions/ProgressNotesAction";

import "./index.scss";

const ProgressNotesCard = ({
  recents = {},
  // isLoading = false,
  handleOpenProgressNotes = {},
  handleScroll,
//   handleProgressNotesFilter,
  hasMorePN
}) => {
  const styles = {
    maxHeight: "calc(100vh - 100px)",
    borderRadius: "15px",
    overflow: "hidden",
  };

  const dispatch = useDispatch();

  const {
    allProgressNotes,
    allProgressNotesPending,
    allProgressNotesDone,
    allProgressNotesRecent,

    pageProgressNotesAll,
    pageProgressNotesPending,
    pageProgressNotesDone,
    pageProgressNotesRecent,

    hasMoreProgressNotesAll,
    hasMoreProgressNotesPending,
    hasMoreProgressNotesDone,
    hasMoreProgressNotesRecent,
  } = useSelector(mySelector, shallowEqual);

  const [currentTab, setCurrentTab] = useState("all");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(getAllProgressNotes())
    .then(() => {
      setLoading(false);
    })
  }, []);

  const handleProgressNotesFilter = (status) => {
    // setCurrentProgressNoteTab(status);

    if (status == "PENDING" && allProgressNotesPending) {
      dispatch({
        type: SET_PROGRESS_NOTES_RECENT,
        data: [...allProgressNotesPending] || [],
        page: pageProgressNotesPending,
        hasMore: hasMoreProgressNotesPending,
      });
    } else if (status == "DONE" && allProgressNotesDone) {
      dispatch({
        type: SET_PROGRESS_NOTES_RECENT,
        data: [...allProgressNotesDone] || [],
        page: pageProgressNotesDone,
        hasMore: hasMoreProgressNotesDone,
      });
    } else if (status == "all") {
      dispatch({
        type: SET_PROGRESS_NOTES_RECENT,
        data: [...allProgressNotes] || [],
        page: pageProgressNotesAll,
        hasMore: hasMoreProgressNotesAll,
      });
    } else {
      dispatch(getAllProgressNotes(0, [], 10, status));
    } 
  };

  const handleItemClick = (recent) => {
    dispatch(linkToRecent(recent));
  };

  const filterProgressNotes = (status) => {
    setCurrentTab(status);
    handleProgressNotesFilter(status);
    var container = document.getElementById('ProgressNotes-list');
    container.scrollTop = 0;
  };

  const hasMorePN_icon = {
    position: "relative",
    display: "flex",
    flexWrap: "nowrap !important",
    flexDirection: "row !important",
  };

  return (
    <div
        // className="Tab-Container"
        style={{
        minHeight: "calc(100vh - 200px)",
        display: "flex",
        flexWrap: "wrap",
        width: '50%',
        marginLeft: 'auto',
        marginRight: 'auto',  
        marginTop: '0px !important',
        // padding: '0 10%'
        }}
    >
    <div className="dashboard-box" style={{ padding: 0 }}>
      <div style={{ padding: "10px 10px 0px 10px", fontSize: 14 }}>
        <h6 className="sidebar-heading d-flex justify-content-between align-items-center ">
          <strong>
            <span>Progess Notes</span>
          </strong>
          <button
            type="button"
            className="btn btn-sm btn-primary"
            style={{ borderRadius: 10 }}
            onClick={() => handleOpenProgressNotes()}
            title="Create Notes & Forms"
          >
            <i class="fa-fw fad fa-file-signature"></i>
          </button>
        </h6>
      </div>
      <ul className="nav nav-bac">
        <li className="nav-item">
          <a
            onClick={() => {if (currentTab != "all") {
              filterProgressNotes("all");}}}
            className={
              currentTab === "all" ? "hc-nav-link-active" : "hc-nav-link"
            }
            href="#!"
          >
            All
          </a>
        </li>
        <li className="nav-item">
          <a
            onClick={() => {if (currentTab != "PENDING") {
              filterProgressNotes("PENDING");}}}
            className={
              currentTab === "PENDING" ? "hc-nav-link-active" : "hc-nav-link"
            }
            href="#!"
          >
            Draft
          </a>
        </li>
        <li className="nav-item">
          <a
            onClick={() => {if (currentTab != "DONE") {
              filterProgressNotes("DONE");}}}
            className={
              currentTab === "DONE" ? "hc-nav-link-active" : "hc-nav-link"
            }
            href="#!"
          >
            Signed
          </a>
        </li>
      </ul>
      <div
        className="h-100 w-100 position-relative overflow-auto"
        style={{ backgroundColor: "#FFFFFF", borderRadius: 8 }}
        // onScroll={handleScroll}
        id="ProgressNotes-list"
      >
        <div className="list-group modified-collection list-group-flush">
          {allProgressNotes.map((r, index) => (
            <Fragment key={r.circle.objectId + index}>
              <div style={{ padding: 10 }}>
                <NavLink
                  to={"/progressnotes/" + r.circle.objectId}
                  target="_blank"
                  className="py-2 border-0 shadow-none mb-0 mx-0 list-group-item-action flex-column align-items-start"
                >
                  <div className="d-flex align-items-center">
                    <div className="w-100 text-truncate">
                      <div className="d-flex w-100 justify-content-between">
                        <span
                          className="mb-0"
                          style={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            fontSize: 14,
                          }}
                        >
                          {r.circle.name}
                        </span>
                        <span style={{ fontSize: 14 }}>
                          <small
                            className={
                              r.status == "DONE"
                                ? "badge badge-success"
                                : "badge badge-warning"
                            }
                            style={{
                              lineHeight: "0px !important",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {r.status == "DONE" ? "SIGNED" : "DRAFT"}
                          </small>
                        </span>
                      </div>
                      <span style={{ fontSize: 14 }}>
                        <LabelNotes notes={r.chiefComplaints.join(", ")} />
                      </span>
                      <br></br>
                      <small style={{ fontWeight: "bold" }}>
                        {moment(new Date(r.createdAt)).format("L")}
                      </small>
                    </div>
                  </div>
                </NavLink>
              </div>
              <div style={{ border: "1px solid #e5e5e5", margin: 10 }} />
            </Fragment>
          ))}
          <div style={hasMorePN_icon}
            className={hasMorePN ? "px-2 py-2 mx-0" : "d-none"}>
            <OverlayLoader isLoading={isLoading} />
          </div>
        </div>
        <OverlayPlaceholder
          // show={true}
          show={!isLoading && !recents.length}
          text="No patients"
        />
        {isLoading && <OverlayLoader isLoading={recents.length === 0} />}
      </div>
    </div>
    </div>
  );
};

const LabelNotes = ({ notes = "" }) => {
  return <small className="text-grey">{notes}</small>;
};

const mySelector = (state) => ({
    allProgressNotes: state.notes.allProgressNotes,
    allProgressNotesPending: state.notes.allProgressNotesPending,
    allProgressNotesDone: state.notes.allProgressNotesDone,
    allProgressNotesRecent: state.notes.allProgressNotesRecent,
    pageProgressNotesAll: state.notes.pageProgressNotesAll,
    pageProgressNotesPending: state.notes.pageProgressNotesPending,
    pageProgressNotesDone: state.notes.pageProgressNotesDone,
    pageProgressNotesRecent: state.notes.pageProgressNotesRecent,
    hasMoreProgressNotesRecent: state.notes.hasMoreProgressNotesRecent,
    hasMoreProgressNotesAll: state.notes.hasMoreProgressNotesAll,
    hasMoreProgressNotesPending: state.notes.hasMoreProgressNotesPending,
    hasMoreProgressNotesDone: state.notes.hasMoreProgressNotesDone,
    // isLoadingPN: state.notes.isLoading,
});

export default ProgressNotesCard;
