import React from 'react';
import ContactSelect from './ContactSelect';
import Modal from '../../../Common/Modal';

const $ = window.$;

function AddMemberModal({ isOpen, onClose, onSelect }) {

  const handleSelect = user => {
    onSelect(user);
  };

  return (<>
    <Modal shown={isOpen} onHide={onClose}>
      <div className="modal-header">
        <h5 className="modal-title hc-primary-text d-flex align-items-center">
          <span className="font-weight-bold">
            Add corporate member
          </span>
        </h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body py-0" style={{ height: '100%' }}>
        <ContactSelect onSelect={user => handleSelect(user)} />
      </div>
      <div className="modal-footer py-0" align="right">
        <button onClick={e => onClose()} type="button" className="btn btn-hubchart btn-hubchart-close my-3">
          Close
        </button>
      </div>
    </Modal>
  </>);
}

export default AddMemberModal;
