import React from "react";
import { computeAge } from "../../../../helper/util";
import "../../../Conversation/Setting/SettingModal.css";

function PatientInfo({ patientInfo = {} }) {
  return (
    <div className="details-container">
      <div style={{ margin: "15px 0px" }}>
        <div className="patient-details">
          <span>
            <i className="fas fa-fw fa-hospital-user"></i> Patient Name:{" "}
          </span>
          <span>{patientInfo.name}</span>
        </div>
        <div className="patient-details">
          <span>
            <i className="fas fa-fw fa-address-card"></i> Address:{" "}
          </span>
          <span>{patientInfo.address}</span>
        </div>
        <div className="patient-details">
          <span>
            {" "}
            <i className="fas fa-fw fa-calendar-day"></i> Birth date:{" "}
          </span>
          <span>
            {patientInfo.dob
              ? `${patientInfo.dob} (${computeAge(patientInfo.dob)} yrs old)`
              : "--"}
          </span>
        </div>
        <div className="patient-details">
          <span>
            <i className="fas fa-fw fa-phone"></i> Contact No.:{" "}
          </span>
          <span>{patientInfo.phoneNumber}</span>
        </div>
        <div className="patient-details">
          <span>
            <i className="fas fa-fw fa-allergies"></i> Allergies:{" "}
          </span>
          <span>{patientInfo.allergies}</span>
        </div>
      </div>
      <div>
        {/* <div className="patient-image">
          {(typeof (patientInfo.image || {}).url === "undefined" ||
            (patientInfo.image || {}).url === "") && (
            <img src={require("../../../../assets/images/default.png")} />
          )}
          {(patientInfo.image || {}).url && (
            <img
              style={{ height: 125, borderRadius: 10 }}
              src={(patientInfo.image || {}).url}
            />
          )}
        </div> */}
      </div>
    </div>
  );
}

export default PatientInfo;
