import React, { Component } from 'react'
import Parse from 'parse'
import '../Login/Login.css'
import '../Login/Login.mobile.css'


const $ = window.$;
export class EmailContainer extends Component {
    constructor() {
        super();
        this.state = {
            emailError : false,
            savingEmailAddress: false,
            emailError : false,
            didChangeEmail : false,
            noEmail : false
        }
        this.updateEmail = this.updateEmail.bind(this);
    }


    componentWillMount() {
        if (!Parse.User.current()) {
            window.location.href = '/';
        }

        if (!Parse.User.current().get("email")) {
            this.setState({
                noEmail : true
            })
        }
    }

    updateEmail(e) {
        e.preventDefault();

        $(this.refs.submit).prop('disabled', true);
        $(this.refs.submit).html('<i class="fas fa-spinner spin-loader" style="margin-right: 10px"></i> Updating...');

        this.setState({
            savingEmailAddress: true,
            emailError : false,
            didChangeEmail : false
        })


        let email = this.refs.email.value;
        if (Parse.User.current().get("email") === email) {
            this.setState({
                savingEmailAddress: false,
                emailError : false,
            })
            $(this.refs.submit).prop('disabled', false);
        	$(this.refs.submit).text("Update");
            return;
        }

        let currentUser = Parse.User.current();

        currentUser.set("email" , email);
        currentUser.save()
        .then((user) => {
            this.setState({
                savingEmailAddress: false,
                didChangeEmail: true
            })

            $(this.refs.submit).prop('disabled', false);
        	$(this.refs.submit).text("Update");
        }, (error) => {
            if (error.code === 203) {
                this.setState({
                    emailError : true,
                    savingEmailAddress: false,
                })
            }
            $(this.refs.submit).prop('disabled', false);
            $(this.refs.submit).text("Update");
        });
    }

    render() {
        return (
            <div className="Login-Container">
                <div className="Form-Container">
                    <a href="/" style={{float: "right", fontSize: "1rem", fontWeight: "500"}}>Login</a>
                    <h5 style={{fontSize: "1rem"}}>Update my email address</h5>
                    {this.state.noEmail && <span style={{fontSize: "14px", color:"#00000096", fontWeight: "500"}}>For an added security measure, we now required an email address for each account user. Kindly update your information by typing in your email address in the box. Thank you.</span>}
                    {this.state.didChangeEmail && <span style={{color: "rgb(74, 173, 82)", fontSize: "13px"}}>Kindly verify your account by clicking the link sent to your email. You can refresh this page or login after you verify your account.</span> }
                    <form onSubmit={this.updateEmail.bind(this)} style={{marginTop: "15px"}}>
                        <div className="form-group">
                            <span style={{fontSize: "13px", color:"#00000096", fontWeight: "500"}}>Email address</span>
                                <div className="card">
                                    <input ref="email" type="email" id="email" placeholder="your@email.here" defaultValue={Parse.User.current().get("email")}  disabled={this.state.savingEmailAddress}/>
                                    <i className="fas fa-envelope hc-primary-text"></i>
                                </div>
                            {this.state.emailError && <span style={{fontSize: "13px", color: "rgb(216, 75, 85)", fontWeight: "500"}}>There is an existing account associated with {this.refs.email.value}.</span>}
                        </div>
                        <div className="form-group" style={{marginBottom: "30px"}}>
                            <button ref="submit" type="submit" className="btn btn-hubchart" style={{width: "100%", fontSize: "18px"}}>Update</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default EmailContainer
