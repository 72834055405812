import { parseAction } from './REST';
import config from '../config'

export const addGuestsToCircle = (circleObjectId = '', userObjectIds = []) => dispatch => {

    const params = {
        circleObjectId,
        userObjectIds,
    };

    return parseAction(
        "post",
        config.BASE_URL + '/parse/functions/addGuests',
        params,
        { "Content-Type": 'application/json' }
    );
}

