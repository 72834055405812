import {
  FETCH_GROUPS,
  GET_GROUPS,
  FILTER_GROUPS,
  FILTER_PATIENTS,
  REMOVE_GROUP,
  LOAD_MORE_GROUPS,
  SEARCHING_STATUS,
  NEW_GROUP,
  LABEL_RESULT,
  LABEL_STATUS,
  LABEL_ITEM_STATUS,
  SET_SIDE_NAV_TAB,
} from "../actions/types";
import sort from "fast-sort";

const initialState = {
  groups: [],
  facilities: [],
  facilitiesFetchStatus: "",
  facilitiesInitialLoad: false,
  insurance: [],
  insuranceFetchStatus: "",
  insuranceInitialLoad: false,
  patients: [],
  filterPatientResult: [],
  groupFetchStatus: "",
  patientFetchStatus: "",
  groupInitialLoad: false,
  patientInitialLoad: false,
  groupPage: 0,
  patientPage: 0,
  isSearchingGroup: false,
  isSearchingPatient: false,
  pagination: {
    page: 0,
    pageSize: 10,
    hasMore: false,
  },
  isLoadingPatients: false,
  insuranceProviders: [],
  isLoadingMembers: false,

  /* 
        Group search
    */
  filterGroupResult: [],
  isLoadingSearch: false,
  isLoadingMoreSearch: false,
  searchPagination: {
    page: 0,
    pageSize: 10,
    hasMore: true,
  },
  activeSideNavTab: "EMR",
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "SET_LOADING_MEMBERS":
      return {
        ...state,
        isLoadingMembers: action.isLoading,
      };
      break;

    case "SAVE_INSURANCE_PROVIDERS":
      return {
        ...state,
        insuranceProviders: action.items,
      };
      break;

    case "SET_LOADING_PATIENTS":
      return {
        ...state,
        isLoadingPatients: action.value,
      };
      break;

    case FETCH_GROUPS:
      if (action.status === "FAILED") {
        if (action.groupType === "regular") {
          return {
            ...state,
            groupFetchStatus: action.status,
          };
        } else {
          return {
            ...state,
            patientFetchStatus: action.status,
          };
        }
      }
      if (action.groupType === "regular") {
        return {
          ...state,
          groups: action.payload,
          groupFetchStatus: action.status,
          groupInitialLoad: true,
          groupPage: state.groupPage + 1,
        };
      } else {
        return {
          ...state,
          patients: action.payload,
          patientFetchStatus: action.status,
          patientInitialLoad: true,
          patientPage: state.patientPage + 1,
        };
      }

      break;

    case GET_GROUPS:
      return {
        ...state,
        groups: state.groups,
      };
      break;

    case "START_LOADING_GROUP_SEARCH":
      const { initialLoad } = action;

      return initialLoad
        ? {
            ...state,
            isLoadingSearch: true,
          }
        : {
            ...state,
            isLoadingMoreSearch: true,
          };
      break;

    /**
     * Filter group
     * @param {string} action.text
     */
    case FILTER_GROUPS:
      if (action.text === "") {
        return {
          ...state,
          filterGroupResult: [],
          isSearchingGroup: false,
        };
      }

      /* 
                Replace results if first page
                Append otherwise
            */
      const searchResults = action.page > 0 ? [...state.filterGroupResult, ...action.results] : [...action.results];

      return {
        ...state,
        filterGroupResult: searchResults,
        isSearchingGroup: true,
        searchPagination: {
          page: action.page,
          pageSize: action.pageSize,
          hasMore: action.hasMore,
        },
        isLoadingSearch: false,
        isLoadingMoreSearch: false,
      };
      break;

    case FILTER_PATIENTS:
      if (action.text === "") {
        return {
          ...state,
          filterPatientResult: [],
          isSearchingPatient: false,
        };
      }

      const filterPatientResult = action.loadMore
        ? [...state.filterPatientResult, ...action.results]
        : [...action.results];

      return {
        ...state,
        filterPatientResult,
        isSearchingPatient: true,
        pagination: action.pagination,
      };
      break;

    case REMOVE_GROUP:
      return {
        ...state,
        groups: [
          ...state.groups.filter((group) => {
            return group.objectId !== action.objectId;
          }),
        ],
        patients: [
          ...state.patients.filter((patient) => {
            return patient.objectId !== action.objectId;
          }),
        ],
      };
      break;

    case LOAD_MORE_GROUPS:
      if (action.groupType === "regular") {
        let tempGroup = [...state.groups, ...action.payload];
        return {
          ...state,
          groups: tempGroup,
          groupPage: state.groupPage + 1,
        };
      } else {
        let tempPatient = [...state.patients, ...action.payload];
        return {
          ...state,
          patients: tempPatient,
          patientPage: state.patientPage + 1,
        };
      }
      break;

    case SEARCHING_STATUS:
      return {
        ...state,
        filterGroupResult: [],
        filterPatientResult: [],
        isSearchingGroup: action.isSearching,
        isSearchingPatient: action.isSearching,
      };
      break;

    case NEW_GROUP:
      if (action.groupType === "regular") {
        return {
          ...state,
          groups: [action.group, ...state.groups],
        };
      }

      return {
        ...state,
        patients: [action.group, ...state.patients],
      };
      break;

    case LABEL_STATUS:
      switch (action.label) {
        case "Facility":
          return {
            ...state,
            facilitiesFetchStatus: action.status,
          };
          break;

        case "Insurance":
          return {
            ...state,
            insuranceFetchStatus: action.status,
          };
          break;
      }
      return state;

      break;

    case LABEL_ITEM_STATUS:
      switch (action.label) {
        case "Facility":
          return {
            ...state,
            facilities: [
              ...state.facilities.map((item) => {
                if (item.objectId === action.labelObjectId) {
                  return {
                    ...item,
                    isFetching: action.isFetching,
                    groups: action.groups,
                  };
                }
                return item;
              }),
            ],
          };
          break;

        case "Insurance":
          return {
            ...state,
            insurance: [
              ...state.insurance.map((item) => {
                if (item.objectId === action.labelObjectId) {
                  return {
                    ...item,
                    isFetching: action.isFetching,
                    groups: action.groups,
                  };
                }
                return item;
              }),
            ],
          };
          break;
      }
      return state;
      break;

    case LABEL_RESULT:
      switch (action.label) {
        case "Facility":
          return {
            ...state,
            facilities: action.items,
            facilitiesInitialLoad: true,
            facilitiesFetchStatus: "SUCCESS",
          };
          break;

        case "Insurance":
          return {
            ...state,
            insurance: [...state.insurance, ...action.items],
            insuranceInitialLoad: true,
            insuranceFetchStatus: "SUCCESS",
          };
          break;
      }

      return state;
      break;

    case SET_SIDE_NAV_TAB:
      return {
        ...state,
        activeSideNavTab: action.activeSideNavTab,
      };
      break;

    default:
      return state;
  }
}
