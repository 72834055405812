import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import { ReactSortable } from "react-sortablejs";

import './index.css';

const $ = window.$;

function AssessmentsComponent({
  title = 'ASSESSMENT AND PLAN',
  subject_placeholder = 'Diagnosis',
  body_placeholder = 'Plan',
  assesments = [
    { problem: '', assessmentPlan: ''},
    { problem: '', assessmentPlan: ''},
    { problem: '', assessmentPlan: ''},
    { problem: '', assessmentPlan: ''},
  ],
  updateAssessment = () => {},
  updateAssessmentObject = () => {},
  removeAssessment = () => {},
  addNewAssessment = () => {},
  setAssessments = () => {},
  isDisabled = false,
}) {

  function handleOnCHange (e, index) {
    e.preventDefault();
    const { value } = e.target;
    updateAssessment(value, index);
  }

  function handleOnChangeObject (e, index) {
    e.preventDefault();
    const { value, name } = e.target;
    updateAssessmentObject(value, name, index);
  }

  function handleAddNewAssessment () {
    addNewAssessment();
    setTimeout(() => {
      document.getElementById(`problem${1}`).focus();
    });
  }

  function handleListAssessments (array = []) {
    return array.map((item, i) => {
      const isString = typeof item === 'string' ? true : false;
      if (isString) {
        return (
          <div className="assessment-item" key={i}>
            <span>{`${i+1}.`}</span>
            <input 
              id="problem" 
              name="problem" 
              type="text" 
              className="form-control"
              placeholder={`${subject_placeholder} #${i+1}`}
              value={item}
              disabled={isDisabled}
              onChange={e => handleOnCHange(e, i)} 
            />
            {!isDisabled && (
              <span 
                className="badge badge-danger custom-badge-danger"
                onClick={() => removeAssessment(i)}>
                <i className="fal fa-times"></i>
              </span>
            )}
          </div>
        );
      } else {
        return (
          <div className="assessment-item" key={i}>
            <button 
              type="button" 
              className="drag-button btn btn-default"
              disabled={isDisabled}>
              <i class="fal fa-arrows-v"></i>
            </button>
            <div style={{ width: '100%' }}>
              <input 
                id={`problem${i+1}`} 
                name="problem" 
                type="text" 
                className="form-control"
                placeholder={`${subject_placeholder} #${i+1}`}
                value={item.problem}
                disabled={isDisabled}
                onChange={e => handleOnChangeObject(e, i)} />
              <TextareaAutosize 
                id="assessmentPlan" 
                name="assessmentPlan" 
                type="text" 
                className="form-control"
                placeholder={`${body_placeholder}`}
                value={item.assessmentPlan}
                disabled={isDisabled}
                onChange={e => handleOnChangeObject(e, i)}
                minRows={2} 
              />
            </div>
            {!isDisabled && (
              <span 
                className="badge badge-danger custom-badge-danger"
                onClick={() => removeAssessment(i)}>
                <i className="fal fa-times"></i>
              </span>
            )}
          </div>
        );
      }
    });
  }

  return (
    <div className="card custom-card">
      <div 
        className="card-header" 
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <h5 style={{ marginBottom: '0px' }}>{title}</h5>
        {!isDisabled && (
          <i 
            className="fas fa-plus" 
            title="Add New Assessment"
            onClick={() => handleAddNewAssessment()}></i>
        )}
      </div>
      <div className="card-body" style={{ padding: '10px' }}>
        <ReactSortable 
          className="custom-form-group-container"
          id="items" 
          tag="div" 
          list={assesments} 
          animation={150} 
          setList={setAssessments}
          disabled={isDisabled}
          handle=".drag-button">
          {handleListAssessments(assesments)}
        </ReactSortable>
      </div>
    </div>
  );
}

export default AssessmentsComponent;
