import React from 'react';
import userDefault from '../../assets/images/default.png'


const RecentImage = ({url, width = '50px', height = '50px'}) => {
  const styles = {
    width,
    height,
    overflow: 'hidden',
    borderRadius: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const imageUrl = url || userDefault;

  return (
    <div style={styles}>
      <Image url={imageUrl} width={width} height={height}/>
    </div>
  );
}

const Image = ({url, width, height}) => {

  const styles = {
    width,
    height,
  }

  return (
    <img style={styles} src={url}/>
  );
}

export default RecentImage;
