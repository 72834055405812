import React, { Component, Fragment } from "react";
import moment from "moment";
import Parse from "parse";
import debounce from "lodash.debounce";
import { parseAction } from "../../../../actions/REST";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import Loader from "../../../Common/Loader";
import PrintPrescription from "../../../Conversation/Setting/PrescriptionPDF";
import PostOnHub from "../../../Conversation/Setting/PrescriptionModal/PostOnHub";
import { connect } from "react-redux";
import { fetchphysiciansAndEditors } from "../../../../actions/SessionAction";
import ConferenceApi from "../../../../api/Conference";
import ForwardModal from "../../../../components/Conversation/Forward/ForwardModal2";
import AddPrescriptionModal from "../../../Conversation/Setting/PrescriptionModal/AddPrescriptionModal";
import { ToastContainer } from "react-toastify";
import Notify from "../../../Common/Notify";
import TabLoader from "../../../Common/TabLoader";
import config from "../../../../config";
import {
  quantifiables,
  defaultSelectedDrug,
} from "../../../Conversation/Setting/PrescriptionModal/config";
import {
  computeAge,
  handleMessageFormat,
  removeExtraLines,
} from "../../../../helper/util";
import PatientInfo from "../PatientInfo";
import Logo from "../../../../assets/images/logo-blue.png";
import { convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "../../../Pages/Login/Login.css";
import "./index.css";

const $ = window.$;

export class MedicationComponent extends Component {
  constructor() {
    super();
    this.state = {
      user: {},
      doctorInfo: {},
      currentTab: "current",
      selectedPill: "free_text",
      showPill: true,
      isLoading: false,
      isLoadingPrescription: false,
      endOfList: false,
      currentErxEntryDisabled: false,
      medicationModalShown: false,
      isPrescriptionShown: false,
      isQuantifiable: false,
      canAssignPrescriber: false,
      editorTouched: false,
      postOnHubModalShown: false,
      forwardMessageModalShown: false,
      isCurrent: false,
      isUncheckAllMeds: true,
      patientInfo: {},
      selectedDrug: {
        ...defaultSelectedDrug,
      },
      selectedDrugs: [
        { ...defaultSelectedDrug },
        { ...defaultSelectedDrug },
        { ...defaultSelectedDrug },
        { ...defaultSelectedDrug },
        { ...defaultSelectedDrug },
      ],
      selectedOption: [],
      selectedRxList: [],
      erxList: [],
      erxListRestructured: [],
      currentMedicines: [],
      checkedMedicines: [],
      deletedMedicines: [],
      messageToForward: [],
      keyWord: "",
      selectedIndex: 0,
      currentPage: 1,
      totalItems: 0,
      isCheckedAll: false,
    };

    this.selectTab = this.selectTab.bind(this);
    this.fetchPatientInfo = this.fetchPatientInfo.bind(this);
    this.fetchErxList = this.fetchErxList.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.savePrescriber = this.savePrescriber.bind(this);
    this.filterCheckedMedicines = this.filterCheckedMedicines.bind(this);
    this.handleCurrentMedsDate = this.handleCurrentMedsDate.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.removeCurrentMedicine = this.removeCurrentMedicine.bind(this);
    this.restorePrescription = this.restorePrescription.bind(this);
    this.selectErx = this.selectErx.bind(this);
    this.manualSelectRx = this.manualSelectRx.bind(this);
    this.handleFetchErxPageChange = this.handleFetchErxPageChange.bind(this);
    this.formatCircle = this.formatCircle.bind(this);
    this.reStructureData = this.reStructureData.bind(this);
    this.calculateDrugQuantity = this.calculateDrugQuantity.bind(this);
    this.handleMedicationChange = this.handleMedicationChange.bind(this);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.addMedication = this.addMedication.bind(this);
    this.filterSelectedDrugs = this.filterSelectedDrugs.bind(this);
    this.saveMedication = this.saveMedication.bind(this);
    this.saveCurrentMedication = this.saveCurrentMedication.bind(this);
    this.handleEditMedication = this.handleEditMedication.bind(this);
    this.openMedicationModal = this.openMedicationModal.bind(this);
    this.checkDuplicates = this.checkDuplicates.bind(this);
    this.clearFields = this.clearFields.bind(this);
    this.checkUncheckAllRx = this.checkUncheckAllRx.bind(this);
    this.checkUncheckRx = this.checkUncheckRx.bind(this);
    this.updateMedications = this.updateMedications.bind(this);
    this.handleChangeDrugDetails = this.handleChangeDrugDetails.bind(this);
    this.handleClosePrescriptionModal = this.handleClosePrescriptionModal.bind(
      this
    );
    this.combineStrength = this.combineStrength.bind(this);
    // this.handleFetchPhysiciansAndEditors = this.handleFetchPhysiciansAndEditors.bind(
    //   this
    // );
    this.selectPrescriber = this.selectPrescriber.bind(this);
    this.getPrescriberDetails = this.getPrescriberDetails.bind(this);
    // this.superAdminChecker = this.superAdminChecker.bind(this);
    this.handleSelectTab = this.handleSelectTab.bind(this);
    this.handlePostOnHub = this.handlePostOnHub.bind(this);
    this.handlePostOnHubOnClose = this.handlePostOnHubOnClose.bind(this);
    this.handlePostMessageOnHub = this.handlePostMessageOnHub.bind(this);
    this.handleForwardMessage = this.handleForwardMessage.bind(this);
    this.handleForwardMessageOnClose = this.handleForwardMessageOnClose.bind(
      this
    );
    this.handleChangeTextAreaDetails = this.handleChangeTextAreaDetails.bind(
      this
    );
    this.removePrescriptionPermanently = this.removePrescriptionPermanently.bind(
      this
    );
    this.addTextAreaField = this.addTextAreaField.bind(this);
    this.removeTextAreaField = this.removeTextAreaField.bind(this);
    this.closeErxModal = this.closeErxModal.bind(this);
    this.resetCheckedMedicines = this.resetCheckedMedicines.bind(this);
    this.handleCreatePrescriptionHistory = this.handleCreatePrescriptionHistory.bind(
      this
    );
  }

  componentWillMount() {
    this.setState({
      user: Parse.User.current(),
    });
  }

  componentDidMount() {
    $("#printPrescriptionModal").modal({
      backdrop: "static",
      keyboard: false,
    });
    $(".modal-dialog").draggable({
      handle: ".modal-header",
    });
    this.fetchPatientInfo(this.props.threadId);
    this.fetchErxList("");

    $("#erxMedicationModal").on(
      "hidden.bs.modal",
      function(e) {
        this.setState({ medicationModalShown: false });
        this.clearFields();
      }.bind(this)
    );
    this.handleFetchPhysiciansAndEditors();
    this.superAdminChecker();
  }

  handleFetchPhysiciansAndEditors() {
    const { objectId } = this.props.organization;
    this.props.fetchphysiciansAndEditors(objectId);
  }

  selectTab(currentTab) {
    this.setState({ currentTab, isCurrent: false });
  }

  fetchPatientInfo(threadId) {
    const { isUncheckAllMeds = false } = this.state;
    this.setState({ isLoading: true });

    parseAction("get", config.BASE_URL + "/parse/classes/Circle", {
      where: {
        objectId: threadId,
      },
    })
      .then((result) => {
        if (result.results.length === 0) {
          this.setState({
            isFetching: false,
          });
          return;
        }

        var data = {
          ...result.results[0],
          prescriber: result.results[0].prescriber
            ? result.results[0].prescriber.objectId
            : "",
        };

        this.setState(
          {
            patientInfo: data,
            currentMedicines: this.handleCurrentMedsDate(data.medications),
            checkedMedicines: this.filterCheckedMedicines(
              data.medications || []
            ),
            deletedMedicines: data.deletedMeds || [],
            isLoading: false,
          },
          () => {
            if (data.prescriber) {
              this.getPrescriberDetails(data.prescriber);
            }

            if (isUncheckAllMeds) {
              this.resetCheckedMedicines();
            }
          }
        );
      })
      .catch((error) => {
        console.error(error);
        this.setState({ isLoading: false });
      });
  }

  fetchErxList(keyWord, cPage = 1) {
    const { erxList, erxListRestructured = [] } = this.state;
    this.setState({ isLoadingPrescription: true });
    parseAction(
      "get",
      `${config.PATIENT_URL}/drugs?search=${keyWord}&page=${cPage}&size=10`
    )
      .then((result) => {
        const { items, page, total } = result.data;
        const finalList = page === 1 ? items : [...erxList, ...items];
        const isEndOfList = total === finalList.length ? true : false;
        const erxListRestructuredFinal = page === 1 ? [] : erxListRestructured;

        const restructured = this.reStructureData(
          erxListRestructuredFinal,
          items
        );

        this.setState({
          erxList: finalList,
          keyWord,
          currentPage: page,
          totalItems: total,
          endOfList: isEndOfList,
          erxListRestructured: restructured,
          isLoadingPrescription: false,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ isLoading: false, isLoadingPrescription: false });
      });
  }

  reStructureData(oldData = [], newData) {
    const drugArray = [];

    for (let item_i = 0; item_i < newData.length; item_i++) {
      for (
        let product_i = 0;
        product_i < newData[item_i].products.length;
        product_i++
      ) {
        drugArray.push({
          _id: newData[item_i]._id,
          application_number: newData[item_i].application_number,
          sponsor_name: newData[item_i].sponsor_name,
          marketing_status:
            newData[item_i].products[product_i].marketing_status,
          route: newData[item_i].products[product_i].route,
          reference_standard:
            newData[item_i].products[product_i].reference_standard,
          brand_name: newData[item_i].products[product_i].brand_name,
          reference_drug: newData[item_i].products[product_i].reference_drug,
          product_number: newData[item_i].products[product_i].product_number,
          dosage_form: newData[item_i].products[product_i].dosage_form,
          strength: this.combineStrength(
            newData[item_i].products[product_i].active_ingredients
          ),
          displayName: `${
            newData[item_i].products[product_i].brand_name
          } ${this.combineStrength(
            newData[item_i].products[product_i].active_ingredients
          )} ${newData[item_i].products[product_i].route} ${
            newData[item_i].products[product_i].dosage_form
          }`,
        });
        for (
          let ai_i = 0;
          ai_i < newData[item_i].products[product_i].active_ingredients.length;
          ai_i++
        ) {}
      }
    }

    const combindeData = [...oldData, ...drugArray];
    const finalDrugArray = Array.from(
      new Set(combindeData.map((a) => a.displayName))
    ).map((displayName) =>
      combindeData.find((a) => a.displayName === displayName)
    );

    return finalDrugArray;
  }

  combineStrength(activeIngredients) {
    if (activeIngredients.length > 3) return "";
    if (activeIngredients.length === 1) return activeIngredients[0].strength;
    let sth = "";

    const mappedIngredients = activeIngredients.map((item) => {
      let str_formatted = item.strength.includes("/")
        ? item.strength.replace(/[+-]?\d+(\.\d+)?/, "-")
        : item.strength.replace(/[0-9]+(?!.*[0-9])/gi, "-");

      const splitted = str_formatted.split("-");
      sth =
        typeof splitted === "undefined"
          ? ""
          : trim(splitted[splitted.length - 1]);

      function trim(str) {
        const removed_op = str.replace("(", "");
        const removed_cp = removed_op.replace(")", "");
        return removed_cp;
      }

      const val =
        item.strength === "N/A"
          ? ""
          : parseFloat(item.strength.match(/[-+]?([0-9]*\.[0-9]+|[0-9]+)/));

      return val;
    });

    return `${mappedIngredients.join("/")} ${sth}`;
  }

  handleFetchErxPageChange() {
    const { currentPage, keyWord } = this.state;
    this.fetchErxList(keyWord, currentPage + 1);
  }

  formatCircle() {
    const { patientInfo, selectedRxList } = this.state;
    const Circle = Parse.Object.extend("Circle");

    let newCircle = new Circle();
    newCircle.id = patientInfo.objectId;

    const newList = selectedRxList.map((item) => ({
      ...item,
      isChecked: true,
    }));

    for (let x = 0; x < newList.length; x++) {
      newCircle.addUnique("medications", newList[x]);
    }

    this.handleSave(newCircle);
  }

  updateMedications(tab) {
    const { patientInfo, currentMedicines, deletedMedicines } = this.state;
    const data = {
      objectId: patientInfo.objectId,
      medications: currentMedicines,
      deletedMeds: deletedMedicines,
    };

    const Circle = Parse.Object.extend("Circle");
    const newCircle = new Circle(data);

    this.handleSave(newCircle, tab);
  }

  async handleSave(circle, tab = "current") {
    const { patientInfo, isUncheckAllMeds = false } = this.state;
    const { refreshData, activeTab } = this.props;
    this.setState({ isLoading: true });

    try {
      const group = await circle.save();
      const json_group = group.toJSON();

      this.setState({
        currentMedicines: json_group.medications,
        checkedMedicines: this.filterCheckedMedicines(json_group.medications),
        selectedRxList: [],
        isLoading: false,
        currentTab: tab,
        patientInfo: { ...patientInfo },
      });
      if (!isUncheckAllMeds) {
        Notify.success("Prescription successfully saved.");
      } else {
        this.setState({ isUncheckAllMeds: false });
      }
      if (activeTab !== "dashboard") {
        refreshData();
      }
    } catch (err) {
      this.setState({ isLoading: false });
      // Notify.error("Could not save Prescription.");
      console.error(err);
    }
  }

  async savePrescriber(circle) {
    const { patientInfo } = this.state;
    this.setState({ isLoading: true });

    try {
      const prescriber = await circle.save();
      const pres_json = prescriber.toJSON();

      this.setState({
        patientInfo: {
          ...patientInfo,
          prescriber: pres_json.prescriber.objectId,
        },
        doctorInfo: pres_json.prescriber,
      });
      this.setState({ isLoading: false });
    } catch (err) {
      this.setState({ isLoading: false });
      console.error(err);
    }
  }

  setLoading(boolean) {
    this.setState({ isLoading: boolean });
  }

  handleCurrentMedsDate(medications = []) {
    const filteredMedications = medications.filter(Boolean);
    return filteredMedications.map((item) => ({
      ...item,
      dateCreated: (item.dateCreated || {}).iso
        ? moment((item.dateCreated || {}).iso).format("MM/DD/YYYY")
        : item.dateCreated,
    }));
  }

  filterCheckedMedicines(medicines = []) {
    const filteredMedicines = medicines.filter(Boolean);
    return filteredMedicines.filter((item) => item.isChecked);
  }

  removeItem(item) {
    const { selectedRxList } = this.state;
    selectedRxList.splice(selectedRxList.indexOf(item), 1);

    this.setState({ selectedRxList });
  }

  removeCurrentMedicine(item) {
    const { currentMedicines, deletedMedicines } = this.state;
    currentMedicines.splice(currentMedicines.indexOf(item), 1);
    deletedMedicines.push(item);

    this.setState(
      {
        currentMedicines,
        deletedMedicines,
        checkedMedicines: this.filterCheckedMedicines(currentMedicines),
      },
      () => {
        this.updateMedications();
      }
    );
  }

  restorePrescription(item) {
    const { currentMedicines, deletedMedicines } = this.state;
    deletedMedicines.splice(currentMedicines.indexOf(item), 1);
    currentMedicines.push(item);

    this.setState(
      {
        currentMedicines,
        deletedMedicines,
      },
      () => {
        this.updateMedications();
      }
    );
  }

  removePrescriptionPermanently(item) {
    const { deletedMedicines } = this.state;
    deletedMedicines.splice(deletedMedicines.indexOf(item), 1);

    this.setState(
      {
        deletedMedicines,
      },
      () => {
        const redirectTab = "deleted";
        this.updateMedications(redirectTab);
      }
    );
  }

  checkDuplicates(selected) {
    const { selectedRxList } = this.state;
    const list = selectedRxList.filter(
      (item) => item.displayName === selected.displayName
    );
    return list.length;
  }

  selectErx(selected, method) {
    const duplicates = this.checkDuplicates(selected[0]);
    if (duplicates > 0) return;
    this.setState({
      showPill: false,
      selectedPill: "labeled_entry",
      selectedDrug: {
        ...selected[0],
        ...defaultSelectedDrug,
        displayName: selected[0].displayName,
        dateCreated: moment(new Date()).format("MM/DD/YYYY"),
      },
      method,
      selectedOption: [],
      isCurrent: false,
    });
    this.openMedicationModal();
  }

  manualSelectRx(pill = "free_text") {
    this.setState({
      showPill: true,
      selectedPill: pill,
      selectedDrug: {
        ...defaultSelectedDrug,
        dateCreated: moment(new Date()).format("MM/DD/YYYY"),
      },
      method: "create",
      isCurrent: false,
    });
    this.openMedicationModal();
  }

  calculateDrugQuantity(val, name) {
    const { selectedDrug } = this.state;
    const dosageForm =
      selectedDrug.dosage_form.length > 0 ? selectedDrug.dosage_form[0] : "";
    const amount = parseInt(selectedDrug.amount || 0);
    const frequency =
      selectedDrug.frequency.length > 0 ? selectedDrug.frequency[0].val : 1;
    const duration = parseInt(selectedDrug.duration || 0);
    const durationStrInt =
      selectedDrug.durationStr.length > 0 ? selectedDrug.durationStr[0].val : 1;
    const isQuantifiable = quantifiables.includes(dosageForm);
    let quantity = isNaN(val) ? "" : val === 0 ? "" : val;

    if (isQuantifiable) {
      const duration_computed = duration * durationStrInt;
      const frequency_computed = frequency * duration_computed;
      quantity = amount * frequency_computed;
    }
    if (name !== "refill") {
      this.setState({
        selectedDrug: {
          ...selectedDrug,
          quantity: isNaN(quantity) ? "" : quantity === 0 ? "" : quantity,
        },
      });
    }
  }

  handleMedicationChange(e) {
    const { value, checked, name } = e.target;
    const { selectedDrug } = this.state;
    const newDrug = {
      ...selectedDrug,
      prn_reason: name === "prn" ? "" : selectedDrug.prn_reason,
      [name]: name === "prn" ? checked : value,
    };
    this.setState({ selectedDrug: newDrug }, () => {
      this.calculateDrugQuantity(value, name);
    });
  }

  handleQuantityChange(e) {
    const { selectedDrug } = this.state;
    const { value } = e.target;
    this.setState({
      selectedDrug: {
        ...selectedDrug,
        quantity: value,
      },
    });
  }

  addMedication(item) {
    const { selectedRxList } = this.state;
    const duplicates = this.checkDuplicates(item);
    if (duplicates > 0) return;
    this.setState({
      selectedRxList: [...selectedRxList, item],
    });
  }

  filterSelectedDrugs(selectedDrugs = []) {
    return selectedDrugs.filter((item) => item.free_text);
  }

  saveMedication() {
    const {
      selectedDrug,
      selectedDrugs,
      selectedRxList,
      method,
      selectedIndex,
    } = this.state;
    let new_rx = [];
    const filteredSelectedDrugs = this.filterSelectedDrugs(selectedDrugs);

    if (method === "create") {
      let rx =
        filteredSelectedDrugs.length === 0
          ? {
              ...selectedDrug,
              direction:
                selectedDrug.direction.length > 0
                  ? selectedDrug.direction[0]
                  : [],
              dosage_form:
                selectedDrug.dosage_form.length > 0
                  ? selectedDrug.dosage_form[0]
                  : [],
              route:
                selectedDrug.route.length > 0
                  ? selectedDrug.route[0].val || selectedDrug.route[0]
                  : [],
              route_obj:
                selectedDrug.route.length > 0 ? selectedDrug.route[0] : [],
              frequency:
                selectedDrug.frequency.length > 0
                  ? selectedDrug.frequency[0].text || selectedDrug.frequency[0]
                  : [],
              frequency_obj:
                selectedDrug.frequency.length > 0
                  ? selectedDrug.frequency[0]
                  : [],
              // location:
              //   selectedDrug.location.length > 0
              //     ? selectedDrug.location[0].val || selectedDrug.location[0]
              //     : [],
              // location_obj:
              //   selectedDrug.location.length > 0
              //     ? selectedDrug.location[0]
              //     : [],
              durationStr:
                selectedDrug.durationStr.length > 0
                  ? selectedDrug.durationStr[0].text
                  : [],
              duration_obj:
                selectedDrug.durationStr.length > 0
                  ? selectedDrug.durationStr[0]
                  : [],
            }
          : {};
      new_rx = [...selectedRxList];
      new_rx.push(...filteredSelectedDrugs);
      if (Object.keys(rx).length > 0) {
        new_rx.push(rx);
      }
    } else {
      new_rx = selectedRxList;
      if (filteredSelectedDrugs.length > 0) {
        new_rx[selectedIndex] = { ...selectedDrugs[0] };
      } else {
        new_rx[selectedIndex] = selectedDrug.textArray
          ? {
              ...selectedDrug,
            }
          : {
              ...selectedDrug,
              direction:
                selectedDrug.direction.length > 0
                  ? selectedDrug.direction[0]
                  : [],
              dosage_form:
                selectedDrug.dosage_form.length > 0
                  ? selectedDrug.dosage_form[0]
                  : [],
              route:
                selectedDrug.route.length > 0 ? selectedDrug.route[0].val : [],
              route_obj:
                selectedDrug.route.length > 0 ? selectedDrug.route[0] : [],
              // location:
              //   selectedDrug.location.length > 0
              //     ? selectedDrug.location[0].val
              //     : [],
              // location_obj:
              //   selectedDrug.location.length > 0
              //     ? selectedDrug.location[0]
              //     : [],
              frequency:
                selectedDrug.frequency.length > 0
                  ? selectedDrug.frequency[0].text
                  : [],
              frequency_obj:
                selectedDrug.frequency.length > 0
                  ? selectedDrug.frequency[0]
                  : [],
              durationStr:
                selectedDrug.durationStr.length > 0
                  ? selectedDrug.durationStr[0].text
                  : [],
              duration_obj:
                selectedDrug.durationStr.length > 0
                  ? selectedDrug.durationStr[0]
                  : [],
            };
      }
    }

    this.setState(
      {
        selectedRxList: new_rx,
        medicationModalShown: false,
        selectedDrugs: [
          { ...defaultSelectedDrug },
          { ...defaultSelectedDrug },
          { ...defaultSelectedDrug },
          { ...defaultSelectedDrug },
          { ...defaultSelectedDrug },
        ],
      },
      () => {
        this.formatCircle();
      }
    );
    this.closeErxModal();
  }

  saveCurrentMedication() {
    const {
      selectedDrug,
      selectedDrugs,
      currentMedicines,
      selectedIndex,
    } = this.state;
    let new_rx = [];
    new_rx = currentMedicines;
    const filteredSelectedDrugs = this.filterSelectedDrugs(selectedDrugs);

    if (filteredSelectedDrugs.length > 0) {
      new_rx[selectedIndex] = { ...selectedDrugs[0] };
    } else {
      new_rx[selectedIndex] = selectedDrug.textArray
        ? {
            ...selectedDrug,
          }
        : {
            ...selectedDrug,
            direction:
              selectedDrug.direction.length > 0
                ? selectedDrug.direction[0]
                : [],
            dosage_form:
              selectedDrug.dosage_form.length > 0
                ? selectedDrug.dosage_form[0]
                : [],
            route:
              selectedDrug.route.length > 0
                ? typeof selectedDrug.route[0] === "string"
                  ? selectedDrug.route[0]
                  : selectedDrug.route[0].val
                : [],
            route_obj:
              selectedDrug.route.length > 0
                ? typeof selectedDrug.route[0] === "string"
                  ? { val: selectedDrug.route[0], text: selectedDrug.route[0] }
                  : selectedDrug.route[0]
                : [],
            location:
              selectedDrug.location.length > 0
                ? typeof selectedDrug.location[0] === "string"
                  ? selectedDrug.location[0]
                  : selectedDrug.location[0].val
                : [],
            location_obj:
              selectedDrug.location.length > 0
                ? typeof selectedDrug.location[0] === "string"
                  ? {
                      val: selectedDrug.location[0],
                      text: selectedDrug.location[0],
                    }
                  : selectedDrug.location[0]
                : [],
            frequency:
              selectedDrug.frequency.length > 0
                ? typeof selectedDrug.frequency[0] === "string"
                  ? selectedDrug.frequency[0]
                  : selectedDrug.frequency[0].val
                : [],
            frequency_obj:
              selectedDrug.frequency.length > 0
                ? typeof selectedDrug.frequency[0] === "string"
                  ? {
                      val: selectedDrug.frequency[0],
                      text: selectedDrug.frequency[0],
                    }
                  : selectedDrug.frequency[0]
                : [],
            durationStr:
              selectedDrug.durationStr.length > 0
                ? selectedDrug.durationStr[0].text
                : [],
            duration_obj:
              selectedDrug.durationStr.length > 0
                ? selectedDrug.durationStr[0]
                : [],
          };
    }

    this.setState(
      {
        currentMedicines: new_rx,
        medicationModalShown: false,
      },
      () => {
        this.updateMedications();
      }
    );
    this.closeErxModal();
  }

  closeErxModal() {
    $("#erxMedicationModal").modal("hide");
    this.clearFields();
  }

  handleEditMedication(item, method, i, is_current) {
    if (item.free_text) {
      this.setState({
        selectedPill: "free_text",
        selectedDrugs: [{ ...item }],
        method,
        selectedIndex: i,
        isCurrent: is_current ? true : false,
      });
      this.openMedicationModal();
      return;
    }
    let selectedDrug = item.textArray
      ? {
          ...item,
        }
      : {
          ...item,
          direction:
            typeof item.direction === "string"
              ? [item.direction]
              : item.direction.length > 0
              ? item.direction
              : [],
          dosage_form:
            typeof item.dosage_form === "string"
              ? [item.dosage_form]
              : item.dosage_form.length > 0
              ? item.dosage_form
              : [],
          route:
            typeof item.route === "string"
              ? [item.route_obj]
              : item.route.length > 0
              ? item.route
              : [],
          location:
            typeof item.location === "string"
              ? [item.location_obj]
              : item.location.length > 0
              ? item.location
              : [],
          frequency:
            typeof item.frequency === "string"
              ? [item.frequency_obj]
              : item.frequency > 0
              ? [item.frequency_obj]
              : item.frequency.length > 0
              ? item.frequency
              : [],
          durationStr:
            typeof item.durationStr === "string"
              ? [item.duration_obj]
              : item.durationStr.length > 0
              ? item.durationStr
              : [],
        };

    this.setState({
      selectedPill: item.textArray ? "free_text" : "labeled_entry",
      selectedDrug,
      method,
      selectedIndex: i,
      isCurrent: is_current ? true : false,
    });
    this.openMedicationModal();
  }

  openMedicationModal() {
    this.setState({ medicationModalShown: true }, () => {
      $("#erxMedicationModal").modal({
        backdrop: "static",
        keyboard: false,
      });
      $("#erxMedicationModal").modal("show");
    });
  }

  clearFields() {
    $("#durationStr").prop("selectedIndex", 0);
  }

  checkUncheckAllRx(e) {
    const { checked } = e.target;
    const { currentMedicines } = this.state;
    const newCurrentMeds = currentMedicines.map((med) => ({
      ...med,
      isChecked: checked,
    }));

    this.setState(
      {
        isCheckedAll: checked,
        currentMedicines: newCurrentMeds,
        checkedMedicines: this.filterCheckedMedicines(newCurrentMeds),
      },
      () => {
        this.updateMedications();
      }
    );
  }

  resetCheckedMedicines() {
    const { currentMedicines } = this.state;
    const newCurrentMeds = currentMedicines.map((med) => ({
      ...med,
      isChecked: false,
    }));

    this.setState(
      {
        currentMedicines: newCurrentMeds,
        checkedMedicines: this.filterCheckedMedicines(newCurrentMeds),
      },
      () => {
        this.updateMedications();
      }
    );
  }

  checkUncheckRx(e, i) {
    const { checked } = e.target;
    const { currentMedicines } = this.state;

    currentMedicines[i].isChecked = checked;
    this.setState(
      {
        currentMedicines,
        checkedMedicines: this.filterCheckedMedicines(currentMedicines),
      },
      () => {
        this.updateMedications();
      }
    );
  }

  handleChangeDrugDetails(selected, str) {
    const { selectedDrug } = this.state;
    let newSelected = [];
    let isQuantifiable = false;

    if (selected.length > 0) {
      newSelected = selected[0].customOption
        ? [selected[0].label || selected[0].text]
        : selected;
    }

    if (str === "dosage_form" && newSelected.length > 0) {
      isQuantifiable = quantifiables.includes(newSelected[0]) ? true : false;
      this.setState({ isQuantifiable });
    }

    this.setState(
      {
        selectedDrug: {
          ...selectedDrug,
          [str]: newSelected,
        },
      },
      () => {
        this.calculateDrugQuantity();
      }
    );
  }

  openPrescriptionTab() {
    const { patientInfo } = this.state;
    if (!patientInfo.prescriber) {
      Notify.error("Please select a prescriber to view prescription.");
      return;
    }
    // this.setState({
    //   isPrescriptionShown: true
    // }, () => {
    //   $("#printPrescriptionModal").modal({
    //     backdrop: 'static',
    //     keyboard: false
    //   });
    //   $("#printPrescriptionModal").modal('show');
    // });
    const prescriptionUrl = `${window.location.origin}/prescription/${this.props.threadId}`;
    window.open(prescriptionUrl, "Hubchart Prescription");
  }

  handleClosePrescriptionModal() {
    $("#printPrescriptionModal").modal("hide");
    setTimeout(() => {
      this.setState({ isPrescriptionShown: false });
    }, 100);
  }

  selectPrescriber(event) {
    const { patientInfo } = this.state;
    const { value } = event.target;

    const User = Parse.Object.extend("_User");
    const Circle = Parse.Object.extend("Circle");

    const prescriber = new User({ objectId: value });

    let newCircle = new Circle({
      objectId: patientInfo.objectId,
      prescriber,
    });

    this.savePrescriber(newCircle);
  }

  getPrescriberDetails(objectId) {
    ConferenceApi.getUsersById({ objectIds: [objectId] }).then((response) => {
      this.setState({
        doctorInfo: response.users[0],
      });
    });
  }

  superAdminChecker() {
    const { owner = {} } = this.props.organization;
    const { user } = this.state;
    const role = user.get("orgRole");

    if (
      owner.objectId === user.id ||
      role === "physician"
      // ||
      // role === "editor"
    ) {
      this.setState({
        canAssignPrescriber: true,
      });
    }
  }

  handleSelectTab(newTab) {
    const { selectedDrugs, method } = this.state;
    this.setState({
      selectedPill: newTab,
      selectedDrug: { ...defaultSelectedDrug },
      selectedDrugs:
        newTab === "labeled_entry" && method === "create"
          ? [
              { ...defaultSelectedDrug },
              { ...defaultSelectedDrug },
              { ...defaultSelectedDrug },
              { ...defaultSelectedDrug },
              { ...defaultSelectedDrug },
            ]
          : [...selectedDrugs],
    });
  }

  displayRichTextinModal(richTextData) {
    const { currentTab } = this.state;
    const richText =
      currentTab === "current"
        ? richTextData
        : convertToRaw(richTextData.getCurrentContent());
    let count = 0;

    return (richText.blocks || []).map((item, i) => {
      const itemLength = item.text.replace(/ /g, "").length;
      const hasBullet =
        item.type === "unordered-list-item" && item.depth === 0 ? true : false;
      const hasNumber =
        item.type === "ordered-list-item" && item.depth === 0 ? true : false;
      let listNum = 0;
      let listAsterisk = "∙";
      const medStyle = {
        fontWeight: "bold",
        marginTop: i > 0 ? "15px" : "0px",
      };

      if (hasNumber) {
        count = count + 1;
        listNum = count;
      }

      return (
        <div key={item.key} className="rte-flex">
          {hasBullet && (
            <div
              className="count-cls"
              style={medStyle}
            >{`${listAsterisk}`}</div>
          )}
          {hasNumber && (
            <div className="count-cls" style={medStyle}>{`${listNum}.`}</div>
          )}
          {!hasNumber && !hasBullet && <div className="count-cls" />}
          <div
            style={{
              marginTop: (hasNumber || hasBullet) && i > 0 ? "15px" : "0px",
            }}
          >
            {itemLength > 0 && (
              <span
                style={{
                  fontWeight:
                    hasBullet ||
                    hasNumber ||
                    !(richText.blocks[i - 1] || {}).text
                      ? "bold"
                      : "normal",
                }}
              >
                {item.text}
              </span>
            )}
            {itemLength === 0 && <div style={{ height: "15px" }}></div>}
          </div>
        </div>
      );
    });
  }

  handleChangeTextAreaDetails(e, i) {
    const { selectedDrugs } = this.state;
    const { value } = e.target;
    let newArr = [...selectedDrugs];
    newArr[i].free_text = value;

    this.setState({
      selectedDrugs: [...newArr],
    });
  }

  addTextAreaField() {
    const { selectedDrugs } = this.state;
    this.setState({
      selectedDrugs: [
        {
          ...defaultSelectedDrug,
          free_text: "",
        },
        ...selectedDrugs,
      ],
    });
  }

  removeTextAreaField(i) {
    const { selectedDrugs } = this.state;
    let newArr = selectedDrugs;
    newArr.splice(i, 1);

    this.setState({
      selectedDrugs: [...newArr],
    });
  }

  handlePostOnHub() {
    this.setState({ postOnHubModalShown: true });
  }

  handlePostOnHubOnClose(bool) {
    this.setState({ postOnHubModalShown: false });
    if (bool) {
      Notify.success("Prescription succesfully posted on hub.");
    }
  }

  handleForwardMessage() {
    this.setState({ forwardMessageModalShown: true });
  }

  handleForwardMessageOnClose() {
    this.setState({ forwardMessageModalShown: false });
  }

  handlePostMessageOnHub(message, thread) {
    this.props.forwardMessage(message, thread);
  }

  handleCreatePrescriptionHistory(data = [], circleId) {
    const { patientInfo } = this.state;
    if (!patientInfo.prescriber) {
      Notify.error("Please select a prescriber to view prescription.");
      return;
    }

    if (data.length === 0) {
      Notify.info("Please select atleast one medicine to generate.");
    } else {
      this.props.createNewPrescriptionHistory(data, circleId);
    }
  }

  render() {
    const {
      physicians = [],
      openDocuments = () => {},
      thread = {},
      fromProgressNotes = false,
      fromOrders = false,
      importPrescription = () => {},
    } = this.props;
    const debouncedFetchErxList = debounce(this.fetchErxList, 500);
    const {
      doctorInfo = {},
      currentTab = "current",
      patientInfo = {},
      selectedDrug = {},
      selectedDrugs = [],
      selectedRxList = [],
      erxListRestructured = [],
      currentMedicines = [],
      checkedMedicines = [],
      deletedMedicines = [],
      isLoading = false,
      isLoadingPrescription = false,
      endOfList = false,
      medicationModalShown = false,
      isPrescriptionShown = false,
      isQuantifiable = false,
      canAssignPrescriber = false,
      postOnHubModalShown = false,
      forwardMessageModalShown = false,
      isCurrent = false,
      showPill = true,
      isCheckedAll = false,
      selectedPill,
      method,
    } = this.state;
    const disableForward = checkedMedicines.length === 0 ? true : false;
    let toBeForwarded = "";
    if (checkedMedicines.length > 0) {
      toBeForwarded = `
  PATIENT NAME: ${patientInfo.name || "--"}
  PATIENT DOB: ${patientInfo.dob || "--"}
  ${removeExtraLines(handleMessageFormat(checkedMedicines))}
  FILE LINK:
  ${window.location.origin}/prescription/${patientInfo.objectId}
  `;
    }

    return (
      <div className="progress-notes-container pn-con componentInnerContainer">
        <div className="container mb-15 pn-title-flex">
          <span className="navbar-brand">
            <img
              className="d-sm-inline"
              width="40"
              height="40"
              src={Logo}
              alt="H"
            ></img>
            <span
              className="hc-primary-text d-none d-md-inline"
              style={{ fontWeight: "500" }}
            >
              HUBCHART
            </span>
          </span>
          <div className="pn-patient-details-flex">
            <div>
              <span>{patientInfo.name || "--"}</span>
              <span>MEDICATIONS</span>
            </div>
            {(typeof (patientInfo.image || {}).url === "undefined" ||
              (patientInfo.image || {}).url === "") && (
              <img src={require("../../../../assets/images/default.png")} />
            )}
            {(patientInfo.image || {}).url && (
              <img src={(patientInfo.image || {}).url} />
            )}
          </div>
          {/* <span className="pn-title">PATIENT MEDICATIONS</span> */}
        </div>
        <div className="pn-template-container">
          <div className="container pb-10">
            <PatientInfo patientInfo={patientInfo} />
          </div>
        </div>
        <div class="container">
          <div>
            <div
              className="pills-input-container"
              style={{ margin: "15px 0px" }}
            >
              <ul className="nav nav-pills">
                <li className="nav-item">
                  <a
                    onClick={() => this.selectTab("current")}
                    className={
                      currentTab === "current"
                        ? "hc-nav-link active"
                        : "hc-nav-link"
                    }
                    href="#"
                  >
                    <i className="fa fa-file-prescription mr-2"></i>
                    Current Rx
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={() => this.selectTab("deleted")}
                    className={
                      currentTab === "deleted"
                        ? "hc-nav-link active"
                        : "hc-nav-link"
                    }
                    href="#"
                  >
                    <i className="fa fa-ban mr-2"></i>
                    Deleted Rx
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={() => this.selectTab("prescribe")}
                    className={
                      currentTab === "prescribe"
                        ? "hc-nav-link active"
                        : "hc-nav-link"
                    }
                    href="#"
                  >
                    <i className="fas fa-pills mr-2"></i>
                    Add Rx
                  </a>
                </li>
              </ul>
              {currentTab === "current" && (
                <div>
                  <button
                    className="btn btn-sm btn-link" //btn-hubchart btn-sm btn-hubchart-primary"
                    style={{
                      marginRight: "10px",
                      height: "37px",
                      textDecoration: "none",
                      fontWeight: "bold",
                      borderRadius: "20px",
                    }}
                    onClick={() => {
                      this.manualSelectRx("labeled_entry");
                      this.selectTab("prescribe");
                    }}
                  >
                    Add Rx Manually
                  </button>
                  <button
                    className="btn btn-sm btn-link" //btn-hubchart btn-sm btn-hubchart-primary"
                    style={{
                      borderRadius: "20px",
                      height: "37px",
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      this.manualSelectRx("free_text");
                      this.selectTab("prescribe");
                    }}
                  >
                    Free Text
                  </button>
                </div>
              )}
              {currentTab === "current" && (
                <div
                  className="search-flex custom-search-flex"
                  style={{ marginBottom: "10px" }}
                >
                  {canAssignPrescriber && (
                    <div style={{ marginBottom: "0px", width: "100%" }}>
                      <span style={{ fontSize: "12px" }}>Prescribed by: </span>
                      <select
                        id="prescription-prescriber"
                        className="form-control"
                        value={patientInfo.prescriber}
                        onChange={(e) => this.selectPrescriber(e)}
                      >
                        <option value="">None</option>
                        {physicians.map((physician, index) => (
                          <option key={index} value={physician.objectId}>
                            {physician.displayName}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
              )}
            </div>
            {currentTab === "prescribe" && (
              <div>
                <div
                  className="search-flex search-identifier"
                  style={{ marginBottom: "10px" }}
                >
                  <div className="rbt-container">
                    <Typeahead
                      id="prescription-typeahead"
                      placeholder="Search Rx here"
                      onChange={(selected) => {
                        this.selectErx(selected, "create");
                      }}
                      onInputChange={(e) => debouncedFetchErxList(e)}
                      options={erxListRestructured}
                      maxResults={999999999}
                      labelKey={"displayName"}
                      filterBy={() => true}
                      selected={this.state.selectedOption}
                      renderMenu={(results, menuProps) => (
                        <Menu {...menuProps}>
                          {results.map((result, index) => (
                            <MenuItem
                              key={result._id + index}
                              option={result}
                              position={index}
                            >
                              {`${result.displayName}`}
                            </MenuItem>
                          ))}
                          <TabLoader isLoading={isLoadingPrescription} />
                          <div className="load-more">
                            {!endOfList && (
                              <button
                                type="button"
                                className="btn btn-block btn-light btn-sm"
                                onClick={() => this.handleFetchErxPageChange()}
                              >
                                Load More
                              </button>
                            )}
                            {endOfList && <span>- End of List -</span>}
                          </div>
                        </Menu>
                      )}
                    />
                    <button
                      type="button"
                      className="btn btn-hubchart btn-sm btn-hubchart-primary"
                      style={{ height: "34px", width: "50px" }}
                    >
                      <i className="fas fa-search"></i>
                    </button>
                  </div>
                  <button
                    type="button"
                    className="btn btn-hubchart btn-hubchart-primary"
                    style={{
                      marginBottom: "0px",
                      marginLeft: "10px",
                      height: "33px",
                      fontSize: ".875rem",
                    }}
                    onClick={() => this.manualSelectRx()}
                  >
                    Enter Rx Manually
                  </button>
                </div>
                {selectedRxList.length > 0 && (
                  <div className="table-container">
                    <div className="rx-list-container ap-height">
                      <div className="prescription-headers">
                        <span>Date Prescribed</span>
                        <span>Medication</span>
                        <span>Quantity</span>
                        <span>Action</span>
                      </div>
                      <div className="erx-item-container">
                        {selectedRxList.map((item, i) => (
                          <Fragment key={i}>
                            {item.textArray && (
                              <div className="prescription-item" key={i}>
                                <span>{item.dateCreated}</span>
                                <div>
                                  {item.textArray.map((item, i) => (
                                    <div
                                      className="textarea-display"
                                      key={item + i}
                                    >
                                      {item}
                                    </div>
                                  ))}
                                </div>
                                <div>
                                  {item.quantity !== 0 && (
                                    <Fragment>
                                      <span>
                                        <strong>{item.quantity}</strong>
                                      </span>
                                      <br />
                                      {isQuantifiable && (
                                        <span>{item.dosage_form}</span>
                                      )}
                                    </Fragment>
                                  )}
                                </div>
                                <div className="action-container">
                                  <button
                                    type="button"
                                    title="Edit Medication"
                                    className="btn btn-primary btn-sm"
                                    style={{ borderRadius: "12px" }}
                                    onClick={() =>
                                      this.handleEditMedication(
                                        item,
                                        "update",
                                        i
                                      )
                                    }
                                  >
                                    <i className="fas fa-fw fa-edit"></i>
                                  </button>
                                  <button
                                    type="button"
                                    title="Remove Medication"
                                    className="btn btn-danger btn-sm"
                                    style={{ borderRadius: "12px" }}
                                    onClick={() => this.removeItem(item)}
                                  >
                                    <i className="fas fa-fw fa-trash"></i>
                                  </button>
                                </div>
                              </div>
                            )}
                            {item.free_text && (
                              <div className="prescription-item" key={i}>
                                <span>{item.dateCreated}</span>
                                <div>
                                  <div className="textarea-display">
                                    {item.free_text}
                                  </div>
                                </div>
                                <div>
                                  {item.quantity !== 0 && (
                                    <Fragment>
                                      <span>
                                        <strong>{item.quantity}</strong>
                                      </span>
                                      <br />
                                      {isQuantifiable && (
                                        <span>{item.dosage_form}</span>
                                      )}
                                    </Fragment>
                                  )}
                                </div>
                                <div className="action-container">
                                  <button
                                    type="button"
                                    title="Edit Medication"
                                    className="btn btn-primary btn-sm"
                                    style={{ borderRadius: "12px" }}
                                    onClick={() =>
                                      this.handleEditMedication(
                                        item,
                                        "update",
                                        i
                                      )
                                    }
                                  >
                                    <i className="fas fa-fw fa-edit"></i>
                                  </button>
                                  <button
                                    type="button"
                                    title="Remove Medication"
                                    className="btn btn-danger btn-sm"
                                    style={{ borderRadius: "12px" }}
                                    onClick={() => this.removeItem(item)}
                                  >
                                    <i className="fas fa-fw fa-trash"></i>
                                  </button>
                                </div>
                              </div>
                            )}
                            {!item.textArray && !item.free_text && (
                              <div className="prescription-item" key={i}>
                                <span>{item.dateCreated}</span>
                                <div>
                                  <span>
                                    <strong>{item.displayName}</strong>
                                  </span>
                                  <div className="instructions-flex">
                                    <span>
                                      <strong>Sig: </strong>
                                    </span>
                                    <div>
                                      <span>
                                        {item.direction} {item.amount}{" "}
                                        {item.dosage_form}{" "}
                                      </span>
                                      <span>
                                        {item.route} {item.location}{" "}
                                      </span>
                                      <span>
                                        {item.frequency
                                          ? item.frequency_obj.text
                                          : item.frequency}{" "}
                                        {item.duration > 0 && item.durationStr
                                          ? `for ${item.duration} ${item.durationStr}`
                                          : ""}{" "}
                                      </span>
                                      <br />
                                      <span>{item.additionalDirections}</span>
                                    </div>
                                  </div>
                                  {item.refill > 0 && (
                                    <div className="instructions-flex">
                                      <span>
                                        <strong>Refill: </strong>
                                      </span>
                                      <div>
                                        <span>{item.refill}</span>
                                      </div>
                                    </div>
                                  )}
                                  {item.prn > 0 && (
                                    <div className="instructions-flex">
                                      <span>
                                        <strong>PRN: </strong>
                                      </span>
                                      <div>
                                        <span>{item.prn_reason}</span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div>
                                  {item.quantity !== 0 && (
                                    <Fragment>
                                      <span>
                                        <strong>{item.quantity}</strong>
                                      </span>
                                      <br />
                                      {isQuantifiable && (
                                        <span>{item.dosage_form}</span>
                                      )}
                                    </Fragment>
                                  )}
                                </div>
                                <div className="action-container">
                                  <button
                                    type="button"
                                    title="Edit Medication"
                                    className="btn btn-primary btn-sm"
                                    style={{ borderRadius: "12px" }}
                                    onClick={() =>
                                      this.handleEditMedication(
                                        item,
                                        "update",
                                        i
                                      )
                                    }
                                  >
                                    <i className="fas fa-fw fa-edit"></i>
                                  </button>
                                  <button
                                    type="button"
                                    title="Remove Medication"
                                    className="btn btn-danger btn-sm"
                                    style={{ borderRadius: "12px" }}
                                    onClick={() => this.removeItem(item)}
                                  >
                                    <i className="fas fa-fw fa-trash"></i>
                                  </button>
                                </div>
                              </div>
                            )}
                          </Fragment>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                {selectedRxList.length === 0 && (
                  <div className="default-rx-view ap-height">
                    <span>No selected prescriptions.</span>
                  </div>
                )}
              </div>
            )}
            {currentTab === "current" && (
              <div>
                {currentMedicines.length > 0 && (
                  <div className="table-container">
                    <div className="rx-list-container cp-height">
                      <div className="cur-prescription-headers">
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                          onClick={() =>
                            this.checkUncheckAllRx({
                              target: { checked: !isCheckedAll },
                            })
                          }
                        >
                          <div class="round-small">
                            <input
                              type="checkbox"
                              id="selectAll"
                              name="selectAll"
                              checked={isCheckedAll}
                            />
                            <label for="checkbox"></label>
                          </div>
                        </div>
                        <span>Drug Name</span>
                        <span>Quantity</span>
                        <span>Rx Date</span>
                        <span>Action</span>
                      </div>
                      <div className="erx-item-container">
                        {currentMedicines.map((item, i) => (
                          <Fragment key={i}>
                            {!item.textArray &&
                              !item.richText &&
                              !item.free_text && (
                                <div className="cur-prescription-item" key={i}>
                                  {/* <div>
                                      <input 
                                        type="checkbox" 
                                        id="isChecked"
                                        name="isChecked"
                                        checked={item.isChecked}
                                        onChange={e => this.checkUncheckRx(e, i)} />
                                    </div> */}
                                  <div
                                    onClick={() =>
                                      this.checkUncheckRx(
                                        {
                                          target: {
                                            checked: !item.isChecked,
                                          },
                                        },
                                        i
                                      )
                                    }
                                  >
                                    <div class="round-small">
                                      <input
                                        type="checkbox"
                                        id="isChecked"
                                        name="isChecked"
                                        checked={item.isChecked}
                                      />
                                      <label for="checkbox"></label>
                                    </div>
                                  </div>
                                  <div
                                    onClick={() =>
                                      this.checkUncheckRx(
                                        {
                                          target: {
                                            checked: !item.isChecked,
                                          },
                                        },
                                        i
                                      )
                                    }
                                  >
                                    <span>
                                      <strong>{item.displayName}</strong>
                                    </span>
                                    {/* <div className="instructions-container"> */}
                                    <div className="instructions-flex">
                                      <span>
                                        <strong>Sig: </strong>
                                      </span>
                                      <div>
                                        <span>
                                          {item.direction} {item.amount}{" "}
                                          {item.dosage_form}{" "}
                                        </span>
                                        <span>
                                          {item.route} {item.location}{" "}
                                        </span>
                                        <span>
                                          {item.frequency
                                            ? (item.frequency_obj || {}).text
                                            : item.frequency}{" "}
                                          {item.duration > 0 && item.durationStr
                                            ? `for ${item.duration} ${item.durationStr}`
                                            : ""}
                                        </span>
                                        <br />
                                        <span>{item.additionalDirections}</span>
                                      </div>
                                    </div>
                                    {item.refill > 0 && (
                                      <div className="instructions-flex">
                                        <span>
                                          <strong>Refill: </strong>
                                        </span>
                                        <div>
                                          <span>{item.refill}</span>
                                        </div>
                                      </div>
                                    )}
                                    {item.prn > 0 && (
                                      <div className="instructions-flex">
                                        <span>
                                          <strong>PRN: </strong>
                                        </span>
                                        <div>
                                          <span>{item.prn_reason}</span>
                                        </div>
                                      </div>
                                    )}
                                    {/* </div> */}
                                  </div>
                                  <div>
                                    {item.quantity !== 0 && (
                                      <Fragment>
                                        <span>
                                          <strong>{item.quantity}</strong>
                                        </span>
                                        <br />
                                        {isQuantifiable && (
                                          <span>{item.dosage_form}</span>
                                        )}
                                      </Fragment>
                                    )}
                                  </div>
                                  <span>{item.dateCreated}</span>
                                  <div className="action-container">
                                    <button
                                      type="button"
                                      title="Edit Medication"
                                      className="btn btn-primary btn-sm"
                                      style={{ borderRadius: "12px" }}
                                      onClick={() =>
                                        this.handleEditMedication(
                                          item,
                                          "update",
                                          i,
                                          true
                                        )
                                      }
                                    >
                                      <i className="fas fa-fw fa-edit"></i>
                                    </button>
                                    <button
                                      type="button"
                                      title="Remove Rx"
                                      onClick={() =>
                                        this.removeCurrentMedicine(item)
                                      }
                                      className="btn btn-danger btn-sm"
                                      style={{ borderRadius: "12px" }}
                                    >
                                      <i className="fas fa-fw fa-trash"></i>
                                    </button>
                                  </div>
                                </div>
                              )}
                            {(item.textArray || []).length > 0 && (
                              <div className="cur-prescription-item" key={i}>
                                <div
                                  onClick={() =>
                                    this.checkUncheckRx(
                                      {
                                        target: { checked: !item.isChecked },
                                      },
                                      i
                                    )
                                  }
                                >
                                  <div class="round-small">
                                    <input
                                      type="checkbox"
                                      id="isChecked"
                                      name="isChecked"
                                      checked={item.isChecked}
                                    />
                                    <label for="checkbox"></label>
                                  </div>
                                </div>
                                <div
                                  onClick={() =>
                                    this.checkUncheckRx(
                                      {
                                        target: { checked: !item.isChecked },
                                      },
                                      i
                                    )
                                  }
                                >
                                  {item.textArray.map((item, i) => (
                                    <div
                                      className="textarea-display"
                                      key={item + i}
                                    >
                                      {item}
                                    </div>
                                  ))}
                                </div>
                                <div>
                                  {item.quantity !== 0 && (
                                    <Fragment>
                                      <span>
                                        <strong>{item.quantity}</strong>
                                      </span>
                                      <br />
                                      {isQuantifiable && (
                                        <span>({item.dosage_form})</span>
                                      )}
                                    </Fragment>
                                  )}
                                </div>
                                <span>{item.dateCreated}</span>
                                <div className="action-container">
                                  <button
                                    type="button"
                                    title="Edit Medication"
                                    className="btn btn-primary btn-sm"
                                    style={{ borderRadius: "12px" }}
                                    onClick={() =>
                                      this.handleEditMedication(
                                        item,
                                        "update",
                                        i,
                                        true
                                      )
                                    }
                                  >
                                    <i className="fas fa-fw fa-edit"></i>
                                  </button>
                                  <button
                                    type="button"
                                    title="Remove Rx"
                                    onClick={() =>
                                      this.removeCurrentMedicine(item)
                                    }
                                    className="btn btn-danger btn-sm"
                                    style={{ borderRadius: "12px" }}
                                  >
                                    <i className="fas fa-fw fa-trash"></i>
                                  </button>
                                </div>
                              </div>
                            )}
                            {item.richText && (
                              <div className="cur-prescription-item" key={i}>
                                <div
                                  onClick={() =>
                                    this.checkUncheckRx(
                                      {
                                        target: { checked: !item.isChecked },
                                      },
                                      i
                                    )
                                  }
                                >
                                  <div class="round-small">
                                    <input
                                      type="checkbox"
                                      id="isChecked"
                                      name="isChecked"
                                      checked={item.isChecked}
                                    />
                                    <label for="checkbox"></label>
                                  </div>
                                </div>
                                <div
                                  onClick={() =>
                                    this.checkUncheckRx(
                                      {
                                        target: { checked: !item.isChecked },
                                      },
                                      i
                                    )
                                  }
                                >
                                  {this.displayRichTextinModal(item.richText)}
                                </div>
                                <div>
                                  {item.quantity !== 0 && (
                                    <Fragment>
                                      <span>
                                        <strong>{item.quantity}</strong>
                                      </span>
                                      <br />
                                      {isQuantifiable && (
                                        <span>({item.dosage_form})</span>
                                      )}
                                    </Fragment>
                                  )}
                                </div>
                                <span>{item.dateCreated}</span>
                                <div className="action-container">
                                  {/* <button 
                                        type="button" 
                                        title="Edit Medication"
                                        className="btn btn-primary btn-sm" 
                                        style={{ borderRadius: '12px' }}
                                        onClick={() => this.handleEditMedication(item, 'update', i, true)}>
                                        <i className="fas fa-fw fa-edit"></i>
                                      </button> */}
                                  <button
                                    type="button"
                                    title="Remove Rx"
                                    onClick={() =>
                                      this.removeCurrentMedicine(item)
                                    }
                                    className="btn btn-danger btn-sm"
                                    style={{ borderRadius: "12px" }}
                                  >
                                    <i className="fas fa-fw fa-trash"></i>
                                  </button>
                                </div>
                              </div>
                            )}
                            {(item.free_text || []).length > 0 && (
                              <div className="cur-prescription-item" key={i}>
                                <div
                                  onClick={() =>
                                    this.checkUncheckRx(
                                      {
                                        target: { checked: !item.isChecked },
                                      },
                                      i
                                    )
                                  }
                                >
                                  <div class="round-small">
                                    <input
                                      type="checkbox"
                                      id="isChecked"
                                      name="isChecked"
                                      checked={item.isChecked}
                                    />
                                    <label for="checkbox"></label>
                                  </div>
                                </div>
                                <div
                                  onClick={() =>
                                    this.checkUncheckRx(
                                      {
                                        target: { checked: !item.isChecked },
                                      },
                                      i
                                    )
                                  }
                                >
                                  <div className="textarea-display">
                                    {item.free_text}
                                  </div>
                                </div>
                                <div>
                                  {item.quantity !== 0 && (
                                    <Fragment>
                                      <span>
                                        <strong>{item.quantity}</strong>
                                      </span>
                                      <br />
                                      {isQuantifiable && (
                                        <span>({item.dosage_form})</span>
                                      )}
                                    </Fragment>
                                  )}
                                </div>
                                <span>{item.dateCreated}</span>
                                <div className="action-container">
                                  <button
                                    type="button"
                                    title="Edit Medication"
                                    className="btn btn-primary btn-sm"
                                    style={{ borderRadius: "12px" }}
                                    onClick={() =>
                                      this.handleEditMedication(
                                        item,
                                        "update",
                                        i,
                                        true
                                      )
                                    }
                                  >
                                    <i className="fas fa-fw fa-edit"></i>
                                  </button>
                                  <button
                                    type="button"
                                    title="Remove Rx"
                                    onClick={() =>
                                      this.removeCurrentMedicine(item)
                                    }
                                    className="btn btn-danger btn-sm"
                                    style={{ borderRadius: "12px" }}
                                  >
                                    <i className="fas fa-fw fa-trash"></i>
                                  </button>
                                </div>
                              </div>
                            )}
                          </Fragment>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                {currentMedicines.length === 0 && (
                  <div className="default-rx-view cp-height">
                    <span>No current prescriptions found.</span>
                  </div>
                )}
              </div>
            )}
            {currentTab === "deleted" && (
              <div>
                {deletedMedicines.length > 0 && (
                  <div className="table-container">
                    <div className="rx-list-container cp-height">
                      <div className="del-prescription-headers">
                        <span>Drug Name</span>
                        <span>Quantity</span>
                        <span>Rx Date</span>
                        <span>Action</span>
                      </div>
                      <div className="erx-item-container">
                        {deletedMedicines.map((item, i) => (
                          <Fragment key={i}>
                            {!item.textArray &&
                              !item.richText &&
                              !item.free_text && (
                                <div className="del-prescription-item" key={i}>
                                  <div>
                                    <span>
                                      <strong>{item.displayName}</strong>
                                    </span>
                                    {/* <div className="instructions-container"> */}
                                    <div className="instructions-flex">
                                      <span>
                                        <strong>Sig: </strong>
                                      </span>
                                      <div>
                                        <span>
                                          {item.direction} {item.amount}{" "}
                                          {item.dosage_form}{" "}
                                        </span>
                                        <span>
                                          {item.route} {item.location}{" "}
                                        </span>
                                        <span>
                                          {item.frequency
                                            ? item.frequency_obj.text
                                            : item.frequency}{" "}
                                          {item.duration > 0 && item.durationStr
                                            ? `for ${item.duration} ${item.durationStr}`
                                            : ""}
                                        </span>
                                        <br />
                                        <span>{item.additionalDirections}</span>
                                      </div>
                                    </div>
                                    {item.refill > 0 && (
                                      <div className="instructions-flex">
                                        <span>
                                          <strong>Refill: </strong>
                                        </span>
                                        <div>
                                          <span>{item.refill}</span>
                                        </div>
                                      </div>
                                    )}
                                    {item.prn > 0 && (
                                      <div className="instructions-flex">
                                        <span>
                                          <strong>PRN: </strong>
                                        </span>
                                        <div>
                                          <span>{item.prn_reason}</span>
                                        </div>
                                      </div>
                                    )}
                                    {/* </div> */}
                                  </div>
                                  <div>
                                    {item.quantity !== 0 && (
                                      <Fragment>
                                        <span>
                                          <strong>{item.quantity}</strong>
                                        </span>
                                        <br />
                                        {isQuantifiable && (
                                          <span>{item.dosage_form}</span>
                                        )}
                                      </Fragment>
                                    )}
                                  </div>
                                  <span>{item.dateCreated}</span>
                                  <div className="action-container">
                                    <button
                                      type="button"
                                      title="Restore"
                                      onClick={() =>
                                        this.restorePrescription(item)
                                      }
                                      className="btn btn-success btn-sm"
                                      style={{ borderRadius: "12px" }}
                                    >
                                      <i className="fas fa-fw fa-history"></i>
                                    </button>
                                    <button
                                      type="button"
                                      title="Remove Rx Permanently"
                                      onClick={() =>
                                        this.removePrescriptionPermanently(item)
                                      }
                                      className="btn btn-danger btn-sm"
                                      style={{ borderRadius: "12px" }}
                                    >
                                      <i className="fas fa-fw fa-trash"></i>
                                    </button>
                                  </div>
                                </div>
                              )}
                            {(item.textArray || []).length > 0 && (
                              <div className="del-prescription-item" key={i}>
                                <div>
                                  {item.textArray.map((item, i) => (
                                    <div
                                      className="textarea-display"
                                      key={item + i}
                                    >
                                      {item}
                                    </div>
                                  ))}
                                </div>
                                <div>
                                  {item.quantity !== 0 && (
                                    <Fragment>
                                      <span>
                                        <strong>{item.quantity}</strong>
                                      </span>
                                      <br />
                                      {isQuantifiable && (
                                        <span>({item.dosage_form})</span>
                                      )}
                                    </Fragment>
                                  )}
                                </div>
                                <span>{item.dateCreated}</span>
                                <div className="action-container">
                                  <button
                                    type="button"
                                    title="Restore"
                                    onClick={() =>
                                      this.restorePrescription(item)
                                    }
                                    className="btn btn-success btn-sm"
                                    style={{ borderRadius: "12px" }}
                                  >
                                    <i className="fas fa-fw fa-history"></i>
                                  </button>
                                  <button
                                    type="button"
                                    title="Remove Rx Permanently"
                                    onClick={() =>
                                      this.removePrescriptionPermanently(item)
                                    }
                                    className="btn btn-danger btn-sm"
                                    style={{ borderRadius: "12px" }}
                                  >
                                    <i className="fas fa-fw fa-trash"></i>
                                  </button>
                                </div>
                              </div>
                            )}
                            {item.richText && (
                              <div className="del-prescription-item" key={i}>
                                <div style={{ width: 0 }}>
                                  {/* <input 
                                        type="checkbox" 
                                        id="isChecked"
                                        name="isChecked"
                                        checked={item.isChecked}
                                        onChange={e => this.checkUncheckRx(e, i)} /> */}
                                </div>
                                <div>
                                  {this.displayRichTextinModal(item.richText)}
                                </div>
                                <div>
                                  {item.quantity !== 0 && (
                                    <Fragment>
                                      <span>
                                        <strong>{item.quantity}</strong>
                                      </span>
                                      <br />
                                      {isQuantifiable && (
                                        <span>({item.dosage_form})</span>
                                      )}
                                    </Fragment>
                                  )}
                                </div>
                                <span>{item.dateCreated}</span>
                                <div className="action-container">
                                  <button
                                    type="button"
                                    title="Restore"
                                    onClick={() =>
                                      this.restorePrescription(item)
                                    }
                                    className="btn btn-success btn-sm"
                                    style={{ borderRadius: "12px" }}
                                  >
                                    <i className="fas fa-fw fa-history"></i>
                                  </button>
                                  <button
                                    type="button"
                                    title="Remove Rx Permanently"
                                    onClick={() =>
                                      this.removePrescriptionPermanently(item)
                                    }
                                    className="btn btn-danger btn-sm"
                                    style={{ borderRadius: "12px" }}
                                  >
                                    <i className="fas fa-fw fa-trash"></i>
                                  </button>
                                </div>
                              </div>
                            )}
                            {(item.free_text || []).length > 0 && (
                              <div className="del-prescription-item" key={i}>
                                <div>
                                  <div className="textarea-display">
                                    {item.free_text}
                                  </div>
                                </div>
                                <div>
                                  {item.quantity !== 0 && (
                                    <Fragment>
                                      <span>
                                        <strong>{item.quantity}</strong>
                                      </span>
                                      <br />
                                      {isQuantifiable && (
                                        <span>({item.dosage_form})</span>
                                      )}
                                    </Fragment>
                                  )}
                                </div>
                                <span>{item.dateCreated}</span>
                                <div className="action-container">
                                  <button
                                    type="button"
                                    title="Restore"
                                    onClick={() =>
                                      this.restorePrescription(item)
                                    }
                                    className="btn btn-success btn-sm"
                                    style={{ borderRadius: "12px" }}
                                  >
                                    <i className="fas fa-fw fa-history"></i>
                                  </button>
                                  <button
                                    type="button"
                                    title="Remove Rx Permanently"
                                    onClick={() =>
                                      this.removePrescriptionPermanently(item)
                                    }
                                    className="btn btn-danger btn-sm"
                                    style={{ borderRadius: "12px" }}
                                  >
                                    <i className="fas fa-fw fa-trash"></i>
                                  </button>
                                </div>
                              </div>
                            )}
                          </Fragment>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                {deletedMedicines.length === 0 && (
                  <div className="default-rx-view cp-height">
                    <span>No deleted prescriptions found.</span>
                  </div>
                )}
              </div>
            )}
            <div className="medicationButtons">
              <div className="container">
                <div className="medicationButtonsFlex">
                  {currentTab === "current" && (
                    <Fragment>
                      {/* <button
                        type="button"
                        className="btn btn-hubchart btn-hubchart-close"
                        onClick={() => this.handlePostOnHub()}
                        disabled={disableForward}
                      >
                        <i className="fas fa-pills"></i> Post on Hub
                      </button> */}
                      <div />
                      {/* <button
                    type="button"
                    className="btn btn-hubchart btn-hubchart-close"
                    style={{ marginRight: "auto" }}
                    onClick={() => this.handleForwardMessage()}
                    disabled={disableForward}
                  >
                    <i className="fas fa-share-square"></i> Send File Link
                  </button> */}
                    </Fragment>
                  )}
                  {currentTab === "current" && ( //&& !fromProgressNotes && !fromOrders
                    <Fragment>
                      <button
                        type="button"
                        className="btn btn-hubchart btn-hubchart-primary"
                        onClick={() =>
                          this.handleCreatePrescriptionHistory(
                            checkedMedicines,
                            this.props.threadId
                          )
                        }
                      >
                        Create Prescription
                      </button>
                    </Fragment>
                  )}
                  {(fromProgressNotes || fromOrders) &&
                    currentTab === "current" && (
                      <button
                        type="button"
                        className="btn btn-hubchart btn-hubchart-primary"
                        onClick={() => {
                          importPrescription(checkedMedicines);
                          $("#patientErxModal").modal("hide");
                        }}
                      >
                        <i className="fas fa-save"></i>
                        {fromProgressNotes
                          ? " Add to Progress Notes"
                          : " Add to Orders"}
                      </button>
                    )}
                  {currentTab === "prescribe" && (
                    <button
                      type="button"
                      className="btn btn-hubchart btn-hubchart-primary"
                      onClick={() => {
                        /* this.formatCircle() */
                      }}
                    >
                      <i className="fas fa-save"></i> Save
                    </button>
                  )}
                  {/* <button
                  type="button"
                  className="btn btn-hubchart btn-hubchart-close"
                  data-dismiss="modal"
                >
                  Close
                </button> */}
                </div>
              </div>
            </div>
          </div>
          <Loader isLoading={isLoading} />
          {medicationModalShown && (
            <AddPrescriptionModal
              showPill={showPill}
              handleSelectTab={this.handleSelectTab}
              handleMedicationChange={this.handleMedicationChange}
              handleQuantityChange={this.handleQuantityChange}
              handleChangeDrugDetails={this.handleChangeDrugDetails}
              handleChangeTextAreaDetails={this.handleChangeTextAreaDetails}
              handleCloseErxModal={this.closeErxModal}
              addTextAreaField={this.addTextAreaField}
              removeTextAreaField={this.removeTextAreaField}
              saveMedication={this.saveMedication}
              saveCurrentMedication={this.saveCurrentMedication}
              openDocuments={openDocuments}
              selectedPill={selectedPill}
              selectedDrug={selectedDrug}
              selectedDrugs={selectedDrugs}
              medicationModalShown={medicationModalShown}
              method={method}
              isCurrent={isCurrent}
            />
          )}
          {postOnHubModalShown && (
            <PostOnHub
              isOpen={postOnHubModalShown}
              modalClose={(bool) => this.handlePostOnHubOnClose(bool)}
              thread={thread}
              message={checkedMedicines}
              forwardMessage={(message, thread) => {
                this.handlePostMessageOnHub(message, thread);
              }}
              patientName={patientInfo.name || "No Name"}
            />
          )}
          {forwardMessageModalShown && (
            <ForwardModal
              isOpen={forwardMessageModalShown}
              onClose={this.handleForwardMessageOnClose}
              messages={[{ text: toBeForwarded }]}
            />
          )}
          {isPrescriptionShown && (
            <PrintPrescription
              medicines={checkedMedicines}
              patientInfo={patientInfo}
              doctorInfo={doctorInfo}
              closePrescriptionModal={() => this.handleClosePrescriptionModal()}
            />
          )}
          <ToastContainer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  physicians: state.session.physicians,
  editors: state.session.editors,
  activeTab: state.thread.activeTab,
  isCreatingPrescriptionHistory: state.patient.isCreatingPrescriptionHistory,
});

export default connect(mapStateToProps, {
  fetchphysiciansAndEditors,
})(MedicationComponent);
