import { parseAction, stripeAction } from "./REST";
import Parse from "parse";
import config from "../config";
const $ = window.$;

export const fetchHCUSubscription = () => dispatch => {
  return new Promise((resolve, reject) => {
    let currentObjectId = Parse.User.current().id;
    let User = Parse.Object.extend("User");
    let currentUser = new User();
    currentUser.id = currentObjectId;
    parseAction("get", config.BASE_URL + "/parse/classes/HCUSubscription", {
      where: {
        user: {
          __type: "Pointer",
          className: "_User",
          objectId: currentObjectId
        }
      }
    })
      .then(req => {
        //    console.log(req);
      })
      .catch(error => {
        // TODO handle error when fetching thread
        console.log(error);
      });
  });
};

export const fetchStripePlan = planId => dispatch => {
  stripeAction("GET", "https://api.stripe.com/v1/plans/" + planId)
    .then(req => {
      // console.log(req);
    })
    .catch(error => {
      // TODO handle error when fetching thread
      console.log(error);
    });
};
