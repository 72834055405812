import React from 'react';

const Video = ({ url }) => {

  const styles = {
    display: "block",
    height: '100%',
  };

  return (
    <video key={1} style={styles} controls>
      <source src={url} />
      Your browser does not support HTML5 video.
    </video>
  )
}

export default Video;
