import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateSchedule } from '../../../actions/ConferenceAction';
import Loading from '../../Pages/Signup/Loading';
import Notify from '../../Common/Notify';

const $ = window.$;

function UpdateScheduleModal(props) {

    const modalId = '#update_schedule_modal';

    // State hook
    const [loading, setLoading] = useState(false);
    const [label, setLabel] = useState('');
    const [comments, setComments] = useState('');
    const [dob, setDob] = useState('');

    // Dispatch hook
    const dispatch = useDispatch();

    // Use componentDidmount lifecycle
    useEffect(componentDidMount, [props.isOpen, props.conference]);

    function componentDidMount() {
        if (props.isOpen) {
            $(modalId).modal({
                backdrop: 'static',
                keyboard: false
            });
            $(modalId).modal('show');

            const {label = '', comments = '', dob = ''} = props.conference;
            
            setLoading(false);
            setLabel(label);
            setComments(comments);
            setDob(dob);
        } else {
            $(modalId).modal('hide');
        }
        $(modalId).on('hidden.bs.modal', function (e) {

            if (props.onClose) {
                props.onClose();
            }
        }.bind(this))
    }

    function handleSubmit(e) {

        e.preventDefault();
                
        setLoading(true);

        const data = {label, comments, dob};
        const {conference} = props;
        
        updateSchedule(conference.objectId, data)(dispatch)
        .then(() => {

            Notify.success('Conference call updated!');
    
            props.onClose();
        })
        .catch(() => {

            Notify.error('Could not update conference call.');
        })
    }

    function handleDobChange(value = '') {

        let dob = value;
        
        if (dob.endsWith('/')){
            dob = dob.slice(0, -1);
        }
        else if (
            dob.length === 2 ||
            dob.length === 5){
            dob += '/'
        }

        setDob(dob);
    }

    function render() {
        return (
            <div id="update_schedule_modal" className="modal" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h5 className="modal-title hc-primary-text" style={{ fontSize: "1.25rem" }}>
                                Update conference
                            </h5>

                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <form onSubmit={handleSubmit}>
                            <div className="modal-body" style={{ paddingBottom: "0px" }}>
                                <div className="form-group">
                                    <div className="card mb-3">
                                        <input value={label} onChange={(e) => setLabel(e.target.value)} type="text" placeholder="Patient name/Topic" required />
                                        <i className="fas fa-user hc-primary-text"></i>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="card mb-3">
                                        <input value={dob} onChange={(e) => handleDobChange(e.target.value)} type="text" placeholder="Date of Birth: mm/dd/yyyy" />
                                        <i className="fas fa-calendar hc-primary-text"></i>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="card mb-3">
                                        <textarea value={comments} onChange={(e) => setComments(e.target.value)} rows="3" placeholder="Enter/edit notes" />
                                        <i className="fas fa-comments hc-primary-text"></i>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer" align="right">
                                {!loading ?
                                    <div className="form-group" style={{ textAlign: "right" }}>
                                        <button type="submit" style={{ marginRight: "15px" }} className="btn btn-hubchart btn-hubchart-primary">Update</button>
                                        <button type="button" className="btn btn-hubchart btn-hubchart-close" data-dismiss="modal">Close</button>
                                    </div>
                                    :
                                    <div className="form-group" style={{ textAlign: "right", position: "relative", height: "40px" }}>
                                        <Loading />
                                    </div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    return render();
}

export default UpdateScheduleModal;
