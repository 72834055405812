import React, { useMemo, useState, useEffect  } from "react";
import OverlayLoader from "../../Common/OverlayLoader";
import OverlayPlaceholder from "../../Common/OverlayPlaceholder";
import ManageEventModal from "../ConferenceMain/ManageEventModal";
import util from "../../../helper/util";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setActiveTab } from "../../../actions/ThreadAction";
import ConferenceImage from "../../Common/ConferenceImage";
import DatePicker from 'react-datepicker';

const TelemedToday = ({ conferences, isLoading, handleTelemedTodayScroll, handleTelemedTodayFilter, scheduleTodayHasMore }) => {

  const [modalShown, setModalShown] = useState(false);
  const [selectedConference, setSelectedConference] = useState({});
  const [startDate, setStartDate] = useState({ date: new Date() });

  const dispatch = useDispatch();
  const dateToday = useMemo(() => {
    return moment().format("dddd, MMM D, YYYY");
  }, []);

  const styles = {
    minHeight: "50vh",
    borderRadius: "8px",
    overflow: "hidden",
  };

  const handleClick = () => {
    dispatch(setActiveTab("conference"));
  };

  const handleOpenModal = (conf) => {
    setSelectedConference(conf);
    setModalShown(true);
  };

  const handleCloseModal = () => {
    setSelectedConference({});
    setModalShown(false);
  };

  function handleStartDate (date, filter) {
    if (date === new Date()) {console.log('same date');}
    console.log(date.toLocaleDateString());
    console.log((new Date()).toLocaleDateString());
    var current = date;
    if (filter == 'next') {
      current.setDate(date.getDate()+1);
    }
    if (filter == 'prev') {
      current.setDate(date.getDate()-1);
    }
    setStartDate({
      ...startDate,
      date: current
    });
    const filter_date = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();
    handleTelemedTodayFilter(filter_date);
    var container = document.getElementById('TelemedToday-list');
    container.scrollTop = 0;
  }

  const itemCls = {
    position: "relative",
    display: "flex",
    flexWrap: "nowrap !important",
    flexDirection: "row !important",
    padding: "0px 10px"
  };

  return (
    <div className="pl-0 h-100" style={styles}>
      <div className="dashboard-box">
        <h6 className="sidebar-heading d-flex justify-content-between align-items-center ">
          <strong>
            <span>Telemed Schedules</span>
          </strong>
          <span className="text-secondary">
            <i className="fad fa-fw fa-calendar"></i>
          </span>
        </h6>
        <div className="flex-date-between">
          <i className="fad fa-chevron-left" role="button" onClick={() => handleStartDate(startDate.date, "prev")}></i>
          <span className="tel-time">
            <DatePicker
              className="text-center text-primary btn"
              todayButton={"Today"}
              dateFormat="EEEE, MMMM dd" 
              selected={startDate.date}
              onChange={date => handleStartDate(date)} 
            />
            {/* ({moment(new Date(dateScheduled.iso)).format("L")}) */}
          </span>
          <i className="fad fa-chevron-right" role="button" onClick={() => handleStartDate(startDate.date, "next")}></i>
        </div>
        <div className="w-100 h-100 position-relative overflow-auto">
          <div
            className="list-group list-group-flush"
            style={{ height: "calc(100vh - 190px)", overflowY: "auto" }}
            onScroll={handleTelemedTodayScroll}
            id="TelemedToday-list"
          >
            {conferences.map((c, index) => (
              <ConferenceItem
                key={c.objectId + index}
                conference={c}
                onClick={() => handleClick()}
                openModal={() => handleOpenModal(c)}
              />
            ))}
            <div style={itemCls} 
              className={scheduleTodayHasMore ? "px-2 py-3 mx-0" : "d-none"}>
              <OverlayLoader isLoading={isLoading} />
            </div>
          </div>
          <OverlayPlaceholder
            show={!isLoading && !conferences.length}
            text={(startDate.date).toLocaleDateString() === (new Date()).toLocaleDateString() 
              ? 'No schedules today' 
              : 'No schedules on ' + (startDate.date).toLocaleString('default', { month: 'long' }) + ' ' + (startDate.date).getDate()}
          />
          <OverlayLoader isLoading={isLoading} />
          {modalShown && (
            <ManageEventModal
              method="update"
              dataEvent={selectedConference}
              isOpen={modalShown}
              onClose={() => handleCloseModal()}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const ConferenceItem = ({ conference, onClick, openModal }) => {
  const {
    label,
    dateScheduled,
    chiefComplaint,
    joined = [],
    dateFinished,
    timeEnded,
  } = conference;
  const schedule = util.formatConferenceDate(dateScheduled.iso);
  const currentDate = moment();
  const startDate = moment(dateScheduled.iso);
  const finishedDate = moment((dateFinished || {}).iso || new Date());
  const dayEndTime = moment(finishedDate).endOf("day");
  const endDate = moment((timeEnded || {}).iso || dayEndTime);
  const appendJoinButton = currentDate.isBetween(startDate, endDate)
    ? true
    : false;
  const showJoin = startDate.isBefore(currentDate);

  function openConferenceWindow(conf) {
    const { comments } = conf;
    const split_description = comments.split("Hubchart Link:");
    const link = split_description[split_description.length - 1].replace(
      /\s/g,
      ""
    );

    window.open(link, "_blank");
  }

  return (
    <div
      onClick={(e) => onClick(e)}
      href="#!"
      style={{ border: "0 !important", padding: "0px 10px" }}
      className="border-0 shadow-none mb-0 mx-0 list-group-item-action flex-column align-items-start hover-bypass"
    >
      <div className="d-flex ">
        <div className="mr-2 line-bulb">
          <div class="bulb" />
          <div class="v-line" />
        </div>
        <div className="w-100">
          <span className="tel-time title-hov">
            <small>{schedule.time}</small>
            {/* ({moment(new Date(dateScheduled.iso)).format("L")}) */}
          </span>
          <div className="telemed-card">
            <i
              className="fas fa-chevron-right fa-position"
              onClick={(e) => {
                e.stopPropagation();
                openModal(conference);
              }}
            ></i>
            <div className="d-flex w-100 justify-content-between">
              {label ? (
                <h6 className="mb-0 title-hov">
                  <strong>{label}</strong>
                </h6>
              ) : (
                <h6 className="mb-0 text-grey font-italic">
                  <strong>No title</strong>
                </h6>
              )}
            </div>
            {chiefComplaint ? (
              <span>
                <small>{chiefComplaint}</small>
              </span>
            ) : (
              <span className="text-grey font-italic">
                <small>None</small>
              </span>
            )}
            <div className="tel-control">
              <div />
              {appendJoinButton && (
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    openConferenceWindow(conference);
                  }}
                  disabled={!showJoin && joined.length === 0}
                  className="btn btn-sm btn-success btn-join-call"
                >
                  <i className="fa fa-phone "></i> Join
                </button>
              )}
              {!appendJoinButton && (
                <button
                  type="button"
                  disabled={true}
                  className="btn btn-sm btn-success btn-join-call"
                >
                  <i className="fa fa-phone "></i> Join
                </button>
              )}
              {/* <a
                href="#!"
                type="button"
                style={{ fontSize: "14px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  openModal(conference);
                }}
              >
                View details
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TelemedToday;
