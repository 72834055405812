import React, { useCallback, useEffect, useState, Fragment } from "react";
import moment from "moment";
import Notify from "../../../Common/Notify";
import useProvider from "./useProvider";
import InsuranceApi from "../../../../api/Insurance";
import Loader from "../../../Common/Loader";

const InsuranceForm = ({
  ipa,
  provider,
  updatedAt,
  policyNumber,
  providerIndex,
  setIpa,
  setPolicyNumber,
  setProviderIndex,
  onAddProvider,
  setProvider,
  setLoading,
  planName,
  setPlanName,
  setCoPayment,
  co_payment,
  setPrimaryDoctor,
  primary_doctor,
  providers,
  editMode,
  label
}) => {
  const [addProviderMode, setAddProviderMode] = useState(false);
  const newProvider = useProvider();

  const handleAddProvider = useCallback(async () => {
    // Check if provider already exists
    const existingProvider = providers.find(p => p.name === newProvider.name);

    if (!newProvider.name) return Notify.info("Please type a provider name");
    if (existingProvider) return Notify.info("Provider already exists");

    const newProviderObj = newProvider.getParseObject().toJSON();

    setLoading(true);

    try {
      const savedProvider = await InsuranceApi.addProvider(newProviderObj);

      // Add the new provider to list
      onAddProvider(savedProvider);

      // Set the new provider as selected
      setProvider(savedProvider);

      // and turn off edit mode
      setAddProviderMode(false);

      Notify.success("Insurance added");

      setLoading(false);
    } catch (err) {
      Notify.error("Could not add insurance");

      setLoading(false);
    }
  }, [newProvider.name]);

  return (
    <div>
      <label>{label}</label>

      {!editMode ? (
        <PatientValue
          label="Insurance type"
          iconClass="fas fa-fw fa-credit-card"
          value={provider ? provider.name : ""}
        />
      ) : (
        ""
      )}

      {editMode && !addProviderMode ? (
        <div className="d-flex flex-row">
          <PatientField
            label="Insurance type"
            iconClass="fas fa-fw fa-credit-card"
          >
            <select
              onChange={e => setProviderIndex(e.target.value)}
              value={providerIndex}
              className="form-control"
              id="insuranceType"
            >
              <option value="">Select type</option>
              {providers.map((p, index) => (
                <option key={p.index} value={index}>
                  {p.name}
                </option>
              ))}
            </select>
          </PatientField>
          <button
            style={{
              marginLeft: "10px",
              width: "120px",
              fontSize: "12px",
              height: "32px"
            }}
            className="btn btn-outline-secondary"
            type="button"
            onClick={() => setAddProviderMode(true)}
          >
            Add new
          </button>
        </div>
      ) : (
        ""
      )}

      {editMode && addProviderMode ? (
        <div className="d-flex flex-row">
          <PatientField
            label=""
            placeholder="Enter insurance type"
            onChange={e => newProvider.setName(e.target.value)}
            value={newProvider.name}
            iconClass="fas fa-fw fa-credit-card"
          />
          <button
            className="btn btn-link"
            type="button"
            onClick={() => setAddProviderMode(false)}
          >
            <i className="fas fa-fw fa-times text-danger"></i>
          </button>
          <button
            className="btn btn-link"
            type="button"
            onClick={() => handleAddProvider()}
          >
            <i className="fas fa-fw fa-check text-success"></i>
          </button>
        </div>
      ) : (
        ""
      )}

      {editMode ? (
        <PatientField
          label="Plan name"
          placeholder="Plan name"
          onChange={e => setPlanName(e.target.value)}
          value={planName}
          id="planName"
          iconClass="fas fa-fw fa-book-open"
        />
      ) : (
        <PatientValue
          label="Plan name"
          value={planName}
          iconClass="fas fa-fw fa-book-open"
        />
      )}

      {editMode ? (
        <PatientField
          label="Policy number"
          placeholder="Policy number"
          onChange={e => setPolicyNumber(e.target.value)}
          value={policyNumber}
          iconClass="fas fa-fw fa-suitcase"
        />
      ) : (
        <PatientValue
          label="Policy number"
          value={policyNumber}
          iconClass="fas fa-fw fa-suitcase"
        />
      )}

      {editMode ? (
        <PatientField
          label="IPA/Med group"
          placeholder="IPA/Med group"
          onChange={e => setIpa(e.target.value)}
          value={ipa}
          iconClass="fas fa-fw fa-users"
        />
      ) : (
        <PatientValue
          label="IPA/Med group"
          value={ipa}
          iconClass="fas fa-fw fa-users"
        />
      )}
      {label === "Primary insurance" && (
        <Fragment>
          {editMode ? (
            <PatientField
              label="Co-payment"
              placeholder="Co-payment"
              onChange={e => setCoPayment(e.target.value)}
              value={co_payment}
              iconClass="fas fa-fw fa-credit-card"
            />
          ) : (
            <PatientValue
              label="Co-payment"
              value={co_payment}
              iconClass="fas fa-fw fa-credit-card"
            />
          )}

          {editMode ? (
            <PatientField
              label="Primary Doctor"
              placeholder="Primary Doctor"
              onChange={e => setPrimaryDoctor(e.target.value)}
              value={primary_doctor}
              iconClass="fas fa-fw fa-user"
            />
          ) : (
            <PatientValue
              label="Primary Doctor"
              value={primary_doctor}
              iconClass="fas fa-fw fa-user"
            />
          )}
        </Fragment>
      )}

      {/* <PatientValue
        label="Updated"
        value={moment(updatedAt).format("l")}
        iconClass="fas fa-fw fa-stopwatch"
        style={editMode ? { marginLeft: "10px" } : {}}
      /> */}
    </div>
  );
};

const PatientField = props => {
  const {
    /* For input */
    label,
    placeholder,
    value,
    id = "",

    /* Icon */
    iconClass,
    children
  } = props;

  return (
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <span class="input-group-text hc-input-addon">
          <i className={iconClass}></i>
          &nbsp;
          {label}
        </span>
      </div>
      {/* You can provide custom input */}
      {children ? (
        { ...children }
      ) : (
        <input
          onChange={props.onChange}
          value={value}
          type="text"
          class="form-control"
          placeholder={placeholder}
          id={id}
        />
      )}
    </div>
  );
};

const PatientValue = props => {
  const {
    /* For input */
    label,
    value,

    /* Icon */
    iconClass,
    style = {}
  } = props;

  return (
    <div class="input-group" style={{ boxShadow: "unset" }}>
      <div class="input-group-prepend">
        <span class="input-group-text hc-input-addon">
          <i className={iconClass}></i>
          &nbsp;
          {label}
        </span>
      </div>
      <div className="center-vertical" style={style}>
        {value ? value : <span className="text-blank">---</span>}
      </div>
    </div>
  );
};

export default InsuranceForm;
