import React, { Component } from 'react'
import Lightbox from 'react-images'
import './Lightbox.css'

const $ = window.$;
export class index extends Component {

    constructor() {
        super();
        this.state = {
			currentImage: 0,
		};


        this.closeLightbox = this.closeLightbox.bind(this);
		this.gotoNext = this.gotoNext.bind(this);
		this.gotoPrevious = this.gotoPrevious.bind(this);
		this.gotoImage = this.gotoImage.bind(this);
		this.handleClickImage = this.handleClickImage.bind(this);
    }

    componentDidMount() {
        let index = this.props.files.findIndex((ele) => {
            return ele.url === this.props.currentURL
        })

        this.setState({
            lightboxIsOpen: this.props.isOpen,
            currentImage : index
        })
    }

    closeLightbox() {
        this.setState({
			currentImage: 0,
			lightboxIsOpen: false,
        });

        this.props.modalClose();
    }

    gotoPrevious() {
        this.setState({
			currentImage: this.state.currentImage - 1,
		});
    }

    gotoNext() {
        this.setState({
			currentImage: this.state.currentImage + 1,
		});
    }

    gotoImage(index) {
        this.setState({
			currentImage: index,
		});
    }

    handleClickImage() {
        if (this.state.currentImage === this.props.files.length - 1) return;

		this.gotoNext();
    }

    downloadFile() {
        let url = this.props.files[this.state.currentImage].url;
        if (!url) return;

        // Source: http://pixelscommander.com/en/javascript/javascript-file-download-ignore-content-type/

        //iOS devices do not support downloading. We have to inform user about this.
        if (/(iP)/g.test(navigator.userAgent)) {
            alert('Your device does not support files downloading. Please try again in desktop browser.');
            window.open(url, '_blank');
            return false;
        }

        //If in Chrome or Safari - download via virtual link click
        if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1 || navigator.userAgent.toLowerCase().indexOf('safari') > -1) {
            //Creating new link node.
            var link = document.createElement('a');
            link.href = url;
            link.setAttribute('target','_blank');

            if (link.download !== undefined) {
                //Set HTML5 download attribute. This will prevent file from opening if supported.
                var fileName = url.substring(url.lastIndexOf('/') + 1, url.length);
                link.download = fileName;
            }

            //Dispatching click event.
            if (document.createEvent) {
                var e = document.createEvent('MouseEvents');
                e.initEvent('click', true, true);
                link.dispatchEvent(e);
                return true;
            }
        }

        // Force file download (whether supported by server).
        if (url.indexOf('?') === -1) {
            url += '?download';
        }

        window.open(url, '_blank');
        return true;


    }

    render() {
        const { id, files } = this.props;



        let images = files.map(function(data) {
            return {
                src : data.url
            }
        })

        return (
            <Lightbox
                images={images}
                isOpen={this.props.isOpen}
                onClose={this.closeLightbox}
                backdropClosesModal={true}
                currentImage={this.state.currentImage}
                onClickImage={this.handleClickImage}
                onClickNext={this.gotoNext}
                onClickPrev={this.gotoPrevious}
                onClickThumbnail={this.gotoImage}
                preventScroll={true}
                showThumbnails={true}
                showImageCount={false}
                customControls={[<button key="dlbutton_xksiu" onClick={this.downloadFile.bind(this)} title="Download Image" className="close_1x3s325 close_1x3s325s" style={{width: "auto", color: "white"}}><i style={{marginRight: "5px"}} className="fas fa-download"></i><span style={{fontSize: "14px"}}></span></button>]}
            />
        )
    }
}

export default index
