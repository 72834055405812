import React, { useEffect, Fragment } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import './index.css';

function PhysicalExamComponent({
  physicalExam = {},
  updatePhysicalExam = () => {},
  isDisabled = false,
  templateType = '',
}) {

  function handleOnChange (e) {
    e.preventDefault();
    const { name, value } = e.target;
    updatePhysicalExam(name, value);
  }

  function handleClear (name) {
    updatePhysicalExam(name, '');
  }
  
  useEffect(() => {
    return () => {}
  }, []);

  return (
    <div className="card custom-card">
      <h5 className="card-header ">PHYSICAL EXAM</h5>
      <div className="card-body" style={{ padding: '10px' }}>
        <div className="custom-form-group-container">
          <div className="textarea-container">
            <span>General</span>
            <TextareaAutosize 
              disabled={isDisabled}
              id="general" 
              name="general" 
              type="text" 
              className="form-control custom-textarea" 
              placeholder="General"
              value={physicalExam.general}
              onChange={e => handleOnChange(e)}
              minRows={2} />
            {!isDisabled && (
              <i 
                className="fal fa-times clear-textarea text-danger"
                onClick={() => handleClear('general')} 
              />
            )}
          </div>
          <div className="textarea-container">
            <span>Head</span>
            <TextareaAutosize 
              disabled={isDisabled}
              id="head" 
              name="head" 
              type="text" 
              className="form-control custom-textarea" 
              placeholder="Head"
              value={physicalExam.head}
              onChange={e => handleOnChange(e)}
              minRows={2} />
            {!isDisabled && (
              <i 
                className="fal fa-times clear-textarea text-danger"
                onClick={() => handleClear('head')} 
              />
            )}
          </div>
          <div className="textarea-container">
            <span>Eyes</span>
            <TextareaAutosize 
              disabled={isDisabled}
              id="eyes" 
              name="eyes" 
              type="text" 
              className="form-control custom-textarea" 
              placeholder="Eyes"
              value={physicalExam.eyes}
              onChange={e => handleOnChange(e)}
              minRows={2} />
            {!isDisabled && (
              <i 
                className="fal fa-times clear-textarea text-danger"
                onClick={() => handleClear('eyes')} 
              />
            )}
          </div>
          <div className="textarea-container">
            <span>Ear, Nose, Mouth and Throat</span>
            <TextareaAutosize 
              disabled={isDisabled}
              id="ent" 
              name="ent" 
              type="text" 
              className="form-control custom-textarea" 
              placeholder="Ear, Nose, Mouth and Throat"
              value={physicalExam.ent}
              onChange={e => handleOnChange(e)}
              minRows={2} />
            {!isDisabled && (
              <i 
                className="fal fa-times clear-textarea text-danger"
                onClick={() => handleClear('ent')}
              />
            )}
          </div>
          <div className="textarea-container">
            <span>Neck</span>
            <TextareaAutosize 
              disabled={isDisabled} 
              id="neck" 
              name="neck" 
              type="text" 
              className="form-control custom-textarea" 
              placeholder="Neck"
              value={physicalExam.neck}
              onChange={e => handleOnChange(e)}
              minRows={2} />
            {!isDisabled && (
              <i 
                className="fal fa-times clear-textarea text-danger"
                onClick={() => handleClear('neck')} 
              />
            )}
          </div>
          <div className="textarea-container">
            <span>Chest and Lungs</span>
            <TextareaAutosize 
              disabled={isDisabled} 
              id="chestAndLungs" 
              name="chestAndLungs" 
              type="text" 
              className="form-control custom-textarea" 
              placeholder="Chest and Lungs"
              value={physicalExam.chestAndLungs}
              onChange={e => handleOnChange(e)}
              minRows={2} />
            {!isDisabled && (
              <i 
                className="fal fa-times clear-textarea text-danger"
                onClick={() => handleClear('chestAndLungs')} 
              />
            )}
          </div>
          <div className="textarea-container">
            <span>Breast</span>
            <TextareaAutosize 
              disabled={isDisabled} 
              id="breast" 
              name="breast" 
              type="text" 
              className="form-control custom-textarea" 
              placeholder="Breast"
              value={physicalExam.breast}
              onChange={e => handleOnChange(e)}
              minRows={2} />
            {!isDisabled && (
              <i 
                className="fal fa-times clear-textarea text-danger"
                onClick={() => handleClear('breast')} 
              />
            )}
          </div>
          <div className="textarea-container">
            <span>Cardiovascular</span>
            <TextareaAutosize 
              disabled={isDisabled} 
              id="cardiovascular" 
              name="cardiovascular" 
              type="text" 
              className="form-control custom-textarea" 
              placeholder="Cardiovascular"
              value={physicalExam.cardiovascular}
              onChange={e => handleOnChange(e)}
              minRows={2} />
            {!isDisabled && (
              <i 
                className="fal fa-times clear-textarea text-danger"
                onClick={() => handleClear('cardiovascular')} 
              />
            )}
          </div>
          <div className="textarea-container">
            <span>Abdomen</span>
            <TextareaAutosize 
              disabled={isDisabled} 
              id="abdomen" 
              name="abdomen" 
              type="text" 
              className="form-control custom-textarea" 
              placeholder="Abdomen"
              value={physicalExam.abdomen}
              onChange={e => handleOnChange(e)}
              minRows={2} />
            {!isDisabled && (
              <i 
                className="fal fa-times clear-textarea text-danger"
                onClick={() => handleClear('abdomen')} 
              />
            )}
          </div>
          {/* {templateType === 'default' && ( */}
            <Fragment>
              <div className="textarea-container">
                <span>Back</span>
                <TextareaAutosize 
                  disabled={isDisabled} 
                  id="back" 
                  name="back" 
                  type="text" 
                  className="form-control custom-textarea" 
                  placeholder="Back"
                  value={physicalExam.back}
                  onChange={e => handleOnChange(e)}
                  minRows={2} />
                {!isDisabled && (
                  <i 
                    className="fal fa-times clear-textarea text-danger"
                    onClick={() => handleClear('back')} 
                  />
                )}
              </div>
              <div className="textarea-container">
                <span>Genitourinary</span>
                <TextareaAutosize 
                  disabled={isDisabled} 
                  id="genitourinary" 
                  name="genitourinary" 
                  type="text" 
                  className="form-control custom-textarea" 
                  placeholder="Genitourinary"
                  value={physicalExam.genitourinary}
                  onChange={e => handleOnChange(e)}
                  minRows={2} />
                {!isDisabled && (
                  <i 
                    className="fal fa-times clear-textarea text-danger"
                    onClick={() => handleClear('genitourinary')} 
                  />
                )}
              </div>
              <div className="textarea-container">
                <span>Rectal</span>
                <TextareaAutosize 
                  disabled={isDisabled} 
                  id="rectal" 
                  name="rectal" 
                  type="text" 
                  className="form-control custom-textarea" 
                  placeholder="Rectal"
                  value={physicalExam.rectal}
                  onChange={e => handleOnChange(e)}
                  minRows={2} />
                {!isDisabled && (
                  <i 
                    className="fal fa-times clear-textarea text-danger"
                    onClick={() => handleClear('rectal')} 
                  />
                )}
              </div>
              {/* <div className="textarea-container">
                <span>Musculoskeletal</span>
                <textarea
                  disabled={isDisabled} 
                  id="musculoskeletal" 
                  name="musculoskeletal" 
                  type="text" 
                  className="form-control custom-textarea" 
                  placeholder="Musculoskeletal"
                  value={physicalExam.musculoskeletal}
                  onChange={e => handleOnChange(e)} />
                {!isDisabled && (
                  <i 
                    className="fal fa-times clear-textarea text-danger"
                    onClick={() => handleClear('musculoskeletal')} 
                  />
                )}
              </div> */}
            </Fragment>
          {/* )} */}
          <div className="textarea-container">
            <span>Upper Extremities</span>
            <TextareaAutosize 
              disabled={isDisabled} 
              id="upperExtrimities" 
              name="upperExtrimities" 
              type="text" 
              className="form-control custom-textarea" 
              placeholder="Upper Extremities"
              value={physicalExam.upperExtrimities}
              onChange={e => handleOnChange(e)}
              minRows={2} />
            {!isDisabled && (
              <i 
                className="fal fa-times clear-textarea text-danger"
                onClick={() => handleClear('upperExtrimities')} 
              />
            )}
          </div>
          <div className="textarea-container">
            <span>Lower Extremities</span>
            <TextareaAutosize 
              disabled={isDisabled} 
              id="lowerExtrimities" 
              name="lowerExtrimities" 
              type="text" 
              className="form-control custom-textarea" 
              placeholder="Lower Extremities"
              value={physicalExam.lowerExtrimities}
              onChange={e => handleOnChange(e)}
              minRows={2} />
            {!isDisabled && (
              <i 
                className="fal fa-times clear-textarea text-danger"
                onClick={() => handleClear('lowerExtrimities')} 
              />
            )}
          </div>
          {/* {templateType === 'default' && ( */}
            <div className="textarea-container">
              <span>Skin</span>
              <TextareaAutosize 
                disabled={isDisabled} 
                id="skin" 
                name="skin" 
                type="text" 
                className="form-control custom-textarea" 
                placeholder="Skin"
                value={physicalExam.skin}
                onChange={e => handleOnChange(e)}
                minRows={2} />
              {!isDisabled && (
                <i 
                  className="fal fa-times clear-textarea text-danger"
                  onClick={() => handleClear('skin')} 
                />
              )}
            </div>
          {/* )} */}
          <div className="textarea-container">
            <span>Neurologic</span>
            <TextareaAutosize 
              disabled={isDisabled} 
              id="neurologic" 
              name="neurologic" 
              type="text" 
              className="form-control custom-textarea" 
              placeholder="Neurologic"
              value={physicalExam.neurologic}
              onChange={e => handleOnChange(e)}
              minRows={2} />
            {!isDisabled && (
              <i 
                className="fal fa-times clear-textarea text-danger"
                onClick={() => handleClear('neurologic')} 
              />
            )}
          </div>
          <div className="textarea-container">
            <span>Psychiatric Eval</span>
            <TextareaAutosize 
              disabled={isDisabled} 
              id="psychiatrist" 
              name="psychiatrist" 
              type="text" 
              className="form-control custom-textarea" 
              placeholder="Psychiatric Eval"
              value={physicalExam.psychiatrist}
              onChange={e => handleOnChange(e)}
              minRows={2} />
            {!isDisabled && (
              <i 
                className="fal fa-times clear-textarea text-danger"
                onClick={() => handleClear('psychiatrist')} 
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhysicalExamComponent;
