import React, { useCallback, useEffect, useState } from 'react';
import Parse from 'parse';

const useServiceLocation = (serviceLocation = {}) => {
  
  const [name, setName] = useState('');

  const getParseObject = useCallback(() => {
    
    const ServiceLocation = Parse.Object.extend('ServiceLocation');

    const parseObject = new ServiceLocation({
      objectId: serviceLocation.objectId,
      name,
    });

    debugger

    return parseObject;

  }, [name, serviceLocation.objectId]);

  const onChangeName = name => {
    setName(name);
  }

  useEffect(() => {
    setName(serviceLocation.name);
  }, [serviceLocation.name]);

  return {
    name,
    onChangeName,
    setName,
    getParseObject,
  }
}

export default useServiceLocation;
