import React from 'react';
import { OutPortal } from 'react-reverse-portal';

const TileLayout = ({
  localParticipantNode,
  remoteParticipantNodes,
  dominantSpeaker,
}) => {

  return (
    <div className="video-container">
      <div className="video-item m-1">
        <OutPortal node={localParticipantNode} />
      </div>

      {remoteParticipantNodes.map(({participant, node}) => 
        <div className={getClassName(participant, dominantSpeaker)} key={participant.identity}>
          <OutPortal node={node} />
        </div>
      )}
    </div>
  );
}

function getClassName(participant, dominantSpeaker) {
  return participant === dominantSpeaker ?
    'video-item m-1 dominant-speaker' :
    'video-item m-1';
}

export default TileLayout;
