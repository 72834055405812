import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setConferenceTab } from '../../../../actions/ConferenceAction';
import './index.css';

const ConferenceNav = (props) => {

    const tab = useSelector(state => state.conference.activeConferenceTab);
    const dispatch = useDispatch();
    
    const tabs = {
        LOGS: 'LOGS',
        SCHEDULES: 'SCHEDULES',
        REQUESTS: 'REQUESTS',
    };

    const activeClass = 'hc-nav-link active';
    const inactiveClass = 'hc-nav-link';

    function handleClick(newTab) {
        
        if (newTab === tab) return;

        setConferenceTab(newTab)(dispatch);

        props.onTabSelect(newTab);
    }

    return (
        <div className="message-nav">
            <ul className="nav nav-pills nav-justified hc-nav-pills">
                <li className="nav-item">
                    <a onClick={() => handleClick(tabs.LOGS)} className={tab === tabs.LOGS ? activeClass : inactiveClass} href="#">Call Logs</a>
                </li>
                <li className="nav-item">
                    <a onClick={() => handleClick(tabs.SCHEDULES)} className={tab === tabs.SCHEDULES ? activeClass : inactiveClass} href="#">Schedules</a>
                </li>
                {/* <li className="nav-item">
                    <a onClick={() => handleClick(tabs.REQUESTS)} className={tab === tabs.REQUESTS ? activeClass : inactiveClass} href="#">Requests</a>
                </li> */}
            </ul>
        </div>
    );
}

export default ConferenceNav;
