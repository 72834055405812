import React, { useEffect, useState, Fragment } from "react";
import Parse from "parse";
import moment from "moment";
import debounce from "lodash.debounce";
import TextareaAutosize from "react-textarea-autosize";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import { toast } from "react-toastify";
import { parseAction } from "../../../../actions/REST";
import {
  getDiagnosticStudies,
  getReferrals,
  getSupplies,
} from "../../../../actions/ProgressNotesAction";
import {
  randomID,
  sendNewMessage,
  initializePubNub,
} from "../../../../actions/ThreadAction";
import AddPrescriptionModal from "../../../Conversation/Setting/PrescriptionModal/AddPrescriptionModal";
import AlertModal from "../../../Conversation/AlertModal";
import {
  defaultSelectedDrug,
  quantifiables,
} from "../../../Conversation/Setting/PrescriptionModal/config";

import TabLoader from "../../../Common/TabLoader";
import config from "../../../../config";
import "./index.css";

const $ = window.$;

function Plan({
  labs = {
    prescription: "",
    diagnosticStudies: "",
    referrals: "",
    medicalSupplies: "",
  },
  plansArray = [],
  openPrescription = () => {},
  updatePlans = () => {},
  updatePlansCheckbox = () => {},
  setPrescriptionToText = () => {},
  isDisabled = false,
  isPatient,
  patientName = "",
  patientDob = "",
  guests = [],
  options = [],
  thread = {},
  toList = false,
}) {
  const dispatch = useDispatch();
  const initDrugs = [
    { ...defaultSelectedDrug },
    { ...defaultSelectedDrug },
    { ...defaultSelectedDrug },
    { ...defaultSelectedDrug },
    { ...defaultSelectedDrug },
  ];
  const { diagnosticStudies, referrals, medicalSupplies } = useSelector(
    mySelector,
    shallowEqual
  );
  const [medicationModalShown, setMedicationModalShown] = useState(false);
  const [selectedPill, setSelectedPill] = useState("free_text");
  const [selectedDrug, setSelectedDrug] = useState({ ...defaultSelectedDrug });
  const [selectedDrugs, setSelectedDrugs] = useState([...initDrugs]);
  const [isQuantifiable, setIsQuantifiable] = useState(false);
  const [selectedRxList, setSelectedRxList] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [erxListRestructured, setErxListReStructured] = useState([]);
  const [erxList, setErxList] = useState([]);
  const [keyWord, setKeyWord] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadingPrescription, setIsLoadingPrescription] = useState(false);
  const [isEndOfList, setIsEndOfList] = useState(false);
  const [alertModalShown, setAlertModalShown] = useState(false);
  const [isSingleSend, setIsSingleSend] = useState(false);
  const [bypassList, setBypassList] = useState(null);
  const [selectedLabItem, setSelectedLabItem] = useState("");
  const filteredGuests = guests.filter(
    (item) => item.objectId !== Parse.User.current().id
  );
  const filteredOptions = options.filter(
    (item) => item.objectId !== Parse.User.current().id
  );
  const postStyle = {
    height: "22px",
    fontSize: "11px",
    borderRadius: "5px",
    width: "55px",
    position: "absolute",
    padding: "2px",
    right: "5px",
    top: "5px",
    fontWeight: "bold",
  };

  function handleOnChange(e, data) {
    e.preventDefault();
    const { name, value, checked } = e.target;

    if (data) {
      updatePlansCheckbox(data, checked);
    } else {
      updatePlans(name, value);
    }
  }

  function handleSelected(e, str) {
    if (e.length > 0) {
      const comma = labs[str] ? "; " : "";
      const val = `${labs[str] || ""}${comma + e[0].name}`;
      updatePlans(str, val);
    }
  }

  function handleMedicationChange(e) {
    const { value, checked, name } = e.target;
    const newDrug = {
      ...selectedDrug,
      prn_reason: name === "prn" ? "" : selectedDrug.prn_reason,
      [name]: name === "prn" ? checked : value,
    };
    const quantifiedData = calculateDrugQuantity(newDrug);
    setSelectedDrug(quantifiedData);
  }

  function calculateDrugQuantity(drug) {
    const dosageForm = drug.dosage_form.length > 0 ? drug.dosage_form[0] : "";
    const amount = parseInt(drug.amount || 0);
    const frequency = drug.frequency.length > 0 ? drug.frequency[0].val : 1;
    const duration = parseInt(drug.duration || 0);
    const durationStrInt =
      drug.durationStr.length > 0 ? drug.durationStr[0].val : 1;
    const quantifiable = quantifiables.includes(dosageForm);
    let quantity = 0;

    if (quantifiable) {
      const duration_computed = duration * durationStrInt;
      const frequency_computed = frequency * duration_computed;
      quantity = amount * frequency_computed;
    } else {
      quantity = 0;
    }

    const data = {
      ...drug,
      quantity,
    };
    return data;
  }

  function handleChangeDrugDetails(selected, str) {
    let newSelected = [];
    let isQuantifiable = false;

    if (selected.length > 0) {
      newSelected = selected[0].customOption
        ? [selected[0].label || selected[0].text]
        : selected;
    }

    if (str === "dosage_form" && newSelected.length > 0) {
      isQuantifiable = quantifiables.includes(newSelected[0]) ? true : false;
      setIsQuantifiable(isQuantifiable);
    }

    const data = {
      ...selectedDrug,
      [str]: newSelected,
    };
    const quantifiedData = calculateDrugQuantity(data);
    setSelectedDrug(quantifiedData);
  }

  function handleChangeTextAreaDetails(e, i) {
    const { value } = e.target;
    let newArr = [...selectedDrugs];
    newArr[i].free_text = value;

    setSelectedDrugs([...newArr]);
  }

  function closeErxModal() {
    $("#erxMedicationModal").modal("hide");
    clearFields();
  }

  function clearFields() {
    $("#durationStr").prop("selectedIndex", 0);
  }

  function addTextAreaField() {
    setSelectedDrugs([
      {
        ...defaultSelectedDrug,
        free_text: "",
      },
      ...selectedDrugs,
    ]);
  }

  function removeTextAreaField(i) {
    let newArr = selectedDrugs;
    newArr.splice(i, 1);
    setSelectedDrugs([...newArr]);
  }

  function saveMedication() {
    let new_rx = [];
    const filteredSelectedDrugs = filterSelectedDrugs(selectedDrugs);

    let rx =
      filteredSelectedDrugs.length === 0
        ? {
            ...selectedDrug,
            direction:
              selectedDrug.direction.length > 0
                ? selectedDrug.direction[0]
                : [],
            dosage_form:
              selectedDrug.dosage_form.length > 0
                ? selectedDrug.dosage_form[0]
                : [],
            route:
              selectedDrug.route.length > 0
                ? selectedDrug.route[0].val || selectedDrug.route[0]
                : [],
            route_obj:
              selectedDrug.route.length > 0 ? selectedDrug.route[0] : [],
            location:
              selectedDrug.location.length > 0
                ? selectedDrug.location[0].val || selectedDrug.location[0]
                : [],
            location_obj:
              selectedDrug.location.length > 0 ? selectedDrug.location[0] : [],
            frequency:
              selectedDrug.frequency.length > 0
                ? selectedDrug.frequency[0].text
                : [],
            frequency_obj:
              selectedDrug.frequency.length > 0
                ? selectedDrug.frequency[0]
                : [],
            durationStr:
              selectedDrug.durationStr.length > 0
                ? selectedDrug.durationStr[0].text
                : [],
            duration_obj:
              selectedDrug.durationStr.length > 0
                ? selectedDrug.durationStr[0]
                : [],
          }
        : {};
    new_rx = [...selectedRxList];
    new_rx.push(...filteredSelectedDrugs);

    if (Object.keys(rx).length > 0) {
      new_rx.push(rx);
    }

    setSelectedRxList(new_rx);
    setPrescriptionToText(new_rx);
    setMedicationModalShown(medicationModalShown);
    setSelectedDrugs([...initDrugs]);

    closeErxModal();
  }

  function filterSelectedDrugs(selectedDrugs = []) {
    return selectedDrugs.filter((item) => item.free_text);
  }

  function openDocuments() {
    const { activeThread = {} } = this.props;
    const imageUrl = this.getImageUrl(activeThread);
    const threadName = this.getThreadName(activeThread);
    const filesUrl = [
      window.location.origin,
      "/files",
      "/" + activeThread.threadId,
      "?imageUrl=" + window.encodeURIComponent(imageUrl),
      "&threadName=" + window.encodeURIComponent(threadName),
    ].join("");
    window.open(filesUrl, "Hubchart Files");
  }

  function handleOpenMedicationModal() {
    setMedicationModalShown(true);
    setTimeout(() => {
      $("#erxMedicationModal").modal({
        backdrop: "static",
        keyboard: false,
      });
      $("#erxMedicationModal").modal("show");
    }, 100);
  }

  function selectErx(selected) {
    const duplicates = checkDuplicates(selected[0]);
    if (duplicates > 0) return;
    setSelectedPill("labeled_entry");
    setSelectedDrug({
      ...selected[0],
      ...defaultSelectedDrug,
      displayName: selected[0].displayName,
      dateCreated: moment(new Date()).format("MM/DD/YYYY"),
    });
    setSelectedOption([]);
    handleOpenMedicationModal();
  }

  function checkDuplicates(selected) {
    const list = selectedRxList.filter(
      (item) => item.displayName === selected.displayName
    );
    return list.length;
  }

  function fetchErxList(kWord, cPage = 1) {
    setIsLoadingPrescription(true);
    parseAction(
      "get",
      `${config.PATIENT_URL}/drugs?search=${kWord}&page=${cPage}&size=10`
    )
      .then((result) => {
        const { items, page, total } = result.data;
        const finalList = page === 1 ? items : [...erxList, ...items];
        const endOfList = total === finalList.length ? true : false;
        const erxListRestructuredFinal = page === 1 ? [] : erxListRestructured;
        const restructured = reStructureData(erxListRestructuredFinal, items);

        setErxList(finalList);
        setKeyWord(kWord);
        setCurrentPage(page);
        setErxListReStructured(restructured);
        setIsLoadingPrescription(false);
        setIsEndOfList(endOfList);
      })
      .catch((error) => {
        console.error(error);
        setIsLoadingPrescription(false);
      });
  }

  function reStructureData(oldData = [], newData) {
    const drugArray = [];

    for (let item_i = 0; item_i < newData.length; item_i++) {
      for (
        let product_i = 0;
        product_i < newData[item_i].products.length;
        product_i++
      ) {
        drugArray.push({
          _id: newData[item_i]._id,
          application_number: newData[item_i].application_number,
          sponsor_name: newData[item_i].sponsor_name,
          marketing_status:
            newData[item_i].products[product_i].marketing_status,
          route: newData[item_i].products[product_i].route,
          reference_standard:
            newData[item_i].products[product_i].reference_standard,
          brand_name: newData[item_i].products[product_i].brand_name,
          reference_drug: newData[item_i].products[product_i].reference_drug,
          product_number: newData[item_i].products[product_i].product_number,
          dosage_form: newData[item_i].products[product_i].dosage_form,
          strength: combineStrength(
            newData[item_i].products[product_i].active_ingredients
          ),
          displayName: `${
            newData[item_i].products[product_i].brand_name
          } ${combineStrength(
            newData[item_i].products[product_i].active_ingredients
          )} ${newData[item_i].products[product_i].route} ${
            newData[item_i].products[product_i].dosage_form
          }`,
        });
        for (
          let ai_i = 0;
          ai_i < newData[item_i].products[product_i].active_ingredients.length;
          ai_i++
        ) {}
      }
    }

    const combindeData = [...oldData, ...drugArray];
    const finalDrugArray = Array.from(
      new Set(combindeData.map((a) => a.displayName))
    ).map((displayName) =>
      combindeData.find((a) => a.displayName === displayName)
    );

    return finalDrugArray;
  }

  function combineStrength(activeIngredients) {
    if (activeIngredients.length > 3) return "";
    if (activeIngredients.length === 1) return activeIngredients[0].strength;
    let sth = "";

    const mappedIngredients = activeIngredients.map((item) => {
      let str_formatted = item.strength.includes("/")
        ? item.strength.replace(/[+-]?\d+(\.\d+)?/, "-")
        : item.strength.replace(/[0-9]+(?!.*[0-9])/gi, "-");

      const splitted = str_formatted.split("-");
      sth =
        typeof splitted === "undefined"
          ? ""
          : trim(splitted[splitted.length - 1]);

      function trim(str) {
        const removed_op = str.replace("(", "");
        const removed_cp = removed_op.replace(")", "");
        return removed_cp;
      }

      const val =
        item.strength === "N/A"
          ? ""
          : parseFloat(item.strength.match(/[-+]?([0-9]*\.[0-9]+|[0-9]+)/));

      return val;
    });

    return `${mappedIngredients.join("/")} ${sth}`;
  }

  function handleFetchErxPageChange() {
    fetchErxList(keyWord, currentPage + 1);
  }

  function handleAlertClose() {
    setAlertModalShown(false);
  }

  function handleAllLabData(labsData) {
    const dsText = labsData.diagnosticStudies
      ? `DIAGNOSTIC STUDIES \n${labsData.diagnosticStudies}\n\n`
      : "";
    const rText = labsData.referrals
      ? `REFERRALS \n${labsData.referrals}\n\n`
      : "";
    const msText = labsData.medicalSupplies
      ? `MEDICAL SUPPLIES \n${labsData.medicalSupplies}\n\n`
      : "";
    const pText = labsData.prescription
      ? `PRESCRIPTIONS \n${labsData.prescription}`
      : "";

    return `PATIENT NAME: ${patientName}\nDOB: ${patientDob}\n\n${dsText}${rText}${msText}${pText}`;
  }

  function handleSingleLabData(labsData) {
    const fullText = `${
      selectedLabItem === "medicalSupplies"
        ? "MEDICAL SUPPLIES"
        : selectedLabItem === "diagnosticStudies"
        ? "DIAGNOSTIC STUDIES"
        : selectedLabItem === "referrals"
        ? "REFERRALS"
        : selectedLabItem === "prescription"
        ? "PRESCRIPTIONS"
        : ""
    } \n${labsData[selectedLabItem]}\n\n`;

    return `PATIENT NAME: ${patientName}\nDOB: ${patientDob}\n\n${fullText}`;
  }

  function handleAlertSubmit(attArray = []) {
    const attNames = attArray.map((att) => att.firstName);
    let fullText;

    if (isSingleSend) {
      fullText = handleSingleLabData(labs);
    } else {
      fullText = handleAllLabData(labs);
    }

    createNewMessage(thread, fullText, attArray, attNames);
    setAlertModalShown(false);
  }

  function createNewMessage(data, text, attentionArray, attNames) {
    let newThread = {
      senderName: "",
      senderObjectId: "",
      threadDetail: "",
      threadType: "group",
      unreadMessageCount: 0,
      threadId: data.threadId,
      objectId: data.threadId,
      groupName: data.groupName,
      groupType: data.groupType,
    };

    createNewMessageThread(newThread, text, attentionArray, attNames);
  }

  function createNewMessageThread(
    newThread,
    text,
    attentionArray,
    attNames = []
  ) {
    const date = new Date();
    let newMessage = {
      text: text.trim(),
      originalMessageDate: date,
      threadId: newThread.threadId,
      user: {
        __type: "Pointer",
        className: "_User",
        objectId: Parse.User.current().id,
      },
      objectId: randomID(),
      threadType: "group",
      newMessageStatus: "sending",
      createdAt: date,
      circle: {
        __type: "Pointer",
        className: "Circle",
        objectId: newThread.threadId,
      },
      adhocGroupId: newThread.threadId,
      attention: attentionArray,
      attentionName: attNames,
      priorityLevel: -1
    };

    sendNewMessage(
      newMessage,
      newThread
    )(dispatch).then((res) => {
      if (res.objectId) {
        handleSendOrdersSuccess();
        setIsSingleSend(false);
      }
    });
  }

  function handleSendOrdersSuccess() {
    const content = (
      <span>
        <i className="fas fa-check-circle mr-1"></i>
        Orders sent to hub.
      </span>
    );
    toast.success(content, {
      position: "top-right",
      className: "toast-base-main",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
      pauseOnHover: true,
    });
  }

  function handleBypassList() {
    initializePubNub().then((pubnub) => {
      if (pubnub) {
        pubnub.hereNow({ channels: [`group_${thread.threadId}`] }, function(
          status,
          response
        ) {
          setBypassList(response);
        });
      }
    });
  }

  useEffect(() => {
    $("#erxMedicationModal").on("hidden.bs.modal", function() {
      setMedicationModalShown(false);
      clearFields();
    });

    $(".checkbox-menu").on("change", "input[type='checkbox']", function() {
      $(this)
        .closest("li")
        .toggleClass("active", this.checked);
    });

    $(document).on("click", ".allow-focus", function(e) {
      e.stopPropagation();
    });

    getDiagnosticStudies()(dispatch);
    getReferrals()(dispatch);
    // getSupplies()(dispatch);
    fetchErxList("");
    handleBypassList();
    return () => {};
  }, []);

  function renderPlansList(array) {
    return array.map((item) => (
      <div className="card-dropdown-list" key={item.title}>
        <span>{item.title}</span>
        {item.checklist.map((listItem, i) => (
          <li key={listItem.name + i}>
            <label className="plan-label-flex">
              <input
                key={listItem.isChecked}
                type="checkbox"
                name="planItem"
                checked={listItem.isChecked}
                onChange={(e) => handleOnChange(e, listItem)}
              />
              <span>{listItem.name}</span>
            </label>
          </li>
        ))}
      </div>
    ));
  }

  const debouncedFetchErxList = debounce(fetchErxList, 500);

  return (
    <Fragment>
      <div className="card custom-card">
        <h5 className="card-header">ORDERS</h5>
        <div className="card-body" style={{ padding: "10px" }}>
          {!isPatient && !toList && (
            <div className="plan-search-container">
              <Typeahead
                id="prescription-typeahead"
                placeholder="Search Rx here"
                onChange={(sel) => selectErx(sel)}
                onInputChange={(e) => debouncedFetchErxList(e)}
                options={erxListRestructured}
                maxResults={999999999}
                labelKey={"displayName"}
                filterBy={() => true}
                selected={selectedOption}
                renderMenu={(results, menuProps) => (
                  <Menu {...menuProps}>
                    {results.map((result, index) => (
                      <MenuItem
                        key={result._id + index}
                        option={result}
                        position={index}
                      >
                        {`${result.displayName}`}
                      </MenuItem>
                    ))}
                    <TabLoader isLoading={isLoadingPrescription} />
                    <div className="load-more">
                      {!isEndOfList && (
                        <button
                          type="button"
                          className="btn btn-block btn-light btn-sm"
                          onClick={() => handleFetchErxPageChange()}
                        >
                          Load More
                        </button>
                      )}
                      {isEndOfList && <span>- End of List -</span>}
                    </div>
                  </Menu>
                )}
              />
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                style={{
                  height: "28px",
                  fontSize: "12px",
                  borderRadius: "15px",
                }}
                onClick={() => {
                  setSelectedPill("free_text");
                  handleOpenMedicationModal();
                }}
              >
                Manual Entry
              </button>
            </div>
          )}
          <div className="plan-inputs-container">
            <div className="custom-form-group">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                style={{
                  height: "28px",
                  fontSize: "12px",
                  borderRadius: "15px",
                }}
                disabled={isDisabled}
                onClick={() => openPrescription()}
              >
                Add Rx
              </button>
            </div>
            {isPatient &&
              (labs.diagnosticStudies ||
                labs.referrals ||
                labs.prescription ||
                labs.medicalSupplies) && (
                <button
                  type="button"
                  className="btn btn-warning btn-sm"
                  style={{
                    marginLeft: "auto",
                    marginRight: "5px",
                    height: "28px",
                    fontSize: "11px",
                    borderRadius: "20px",
                    width: "125px",
                    fontWeight: "bold",
                  }}
                  disabled={isDisabled}
                  onClick={() => setAlertModalShown(true)}
                >
                  <i className="fas fa-at"></i> Post Orders
                </button>
              )}
          </div>
          <div className="plan-textarea-container">
            {isPatient && labs.prescription && (
              <button
                type="button"
                className="btn btn-warning btn-sm"
                style={postStyle}
                disabled={isDisabled}
                onClick={() => {
                  setIsSingleSend(true);
                  setSelectedLabItem("prescription");
                  setAlertModalShown(true);
                }}
              >
                <i className="fas fa-at"></i> Post
              </button>
            )}
            <TextareaAutosize
              id="prescription"
              name="prescription"
              type="text"
              className="form-control custom-textarea"
              placeholder="Prescription"
              value={labs.prescription}
              onChange={(e) => handleOnChange(e)}
              disabled={isDisabled}
              minRows={2}
            />
          </div>
          <div className="plan-inputs-container">
            <div className="custom-form-group cust-typeahead">
              <Typeahead
                id="diagTypeAhead"
                labelKey="name"
                options={diagnosticStudies}
                placeholder="Diagnostic Studies"
                onChange={(e) => handleSelected(e, "diagnosticStudies")}
                filterBy={["name"]}
                inputProps={{
                  disabled: isDisabled,
                }}
                selected={[]}
              />
            </div>
            {!isDisabled && (
              <button
                className="btn btn-light btn-sm dropdown-toggle"
                type="button"
                id="plansId"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
                disabled={isDisabled}
              >
                <i className="fas fa-list fa-fw"></i>
              </button>
            )}
            <div
              className="dropdown-menu checkbox-menu allow-focus cust-width"
              aria-labelledby="plansId"
            >
              <div className="dropdown-title">
                <h3>Laboratory</h3>
              </div>
              <div className="dropdown-flex">{renderPlansList(plansArray)}</div>
            </div>
          </div>
          <div className="plan-textarea-container">
            {isPatient && labs.diagnosticStudies && (
              <button
                type="button"
                className="btn btn-warning btn-sm"
                style={postStyle}
                disabled={isDisabled}
                onClick={() => {
                  setIsSingleSend(true);
                  setSelectedLabItem("diagnosticStudies");
                  setAlertModalShown(true);
                }}
              >
                <i className="fas fa-at"></i> Post
              </button>
            )}
            <TextareaAutosize
              id="diagnosticStudies"
              name="diagnosticStudies"
              type="text"
              className="form-control custom-textarea"
              placeholder="Diagnostic Studies"
              value={labs.diagnosticStudies}
              onChange={(e) => handleOnChange(e)}
              disabled={isDisabled}
              minRows={2}
            />
          </div>
          <div className="plan-inputs-container">
            <div className="custom-form-group cust-typeahead">
              <Typeahead
                dropup={true}
                id="diagTypeAhead"
                labelKey="name"
                options={referrals}
                onChange={(e) => handleSelected(e, "referrals")}
                placeholder="Referrals/Consults"
                filterBy={["name"]}
                selected={[]}
                inputProps={{
                  disabled: isDisabled,
                }}
              />
            </div>
          </div>
          <div className="plan-textarea-container">
            {isPatient && labs.referrals && (
              <button
                type="button"
                className="btn btn-warning btn-sm"
                style={postStyle}
                disabled={isDisabled}
                onClick={() => {
                  setIsSingleSend(true);
                  setSelectedLabItem("referrals");
                  setAlertModalShown(true);
                }}
              >
                <i className="fas fa-at"></i> Post
              </button>
            )}
            <TextareaAutosize
              id="referrals"
              name="referrals"
              type="text"
              className="form-control custom-textarea"
              placeholder="Referrals/Consults"
              value={labs.referrals}
              onChange={(e) => handleOnChange(e)}
              disabled={isDisabled}
              minRows={3}
            />
          </div>
          <div className="plan-inputs-container">
            <div className="custom-form-group cust-typeahead">
              <Typeahead
                dropup={true}
                id="medTypeAhead"
                labelKey="name"
                options={medicalSupplies}
                onChange={(e) => handleSelected(e, "medicalSupplies")}
                placeholder="Medical Supplies"
                filterBy={["name"]}
                selected={[]}
                inputProps={{
                  disabled: isDisabled,
                }}
              />
            </div>
          </div>
          <div className="plan-textarea-container">
            {isPatient && labs.medicalSupplies && (
              <button
                type="button"
                className="btn btn-warning btn-sm"
                style={postStyle}
                disabled={isDisabled}
                onClick={() => {
                  setIsSingleSend(true);
                  setSelectedLabItem("medicalSupplies");
                  setAlertModalShown(true);
                }}
              >
                <i className="fas fa-at"></i> Post
              </button>
            )}
            <TextareaAutosize
              id="medicalSupplies"
              name="medicalSupplies"
              type="text"
              className="form-control custom-textarea"
              placeholder="Medical Supplies"
              value={labs.medicalSupplies}
              onChange={(e) => handleOnChange(e)}
              disabled={isDisabled}
              minRows={3}
            />
          </div>
        </div>
      </div>
      {medicationModalShown && (
        <AddPrescriptionModal
          showPill={false}
          selectedPill={selectedPill}
          handleMedicationChange={handleMedicationChange}
          handleChangeDrugDetails={handleChangeDrugDetails}
          handleChangeTextAreaDetails={handleChangeTextAreaDetails}
          handleCloseErxModal={closeErxModal}
          addTextAreaField={addTextAreaField}
          removeTextAreaField={removeTextAreaField}
          saveMedication={saveMedication}
          openDocuments={openDocuments}
          selectedDrug={selectedDrug}
          selectedDrugs={selectedDrugs}
          medicationModalShown={medicationModalShown}
          method={"create"}
        />
      )}
      {alertModalShown && (
        <AlertModal
          guests={filteredGuests}
          options={filteredOptions}
          isOpen={alertModalShown}
          bypassList={bypassList}
          onClose={handleAlertClose}
          onSubmit={handleAlertSubmit}
          threadId={thread.threadId}
          threadName={thread.groupName}
        />
      )}
    </Fragment>
  );
}

const mySelector = (state) => ({
  diagnosticStudies: state.notes.diagnosticStudies,
  referrals: state.notes.referrals,
  medicalSupplies: state.notes.medicalSupplies,
});

export default Plan;
