import React, { useMemo } from "react";

const defaultStyles = {
  borderRadius: "0",
  fontSize: "14px"
};

const defaultClassNames = ["shadow-sm", "border-0"];

export const Input = props => {
  const classNames = useMemo(() => {
    return getClassNames(defaultClassNames, props.className);
  }, [props.className]);

  return <input {...props} className={classNames} style={defaultStyles} />;
};

export const Select = props => {
  const classNames = useMemo(() => {
    return getClassNames(defaultClassNames, props.className);
  }, [props.className]);

  return (
    <select {...props} className={classNames} style={defaultStyles}>
      {props.children}
    </select>
  );
};

export const Label = props => {
  const styles = {
    fontSize: "12px",
    whiteSpace: "pre-wrap"
  };

  return (
    <label className="text-primary mb-0" style={styles}>
      {props.children}
    </label>
  );
};

export const Spinner = props => {
  const defaultClassNames = ["spinner-border"];

  const classNames = useMemo(() => {
    return getClassNames(defaultClassNames, props.className);
  }, [props.className]);

  return (
    <div className={classNames} role="status">
      <span class="sr-only">Loading...</span>
    </div>
  );
};

export const SearchInput = props => {
  const classNames = useMemo(() => {
    return getClassNames(defaultClassNames, props.className);
  }, [props.className]);

  return <input {...props} className={classNames} style={defaultStyles} />;
};

export const Button = props => {
  const classNames = useMemo(() => {
    return getClassNames(defaultClassNames, props.className);
  }, [props.className]);

  return (
    <button {...props} className={classNames} style={defaultStyles}>
      {props.children}
    </button>
  );
};

function getClassNames(defaultClassNames, className) {
  return [...defaultClassNames, className].join(" ");
}

export default {
  Input,
  Select,
  Label,
  Spinner,
  SearchInput,
  Button
};
