import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import OverlayLoader from "../../Common/OverlayLoader";
import { getCirclesWithTodos as getCircles } from "../../../actions/ThreadAction";
import RecentImage from "../../Common/RecentImage";
import { NavLink } from "react-router-dom";
import OverlayPlaceholder from "../../Common/OverlayPlaceholder";

const TodosTab = ({ typeValue = "" }) => {
  const dispatch = useDispatch();

  const [circles, setCircles] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    dispatch(getCircles())
      .then(circles => {
        setCircles(circles);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <TabWrapper>
      <TodoList typeValue={typeValue} items={circles} />
      <OverlayLoader background="#f7f8fa" isLoading={loading} />
      <OverlayPlaceholder
        background="#f7f8fa"
        show={!circles.length && !loading}
        text="You have no todos"
      />
    </TabWrapper>
  );
};

const TabWrapper = ({ children }) => {
  const styles = {
    position: "relative",
    paddingLeft: "20px",
    paddingRight: "20px",
    height: "100%"
  };

  return (
    <div style={styles} className="mb-0">
      {children}
    </div>
  );
};

const TodoList = ({ items = [], typeValue }) => {
  const styles = {
    height: "inherit",
    width: "inherit"
  };

  const getName = item => {
    const name = [item.firstName, item.lastName].join(" ");

    return name.toLowerCase();
  };

  const filtered = useMemo(() => {
    if (!typeValue) return items;

    return items.filter(item => {
      return getName(item).includes(typeValue.toLowerCase());
    });
  }, [typeValue, items]);

  return (
    <div style={styles}>
      {filtered.map(item => (
        <Item circle={item} />
      ))}
    </div>
  );
};

const Item = ({ circle = {} }) => {
  const styles = {
    minHeight: "65px",
    userSelect: "none"
  };

  const imageUrl = useMemo(() => {
    return circle.image ? circle.image.url : "";
  }, [circle]);

  const idPath = useMemo(() => {
    return "/u/" + circle.objectId;
  }, [circle]);

  return (
    <NavLink
      to={idPath}
      activeClassName="active"
      className="border-item layout-item bg-white text-decoration-none mb-2"
      style={styles}
    >
      <RecentImage url={imageUrl} />
      <div className="text-truncate ml-2">
        {circle.lastName}, &nbsp;
        {circle.firstName}
      </div>
    </NavLink>
  );
};

export default TodosTab;
