import React, { Component, Fragment } from "react";
import "../../../node_modules/emoji-mart/css/emoji-mart.css";
import AudioModal from "./Audio/AudioModal";
import CameraModal from "./Camera/CameraModal";
import ReminderModal from "./Reminder/ReminderModal";
import { connect } from "react-redux";
import { setEmojiShown, showTagsModal } from "../../actions/ThreadAction";
import OrdersModal from "../Pages/ProgressNotes/OrdersModal";
const $ = window.$;

export class ConversationMenu extends Component {
  constructor() {
    super();
    this.state = {
      openPopover: false,
      openAudioRecord: false,
      openCamera: false,
      ordersModalShown: false,
      openReminder: false,
    };

    this.openAudioRecord = this.openAudioRecord.bind(this);
    this.openCamera = this.openCamera.bind(this);
    this.handleSetTags = this.handleSetTags.bind(this);
    this.openProgressNotes = this.openProgressNotes.bind(this);
    this.handleOrderModalShown = this.handleOrderModalShown.bind(this);
    this.handleDisabled = this.handleDisabled.bind(this);
    this.openReminder = this.openReminder.bind(this);
  }

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  openAudioRecord() {
    this.setState({
      openAudioRecord: true,
    });
  }

  closeAudioRecord() {
    this.setState({
      openAudioRecord: false,
    });
  }

  openCamera() {
    this.setState({
      openCamera: true,
    });
  }

  openReminder() {
    this.setState({
      openReminder: true,
    });
  }

  closeCamera() {
    this.setState({
      openCamera: false,
    });
  }

  closeReminder() {
    this.setState({
      openReminder: false,
    });
  }

  handleClick(e) {
    this.props.setEmojiShown(!this.props.emojiShown);
  }

  handleClose(e) {
    this.props.setEmojiShown(false);
  }

  handleSetTags(e) {
    debugger;
    this.props.showTagsModal(this.props.recent);
  }

  openProgressNotes() {
    const filesUrl = `${window.location.origin}/progressnotes/${this.props.activeThread.threadId}`;
    window.open(
      filesUrl,
      // "_blank",
      "width=1200, height=800"
    );
  }

  handleOrderModalShown(bool) {
    this.setState({
      ordersModalShown: bool,
    });
  }

  handleDisabled() {
    if (this.props.files.length > 0) {
      return false;
    }
    return this.props.isSending || !this.props.activeSend;
  }

  render() {
    const {
      activeThread,
      postOnConversation,
      patientInfo = {},
      AlertButton,
      assignAttentionLength,
      alertOnClick,
      createTask = () => {},
      sendAsUrgent = () => {},
      setPriority = () => {},
      selectedPriority = 0,
      recent,
      patientInfoReduced
    } = this.props;
    let showSetting = false;
    let conversationActionStyle = {
      display: "flex",
      flexWrap: "wrap",
    };
    if (this.props.hide) {
      conversationActionStyle.display = "none";
    }

    const isPatient =
      activeThread.threadType === "group" &&
      activeThread.groupType === "patient";
    const isPatientOrGroup = activeThread.threadType !== "private";
    switch (activeThread.threadType) {
      case "private":
        showSetting = true;
        break;

      case "group":
        showSetting = true;
        break;
      default:
    }

    // console.log('patientInfo', patientInfo);

    return (
      <div className="Conversation-Action-Menu" style={conversationActionStyle}>
        <div className={"item"} /*+ (this.props.activeSend && "active")*/>
          {recent.threadType === "group" && (
            <AlertButton
              active={assignAttentionLength}
              onClick={alertOnClick}
              className="mb-1"
            />
          )}

          <div class="btn-group">
            <button
              className={`btn btn-sm ${
                this.props.activeSend || this.props.files.length > 0
                  ? "btn-primary"
                  : "btn-default"
              }`}
              style={{ width: "100px", border: "solid 1px #b3b3b3" }}
              // data-toggle="tooltip"
              // data-placement="top"
              // data-html="true"
              // title="Press Enter or Press Shift + Enter to add a new paragraph"
              onClick={this.props.handleSendButton}
              disabled={this.handleDisabled()}
            >
              <i className="fas fa-paper-plane"></i> Send{/*  */}
            </button>
            {recent.threadType === "group" && (
              <Fragment>
                <div class="btn-group dropup" role="group">
                  <button
                    id="prioritization"
                    className={`btn btn-sm ${
                      this.props.activeSend || this.props.files.length > 0
                        ? "btn-primary"
                        : "btn-default"
                    }`}
                    type="button"
                    style={{
                      border: "solid 1px #b3b3b3",
                      borderTopRightRadius: "20px",
                      borderBottomRightRadius: "20px",
                    }}
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    disabled={this.props.isSending || !this.props.activeSend}
                  >
                    {selectedPriority === -1 && (
                      <div style={{ transform: "rotate(90deg)" }}>
                        <i
                          style={{ marginTop: 2 }}
                          class="fad fa-angle-left"
                        ></i>
                        <i
                          style={{ marginTop: 2 }}
                          class="fad fa-angle-right"
                        ></i>
                      </div>
                    )}
                    {selectedPriority === 0 && (
                      <i
                        style={{ marginTop: 2 }}
                        class="fad fa-angle-double-down fa-fw"
                      ></i>
                    )}
                    {selectedPriority === 1 && (
                      <span style={{ fontWeight: "bold", color: "#f6c13d" }}>
                        =
                      </span>
                    )}
                    {selectedPriority === 2 && (
                      <i
                        class="fad fa-angle-double-up fa-fw"
                        style={{ color: "#04589e" }}
                      ></i>
                    )}
                  </button>
                  <div class="dropdown-menu" aria-labelledby="prioritization">
                    <h5 class="dropdown-header">Send Alert As</h5>
                    <a
                      style={{ cursor: "pointer" }}
                      class={`dropdown-item ${
                        assignAttentionLength === 1 && this.props.activeSend
                          ? ""
                          : "disabled"
                      }`}
                      disabled={
                        !this.props.activeSend &&
                        !(this.props.files.length === 1)
                      }
                      onClick={this.props.handleSendButton}
                    >
                      <i
                        class="fal fa-fw fa-at"
                        style={{ 
                          color: "yellow",
                          margin: "0px 3px",
                         }}
                      ></i>{" "}
                      Attention
                    </a>
                    <a
                      style={{ cursor: "pointer" }}
                      class={`dropdown-item ${
                        assignAttentionLength > 0 && this.props.activeSend
                          ? ""
                          : "disabled"
                      } ${
                        this.props.activeSend || this.props.files.length > 0
                          ? "btn-primary"
                          : "btn-default"
                      }`}
                      disabled={assignAttentionLength === 0}
                      onClick={() => sendAsUrgent()}
                    >
                      <i
                        class="fal fa-fw fa-exclamation-circle"
                        style={{ 
                          color: "red",
                          margin: "0px 3px",
                         }}
                      ></i>{" "}
                      Urgent
                    </a>
                    <h5 class="dropdown-header">Send Task As</h5>
                    <a
                      style={{ cursor: "pointer" }}
                      class={`dropdown-item ${
                        assignAttentionLength === 1 && this.props.activeSend
                          ? ""
                          : "disabled"
                      }`}
                      disabled={
                        !this.props.activeSend &&
                        !(this.props.files.length === 1)
                      }
                      onClick={() => setPriority(2)}
                    >
                      <i
                        class="fad fa-angle-double-up fa-fw"
                        style={{ color: "#04589e" }}
                      ></i>{" "}
                      High Priority
                    </a>
                    <a
                      style={{ cursor: "pointer" }}
                      class={`dropdown-item ${
                        assignAttentionLength === 1 && this.props.activeSend
                          ? ""
                          : "disabled"
                      }`}
                      disabled={
                        !this.props.activeSend &&
                        !(this.props.files.length === 1)
                      }
                      onClick={() => setPriority(1)}
                    >
                      <span
                        style={{
                          padding: "0px 4px",
                          fontWeight: "bold",
                          color: "#f6c23e",
                        }}
                      >
                        =
                      </span>{" "}
                      Medium Priority
                    </a>
                    <a
                      style={{ cursor: "pointer" }}
                      className="dropdown-item"
                      onClick={() => setPriority(0)}
                    >
                      <i class="text-primary fad fa-angle-double-down fa-fw"></i>{" "}
                      Routine Priority
                    </a>
                  </div>
                </div>
                {/* <button
                  id="tasking"
                  type="button"
                  style={{
                    border: "solid 1px #b3b3b3",
                    borderTopRightRadius: "20px",
                    borderBottomRightRadius: "20px",
                  }}
                  class={`btn btn-sm ${
                    assignAttentionLength > 0 && this.props.activeSend
                      ? ""
                      : "disabled"
                  } ${
                    this.props.activeSend || this.props.files.length > 0
                      ? "btn-primary"
                      : "btn-default"
                  }`}
                  disabled={assignAttentionLength === 0}
                  onClick={() => sendAsUrgent()}
                  title="Send as Urgent"
                >
                  <i class="fal fa-fw fa-exclamation-circle"></i>
                </button> */}
                {/* <div class="btn-group dropup" role="group">
                  <button
                    id="tasking"
                    type="button"
                    className={`btn btn-sm ${
                      this.props.activeSend || this.props.files.length > 0
                        ? "btn-primary"
                        : "btn-default"
                    }`}
                    style={{
                      border: "solid 1px #b3b3b3",
                      borderTopRightRadius: "20px",
                      borderBottomRightRadius: "20px",
                    }}
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    disabled={this.props.isSending || !this.props.activeSend}
                  >
                    <i className="fas fa-caret-up" />
                  </button>
                  <div class="dropdown-menu" aria-labelledby="tasking">
                    <a
                      class={`dropdown-item ${
                        assignAttentionLength === 1 && this.props.activeSend
                          ? ""
                          : "disabled"
                      }`}
                      disabled={
                        !this.props.activeSend &&
                        !(this.props.files.length === 1)
                      }
                      onClick={() => createTask()}
                    >
                      <i class="fal fa-fw fa-list"></i> Send as Task
                    </a>
                    <a
                      class={`dropdown-item ${
                        assignAttentionLength > 0 && this.props.activeSend
                          ? ""
                          : "disabled"
                      }`}
                      disabled={!this.props.activeSend}
                      onClick={() => sendAsUrgent()}
                    >
                      <i class="fal fa-fw fa-exclamation-circle"></i> Send as
                      Urgent
                    </a>
                  </div>
                </div> */}
              </Fragment>
            )}
          </div>
        </div>
        <div style={conversationActionStyle}>
          <div
            onClick={this.props.openFile}
            className="item"
            data-toggle="tooltip"
            data-placement="top"
            data-html="true"
            title="Add Files"
          >
            <i className="fas fa-images"></i>
          </div>
          <div
            onClick={() => this.openCamera()}
            className="item"
            data-toggle="tooltip"
            data-placement="top"
            data-html="true"
            title="Camera"
          >
            <i className="fas fa-camera"></i>
          </div>
          <div
            onClick={() => this.openAudioRecord()}
            className="item"
            data-toggle="tooltip"
            data-placement="top"
            data-html="true"
            title="Send a voice clip"
          >
            <i className="fas fa-microphone"></i>
          </div>

          <div
            className={"item " + (this.props.emojiShown && "active")}
            data-toggle="tooltip"
            data-placement="top"
            data-html="true"
            title="Select emoji"
          >
            <i
              className="far fa-smile"
              onClick={this.handleClick.bind(this)}
            ></i>
          </div>
          {this.props.activeThread.threadType === "group" && (
            <div
              onClick={this.handleSetTags}
              className="item-tag"
              data-toggle="tooltip"
              data-placement="top"
              data-html="true"
              title="Set/Unset tags"
            >
              <i className="fas fa-hashtag"></i>
            </div>
          )}
          {isPatient && showSetting && (
            <div className="dropdown mr-1">
              <button
                className="Setting-Button btn btn-link p-0"
                type="button"
                id="drpdwn-apps"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ transform: "translateY(3px)" }}
              >
                <i
                  style={{ color: "#908e8e" }}
                  className="fal fa-file-signature fa-lg"
                  data-toggle="tooltip"
                  data-placement="top"
                  data-html="true"
                  title="Forms"
                />
              </button>
              <div
                className="dropdown-menu custom-menu"
                aria-labelledby="drpdwn-apps"
              >
                <div className="forms-container">
                  <div
                    className="form-item"
                    onClick={() => this.openProgressNotes()}
                  >
                    <i className="fas fa-file-alt fa-4x"></i>
                    <span>Progress Notes</span>
                  </div>
                  <div style={{ width: "82px" }} />
                  <div style={{ width: "82px" }} />
                </div>
              </div>
            </div>
          )}
          {showSetting && (
            <div
              className="item-tag"
              data-toggle="tooltip"
              data-placement="top"
              data-html="true"
              title="Set Orders"
              onClick={() => this.handleOrderModalShown(true)}
            >
              <i class="fas fa-prescription"></i>
            </div>
          )}
          {activeThread.groupType === "patient" && (
            <div
              className="item-tag color-blue"
              style={ patientInfoReduced.isCritical === true ? {color:'#D4403A', marginLeft:'10px'} : {}}
              data-toggle="tooltip"
              data-placement="top"
              data-html="true"
              title={patientInfoReduced.isCritical === true ? "Currently Tagged" : "Tag as Critical"}
              onClick={() => this.openReminder()}
            >
              <i class="fas fa-business-time"></i>
            </div>
          )}
        </div>
        {/* {activeThread.threadType === "group" && (
          <button
            className="btn btn-sm btn-primary view-task-btn"
            title="View Critical"
            onClick={() => this.props.openCriticalModal(true)}
          >
            <i className="fas fa-exclamation-triangle mr-1"></i>{" "} View Critical
          </button>
        )} */}
        {activeThread.threadType === "group" && (
          <button
            className="btn btn-sm btn-primary view-task-btn"
            title="View Tasks"
            onClick={() => this.props.openActionsModal(true)}
          >
            <i class="fas fa-list"></i> View Tasks
          </button>
        )}
        {this.state.ordersModalShown && (
          <OrdersModal
            isOpen={this.state.ordersModalShown}
            title="Orders"
            thread={this.props.activeThread}
            patientId={this.props.activeThread.threadId}
            onClose={() => this.handleOrderModalShown(false)}
            postOnConversation={postOnConversation}
            isPatient={isPatientOrGroup}
            patientName={patientInfo.name}
            patientDob={patientInfo.dob}
            guests={this.props.guests}
            options={this.props.options}
          />
        )}

        {this.state.openAudioRecord && (
          <AudioModal
            uploadAudioFile={this.props.uploadAudioFile}
            isOpen={this.state.openAudioRecord}
            modalClose={this.closeAudioRecord.bind(this)}
          />
        )}
        {this.state.openCamera && (
          <CameraModal
            isOpen={this.state.openCamera}
            modalClose={this.closeCamera.bind(this)}
            sendCapturedPhoto={this.props.sendCapturedPhoto}
          />
        )}
        {this.state.openReminder && (
          <ReminderModal
            isOpen={this.state.openReminder}
            modalClose={this.closeReminder.bind(this)}
            circleId={this.props.activeThread.threadId}
            currentReminder={patientInfoReduced.isCritical}
            members={this.props.members}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  emojiShown: state.thread.emojiShown,
  tagsModalShown: state.thread.tagsModalShown,
  recentToTag: state.thread.recentToTag,
  isSending: state.thread.isSending,
  patientInfo: state.patient.patientInfo,
  patientInfoReduced: state.patient.patientInfoReduced,
});

export default connect(mapStateToProps, {
  setEmojiShown,
  showTagsModal,
})(ConversationMenu);
