import React, { useCallback, useEffect, useState } from 'react';
import Participants from './Participants';
import Modal from '../../../Common/Modal';
import Notify from '../Notify';
import ConferenceApi from '../../../../api/Conference'
import useRoom from '../useRoom';
import OverlayPlaceholder from '../../../Common/OverlayPlaceholder';

const ParticipantsModal = ({ isOpen, onClose, invited = [], guests = [], roomId, isHost }) => {

    const { sendData } = useRoom();

    const onRequestMic = useCallback((user, value) => {

        const type = value ? 'mic_request' : 'mic_disable';

        const data = {
            type,
            identities: [user.objectId],
            mic: value,
        };
        sendData(data);

        const message = value ? 'Requesting mic for' : 'Turning off mic for';

        Notify.show(`${message} ${user.displayName}`);

        onClose();
    }, [sendData]);

    const onRequestCam = useCallback((user, value) => {

        const type = value ? 'cam_request' : 'cam_disable';

        const data = {
            type,
            identities: [user.objectId],
            cam: value,
        };
        sendData(data);

        const message = value ? 'Requesting cam for' : 'Turning off cam for';

        Notify.show(`${message} ${user.displayName}`);

        onClose();
    }, [roomId]);

    return (
        <Modal shown={isOpen} onHide={onClose}>

            <div className="modal-header">
                <h5 className="modal-title hc-primary-text">Participants</h5>
                <button type="button" className="close" aria-label="Close" onClick={e => onClose()}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div className="modal-body py-0" style={{ height: '100%' }}>
                <Participants invited={invited} onRequestMic={onRequestMic} onRequestCam={onRequestCam} isHost={isHost} />
                <OverlayPlaceholder show={!invited.length} text="No invited participants"/>
            </div>
            <div className="modal-footer rm-border" />
        </Modal>
    );
}

export default ParticipantsModal;
