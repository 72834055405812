import React, { useMemo } from "react";
import { linkToImportant, linkToUrgent } from "../../../actions/ThreadAction";
import OverlayLoader from "../../Common/OverlayLoader";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { linkToLabel } from "../../../actions/ThreadAction";

const MessagesCard = ({ attentionCount, urgentCount, returnCallCount, isLoading, handleCreateMessage }) => {
  const dispatch = useDispatch();

  const { circleLabel } = useSelector(mySelector, shallowEqual);

  const handleImportant = () => {
    dispatch(linkToImportant());
  };

  const handleUrgent = () => {
    dispatch(linkToUrgent());
  };

  const handleClickLabel = (label) => {
    dispatch(linkToLabel(label.objectId));
  };

  const containerStyles = {
    position: "relative",
    height: "100%",
  };

  const listStyles = {
    width: "100%",
    fontSize: "14px",
  };

  const sum = useMemo(() => {
    return attentionCount + urgentCount + returnCallCount;
  }, [attentionCount, urgentCount, returnCallCount]);

  return (
    <div className="dashboard-box">
      <h6 className="sidebar-heading d-flex justify-content-between align-items-center ">
        <strong>
          <span>Messages</span>
        </strong>
        <a className="text-secondary" onClick={() => handleCreateMessage()}>
          <i className="fa fa-fw fa-comment-edit"></i>
        </a>
      </h6>
      <div style={containerStyles}>
        <div className="list-group list-group-flush" style={listStyles}>
          <a
            onClick={(e) => handleUrgent()}
            className="list-group-item sidebar-item d-flex align-items-center label-cont"
            style={{ cursor: "pointer" }}
            // href="#!"
          >
            <i className="fas fa-fw mr-2 fa-exclamation fa-md text-danger"></i>
            <span className="text-truncate text-">Urgent</span>
            <span className="badge ml-auto">{urgentCount}</span>
          </a>
          <a
            onClick={(e) => handleImportant()}
            className="list-group-item sidebar-item d-flex align-items-center label-cont"
            style={{ cursor: "pointer" }}
            // href="#!"
          >
            <i className="fas fa-fw mr-2 fa-at fa-md text-warning"></i>
            <span className="text-truncate text-">For you</span>
            <span className="badge ml-auto">{attentionCount}</span>
          </a>
          {/* <a
            onClick={(e) => handleClickLabel(circleLabel)}
            className="list-group-item sidebar-item d-flex align-items-center label-cont"
            href="#!"
          >
            <i className="fas fa-fw mr-2 fa-phone fa-md hc-primary-text"></i>
            <span className="text-truncate text-">Return call</span>
            <span className="badge ml-auto">{returnCallCount}</span>
          </a> */}
        </div>
      </div>
      {isLoading && <OverlayLoader isLoading={sum === 0} />}
    </div>
  );
};

const findLabel = (circleLabels) => {
  return circleLabels.find((label) => label.code === "return_call") || {};
};

const mySelector = (state) => ({
  circleLabel: findLabel(state.dashboard.circleLabels),
});

export default MessagesCard;
