import React, { useEffect } from "react";

import "./index.css";

const $ = window.$;

function AssessmentsComponent({
  assessments = ["", "", "", "", "", "", "", "", ""]
}) {
  function handleListAssessments(array = []) {
    const filteredAssessments = array.filter(Boolean);
    const newFAssessnments = filteredAssessments.filter(
      item => typeof item === "string" || item.problem || item.assessmentPlan
    );

    if (newFAssessnments.length === 0) {
      return (
        <div className="custom-form-group-container pe-text">
          <span></span>
        </div>
      );
    }

    return newFAssessnments.map((item, i) => {
      const isString = typeof item === "string" ? true : false;

      if (isString) {
        return (
          <div className="assessment-item" key={i}>
            <span>{`${i + 1}.`}</span>
            <span>{item || "--"}</span>
          </div>
        );
      } else {
        return (
          <div className="assessment-item" key={i}>
            <span>{`${i + 1}.`}</span>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>{item.problem || "--"}</span>
              <span>{item.assessmentPlan || "--"}</span>
            </div>
          </div>
        );
      }
    });
  }

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="card custom-card">
      <div
        className="card-header"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <h5 style={{ marginBottom: "0px" }}>
          <strong>PROBLEM LIST</strong>
        </h5>
      </div>
      <div className="card-body" style={{ padding: "20px" }}>
        <div className="custom-form-group-container">
          {handleListAssessments(assessments)}
        </div>
      </div>
    </div>
  );
}

export default AssessmentsComponent;
