import React, { useState, Fragment } from 'react';
import Parse from 'parse';
import { parseAction } from '../../../actions/REST';
import { setActiveTab } from '../../../actions/ThreadAction';
import { newGroup, inviteMember } from '../../../actions/GroupAction';
import { resetSelectedContacts, removeSelectedContact } from '../../../actions/ContactAction';
import { isMobile } from 'react-device-detect';
import config from '../../../config';
import Notify from '../../../components/Common/Notify';
import Loader from '../../Common/Loader';

import { 
  useSelector, 
  useDispatch, 
  shallowEqual,
} from 'react-redux';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './index.css';

const $ = window.$;

const CreateChat = ({ history }) => {
  const dispatch = useDispatch();
  const [group_name, setGroupName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { 
    selectedContacts, 
    contacts,
    filterResult,
    otherUsersResult,
  } = useSelector(state => ({
      selectedContacts: state.contact.selectedContacts,
      contacts: state.contact.contacts,
      filterResult: state.contact.filterResult,
      otherUsersResult: state.contact.otherUsersResult,
  }), shallowEqual);

  function handleCreateGroup(e) {
    if (selectedContacts.length === 1) {
      history.push(`/u/${selectedContacts[0].objectId}`);
      dispatch(setActiveTab('message'));
    } else {
      createGroup(e);
    }
  }

  function createGroup (e) {
    e.preventDefault();
    setIsLoading(true);
    
    const name = Parse.User.current().get('firstName');
    const defaultGroupName =`${name}, ${ (selectedContacts.map(item => item.firstName)).join(', ')}`;
    let groupName = group_name || defaultGroupName;
    const memberIds = selectedContacts.map(item => item.objectId);
    const params = { groupName, memberIds };
    
    parseAction("post", config.BASE_URL + '/parse/functions/createCircleUnique', //createRegularGroup
      params,
      {
        "Content-Type": "application/json",
      }).then(res => {  
        const group = res.result.circle;
        const isNew = res.result.isNew;
        const { objectId } = group;
        
        if (isNew) {
          Notify.success('Group successfully created!');
          handleResetContacts();
          newGroup(group)(dispatch);
        }
        history.push(`/u/${objectId}`);
        dispatch(setActiveTab('group'));
        setIsLoading(false);
    }).catch(error => {
      console.log(error);
      setIsLoading(false);
    });
  }

  function inviteMembers(contacts, uuid, groupId) {
    const promises = contacts.map(contact => {
      return inviteMember(contact, uuid)(dispatch);
    });

    Promise.all(promises)
      .then(() => {
          Notify.success('Group successfully created!');
          setIsLoading(false);
          handleResetContacts();
          history.push(`/u/${groupId}`);
          dispatch(setActiveTab('group'));
      })
      .catch(res => {
          const {responseJSON} = res;
          if (responseJSON) {
              const {error} = responseJSON;
              const alertContent = (
                  <div>
                      <div style={{fontSize: '16px', fontWeight: 'bold'}}>Failed to add member</div>
                      <div style={{fontSize: '14px'}}>{error}</div>
                  </div>
              )
              return Notify.error(alertContent);
          }
          setIsLoading(false);
          return Notify.error('Failed to add members.');
      });
  }

  function handleResetContacts () {
    resetSelectedContacts(contacts)(dispatch);
    dispatch(setActiveTab('dashboard'));
  }

  return (
    <div className="chat-view-container">
      <div className="chat-container">
        {selectedContacts.length > 1 && (
          <div className="Tab-Search-Container" style={{ maxWidth: '350px' }}>
            <input id="search" placeholder="Group Name" type="text" onChange={e => setGroupName(e.target.value)} />
          </div>
        )}
        {!isMobile && (
          <Fragment>
            {selectedContacts.length <= 1 && <span>Select contacts to start a conversation</span>}
            <div className="chat-users-container">
              {selectedContacts.map(user => (
                <div key={user.imgUrl} style={{ textAlign: 'center' }}>
                  <div className="chat-user">
                    {user.imgUrl && <img src={user.imgUrl} />}
                    {!user.imgUrl && <i className="fas fa-user fa-3x"></i>}
                    <div 
                      className="chat-user-overlay" 
                      onClick={() => removeSelectedContact(user.objectId, {
                        selectedContacts, 
                        contacts,
                        filterResult,
                        otherUsersResult,
                      })(dispatch)}>
                      <i className="fas fa-times"></i>
                    </div>
                  </div>
                  <div className="chat-name">{user.displayName || 'No Name'}</div>
                </div>
              ))}
              <div>
                <div className="default-placeholder">
                  <i className="fas fa-user fa-3x"></i>
                </div>
              </div>
            </div>
          </Fragment>
        )}
        <div className="chat-button-container">
          <button type="button" className="btn btn-primary hc-bg-primary" onClick={e => handleCreateGroup(e)} disabled={selectedContacts.length === 0}>Start Chat</button>
          <button type="button" className="btn btn-secondary" onClick={() => handleResetContacts()}>Cancel</button>
        </div>
      </div>
      <Loader isLoading={isLoading} message={'Creating group. Please wait.'} />
    </div>
  );
}

export default CreateChat;