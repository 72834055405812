import React from "react";
import defaultImageUrl from "../../../../../assets/images/default.png";

const Item = ({ onSelect, recent }) => {
  const { groupName, groupImageURL } = recent;

  const imageUrl = groupImageURL || defaultImageUrl;

  return (
    <div className="border-item layout-item">
      <img
        src={imageUrl}
        alt=""
        className="profile-image rounded-circle mr-2"
        style={{ width: "30px", height: "30px" }}
      />
      <p className="name m-0 text-uppercase">{groupName}</p>
      <button
        onClick={e => onSelect(recent.threadId)}
        className="btn btn-primary btn-sm ml-auto text-nowrap"
      >
        Select
      </button>
    </div>
  );
};

export default Item;
