const initialState = {

	// List of priorities
	priorities: [],

	// If fetching items in progress
	isLoading: false,

	// The current page viewed
	currentPage: 0,

	// If more items can be loaded by scrolling
	hasMore: false,

	// (SUCCESS, FAILED) So that we can handle if it failed
	fetchStatus: "",
}

export default function (state = initialState, action) {

	switch (action.type) {

		// Set isLoading
		case 'SET_LOADING':
			return {
				...state,
				isLoading: action.value
			};

		case 'CLEAR_PRIORITIES':
			return {
				...state,
				priorities: [],
			};

		/**
		 * Save priorities
		 * @param {Object[]} action.priorities
		 */
		case 'SAVE_PRIORITIES':

			if (action.status === "FAILED") {
				return {
					...state,
					fetchStatus: action.status,
				}
			}

			const { replace, page, hasMore } = action;

			const currentPage = page;

			// We either replace list or append to end
			const priorities = replace ? action.priorities : state.priorities.concat(action.priorities);

			return {
				...state,
				priorities,
				currentPage,
				hasMore,
				fetchStatus: action.status,
			};

		case 'DELETE_PRIORITY':
			return {
				...state,
				priorities: state.priorities.filter(
					p => p.objectId != action.priorityId
				),
			};

		case 'DELETE_PRIORITIES':
			return {
				...state,
				priorities: state.priorities.filter(
					p => !action.priorityIds.includes(p.objectId)
				),
			};

		default: return state;
	}
}
