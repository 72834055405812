import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import {
  PDFViewer,
  Document,
  // PDFDownloadLink,
  View,
  Text,
  Page,
  Image,
} from "@react-pdf/renderer";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Notify from "../../../Common/Notify";
import { parseAction } from "../../../../actions/REST";
import {
  getPrescriptionHistory,
  setPrescriptionHistory,
  requestOTP,
  eSignPDF,
} from "../../../../actions/PatientAction";
import Loader from "../../../Common/Loader";
import ConferenceApi from "../../../../api/Conference";
import { calculateAge } from "../../../../helper/util";
import config from "../../../../config";
import Styles from "./styles.js";
import Rx from "../../../../assets/images/rx.png";
import RequirePasswordESign from "./RequirePasswordESign";

function PrescriptionPDF({
  patientId = "",
  historyId = "",
  fromMedications = false,
}) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [patientInfo, setPatientInfo] = useState({});
  const [doctorInfo, setDoctorInfo] = useState({});
  const [isESignedOpen, setIsESignedOpen] = useState(false);
  // const [medicines, setMedicines] = useState([]);
  const { prescriptionHistory, isPrescriptionHistoryLoading } = useSelector(
    (state) => ({
      prescriptionHistory: state.patient.prescriptionHistory,
      isPrescriptionHistoryLoading: state.patient.isPrescriptionHistoryLoading,
    }),
    shallowEqual
  );
  const medicines = prescriptionHistory.medications || [];

  function fetchPatientInfo(threadId) {
    setIsLoading(true);
    parseAction("get", config.BASE_URL + "/parse/classes/Circle", {
      where: {
        objectId: threadId,
      },
    })
      .then((result) => {
        var data = {
          ...result.results[0],
          prescriber: result.results[0].prescriber
            ? result.results[0].prescriber.objectId
            : "",
        };
        const checkedMeds = filterCheckedMedicines(data.medications || []);
        setPatientInfo(data);
        // setMedicines(checkedMeds);
        setIsLoading(false);

        if (data.prescriber) {
          getPrescriberDetails(data.prescriber);
        }

        if (fromMedications) {
          getPrescriptionHistory(historyId)(dispatch);
        } else {
          setPrescriptionHistory({
            createdAt: new Date(),
            medications: [...checkedMeds],
          })(dispatch);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }

  function getPrescriberDetails(objectId) {
    ConferenceApi.getUsersById({ objectIds: [objectId] }).then((response) => {
      setDoctorInfo(response.users[0]);
    });
  }

  function filterCheckedMedicines(medicines = []) {
    return medicines.filter((item) => item.isChecked);
  }

  function copyPrescriptionLink() {
    const prescriptionUrl = `${window.location.origin}/prescription/${patientId}`;
    const elem = document.createElement("textarea");

    elem.value = prescriptionUrl;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);

    Notify.success("Prescription link copied!");
  }

  function copyPrescriptionHistoryLink() {
    const prescriptionUrl = `${window.location.origin}/prescription-history/${patientId}/${historyId}`;
    const elem = document.createElement("textarea");

    elem.value = prescriptionUrl;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);

    Notify.success("Prescription link copied!");
  }

  function handleESignPDF(otp) {
    eSignPDF(
      {
        otp,
        prescriptionId: historyId,
      },
      "prescription",
      (res) => {
        setIsESignedOpen(false);
        getPrescriptionHistory(historyId)(dispatch);
      }
    )(dispatch);
  }

  useEffect(() => {
    fetchPatientInfo(patientId);
    return () => {};
  }, [patientId]);

  function displayRichTextInModal(richTextData) {
    let count = 0;
    return (richTextData.blocks || []).map((item, i) => {
      const itemLength = item.text.replace(/ /g, "").length;
      const hasBullet =
        item.type === "unordered-list-item" && item.depth === 0 ? true : false;
      const hasNumber =
        item.type === "ordered-list-item" && item.depth === 0 ? true : false;
      let listNum = 0;
      let listAsterisk = "*";
      const medStyle = {
        fontWeight: "bold",
        fontFamily: "Open Sans",
        marginTop: i > 0 ? "15px" : "0px",
      };
      const titleStyle = {
        fontWeight:
          hasBullet || hasNumber || !(richTextData.blocks[i - 1] || {}).text
            ? "bold"
            : "normal",
        ...{
          ...(hasBullet || hasNumber || !(richTextData.blocks[i - 1] || {}).text
            ? { fontFamily: "Open Sans" }
            : {}),
        },
      };

      if (hasNumber) {
        count = count + 1;
        listNum = count;
      }
      return (
        <View key={item.key} style={Styles.leftRow}>
          {hasBullet && (
            <View style={[{ ...medStyle, ...Styles.countCls }]}>
              <Text style={Styles.textXs}>{`${listAsterisk}`}</Text>
            </View>
          )}
          {hasNumber && (
            <View style={[{ ...medStyle, ...Styles.countCls }]}>
              <Text style={Styles.textXs}>{`${listNum}.`}</Text>
            </View>
          )}
          {!hasNumber && !hasBullet && (
            <View style={Styles.countCls}>
              <Text style={Styles.textXs}> </Text>
            </View>
          )}
          <View
            style={[
              { marginTop: (hasNumber || hasBullet) && i > 0 ? "15px" : "0px" },
            ]}
          >
            {itemLength > 0 && (
              <Text style={[{ ...Styles.textXs, ...titleStyle }]}>
                {item.text}
              </Text>
            )}
            {itemLength === 0 && <View style={[{ height: "15px" }]}></View>}
          </View>
        </View>
      );
    });
  }

  function displayTextArray(textArray) {
    return textArray.map((item) => (
      <Fragment key={item}>
        <View style={Styles.leftRow}>
          {/* <Image src={Rx} style={[{ height: 18, width: 15, marginHorizontal: 10 }]} /> */}
          <View style={Styles.textSmStrong}>{displayFreeText(item)}</View>
        </View>
        <View style={Styles.divider}></View>
      </Fragment>
    ));
  }

  function displayFreeText(free_text) {
    const ftArray = free_text.split("\n");
    return ftArray.map((item, i) => {
      if (i === 0) {
        return <Text style={Styles.textSmStrong}>{item}</Text>;
      } else {
        return <Text style={Styles.textXs}>{item}</Text>;
      }
    });
  }

  function renderString(str) {
    return String(str).replace("\t", "");
  }

  return (
    <Fragment>
      <PDFViewer>
        <Document>
          <Page style={Styles.page}>
            <Text style={Styles.date} fixed>{`${moment(
              prescriptionHistory.createdAt
            ).format("LLLL")}`}</Text>
            <View style={Styles.headerContainer} fixed>
              <View style={Styles.header} fixed>
                <Text style={[Styles.textMdStrong, { marginBottom: "5px" }]}>
                  {"Vital Health Medical Group"}
                </Text>
                <Text style={[Styles.textXs, { color: "#2194f3" }]}>
                  {"6245 2nd flr De Longpre Ave, Hollywood, CA 90028"}
                </Text>
                <Text
                  style={[
                    Styles.textXs,
                    { color: "#2194f3", marginBottom: "5px" },
                  ]}
                >
                  Los Angeles, CA 90026
                </Text>
                <View style={Styles.rowView}>
                  <View style={Styles.rowView}>
                    <Text style={Styles.textXsStrong}>Phone: </Text>
                    <Text style={[Styles.textXs, { color: "#2194f3" }]}>
                      {"(323)315-0911"}
                    </Text>
                  </View>
                  <Text> </Text>
                  <View style={Styles.rowView}>
                    <Text style={Styles.textXsStrong}>Fax: </Text>
                    <Text style={[Styles.textXs, { color: "#2194f3" }]}>
                      {"(323) 798-3021"}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={Styles.divider} fixed></View>
              <View
                style={[Styles.aroundBox, { padding: 5, minHeight: 67 }]}
                fixed
              >
                <View style={Styles.divider}></View>
                <View style={[{ width: 200 }]}>
                  <View style={Styles.details}>
                    <Text style={Styles.patientBold}>Patient: </Text>
                    <View>
                      <Text style={Styles.patientReg}>
                        {renderString(patientInfo.name) || "--"}
                      </Text>
                    </View>
                  </View>
                  <View style={Styles.details}>
                    <Text style={Styles.patientBold}>Address: </Text>
                    <View>
                      <Text style={Styles.patientReg}>
                        {renderString(patientInfo.address) || "--"}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={[{ width: 200 }]}>
                  <View style={Styles.details}>
                    <Text style={Styles.patientBold}>DOB:</Text>
                    <View>
                      <Text style={Styles.patientReg}>{`${calculateAge(
                        patientInfo.dob
                      )}`}</Text>
                    </View>
                  </View>
                  <View style={Styles.details}>
                    <Text style={Styles.patientBold}>Sex: </Text>
                    <View>
                      <Text style={Styles.patientReg}>
                        {patientInfo.gender || "--"}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={[{ width: 200 }]}>
                  <View style={Styles.details}>
                    <Text style={Styles.patientBold}>Phone: </Text>
                    <View>
                      <Text style={Styles.patientReg}>
                        {patientInfo.phoneNumber || "--"}
                      </Text>
                    </View>
                  </View>
                  <View style={Styles.details}>
                    <Text style={Styles.patientBold}>MRN: </Text>
                    <View>
                      <Text style={Styles.patientReg}>
                        {patientInfo.mrn || "--"}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={[Styles.divider, { marginBottom: "20px" }]}
              fixed
            ></View>
            <View style={Styles.rteFlex}>
              {/* <Image
                src={Rx}
                style={[{ height: 50, width: "auto", marginRight: "20px" }]}
                fixed
              /> */}
              <View style={[{ width: "100%" }]}>
                {medicines.map((item, index) => (
                  <Fragment>
                    {item.richText && (
                      <View key={`${item._id}${index}`}>
                        {displayRichTextInModal(item.richText)}
                        <View style={Styles.divider}></View>
                      </View>
                    )}
                    {item.textArray && (
                      <View wrap={false} key={`${item._id}${index}`}>
                        {displayTextArray(item.textArray)}
                      </View>
                    )}
                    {item.free_text && (
                      <View wrap={false} key={`${item._id}${index}`}>
                        <View style={Styles.leftRow}>
                          <View>{displayFreeText(item.free_text)}</View>
                        </View>
                        <View style={Styles.divider}></View>
                      </View>
                    )}
                    {!item.richText && !item.textArray && !item.free_text && (
                      <View
                        style={Styles.rxContainer}
                        wrap={false}
                        key={`${item._id}${index}`}
                      >
                        <View style={Styles.leftRow}>
                          <Text style={Styles.textSmStrong}>
                            {item.displayName}
                          </Text>
                        </View>
                        <View style={Styles.drugContainer}>
                          <View style={Styles.drugsFlex}>
                            <Text style={Styles.drugBold}>SIG: </Text>
                            <View>
                              <Text style={Styles.textXs}>
                                {item.direction} {item.amount}{" "}
                                {item.dosage_form} {item.route} {item.location}{" "}
                                {item.frequency ? item.frequency_obj.text : ""}{" "}
                                {item.duration > 0 && item.durationStr
                                  ? `for ${item.duration} ${item.durationStr}`
                                  : ""}
                              </Text>
                              <Text style={Styles.textXs}>
                                {item.additionalDirections}
                              </Text>
                            </View>
                          </View>
                          {item.quantity !== 0 && (
                            <View style={Styles.drugsFlex}>
                              <Text style={Styles.drugBold}>
                                Dispense Qty:{" "}
                              </Text>
                              <View>
                                <Text style={Styles.textXs}>
                                  {item.quantity}
                                </Text>
                              </View>
                            </View>
                          )}
                          {item.refill > 0 && (
                            <View style={Styles.drugsFlex}>
                              <Text style={Styles.drugBold}>Refill: </Text>
                              <View>
                                <Text style={Styles.textXs}>{item.refill}</Text>
                              </View>
                            </View>
                          )}
                          {item.prn && (
                            <View style={Styles.drugsFlex}>
                              <Text style={Styles.drugBold}>PRN: </Text>
                              <View>
                                <Text style={Styles.textXs}>
                                  {item.prn_reason}
                                </Text>
                              </View>
                            </View>
                          )}
                        </View>
                        <View style={Styles.divider}></View>
                      </View>
                    )}
                  </Fragment>
                ))}
              </View>
            </View>
            <View style={Styles.SignatureBox} fixed>
              <View>
                <View style={[Styles.doctorsView, { marginBottom: 40 }]}>
                  <Text style={[Styles.doctorDetails]}>Signature: </Text>
                  <View style={Styles.underline}>
                    {prescriptionHistory.isESigned && (
                      <View style={{ marginLeft: "5px", marginBottom: "5px" }}>
                        <Text style={Styles.textXsStrong}>
                          Electronically Signed
                        </Text>
                        <Text
                          style={Styles.textXs}
                        >{`${doctorInfo.displayName}, ${doctorInfo.title}`}</Text>
                      </View>
                    )}
                  </View>
                </View>
                <View style={Styles.doctorsView}>
                  <Text style={[Styles.doctorDetails]}>Prescribed by: </Text>
                  <Text
                    style={Styles.doctorsText}
                  >{`${doctorInfo.displayName}, ${doctorInfo.title}`}</Text>
                </View>
                <View style={Styles.doctorsView}>
                  <Text style={[Styles.doctorDetails]}>NPI#: </Text>
                  <Text style={Styles.doctorsText}>--</Text>
                </View>
                <View style={Styles.doctorsView}>
                  <Text style={[Styles.doctorDetails]}>LIC#: </Text>
                  <Text style={Styles.doctorsText}>--</Text>
                </View>
                <View style={Styles.doctorsView}>
                  <Text style={[Styles.doctorDetails]}>DEA#: </Text>
                  <Text style={Styles.doctorsText}>--</Text>
                </View>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
      <div
        style={{
          display: "flex",
          AlignItems: "center",
          justifyContent: "center",
          padding: "10px",
          background: "#37373a",
          boxShadow: "0px 0px 4px 0px #000000",
        }}
      >
        <button
          type="button"
          className="btn btn-hubchart"
          style={{
            fontSize: "12px",
            padding: "3px 10px",
            height: "33px",
            marginRight: "5px",
          }}
          onClick={() => {
            if (fromMedications) {
              copyPrescriptionHistoryLink();
            } else {
              copyPrescriptionLink();
            }
          }}
        >
          Copy Link
        </button>
        {!prescriptionHistory.isESigned && (
          <button
            type="button"
            className="btn btn-hubchart-success"
            style={{
              fontSize: "12px",
              padding: "3px 10px",
              height: "33px",
              color: "#FFFFFF",
            }}
            onClick={() => {
              requestOTP(doctorInfo.username)(dispatch);
              setIsESignedOpen(true);
            }}
          >
            E-Sign
          </button>
        )}
      </div>
      <ToastContainer />
      <Loader isLoading={isPrescriptionHistoryLoading} />
      {isESignedOpen && (
        <RequirePasswordESign
          onClose={(otp) => {
            if (otp) {
              handleESignPDF(otp);
            } else {
              setIsESignedOpen(false);
            }
          }}
          title="Password Required"
          message="You need to enter a OTP to electronically sign this document."
        />
      )}
    </Fragment>
  );
}

export default PrescriptionPDF;
