import React from 'react'

const Spinner = (props) => {
    const Style = {
        height: "100%",
        display: "flex",
        position: "relative",
        top: "50%"
    }

    if (props.top) {
        Style.top = props.top;
    }

    return (
        <div style={Style}>
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Spinner;