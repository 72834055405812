import React, { useEffect, useState } from 'react';
import Ringtone from '../../../assets/audio/ringtone.wav';
import { Howl } from 'howler';

const CallRingSound = () => {

    const [sound, setSound] = useState(null);

    useEffect(() => {
        
        const newSound = new Howl({
            src: [Ringtone],
            autoplay: true,
            loop: true,
            autoUnlock : true,
            usingWebAudio : true,
            onplayerror: () => {
                newSound.once('unlock', function() {
                    newSound.play();
                });
            }
        });

        setSound(newSound);
        
        newSound.play();
        
        return () => {
            newSound.stop();
        };
    }, []);

    useEffect(() => {
        if (sound) {
            // Turn off sound after 60 minutes of playing
            const soundTimeout = setTimeout(() => {
                sound.stop();
            }, 60 * 1000);

            return () => {
                clearTimeout(soundTimeout);
            }
        }
    }, [sound]);

    return <div style={{display: 'none'}}></div>;
}

export default CallRingSound;
