import React from 'react'
import defaultImage from '../../assets/images/default.png'

const ProfilePic = ({url, size=50}) => {

  const styles = {
    borderRadius: '50%',
    width: `${size}px`,
    height: `${size}px`,
    overflow: 'hidden',
  }

  const imgStyles = {
    width: '100%',
    height: '100%',
  }

  const imageUrl = url || defaultImage;

  return (
    <div style={styles}>
      <img src={imageUrl} className="img-fluid"></img>
    </div>
  )
}

export default ProfilePic;
