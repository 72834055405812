import { revenueAction } from './REST'
import { REVENUE_CAT_SUBSCRIBER } from './types'
import Parse from 'parse'

export const fetchSubscriber = () => dispatch => {
    return new Promise((resolve, reject) => {
        let currentObjectId = Parse.User.current().id;
        revenueAction("get",'https://api.revenuecat.com/v1/subscribers/'+currentObjectId)
        .then(result => {
            dispatch({
                type : REVENUE_CAT_SUBSCRIBER,
                subscriber : result
            });
        })
        .catch(error => {
            console.log(error);
        });
    })

}