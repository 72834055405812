import React, { Component } from 'react'

const $ = window.$;
export class DeleteForEveryoneModal extends Component {

    componentDidMount() {
        if (this.props.isOpen) {
            $("#removeMessageForEveryone").modal('show');
        } else {
            $("#removeMessageForEveryone").modal('hide');
        }
        $("#removeMessageForEveryone").on('hidden.bs.modal', function (e) {
            this.props.modalClose();
        }.bind(this))
    }

    confirm() {
        this.props.confirmDeleteForEveryone();
        $("#removeMessageForEveryone").modal('hide');
    }
    render() {
        return (
            <div id="removeMessageForEveryone" className="modal" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-body" style={{paddingBottom: "0px"}}>
                        <h5 style={{color: "#D84B55"}}>Are you sure?</h5>
                        <p style={{fontSize: "0.9em"}}>This message will be delete permanently. Message that has been deleted will not be able to retrieve.</p>
                    </div>
                    <div className="modal-footer" style={{border: "0", padding: "0 20px 20px 0"}}>
                        <button onClick={this.confirm.bind(this)} type="button" className="btn btn-hubchart btn-hubchart-danger">Delete</button>
                        <button type="button" className="btn btn-hubchart btn-hubchart-close" data-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DeleteForEveryoneModal
