import React, { useCallback, useState, Fragment } from "react";
import { useDispatch, connect } from "react-redux";

import "rc-time-picker/assets/index.css";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import { isAndroid, isIOS, isWindows } from "react-device-detect";

const $ = window.$;

const DownloadApp = ({}) => {
  const width = window.innerWidth;

  function handleDowloadApp() {
    const url_googlePlay =
      "https://play.google.com/store/apps/details?id=apps.synchronytelemed.hubchart&hl=en";
    const url_itunes =
      "https://itunes.apple.com/us/app/hubchart/id991641284?ls=1&mt=8";

    if (isIOS) {
      window.open(url_itunes, "_blank", "noopener,noreferrer");
    } else {
      if (width <= 768 || isAndroid || isWindows) {
        window.open(url_googlePlay, "_blank", "noopener,noreferrer");
      }
    }
  }

  return (
    <Fragment>
      <div className="conference-main-container">
        <button
          type="button"
          onClick={() => handleDowloadApp()}
          className="tel-flex download-cls"
          data-toggle="tooltip"
          data-placement="top"
          data-html="true"
          title="Download App"
        >
          <div style={{ position: "relative" }}>
            {((width <= 768 && !isIOS) || isAndroid) && (
              <img
                style={{ width: "100px", height: "33px" }}
                src={require("../../../assets/images/google.png")}
              />
            )}
            {isIOS && (
              <img
                style={{ width: "100px", height: "33px" }}
                src={require("../../../assets/images/apple.png")}
              />
            )}
          </div>
        </button>
      </div>
    </Fragment>
  );
};

export default DownloadApp;
