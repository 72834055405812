import React, { Component } from "react";
import PropTypes from "prop-types";

class OverlayPlaceholder extends Component {
  render() {
    const { show, text, children, background } = this.props;

    const containerStyles = {
      position: "absolute",
      top: "0",
      left: "0",
      display: show ? "flex" : "none",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
      background
    };

    return (
      <div style={containerStyles}>
        {text ? <p className="text-grey font-italic">{text}</p> : children}
      </div>
    );
  }
}

OverlayPlaceholder.propTypes = {
  show: PropTypes.bool.isRequired
};

export default OverlayPlaceholder;
