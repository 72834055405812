import React, { Component } from 'react'
import '../../Pages/Login/Login.css'

const $ = window.$;

export default class LeaveModal extends Component {

  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    if (this.props.isOpen) {
      $("#leaveModal").modal({
        backdrop: 'static',
        keyboard: false
      });
      $("#leaveModal").modal('show');
    } else {
      $("#leaveModal").modal('hide');
    }

    $("#leaveModal").on('hidden.bs.modal', function (e) {
      this.props.modalClose();
    }.bind(this))
  }

  getSnapshotBeforeUpdate() {
    if (this.props.isOpen) {
      $("#leaveModal").modal({
        backdrop: 'static',
        keyboard: false
      });
      $("#leaveModal").modal('show');
    } else {
      $("#leaveModal").modal('hide');
    }
  }

  handleClose() {
    $("#leaveModal").modal('hide');
  }

  handleLeave() {
    this.props.onLeave();
    $("#leaveModal").modal('hide');
  }

  handleEnd() {
    this.props.onEnd();
    $("#leaveModal").modal('hide');
  }

  render() {
    return (
      <div id="leaveModal" className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button onClick={(e) => this.handleLeave()} className="btn btn-block btn-primary">Leave call</button>
              {this.props.isHost ?
                <button onClick={(e) => this.handleEnd()} className="btn btn-block btn-danger">End call</button>
                : ''
              }
              <button onClick={(e) => this.handleClose()} className="btn btn-block btn-light">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
