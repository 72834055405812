import React from 'react';
import PatientInfo from '../PatientInfo';
import ChiefComplaints from '../ChiefComplaints';
import Rte from '../Rte';
import Orders from '../Orders';
import AddendumNote from '../AddendumNote';
import ProcedureNotes from '../ProcedureNotes';
import FollowUps from '../FollowUps';
import CptCode from '../CptCode';
import ESign from '../ESign';

const $ = window.$;

function SoapNoteView({
  progressNote = '',
  patient = {},
}) {

  const {
    rte,
    chiefComplaints,
    dateSeen,
    encounterType,
    addendumNote = '',
    procedureNotes = '',
    followUps = {},
    cptCode = '',
    posCode = '',
    modifierCode = '',
    labs = {},
    status
  } = progressNote;

  return (
    <div className="note-view">
      <div className="container">
        <div id="section-to-print">
          <div className="pn-container">
            <div className="pn-header">
              <span>Vital Health Medical Group</span>
              <span className="hc-primary-text">6245 2nd flr De Longpre Ave, Hollywood, CA 90028</span>
              <span className="hc-primary-text">Tel no. (323)315-0911 Fax (323)</span>
            </div>
            <PatientInfo patient={patient} dateOfService={dateSeen} encounterType={encounterType} />
            <div style={{ textAlign:'center' }}>
              <h2>Progress Note</h2>
            </div>
            <ChiefComplaints chiefComplaints={chiefComplaints} />
            <Rte rte={rte} />
            <Orders orders={labs} />
            <AddendumNote addendumNote={addendumNote} />
            <ProcedureNotes procedureNote={procedureNotes} />
            <div className="two-columns">
              <FollowUps followUps={followUps} />
              <CptCode 
                cptCode={cptCode} 
                encounterType={encounterType}
                posCode={posCode}
                modifierCode={modifierCode} />
            </div>
            <hr style={{ margin: '10px 0px' }} />
            <div className="two-columns">
              <div />
              <ESign
                dateOfService={dateSeen} 
                prescriber={patient.prescriber} 
                status={status}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SoapNoteView;
