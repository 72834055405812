import React, { useState, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import useContacts from './useContacts';
import Item from './Item';
import TabLoader from '../../../../Common/TabLoader';
import SearchInput from './SearchInput';
import { searchContacts } from '../../../../../actions/ContactAction';

const ContactSelect = ({ onSelect, exclude }) => {

  const dispatch = useDispatch();

  const {
    isLoading,
    searchItems,
    items,
  } = useContacts();

  const [isSearching, setIsSearching] = useState(false);

  // Choose whether to display contact list or search results
  const toFilter = useMemo(() => {
    return isSearching ? searchItems : items;
  }, [items, searchItems, isSearching])

  // Filter out excluded contacts
  const filteredItems = useMemo(() => {
    return toFilter.filter(item => {
      return !exclude.find(contact =>
        contact.objectId === item.objectId
      );
    });
  }, [toFilter, exclude]);

  const isEmpty = isSearching && !isLoading && !searchItems.length;

  const handleSearch = (keyword) => {

    setIsSearching(true);

    dispatch(
      searchContacts(keyword)
    );
  }

  const handleClear = () => {
    setIsSearching(false);
  }

  // Add the first search result to selected when pressing enter
  const handlePressEnter = useCallback(() => {

    // if (!isSearching) return;
    
    const firstItem = filteredItems[0];

    if (firstItem) onSelect(firstItem);

  }, [isSearching, filteredItems]);

  return (
    <div className="d-flex flex-column h-100">
      <div className="py-3">
        <SearchInput data-tip data-for="contact-select-search" data-place="top" onSearch={handleSearch} onClear={handleClear} onPressEnter={handlePressEnter} />
        <ReactTooltip
          id="contact-select-search"
          effect="solid"
          place="bottom">
          <span>
            <i className="fas fa-lightbulb-on mx-1 text-warning"></i>
            Tip: Press [<i className="fas fa-level-down-alt fa-rotate-90 mx-1"></i> Enter] key to select first item
          </span>
        </ReactTooltip>
      </div>
      <div className="h-100" style={{overflowY: 'auto', position: 'relative'}}>
        {!isLoading ?
          <div className="mycard-1 py-0 w-100" style={{position: 'absolute'}}>
            <div className="list-group">
              {filteredItems.map(contact =>
                <Item
                  key={contact.objectId}
                  contact={contact}
                  onSelect={(e) => onSelect(contact)} />
              )}
            </div>
          </div>
          : ''
        }
        <NoResults show={isEmpty} />
        <TabLoader isLoading={isLoading} />
      </div>
    </div>
  );
};

const NoResults = ({ show }) => {

  if (!show) return '';

  return (
    <div style={{ height: '100%' }} className="d-flex flex-column justify-content-center">
      <span className="text-empty">No results</span>
    </div>
  )
};

export default ContactSelect;
