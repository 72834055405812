import React, { Component } from 'react'

export class CertifiedSubscription extends Component {

    constructor() {
        super();
        this.state = {
            planPeriod: "monthly"
        }
    }

    componentDidMount() {

    }

    render() {
        let _plan = this.state.planPeriod === "monthly" ? this.props.planMonthly : this.props.planAnnual;
        let detailList = _plan.details.split("\n");
        let _detailList = detailList.map((detail, i) => {
            return <p key={i} style={{margin: "0", width: "100%"}}>
                <i className="fa fa-check" style={{color: "rgb(125, 185, 99)"}}></i>
                {detail}
            </p>
        })
        return (
            <div>
                <a href="#!" style={{fontSize: "20px", margin: "0", padding: "0", position: "fixed" ,top: "12px", zIndex: "2001"}} onClick={this.props.back.bind(this, "")}>
                    <i className="fas fa-chevron-left"></i>
                </a>
                <h4 style={{textAlign: "center", color: "rgb(135, 207, 254)"}}>Certified User</h4>
                <div className="row">
                    <img style={{height: "150px", margin: "0 auto"}} src={_plan.itemBadge.url}/>
                </div>

                <div style={{padding: "0 20px"}}>
                    <p className="hc-primary-text" style={{textAlign: "center", fontSize: "18px", marginTop: "15px", fontWeight: "500"}}>You are qualified of the following benefits</p>
                </div>

                <div style={{padding: "0 75px"}}>
                    {_detailList}
                </div>
            </div>
        )
    }
}

export default CertifiedSubscription
