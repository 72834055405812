import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getAllToDos,
  setActiveThread,
  setActiveTab,
  getAllCritical
} from "../../../../actions/ThreadAction";
import OverlayLoader from "../../../Common/OverlayLoader";
import OverlayPlaceholder from "../../../Common/OverlayPlaceholder";
import moment from "moment";
import Modal from "../../../Common/Modal";

const $ = window.$;
export class CriticalModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      allToDosPendingCritical: [],
    };
  }

  componentDidMount() {
    this.props.getAllCritical();

    if (this.props.isOpen) {
      $("#criticalModal").modal("show");
    } else {
      $("#criticalModal").modal("hide");
    }
  }

  componentDidUpdate() {
    if (this.props.isOpen) {
      $("#criticalModal").modal("show");
    } else {
      $("#criticalModal").modal("hide");
    }
  }

  render() {
    const { allToDosPendingCritical = [] } = this.props;

    return (
      <Modal shown={this.props.isOpen} onHide={this.props.onClose}>
        <ModalHeader />
        <ModalBody
          style={{ height: "80%", overflowY: "auto", padding: "10px 5px" }}
        >
          <div className="card-body px-3" style={{ padding: "0" }}>
            {allToDosPendingCritical.map((user) => {
              if (user.status !== "DONE") {
                return (
                  <Item
                    key={user.objectId}
                    data={user}
                    openThread={this.props.openThread}
                  />
                );
              }
            })}
          </div>
          <OverlayPlaceholder
            show={!this.state.isLoading && !allToDosPendingCritical.length}
            text="No Critical Patient"
          />
          <OverlayLoader isLoading={this.state.isLoading} />
        </ModalBody>
        {/* <ModalFooter>
        <button 
          className="btn btn-hubchart btn-hubchart-close" 
          onClick={this.props.onClose}>
          Close
        </button>
      </ModalFooter> */}
      </Modal>
    );
  }
}

const Item = ({ data, openThread }) => {
  return (
    <a
      className="list-group-item list-group-item-action flex-column align-items-start"
      style={{ marginLeft: "0px" }}
      onClick={() => openThread(data)}
      title={data.text}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span className="name m-0">
          {data.threadName}{" "}
          {" (" + `${moment(data.due_date.iso).format('LL') }` + ")"}
          {/* <i className="fas fa-edit" style={{float:'right'}}></i> */}
        </span>
        <label 
          style={{
            fontSize:'12px',
            opacity:0.4,
            display: 'flex',
            margin:'0px',
            height:"15px",
            whiteSpace:"pre-line",
            overflow:"hidden"}}>
              {data.text}
          </label>
      </div>
    </a>
  );
};

const ModalHeader = () => {
  return (
    <div className="modal-header">
      <h5 className="text-danger mb-0" style={{ fontSize: "1.25rem" }}>
        Critical Patients
      </h5>
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
        onClick={() => this.props.onClose()}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

const ModalBody = ({ children, style = {} }) => {
  const styles = {
    ...style,
  };

  return (
    <div className="modal-body" style={styles}>
      {children}
    </div>
  );
};

const ModalFooter = ({ children }) => {
  return <div className="modal-footer">{children}</div>;
};

const mapStateToProps = (state) => ({
  threads: state.thread.threads,
  allToDosPendingCritical: state.thread.allToDosPendingCritical,
  activeTab: state.thread.activeTab,
});

export default connect(mapStateToProps, {
  getAllToDos,
  setActiveThread,
  setActiveTab,
  getAllCritical
})(CriticalModal);
