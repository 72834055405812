import React, { useEffect } from "react";
import { fetchSelectedThread } from "../../../../actions/ThreadAction";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import ActionsComponent from "./ActionsComponent";

const ActionsView = ({ threadId = "", isCritical = false }) => {
  const dispatch = useDispatch();
  const { selectedThread } = useSelector(mySelector, shallowEqual);

  useEffect(() => {
    if (threadId) {
      fetchSelectedThread(threadId)(dispatch);
    }
  }, [threadId]);

  return (
    <div className="componentContainer">
      {selectedThread.objectId && threadId && !isCritical && <ActionsComponent thread={selectedThread} threadId={threadId} />}
    </div>
  );
};

const mySelector = (state) => ({
  selectedThread: state.thread.selectedThread,
});

export default ActionsView;
