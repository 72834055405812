import React, { useCallback, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Notify from './Notify';

const useRoom = () => {

  const dispatch = useDispatch();

  const room = useSelector(state => state.activeConference.room);

  const setRoom = (room) => {
    dispatch({
      type: 'SET_ROOM',
      room,
    });
  }

  const sendData = useCallback(data => {

    if (!room) {
      return Notify.error('sendData(): Cannot send data when room does not exist.');
    }
    
    const message = JSON.stringify(data);

    // Get the LocalDataTrack that we published to the room.
    const [localDataTrackPublication] = [...room.localParticipant.dataTracks.values()];

    // Send the message
    localDataTrackPublication.track.send(message);
  }, [room]);

  return {
    room,
    setRoom,
    sendData,
  }
}

export default useRoom;
