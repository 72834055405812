import React, { Component } from 'react';
import PropTypes from 'prop-types'
import Spinner from './Spinner';

class OverlayLoader extends Component {

    render() {
        const {isLoading, background='#fff'} = this.props

        const containerStyles = {
            position: 'absolute',
            top: '0',
            left: '0',
            display: isLoading ? "flex" : "none",
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            background,
        };

        return (
            <div style={containerStyles}>
                <Spinner
                    size={5}
                    loading={true} />
            </div>
        );
    }
}

OverlayLoader.propTypes = {
    isLoading: PropTypes.bool.isRequired,
};

export default OverlayLoader;
