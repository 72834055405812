import React, { useEffect, useMemo } from 'react';
import Parse from 'parse';
import OverlayLoader from './Common/OverlayLoader';

const Auth = ({ sessionToken, next, history }) => {
  const nextRoute = useMemo(() => {
    return next ? decodeURIComponent(next) : '/';
  }, [next]);

  const injectUser = async () => {
    try {
      await Parse.User.become(sessionToken);
      history.push(nextRoute);
    } catch (err) {
      console.log(err);
      history.push('/');
    }
  };

  useEffect(() => {
    injectUser();
  }, []);

  return (
    <div>
      <h1>Authenticating...</h1>
      <h2>Token: {sessionToken}</h2>
      <h2>Next: {nextRoute}</h2>
      <OverlayLoader isLoading={true} />
    </div>
  );
};

export default Auth;
