import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchPatients,
  getPatients,
  searchPatients,
  searchStatus,
  fetchLabel,
  fetchInsuranceProviders,
} from "../../../actions/GroupAction";
import { createThread, fetchThreads } from "../../../actions/ThreadAction";
import PatientList from "./PatientList";
import LabelNavContainer from "./LabelNavContainer";
import OverlayLoader from "../../Common/OverlayLoader";

export class PatientContainer extends Component {
  constructor() {
    super();
    this.state = {
      isLoadingMorePatients: false,
      preventReload: false,
      openNav: false,
      label: {},
      patientListShown: false,
      searchTimeout: null,
      isLoadingSearch: false,
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.closeNav = this.closeNav.bind(this);
    // this.fetchAllPatients = this.fetchAllPatients.bind(this);
  }

  componentDidMount() {
    this.props.fetchPatients("patient", false, 0, undefined, undefined, undefined, '');
    // this.props.fetchThreads();
    if (!this.props.facilitiesInitialLoad) {
      this.props.fetchLabel("Facility", "facility");
    }
    if (!this.props.insuranceInitialLoad) {
      /* this.props.fetchLabel("Insurance", "primaryInsurance");
            this.props.fetchLabel("Insurance", "secondaryInsurance");
            this.props.fetchLabel("Insurance", "otherInsurance"); */
      this.props.fetchInsuranceProviders();
    }
    this.content.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    this.content.removeEventListener("scroll", this.handleScroll);
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (this.props.typeValue !== "") {
      if (prevProps.typeValue !== this.props.typeValue) {
        // Everytime the user types something in search input...
        const { searchTimeout } = this.state;

        // Clear the currently running timer
        // Reset it basically
        if (searchTimeout) {
          clearTimeout(searchTimeout);
        }

        // Create new one
        const newSearchTimeout = setTimeout(() => {
          this.setState({ isLoadingSearch: true });

          this.props
            .searchPatients(this.props.typeValue)
            .then((res) =>
              this.setState({
                isLoadingSearch: false,
                isLoadingMorePatients: false,
              })
            )
            .catch((err) =>
              this.setState({
                isLoadingSearch: false,
                isLoadingMorePatients: false,
              })
            );
        }, 500);

        // Update the state with the new timeout
        this.setState({ searchTimeout: newSearchTimeout });
      }
    } else {
      if (this.props.isSearchingPatient) {
        this.props.searchStatus(false);
      }
    }

    return null;
  }

  componentDidUpdate() {}

  fetchAllPatients() {
    this.setState((prevState) => ({
      patientListShown: !prevState.patientListShown,
    }));

    if (!this.props.patientInitialLoad) {
      this.props.fetchPatients("patient", false);
    }
  }

  handleScroll(event) {
    if (this.state.preventReload) return;
    let initialHeight = event.target.scrollHeight;
    let height = event.target.offsetHeight;
    let scrollTop = event.target.scrollTop;
    if (scrollTop + height + 10 >= initialHeight) {
      /* console.log(`${scrollTop + height + 10} >= ${initialHeight}`);

            console.log('isLoadingMore | preventReload');
            console.log(`${this.state.isLoadingMorePatients} | ${this.state.preventReload}`);

            console.log('hasMore');
            console.log(this.props.pagination.hasMore); */

      if (!this.state.isLoadingMorePatients && !this.state.preventReload) {
        this.setState({
          isLoadingMorePatients: true,
        });

        if (this.props.typeValue && this.props.pagination.hasMore) {
          this.searchMorePatients();
        } else {
          this.loadMorePatients();
        }
      }
    }
  }

  openLabelNav(label) {
    // console.log(label);
    this.setState({
      openNav: true,
      label: label,
    });
  }

  closeNav() {
    this.setState({
      openNav: false,
      label: {},
    });
  }

  loadMorePatients() {
    this.props
      .fetchPatients("patient", true, this.props.page)
      .then((items) => {
        if (items.length === 0) {
          this.setState({
            preventReload: true,
          });
        }
        this.setState({
          isLoadingMorePatients: false,
        });
      })
      .catch(() => {
        this.setState({
          isLoadingMorePatients: false,
        });
      });
  }

  searchMorePatients() {
    const { typeValue, pagination } = this.props;

    this.setState({ isLoadingMorePatients: true });

    this.props
      .searchPatients(typeValue, pagination.page + 1)
      .then((res) =>
        this.setState({
          isLoadingMorePatients: false,
        })
      )
      .catch((err) =>
        this.setState({
          isLoadingMorePatients: false,
        })
      );
  }

  render() {
    const { isLoadingSearch, patientListShown } = this.state;
    const { pagination, isLoadingPatients, insuranceProviders } = this.props;

    return (
      <div className="Tab-Container mb-0" ref={(ul) => (this.content = ul)}>
        <PatientList
          {...this.props}
          hasMore={pagination.hasMore}
          isLoadingSearch={isLoadingSearch}
          isLoadingMorePatients={this.state.isLoadingMorePatients}
          openLabelNav={this.openLabelNav.bind(this)}
          // fetchAllPatients={this.fetchAllPatients}
          patientListShown={patientListShown}
          isLoadingPatients={isLoadingPatients}
          insuranceProviders={insuranceProviders}
        />
        <OverlayLoader isLoading={isLoadingSearch} background="rgba(0, 0 ,0, 0.1)" />
        <LabelNavContainer
          isOpen={this.state.openNav}
          closeNav={this.closeNav}
          label={this.state.label}
          fetchPatients={this.props.fetchPatients}
          createThread={this.props.createThread}
          history={this.props.history}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoadingPatients: state.group.isLoadingPatients,
  patientInitialLoad: state.group.patientInitialLoad,
  patients: state.group.patients,
  filterPatientResult: state.group.filterPatientResult,
  isSearchingPatient: state.group.isSearchingPatient,
  page: state.group.patientPage,
  facilities: state.group.facilities,
  facilitiesFetchStatus: state.group.facilitiesFetchStatus,
  facilitiesInitialLoad: state.group.facilitiesInitialLoad,
  insurance: state.group.insurance,
  insuranceFetchStatus: state.group.insuranceFetchStatus,
  insuranceInitialLoad: state.group.insuranceInitialLoad,
  pagination: state.group.pagination,
  insuranceProviders: state.group.insuranceProviders,
});

export default connect(mapStateToProps, {
  fetchPatients,
  getPatients,
  createThread,
  fetchThreads,
  searchPatients,
  searchStatus,
  fetchLabel,
  fetchInsuranceProviders,
})(PatientContainer);
