import React, { useEffect, Fragment } from 'react';

import './index.css';

function PhysicalExamComponent({
  physicalExam = {},
  templateType = '',
}) {
  
  useEffect(() => {
    return () => {}
  }, []);

  return (
    <div className="card custom-card">
      <h5 className="card-header ">
        <strong>PHYSICAL EXAM</strong>
      </h5>
      <div className="card-body">
        <div className="custom-form-group-container">
          {physicalExam.general && <div className="pe-text">
            <strong>General</strong>
            <div>{physicalExam.general}</div>
          </div>}
          {physicalExam.head && <div className="pe-text">
            <strong>Head</strong>
            <div>{physicalExam.head}</div>
          </div>}
          {physicalExam.eyes && <div className="pe-text">
            <strong>Eyes</strong>
            <div>{physicalExam.eyes}</div>
          </div>}
          {physicalExam.ent && <div className="pe-text">
            <strong>Ear, Nose, Mouth and Throat</strong>
            <div>{physicalExam.ent}</div>
          </div>}
          {physicalExam.neck && <div className="pe-text">
            <strong>Neck</strong>
            <div>{physicalExam.neck}</div>
          </div>}
          {physicalExam.chestAndLungs && <div className="pe-text">
            <strong>Chest and Lungs</strong>
            <div>{physicalExam.chestAndLungs}</div>
          </div>}
          {physicalExam.breast && <div className="pe-text">
            <strong>Breast</strong>
            <div>{physicalExam.breast}</div>
          </div>}
          {physicalExam.cardiovascular && <div className="pe-text">
            <strong>Cardiovascular</strong>
            <div>{physicalExam.cardiovascular}</div>
          </div>}
          {physicalExam.abdomen && <div className="pe-text">
            <strong>Abdomen</strong>
            <div>{physicalExam.abdomen}</div>
          </div>}
          {/* {templateType === 'default' && ( */}
            <Fragment>
              {physicalExam.back && <div className="pe-text">
                <strong>Back</strong>
                <div>{physicalExam.back}</div>
              </div>}
              {physicalExam.genitourinary && <div className="pe-text">
                <strong>Genitourinary</strong>
                <div>{physicalExam.genitourinary}</div>
              </div>}
              {physicalExam.rectal && <div className="pe-text">
                <strong>Rectal</strong>
                <div>{physicalExam.rectal}</div>
              </div>}
              {/* {physicalExam.musculoskeletal && <div className="pe-text">
                <strong>Musculoskeletal</strong>
                <div>{physicalExam.musculoskeletal}</div>
              </div>} */}
            </Fragment>
          {/* )} */}
          {physicalExam.upperExtrimities && <div className="pe-text">
            <strong>Upper Extremities</strong>
            <div>{physicalExam.upperExtrimities}</div>
          </div>}
          {physicalExam.lowerExtrimities && <div className="pe-text">
            <strong>Lower Extremities</strong>
            <div>{physicalExam.lowerExtrimities}</div>
          </div>}
          {/* {physicalExam.skin && templateType === 'default'  && ( */}
            <div className="pe-text">
              <strong>Skin</strong>
              <div>{physicalExam.skin}</div>
            </div>
          {/* )} */}
          {physicalExam.neurologic && <div className="pe-text">
            <strong>Neurologic</strong>
            <div>{physicalExam.neurologic}</div>
          </div>}
          {physicalExam.psychiatrist && <div className="pe-text">
            <strong>Psychiatric Eval</strong>
            <div>{physicalExam.psychiatrist}</div>
          </div>}
        </div>
      </div>
    </div>
  );
}

export default PhysicalExamComponent;