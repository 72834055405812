import React, { useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import moment from 'moment';
import { duration_strings } from '../../../Conversation/Setting/PrescriptionModal/config';

import './index.css';

function FollowUpsComponent({
  followUps = {
    in: 0,
    duration: [],
    date: '',
    provider: '',
  },
  updateFollowUps = () => {},
  isDisabled = false,
}) {
  const minDate = moment(new Date()).format("YYYY-MM-DD");
  const ff = {
    ...followUps,
    date: moment(followUps.date || '').format("YYYY-MM-DD"),
  }

  function handleOnChange (e) {
    e.preventDefault();
    const { name, value } = e.target;
    updateFollowUps(name, value);
  }

  function handleDurationOnChange (name, value) {
    updateFollowUps(name, value);
  }
  
  useEffect(() => {
    return () => {}
  }, []);

  return (
    <div className="custom-form-group-container">
      <div className="custom-form-group">
        <span htmlFor="in">Follow Up In</span>
        <input 
          id="in" 
          name="in" 
          type="number" 
          className="form-control" 
          placeholder="Follow Up" 
          onChange={e => handleOnChange(e)}
          value={ff.in} 
          disabled={isDisabled}
        />
      </div>
      <div className="custom-form-group">
        <span htmlFor="duration">Duration</span>
        <Typeahead
          id="duration"
          placeholder="Duration"
          onChange={selected => {
            handleDurationOnChange('duration', selected);
          }}
          labelKey="text"
          options={duration_strings}
          maxResults={999999999}
          selected={ff.duration}
          inputProps={{ 
            disabled: isDisabled 
          }}
        />
      </div>
      <div className="custom-form-group">
        <span htmlFor="provider">Follow Up Date</span>
          <input 
            id="date" 
            name="date" 
            type="date" 
            className="form-control" 
            placeholder="Follow Up Date" 
            onChange={e => handleOnChange(e)}
            min={minDate}
            value={ff.date}
            disabled={true}
            disabled={isDisabled} />
      </div>
      <div className="custom-form-group">
        <span htmlFor="provider">FF-up w/ Provider</span>
        <input 
          id="provider" 
          name="provider" 
          type="text" 
          className="form-control" 
          placeholder="FF-up w/ Provider" 
          onChange={e => handleOnChange(e)}
          value={ff.provider || ''}
          disabled={isDisabled} />
      </div>
    </div>
  );
}

export default FollowUpsComponent;