import React, { useCallback, useState, Fragment } from "react";
import { useDispatch, connect } from "react-redux";
import ConferenceApi from "../../../api/Conference";
import Notify from "../../Common/Notify";
import ConferenceHelper from "../../../helper/conference";
import ManageEventModal from "./ManageEventModal";

import "rc-time-picker/assets/index.css";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import { SET_OPEN_CRITICAL } from "../../../actions/types";
import { launchTelemed } from "../../../actions/ConferenceAction";
import { isAndroid, isIOS, isWindows } from "react-device-detect";

const $ = window.$;

const ConferenceMain = ({ handleOpenPrescription }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [manageEventModalShown, setManageEventModalShown] = useState(false);
  const width = window.innerWidth;

  const startConference = (selected = []) => {
    const participantIds = selected.map(s => {
      return s.objectId;
    });

    createConference(participantIds);
  };

  const createConference = useCallback(participantIds => {
    dispatch(launchTelemed(participantIds));
  });

  function handleOpenCritical() {
    dispatch({
      type: SET_OPEN_CRITICAL,
      value: true
    })
  }

  function handleScheduleTelemed() {
    setManageEventModalShown(true);
  }

  function handleCloseManageModal() {
    setManageEventModalShown(false);
  }

  return (
    <Fragment>
      <div className="conference-main-container">
        <button
          type="button"
          onClick={() => handleOpenCritical()}
          className="btn btn-danger hc-bg-danger tel-flex start-telemed-cls"
          data-toggle="tooltip"
          data-placement="top"
          data-html="true"
          title="Open Critical Task"
        >
          <i
            className="fas fa-business-time"
            style={{ marginTop: "3px" }}
          ></i>
          <span>Critical Task</span>
        </button>
        <button
          type="button"
          // className="btn btn-sm btn-primary"
          onClick={() => handleOpenPrescription()}
          className="btn btn-primary hc-bg-primary tel-flex start-telemed-cls"
          data-toggle="tooltip"
          data-placement="top"
          data-html="true"
          title="Create Prescription"
        >
          <i
            className="fad fa-fw fa-prescription fa-lg"
            style={{ marginTop: "3px" }}
          ></i>
          <span>Prescription</span>
        </button>
        <button
          type="button"
          onClick={() => startConference([])}
          className="btn btn-primary hc-bg-primary tel-flex start-telemed-cls"
          data-toggle="tooltip"
          data-placement="top"
          data-html="true"
          title="Start Telemed"
        >
          <i
            className="fad fa-fw fa-video fa-lg"
            style={{ marginTop: "3px" }}
          ></i>
          <span>Start Telemed</span>
        </button>
        <button
          type="button"
          className="btn btn-secondary tel-flex schedule-telemed-cls"
          onClick={() => handleScheduleTelemed()}
          data-toggle="tooltip"
          data-placement="top"
          data-html="true"
          title="Schedule Telemed"
        >
          <i
            className="fad fa-fw fa-calendar fa-lg text-white"
            style={{ marginTop: "3px" }}
          ></i>
          <span>Schedule Telemed</span>
        </button>
        {(width <= 768 || isAndroid || isIOS) && (
          <button
            type="button"
            className="tel-flex download-cls"
          >
            <div style={{position: 'relative'}}>
              <img
                style={{ width: "100px", height: "33px" }}
              />
            </div>
          </button>
        )}
      </div>
      {manageEventModalShown && (
        <ManageEventModal
          isOpen={manageEventModalShown}
          onClose={() => handleCloseManageModal()}
        />
      )}
    </Fragment>
  );
};

export default ConferenceMain;
