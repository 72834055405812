import React, { Component } from 'react';
import { connect } from 'react-redux';
import { parseAction } from '../../../actions/REST';
import { blockContact, unblockContact } from '../../../actions/ContactAction';
import Parse from 'parse';
import config from '../../../config';
import ContactProfile from '../../Pages/ContactProfile';

export class User extends Component {
  constructor() {
    super();
    this.state = {
      threadObjectId: '',
      activeThread: '',
      isFetchingUserData: false,
      userData: '',
      userRoster: {},
      isFetching: false,
      fetchUserResult: '',
    };

    this.blockUser = this.blockUser.bind(this);
    this.unblockUser = this.unblockUser.bind(this);
  }

  blockUser(user) {
    this.props
      .blockContact(user.objectId, this.props.thread.threadId)
      .then((roster) => {
        this.setState({
          userRoster: {
            ...this.state.userRoster,
            isBlocked: true,
          },
        });
      });
  }

  unblockUser(user) {
    this.props
      .unblockContact(user.objectId, this.props.thread.threadId)
      .then((roster) => {
        this.setState({
          userRoster: {
            ...this.state.userRoster,
            isBlocked: false,
          },
        });
      });
  }

  render() {
    const user = this.state.userData;
    let imageURL = require('../../../assets/images/default.png');
    if (user.picture) {
      if (user.picture.url) {
        imageURL = user.picture.url;
      }
    }
    let Name = '';
    if (user.displayName) Name = user.displayName;

    let statusStyle = {
      padding: '0',
      fontSize: '12px',
      fontWeight: '500',
      color: 'rgb(95, 93, 93)',
    };

    return (
      <div className="setting-container">
        <ContactProfile contactId={this.props.userId} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  blockContact,
  unblockContact,
})(User);
