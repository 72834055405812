import React from 'react'
import { useDrop } from 'react-dnd'
import { connect } from 'react-redux';
import { saveDocument } from '../../../actions/DocumentAction'

const style = {
    // height: '12rem',
    // width: '12rem',
    // marginRight: '1.5rem',
    // marginBottom: '1.5rem',
    // color: 'white',
    // padding: '1rem',
    // textAlign: 'center',
    // fontSize: '1rem',
    // lineHeight: 'normal',
    // float: 'left',
    // backgroundColor : 'transparent'
}

const Dropbox = (props) => {

    const [{ canDrop, isOver }, drop] = useDrop({
        accept: 'Box',
        drop: (item) => {
            props.saveDocument(item.data, props.target);
        },
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    })
    const isActive = canDrop && isOver
    let border = 'none'
    if (isActive) {
        border = '1px dashed darkgreen'
    }
    // else if (canDrop) {
    //     backgroundColor = 'darkkhaki'
    // }
    return (
        <div ref={drop} style={{ ...style, border }}>
            {/* {isActive ? 'Release to drop' : 'Drag a box here'} */}
            {props.children}
        </div>
    )
}

const mapStateToProps = state => ({

})

export default connect(mapStateToProps, {saveDocument})(Dropbox);
