import { HAS_A_CALLER, END_CALL } from "./types";
import { parseAction } from "./REST";
import Parse from "parse";
import config from "../config";

export const receiveCall = roomId => dispatch => {
  const url = config.BASE_URL + "/parse/functions/call_received";

  const payload = {
    room_id: roomId
  };

  parseAction("post", url, payload)
    .then(res => {})
    .catch(error => {
      console.log("Error on receiveCall");
    });
};

export const declineCall = roomId => dispatch => {
  const url = config.BASE_URL + "/parse/functions/call_declined";

  const payload = {
    room_id: roomId
  };

  parseAction("post", url, payload)
    .then(res => {
      console.log(res);
    })
    .catch(error => {
      console.log("Error on declineCall");
    });
};
