import React, { Component } from 'react'

export class CorporateSubscription extends Component {

    render() {
        return (
            <div>
                <a href="#!" style={{fontSize: "20px", margin: "0", padding: "0", position: "fixed" ,top: "12px", zIndex: "2001"}} onClick={this.props.back.bind(this, "")}>
                    <i className="fas fa-chevron-left"></i>
                </a>
                <h4 style={{textAlign: "center"}} className="hc-primary-text">Corporate Account Subscription</h4>
                <p style={{textAlign: "center", marginTop: "15px"}}>Set up your own organization so that all members of the organization get immediate access to all Patient Hubs that you created.</p>


                <button onClick={this.props.subscribeToPlan} className="btn btn-hubchart" style={{width: "100%", fontSize: "16px", margin: "0 auto", display: "block"}}>Subscribe</button>
            </div>
        )
    }
}

export default CorporateSubscription
