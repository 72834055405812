import React, { useMemo, useState } from 'react';

const Image = ({ url = '' }) => {

  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);

  const imageTransform = useMemo(() => {
    return `scale(${zoom})`;
  }, [zoom]);

  const containerTransform = useMemo(() => {
    return `rotate(${rotation}deg)`;
  }, [rotation]);

  const imageStyles = {
    height: '100%',
    transform: imageTransform,
    transformOrigin: 'top left',
  };

  const containerStyles = {
    transform: containerTransform,
    display: 'flex',
    flexDirection: 'row',
  };

  const controlStyles = {
    width: '100%',
    zIndex: 1,
    position: 'absolute',
    top: '0',
    left: '0',
    backgroundColor: 'rgba(0,0,0,0.6)'
  };

  const zoomIn = () => {

    // Allow 3x zoom only
    if (zoom > 3) return;

    setZoom(zoom => zoom + 1);
  };

  const zoomOut = () => {

    // Prevent 0 zoom
    if (zoom === 1) return;

    setZoom(zoom => zoom - 1);
  };

  const rotateLeft = () => {
    setRotation(r => r - 90);
  }

  const rotateRight = () => {
    setRotation(r => r + 90);
  }

  return (
    <div className="flex-column" style={{ backgroundColor: 'unset', width: '100%', overflow: 'auto' }}>
      <div className='d-flex justify-content-center' style={controlStyles}>
        <a href="#!" className="File-Action-Button no-underline" onClick={e => zoomIn()}>
          <i className="fas fa-search-plus mr-2"></i>
          <span style={{ fontSize: "18px" }}>Zoom in</span>
        </a>
        <a href="#!" className="File-Action-Button no-underline" onClick={e => zoomOut()}>
          <i className="fas fa-search-minus mr-2"></i>
          <span style={{ fontSize: "18px" }}>
            Zoom out
          </span>
        </a>
        <a href="#!" className="File-Action-Button" onClick={e => rotateLeft()}>
          <i className="fas fa-undo"></i>
        </a>
        <a href="#!" className="File-Action-Button" onClick={e => rotateRight()}>
          <i className="fas fa-undo fa-flip-horizontal"></i>
        </a>
      </div>
      <div className="Main-Image" style={containerStyles}>
        <img draggable="false" src={url} style={imageStyles} />
      </div>
    </div>
  );
}

export default Image;
