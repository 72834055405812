import React, { Component, Fragment } from "react";
import Draggable from "react-draggable";
import { Document, Page, pdfjs } from "react-pdf";
import moment from "moment";
import "./FilePreview.css";
import { getFileExtension } from "../../helper/util";
import ImageLoad from "../Common/ImageLoad2";

const PDFThumbnail = require("../../assets/images/pdf.png");
const defualtPicture = require("../../assets/images/default.png");

export class index extends Component {
  constructor() {
    super();
    this.state = {
      defaultScale: 1,
      defaultDeg: 0,
      cantBeDrag: true,
      images: [],
      currentIndex: 0,
      openPopover: false,
      transformOrigin: "top left",
      transformRotate: "rotate(0deg)",
      transformScale: "scale(1)",
    };

    this.closeModal = this.closeModal.bind(this);
    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);

    this.openPopover = this.openPopover.bind(this);
    this.closePopover = this.closePopover.bind(this);
  }

  // getSnapShotBeforeUpdate was used
  // so need to provide this thingy
  componentDidUpdate() {}

  componentDidMount() {
    if (this.props.isOpen) {
      this.refs.filePreview.style.display = "block";
    } else {
      this.refs.filePreview.style.display = "none";
    }

    const { images, currentIndex } = this.props;

    // const sliced = images.slice(0, currentIndex);

    this.setState({
      images,
      currentIndex,
    });
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    // Are we adding new items to the list?
    // Capture the scroll position so we can adjust scroll later.
    if (prevProps.isOpen != this.props.isOpen) {
      if (this.props.isOpen) {
        this.refs.filePreview.style.display = "block";
      } else {
        this.refs.filePreview.style.display = "none";
      }

      const { images, currentIndex } = this.props;
      // const sliced = images.slice(0, currentIndex);

      console.log("FilePreview2: getSnapshotBeforeUpdate");
      console.log(images);

      this.setState({
        images,
        currentIndex,
      });
    }
    return null;
  }

  closeModal() {
    this.refs.filePreview.style.display = "none";
    this.props.modalClose();
  }

  zoomIn(e) {
    const { defaultScale } = this.state;

    if (defaultScale === 4) return;

    this.setState({
      defaultScale: defaultScale + 1,
      cantBeDrag: false,
      transformScale: `scale(${defaultScale + 1})`,
    });
  }

  zoomOut() {
    const { defaultScale } = this.state;

    if (defaultScale === 1) return;

    this.setState({
      defaultScale: defaultScale - 1,
      cantBeDrag: false,
      transformScale: `scale(${defaultScale - 1})`,
    });
  }

  next() {
    if (this.refs.imageContainer) {
      this.refs.imageContainer.style.transform = "translate(0px, 0px)";
    }
    if (this.refs.primaryImage) {
      this.refs.primaryImage.style.transform = "scale(1)";
    }
    this.setState({
      cantBeDrag: true,
      defaultScale: 1,
      defaultDeg: 360,
      currentIndex: this.state.currentIndex + 1,
    });
  }

  previous() {
    if (this.refs.imageContainer) {
      this.refs.imageContainer.style.transform = "translate(0px, 0px)";
    }
    if (this.refs.primaryImage) {
      this.refs.primaryImage.style.transform = "scale(1)";
    }
    this.setState({
      currentIndex: this.state.currentIndex - 1,
      cantBeDrag: true,
      defaultScale: 1,
      defaultDeg: 360,
    });
  }

  thumbnailAction(data) {
    let index = this.state.images.indexOf(data);
    if (index < 0) return;
    if (this.refs.imageContainer) {
      this.refs.imageContainer.style.transform = "translate(0px, 0px)";
    }
    if (this.refs.primaryImage) {
      this.refs.primaryImage.style.transform = "scale(1)";
    }

    this.setState({
      currentIndex: index + 1,
      cantBeDrag: true,
      defaultScale: 1,
      defaultDeg: 360,
    });
  }

  paginateThumbnail(items, currentIndex = 1) {
    if (items.length === 0) return [];
    let emptyIndex = 0,
      emptyLeftItems = [],
      emptyRightItems = [],
      leftItems = [],
      rightItems = [];
    // activeIndex is only 0, 1, 2 ,3

    if (currentIndex < 4) {
      emptyIndex = 4 - currentIndex;
      emptyLeftItems = [...Array(emptyIndex)].map(() => {
        return null;
      });
    }

    rightItems = items.slice(currentIndex - 1, currentIndex + 3);
    // console.log(emptyIndex)
    if (emptyIndex !== 0) {
      leftItems = items.slice(
        currentIndex - (4 - emptyIndex),
        currentIndex - 1
      );
    } else {
      leftItems = items.slice(currentIndex - 4, currentIndex - 1);
    }

    if (rightItems.length < 4) {
      let numberOfEmptyRight = 4 - rightItems.length;
      emptyRightItems = [...Array(numberOfEmptyRight)].map(() => {
        return null;
      });
    }

    // console.log(emptyLeftItems, leftItems, rightItems, emptyRightItems)

    let tempItems = [].concat.apply(
      [],
      [emptyLeftItems, leftItems, rightItems, emptyRightItems]
    );
    // return object with all pager properties required by the view
    return tempItems;
  }

  downloadFile() {
    let url = this.state.images[this.state.currentIndex - 1].url;
    if (!url) return;

    // Source: http://pixelscommander.com/en/javascript/javascript-file-download-ignore-content-type/

    //iOS devices do not support downloading. We have to inform user about this.
    if (/(iP)/g.test(navigator.userAgent)) {
      alert(
        "Your device does not support files downloading. Please try again in desktop browser."
      );
      window.open(url, "_blank");
      return false;
    }

    //If in Chrome or Safari - download via virtual link click
    if (
      navigator.userAgent.toLowerCase().indexOf("chrome") > -1 ||
      navigator.userAgent.toLowerCase().indexOf("safari") > -1
    ) {
      //Creating new link node.
      var link = document.createElement("a");
      link.href = url;
      link.setAttribute("target", "_blank");

      if (link.download !== undefined) {
        //Set HTML5 download attribute. This will prevent file from opening if supported.
        var fileName = url.substring(url.lastIndexOf("/") + 1, url.length);
        link.download = fileName;
      }

      //Dispatching click event.
      if (document.createEvent) {
        var e = document.createEvent("MouseEvents");
        e.initEvent("click", true, true);
        link.dispatchEvent(e);
        return true;
      }
    }

    // Force file download (whether supported by server).
    if (url.indexOf("?") === -1) {
      url += "?download";
    }

    window.open(url, "_blank");
    return true;
  }

  openPopover() {
    this.setState({
      openPopover: true,
    });
  }

  closePopover() {
    this.setState({
      openPopover: false,
    });
  }

  rotate(direction) {
    const { defaultDeg } = this.state;

    let newDeg;

    if (direction === "left") {
      newDeg = defaultDeg - 90;
    } else if (direction === "right") {
      newDeg = defaultDeg + 90;
    }

    this.setState({
      defaultDeg: newDeg,
      transformRotate: `rotate(${newDeg}deg)`,
      transformOrigin: this.getTransformOrigin(newDeg),
    });
  }

  getTransformOrigin(degrees) {
    // 360 degress is same as 0 degrees
    if (Math.abs(degrees) >= 360) degrees %= 360;

    // Pad negative degrees by 360
    if (degrees < 0) degrees += 360;

    const orientation = degrees / 90;

    switch (orientation) {
      case 0:
        return "top left";
      case 1:
        return "bottom left";
      case 2:
        return "bottom right";
      case 3:
        return "top right";
    }
  }

  handleSelect(link) {
    if (this.props.onSelect) {
      this.props.onSelect(link);
    }
  }
  render() {
    const { transformOrigin, transformScale, transformRotate } = this.state;

    let paginate = this.paginateThumbnail(
      this.state.images,
      this.state.currentIndex
    );
    let Thumbnail = paginate.map((data, i) => {
      if (data === null) {
        return (
          <div key={i} className="thumbnal" style={{ cursor: "auto" }}></div>
        );
      }

      let url = data.url;
      if (data.isPDF) {
        url = PDFThumbnail;
      }
      let imageStyle = {
        backgroundImage: "url(" + url + ")",
      };
      if (i === 3) {
        return <div key={i} className="thumbnal" style={imageStyle}></div>;
      }
      return (
        <div
          onClick={this.thumbnailAction.bind(this, data)}
          key={i}
          className="thumbnal"
          style={imageStyle}
        >
          <div className="Image-Overlay"></div>
        </div>
      );
    });

    let mainImage;
    let isPDF = false;
    let isIMG = true;
    let isVideo = false;
    let isAudio = false;
    let file;
    let senderProfile = defualtPicture;
    let senderName = "";
    let uploaderProfile = defualtPicture;
    let uploaderName = "";
    let date = "";
    let originalDateSent = "";

    const { links = [], fromThread = false } = this.props;
    const listImages = fromThread ? this.state.images : this.props.images;

    if (this.state.images.length !== 0) {
      file = fromThread
        ? this.state.images[this.state.currentIndex - 1]
        : this.props.images[this.state.currentIndex - 1];

      mainImage = file.url;

      let fileExt = getFileExtension(mainImage);

      isAudio = file.fileType === "audio";
      isVideo = file.fileType === "video";
      isPDF = fileExt === "pdf" || false;
      isIMG = !isPDF;
    }

    let disabledZoomIn = false;
    let disabledZoomOut = false;

    if (this.state.defaultScale === 1) {
      disabledZoomOut = true;
    }

    if (this.state.defaultScale === 4) {
      disabledZoomIn = true;
    }

    return (
      <div ref="filePreview" className="File-Preview-Modal">
        <div className="doc-action-container">
          <button
            className="File-Action-Button"
            onClick={this.downloadFile.bind(this)}
          >
            <img
              style={{ width: "22px", marginRight: "10px" }}
              src={require("../../assets/images/file-download.png")}
            />
            <span style={{ fontSize: "15px" }}>Download</span>
          </button>
          {isPDF && (
            <a href={mainImage} target="_blank" className="File-Action-Button">
              <img
                style={{ width: "22px", marginRight: "10px" }}
                src={require("../../assets/images/view.png")}
              />
              <span style={{ fontSize: "15px" }}>View PDF</span>
            </a>
          )}
          <button
            className="File-Action-Button"
            onClick={this.closeModal}
            style={{ width: "32px" }}
          >
            {/* <img style={{ width: "100%" }} src={require("../../assets/images/close_file_preview.png")} /> */}
            <i className="fal fa-times fa-lg" style={{ color: "#dcdcdc" }} />
          </button>
        </div>
        <div className="File-Preview-Modal-Content2">
          <div className="row mr-0 ml-0" style={{ height: "100%" }}>
            <div className="col-md-12 p-0">
              <div className="File-Preview-Overlay">
                {this.state.currentIndex !== 1 && (
                  <button className="Previous-Button" onClick={this.previous}>
                    <img
                      style={{ width: "100%", verticalAlign: "unset" }}
                      src={require("../../assets/images/back.png")}
                    />
                  </button>
                )}
                {this.state.currentIndex !== listImages.length && (
                  <button className="Next-Button" onClick={this.next}>
                    <img
                      style={{ width: "100%", verticalAlign: "unset" }}
                      src={require("../../assets/images/next.png")}
                    />
                  </button>
                )}
              </div>

              <div className="File-Preview-Container">
                {!isVideo && isIMG && (
                  <div className="doc-action-view">
                    <button
                      className="File-Action-Button"
                      onClick={this.zoomIn}
                      disabled={disabledZoomIn}
                    >
                      <img
                        style={{ width: "22px", marginRight: "5px" }}
                        src={require("../../assets/images/zoom-in.png")}
                      />
                      <span style={{ fontSize: "15px" }}>Zoom In</span>
                    </button>
                    <button
                      className="File-Action-Button"
                      onClick={this.zoomOut}
                      disabled={disabledZoomOut}
                    >
                      <img
                        style={{ width: "22px", marginRight: "5px" }}
                        src={require("../../assets/images/zoom-out.png")}
                      />
                      <span style={{ fontSize: "15px" }}>Zoom Out</span>
                    </button>
                    <button
                      className="File-Action-Button"
                      onClick={this.rotate.bind(this, "left")}
                    >
                      <img
                        style={{ width: "21px" }}
                        src={require("../../assets/images/rotate-left.png")}
                      />
                      <span style={{ fontSize: "15px" }}></span>
                    </button>
                    <button
                      className="File-Action-Button"
                      onClick={this.rotate.bind(this, "right")}
                    >
                      <img
                        style={{ width: "21px" }}
                        src={require("../../assets/images/rotate-right.png")}
                      />
                      <span style={{ fontSize: "15px" }}></span>
                    </button>
                  </div>
                )}
                {isAudio ? (
                  <div ref="imageContainer" className="Main-Video">
                    <audio
                      controls
                      style={{
                        display: "block",
                        marginBottom: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <source src={file.url} />
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                ) : isVideo ? (
                  <div ref="imageContainer" className="Main-Video">
                    <video
                      style={{
                        maxHeight: "calc(100vh - 150px)",
                        display: "block",
                        width: "100%",
                      }}
                      key={1}
                      controls
                    >
                      <source src={file.url} />
                      Your browser does not support HTML5 video.
                    </video>
                  </div>
                ) : (
                  <div
                    ref="imageContainer"
                    className="Main-Image"
                    style={{ transform: transformRotate }}
                  >
                    {isPDF ? (
                      <Document
                        className="PDF-Document"
                        file={mainImage}
                        loading={
                          <div>
                            <i className="fas fa-circle-notch fa-spin"></i>{" "}
                            Loading PDF
                          </div>
                        }
                      >
                        <Page pageNumber={1} className="PDF-Page" />
                      </Document>
                    ) : isIMG ? (
                      // <img className="Primary-Image" ref="primaryImage" draggable="false" src={mainImage} style={{ height: '100%', maxWidth: "100%", maxHeight: "calc(100vh - 190px)", marginTop: "40px", transform: transformScale, transformOrigin }} /> : ''
                      <a onClick={(e) => window.open(mainImage, "_blank")}>
                        <ImageLoad
                          imageURL={mainImage}
                          imageStyles={{
                            height: "100%",
                            maxWidth: "100%",
                            maxHeight: "calc(100vh - 190px)",
                            marginTop: "40px",
                            transform: transformScale,
                            transformOrigin,
                          }}
                        />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="Thumbnail-Container">{Thumbnail}</div>
      </div>
    );
  }
}

export default index;
