import React from 'react';

export default function MMSMedia(props) {

  const {message} = props;
  const {mmsUrl, mmsContentType} = message;

  if (mmsContentType.startsWith('image/')) {
    return <Image url={mmsUrl} onClick={props.onClickImage}/>
  }

  return <Placeholder mmsUrl={mmsUrl} mmsContentType={mmsContentType} />;
}

function Image(props) {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center" style={{width: '200px', height: '200px'}} style={{minHeight: '200px', minWidth: '200px'}}>
      <img className="img-fluid" src={props.url} onClick={props.onClick}></img>
    </div>
  )
}

function Placeholder(props) {
  return (
    <div>
      <div className="d-flex flex-column justify-content-center align-items-center" style={{width: '200px', height: '200px'}}>
        <i className="fas fa-4x fa-file hc-primary-text"></i>
        <span className="mt-1 text-grey">{props.mmsContentType}</span>
        <a download href={props.mmsUrl} className="no-underline mt-1">
          <i className="fas fa-download mr-1"></i>
          download
        </a>
      </div>
    </div>
  )
}
