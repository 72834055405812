

import React, { Fragment, useCallback, useMemo, useState } from 'react';
import moment from 'moment';

function FromConference (props) {
    
    const { conference, message, fromMe, openModal = () => {}, openConfirmModal = () => {} } = props;
    const { host = {}, isScheduled, dateScheduled = {} } = conference;

    const conferenceActive = useMemo(() => {
        
        if (!conference) return false;

        if (isScheduled) {
            return message.conferenceActive === true;
        }

        return conference.status === 'active';
    }, [conference, message]);

    /* Apply appropriate bubble class */
    const containerClass = fromMe ?
        "bubble-list-item reverse":
        "bubble-list-item";

    const divClass = fromMe ?
        "bubble-from me":
        "bubble-from them";
    
    const iconClass = fromMe ?
        "fas fa-arrow-up mr-1 fa-rotate-45-left":
        "fas fa-arrow-down mr-1 fa-rotate-45-left";

    const avatarUrl = getAvatar(message);

    const callDate = moment(conference.createdAt).format('h:mm a');
    const scheduleTime = moment(dateScheduled.iso).format('h:mm a');
    const scheduleDate = moment(dateScheduled.iso).format('MMM DD');
    const isBeforeSchedule = moment().isBefore(dateScheduled.iso);

    const theName = fromMe ? 'You' : host.firstName;
    
    function render() {
        return (
            <li className={containerClass}>
                { !fromMe ?
                    <div className="bubble-avatar-container">
                        <div className="bubble-avatar">
                            <img width="32" height="32" src={avatarUrl} />
                        </div>
                    </div>
                    : ''
                }
                <div className={divClass}>
                    { isScheduled ?
                        <div className="bubble-text-main">
                            {theName} scheduled a conference call.
                        </div>
                        :
                        <div className="bubble-text-main">
                            {theName} started a conference call.
                        </div>
                    }
                    { isScheduled ?
                        <div className="bubble-text-sub">
                            <i className="fa fa-fw fa-calendar mr-1"></i>
                            <span className="badge badge-warning mr-1">
                                {scheduleDate}
                            </span>
                            <span className="badge badge-warning">
                                {scheduleTime}
                            </span>
                        </div>
                        :
                        <div className="bubble-text-sub">
                            <i className={iconClass}></i>
                            {callDate}
                        </div>
                    }
                    {isScheduled && (
                        <Fragment>
                        <div className="bubble-text-sub">
                            <strong>Topic:</strong> {conference.label || 'No Topic'}
                        </div>
                        <div className="bubble-text-sub">
                            <strong>Cc:</strong> {conference.chiefComplaint || 'No chief complaint'}
                        </div>
                        </Fragment>
                    )}
                    <div>
                        { conferenceActive ?
                            <button onClick={() => openConferenceWindow(conference.roomId)} className="btn btn-sm btn-success btn-join-call">
                                <i className="fa fa-phone mr-1"></i>
                                Join call
                            </button>
                            : isScheduled && isBeforeSchedule ?
                            <button disabled className="btn btn-sm btn-success btn-join-call">
                                <i className="fa fa-phone mr-1"></i>
                                Join call
                            </button>
                            : 
                            <span className="bubble-text-ended">
                                Call has ended
                            </span>
                        }
                    </div>
                    {isScheduled && (
                        <Fragment>
                            {(isScheduled && isBeforeSchedule) && (
                                <div className="bubble-text-sub bubble-text-flex" style={{ marginTop: '10px' }}>
                                    {/* <a href="#">Re-schedule</a>
                                    <a href="#" 
                                        type="button"
                                        onClick={() => openConfirmModal(conference)}>Confirm</a> */}
                                    <a  href="#" 
                                        type="button"
                                        style={{ float: 'right', marginBottom: 0 }}
                                        onClick={() => openModal(conference)}>
                                        View details
                                    </a>
                                </div>
                            )}
                        </Fragment>
                    )}
                </div>
            </li>
        )
    }

    function getAvatar(message) {
        let avatarImage;
        if (message.user) {
            if (!message.user.picture) {
                avatarImage =  require("../../../assets/images/default.png");
            } else {
                avatarImage = message.user.picture.url;
            }
        } else {
            avatarImage =  require("../../../assets/images/default.png");
        }
        return avatarImage;
    }

    function openConferenceWindow(roomId) {
        const conferenceUrl = [
            window.location.origin,
            "/conferencecall",
            "?roomId=" + roomId
        ].join('');

        window.open(conferenceUrl, "Hubchart Conference Call", "status=1,menubar=0");
    }

    return render();
}

export default FromConference;
