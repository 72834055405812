import React, { Fragment, useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Parse from "parse";
import moment from "moment";
import TextareaAutosize from "react-textarea-autosize";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import debounce from "lodash.debounce";
import { parseAction } from "../../../../actions/REST";
import Loader from "../../../Common/Loader";
import TabLoader from "../../../Common/TabLoader";
// import { updatePatientInfo } from '../../../../actions/GroupAction';
import Notify from "../../../Common/Notify";
import config from "../../../../config";
import { defaultList } from "../../../Conversation/Setting/PatientDiagnosisModal/config";
import PatientInfo from "../PatientInfo";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "../../../Pages/Login/Login.css";
import "../../../Conversation/Setting/SettingModal.css";
import AddDiagnosisModal from "../../../Conversation/Setting/PatientDiagnosisModal/AddDiagnosisModal";
import Logo from "../../../../assets/images/logo-blue.png";

const $ = window.$;
const defaultSelectedDiagnosis = {
  code: "",
  longDescription: "",
  shortDescription: "",
  onsetDate: new Date(),
  is_acute: false,
  is_chronic: false,
  is_resolved: false,
  type: "manual"
};

function PatientDiagnosisModal({
  isOpen = false,
  threadId = "",
  refreshData = () => {}
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDiagnosis, setIsLoadingDiagnosis] = useState(false);
  const [endOfList, setEndOfList] = useState(false);
  const [manualDxEntryDisabled, setManualDxEntryDisabled] = useState(false);
  const [patientInfo, setPatientInfo] = useState(false);
  const [addDiagnosisModalShown, setAddDiagnosisModalShown] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState([]);
  const [selectedTempDiagnosis, setSelectedTempDiagnosis] = useState([
    { ...defaultSelectedDiagnosis },
    { ...defaultSelectedDiagnosis },
    { ...defaultSelectedDiagnosis },
    { ...defaultSelectedDiagnosis },
    { ...defaultSelectedDiagnosis }
  ]);
  const [method, setMethod] = useState("create");
  const [diagnosisList, setDiagnosisList] = useState([]);
  const [keyWord, setKeyWord] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  function fetchPatientInfo(id) {
    setIsLoading(true);
    parseAction("get", config.BASE_URL + "/parse/classes/Circle", {
      where: {
        objectId: id
      }
    })
      .then(result => {
        if (result.results.length === 0) {
          return;
        }

        var data = result.results[0];
        const diags = (data.diagnosis || []).map(item => ({
          ...item,
          onsetDate: new Date(item.onsetDate.iso)
        }));
        setPatientInfo(data);
        setSelectedDiagnosis(diags);
        setIsLoading(false);
      })
      .catch(error => {
        console.error(error);
        setIsLoading(false);
      });
  }

  function fetchDiagnosisList(keyWord, cPage = 1) {
    setIsLoadingDiagnosis(true);
    parseAction(
      "get",
      `${config.PATIENT_URL}/diagnosis?search=${keyWord}&page=${cPage}&size=50`
    )
      .then(result => {
        const { items, page, total } = result.data;
        const finalList =
          page === 1 && !keyWord
            ? [...defaultList, ...items]
            : page === 1
            ? items
            : [...diagnosisList, ...items];
        const isEndOfList = total === finalList.length ? true : false;

        setDiagnosisList(finalList);
        setKeyWord(keyWord);
        setCurrentPage(page);
        setTotalItems(total);
        setEndOfList(isEndOfList);
        setIsLoadingDiagnosis(false);
      })
      .catch(error => {
        console.error(error);
        setIsLoading(false);
        setIsLoadingDiagnosis(false);
      });
  }

  function handleFetchDiagnosisPageChange() {
    fetchDiagnosisList(keyWord, currentPage + 1);
  }

  function formatCircle(newDiagnosis = []) {
    const data = {
      objectId: patientInfo.objectId,
      diagnosis: newDiagnosis.length ? newDiagnosis : selectedDiagnosis
    };

    const Circle = Parse.Object.extend("Circle");
    const newCircle = new Circle(data);

    handleSave(newCircle);
  }

  async function handleSave(circle) {
    setIsLoading(true);

    try {
      const group = await circle.save();
      setPatientInfo(group.toJSON());
      setIsLoading(false);

      Notify.success("Diagnosis successfully saved.");
      refreshData();
      $("#patientDiagnosisModal").modal("hide");
    } catch (err) {
      setIsLoading(false);
      Notify.error("Could not save Diagnosis.");
      console.error(err);
    }
  }

  function removeItem(item) {
    selectedDiagnosis.splice(selectedDiagnosis.indexOf(item), 1);
    setSelectedDiagnosis([...selectedDiagnosis]);
    formatCircle([...selectedDiagnosis]);
  }

  function updateOnsetDate(item, date) {
    const item_index = selectedDiagnosis.indexOf(item);
    selectedDiagnosis[item_index].onsetDate = date;

    setSelectedDiagnosis([...selectedDiagnosis]);
    formatCircle([...selectedDiagnosis]);
  }

  function manualDxEntry(selectedDiagnosis) {
    setManualDxEntryDisabled(true);
    setTimeout(() => {
      const newSelectedDiagnosis = [
        {
          code: "",
          longDescription: "",
          shortDescription: "",
          onsetDate: new Date(),
          is_acute: false,
          is_chronic: false,
          type: "manual"
        },
        ...selectedDiagnosis
      ];

      setSelectedDiagnosis([...newSelectedDiagnosis]);
      setManualDxEntryDisabled(false);
    }, 100);
  }

  function onInputChange(e, idx) {
    const { value } = e.target;

    selectedDiagnosis[idx].longDescription = value;
    selectedDiagnosis[idx].shortDescription = value;

    setSelectedDiagnosis([...selectedDiagnosis]);
  }

  function onInputCheckedChange(e, idx) {
    const { checked, name } = e.target;
    selectedDiagnosis[idx][name] = checked;

    setSelectedDiagnosis([...selectedDiagnosis]);
    formatCircle([...selectedDiagnosis]);
  }

  function onInputCodeChange(e, idx) {
    const { value } = e.target;
    selectedDiagnosis[idx].code = value;

    setSelectedDiagnosis([...selectedDiagnosis]);
  }

  function selectDiagnosis(selected) {
    const new_dx = [
      {
        code: selected[0].code,
        longDescription:
          selected[0].longDescription ||
          selected[0].shortDescription ||
          "No description",
        shortDescription: selected[0].shortDescription || "No description",
        is_acute: false,
        is_chronic: false,
        onsetDate: new Date()
      },
      ...selectedDiagnosis
    ];
    const final_dx = Array.from(new Set(new_dx.map(a => a.code))).map(code =>
      new_dx.find(a => a.code === code)
    );

    setSelectedDiagnosis(final_dx);
    formatCircle([...final_dx]);
    setSelectedOption([]);
  }

  // function calculateAge (dob) {
  //   const newDate = new Date(dob);
  //   return moment().diff(moment(newDate, 'YYYY'), 'years');
  // }

  function handleChangeTextAreaDetails(e, i) {
    const { value } = e.target;
    let newArr = [...selectedTempDiagnosis];
    newArr[i].longDescription = value;

    setSelectedTempDiagnosis([...newArr]);
  }

  function addTextAreaField() {
    setSelectedTempDiagnosis([
      {
        ...defaultSelectedDiagnosis,
        longDescription: ""
      },
      ...selectedTempDiagnosis
    ]);
  }

  function removeTextAreaField(i) {
    let newArr = selectedTempDiagnosis;
    newArr.splice(i, 1);
    setSelectedTempDiagnosis([...newArr]);
  }

  function closeAddDiagnosisModal() {
    // $("#patientAddDiagnosisModal").modal('hide');
    setAddDiagnosisModalShown(false);
  }

  function handleSetAddDiagnosisModalShown() {
    setAddDiagnosisModalShown(true);
    setTimeout(() => {
      $("#patientAddDiagnosisModal").modal({
        backdrop: false,
        keyboard: false
      });
      $("#patientAddDiagnosisModal").modal("show");
    }, 100);
  }

  function handleSaveTempDiagnosis() {
    const filteredSelectedTempDiagnosis = selectedTempDiagnosis.filter(
      item => item.longDescription
    );
    const newDiagnosis = [
      ...filteredSelectedTempDiagnosis,
      ...selectedDiagnosis
    ];

    setSelectedDiagnosis(newDiagnosis);
    setSelectedTempDiagnosis([
      { ...defaultSelectedDiagnosis },
      { ...defaultSelectedDiagnosis },
      { ...defaultSelectedDiagnosis },
      { ...defaultSelectedDiagnosis },
      { ...defaultSelectedDiagnosis }
    ]);
    closeAddDiagnosisModal();
    formatCircle(newDiagnosis);
  }

  useEffect(() => {
    if (threadId) {
      componentDidMount();
    }
  }, [threadId]);

  function componentDidMount() {
    fetchPatientInfo(threadId);
    fetchDiagnosisList("");
  }

  const debouncedFetchDiagnosisList = debounce(fetchDiagnosisList, 500);

  return (
    <div className="progress-notes-container pn-con">
      <div className="container mb-15 pn-title-flex">
        <span className="navbar-brand">
          <img
            className="d-sm-inline"
            width="40"
            height="40"
            src={Logo}
            alt="H"
          ></img>
          <span
            className="hc-primary-text d-none d-md-inline"
            style={{ fontWeight: "500" }}
          >
            HUBCHART
          </span>
        </span>
        <div className="pn-patient-details-flex">
          <div>
            <span>{patientInfo.name || "--"}</span>
            <span>DIAGNOSIS</span>
          </div>
          {(typeof (patientInfo.image || {}).url === "undefined" ||
            (patientInfo.image || {}).url === "") && (
            <img src={require("../../../../assets/images/default.png")} />
          )}
          {(patientInfo.image || {}).url && (
            <img src={(patientInfo.image || {}).url} />
          )}
        </div>
        {/* <span className="pn-title">PATIENT DIAGNOSES</span> */}
      </div>
      <div className="pn-template-container">
        <div className="container pb-10">
          <PatientInfo patientInfo={patientInfo} />
        </div>
      </div>
      <div class="container">
        <div className="filter-view" style={{ marginTop: "15px" }}>
          <div
            className="search-flex search-identifier"
            style={{ marginBottom: "15px" }}
          >
            <div className="rbt-container">
              <Typeahead
                id="setting-modal-typeahead"
                placeholder="Search Dx here"
                onChange={selected => {
                  selectDiagnosis(selected);
                }}
                onInputChange={e => debouncedFetchDiagnosisList(e)}
                options={diagnosisList}
                maxResults={999999999}
                labelKey={"longDescription"}
                filterBy={() => true}
                selected={selectedOption}
                renderMenu={(results, menuProps) => (
                  <Menu {...menuProps}>
                    {results.map((result, index) => (
                      <MenuItem
                        key={result.code + index}
                        option={result}
                        position={index}
                      >
                        {`(${result.code}) - ${result.longDescription ||
                          result.shortDescription}`}
                      </MenuItem>
                    ))}
                    <TabLoader isLoading={isLoadingDiagnosis} />
                    <div className="load-more">
                      {!endOfList && (
                        <button
                          type="button"
                          className="btn btn-block btn-light btn-sm"
                          onClick={() => handleFetchDiagnosisPageChange()}
                        >
                          Load More
                        </button>
                      )}
                      {endOfList && <span>- End of List -</span>}
                    </div>
                  </Menu>
                )}
              />
              <button
                type="button"
                className="btn btn-hubchart btn-sm btn-hubchart-primary"
                style={{ height: "34px", width: "50px" }}
              >
                <i className="fas fa-search"></i>
              </button>
            </div>
            <button
              type="button"
              className="btn btn-hubchart btn-sm btn-hubchart-primary"
              disabled={manualDxEntryDisabled}
              onClick={() => handleSetAddDiagnosisModalShown()} // manualDxEntry(selectedDiagnosis)}
              style={{ margin: "0px", height: "33px", width: "170px" }}
            >
              <i className="fas fa-plus"></i> Add
            </button>
          </div>
        </div>
        <div className="diagnosis" style={{ overflowX: "auto" }}>
          {selectedDiagnosis.length > 0 && (
            <div className="setting-modal-headers">
              <span>Code</span>
              <span>Diagnosis</span>
              <span>Date Entered</span>
              <span>Resolved</span>
              <span>Action</span>
            </div>
          )}
          <div className="setting-modal-item-container">
            {selectedDiagnosis.map((item, i) => (
              <div
                className="setting-modal-item"
                key={`dx-${moment(new Date()).format("lll")}-${i}`}
              >
                {item.type !== "manual" && (
                  <Fragment>
                    <span>{item.code}</span>
                    <span>{item.longDescription || item.shortDescription}</span>
                  </Fragment>
                )}
                {item.type === "manual" && (
                  <Fragment>
                    <input
                      className="setting-modal-input"
                      type="text"
                      placeholder="Enter Code"
                      value={item.code}
                      onChange={e => onInputCodeChange(e, i)}
                      onBlur={() => formatCircle()}
                    />
                    <TextareaAutosize
                      key={`${i}-dx`}
                      className="setting-modal-input-2"
                      value={item.longDescription}
                      minRows={1.3}
                      onChange={e => onInputChange(e, i)}
                      onBlur={() => formatCircle()}
                    />
                  </Fragment>
                )}
                <span>
                  <DatePicker
                    className="date-picker-custom black"
                    // dateFormat="MM/dd/yyyy"
                    placeholderText="mm/dd/yyyy"
                    selected={item.onsetDate}
                    onChange={date => {
                      updateOnsetDate(item, date);
                    }}
                    isClearable
                  />
                </span>
                <span>
                  <input
                    type="checkbox"
                    id="is_resolved"
                    name="is_resolved"
                    checked={item.is_resolved}
                    style={{ marginTop: "10px" }}
                    onChange={e => onInputCheckedChange(e, i)}
                  />
                </span>
                <span>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    title="Remove Dx"
                    onClick={() => removeItem(item)}
                  >
                    <i className="fas fa-trash"></i>
                  </button>
                </span>
              </div>
            ))}
          </div>
        </div>
        {selectedDiagnosis.length === 0 && (
          <div className="default-view">
            <span>No Diagnosis Found.</span>
          </div>
        )}
        {addDiagnosisModalShown && (
          <AddDiagnosisModal
            handleChangeTextAreaDetails={handleChangeTextAreaDetails}
            handleCloseAddDiagnosisModal={closeAddDiagnosisModal}
            addTextAreaField={addTextAreaField}
            removeTextAreaField={removeTextAreaField}
            saveTempDiagnosis={handleSaveTempDiagnosis}
            selectedTempDiagnosis={selectedTempDiagnosis}
            method={method}
          />
        )}
        <Loader isLoading={isLoading} />
      </div>
    </div>
  );
}

export default PatientDiagnosisModal;
