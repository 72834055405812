import React, { Component, Fragment } from "react";
import moment from "moment";
import Linkify from "react-linkify";
import ImageLoad from "../../Common/ImageLoad";
import ImagePlaceholder from "../../Common/ImagePlaceholder";
import DraggableBubble from "./DraggableBubble";
import Seen from "./Seen";
import { Document, Page, pdfjs } from "react-pdf";
import MMSMedia from "./MMSMedia";
import AttachmentContainer from "./AttachmentContainer";
import ReplyTo from "./ReplyTo";

export class FromMe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playing: false,
      pos: 0,
      isExpanded: false,
      showViewMore: false,
      attentioned_users: [],
    };

    this.handleTextDisplay = this.handleTextDisplay.bind(this);
    this.highlightSpecificWords = this.highlightSpecificWords.bind(this);
    this.handleViewMoreDisplay = this.handleViewMoreDisplay.bind(this);
    this.handleTogglePlay = this.handleTogglePlay.bind(this);
    this.handlePosChange = this.handlePosChange.bind(this);
    this.resendMessage = this.resendMessage.bind(this);
  }

  resendMessage() {
    this.props.resendMessage();
  }

  getFileExtention(name) {
    return name.substr((~-name.lastIndexOf(".") >>> 0) + 2);
  }

  handleTogglePlay() {
    this.setState({
      playing: !this.state.playing,
    });
  }

  handlePosChange(e) {
    this.setState({
      pos: e.originalArgs[0],
    });
  }

  handleReply() {
    const { message } = this.props;
    // let time = moment(new Date(message.createdAt)).format('MM[/]D[/]YY HH[:]mm');
    // let reply = "\"Re: " + message.text + "\" -- " + message.user.firstName + " - " + time + "\n------------------\n\n";
    this.props.initReply(message);
  }

  openLightbox(url) {
    this.props.openLightbox(url);
  }

  getPdfThumbnail(thumbnail) {
    if (!thumbnail) return "";

    const url = thumbnail.url;

    const ext = this.getFileExtention(url).toLowerCase();

    if (ext === "pdf") return "";

    return url;
  }

  highlightSpecificWords = ({ text, highlightWords }) => {
    const highlightText = (text, words) => {
      const escapedHighlightWords = words.map((word) => word.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"));
      const pattern = new RegExp(`(${escapedHighlightWords.join("|")})`, "gi");
      const parts = text.split(pattern);
      if (highlightWords.length > 0) {
        return parts.map((part, index) =>
          pattern.test(part) ? (
            <span key={index} style={{ color: "#2193f3", fontWeight: "bold" }}>
              {part}
            </span>
          ) : (
            part
          )
        );
      } else {
        return text;
      }
    };

    return <div>{highlightText(text, highlightWords)}</div>;
  };

  handleTextDisplay(text = "", isViewMore) {
    const { isExpanded, attentioned_users } = this.state;
    const textLength = text.length;
    if (isViewMore) {
      return isExpanded
        ? this.highlightSpecificWords({
            text,
            highlightWords: attentioned_users,
          })
        : this.highlightSpecificWords({
            text: `${text.substr(0, 700)}...`,
            highlightWords: attentioned_users,
          });
    }
    return textLength < 700
      ? this.highlightSpecificWords({ text, highlightWords: attentioned_users })
      : this.highlightSpecificWords({
          text: `${text.substr(0, 700)}...`,
          highlightWords: attentioned_users,
        });
  }

  handleViewMoreDisplay(bool) {
    this.setState({ isExpanded: bool });
  }

  getPicture() {}

  componentDidMount() {
    const { message, threadType = "group" } = this.props;
    if (message.attention && message.attention.length > 0 && threadType === "group") {
      const attentioned_users = message.attention.map((item) => `@${item.firstName}`);
      this.setState({ attentioned_users });
    }
  }

  render() {
    let { message, continuesMessage, isCCM, isChecked, threadType = "group" } = this.props;
    const { attentioned_users } = this.state;

    // console.log('messageCON', message);

    let Picture,
      Video,
      Audio,
      hasPicture = false,
      AvatarImage,
      isPicture = false,
      isPDF = false,
      filename,
      Break = [],
      SeenArray = [],
      hasAudio = false,
      audioUrl = "",
      audioType = "",
      bubbleClass = "from-me";

    const seenContainer = {
      fontSize: "12px",
      margin: "2px 3px 0 8px",
      color: "rgba(0, 0, 0, .50)",
      fontWeight: "400",
      lineHeight: "12px",
      display: "flex",
      flexFlow: "wrap",
    };
    let themNameStyle = {
      paddingBottom: "10px",
      paddingLeft: "42px",
      display: "inherit",
    };

    let containerStyle = {};

    let textStyle = {
      display: "block",
    };

    if (message.forwardedToCCM) {
      bubbleClass = "from-me-forwarded";
    }

    if (continuesMessage) {
      containerStyle = {
        marginBottom: "0",
      };
      if (bubbleClass === "from-me-forwarded") {
        bubbleClass = "from-me-forwarded-continues";
      } else {
        bubbleClass = "from-me-continues";
      }
    }

    if (typeof message.newMessageStatus === "undefined" || message.newMessageStatus === "") {
      themNameStyle.display = "none";
    }

    if (!message.user.picture) {
      AvatarImage = require("../../../assets/images/default.png");
    } else {
      AvatarImage = message.user.picture.url;
    }

    /**
     * Check if picture object is pdf or picture
     */
    if (typeof message.picture !== "undefined") {
      if (typeof message.picture.url !== "undefined") {
        let ext;
        if (typeof message.picture.name === "undefined") {
          ext = this.getFileExtention(message.picture.file.name).toLowerCase();
        } else {
          ext = this.getFileExtention(message.picture.name).toLowerCase();
        }
        if (ext === "pdf") {
          isPDF = true;

          const thumbUrl = this.getPdfThumbnail(message.thumbnail);

          Picture = thumbUrl ? (
            <ImageLoad openLightbox={() => {}} imageURL={thumbUrl} />
          ) : (
            <ImagePlaceholder type="pdf" />
          );
        } else {
          isPicture = true;
          Picture = (
            <ImageLoad
              openLightbox={() => this.props.openLightbox(message.picture.url)}
              imageURL={message.picture.url}
            />
          );
        }
      }
    }

    if (message.file) {
      if (message.file.type === "audio/mp3") {
        audioUrl = URL.createObjectURL(message.file);
        audioType = message.file.type;
        hasAudio = true;
      }
    }

    const { smsFrom, mmsContentType } = message;

    /**
     * Check if video
     */
    if (typeof message.video != "undefined") {
      if (typeof message.video.url != "undefined") {
        Video = (
          <video key={1} style={{ marginBottom: "15px", display: "block", width: "100%" }} controls>
            <source src={message.video.url} />
            Your browser does not support HTML5 video.
          </video>
        );
      }
    }
    /**
     * Check if pdf
     */
    if (isPDF) {
      if (typeof message.filename !== "undefined") {
        filename = message.filename;
      } else {
        filename = message.picture.name;
      }

      if (message.text === "") {
        // Do nothing
      } else {
        if (typeof message.text !== "undefined") {
          Break.push(<br key={1} />);
          Break.push(<br key={2} />);
        }
      }
    }

    if (message.attachments && message.attachments.length) {
      if (message.attachments[0].file) {
        if (message.attachments[0].fileType) {
          if (message.attachments[0].fileType.match("image/.*") || message.attachments[0].fileType === "photo") {
            isPicture = true;
            Picture = (
              <ImageLoad
                openLightbox={this.openLightbox.bind(this, message.attachments[0].file.url)}
                imageURL={message.attachments[0].file.url}
              />
            );
          } else if (message.attachments[0].fileType.match("audio/.*")) {
            audioUrl = message.attachments[0].file.url;
            audioType = message.attachments[0].fileType;
            hasAudio = true;
          } else if (
            message.attachments[0].fileType.match("file/pdf") ||
            message.attachments[0].fileType.match("application/pdf")
          ) {
            // isPDF = true;
            // pdfURL = message.attachments[0].file.url;
            // filename = message.attachments[0].file.name;
            // Picture = <img key={1} src="../../images/pdf.png" alt={message.attachments[0].file.url} className="img-fluid" style={{display: 'inline-block', width: "20px", height: "20px",marginBottom: "15px"}} />;
          }
        }
      }
    }

    if (hasAudio) {
      Audio = (
        <audio controls style={{ display: "block", marginBottom: "10px", marginTop: "10px" }}>
          <source src={audioUrl} type={audioType} />
          Your browser does not support the audio element.
        </audio>
      );
    }

    const seenBy = this.props.seenBy();
    for (let x = 0; x < seenBy.length; x++) {
      let url;
      if (typeof seenBy[x].picture == "undefined") {
        url = "../images/default.png";
      } else {
        url = seenBy[x].picture.url;
      }
      const name = seenBy[x].firstName + " " + seenBy[x].lastName;
      SeenArray.push(<Seen key={seenBy[x].objectId} url={url} name={name} />);
    }

    // Render attentioned users
    let attentioned;

    if (typeof message.attention !== "undefined") {
      attentioned = message.attention
        .filter((user) => !message.text.includes(`@${user.firstName}`))
        .map((user) => {
          return "@" + user.firstName;
        })
        .join(", ");
    }

    const { replyTo, replyingTo } = message;

    let today = moment(new Date());
    let dateSend = moment(message.createdAt);
    let themDate;
    if (moment(dateSend).isSame(today, "day")) {
      themDate = "Today, " + moment(message.createdAt).format("LT");
    } else {
      themDate = moment(message.createdAt).format("MMM D, YYYY, h:mm A");
    }

    return (
      <li className="Bubble-List-Container" style={containerStyle}>
        <div className="_me-container">
          <div className="_avatar-column">
            <div className="acR"></div>
            <div className="avatar">
              <img src={AvatarImage} />
            </div>
          </div>
          <div className="_me-column" style={{ position: "relative" }}>
            <div
              aria-haspopup="true"
              aria-expanded="false"
              className={"bubble " + bubbleClass}
              style={{ cursor: "pointer", overflow: "hidden" }}
              title={moment(message.createdAt).format("MMMM D, YYYY [at] LTS Z")}
            >
              {replyingTo && replyingTo.length ? (
                replyingTo.map((replyTo) => {
                  return <ReplyTo replyTo={replyTo} />;
                })
              ) : replyTo ? (
                <ReplyTo replyTo={replyTo} />
              ) : (
                ""
              )}

              {message.attachments && message.attachments.length ? (
                <AttachmentContainer attachments={message.attachments} onOpen={(url) => this.openLightbox(url)} />
              ) : (
                <div>
                  {isPDF ? (
                    <div onClick={() => window.open(message.picture.url, "_blank")} style={{ cursor: "pointer" }}>
                      {Picture}
                    </div>
                  ) : (
                    Picture
                  )}
                  {Audio}
                  {Video}
                  {isPDF ? (
                    <a
                      ref={(p) => (this.pdf = p)}
                      style={{ textDecoration: "underline", marginLeft: "3px" }}
                      href={message.picture.url}
                      target="_blank"
                    >
                      {filename}
                    </a>
                  ) : null}
                </div>
              )}
              {smsFrom && mmsContentType ? (
                <MMSMedia message={message} onClickImage={() => this.props.onClickMMS(message.mmsUrl)} />
              ) : (
                ""
              )}
              {Break}

              {!hasAudio ? (
                <Linkify
                  properties={{
                    onClick(event) {
                      window.open(event.target.href, "Hubchart");
                    },
                  }}
                >
                  {attentioned ? <p className="attentioned-users">{attentioned}</p> : ""}
                  {/* <span className="message-text-content" style={textStyle}>
                    {message.text}
                  </span> */}

                  <div>
                    {!this.state.isExpanded && (
                      <span className="message-text-content" style={textStyle}>
                        {this.handleTextDisplay(message.text)}
                      </span>
                    )}
                    {this.state.isExpanded && (
                      <span className="message-text-content" style={textStyle}>
                        {this.highlightSpecificWords({
                          text: message.text,
                          highlightWords: attentioned_users,
                        })}
                      </span>
                    )}
                    {threadType === "group" && (
                      <div className="task-priority-cls">
                        {[0, 1, 2].includes(message.priorityLevel) && (
                          <Fragment>
                            {message.priorityLevel === 2 && (
                              <span className="badge badge-pill badge-light priority-flex">
                                <i className="fad fa-angle-double-up fa-fw" style={{ color: "#04589e" }}></i>
                                &nbsp;High Priority
                              </span>
                            )}
                            {message.priorityLevel === 1 && (
                              <Fragment>
                                <span className="badge badge-pill badge-light priority-flex">
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "#f6c23e",
                                    }}
                                  >
                                    =
                                  </span>
                                  &nbsp;
                                  <span> Medium Priority</span>
                                </span>
                              </Fragment>
                            )}
                            {/* {message.priorityLevel === 0 && (
                              <span className="badge badge-pill badge-light priority-flex">
                                <i className="text-primary fad fa-angle-double-down fa-fw"></i>
                                &nbsp; Routine Priority
                              </span>
                            )} */}
                          </Fragment>
                        )}
                        {message.actionItem && (
                          <div align="right">
                            <span
                              className="text-primary badge badge-light"
                              style={{ padding: "4px 8px" }}
                              onClick={() => {
                                this.props.openActionsModal(true, message.actionItem.objectId);
                                // window.open(
                                //   `${window.location.origin}/action-items/${message.threadId}?itemId=${message.actionItem.objectId}`,
                                //   "Hubchart Action Items"
                                // );
                              }}
                            >
                              View task
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                    {(message.text || "").length > 700 && (
                      <Fragment>
                        {!this.state.isExpanded && (
                          <div align="right">
                            <span
                              className="message-text-content"
                              style={{ color: "#2193f3", cursor: "pointer" }}
                              onClick={() => this.handleViewMoreDisplay(true)}
                            >
                              Show more
                            </span>
                          </div>
                        )}
                        {this.state.isExpanded && (
                          <div align="right">
                            <span
                              className="message-text-content"
                              style={{ color: "#2193f3", cursor: "pointer" }}
                              onClick={() => this.handleViewMoreDisplay(false)}
                            >
                              Show less
                            </span>
                          </div>
                        )}
                      </Fragment>
                    )}
                  </div>
                </Linkify>
              ) : (
                ""
              )}
            </div>
            {/* {<div align="right">
              <small>
                {moment(new Date(message.createdAt)).format('LTS')}
                {message.actionItem && (
                  <>
                  {" - "}
                  <span style={{fontStyle:'bold'}} className={message.actionItem.status === "DONE" ? "text-success font-bold" : "text-warning font-bold"}>
                    {message.actionItem.status} <i className="fas fa-check-circle"></i>
                  </span>
                  </>
                )}
              </small>
            </div>} */}
            {!this.props.showCheckBoxes && (
              <div className="speech-menu-container dropdown">
                <button
                  type="button"
                  className="btn speech-menu dropdown-toggle"
                  style={{ padding: "0px", height: "28px" }}
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-ellipsis-h"></i>
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a onClick={this.handleReply.bind(this)} className="dropdown-item" href="#">
                    <i className="fas fa-reply mr-1"></i>
                    Reply
                  </a>
                  <a onClick={() => this.props.openForward(message, "forward")} className="dropdown-item" href="#">
                    <i className="fas fa-share-square mr-1"></i>
                    Forward
                  </a>
                  <a onClick={() => this.props.openSelectMultiple(true)} className="dropdown-item" href="#">
                    <i className="fas fa-share-square mr-1"></i>
                    Forward multiple
                  </a>
                  <a
                    onClick={() => this.props.handleSetTaskAndMessage(true, message.text, true)}
                    className="dropdown-item"
                    href="#"
                  >
                    <i className="fas fa-tasks mr-1"></i>
                    Set as new Task
                  </a>
                  <div className="dropdown-divider"></div>
                  <a onClick={() => this.props.toBeDelete()} className="dropdown-item" href="#">
                    <i className="fas fa-trash mr-1"></i>
                    Delete for myself
                  </a>
                  <a onClick={() => this.props.toBeDeleteForEveryone()} className="dropdown-item" href="#">
                    <i className="fas fa-trash mr-1"></i>
                    Delete for everyone
                  </a>
                  {isCCM && (
                    <a onClick={() => this.props.openForwardToCCM()} className="dropdown-item" href="#">
                      Forward To CCM
                    </a>
                  )}
                </div>
                <ul>{/* <li className="transform-btn" onClick={this.handleReply.bind(this)}>Reply</li> */}</ul>
                {threadType != "private" && (
                  <button
                    type="button"
                    className="btn speech-menu-2"
                    style={{ padding: "0px", height: "28px", marginTop:'30px' }}
                    onClick={() => this.props.openReminder(message.text)}
                  >
                    <i className="fas fa-business-time"></i>
                  </button>
                )}
              </div>
            )}
          </div>
          {this.props.showCheckBoxes && (
            <div
              className="_avatar-column"
              style={{
                display: "flex",
                marginRight: "auto",
              }}
            >
              <div className="acR acr-flex">
                {/* <input
                                    key={isChecked}
                                    type="checkbox"
                                    onClick={e => {
                                        const { checked } = e.target;
                                        this.props.handleCheckUncheckMessages(checked);
                                    }}
                                    checked={isChecked || false}
                                /> */}
                <div className="round-small" onClick={() => this.props.handleCheckUncheckMessages(!isChecked)}>
                  <input type="checkbox" id="selectAll" name="selectAll" checked={isChecked} />
                  <label for="checkbox"></label>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="me-name">
          <div>
            <span className="">{themDate}</span>{" "}
            {message.actionItem && (
              <>
                <span style={{ fontSize: "8px" }}>&#9679;</span>{" "}
                <span
                  style={{ fontStyle: "bold" }}
                  className={message.actionItem.status === "DONE" ? "text-success font-bold" : "text-warning font-bold"}
                >
                  {message.actionItem.status} <i className="fas fa-check-circle"></i>
                </span>
              </>
            )}
          </div>
        </div>
        <div className="me-name" style={themNameStyle}>
          <span style={{ paddingLeft: "4px" }}>{message.newMessageStatus === "sending" ? "Sending..." : null}</span>
          {message.newMessageStatus === "error" ? (
            <a href="#!" onClick={this.resendMessage} style={{ paddingLeft: "4px", color: "#D84B55" }}>
              This message was not sent, Click to resent.
              <i className="fas fa-exclamation-circle" style={{ marginLeft: "2px" }}></i>
            </a>
          ) : null}
        </div>
        <div style={seenContainer}>{SeenArray}</div>
      </li>
    );
  }
}

export default FromMe;
