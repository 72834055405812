import React, { useEffect } from 'react';
import moment from 'moment';

import './index.css';

function FollowUpsComponent({
  followUps = {
    in: 0,
    duration: [],
    date: '',
    provider: '',
  },
}) {
  const ff = {
    ...followUps,
    date: moment(followUps.date || new Date()).format("YYYY-MM-DD"),
  };

  return (
    <div className="custom-form-group-container">
      <div className="custom-form-group">
        <strong>Follow Up In</strong>
        <div>
          <span>{ff.in || '--'}</span>
        </div>
      </div>
      <div className="custom-form-group">
        <strong>Duration</strong>
        <div>
          <span>{(ff.duration || []).length > 0 ? ff.duration[0].text : '--'}</span>
        </div>
      </div>
      <div className="custom-form-group">
        <strong>Follow Up Date</strong>
        <div>
          <span>{ff.in || '--/--/--'}</span>
        </div>
      </div>
      <div className="custom-form-group">
        <strong>FF-up w/ Provider</strong>
        <div>
          <span>{ff.provider || '--'}</span>
        </div>
      </div>
    </div>
  );
}

export default FollowUpsComponent;